import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function addTask(task, success) {
  const urlForTasks = "ToDo/AddEditTask";
  return async (dispatch) => {
    try {
      dispatch({ type: constants.ADDING_TASK });
      const todo = await db
        .table("todos")
        .where({ GUID: task.TodoGUID })
        .first();
      const tasks = todo.TodoTasks.concat(task);
      todo.TodoTasks = tasks;
      await db.table("todos").put(todo);
      await apiService.post(urlForTasks, [task]);
      success();
      dispatch({ type: constants.ADDED_TASK, data: todo });
    } catch (error) {
      dispatch({
        type: constants.ADDING_TASK_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.ADDING_TASK: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.ADDED_TASK: {
      return {
        ...state,
        loading: false,
        todos: state.todos.map((todo) => {
          if (todo.GUID === action.data.GUID) {
            todo = action.data;
            return todo;
          }
          return todo;
        }),
      };
    }
    case constants.ADDING_TASK_ERROR: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
}
