// import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import uuid from "uuid";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import { sortArray } from "../../common/helpers";
import { IMAGES } from "../../constant";
import {
  DocsComponent,
  Settings,
} from "../../page/files/editors/DocsComponent";
import { PDFComponent } from "../../page/files/editors/PDFComponent";
import { SpreadSheets } from "../../page/files/editors/SpreadSheets";
import { getBasicInfo } from "../../utils";
import { handleCloseEmail } from "../../utils/closeMailModal";
import EmptyStateMoreDetail from "../common/EmptyStateMoreDetail";
import { ModalComponent } from "../common/modal";
import SharingHub from "../common/sharingHub";
import EmailModal from "../hotel/emailModal";
import MoreDetailsCreationModal from "./MoreDetailsCreationModal/MoreDetailsCreationModal";
import MoreDetailsNavigation from "./MoreDetailsNavigation";
import { get } from "jquery";
import { ToastNotificationSuccess } from "../common";

/*

  <SharingHub 
                    toggle={toggleShare}
                    ParentSectionGUID ={props.event.GUID}
                    AccountGUID={state.AccountGUID.value}
                    name = {state.UI.Current.Title !== undefined? state.UI.Current.Title.value : ""}
                    sectionType="MoreDetails"
                    sectionGUID={state.UI.Current.GUID !== undefined? state.UI.Current.GUID.value: ""}
                    />
*/
const MoreDetails = (props) => {
  const [state, setState] = useState({
    Event: {},
    ProductionItems: [],
    UI: {
      IsLoading: false,
      Current: null,
      ShareViaEmail: false,
      ShareModal: false,
      CreateNewFile: false,
      fileExtension: "",
    },
    AccountGUID: "",
  });

  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const documentEditorRef = useRef(null);
  const spreadsheetRef = useRef(null);

  useEffect(() => {
    //Load Production items localy
    bindData();
    var basicInfo = getBasicInfo();
    setState((prev) => {
      return { ...prev, AccountGUID: basicInfo.GUID };
    });
    //      debugger;
    bindDataRemote();
    /* const remoteData = await api.get(
           `/EventMoreDetails/List?EventGUID=${this.props.event.GUID}`
         );*/
  }, []);

  const bindData = async () => {
    let localData = await db
      .table("productions")
      .where({ EventGUID: props.event.GUID })
      .toArray();
    localData = localData.filter((x) => x.Deleted === null);
    localData = sortArray(localData, "Title");

    if (localData.length > 0) {
      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            Current: localData?.[0],
            fileExtension:
              localData?.[0]?.Path?.split(".")[
                localData?.[0]?.Path?.split(".").length - 1
              ],
          },
        };
      });
    }
    setState((prev) => {
      return { ...prev, ProductionItems: localData };
    });
    console.log("records", localData, props, state);
  };
  const bindDataRemote = async () => {
    setState((prev) => {
      return { ...prev, UI: { ...prev.UI, IsLoading: true } };
    });
    const remoteData = await apiService.get(
      `/EventMoreDetails/List?EventGUID=${props.event.GUID}`
    );
    setState((prev) => {
      return { ...prev, UI: { ...prev.UI, IsLoading: false } };
    });

    if (remoteData.data.status === "ok") {
      var records = remoteData.data.response;
      await db.table("productions").bulkPut(records);
      bindData();
    }
    console.log("Remote data", remoteData);
  };

  const saveRemote = async () => {
    setState((prev) => {
      return { ...prev, UI: { ...prev.UI, IsLoading: true } };
    });
    let query = state.UI.Current;
    let queryArray = [];
    queryArray.push(query);
    const remoteData = await api.post(`/EventMoreDetails/AddEdit`, queryArray);

    setState((prev) => {
      return { ...prev, UI: { ...prev.UI, IsLoading: false } };
    });
  };

  async function uploadFiles(file) {
    const fileUrl = "file/upload";
    let bodyData = new FormData();
    bodyData.append("path", file);

    try {
      const { data } = await apiService.post(fileUrl, bodyData);
      return data?.Response;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }

  const createNewFileOnServer = async (name) => {
    const file = new File([{}], `${name}.docx`, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    try {
      const filePath = await uploadFiles(file);
      return filePath;
    } catch (error) {
      console.error("Error creating new file on server:", error);
      throw error;
    }
  };
  const createFile = async (file, extension) => {
    Swal.fire({
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      title:
        file === "link" ? "Enter the link URL" : "Enter the name of the file",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Create",
      showLoaderOnConfirm: true,
      preConfirm: (name) => {
        if (name === "") {
          Swal.showValidationMessage("Name is required");
        }
        return name;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let Title = result.value;
        const filePath =
          file === "link"
            ? result.value
            : file
            ? await uploadFiles(file)
            : null;

        const pathToSave = filePath ? filePath : `newFile${extension}`;

        // const fileOnServer = await createNewFileOnServer(result.value);

        if (extension === "link") {
          await Swal.fire({
            imageUrl: IMAGES.LOGO_BLACK_P,
            imageWidth: 80,
            imageHeight: 80,
            title: "Enter Link Name",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Create",
            showLoaderOnConfirm: true,
            preConfirm: (name) => {
              if (name === "") {
                Swal.showValidationMessage("Name is required");
              }
              return name;
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(async (result) => {
            if (result.isConfirmed) {
              Title = result.value;
            }
          });
        }

        let newItem = {
          GUID: uuid.v4(),
          EventGUID: props.event.GUID,
          Title: Title,
          Deleted: null,
          Content: extension === "link" ? "link" : "no content",
          Created: moment().format("YYYY-MM-DDTHH:mm:ss"),
          Path: pathToSave,
        };

        await apiService.post(`/EventMoreDetails/AddEdit`, newItem);

        setState((prev) => {
          return {
            ...prev,
            UI: {
              ...prev.UI,
              Current: newItem,
              fileExtension:
                pathToSave?.split(".")[pathToSave?.split(".").length - 1],
            },
          };
        });

        //Add to the array
        let objs = state.ProductionItems;
        objs.push(newItem);

        setState((prev) => {
          return { ...prev, ProductionItems: objs };
        });

        setTimeout(() => {
          if (document.getElementById("name")) {
            document.getElementById("name").focus();
          }
        }, 1000);
      }
    });
  };

  const createFileFromTemplate = async (template) => {
    let newItem = {
      GUID: uuid.v4(),
      EventGUID: props.event.GUID,
      Title: template.Title,
      Deleted: null,
      Content: template.Content === "link" ? "link" : "no content",
      Created: moment().format("YYYY-MM-DDTHH:mm:ss"),
      Path: template.Path,
    };

    await apiService.post(`/EventMoreDetails/AddEdit`, newItem);

    setState((prev) => {
      return {
        ...prev,
        UI: {
          ...prev.UI,
          Current: newItem,
          fileExtension:
            template?.Path?.split(".")[template?.Path?.split(".").length - 1],
        },
      };
    });

    let objs = state.ProductionItems;
    objs.push(newItem);

    setState((prev) => {
      return { ...prev, ProductionItems: objs };
    });
    toggleCreateNewFile();
    ToastNotificationSuccess(Math.random(), "File created successfully");
  };

  const handleChangeName = (e) => {
    setState((prev) => {
      const title = e?.target?.value;
      return {
        ...prev,
        UI: { ...prev.UI, Current: { ...prev.UI.Current, Title: title } },
      };
    });
  };

  const SelectItem = (item) => {
    setState((prev) => {
      return {
        ...prev,
        UI: { ...prev.UI, Current: item },
      };
    });
  };
  const intl = useIntl();
  const DeleteItem = (item) => {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + item.Title + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        let query = state.UI.Current;
        let queryArray = [];
        query.Deleted = moment().format("YYYY-MM-DDTHH:mm:ss");
        queryArray.push(query);

        const remoteData = await api.post(
          `/EventMoreDetails/AddEdit`,
          queryArray
        );

        let objs = state.ProductionItems;
        let filtered = objs.filter((x) => x.GUID !== item.GUID);

        setState((prev) => {
          return {
            ...prev,
            ProductionItems: filtered,
            UI: { ...prev.UI, Current: filtered[0] },
          };
        });
      }
    });
  };

  const toggleShareEmail = () => {
    setState((prev) => {
      return {
        ...prev,
        UI: { ...prev.UI, ShareViaEmail: !prev.UI.ShareViaEmail },
      };
    });
  };

  const toggleCreateNewFile = () => {
    setState((prev) => {
      return {
        ...prev,
        UI: { ...prev.UI, CreateNewFile: !prev.UI.CreateNewFile },
      };
    });
  };
  const toggleShare = () => {
    setState((prev) => {
      return {
        ...prev,
        UI: { ...prev.UI, ShareModal: !prev.UI.ShareModal },
      };
    });
  };

  const handleSaveFile = async () => {
    if (state.UI.fileExtension === "xlsx" || state.UI.fileExtension === "xls") {
      let spreadsheet = spreadsheetRef?.current;
      spreadsheet?.save({
        url: `${Settings.serviceApiUrl}file/SaveExcel`,
        fileName: state?.UI?.Current?.Title,
        saveType: "Xlsx",
      });
    } else {
      await handleDocumentChange();
    }
  };

  const handleDocumentChange = async (blob) => {
    // const response = await documentEditorRef.current.documentEditor.saveAsBlob(
    //   "Sfdt"
    // );
    // const text = await response.text();
    let query = state.UI.Current;
    let queryArray = [];
    queryArray.push(query);

    if (blob) {
      const file = new File([blob], `${state.UI.Current.Path}`, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const filePath = await uploadFiles(file);
      console.log({ filePath });
      query.Path = filePath;
    }
    await apiService.post(`/EventMoreDetails/AddEdit`, query);
  };

  const handleExport = async (fileName, extension) => {
    await documentEditorRef.current.documentEditor.save(fileName, extension);
  };

  const [showSideBar, setShowSideBar] = useState(true);

  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  const loadTheFile = () => {
    let http = new XMLHttpRequest();
    let fullPath = `${Settings.appApiUrl}files/${props?.file?.Path}`;
    //add your url in which you want to open document inside the ""
    let content = { fileUrl: fullPath };
    let baseurl = Settings.serviceApiUrl + "file/OpenWord";

    http.open("POST", baseurl, true);
    http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200 || http.status === 304) {
          //open the SFDT text in Document Editor
          if (!documentEditorRef) {
            return;
          }
          documentEditorRef.documentEditor.open(http.responseText);
        }
      }
    };
    http.send(JSON.stringify(content));
  };

  const handleSaveAsTemplate = async () => {
    setIsSavingTemplate(true);
    try {
      const newTemplate = {
        GUID: state?.UI?.Current?.GUID,
        EventGUID: state?.UI?.Current?.EventGUID,
        Title: state?.UI?.Current?.Title,
        Content: state?.UI?.Current?.Content,
        Color: null,
        VisibleBy: null,
        Path: state?.UI?.Current?.Path,
        Shared: null,
        CreatorAccountGUID: getBasicInfo().GUID,
      };
      await apiService.post(`/EventMoreDetails/AddEditTemplate`, newTemplate);
      ToastNotificationSuccess(1, "Template saved successfully");
    } catch (error) {
      console.error("Error saving template:", error);
    } finally {
      setIsSavingTemplate(false);
    }
  };

  return (
    <div className={props.containerClass ?? "moreDetailsContainer"}>
      <ModalComponent
        toggle={() => handleCloseEmail(toggleShareEmail)}
        modal={state.UI.ShareViaEmail}
        childeren={
          <EmailModal
            subject={
              state?.UI?.Current?.Title !== undefined
                ? state?.UI?.Current?.Title
                : ""
            }
            content={
              state?.UI?.Current?.Content !== undefined
                ? state?.UI?.Current?.Content
                : ""
            }
            //  sendEmail={sendEmail}
            fromSection="PRODUCTIONS"
            //event={event}
            toggle={() => toggleShareEmail()}
          />
        }
      />

      <MoreDetailsCreationModal
        toggleModal={toggleCreateNewFile}
        showModal={state.UI.CreateNewFile}
        handleAddDocument={(file, extension) => {
          createFile(file, extension);
          toggleCreateNewFile();
        }}
        createFileFromTemplate={createFileFromTemplate}
        handleAddSheet={() => {
          // createFile();
          toggleCreateNewFile();
        }}
        handleAddPDF={() => {
          // createFile();
          toggleCreateNewFile();
        }}
        handleUploadFile={() => {
          // createFile();
          toggleCreateNewFile();
        }}
      />
      {/*       
      <ModalComponent
        toggle={() => {
          toggleCreateNewFile();
        }}
        modalClassName="position-absolute top-0 left-0"
        modal={state.UI.CreateNewFile}
        childeren={
          <div
            className="p-2 bg-white "
            style={{
              height: "80vh",
              width: "100vw",
              margin: "auto",
            }}
          >
            <div className="d-flex justify-content-between">
              <h6>Add a new attachment</h6>
              <button
                className="btn btn-primary"
                onClick={() => {
                  toggleCreateNewFile();
                }}
              >
                Close
              </button>
            </div>
          </div>
        }
      /> */}

      <ModalComponent
        modal={state.UI.ShareModal}
        toggle={() => toggleShare()}
        childeren={
          <SharingHub
            ViaEmail
            ViaLink
            ViaClipboard
            Item={state.UI.Current}
            toggle={toggleShare}
            ParentSectionGUID={props.event.GUID}
            AccountGUID={state.AccountGUID}
            name={
              state?.UI?.Current?.Title !== undefined
                ? state?.UI?.Current?.Title
                : ""
            }
            Type="MOREDETAILS"
            sectionGUID={
              state?.UI?.Current?.GUID !== undefined
                ? state?.UI?.Current?.GUID
                : ""
            }
          />
        }
      />

      <div className="event-page moreDetails" style={{ overflow: "revert" }}>
        <div
          className="body p-2 d-flex"
          style={{
            gap: 10,
            height: "100%",
          }}
        >
          <MoreDetailsNavigation
            parentState={state}
            showSideBar={showSideBar}
            setParentState={setState}
            createFile={toggleCreateNewFile}
            SelectItem={SelectItem}
            DeleteItem={DeleteItem}
            saveRemote={saveRemote}
            toggleSideBar={toggleSideBar}
          />

          {state.ProductionItems.length > 0 && state.UI.Current !== null ? (
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: showSideBar ? "calc(100% - 360px)" : "100%",
                height: "100%",
              }}
            >
              {!showSideBar && (
                <button
                  title="Show Events"
                  className="btn btn-primary border-0 position-absolute event-sidebar-btn"
                  onClick={toggleSideBar}
                  style={{ zIndex: "50", left: "-20px", top: "130px" }}
                >
                  <i
                    className="fas fa-angle-right"
                    style={{ marginLeft: "13px" }}
                  ></i>
                </button>
              )}
              <div className="w-100 px-0 h-100">
                <div className="card w-100 shadow-sm h-100">
                  <div className="d-flex align-items-center p-2 w-100 justify-content-between">
                    <div className="d-flex align-items-center w-100">
                      <h6 className="">{state?.UI?.Current?.Title}</h6>
                    </div>
                    <div
                      className="d-flex align-items-center w-50 justify-content-end"
                      style={{ gap: 4 }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={handleSaveFile}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{
                          width: "165px",
                        }}
                        disabled={isSavingTemplate}
                        onClick={() => handleSaveAsTemplate()}
                      >
                        {isSavingTemplate && (
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                            height="15px"
                            width="15px"
                          ></span>
                        )}
                        Save as Template
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleExport("New File", "Docx")}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                  <div
                    className={props.noteClass ?? "card-body containerEditor"}
                    style={
                      props.noteClass
                        ? {
                            height: "calc(100dvh - 220px)",
                            padding: "0px",
                            width: "100%",
                          }
                        : { height: "79vh", width: "100%" }
                    }
                  >
                    {state.UI.fileExtension === "docx" ||
                    state.UI.fileExtension === "doc" ? (
                      <DocsComponent
                        hideHeader={true}
                        file={state.UI.Current}
                        modal={false}
                        onSave={handleDocumentChange}
                      />
                    ) : state.UI.fileExtension === "xlsx" ||
                      state.UI.fileExtension === "xls" ? (
                      <SpreadSheets
                        hideHeader={true}
                        forwardRef={spreadsheetRef}
                        file={state.UI.Current}
                        modal={false}
                      />
                    ) : state.UI.fileExtension === "pdf" ? (
                      <PDFComponent
                        hideHeader={true}
                        file={state.UI.Current}
                        modal={false}
                      />
                    ) : state.UI.Current.Content === "link" ? (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <i className="fas fa-globe fa-5x text-black-50  mb-2"></i>
                        <a
                          className="btn btn-primary"
                          href={state.UI.Current.Path}
                          target="_blank"
                        >
                          Open Link
                        </a>
                      </div>
                    ) : (
                      <div>
                        <textarea
                          className="form-control"
                          value={
                            state.UI.Current.Content === null
                              ? ""
                              : state.UI.Current.Content
                          }
                          onChange={(e) => {
                            setState((prev) => {
                              return {
                                ...prev,
                                UI: {
                                  ...prev.UI,
                                  Current: {
                                    ...prev.UI.Current,
                                    Content: e.target.value,
                                  },
                                },
                              };
                            });
                          }}
                          onBlur={async () => {
                            await saveRemote();
                          }}
                          style={{ height: "calc(100% - 40px)" }}
                        ></textarea>
                      </div>
                    )}
                    {/* <Editor
                      apiKey={TINY_MCE_KEY}
                      value={
                        state.UI.Current.Content === null
                          ? ""
                          : state.UI.Current.Content
                      }
                      onEditorChange={(content) => {
                        console.log("content of the editor", content);
                        setState((prev) => {
                          return {
                            ...prev,
                            UI: {
                              ...prev.UI,
                              Current: { ...prev.UI.Current, Content: content },
                            },
                          };
                        });
                      }}
                      onBlur={async () => {
                        await saveRemote();
                      }}
                      init={{
                        placeholder: "",
                        images_upload_url:
                          "https://app.plnify.com/api/Events/UploadImageTINYMCE",
                        menubar: true,
                        plugins: [
                          " advlist autolink lists link image fullscreen",
                          "charmap print preview anchor help",
                          "searchreplace visualblocks code",
                          "insertdatetime media table  wordcount",
                          "template textcolor powerpaste code print",
                        ],
                        // plugins: [
                        //     "forecolorpicker advlist autolink lists link image fullscreen",
                        //     "charmap print preview anchor help",
                        //     "searchreplace visualblocks code",
                        //     "insertdatetime media table advtable  wordcount  textcolor  powerpaste",
                        // ],
                        paste_merge_formats: false,
                        powerpaste_allow_local_images: true,
                        toolbar:
                          "print undo redo | fontsizeselect forecolor backcolor | template | bold italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent | help | image table link media code removeformat",
                        // toolbar:
                        //     "undo redo | fontselect fontsizeselect formatselect | bold italic underline forecolor backcolor | \
                        // alignleft aligncenter alignright | \
                        // table  | \
                        // bullist numlist outdent indent | image link media | removeformat help",

                        content_style:
                          "p{font-size:14px !important;} height:100%",
                        branding: false,
                        visual: false,
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mx-2 align-items-center">
              <div className="w-100 align-items-center d-flex imtes">
                <EmptyStateMoreDetail
                  image={IMAGES.MORE_DETAIL_IMG}
                  showSideBar={showSideBar}
                  cta="Add a new attachment"
                  text="No content added yet"
                  handleAdd={toggleCreateNewFile}
                  toggleSideBar={toggleSideBar}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoreDetails;
