import React, { useEffect, useRef } from "react";
import { Event } from "./event";
import { IMAGES } from "../../constant";
import InfiniteScroll from "react-infinite-scroll-component";

import { Scrollbars } from "react-custom-scrollbars";
import {
  Menu,
  Item,
  useContextMenu,
  Separator,
  Submenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "f19a77bc-38a6-11eb-adc1-0242ac120002";

export const EventList = (props) => {
  let {
    events,
    deleteEvent,
    fetchAndSetVisibleBy,
    toggleExportMail,
    toggle,
    toggleAddEvent,
    editEvent,
  } = props;
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  let containerScroller = useRef();
  useEffect(() => {
    //navigating
    scrollNearTo();
  });
  let scrollNearTo = () => {
    let element = containerScroller.current;

    if (element === null || element === undefined) {
      return;
    }
    let elementEstimatedHeight = 58;
    //find first index of dates in the array of events
    let theIndex = findWithAttr(events);
    if (theIndex > 0) {
      //check if we have more than 10 events ahead
      if (events.length - theIndex > 10) {
        element.scrollTop(elementEstimatedHeight * (theIndex + 10));
      } else if (theIndex - 5 > 0) {
        //check if we have 10 events below so we can try to center
        element.scrollTop(elementEstimatedHeight * (theIndex + 5));
      } else {
        element.scrollTop(elementEstimatedHeight * theIndex);
      }
    }
  };
  let findWithAttr = (array) => {
    let today = new Date();

    for (var i = 0; i < array.length; i += 1) {
      var record = array[i];
      var parsedDate = new Date(record.Date);
      if (
        parsedDate.getFullYear() === today.getFullYear() &&
        parsedDate.getMonth() == today.getMonth()
      ) {
        return i;
      }
    }
    return -1;
  };

  function handleItemClick({ event, props }) {
    switch (event.currentTarget.id) {
      case "delete":
        deleteEvent(props.event);
        break;
      case "visible":
        fetchAndSetVisibleBy(props.event);
        break;
      case "confirmed":
        editEvent(props.event, "Confirmed");
        break;
      case "tentative":
        editEvent(props.event, "Tentative/Hold");
        break;
      case "postponed":
        editEvent(props.event, "Postponed");
        break;
      case "cancelled":
        editEvent(props.event, "Cancelled");
        break;
      case "export":
        toggleExportMail(props.event);
        break;
    }
  }

  function displayMenu(e, event) {
    show(e, { props: { event } });
  }

  return (
    <div className="calendar-bottom">
      <Menu id={MENU_ID}>
        <Item id="export" onClick={handleItemClick}>
          Export Event
        </Item>
        <Separator />
        <Submenu label="Change status">
          <Item onClick={handleItemClick} id="confirmed">
            Confirmed
          </Item>
          <Item onClick={handleItemClick} id="postponed">
            Postponed
          </Item>
          <Item onClick={handleItemClick} id="tentative">
            Tentative/Hold
          </Item>
          <Item onClick={handleItemClick} id="cancelled">
            Cancelled
          </Item>
        </Submenu>
        <Separator />
        <Item id="visible" onClick={handleItemClick}>
          Visibles By
        </Item>
        <Separator />
        <Item id="delete" onClick={handleItemClick}>
          <span className="text-danger">Delete</span>
        </Item>
      </Menu>
      <div className="calendar-bottom-inside">
        <div className="clendar-section">
          {events.length > 0 ? (
            <Scrollbars
              style={{ width: "100%", height: "calc(100%)" }}
              ref={containerScroller}
            >
              {events.map((event) => (
                <div
                  className="card my-1 shadow-sm"
                  key={event.GUID}
                  onContextMenu={(e) => displayMenu(e, event)}
                >
                  <div className="card-body p-0">
                    <Event event={event} toggle={toggle} hasDragAndDrop />
                  </div>
                </div>
              ))}
            </Scrollbars>
          ) : (
            <div className="d-flex align-items-center flex-1 justify-content-center flex-column h-100">
              <div className="row justify-content-center ">
                <div className="text-center">
                  <img
                    src={IMAGES.CALENDAR_LIST_PLACEHOLDER}
                    className="img-fluid mx-auto mt-0 emptyStateStyle"
                  />
                </div>
                <div className="col-12 text-center">
                  <h6 className="mb-4">
                    You have no events yet, click below to create your first one
                  </h6>
                  <button
                    onClick={() => toggleAddEvent()}
                    type="button"
                    className="btn btn-primary btn-rounded"
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
