import React from "react";
import { IMAGES } from "../../../constant";

const SuccessPopup = (props) => {
  const { toggle, email } = props;
  return (
    <div className="before-login">
      <div className="inner-page">
        <div className="emailsent-page">
          <div className="emailsent-page-content">
            <div className="emailsent-img">
              <img
                src={IMAGES.SUCCESS_IMAGE}
                alt="sentmail"
                width="103"
                height="100"
              />
            </div>
            <div className="emailsent-text">
              <h1>Email sent successfully!</h1>
              <p>{email}</p>
            </div>
            <div className="emailsent-pg">
              <p>
                To get back into your account, follow the instructions we’ve
                sent to your provided email address.
              </p>
            </div>
            <div className="submit-button">
              <button
                type="submit"
                className="btn primary"
                id="reset"
                onClick={() => toggle()}
              >
                Back to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
