import {
  AccountBox,
  Airplane,
  Attachment,
  Bus,
  ClipboardList,
  Domain,
} from "mdi-material-ui";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import CalendarEventModal from "./CalendarEventModal";
import { MeetingEvent } from "./MeetingEvent";
import Swal from "sweetalert2";
import { sendEmail } from "../../page/common/redux/sendEmail";
import * as actions from "../../page/event/redux/actions";
import { getBasicInfo } from "../../utils";
import DragAndDrop from "../common/DragAndDrop";
import EmailModal from "../hotel/emailModal";
import { handleCloseEmail } from "../../utils/closeMailModal";
import { IMAGES } from "../../constant";

// getCurrentOrganizationLocal;

const CalendarEvent = (props) => {
  const events = useSelector((state) => state.calendar.calendarEvents);
  const user = getBasicInfo();
  const [exportType, setExportType] = useState("email");
  // const intl = useIntl();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showEventModal: false,
    showDetailsMeetingModal: false,
    showVisibleBy: false,
    showMailModal: false,
  });

  const toggleMeetingModal = () => {
    setState((prev) => ({
      ...prev,
      showDetailsMeetingModal: !state.showDetailsMeetingModal,
    }));
  };

  const toggleShowEventModal = () => {
    setState((prev) => ({ ...prev, showEventModal: !state.showEventModal }));
  };

  const { event } = props;

  const currentEvent = moment(event.Date).toDate();

  const sameDayEvents = events.filter(
    (event) => moment(event.Date).isSame(currentEvent, "day") === true
  );
  const sameDayEventsCount = sameDayEvents.length - 1;

  // const MENU_ID = "45648979_4654687";

  function toggleMailModal() {
    if (state.showMailModal)
      return setState((prev) => {
        return { ...prev, showMailModal: !prev.showMailModal };
      });

    Swal.fire({
      text: "How would you like to send this email?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "secondary",
      confirmButtonText: "Generate PDF",
      cancelButtonText: "As Body of Email",
    }).then((result) => {
      if (result.value) {
        setExportType("export");
        setState((prev) => {
          return { ...prev, showMailModal: !prev.showMailModal };
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setExportType("email");
        setState((prev) => {
          return { ...prev, showMailModal: !prev.showMailModal };
        });
      }
    });
  }

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const onChange = (e) => {
    let files = e?.target?.files || e;
    const arrFiles = Array.from(files);

    try {
      arrFiles.forEach((file) => {
        window.URL.createObjectURL(file);
        let attachment = {
          name: file.name,
          note: "",
          event: event.GUID,
          file: file,
        };
        dispatch(
          actions.addAttachment(
            event.GUID,
            attachment,
            () => {},
            () => dispatch(actions.getEvents(event.OrganizationGUID, user.GUID))
          )
        );
      });
      ToastNotificationSuccess(1, "Attachment succesfully created");
    } catch (error) {
      Swal.fire("Oops...", "Something went wrong!", "error");
    }
  };

  const handleDrop = (file) => {
    onChange(file);
  };

  return event.EventType === "Meeting" ? (
    <MeetingEvent
      event={event}
      toggleMeetingModal={toggleMeetingModal}
      state={state}
    />
  ) : (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        // handleShow(e);
        props.handleShow(e, event);
      }}
      onDragEnterCapture={handleDragIn}
    >
      <ModalComponent
        modal={state.showEventModal}
        toggle={toggleShowEventModal}
        childeren={
          <CalendarEventModal date={event.Date} events={sameDayEvents} />
        }
      />
      <ModalComponent
        modal={state.showMailModal}
        toggle={() => handleCloseEmail(toggleMailModal, setExportType)}
        childeren={
          <EmailModal
            EventDetails={true}
            sendEmail={sendEmail}
            fromSection="EVENT_DETAILS"
            ExportType={exportType}
            setExportType={setExportType}
            event={event}
            toggle={() => toggleMailModal()}
          />
        }
      />

      <Link
        className="clean-link inner event-container relative"
        style={{
          opacity:
            moment(event.Date).month() < moment().month() &&
            moment(event.Date).year() <= moment().year()
              ? 0.5
              : 1,
        }}
        to={`/calendar/${event.GUID}`}
      >
        <DragAndDrop
          handleDragOut={handleDragOut}
          handleDropCalendarEvent={handleDrop}
          fontSize={18}
        >
          <div
            style={{
              ...showDrop.style,
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 999,
            }}
          ></div>
        </DragAndDrop>

        <span
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: 4,
            height: "100%",
            backgroundColor: event.EventGroup && event.EventGroup.Color,
          }}
        ></span>
        <span className={`event-status ${event.Status}`}></span>
        <div
          className={
            "event-title text-truncate" +
            (event.LastSync !== undefined && event.LastSync !== null
              ? ""
              : " text-muted")
          }
        >
          {event.Name}
        </div>
        <div className="event-location text-truncate">
          {event.VenueGUID && event.Venue
            ? `${event.Venue.City ? event.Venue.City + ", " : ""}${
                event.Venue.State ? event.Venue.State + ", " : ""
              }${event.Venue.Country ? event.Venue.Country : ""}`
            : `${event.City ? event.City + ", " : ""} ${
                event.State ? event.State + ", " : ""
              } ${event.Country ? event.Country : ""}`}
        </div>
        <div className="event-icons d-flex align-items-center ">
          {event.FlightsCount > 0 && (
            <div className="icon-section">
              <Airplane htmlColor="#FFF" />
            </div>
          )}
          {event.GroundCount > 0 && (
            <div className="icon-section">
              <Bus htmlColor="#FFF" />
            </div>
          )}
          {event.HotelsCount > 0 && (
            <div className="icon-section">
              <Domain htmlColor="#FFF" />
            </div>
          )}
          {event.ScheduleMasterCount > 0 && (
            <div className="icon-section">
              <ClipboardList htmlColor="#FFF" />
            </div>
          )}
          {event.ContactsCount > 0 && (
            <div className="icon-section">
              <AccountBox htmlColor="#FFF" />
            </div>
          )}
          {event.AttachmentsCount > 0 && (
            <div className="icon-section">
              <Attachment htmlColor="#FFF" />
            </div>
          )}
        </div>
      </Link>
      {sameDayEventsCount > 0 && (
        <button
          className="rbc-show-more-modal clean-link btn btn-block"
          onClick={() => toggleShowEventModal()}
        >
          + {sameDayEventsCount}
        </button>
      )}
    </div>
  );
};

export default CalendarEvent;
