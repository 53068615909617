import React, { useEffect, useState } from "react";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import { IMAGES } from "../../constant";
import { getOrganization } from "../../utils";
import TemplateItem from "./TemplateItem";
import TemplateActivity from "./TemplateActivity";

const TemplatesModal = (props) => {
  let { toggle, templates, createFromTemplate } = props;
  const [stateTemplate, setStateTemplate] = useState([]);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);

  const loadTemplatesRemote = async () => {
    let theOrganization = getOrganization();
    const request = await apiService.get(
      `/ToDo/ToDoTemplateList?OrganizationGUID=${theOrganization.GUID}`  
    );
    setStateTemplate(request.data);
  };

  const viewDetailsOftheTemplate = async (obj) => {
    setTemplateSelected(obj);
    setToggleDetails(!toggleDetails);
    if (!toggleDetails) {
      await loadTemplatesRemote();
    }
  };

  const updateTemplate = async (selected) => {

    setTemplateSelected(selected);
    await loadTemplatesRemote();
  };
  useEffect(() => {
    loadTemplatesRemote();
  }, []);

  return (
    <div className="organization-transparent">
      <div className="event-page text-dark">
        <div className="event-expennd-section ">
          <div className="text-head">
            <div className="text-left">
              <h1>Templates</h1>
            </div>
            <div className="text-right">
              {toggleDetails ? (
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => viewDetailsOftheTemplate(null)}
                >
                  Back to list
                </button>
              ) : null}
            </div>
            <div className="close-icon d-none d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="event-inside">
            {toggleDetails ? (
              <>
                <div className="my-4">
                  {templateSelected?.ScheduleDetailsTemplates?.filter(
                    (a) => a.Deleted === null
                  )
                    .sort((a, b) => {
                      if (a.Date > b.Date) return 1;
                      if (a.Date < b.Date) return -1;
                    })
                    .map((activity, index) => (
                      <TemplateActivity
                        key={index}
                        activity={activity}
                        scheduleMaster={templateSelected}
                        updateTemplate={updateTemplate}
                      />
                      //   <Activity
                      //     key={activity.GUID}
                      //     activity={activity}
                      // //    deleteActivity={deleteActivity}
                      //   //  event={event.GUID}
                      //     //addActivity={addActivity}
                      //     //scheduleMaster={sideBarItemData}
                      //     editActivity={editActivity}
                      //     refreshSchedules={refreshSchedules}
                      //   />
                    ))}
                </div>
                <div>
                  <button
                    className="btn btn-block btn-primary mt-2"
                    onClick={() =>
                      createFromTemplate(templateSelected, () => toggle())
                    }
                  >
                    Import Template to the Event
                  </button>
                </div>
              </>
            ) : (
              <>
                {stateTemplate.length > 0 ? (
                  stateTemplate
                    ?.filter((t) => t?.Name && t?.Deleted === null)
                    .map((t) => (
                      <TemplateItem
                        key={t.GUID}
                        template={t}
                        onClick={(e) => {
                          e.preventDefault();
                          viewDetailsOftheTemplate(t);
                          //createFromTemplate(t, () => toggle());
                        }}
                        updateTemplate={updateTemplate}
                      />
                    ))
                ) : (
                  <>No template yet</>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatesModal;
