import React, { useState } from "react";
import { IMAGES } from "../../constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Scrollbars } from "react-custom-scrollbars";
import { DropDownList, SimpleLoader } from "../common";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import Select from "react-select";
import { TYPES_VENUE } from "../../constant";
import { DebounceInput } from "react-debounce-input";
import * as actions from "../../page/event/redux/actions";
import {useIntl, FormattedMessage} from 'react-intl'

const EditVenueModal =(props)=> {
 const state = {
    countries: [],
    cities: [],
  };
  const intl = useIntl()
  const emptyCities = () => {};

 const emptyCountries = () => {};

  const getCities = () => {};

 const  getCountries = () => {};
 function setStateOrNotState(place, props) {
  if (place.split(",").length === 3) {
    props.setFieldValue("City", place.split(",")[0].trim());
    props.setFieldValue("State", place.split(",")[1].trim());
    props.setFieldValue("Country", place.split(",")[2].trim());
  } else {
    props.setFieldValue("City", place.split(",")[0].trim());
    props.setFieldValue("State", "");
    props.setFieldValue("Country", place.split(",")[1].trim());
  }
}
    const {
      toggle,
      venue,
      loading,
      venueToEdit,
      editMode,
      actions: { editVenue },
    } = props;
    const { cities, countries } = state;
    if (!venue) {
      return null;
    }
console.log("venue",props)
    return (
      <div className={"organization-transparent"}>
        <div className="event-page">
          <div className="event-expennd-section">
            <div className="text-head">
              <div className="text-left">
                <h1>{venueToEdit?  venueToEdit.Name: venue.Name}</h1>
              </div>
              <div className="close-icon ">
              <button
                onClick={() => toggle()}
                className="btn btn-secondary btn-sm float-right"
              >
              <FormattedMessage
  id="backSearchVenue"
  defaultMessage="Back to Search venue"/>
              </button>
            </div>
            </div>
            <div className="event-inside">
              <Formik
                onSubmit={(values) => {
                  editVenue(values, () => {
                    toggle();
                  });
                }}
                initialValues={editMode ? { ...venue } : { ...venue }}
              >
                {(props) => (
                  <Form
                    style={{ height: "calc(100vh - 70px)" }}
                    autoComplete="off"
                    className="pt-1"
                  >
                    <FormField fullWidth={true} label={<FormattedMessage
  id="firstName.signUp"
  defaultMessage="Name"/>}>
                      <Field className="form-control" type="text" name="Name" />
                      <ErrorMessage
                        className="error-message"
                        name="Name"
                        component="div"
                      />
                    </FormField>
                    <FormField fullWidth={true} label={<FormattedMessage
  id="Address"
  defaultMessage="Address"/>}>
                      <Field
                        className="form-control"
                        type="text"
                        name="Address"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Address"
                        component="div"
                      />
                    </FormField>
                    <FormField fullWidth={true} label={<FormattedMessage
  id="city.signUp"
  defaultMessage="City"/>}>
                      <DebounceInput
                        minLength={5}
                        debounceTimeout={300}
                        type="text"
                        value={props.values.City}
                        placeholder="City"
                        className="form-control"
                        name="City"
                        onChange={(e) => {
                          props.setFieldValue("City", e.currentTarget.value);
                          if (e.currentTarget.value.length > 3) {
                            getCities(e.currentTarget.value);
                          } else if (e.currentTarget.value.length < 3) {
                            emptyCities();
                          }
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="City"
                        component="div"
                      />
                      <DropDownList
                        searchList={cities}
                        setData={(id) => {
                          setStateOrNotState(cities[id].Address, props);
                          emptyCities();
                        }}
                        displayValue={"Address"}
                      />
                    </FormField>
                    <div className="row mx-0">
                      <FormField
                        additionalClasses="px-0 pr-1"
                        fullWidth={false}
                        label={<FormattedMessage
                          id="state"
                          defaultMessage="State"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="State"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="State"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        additionalClasses="px-0 pl-1"
                        fullWidth={false}
                        label={<FormattedMessage
                          id="zipCode"
                          defaultMessage="Zip Code"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="ZipCode"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="ZipCode"
                          component="div"
                        />
                      </FormField>
                    </div>

                    <FormField fullWidth={true} label={<FormattedMessage
  id="country.signUp"
  defaultMessage="Country"/>}>
                      <DebounceInput
                        minLength={5}
                        debounceTimeout={300}
                        type="text"
                        value={props.values.Country}
                        placeholder="Country"
                        className="form-control"
                        name="Country"
                        onChange={(e) => {
                          if (e.currentTarget.value.length > 3) {
                            getCountries(e.currentTarget.value);
                          } else if (e.currentTarget.value.length < 3) {
                            emptyCountries();
                          }
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Country"
                        component="div"
                      />
                      <DropDownList
                        searchList={state.countries}
                        setData={(id) => {
                          props.setFieldValue("Country", countries[id].Name);
                          emptyCountries();
                        }}
                        displayValue={"Name"}
                      />
                    </FormField>
                    <div className="row mx-0">
                      <FormField
                        fullWidth={false}
                        label={<FormattedMessage
                          id="capacity"
                          defaultMessage="Capacity"/>}
                        additionalClasses="px-0 pr-1 mt-3"
                      >
                        <Field
                          autoComplete="off"
                          className="form-control"
                          type="number"
                          name="Capacity"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Capacity"
                          component="div"
                        />
                      </FormField>
                      <div
                        style={{ position: "relative", top: 2 }}
                        className="mb-3 border-bottom col-6 px-0 pl-1"
                      >
                        <label><FormattedMessage
  id="type"
  defaultMessage="Type"/></label>
                        <Select
                          placeholder={
                            TYPES_VENUE
                              ? TYPES_VENUE.find((o) => o === props.values.Type)
                              : intl.formatMessage({id:"selectVenueType"})
                          }
                          value={
                            TYPES_VENUE
                              ? TYPES_VENUE.find((o) => o === props.values.Type)
                              : ""
                          }
                          onChange={(value) => {
                            props.setFieldValue("Type", value.value);
                          }}
                          options={TYPES_VENUE.map((type) => ({
                            value: type,
                            label: type,
                          }))}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Type"
                          component="div"
                        />
                      </div>
                    </div>

                    <div>
                      {loading ? (
                        <SimpleLoader loading={loading} />
                      ) : (
                        <button type="submit" className=" btn primary block">
            <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }


const mapStateToProps = (state) => ({
  venue: state.event.eventVenue,
  loading: state.event.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditVenueModal);
