import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import api from "../../../common/api";
import PropTypes from "prop-types";

import { DropDownList, Loader } from "../../../components/common";
import FormField from "../../../components/form/formField";
import { IMAGES, ROUTES } from "../../../constant";
import { signUpSchema } from "../../../utils/validationsSchemas";
import { TextInput } from "../../../components";
import { WindowShutter } from "mdi-material-ui";

export const SignUpForm = (props) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const [existingEmail, setExistingEmail] = useState(false);
  const {
    onClickNavigation,
    loading,
    togglePassword,
    showPassword,
    toggleTermsCheck,
    termsAndConditionCheck,
    termsAndConditionError,
    isElectron,
    signUp,
    getCities,
    getCountrys,
    cities,
    countries,
    emptyCities,
    emptyCountries,
    login,
    history,
  } = props;

  const searchExistingEmail = useDebouncedCallback(async (email) => {
    const request = await api.get(`/user/exist?email=${email}`);
    setExistingEmail(request.data);
  }, 500);

  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("city", place.split(",")[0].trim());
      props.setFieldValue("state", place.split(",")[1].trim());
      props.setFieldValue("country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("city", place.split(",")[0].trim());
      props.setFieldValue("state", "");
      props.setFieldValue("country", place.split(",")[1].trim());
    }
  }

  return (
    <div className="main-container">
      <div className="signup-background">
        <Loader loading={loading} />
        <div className="signup-content">
          <div className="inside-content">
            <div className="row">
              <div className="col-md-12">
                <div className="signup-right">
                  <div className="signup-right-bg">
                    <div className="right-content">
                      <div className="head-section">
                        <div className="mr-3">
                          <img src={IMAGES.LOGO_GRADIENT} width={56} />
                        </div>
                        <div>
                          <h1>
                            <FormattedMessage
                              id="create.signUp"
                              defaultMessage="Create an Account"
                            />
                          </h1>
                          <p>
                            <FormattedMessage
                              id="slogan.signUp"
                              defaultMessage="Fill out the below details to set up your account."
                            />
                          </p>
                        </div>
                      </div>
                      <Formik
                        validateOnChange={false}
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          email: "",
                          confirmEmail: "",
                          password: "",
                          confirmPassword: "",
                          city: "",
                          country: "",
                          state: "",
                          phoneNumber: "",
                          jobTitle: "",
                        }}
                        onSubmit={(values) => {
                          signUp(values, () =>
                            login(values.email, values.password, () =>
                              history.push("/onboarding")
                            )
                          );
                        }}
                        validationSchema={signUpSchema}
                      >
                        {(props) => (
                          <Form autoComplete="off">
                            <div className="row">
                              <FormField
                                additionalClasses="px-0 pr-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="firstName.signUp"
                                    defaultMessage="First name"
                                  />
                                }
                              >
                                <Field
                                  autoComplete="off"
                                  className="form-control"
                                  type="text"
                                  name="firstName"
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="firstName"
                                  component="div"
                                />
                              </FormField>
                              <FormField
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="lastName.signUp"
                                    defaultMessage="Last name"
                                  />
                                }
                                additionalClasses="px-0 pl-1"
                              >
                                <Field
                                  autoComplete="off"
                                  className="form-control"
                                  type="text"
                                  name="lastName"
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="lastName"
                                  component="div"
                                />
                              </FormField>
                            </div>

                            <FormField fullWidth={true} label="Email">
                              <Field
                                className="form-control"
                                type="email"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => {
                                  props.setFieldValue("email", e.target.value);
                                  searchExistingEmail.callback(e.target.value);
                                }}
                              />
                              <ErrorMessage
                                className="error-message"
                                component="div"
                                name="email"
                              />
                              {existingEmail ? (
                                <div className="error-message">
                                  <FormattedMessage
                                    id="thisEmailExists.signUp"
                                    defaultMessage="This email Exists"
                                  />
                                </div>
                              ) : null}
                            </FormField>
                            <FormField
                              fullWidth={true}
                              label={
                                <FormattedMessage
                                  id="confirmEmailAddress.signUp"
                                  defaultMessage="Confirm Email address"
                                />
                              }
                            >
                              <Field
                                className="form-control"
                                type="email"
                                name="confirmEmail"
                                autoComplete="off"
                              />
                              <ErrorMessage
                                className="error-message"
                                name="confirmEmail"
                                component="div"
                              />
                            </FormField>
                            <div className="row">
                              <FormField
                                additionalClasses="px-0 pr-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="password.signUp"
                                    defaultMessage="Password"
                                  />
                                }
                              >
                                <Field
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  id="password"
                                  autoComplete="off"
                                  component={TextInput}
                                  onBlur={() =>
                                    console.log(props.validateField("password"))
                                  }
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="password"
                                  component="div"
                                />
                              </FormField>
                              <FormField
                                additionalClasses="px-0  pl-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="passwordConfirm.signUp"
                                    defaultMessage="Confirm password"
                                  />
                                }
                              >
                                <Field
                                  autoComplete="off"
                                  name="confirmPassword"
                                  type={showPassword ? "text" : "password"}
                                  id={"confirmPassword"}
                                  component={TextInput}
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="confirmPassword"
                                  component="div"
                                />
                              </FormField>
                            </div>
                            <div className="row">
                              <FormField
                                additionalClasses="px-0 pr-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="phoneNumber.signUp"
                                    defaultMessage="Phone Number (Optional)"
                                  />
                                }
                              >
                                <Field
                                  className="form-control"
                                  autoComplete="off"
                                  name="phoneNumber"
                                  id="phoneNumber"
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="phoneNumber"
                                  component="div"
                                />
                              </FormField>
                              <FormField
                                additionalClasses="px-0 pl-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="jobTitle.signUp"
                                    defaultMessage="Job Title (Optional)"
                                  />
                                }
                              >
                                <Field
                                  className="form-control"
                                  autoComplete="off"
                                  name="jobTitle"
                                  type={"text"}
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="jobTitle"
                                  component="div"
                                />
                              </FormField>
                            </div>
                            <div className="row">
                              <FormField
                                additionalClasses="px-0 pr-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="city.signUp"
                                    defaultMessage="City"
                                  />
                                }
                              >
                                <Field
                                  autoComplete="off"
                                  className="form-control"
                                  name="city"
                                  id="city"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "city",
                                      e.currentTarget.value
                                    );
                                    if (e.currentTarget.value.length > 3) {
                                      getCities(e.currentTarget.value);
                                    } else if (
                                      e.currentTarget.value.length < 3
                                    ) {
                                      emptyCities();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="city"
                                  component="div"
                                />
                                <DropDownList
                                  searchList={cities}
                                  setData={(id) => {
                                    setStateOrNotState(
                                      cities[id].Address,
                                      props
                                    );
                                    emptyCities();
                                  }}
                                  displayValue={"Address"}
                                />
                              </FormField>
                              <FormField
                                additionalClasses="px-0 pl-1"
                                fullWidth={
                                  screenSize?.width > 768 ? false : true
                                }
                                label={
                                  <FormattedMessage
                                    id="state.signUp"
                                    defaultMessage="State"
                                  />
                                }
                              >
                                <Field
                                  autoComplete="off"
                                  className="form-control"
                                  name="state"
                                  id="state"
                                />
                                <ErrorMessage
                                  className="error-message"
                                  name="state"
                                  component="div"
                                />
                              </FormField>
                            </div>
                            <FormField
                              fullWidth={true}
                              label={
                                <FormattedMessage
                                  id="country.signUp"
                                  defaultMessage="Country"
                                />
                              }
                            >
                              <Field
                                autoComplete="off"
                                className="form-control"
                                type="text"
                                name="country"
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "country",
                                    e.currentTarget.value
                                  );
                                  if (e.currentTarget.value.length > 3) {
                                    getCountrys(e.currentTarget.value);
                                  } else if (e.currentTarget.value.length < 3) {
                                    emptyCountries();
                                  }
                                }}
                              />
                              <ErrorMessage
                                className="error-message"
                                name="country"
                                component="div"
                              />
                              <DropDownList
                                searchList={countries}
                                setData={(id) => {
                                  props.setFieldValue(
                                    "country",
                                    countries[id].Name
                                  );
                                  emptyCountries();
                                }}
                                displayValue={"Name"}
                              />
                            </FormField>
                            <div className="agree-section">
                              <div className="input-left">
                                <div className="radio">
                                  <button
                                    className="btn btn-sm"
                                    type="button"
                                    onClick={toggleTermsCheck}
                                  >
                                    {termsAndConditionCheck ? (
                                      <i className="fas fa-check-square"></i>
                                    ) : (
                                      <i className="far fa-square"></i>
                                    )}
                                  </button>

                                  <label
                                    hrmlfor="remember"
                                    className="remember-text"
                                  >
                                    <FormattedMessage
                                      id="agree.signUp"
                                      defaultMessage="I agree to Plnify’s"
                                    />
                                    <span
                                      onClick={() =>
                                        isElectron
                                          ? onClickNavigation(
                                              ROUTES.TERMS_AND_CONDITION
                                            )
                                          : window.open(
                                              "https://plnify.com/terms-of-use/",
                                              "_blank"
                                            )
                                      }
                                    >
                                      {" "}
                                      <FormattedMessage
                                        id="terms.signUp"
                                        defaultMessage="Terms of Use"
                                      />{" "}
                                    </span>{" "}
                                    <FormattedMessage
                                      id="and.signUp"
                                      defaultMessage="and"
                                    />
                                    <span
                                      onClick={() =>
                                        isElectron
                                          ? onClickNavigation(
                                              ROUTES.SECURITY_AND_PRIVACY
                                            )
                                          : window.open(
                                              "https://plnify.com/privacy-policy/",
                                              "_blank"
                                            )
                                      }
                                    >
                                      {" "}
                                      <FormattedMessage
                                        id="securityPrivacy.signUp"
                                        defaultMessage="Security & Privacy"
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="invalid-feedback">
                                  {termsAndConditionError}
                                </div>
                              </div>
                            </div>
                            <div className="submit-button">
                              <button
                                type="submit"
                                className="btn primary"
                                disabled={
                                  !termsAndConditionCheck ||
                                  existingEmail ||
                                  !props.isValid
                                }
                              >
                                <FormattedMessage
                                  id="sign.up"
                                  defaultMessage="Sign Up"
                                />
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="signup-footer">
                    <div className="footer-inside">
                      <div
                        className="footer-content"
                        onClick={() => onClickNavigation(ROUTES.LOGIN)}
                      >
                        <p>
                          <FormattedMessage
                            id="haveAccount.signUp"
                            defaultMessage="Already have an account?"
                          />
                        </p>
                        <span>
                          <FormattedMessage
                            id="log.in"
                            defaultMessage="Log in"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpForm.propTypes = {
  firstName: PropTypes.string,
  firstNameError: PropTypes.string,
  lastName: PropTypes.string,
  lastNameError: PropTypes.string,
  email: PropTypes.string,
  emailError: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneNumberError: PropTypes.string,
  jobTitle: PropTypes.string,
  password: PropTypes.string,
  passwordError: PropTypes.string,
  city: PropTypes.string,
  cityError: PropTypes.string,
  countryState: PropTypes.string,
  countryStateError: PropTypes.string,
  country: PropTypes.string,
  countryError: PropTypes.string,
  handleChange: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  onClickNavigation: PropTypes.func,
  loading: PropTypes.bool,
  handleCitySearch: PropTypes.func,
  searchCity: PropTypes.func,
  handleSubmit: PropTypes.func,
  togglePassword: PropTypes.func,
  setCountryStateValue: PropTypes.func,
};
