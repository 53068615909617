import api from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";

export function deleteEvent(event) {
  const url = "/Events/Edit";
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_EVENT,
    });
    await db.table("events").delete(event.GUID);
    try {
      await api.post(url, { ...event, Deleted: new Date() });
      dispatch({
        type: constant.DELETED_EVENT,
        data: event,
      });
    } catch (e) {}
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_EVENT: {
      return {
        ...state,
        loadingCalendar: true,
      };
    }
    case constant.DELETED_EVENT: {
      return {
        ...state,
        loadingCalendar: false,
        calendarEvents: state.calendarEvents.filter(
          (e) => action.data.GUID !== e.GUID
        ),
      };
    }
    default:
      return state;
  }
}
