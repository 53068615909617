const initialState = {
  loading: false,
  status: null,
  recentConversations: [],
  enableChat: false,
  conversation: null,
  chatMessages: [],
  connection: null,
  chats: [],
  conversationDetails: null,
  connectedUsers: [],
};

export default initialState;
