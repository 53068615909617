import React from "react";

export const ComponentLoader = (props) => {
  let { loading } = props;
  return (
    loading && (
      <div className="spinner-main">
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  );
};
