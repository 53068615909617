import { Field, Form, Formik } from "formik";
import { Pencil } from "mdi-material-ui";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../page/organization/redux/actions";
import { parseLogo } from "../../../utils";
import {
  ModalComponent,
  SimpleLoader,
  ToastNotificationSuccess,
} from "../../common";
import LogoCrop from "../LogoCrop";

const EditOrganizationModal = (props) => {
  const organizationsInState = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const currentOrganization = props.currentOrganization ?? organizationsInState;

  const loading = useSelector((state) => state.organizations.loading);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    cropper: false,
    src: null,
    mime: "image/png",
    cropModal: true,
    croppedImageUrl: null,
  });

  const [imageRef, setImageRef] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  let fileRef = useRef(null);

  const toggleCropModal = () => {
    setState((prev) => ({ ...prev, cropModal: !prev.cropModal }));
  };

  const closeCropModal = () => {
    setState((prev) => ({ ...prev, cropModal: true, src: null }));
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setState((prev) => ({ ...prev, src: reader.result }))
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = currentOrganization.Name;
        setImageBlob(blob);
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      }, "image/jpeg");
    });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setState((prev) => ({ ...prev, croppedImageUrl }));
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const sendImage = () => {
    const image = imageBlob || imageRef;
    const currentOrgGUID = currentOrganization.GUID;
    window.URL.revokeObjectURL(state.croppedImageUrl);
    dispatch(
      actions.editLogo(currentOrgGUID, image, () => {
        closeCropModal();
      })
    );
    // window.URL.revokeObjectURL(state.croppedImageUrl);
  };

  const editOrganization = (organization) => {
    dispatch(
      actions.editOrganization(organization, () => {
        props.toggle();
        ToastNotificationSuccess(
          Math.random(),
          "Organization successfully updated"
        );
        props.loadOrganization && props.loadOrganization();
      })
    );
  };

  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="event-scroll">
            <div className="text-head">
              <div className="text-left">
                <h1>
                  <FormattedMessage id="Edit" defaultMessage="Edit" />:{" "}
                  {currentOrganization.Name}
                </h1>
              </div>
            </div>
          </div>
          <div className="event-inside">
            <div className="event-content">
              {state.src && (
                <ModalComponent
                  modal={state.cropModal}
                  toggle={() => toggleCropModal()}
                  childeren={
                    <LogoCrop
                      croppedImageUrl={state.croppedImageUrl}
                      src={state.src}
                      onComplete={onCropComplete}
                      // onChangeCrop={(e) => {
                      //   console.log({ croppedEvent: e });
                      // }}
                      onImageLoaded={onImageLoaded}
                      toggle={closeCropModal}
                      sendImage={sendImage}
                      loading={loading}
                    />
                  }
                />
              )}
              <div>
                <Formik
                  enableReinitialize
                  initialValues={currentOrganization}
                  onSubmit={(values) => editOrganization(values)}
                >
                  {(props) => (
                    <Form>
                      <div className="row">
                        <div className="col-12 relative text-center">
                          {!currentOrganization.Logo.includes("placehold") ? (
                            <div className="relative d-inline-block">
                              <div className="editLogo">
                                <Pencil onClick={handleClick} />
                                <input
                                  ref={fileRef}
                                  type="file"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  className="d-none"
                                  onChange={handleChange}
                                />
                              </div>
                              <label>
                                <FormattedMessage
                                  id="currentLogo"
                                  defaultMessage="Current Logo"
                                />
                              </label>
                              <img
                                width={100}
                                height={100}
                                src={
                                  state.croppedImageUrl ??
                                  `https://app.plnify.com/files/${parseLogo(
                                    currentOrganization.Logo
                                  )}`
                                }
                                className="avatarLogo d-block mx-auto"
                                alt={currentOrganization.Name}
                              />
                            </div>
                          ) : (
                            <div className="mt-3">
                              <button
                                onClick={handleClick}
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                {loading
                                  ? "Uploading image..."
                                  : "Upload an image"}
                              </button>
                              <input
                                ref={fileRef}
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg"
                                className="d-none"
                                onChange={handleChange}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-12 my-2">
                          <label htmlFor="organizationName">
                            <FormattedMessage id="name" defaultMessage="Name" />
                          </label>
                          <Field
                            id="organizationName"
                            name="Name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-12 my-3 d-flex justify-content-end align-items-center">
                        {loading ? (
                          <SimpleLoader loading={loading} />
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                            >
                              <FormattedMessage
                                id="done"
                                defaultMessage="Done"
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditOrganizationModal;
