import React, { useEffect, useState } from "react";
import { IMAGES } from "../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import FormField from "../form/formField";
import { addListSchema } from "../../utils/validationsSchemas";
import uuid from "uuid";
import { InformationOutline } from "mdi-material-ui";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  getOrganization,
  getBasicInfo,
  logoutUser,
  dynamicFilter,
} from "../../utils";
import db from "../../common/db";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { event } from "jquery";
const AddList = (props) => {
  const intl = useIntl();
  const [currentDate, setCurrentDate] = useState("");
  let {
    events,
    addList,
    loading,
    currentList,
    edit,
    editList,
    toggle,
    fromEvent,
    title,
  } = props;
  const getCurrentDayOnCalendar = async () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const calendarMode = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    setCurrentDate({
      currentDate: calendarMode
        ? moment(calendarMode.currentDayOnCalendar).toDate()
        : new Date(),
    });
  };
  useEffect(() => {
    document.getElementsByClassName("Name")?.[0]?.focus();
    getCurrentDayOnCalendar();
  }, []);

  const eventsToSelect = events
    .sort((a, b) => {
      if (a.Date < b.Date) return 1;
      if (a.Date > b.Date) return -1;
    })
    .sort((a) => {
      const CurrentDate = new Date(currentDate).getTime();
      const sortDate = new Date(a.Date).getTime();
      if (CurrentDate > sortDate);
    })
    .filter((evnt) => {
      return new Date(evnt.Date) >= new Date();
    })
    .map((e) => ({
      label: moment(e.Date).format("MMM DD, YYYY") + " - " + e.Name,
      value: e.GUID,
    }));
  return (
    <div className="event-page" style={{ overflowY: "visible" }}>
      <div className="event-expennd-section">
        <div className="event-scroll">
          <div className="text-head">
            <div className="text-left d-flex justify-content-between w-100">
              <h5 className="title">
                <FormattedMessage
                  id="addTo-Do"
                  defaultMessage={props.title ?? "Add To-Do"}
                />
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </div>
          <Formik
            onSubmit={(values) =>
              title === "Link To-Do"
                ? editList(values, toggle)
                : edit
                ? editList(values)
                : addList(values)
            }
            validationSchema={addListSchema}
            enableReinitialize
            initialValues={
              edit || title === "Link To-Do"
                ? currentList
                : {
                    GUID: uuid.v4(),
                    Name: "",
                    EventGUID: "",
                    Note: "",
                    Date: new Date(),
                  }
            }
          >
            {(props) => (
              <Form autoComplete="off" className="addEvent__form">
                <div className="event-inside">
                  <div className="event-content">
                    {title === "Link To-Do" ? (
                      ""
                    ) : (
                      <FormField
                        fullWidth={true}
                        label={
                          <FormattedMessage id="name" defaultMessage="Name" />
                        }
                      >
                        <Field
                          className="form-control Name"
                          type="text"
                          name="Name"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Name"
                          component="div"
                        />
                      </FormField>
                    )}
                    {fromEvent ? null : (
                      <div className="row">
                        <div className="col-md-12 px-0 pr-2 event-picker">
                          <label
                            className="d-flex align-items-center"
                            htmlFor={"EventType"}
                            style={{ marginBottom: 10 }}
                          >
                            <FormattedMessage
                              id="linkWithEvent"
                              defaultMessage="Link with an Event (Optional)"
                            />
                            <>
                              <ReactTooltip type="info" effect="solid" />
                              <InformationOutline
                                className="ml-1 label-info"
                                data-tip={intl.formatMessage({
                                  id: "byLinkingTo-Do",
                                })}
                              />
                            </>
                          </label>

                          <div
                            style={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                              position: "relative",
                              bottom: "10px",
                            }}
                          >
                            <Select
                              options={eventsToSelect}
                              placeholder={
                                edit || title === "Link To-Do"
                                  ? currentList
                                    ? currentList.Event &&
                                      currentList.Event.Name
                                    : "None"
                                  : "None"
                              }
                              name="EventType"
                              onChange={(selection) =>
                                props.setFieldValue(
                                  "EventGUID",
                                  selection.value
                                )
                              }
                            />
                            <ErrorMessage
                              className="error-message"
                              name="EventType"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="pb-2">
                      {edit || title === "Link To-Do" ? (
                        <button
                          disabled={!props.isValid || loading}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                          {/* {loading ? "Editing..." : "Edit"} */}
                        </button>
                      ) : (
                        <button
                          disabled={!props.isValid || loading}
                          type="submit"
                          className="btn btn-primary"
                        >
                          {loading ? (
                            "Adding..."
                          ) : (
                            <FormattedMessage id="done" defaultMessage="Done" />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddList;
