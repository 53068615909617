import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { ToastNotificationSuccess } from "..";
import api from "../../common/api";
import db from "../../common/db";
import { IMAGES } from "../../constant";
import { DropDownComponent } from "../common";
import NotesPalette from "./NotesPalette";

const Note = (props) => {
  const intl = useIntl();
  const [colorState, setColorState] = useState();
  // const basicInfoGUID = getBasicInfo().GUID;
  const dispatch = useDispatch();
  let { note, setNotes, toggle } = props;
  let style = {
    backgroundColor: note.Color ? note.Color : "#fad155",
  };
  let secondStyle = {
    backgroundImage: "url(" + IMAGES.LOGO_BLACK_P + ")",
  };
  const deleteNote = async (note) => {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDeleteNote" }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        await api.post(`/Note/AddEditBatch`, [
          { ...note, Deleted: new Date() },
        ]);
        let local = await db.table("notes").where({ GUID: note.GUID }).first();
        if (local) {
          local.Deleted = new Date();
          await db.table("notes").put(local);
          ToastNotificationSuccess(Math.random(), "Note successfully deleted");
        }
        props.reload();
      }
    });
  };
  const setNewColorNote = async (color) => {
    // setColorState(color)
    const noteToSave = {
      ...note,
      Color: color,
    };
    await db.table("notes").put(noteToSave);
    await db.table("activeNotes").put(noteToSave);
    api.post(`/Note/AddEditBatch`, [noteToSave]);
    props.reload();
  };

  const loadData = async () => {
    const appSettings = await db
      .table("notes")
      .where({ GUID: note.GUID })
      .first();

    if (appSettings) {
      let dataSettings = await db.appSettings.toArray();
      setColorState(dataSettings);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="shadow-sm" style={style}>
      <div className="col-12 text-right px-0">
        <div className="btn-group">
          <DropDownComponent
            data={[
              {
                name: <FormattedMessage id="delete" defaultMessage="Delete" />,
                delete: true,
                border: true,
                trigger: () => deleteNote(note),
              },
            ]}
          >
            <NotesPalette
              setNewColorNote={setNewColorNote}
              noteColor={colorState}
            />
          </DropDownComponent>
        </div>
      </div>
      <div
        className="note"
        onClick={() => {
          toggle && toggle();
          dispatch(setNotes(note));
        }}
      >
        <div className="noteWatermark" style={secondStyle}></div>
        {note.Name ? (
          <h6 className="note__title text-truncate">{note.Name}</h6>
        ) : null}
        <p
          className="note__text"
          dangerouslySetInnerHTML={{ __html: note.Details }}
        />
      </div>
    </div>
  );
};

export default Note;
