import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import uuid from "uuid";
import { IMAGES } from "../../constant";
import * as actions from "../../page/todoPage/redux/actions";
import { getBasicInfo, getEvent, getOrganization } from "../../utils";
import { ToastNotificationSuccess } from "../common";
import Todo from "./Todo";
import db from "../../common/db";

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function compareDates(a, b) {
  const dateA = new Date(a.Date);
  const dateB = new Date(b.Date);
  return dateB - dateA;
}

const TodoModal = (props) => {
  const todos = useSelector((state) =>
    state.todo.todos
      .filter((todo) => todo.EventGUID === state.event.event.GUID)
      .sort(compareDates)
  );

  const currentEvent = useSelector((state) => state.event.event);
  const loading = useSelector((state) => state.todo.loading);
  const teams = useSelector((state) => state.organizations.teams);
  const events = useSelector((state) => state.calendar.calendarEvents);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    memberModals: false,
    tourListSelect: "",
    todoSelected: null,
    taskEditModal: false,
    currentList: null,
    addListModal: false,
    addTaskModal: false,
    editList: false,
    editListModal: false,
    options: [],
  });

  const [selectTodo, setSelectTodo] = useState(state.currentList);

  useEffect(() => {
    dispatch(actions.getListsOfTodo(currentEvent.GUID, null, true));
  }, []);

  const setCurrentList = async (list) => {
    let event;
    //Update with remote data object
    dispatch(
      actions.getTodoDetails(list.GUID, async (id, eventId) => {
        let listLocal = await db.table("todos").where({ GUID: id }).first();
        if (eventId) {
          event = await db.table("events").where({ GUID: eventId }).first();
        }
        setState((prev) => ({
          ...prev,
          currentList: { ...listLocal, Event: event },
        }));
      })
    );
  };

  // useEffect(() => {
  //   selectTodo && setCurrentList(selectTodo);
  // }, [selectTodo]);

  const selectList = (obj) => {
    setCurrentList(obj);
  };

  const setEditTask = () => {
    setState((prev) => ({ ...prev, taskEdit: !prev.editList }));
  };

  const toggleAssigneeModal = () => {
    setState((prev) => ({ ...prev, memberModals: !prev.memberModals }));
  };

  const toggleAddList = () => {
    setState((prev) => ({ ...prev, addListModal: !prev.addListModal }));
  };

  const toggleAddTask = (currentList) => {
    setState((prev) => ({
      ...prev,
      addTaskModal: !prev.addTaskModal,
      currentList,
    }));
  };

  const setEdit = () => {
    setState((prev) => ({ ...prev, editList: !prev.editList }));
  };

  const toggleTaskEdit = (task, currentList) => {
    setState((prev) => ({
      ...prev,
      taskEditModal: !prev.taskEditModal,
      todoSelected: task,
      //currentList,
    }));
    setEditTask();
  };

  const setEditList = (currentList) => {
    setState((prev) => ({
      ...prev,
      editListModal: !prev.editListModal,
      currentList,
    }));
    setEdit();
  };

  const toggleTask = (task) => {
    const user = getBasicInfo();
    const toggledTask = { ...task, Status: task.Status === 0 ? 1 : 0 };
    dispatch(
      actions.toggleTask(toggledTask, () => {
        dispatch(actions.getListsOfTodo(currentEvent.GUID, user.GUID, true));
      })
    );

    // setState((prev) => {
    //   const Tasks = prev?.currentList?.TodoTasks;
    //   let TaskToUpdate = {};

    //   Tasks?.forEach((todo, index) => {
    //     if (todo.GUID === task.GUID) {
    //       TaskToUpdate = { ...todo, index, Status: todo.Status === 0 ? 1 : 0 };
    //     }
    //   });

    //   Tasks[TaskToUpdate.index] = TaskToUpdate;
    //   const newState = {
    //     ...prev,
    //     currentList: { ...prev.currentList, TodoTasks: Tasks },
    //   };
    //   return newState;
    // });
  };

  const editTask = (task) => {
    const user = getBasicInfo();
    if (task.DueDate) {
      const date = moment(task.Date).format("YYYY-MM-DD");
      const time = moment(task.Time).format("HH:mm");
      task.Date = moment(date + "T" + time).toDate();
    } else {
      task.Date = undefined;
    }
    dispatch(
      actions.editTask(task, () => {
        ToastNotificationSuccess(Math.random(), "Task created successfully");
        dispatch(actions.getListsOfTodo(currentEvent.GUID, user.GUID, true));
        toggleTaskEdit(null);
      })
    );
  };

  const deleteTask = (task) => {
    const user = getBasicInfo();
    const deletedTask = { ...task, Deleted: new Date() };
    Swal.fire({
      text: "Are you sure you want to delete this task?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteTask(deletedTask, () => {
            dispatch(
              actions.getListsOfTodo(currentEvent.GUID, user.GUID, true)
            );
          })
        );
      }
    });
  };

  // const getPercentageDone = (tasks) => {
  //   const tasksTotal = cleanDelete(tasks);
  //   const tasksDone = tasksTotal.filter((t) => t.Status).length;
  //   const total = (tasksDone / tasksTotal.length) * 100;
  //   return isNaN(total) ? 0 : Math.round(total);
  // };

  const addList = (list, localMethod, callBack) => {
    const allStaffs = [];
    const user = getBasicInfo();
    const organization = getOrganization();
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
      EventGUID: currentEvent.GUID,
      OrganizationGUID: organization.GUID,
    };
    teams
      .filter((t) => t.Deleted === null)
      .forEach((team) => {
        team.Staffs.filter((s) => s.Deleted === null).forEach((s) =>
          allStaffs.push({
            GUID: uuid.v4(),
            AccountGUID: s.AccountGUID,
            Deleted: new Date(),
            TodoGUID: null,
          })
        );
      });
    dispatch(
      actions.addList(newList, allStaffs, async () => {
        ToastNotificationSuccess(Math.random(), "List created successfully");
        toggleAddList();
        await delay(500);
        dispatch(actions.getTodoDetails(newList.GUID));

        await delay(500);
        dispatch(actions.getListsOfTodo(currentEvent.GUID, user.GUID, true));
        setCurrentList(newList);

        await delay(500);
        selectList(newList);
      })
    );
  };

  const addTask = (task, close, reset) => {
    const user = getBasicInfo();
    const { currentList } = state;
    // if (task.DueDate) {
    //   const date = moment(task.Date).format("YYYY-MM-DD");
    //   const time = moment(task.Time).format("HH:mm");
    //   task.Date = moment(date + "T" + time).toDate();
    // } else {
    //   task.Date = undefined;
    // }
    let taskToCreate;
    if (task.Name) {
      taskToCreate = { ...task, Deleted: null };

      dispatch(
        actions.addTask(taskToCreate, async () => {
          ToastNotificationSuccess(Math.random(), "Task created successfully");
          if (close) {
            toggleAddTask(currentList);
          }
          if (reset) {
            reset();
          }
        })
      );
    } else {
      ToastNotificationSuccess(Math.random(), "Task created Error");
    }
  };

  const deleteList = (list) => {
    const listToBeDeleted = {
      ...list,
      Deleted: new Date(),
      TodoTasks: null,
      TodoAssignees: null,
    };
    Swal.fire({
      text: "Are you sure you want to delete this list?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteList(listToBeDeleted, () => {
            ToastNotificationSuccess(
              Math.random(),
              "List deleted successfully"
            );
          })
        );
      }
    });
  };

  const editList = (list) => {
    const user = getBasicInfo();
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
    };
    dispatch(
      actions.editList(newList, () => {
        ToastNotificationSuccess(Math.random(), "List edited successfully");
        dispatch(actions.getListsOfTodo(null, user.GUID, false));
        setEditList(list);
      })
    );
  };
  const handleAddTodo = () => {
    toggleAddList();
  };

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div
          className="event-expennd-section move-modal"
          style={{ width: "1200px" }}
        >
          <div className="text-head ">
            <div className="text-left mr-auto d-flex w-100 justify-content-between px-4">
              <h1>
                <FormattedMessage id="to-Do" defaultMessage="To-Do's" />
              </h1>
              {/* {todos.length > 0 && (
                <button className="btn btn-primary" onClick={handleAddTodo}>
                  Add To-Do
                </button>
              )} */}
            </div>
            <div className="btn-group d-none">
              <button
                onClick={() => toggleAddList()}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faPlus} color="white" />
              </button>
              <a href="/todo" className="btn btn-sm btn-secondary">
                <FormattedMessage
                  id="viewList"
                  defaultMessage="View in To-Do List"
                />
              </a>
            </div>
          </div>
          <Scrollbars style={{ height: "calc(100vh - 50px)" }}>
            <div className="event-inside px-1">
              <div className="event-content">
                <Todo
                  {...state}
                  toggleAddList={toggleAddList}
                  toggleAddTask={toggleAddTask}
                  deleteList={deleteList}
                  addList={addList}
                  addTask={addTask}
                  toggleTask={toggleTask}
                  deleteTask={deleteTask}
                  toggleAssigneeModal={toggleAssigneeModal}
                  setEditList={setEditList}
                  editListFunc={editList}
                  setEditTask={setEditTask}
                  toggleTaskEdit={toggleTaskEdit}
                  taskEditModal={state.taskEditModal}
                  taskEdit={state.taskEdit}
                  todoSelected={state.todoSelected}
                  editTaskFunc={editTask}
                  events={events}
                  todos={todos}
                  teams={teams}
                  loading={loading}
                  editList={state.editList}
                  fromEventDetails
                  selectList={selectList}
                  selectTodo={selectTodo}
                  setSelectTodo={setSelectTodo}
                />
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default TodoModal;
