import api from "../../../common/api";
import uuid from "uuid";

import * as constant from "./constants";
const subUrl = "/Roles/AddEdit";

export function editTeam(team, success) {
  return async (dispatch) => {
    try {
      dispatch({ type: constant.EDITING_TEAM });
      const request = await creatingTeamRequest(subUrl, team);
      dispatch({ type: constant.EDITED_TEAM, data: request.data.response });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_TEAM_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_TEAM: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_TEAM: {
      const editedTeam = action.data;
      return {
        ...state,
        loading: false,
        teams: state.teams.map((team) => {
          if (team.GUID === editedTeam.GUID) {
            team = editedTeam;
            return team;
          }
          return team;
        }),
      };
    }
    case constant.EDITING_TEAM_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function creatingTeamRequest(url, team) {
  return api.post(url, {
    guid: team.GUID,
    name: team.Name,
    organizationGUID: team.OrganizationGUID,
    organization: team.Organization,
    AllowToReceiveNotifications: team.AllowToReceiveNotifications,
    role_CanViewRole1: team.role_CanViewRole1,
    staffs: team.Staffs,
    role_CanViewRole: team.role_CanViewRole,
  });
}
