import React, { useState } from "react";
import { getBasicInfo } from "../utils";
import { useDispatch } from "react-redux";
import { addAttachment } from "../page/event/redux/addAttachment";
import * as actions from "../page/event/redux/actions";
import Swal from "sweetalert2";
import { ToastNotificationSuccess } from "../components";

export const useDragAndDrop = (event) => {
  const user = getBasicInfo();
  const dispatch = useDispatch();
  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const onChange = (e) => {
    let files = e?.target?.files || e;
    const arrFiles = Array.from(files);
    try {
      arrFiles.forEach((file) => {
        window.URL.createObjectURL(file);
        let attachment = {
          name: file.name,
          note: "",
          event: event.GUID,
          file: file,
        };
        dispatch(
          actions.addAttachment(
            event.GUID,
            attachment,
            () => {},
            () => dispatch(actions.getEvents(event.OrganizationGUID, user.GUID))
          )
        );
      });
      ToastNotificationSuccess(1, "Attachment succesfully created");
    } catch (error) {
      Swal.fire("Oops...", "Something went wrong!", "error");
    }
  };

  const handleDrop = (file) => {
    onChange(file);
  };

  return {
    showDrop,
    handleDrop,
    handleDragIn,
    handleDragOut,
  };
};
