import React, { Component } from "react";
import Payments from "./Payments";

class PaymentsContainer extends Component {
  state = {};
  render() {
    return <Payments />;
  }
}

export default PaymentsContainer;
