import React from "react";

export const SandwichButton = ({ showExpanded, expanded }) => {
  return (
    <button
      onClick={() => showExpanded()}
      className={`menu__toggle btn  ${expanded ? "d-none" : ""}`}
    >
      <span className="white-text ">
        <i className={`${expanded ? "fas fa-bars" : "fas fa-bars"}`}></i>
      </span>
    </button>
  );
};
