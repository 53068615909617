import * as constant from "./constants";
import api from "../../../common/api";
import { resourceUrl } from "../../../utils";
import uuid from "uuid";

export function exportResource(
  resource,
  type,
  arrayOfEvents,
  success,
  refresh
) {
  const url = resourceUrl(type);
  return async (dispatch) => {
    dispatch({
      type: constant.EXPORTING_RESOURCE,
    });
    let newResource = {GUID: resource.GUID, TargetEventsGUID: ""};
    //We are going to try to sent the request
    
    try {
      let targets = "";
      arrayOfEvents.forEach(async (event) => {
        targets+= (targets.length>0? ",": "")+event.GUID;
        
      });
      newResource.TargetEventsGUID = targets;
      await api.post(url, newResource);
      dispatch({
        type: constant.EXPORTED_RESOURCE,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.EXPORTING_RESOURCE_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_RESOURCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EXPORTED_RESOURCE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EXPORTING_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
