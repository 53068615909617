import React, { useEffect, useState } from "react";

import { IMAGES } from "../../constant";
import { ToastNotificationSuccess, SimpleLoader } from "../common";
import { Scrollbars } from "react-custom-scrollbars";

import api from "../../common/api";
import uuid from "uuid";
import moment from "moment";
import db from "../../common/db";

import { organizationUsers, sortArray } from "../../common/helpers";
import { getOrganization } from "../../utils";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const MoveUserToTeam = (props) => {
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOtherTeams = async () => {
    let theOrganization = getOrganization();
    let users = await organizationUsers(theOrganization.GUID);
    let obj = [];
    users.forEach((team) => {
      obj.push({
        //RoleGUID: roleToEdit.GUID,
        GUID: team.GUID,

        Deleted: null,
        Name: team.Name,
      });
    });
    obj = obj.sort((a, b) => a.Name.localeCompare(b.Name));
    setTeams(obj);
  };

  const move = async (selected) => {
    try {
      let query =
        "UserGUID=" + props.selectedUser.GUID + "&RoleGUID=" + selected.GUID;

      //Add the can view
      setIsLoading(!isLoading);
      const request = await api.get("/User/MoveToGroup?" + query);
      setIsLoading(!isLoading);
      console.log("Response from server", request);
      if (request.data.Status === "ok") {
        //Saving locally

        props.toggle(props.selectedUser, true);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    //  setTeams(teams);
    loadOtherTeams();
  }, []);
  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100">
              <div className="d-flex justify-content-between">
                <h1>
                  <FormattedMessage id="moveUser" defaultMessage="Move user" />
                </h1>

                <button
                  type="button"
                  className="btn btn-sm btn-link ml-2"
                  onClick={() => props.toggle(false)}
                >
                  <FontAwesomeIcon icon={faTimes} color="black" />
                </button>
              </div>
              <p className="d-none">
                <FormattedMessage
                  id="selectTeam"
                  defaultMessage="Select the team"
                />
              </p>
            </div>
            <div
              className="d-none close-icon d-none"
              onClick={() => props.toggle()}
            >
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content">
              <Scrollbars style={{ width: "100%", height: "calc(34vh)" }}>
                {teams
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  .sort((a) => {
                    if (a.Name === "Admin") return -1;
                    if (a.Name) return 1;
                  })
                  .map((team) => {
                    return (
                      <div key={team.GUID} className="createTeam__item p-0 row">
                        <div className="col-auto p-2">
                          <p>{team.Name}</p>
                        </div>
                        <div className="col-auto ml-auto text-right">
                          {props.selectedUser.RoleGUID !== team.GUID ? (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => move(team)}
                              disabled={isLoading}
                            >
                              <FormattedMessage
                                id="moveHere"
                                defaultMessage="Move here"
                              />
                            </button>
                          ) : null}
                        </div>
                        <div className="col-12 px-0">
                          <hr className="p-0 m-0" />
                        </div>
                      </div>
                    );
                  })}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveUserToTeam;
