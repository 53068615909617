import * as constants from "./constants";
import api from "../../../common/api";

export function rejectTickets() {
  return async (dispatch) => {
    try {
    } catch (error) {}
  };
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
