import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function deleteList(list, success) {
  const urlForTodos = "ToDo/AddEdit";
  return async (dispatch) => {
    try {
      dispatch({ type: constants.DELETING_LIST });
      await db.table("todos").delete(list.GUID);
      dispatch({ type: constants.DELETED_LIST, data: list });

      success();
      const request = await apiService.post(urlForTodos, list);
    } catch (error) {
      dispatch({
        type: constants.DELETING_LIST_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.DELETING_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.DELETED_LIST: {
      return {
        ...state,
        loading: false,
        todos: state.todos.filter((l) => l.GUID !== action.data.GUID),
      };
    }
    case constants.DELETING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
