import uuid from "uuid";

const redirectURL =
  process.env.REACT_APP_MODE === "dev"
    ? "http://localhost:3021"
    : "https://beta.plnify.com";

export const params = {
  sdk: "8.0",
  entry: {
    oneDrive: {
      files: {},
    },
  },
  authentication: {},
  messaging: {
    origin: redirectURL,
    channelId: "27",
  },
  typesAndSources: {
    mode: "files",
    pivots: {
      oneDrive: true,
      recent: true,
    },
  },
  selection: {
    mode: "multiple",
  },
};
