import * as CONSTANT from "./constants";

export function emptyCities() {
  return dispatch => {
    dispatch({
      type: CONSTANT.CLEAR_ALL_CITIES
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.CLEAR_ALL_CITIES: {
      return {
        ...state,
        cities: []
      };
    }
    default:
      return state;
  }
}
