import React, { Component } from "react";
import { connect } from "react-redux";
import AddHotel from "./addHotel";
import moment from "moment";
import { bindActionCreators } from "redux";
import { ToastNotificationSuccess } from "../../components/common";
import uuid from "uuid";
import db from "../../common/db";

import * as actions from "../event/redux/actions";
import Hotel from "../../components/hotel/hotel";

export class AddHotelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkModal: false,
      staffSelected: [],
      fromDate: "",
      toDate: "",
      eventsSelected: [],
      linkableEvents: [],
      events: [],
      labelModal: false,
      label: "",
      reservationsModal: false,
    };
  }

  componentDidMount() {
    const { event } = this.props;
    this.setState({
      toDate: moment(event.Date).add(1, "days").toDate(),
      fromDate: moment(event.Date).subtract(1, "days").toDate(),
      eventsSelected: [...this.state.eventsSelected, this.props.event],
    });
    this.linkOrNotStep(
      this.state.fromDate,
      this.state.toDate,
      this.props.events
    );
  }

  componentDidUpdate() {}

  setToDate = (date) => {
    this.setState({
      toDate: date,
    });
    this.linkOrNotStep(
      this.state.fromDate,
      this.state.toDate,
      this.props.events
    );
  };

  toggleReservationsModal = () => {
    this.setState({
      reservationsModal: !this.state.reservationsModal,
    });
  };

  setFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
    this.linkOrNotStep(
      this.state.fromDate,
      this.state.toDate,
      this.props.events
    );
  };

  toggleLinkModal = () => {
    this.setState({
      linkModal: !this.state.linkModal,
    });
  };

  emptyReservations = () => {
    this.setState({
      staffSelected: [],
    });
  };

  toggleHotelAssign = (staff) => {
    let staffs = this.state.staffSelected;
    if (staffs.filter((s) => s.AccountGUID === staff.AccountGUID).length > 0) {
      let filtered = staffs.filter((s) => s.AccountGUID !== staff.AccountGUID);
      return this.setState({
        staffSelected: filtered,
      });
    } else {
      let newOnes = staffs.concat(staff);
      return this.setState({
        staffSelected: newOnes,
      });
    }
  };

  toggleSelectAll = (team, checkIn, checkOut) => {
    let staffs = this.state.staffSelected;
    team.forEach((staff) => {
      if (
        staffs.filter((s) => s.AccountGUID === staff.AccountGUID).length > 0
      ) {
        let filtered = staffs.filter(
          (s) => s.AccountGUID !== staff.AccountGUID
        );
        staffs = filtered;
      } else {
        staffs.push({ ...staff, CheckinDate: checkIn, CheckoutDate: checkOut });
      }
    });
    this.setState({
      visibleStaffs: staffs,
    });
  };

  toggleEvent = (event) => {
    let events = this.state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return this.setState({
        eventsSelected: filtered,
      });
    } else {
      let newOnes = events.concat(event);
      return this.setState({
        eventsSelected: newOnes,
      });
    }
  };

  updateReservation = (reservation) => {
    let staffs = this.state.staffSelected.map((staff) =>
      staff.AccountGUID === reservation.AccountGUID
        ? (staff = reservation)
        : staff
    );
    this.setState({
      staffSelected: staffs,
    });
  };

  setHotelData = (hotel, props) => {
    let city = "",
      address = "",
      country = "",
      state = "",
      zipcode = "";
    //Proper filtering way
    let elements = hotel.address_components;
    let cityAddress = elements.filter(
      (x) => x.types.indexOf("locality") !== -1
    );
    if (cityAddress.length > 0) {
      city = cityAddress[0].short_name;
    } else {
      cityAddress = elements.filter(
        (x) => x.types.indexOf("sublocality_level_1") !== -1
      );
      if (cityAddress.length > 0) {
        city = cityAddress[0].short_name;
      } else {
        cityAddress = elements.filter(
          (x) => x.types.indexOf("postal_town") !== -1
        );
        if (cityAddress.length > 0) {
          city = cityAddress[0].short_name;
        }
      }
    }
    let zipCodeAddress = elements.filter(
      (x) => x.types.indexOf("postal_code") !== -1
    );
    if (zipCodeAddress.length > 0) {
      zipcode = zipCodeAddress[0].short_name;
    }

    let stateAddress = elements.filter(
      (x) => x.types.indexOf("administrative_area_level_1") !== -1
    );
    if (stateAddress.length > 0) {
      state = stateAddress[0].short_name;
    }

    let countryAddress = elements.filter(
      (x) => x.types.indexOf("country") !== -1
    );
    if (countryAddress.length > 0) {
      country = countryAddress[0].long_name;
    }

    let streetAddress = elements.filter(
      (x) => x.types.indexOf("street_number") !== -1
    );
    if (streetAddress.length > 0) {
      address = streetAddress[0].short_name;
    }

    let routeAddress = elements.filter((x) => x.types.indexOf("route") !== -1);
    if (routeAddress.length > 0) {
      address += (address.length > 0 ? " " : "") + routeAddress[0].short_name;
    }

    let neighborhoodAddress = elements.filter(
      (x) => x.types.indexOf("neighborhood") !== -1
    );
    if (neighborhoodAddress.length > 0) {
      address +=
        (address.length > 0 ? " " : "") + neighborhoodAddress[0].short_name;
    }

    if (address.length === 0) {
      address = hotel.vicinity;
    }

    //end proper filtering way
    props.setFieldValue("Name", hotel.name);
    props.setFieldValue("City", city);
    props.setFieldValue("Country", country);
    props.setFieldValue("ZipCode", zipcode);
    props.setFieldValue("State", state);
    props.setFieldValue("Address", address);
    props.setFieldValue("Phone", hotel.formatted_phone_number);
    props.setFieldValue("Website", hotel.website);

    if (
      hotel.geometry !== undefined &&
      hotel.geometry !== null &&
      hotel.geometry.location !== null
    ) {
      props.setFieldValue("Latitude", hotel.geometry.location.lat);
      props.setFieldValue("Longitude", hotel.geometry.location.lng);
    }
    props.setFieldValue("GoogleplaceID", hotel.place_id);
  };

  linkOrNotStep = (from, to, events) => {
    let linkables = [];
    events.map((event) => {
      if (new Date(event.Date) > from && new Date(event.Date) < to) {
        if (event.GUID !== this.props.event.GUID) {
          linkables.push(event);
        }
      }
    });
    this.props.actions.linkableEvents(linkables);
  };

  prepareAndCreateHotel = async () => {
    const hotel = await db.table("hotelsTransactions").toArray();
    const hotels = this.state.eventsSelected.map((e) => ({
      ...hotel[0],
      EventGUID: e.GUID,
      GUID: uuid.v4(),
      Label: this.state.label,
    }));
    this.props.addHotel(hotels, this.state.staffSelected, () => {
      ToastNotificationSuccess(1, "Hotel successfully updated");
      this.props.toggle();
      this.props.actions.getHotel(this.props.event.GUID);
    });
  };

  prepareHotelLocal = (hotel) => {
    const currentHotel = { ...hotel, GUID: uuid.v4(), HotelReservations: [] };

    const hotels = this.state.eventsSelected.map((e) => ({
      ...currentHotel,
      EventGUID: e.GUID,
      Label: this.state.label,
    }));

    console.log({ hotels, currentHotel });
    this.props.actions.addHotel(
      hotels,
      () => {
        ToastNotificationSuccess(1, "Hotel successfully created");
        this.props.actions.getHotel(this.props.event.GUID);
        this.props.toggle(true, currentHotel);
      },
      true
    );
  };

  toggleLabelModal = () => {
    this.setState({
      labelModal: !this.state.labelModal,
    });
  };

  onChangeLabel = (value) => {
    this.setState({
      label: value,
    });
  };

  setLabel = (value) => {
    this.setState({
      label: value || "",
    });
  };

  render() {
    const {
      hotelList,
      toggle,
      event,
      events,
      teams,
      searchHotels,
      hotels,
      emptyHotels,
      searchingHotels,
      loading,
      linkableEvents,
      edit,
      hotel,
      editHotel,
    } = this.props;
    const {
      linkModal,
      staffSelected,
      toDate,
      fromDate,
      eventsSelected,
      label,
      labelModal,
    } = this.state;
    return (
      <AddHotel
        searchList={[]}
        event={event}
        addHotel={this.prepareAndCreateHotel}
        addHotelLocal={this.prepareHotelLocal}
        hotelList={hotelList}
        findHotel={this.findHotel}
        setHotelDetails={this.setHotelDetails}
        toggleModal={toggle}
        toggleLinkModal={this.toggleLinkModal}
        toggleHotelAssign={this.toggleHotelAssign}
        toggleSelectAll={this.toggleSelectAll}
        updateReservation={this.updateReservation}
        linkModal={linkModal}
        events={events}
        teams={teams}
        staffSelected={staffSelected}
        emptyReservations={this.emptyReservations}
        searchHotels={searchHotels}
        hotels={hotels}
        emptyHotels={emptyHotels}
        toDate={toDate}
        fromDate={fromDate}
        setToDate={this.setToDate}
        setFromDate={this.setFromDate}
        eventsSelected={eventsSelected}
        searchingHotels={searchingHotels}
        loading={loading}
        setHotelData={this.setHotelData}
        linkableEvents={linkableEvents}
        toggleEvent={this.toggleEvent}
        edit={edit}
        hotel={hotel}
        editHotel={editHotel}
        toggleLabelModal={this.toggleLabelModal}
        onChangeLabel={this.onChangeLabel}
        label={label}
        labelModal={labelModal}
        setLabel={this.setLabel}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.event.event,
  events: state.calendar.calendarEvents,
  teams: state.organizations.teams,
  hotels: state.common.hotels,
  searchingHotels: state.common.searchingHotels,
  loading: state.event.loading,
  linkableEvents: state.event.linkableEvents,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHotelContainer);
