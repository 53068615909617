import React from "react";
import { IMAGES } from "../../../constant";

const ResetPasswordLinkExpire = (props) => {

  return (
    <div className="main-container">
      <div className="before-password-login">
      <div className="inner-page">
          <div className="logo">
            <img src={IMAGES.LOGO} width="128" alt="logo" />
          </div>
          <div className="password-success-page">
            <div className="password-success-content">
              <div className="password-success-img">
                <img src={IMAGES.EXPIRE_ICON} alt="success" width="103" height="100" />
              </div>
              <div className="password-success-text">
                <h1>Sorry, the link has expired.</h1>
              </div>
              <div className="password-success-pg">
                <p>The link was set to expire after a certain amount of time.Please try resetting your password again.</p>
              </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};


export default ResetPasswordLinkExpire;
