import { useState } from "react";
import history from "../../../components/common/history";
import { SignUpForm } from "./SignUpForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../redux/actions";
import * as commonActions from "../../common/redux/actions";

const SignUpPage = (props) => {
  const [state, setState] = useState({
    showPassword: false,
    showConfirmPassword: false,
    termsAndConditionCheck: false,
    termsAndConditionError: "",
    cursor: 0,
    loading: false,
    isElectron: false,
  });

  const {
    termsAndCondition,
    common,
    auth,
    authActions: { signUp, login },
    commonActions: { searchCity, searchCountry, emptyCities, emptyCountries },
  } = props;

  const onClickNavigation = (routes) => {
    history.push({ pathname: routes });
  };

  const togglePassword = (key) => {
    setState({ [key]: !state[key] });
  };

  const toggleTermsCheck = () => {
    setState({
      termsAndConditionCheck: !state.termsAndConditionCheck,
      termsAndConditionError: "",
    });
  };

  const handleKeyDown = (e, list, onEnter) => {
    const { cursor } = state;
    if (e.keyCode === 38 && cursor > 0) {
      setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && cursor < list.length - 1) {
      setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    }
    if (e.keyCode === 13) {
      onEnter(cursor);
      setState({ cursor: 0 });
    }
  };

 

  return (
    <SignUpForm
      {...state}
      history={history}
      login={login}
      loading={auth.logging}
      searchCity={searchCity}
      onClickNavigation={onClickNavigation}
      togglePassword={togglePassword}
      termsAndCondition={termsAndCondition}
      toggleTermsCheck={toggleTermsCheck}
      handleKeyDown={handleKeyDown}
      signUp={signUp}
      getCities={searchCity}
      getCountrys={searchCountry}
      countries={common.countries}
      cities={common.cities}
      emptyCities={emptyCities}
      emptyCountries={emptyCountries}
    />
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators({ ...authActions }, dispatch),
    commonActions: bindActionCreators({ ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
