import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function editFlight(data, success) {
  const url = "/Flights/AddEditBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_FLIGHT,
      });
      await apiService.post(url, [data]);
      dispatch({
        type: constant.EDITED_FLIGHT,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_FLIGHT_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_FLIGHT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_FLIGHT:
    case constant.EDITING_FLIGHT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
