import { ArrowLeft } from "mdi-material-ui";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import MailContactItem from "../mail/MailContactItem";

const MailingListModal = (props) => {
  const intl = useIntl();
  const [state, setState] = useState({
    teams: [],
  });

  const { selectEmail, emails, selectAllEmail, selectAll } = props;
  const { teams } = state;

  useEffect(() => {
    const cleanedTeams = props.teams
      .filter((t) => t.Name !== "Invited Users" && t.Deleted === null)
      .filter((t) => t.Staffs.length > 0);
    setState({
      teams: cleanedTeams,
    });
  }, [props.teams]);

  return (
    <div
      className={props.className ? props.className : "organization-transparent"}
    >
      <div className="event-page overflow-hidden">
        <div
          className={
            props.isMobile
              ? "event-expennd-section w-100"
              : "event-expennd-section"
          }
        >
          <div
            className="text-head headerBg  d-flex align-items-center justify-content-between"
            style={{ padding: "10px" }}
          >
            <div className="d-flex align-items-center w-50">
              {/* <ArrowLeft onClick={() => props.toggle()} /> */}
              {props.showSideBar && (
                <buttom
                  className="btn mr-1"
                  onClick={() => props.setShowSideBar((prev) => !prev)}
                >
                  <i className="fas fa-bars"></i>
                </buttom>
              )}
              <div className={props.showSideBar ? "col-auto pl-0" : "col-auto"}>
                <h1>
                  <FormattedMessage
                    id="mailingList"
                    defaultMessage="Mailing List"
                  />
                </h1>
              </div>
            </div>
            <div
              className={
                props.isMobile
                  ? "d-flex w-50 justify-content-between"
                  : "d-flex w-50 justify-content-end "
              }
            >
              <button
                onClick={() => selectAll(emails, teams)}
                type="button"
                className={
                  props.isMobile
                    ? "btn btn secondary w-75"
                    : "btn btn secondary w-75"
                }
              >
                <FormattedMessage id="selectAll" defaultMessage="Select all" />
              </button>
              {!props.isMobile && (
                <button
                  className="btn btn-secondary ml-1 h-75"
                  onClick={() => props.setCurrentView("")}
                >
                  <i className="fas fa-angle-left"></i>
                </button>
              )}
              {props.isMobile && (
                <button
                  className="ml-2 btn btn-primary"
                  onClick={props.toggleMailingList}
                >
                  Done
                </button>
              )}
            </div>
          </div>

          <div
            className="event-inside"
            style={{
              overflowY: "auto",
              height: "calc(100vh - 60px)",
            }}
          >
            <div className="event-content">
              <div className="mailingList__section">
                {teams.map((team) => {
                  return (
                    <>
                      <div
                        key={team.GUID}
                        className="mailingList__section-header"
                      >
                        <h6>{team.Name}</h6>
                        <button
                          onClick={() => selectAllEmail(emails, team.Staffs)}
                          type="button"
                          className="btn btn-link "
                        >
                          <FormattedMessage
                            id="selectAll"
                            defaultMessage="Select all"
                          />
                        </button>
                      </div>
                      {team.Staffs.filter(
                        (s) => s.Account.Email !== null && s.Deleted === null
                      )
                        .filter((s) => s.Account.Email.length > 2)
                        .map((staff) => (
                          <MailContactItem
                            emails={emails}
                            staff={staff}
                            selectEmail={selectEmail}
                            className={"bg-white"}
                          />
                        ))}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailingListModal;
