const initialState = {
  countries: [],
  cities: [],
  venues: [],
  hotels: [],
  notes: [],
  notifications: [],
  sidebarExpanded: false,
  searchingHotels: false,
  sendingEmail: false,
  sharingLink: false,
  deletingSharingLink: false,
  guidLink: null,
};

export default initialState;
