import * as constant from "./constants";
import api from "../../../common/api";

export function editPassengers(passengers, success) {
  const urlStaff = "/Grounds/AddEditStaffBatch ";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_PASSENGERS,
      });
      const request = await api.post(urlStaff, passengers);
      dispatch({
        type: constant.EDITED_PASSENGERS,
        data: request.data,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_PASSENGERS_ERROR,
        data: error,
      });
    }
  };
}

export function deletePassenger(passenger, success) {
  const urlStaff = "/Grounds/AddEditStaffBatch ";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_PASSENGER,
      });
      const request = await api.post(urlStaff, [passenger]);
      dispatch({
        type: constant.DELETED_PASSENGER,
        data: request.data.Response[0],
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_PASSENGERS_ERROR,
        data: error,
      });
    }
  };
}

export function cleanPassengers(passengers, success) {
  const urlStaff = "/Grounds/AddEditStaffBatch ";
  return async (dispatch) => {
    dispatch({
      type: constant.CLEANING_PASSENGERS,
    });
    try {
      let passengersToSend = passengers.map((p) => ({
        ...p,
        Deleted: new Date(),
      }));
      const request = await api.post(urlStaff, passengersToSend);
      dispatch({
        type: constant.CLEANED_PASSENGERS,
        data: request.data.Response[0],
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_PASSENGERS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CLEANING_PASSENGERS:
    case constant.DELETING_PASSENGER:
    case constant.EDITING_PASSENGERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_PASSENGER: {
      return {
        ...state,
        loading: false,
        grounds: state.grounds.map((ground) => {
          if (ground.GUID === action.data.GroundGUID) {
            return {
              ...ground,
              GroundStaff: ground.GroundStaff.filter(
                (g) => g.GUID !== action.data.GUID
              ),
            };
          }
          return ground;
        }),
      };
    }
    case constant.EDITED_PASSENGERS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CLEANED_PASSENGERS: {
      return {
        ...state,
        loading: false,
        grounds: state.grounds.map((ground) => {
          if (ground.GUID === action.data.GroundGUID) {
            return {
              ...ground,
              GroundStaff: [],
            };
          }
          return ground;
        }),
      };
    }
    case constant.EDITING_PASSENGERS_ERROR:
    case constant.EDITING_PASSENGERS_ERROR:
    case constant.EDITING_PASSENGERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
