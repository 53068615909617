import api from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";

const url = `/Organizations/AddEditWithoutImage`;

export function editOrganization(organization, success) {
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_ORGANIZATION,
      });
      await api.post(url, organization);
      await db.table("organizations").put(organization);
      dispatch({
        type: constant.EDITED_ORGANIZATION,
        data: organization,
      });
      success();
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_ORGANIZATION: {
      return {
        ...state,
        loading: false,
        currentOrganization: action.data,
      };
    }
    case constant.EDITING_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
