import React, { Component } from "react";
import { IMAGES, ROUTES } from "../../constant";

export class WelcomeContainer extends Component {
  state = {
    loading: false,
  };
  render() {
    return (
      <div className="onboarding__container">
        <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
          <img
            src={IMAGES.LOGO_ALT}
            className="mx-auto img-fluid mb-4"
            alt="PLNIFY"
          />

          <img
            src={IMAGES.APPROVED_CIRCLE}
            style={{ maxWidth: "8%" }}
            className="mx-auto img-fluid mb-4"
            alt="PLNIFY"
          />

          <h4
            style={{ fontSize: "20px" }}
            className="text-white welcome__headline text-center mb-4"
          >
            You are good to go! <br />
            <small style={{ fontSize: "15px" }}>
              Click below to download the app from the stores
            </small>
          </h4>
          <div className="d-flex align-items-center justify-content-center text-center">
            <a
              target="_blank"
              className="mr-2"
              href="https://itunes.apple.com/us/app/plnify/id1001393718?mt=8"
            >
              <img src={IMAGES.APPLE_STORE} alt="PLNIFY App Store" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.valegoconsulting.Plnify&hl=en"
            >
              <img src={IMAGES.PLAY_STORE} alt="PLNIFY Play Store" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomeContainer;
