import * as constant from "./constants";
import api from "../../../common/api";
import { setBasicInfo } from "../../../utils";

export function updateSocialMedia(values) {
  const url = "User/Edit";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.UPDATING_BASIC_INFO,
      });
      const socialMediaUpdated = await api.post(url, values);
      setBasicInfo(socialMediaUpdated.data);
      dispatch({
        type: constant.UPDATED_BASIC_INFO,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.UPDATING_BASIC_INFO_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.UPDATING_BASIC_INFO: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.UPDATED_BASIC_INFO: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.UPDATING_BASIC_INFO_ERROR: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
}
