import React from "react";
import { DropDownComponent } from "../common";
import { DotsVertical } from "mdi-material-ui";
import {useIntl, injectIntl,FormattedMessage} from 'react-intl'
const Vehicle = (props) => {
  let {
    ground,
    deleteVehicle,
    editVehicle,
    vehicle,
    toggleVehicleModal,
  } = props;
  return (
    <div className="card shadow-sm my-1">
      <div className="card-body p-1 row mx-0">
        <div className="col-11 ">
          <b>{vehicle.VehicleType ? vehicle.VehicleType+" | " : "-"}</b>
          
          {vehicle.DriverName?<label className="ml-2 text-truncate">Drivers Name: {vehicle.DriverName}</label>: null}
          {vehicle.DriverPhone? <label className="ml-2 text-truncate">Contact: {vehicle.DriverPhone}</label>: null}
          
        </div>
        <div className="col-1 text-right px-0">

          <DropDownComponent
            direction="left"
            data={[
              {
               name:<FormattedMessage
                    id="Edit"
                    defaultMessage="Edit"/>,
                trigger: () => toggleVehicleModal(vehicle),
                border: true,
              },
              {
                name: <FormattedMessage
                id="delete"
                defaultMessage="Delete"/>,
                trigger: () => deleteVehicle(vehicle),
                delete: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
   
  );
};

export default Vehicle;
