import React, { useEffect } from "react";

const MenuItems = [
  {
    icon: "fa-bold",
    title: "font-weight",
  },
  {
    icon: "fa-italic",
    title: "font-style",
  },
  {
    icon: "fa-underline",
    title: "font-decoration",
  },
  {
    icon: "fa-strikethrough",
    title: "font-strikethrough",
  },
  {
    icon: "fa-palette",
    title: "color",
    input: "color",
  },
  {
    icon: "fa-highlighter",
    title: "background-color",
    input: "color",
  },
  {
    icon: "fa-font",
    title: "font-size",
    fontSize: true,
  },
  {
    icon: "fa-undo",
    title: "undo",
  },
];

const ExportItemStyleComponent = ({
  setSectionsParams,
  sectionsParams,
  section,
  selected,
  item,
}) => {
  const [styles, setStyles] = React.useState({});
  const [showFonts, setShowFonts] = React.useState(false);

  const handleColorChange = (color, menu) => {
    handleStyleChange(menu, color);
  };

  useEffect(() => {
    if (!selected) {
      setStyles({});
    }
  }, [selected]);

  const handleParams = (incommingStyles) => {
    setSectionsParams((prev) => {
      let currentStyles = prev[`${section}Styles`]?.Records ?? [];

      if (incommingStyles === "undo") {
        currentStyles = currentStyles.filter(
          (style) => style.GUID !== item.GUID
        );

        return {
          ...prev,
          [section + "Styles"]: {
            Container: null,
            Records: currentStyles,
          },
        };
      }

      if (
        currentStyles.length > 0 &&
        currentStyles.some((style) => style.GUID === item.GUID)
      ) {
        currentStyles = currentStyles.map((style) => {
          if (style.GUID === item.GUID) {
            return {
              GUID: item.GUID,
              styles: JSON.stringify(incommingStyles),
            };
          }
          return style;
        });
      } else {
        currentStyles.push({
          GUID: item.GUID,
          styles: JSON.stringify(incommingStyles),
        });
      }

      return {
        ...prev,
        [`${section}Styles`]: {
          Container: null,
          Records: currentStyles,
        },
      };
    });
  };

  const handleStyleChange = (menu, value) => {
    const fontStyles = {
      "font-weight": "bold",
      "font-style": "italic",
      "font-decoration": "underline",
      "font-strikethrough": "line-through",
    };

    if (!selected) return;

    setStyles((prev) => {
      let currentStyle = prev[menu.title] ?? false;

      if (menu.title === "undo") {
        setShowFonts(false);
        handleParams("undo");
        return {};
      }

      const fontValue =
        fontStyles[menu.title] === currentStyle ? null : fontStyles[menu.title];
      const newValue =
        fontValue && !currentStyle
          ? fontValue
          : value !== undefined
          ? value
          : !currentStyle;

      const newStyles = {
        ...prev,
        [menu.title]: newValue,
      };

      handleParams(newStyles);
      return newStyles;
    });
  };

  const handleFontSizeChange = (fontSize) => {
    setShowFonts((prev) => !prev);
    handleStyleChange({ title: "font-size" }, `${fontSize}px`);
  };

  return (
    <div className="export-style-menu-content border w-100 d-flex justify-content-center align-items-center border-top-0 px-4 pb-2">
      {MenuItems.map((menu, index) => {
        return (
          <button
            disabled={!selected}
            key={index + menu.title}
            onClick={
              menu.fontSize
                ? showFonts
                  ? () => {}
                  : () => setShowFonts((prev) => !prev)
                : () => handleStyleChange(menu)
            }
            name={menu.title}
            className={`btn position-relative ${
              styles[menu.title] ? "btn-outline-info" : ""
            } mr-1`}
          >
            {menu.fontSize && styles["font-size"] && (
              <span
                className="position-absolute"
                style={{ fontSize: "10px", top: 1, right: 4 }}
              >
                {styles["font-size"]?.replace("px", "")}
              </span>
            )}
            <i
              className={`fas ${menu.icon}`}
              style={{ color: styles[menu.title] }}
            ></i>

            {menu.fontSize && showFonts && (
              <select
                onChange={({ target }) => handleFontSizeChange(target.value)}
                className={"position-absolute h-100"}
                style={{ top: 0, left: 0, opacity: 1 }}
                defaultValue={styles["font-size"]?.replace("px", "")}
              >
                <option value="12">12</option>
                <option value="14">14</option>
                <option value="16">16</option>
                <option value="18">18</option>
                <option value="20">20</option>
                <option value="22">22</option>
                <option value="24">24</option>
                <option value="26">26</option>
                <option value="28">28</option>
                <option value="30">30</option>
                <option value="32">32</option>
              </select>
            )}

            {menu?.input && (
              <input
                type={menu.input}
                className={"position-absolute w-100 h-100"}
                style={{ top: 0, left: 0, opacity: 0 }}
                name={menu.title + "Input"}
                onBlur={({ target }) => handleColorChange(target.value, menu)}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default ExportItemStyleComponent;
