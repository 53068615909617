import * as CONSTANT from "./constants";

export function emptyVenues() {
  return (dispatch) => {
    dispatch({
      type: CONSTANT.CLEAR_ALL_VENUES,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.CLEAR_ALL_VENUES: {
      return {
        ...state,
        venues: [],
      };
    }
    default:
      return state;
  }
}
