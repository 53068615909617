import React from "react";
import { Menu, Item, Separator, Submenu } from "react-contexify";

export const ContextMenu = ({ menuId, ContextMenuOptions }) => {
  return (
    <div className="w-100">
      <Menu id={menuId} disableBoundariesCheck={false}>
        {ContextMenuOptions?.map((option, index) => {
          switch (option.type) {
            case "item":
              return (
                <div key={option.id + option.title}>
                  {index > 0 && index <= ContextMenuOptions.length - 1 && (
                    <Separator />
                  )}
                  <Item onClick={() => option.actions()} id={option.id}>
                    {option.destructive ? (
                      <h6 className="text-danger">{option.title}</h6>
                    ) : (
                      option.title
                    )}
                  </Item>
                </div>
              );
            case "submenu":
              return (
                <div key={option.id + option.title}>
                  {index > 0 && index <= ContextMenuOptions.length - 1 && (
                    <Separator />
                  )}
                  <Submenu label={option.label}>
                    {option.options.map((option, index) => {
                      return (
                        <Item
                          onClick={() => option.actions(option.title)}
                          key={option.id + option.title}
                          id={option.id}
                        >
                          {option.destructive ? (
                            <h6 className="text-danger">{option.title}</h6>
                          ) : (
                            option.title
                          )}
                        </Item>
                      );
                    })}
                  </Submenu>
                </div>
              );

            default:
              break;
          }
        })}
      </Menu>
    </div>
  );
};
