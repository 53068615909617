import * as constant from "./constants";
import api from "../../../common/api";

export function deleteShareLink(link, success) {
  const url = "/Collab/GenerateLink";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_SHARING_LINK,
      });
      await api.post(url, { ...link, Deleted: new Date() });
      success();
    } catch (e) {
      dispatch({
        type: constant.DELETING_SHARING_LINK_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_SHARING_LINK: {
      return {
        ...state,
        deletingSharingLink: true,
      };
    }
    case constant.DELETED_SHARING_LINK: {
      return {
        ...state,
        deletingSharingLink: false,
      };
    }
    case constant.DELETING_SHARING_LINK_ERROR: {
      return {
        ...state,
        deletingSharingLink: false,
      };
    }
    default:
      return state;
  }
}
