import React, { useRef, useState } from "react";
import { ModalComponent } from "./modal";
import SharingHub from "./sharingHub";
import Draggable from "react-draggable";
import { EditAttachmentModal } from "../attachments/EditAttachment";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IframeGoogleDoc, ToastNotificationSuccess } from "..";
import { fileIcon } from "../../page/files/helper";
import LazyLoadImage from "./LazyLoadImage";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import RichTextComponent from "./RichTextEditorComponent";
import { PDFComponent } from "../../page/files/editors/PDFComponent";

export const FileDetailComponent = ({
  confirmDeleteAttachment,
  editAttachment,
  file,
  type,
  handleSubmit,
}) => {
  const urlRef = useRef(null);
  const dispatch = useDispatch();
  const allowedFormats = ["jpg", "png", "jpeg", "pdf", "mp3", "folder"];
  const modules = {
    toolbar: false, // Set the toolbar option to false to hide it
  };
  const fileFormat =
    file.Type === "folder" ? "folder" : file.Path.split(".").pop();
  const [viewerModal, setViewerModal] = useState(false);
  const [viewModalType, setViewModalType] = useState("preview");
  const [ShareModal, setShareModal] = useState(false);
  const [showEditAttachment, setShowEditAttachment] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(true);
  const [showPDFView, setShowPDFView] = useState(false);

  const toggleViewerModal = (type) => {
    setViewerModal((prev) => !prev);
    if (type) {
      setViewModalType(type);
    } else {
      setViewModalType("preview");
    }
  };

  const togglePDFView = () => {
    setShowPDFView((prev) => !prev);
  };

  const toggleEditAttachment = () => {
    setShowEditAttachment((prev) => !prev);
  };

  const toggleShare = () => {
    setShareModal(!ShareModal);
  };

  const iframePreview = (icon, file) => {
    switch (icon) {
      case "jpg":
      case "png":
      case "jpeg":
        return (
          <div className="image-viewer">
            <LazyLoadImage
              src={`https://service.plnify.com/files/${file.Path}`}
            />
          </div>
        );
      case "pdf":
        return viewModalType === "modal" ? (
          <PDFComponent
            toggle={togglePDFView}
            file={{ Path: file.Path }}
            modal={showPDFView}
            hideHeader
          />
        ) : (
          <IframeGoogleDoc
            url={`https://service.plnify.com/files/${file.Path}`}
          />
        );

      case "mp3":
        return (
          <div className="w-100 d-flex flex-column align-items-center justify-content-between">
            {/* <img className="w-25" src={IMAGES.DEFAULT_FILE} /> */}
            <audio controls className="w-100">
              <source
                src={`https://service.plnify.com/files/${file.Path}`}
                type="audio/ogg"
              />
              <source
                src={`https://service.plnify.com/files/${file.Path}`}
                type="audio/mpeg"
              />
            </audio>
          </div>
        );

      case "folder":
        return (
          <i
            style={
              file.Color
                ? { color: file.Color }
                : file.Type === "folder"
                ? { color: "#E6E8EA" }
                : null
            }
            className={"fa fa-folder fa-2xl"}
          />
        );

      default:
        if (file.Type === "folder") {
          return (
            <i
              style={
                file.Color
                  ? { color: file.Color }
                  : file.Type === "folder"
                  ? { color: "#E6E8EA" }
                  : null
              }
              className={"fa fa-folder fa-2xl"}
            />
          );
        } else {
          return (
            <IframeGoogleDoc
              url={`https://service.plnify.com/files/${file.Path}`}
            />
          );
        }
    }
  };

  return (
    <div className="attachment__detail d-flex flex-column px-2">
      <ModalComponent
        modal={ShareModal}
        toggle={() => toggleShare()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={file}
            toggle={toggleShare}
            ParentSectionGUID={file.EventGUID}
            Type={"ATTACHMENT"}
            sectionGUID={file.GUID}
          />
        }
      />

      <ModalComponent
        modal={viewerModal}
        toggle={() => toggleViewerModal()}
        childeren={
          <>
            <Draggable
              axis="x"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[25, 25]}
              scale={1}
            >
              <div className="viewer-documents d-flex flex-column">
                <div className="viewer-documents-drag"></div>
                {file.Path && iframePreview(file.Path.split(".").pop(), file)}
              </div>
            </Draggable>
          </>
        }
      />

      <EditAttachmentModal
        toggle={toggleEditAttachment}
        show={showEditAttachment}
        attachment={file}
        type={type}
        handleSubmit={handleSubmit}
      />

      <div className="attachment__detail-actions d-flex justify-content-end align-items-center">
        <h4>
          <FormattedMessage
            id={type.toLowerCase()}
            defaultMessage={`${
              type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()
            }`}
          />
        </h4>
        <div className="d-flex">
          <button
            type="button"
            className="btn-primary btn btn-sm mr-2 border-0"
            onClick={toggleEditAttachment}
          >
            <FormattedMessage id="Edit" defaultMessage="Edit" />
          </button>
          {(file.Path && file.Path.includes("dropbox")) ||
          file.Path.includes("google") ||
          file.Path.includes("1drv") ||
          file.Path.includes("http") ? (
            <button
              onClick={() => window.open(file.Path, "noopener")}
              className="btn-sm btn btn-secondary mr-2 border-0"
            >
              <i className="fas fa-eye"></i>
            </button>
          ) : (
            <button
              onClick={() => {
                if (file.Path.includes("pdf")) {
                  toggleViewerModal("modal");
                } else {
                  toggleViewerModal();
                }
              }}
              className="btn-sm btn btn-secondary mr-2 border-0"
            >
              <i className="fas fa-eye"></i>
            </button>
          )}

          <a
            href={
              file.Path.includes("https")
                ? file.Path
                : `https://service.plnify.com/files/${file.Path}`
            }
            target="_blank"
            style={{ flex: 0, cursor: "pointer" }}
            className="btn btn-sm btn-success mr-2 border-0"
          >
            <i className="fas fa-download"></i>
          </a>

          <button
            onClick={() => {
              toggleShare();
            }}
            className="btn-sm btn btn-secondary shareBg mr-2 border-0"
          >
            <i className="fa fa-share-alt"></i>
          </button>

          <button
            onClick={() => confirmDeleteAttachment(file)}
            className=" btn-sm btn-danger btn border-0"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div className="attachment__file-container border-0">
        {fileFormat === "folder" ? (
          <i
            style={
              file.Color
                ? { color: file.Color }
                : file.Type === "folder"
                ? { color: "#E6E8EA" }
                : null
            }
            className={"fa fa-folder fa-7x"}
          />
        ) : allowedFormats.includes(fileFormat) ? (
          <div className="file-preview">{iframePreview(fileFormat, file)}</div>
        ) : (
          <>
            <i className={fileIcon(file) + " fa-7x"}></i>
          </>
        )}

        {/* <div className="attachment__detail-main mt-4">
          <h6>{file.Name}</h6>
          {file.Size && <p>{file.Size}</p>}
        </div> */}
      </div>

      <div className="attachment__section">
        <div className=" attachment__name">
          <a
            className="clean-link "
            onClick={toggleEditAttachment}
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >
            {file.Name}
          </a>
        </div>

        <div className="attachment__name">
          <h6>
            <FormattedMessage id="notes" defaultMessage="Notes" />
          </h6>
        </div>
        <div
          className="border px-2 text-black-50"
          style={{ height: "200px", cursor: "pointer" }}
          onClick={toggleEditAttachment}
        >
          <span dangerouslySetInnerHTML={{ __html: file?.Note }}></span>
        </div>
      </div>
      {/* <Formik
        enableReinitialize={true}
        initialValues={file}
        onSubmit={(values) => editAttachment(values)}
      >
        {(props) => (
          <Form>
            <div className="attachment__section mt-4">
              <div className=" attachment__name">
                <h6>
                  <FormattedMessage id="fileName" defaultMessage="File Name" />
                </h6>
              </div>
              <div className="attachment__input">
                <Field
                  onBlur={() => props.handleSubmit()}
                  id="name"
                  className="form-control"
                  type="text"
                  name="Name"
                  disabled={disabledInputs}
                  autoComplete="off"
                />
                <ErrorMessage
                  className="error-message"
                  component="div"
                  name="Name"
                />
              </div>
            </div>

            <div className="d-none">
              <input
                ref={urlRef}
                id="url"
                type="text"
                defaultValue={
                  (file.Path && file.Path.includes("dropbox")) ||
                  file.Path.includes("google") ||
                  file.Path.includes("1drv")
                    ? `${file.Path}`
                    : `https://app.plnify.com/files/${file.Path}`
                }
              />
            </div>
            <div className="attachment__name">
              <h6>
                <FormattedMessage id="notes" defaultMessage="Notes" />
              </h6>
            </div>

            {/* <ReactQuill
              className="text-dark"
              theme="snow"
              modules={modules}
              value={props.values.Note}
              readOnly={true}
            /> */}

      {/* <div className="border px-2" style={{ height: "200px" }}>
              <span
                dangerouslySetInnerHTML={{ __html: props.values.Note }}
              ></span>
            </div>
          </Form> */}
      {/* )} */}
      {/* </Formik> */}
    </div>
  );
};
