export const TICKETS_PASSES_FETCHED = "TICKETS_PASSES_FETCHED";
export const SELECT_EVENT_GUID = "SELECT_EVENT_GUID";
export const TICKETS_PASSES_FETCHING_ERROR = "TICKETS_PASSES_FETCHING_ERROR";

export const UPDATING_TICKETS = "UPDATING_TICKETS";
export const UPDATING_TICKETS_ERROR = "UPDATING_TICKETS_ERROR";
export const UPDATED_TICKETS = "UPDATED_TICKETS";

export const CREATING_TICKET = "CREATING_TICKET";
export const CREATED_TICKET = "CREATED_TICKET";
export const CREATING_TICKET_ERROR = "CREATING_TICKET_ERROR";

export const UPDATING_COMPS = "UPDATING_COMPS";
export const UPDATED_COMPS = "UPDATED_COMPS";
export const UPDATING_COMPS_ERROR = "UPDATING_COMPS_ERROR";
