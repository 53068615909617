import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import { DropDownList } from "..";
import api from "../../common/api";
import apiWithPower from "../../common/apiWithPower";
import { ToastNotificationSuccess } from "../common";
import FormField from "../form/formField";

export default function BasicInfo({ onlyRead, ...props }) {
  const intl = useIntl();
  const { editBasicInfo, requesData, setRequesData, user } = props;
  const [cities, setCities] = useState([]);

  useEffect(() => {
    console.log("state basic info", props);
  }, []);

  const updateBasicInfo = async (values) => {
    const request = await api.post(`/User/Edit`, {
      ...editBasicInfo,
      ...values,
    });
    setRequesData && setRequesData(request.data);
    if (request.statusText === "OK") {
      ToastNotificationSuccess(1, "Basic info Succesfully Updated");
    }
  };

  const searchCities = useDebouncedCallback(async (query) => {
    const API = apiWithPower;
    const request = await API.getAction(
      `/venue/CityAutoComplete?Query=${query}`,
      "Query=" + query,
      null,
      true,
      "cities"
    );
    setCities(request.data);
  }, 300);

  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", place.split(",")[1].trim());
      props.setFieldValue("Country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", "");
      props.setFieldValue("Country", place.split(",")[1].trim());
    }
  }

  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">
        <Formik
          enableReinitialize
          initialValues={
            requesData?.Name !== undefined
              ? {
                  ...requesData,
                  Birthday: requesData?.Birthday
                    ? moment(requesData?.Birthday).format("yyyy-MM-DD")
                    : null,
                }
              : editBasicInfo?.Name !== undefined
              ? {
                  ...editBasicInfo,
                  Birthday: editBasicInfo?.Birthday
                    ? moment(editBasicInfo?.Birthday).format("yyyy-MM-DD")
                    : null,
                }
              : user?.Name !== undefined
              ? {
                  ...user,
                  // Name:"",
                  Birthday: user?.Birthday
                    ? moment(user?.Birthday).format("yyyy-MM-DD")
                    : null,
                }
              : null
          }
          onSubmit={(values) => updateBasicInfo(values)}
        >
          {(props) => (
            <Form autoComplete="off" className="pt-1 row">
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={
                  <FormattedMessage
                    id="firstName.signUp"
                    defaultMessage="First Name"
                  />
                }
              >
                <Field
                  className="form-control"
                  type="text"
                  name="Name"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Name"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={
                  <FormattedMessage
                    id="lastName.signUp"
                    defaultMessage="Last Name"
                  />
                }
              >
                <Field
                  className="form-control"
                  type="text"
                  name="LastName"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="LastName"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                label="Email"
                additionalClasses="px-0 pr-3"
              >
                <Field
                  className="form-control"
                  type="text"
                  name="Email"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Email"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={<FormattedMessage id="mobile" defaultMessage="Mobile" />}
              >
                <Field
                  className="form-control"
                  type="text"
                  name="Phone"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Phone"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                label={<FormattedMessage id="role" defaultMessage="Role" />}
                additionalClasses="px-0 pr-3"
              >
                <Field
                  className="form-control"
                  type="text"
                  name="Ocupation"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Occupation"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={
                  <FormattedMessage
                    id="dateBirth"
                    defaultMessage="Date of Birth"
                  />
                }
              >
                <Field
                  className="form-control"
                  type="date"
                  name="Birthday"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Birthday"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={
                  <FormattedMessage id="city.signUp" defaultMessage="City" />
                }
              >
                <Field
                  autoComplete="off"
                  name="City"
                  type="text"
                  className="form-control"
                  placeholder="City*"
                  readOnly={onlyRead}
                  // onBlur={() => updateEvent(props.values)} =>actualizar la vista del usuario
                  onChange={(e) => {
                    props.setFieldValue("City", e.currentTarget.value);
                    if (e.currentTarget.value.length > 3) {
                      searchCities.callback(e.currentTarget.value);
                    }
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  component="div"
                  name="City"
                />
                <DropDownList
                  searchList={cities}
                  setData={(id) => {
                    setStateOrNotState(cities[id].Address, props);

                    setCities([]);
                  }}
                  displayValue={"Address"}
                />
              </FormField>

              <FormField
                additionalClasses="px-0 pr-3"
                fullWidth={false}
                label={<FormattedMessage id="state" defaultMessage="State" />}
              >
                <Field
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="State"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="State"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                additionalClasses="px-0 pr-3"
                label={
                  <FormattedMessage
                    id="country.signUp"
                    defaultMessage="Country"
                  />
                }
              >
                <Field
                  className="form-control"
                  type="text"
                  name="Country"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Country"
                  component="div"
                />
              </FormField>
              <FormField
                fullWidth={false}
                label={
                  <FormattedMessage
                    id="gender.signUp"
                    defaultMessage="Gender"
                  />
                }
              >
                <Field as="select" name="Gender" className="form-control" disabled={onlyRead}>
                  <option value="0">
                    {intl.formatMessage({ id: "male" })}
                  </option>
                  <option value="1">
                    {intl.formatMessage({ id: "female" })}
                  </option>
                  <option value="2">
                    {intl.formatMessage({ id: "other" })}
                  </option>
                </Field>
              </FormField>
              {!onlyRead && (
                <div className="col-12 text-right">
                  {props.loading ? (
                    <div
                      className="spinner-grow text-primary  spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      disabled={props.loading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {props.loading ? (
                        "Saving..."
                      ) : (
                        <FormattedMessage id="save" defaultMessage="Save" />
                      )}
                    </button>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

// export default BasicInfo;
