import * as CONSTANT from "./constants";

export function emptyCountries() {
  return dispatch => {
    dispatch({
      type: CONSTANT.CLEAR_ALL_COUNTRIES
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.CLEAR_ALL_COUNTRIES: {
      return {
        ...state,
        countries: []
      };
    }
    default:
      return state;
  }
}
