import * as CONSTANT from "./constants";
import api from "../../../common/api";

const sub_url = `/venue/CityAutoComplete?Query=`;

export function searchCity(query) {
  return async dispatch => {
    dispatch({
      type: CONSTANT.GETTING_ALL_CITIES
    });
    try {
      const request = await api.get(`${sub_url}${query}`);
      dispatch({
        type: CONSTANT.GET_ALL_CITIES,
        data: request.data
      });
    } catch (error) {
      dispatch({
        type: CONSTANT.GETTING_ALL_CITIES_ERROR,
        error: error
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.GETTING_ALL_CITIES: {
      return {
        ...state,
        cities: []
      };
    }
    case CONSTANT.GET_ALL_CITIES: {
      return {
        ...state,
        cities: action.data
      };
    }
    case CONSTANT.GETTING_ALL_CITIES_ERROR: {
      return {
        ...state,
        cities: [{ name: action.error }]
      };
    }
    default:
      return state;
  }
}
