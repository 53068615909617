import React from "react";

import { Modal } from "react-bootstrap";

export const SideModal = ({
  toggle,
  setEmail,
  handleSuccess,
  show,
  sent,
  email,
  children,
}) => {
  return (
    <Modal
      show={show}
      onHide={toggle}
      fullscreen={true}
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      {children}
    </Modal>
  );
};
