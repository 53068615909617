import React from "react";
import PropTypes from "prop-types";

const FormField = ({
  children,
  fullWidth,
  label,
  icon,
  additionalClasses,
  subLabel,
  subAction,
  ...props
}) => {
  let theWidth = fullWidth
    ? "col-12 " + (additionalClasses !== undefined ? additionalClasses : null)
    : "col-6 " + (additionalClasses !== undefined ? additionalClasses : null);
  if (additionalClasses !== undefined) {
    //  theWidth = additionalClasses;
  }

  return (
    <div className={theWidth} {...props}>
      <div className="form-group">
        <label className="d-flex align-items-center">
          {icon && <img src={icon} className="mr-1" width="16" />}
          {label}{" "}
          <small className="ml-1" onClick={() => subAction()}>
            {subLabel}
          </small>
        </label>
        {children}
      </div>
    </div>
  );
};

FormField.propTypes = {
  fullWidth: PropTypes.bool,

  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.func,
  ]),
  type: PropTypes.string,
  children: PropTypes.node,
  additionalClasses: PropTypes.string,
};

export default FormField;
