import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as commonActions from "../common/redux/actions";
import { ROUTES } from "../../constant/routes";

class CleanPage extends Component {
  componentDidMount() {
    this.props.actions.cleanUpSession(() =>
      this.props.history.push(ROUTES.CALENDAR)
    );
  }
  render() {
    return (
      <div>
        <div>Cleaning up...</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.common.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...commonActions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CleanPage);
