import React from "react";
import { IMAGES } from "../../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../../components/form/formField";
import { ComponentLoader } from "../../../components/common";
import { Link } from "react-router-dom";
import { newPasswordSchema } from "../../../utils/validationsSchemas";

const ChangePassword = ({
  changePassword,
  token,
  history,
  loading,
  requested,
}) => {
  return (
    <div className="onboarding__container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "calc(100vh)" }}
      >
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 text-align-center d-flex align-items-center justify-content-center">
            <img
              src={IMAGES.LOGO_ALT}
              width="138"
              alt="PLNIFY"
              className="mx-auto mb-5"
            />
          </div>
          <div className="col-md-5 px-0 changePassword-form shadow-sm">
            <div className="text-head">
              <div className="text-left">
                <h1 className="text-white">Change your password</h1>
              </div>
            </div>
            <p className="changePassword-form__sentence">
              A strong password helps prevent unauthorized access to your
              account
            </p>
            {requested ? (
              <div className="text-center">
                <p className="changePassword-form__sentence">
                  Password Changed.
                </p>
                <Link to="/">Back to home</Link>
              </div>
            ) : (
              <Formik
                initialValues={{ newPassword: "", confirmNewPassword: "" }}
                onSubmit={(values) =>
                  changePassword(values.newPassword, token, () =>
                    history.push("/")
                  )
                }
                validationSchema={newPasswordSchema}
              >
                <Form className=" changePassword-form__inner ">
                  <FormField fullWidth={true} label="New password">
                    <Field
                      className="form-control"
                      type="password"
                      name="newPassword"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="newPassword"
                      component="div"
                    />
                  </FormField>
                  <FormField fullWidth={true} label="Confirm new password">
                    <Field
                      className="form-control"
                      type="password"
                      name="confirmNewPassword"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="confirmNewPassword"
                      component="div"
                    />
                  </FormField>

                  <div className="submit-button text-center">
                    {loading ? (
                      <ComponentLoader loading={loading} />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-block btn-primary"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
