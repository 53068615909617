import React, { useEffect, useState } from "react";

import { IMAGES, ROOM_TYPES } from "../../constant";
import { ToastNotificationSuccess, SimpleLoader } from "../common";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import api from "../../common/api";
import { useFormik } from "formik";
import GeneralUserItem from "../common/GeneralUserItem";
import {useIntl, FormattedMessage} from 'react-intl'

const AddEditSingleAssigneeHotel = props => {

  const [isLoading, setIsLoading] = useState(false);

  const loadRoomTypes = () => {
    let rooms = ROOM_TYPES.map((roomType, index) => {
      return (<option value={roomType.value} key={index}>{roomType.label}</option>)
    });

    return rooms;
  }

  const handleSubmit = async (selected) => {
    try {

      setIsLoading(!isLoading);

      await api.post("/HotelReservations/AddEditMultiple", [selected]);
      setIsLoading(!isLoading);



      props.toggle(selected, true);

    } catch (ex) {
      console.error(ex)
    }
  }

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: props.model,
    // validationSchema,
    onSubmit: handleSubmit,
  });


  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <div className="d-flex align-items-center">
                <h1><FormattedMessage
                    id="Edit"
                    defaultMessage="Edit"/></h1>


              </div>

            </div>
            <div className="d-none close-icon d-none" onClick={() => props.toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content text-dark">
              <Scrollbars style={{ width: "100%", height: "calc(20vh)" }}>
                <div className="row py-2">
                  <div className="col-12 my-2">
                    <GeneralUserItem account={props.model.Account} />
                  </div>
                  <div className="col-6 my-2">
                    <input type="date" className='form-control py-0'
                      //   min={moment().format("YYYY-MM-DD")}

                      value={formik?.values?.CheckInDate ?
                        moment(formik?.values?.CheckInDate).format("YYYY-MM-DD") :
                        moment(props?.hotel?.Checkin).format("YYYY-MM-DD")}
                      onChange={e => {
                        try {
                          if (!e.target.value) {
                            return;
                          }
                          formik.setFieldValue("CheckInDate", moment(e.target.value).format("YYYY-MM-DD"))


                          //formik.values.CheckInDate.set(e.target.value)
                          //      props.updateSelected(state);
                        } catch (ex) {
                          console.error(ex);
                        }
                      }}

                    />
                  </div>
                  <div className="col-6 my-2">
                    <input type="date" className='form-control py-0'
                      //   min={moment().format("YYYY-MM-DD")}

                      value={formik?.values?.CheckOutDate ? moment(formik?.values?.CheckOutDate).format("YYYY-MM-DD") :
                        moment(props?.hotel?.Checkout).format("YYYY-MM-DD")}
                      //value={moment(formik.values.CheckOutDate.value).format("YYYY-MM-DD")}
                      onChange={e => {
                        try {
                          if (!e.target.value) {
                            return;
                          }

                          formik.setFieldValue("CheckOutDate", moment(e.target.value).format("YYYY-MM-DD"))

                          // props.updateSelected(state);
                        } catch (ex) {
                          console.error(ex);
                        }
                      }}
                    />
                  </div>

                  <div className="col-6 my-2">

                    <input type="text" className='form-control' placeholder='Rsv.'

                      value={formik.values.Number}
                      onChange={e => {
                        formik.setFieldValue("Number", e.target.value);

                      }}
                    />
                  </div>
                  <div className="col-6 my-2">
                    <select className="form-control"
                      value={formik.values.RoomType === null ? "" : formik.values.RoomType}
                      onChange={e => {
                        formik.setFieldValue("RoomType", e.target.value);


                      }}

                    >
                      <option value="">Room Type</option>
                      {loadRoomTypes()}
                    </select>

                  </div>
                </div>
              </Scrollbars>
            </div>
            <div className="button-section py-3 d-flex align-items-center justify-content-center">
              {isLoading ? (
                <SimpleLoader loading={isLoading} />
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mx-2" onClick={() => formik.handleSubmit()}
                >
    <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                </button>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditSingleAssigneeHotel;
