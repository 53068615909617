import React from "react";

import { Modal } from "react-bootstrap";
import ForgotPasswordContainer from "./forgotPasswordContainer";
import SuccessPopup from "./successPopup";

export const BootStrapModal = ({
  toggle,
  setEmail,
  handleSuccess,
  open,
  sent,
  email,
}) => {
  return (
    <Modal show={open} onHide={toggle} backdrop="static">
      {sent ? (
        <SuccessPopup email={email} toggle={toggle} />
      ) : (
        <ForgotPasswordContainer
          toggle={toggle}
          setEmail={setEmail}
          handleSuccess={handleSuccess}
        />
      )}
    </Modal>
  );
};
