import React from "react";
import PropTypes from "prop-types";
import { IMAGES } from "../../../constant";
import { Link } from "react-router-dom";

const ConfirmEmail = (props) => {
  return (
    <div className="main-container">
      <div className="dashboard-screen">
        <div className="dashboard-content">
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-12 text-center confirmEmail shadow-sm">
              <img
                src={IMAGES.SUCCESS_IMAGE}
                alt="Confirmation Email"
                className="img-responsive mb-3 confirmEmail__image"
              />
              <img
                src={IMAGES.LOGO}
                alt="Confirmation Email"
                className="img-responsive mb-3 confirmEmail__logo"
              />
              <h3>Great! You've successfully confirmed your Email!</h3>
              <p className="confirmEmail__mail">test@mail.com</p>
              <button className="btn btn-primary confirmEmail__button">
                <Link to="/dashboard/:id">Back to Home</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmEmail.propTypes = {};

export default ConfirmEmail;
