export const getEventGroups = (events) => {
  const eventGroupsAll = [];

  events.forEach((event) => {
    if (
      !eventGroupsAll.includes(event?.EventGroup?.GUID) &&
      event?.EventGroup?.GUID
    ) {
      eventGroupsAll.push(event.EventGroup);
    }
  });

  const eventGroups = Array.from(
    eventGroupsAll.reduce((guidSet, obj) => {
      if (!guidSet.has(obj.GUID)) {
        guidSet.add(obj.GUID);
      }
      return guidSet;
    }, new Set()),
    (guid) => eventGroupsAll.find((obj) => obj.GUID === guid)
  );

  return eventGroups;
};
