import { CalendarBlank, CogSyncOutline } from "mdi-material-ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ModalComponent, ToastNotificationSuccess } from "..";
import { IMAGES, ROUTES } from "../../constant";
import { Counter, DropDownComponent } from "../common";
import EmailModal from "../hotel/emailModal";
import AddNotes from "../notes/AddNotes";
import TodoModal from "../todo/TodoModal";
import { Event } from "./event";
import {
  Email,
  Export,
  Eye,
  Folder,
  FormatListChecks,
  MapMarkerRadius,
  Sticker,
} from "mdi-material-ui";
import { FormattedMessage } from "react-intl";
import Swal from "sweetalert2";
import FilesModal from "../../page/files/filesModal";
import {
  getBasicInfo,
  getCurrentEventGUID,
  getOrganization,
  isEqual,
} from "../../utils";
import OnlineUsers from "../meet/OnlineUsers";
import EventHistory from "./EventHistory";
import MarkerModal from "./MarkerModal";
import MoreDetails from "./MoreDetails";
import { MoveEventModal } from "./moveEventModal";
import uuid from "uuid";
import { addList } from "../../page/todoPage/redux/addList";
import { useDispatch } from "react-redux";
import { Loading } from "../common/loading";
import SkeletonLoading from "../common/EventSkeletonLoading";
import { SandwichButton } from "../common/SandwichButton";
import { handleCloseEmail } from "../../utils/closeMailModal";

const EventHeader = (props) => {
  const [state, setState] = useState({
    notesModal: false,
    mailModal: false,
    todoModal: false,
    markerModal: false,
    connection: null,
    fileModal: false,
    showEventHistory: false,
    connectedUsers: [],
    event: props.event,
  });
  const [exportType, setExportType] = useState("email");
  const dispatch = useDispatch();

  const exitUser = useCallback(
    async (user) => {
      const { connection } = props;
      if (connection !== null && connection.connectionState === "Connected") {
        try {
          await connection.invoke(
            "Exit",
            user.AccountGUID,
            user.SectionGUID,
            user.SectionType,
            user.ParentSectionGuid
          );
        } catch (error) {
          console.log({ error });
        }
      }
    },
    [props]
  );

  const changeOfEvent = useCallback(
    async (user, prevEvent) => {
      const { connection } = props;
      if (connection === null) {
        return;
      }
      await connection.invoke(
        "Exit",
        user.AccountGUID,
        prevEvent,
        user.SectionType,
        user.ParentSectionGuid
      );
      await connection.invoke(
        "Enter",
        user.AccountGUID,
        getCurrentEventGUID(),
        user.SectionType,
        user.ParentSectionGuid
      );
    },
    [props]
  );

  useEffect(() => {
    const prevProps = state;

    if (
      props.event.NotesCount !== props.notes.length &&
      props?.notes?.length === 0
    ) {
      setState((prev) => ({ ...prev, countNote: props.event.NotesCount }));
    }
    //   if(props.event.NotesCount !== state.countNote){
    //     loadData()
    //   }

    // Commented until we re-integrated the socket again
    const { connection } = props;

    const user = {
      AccountGUID: getBasicInfo().GUID,
      SectionGUID: getCurrentEventGUID(),
      SectionType: "Event",
      ParentSectionGuid: "",
    };
    if (!isEqual(prevProps.connection, props.connection)) {
      if (connection.connectionState === "Connected") {
        connection.start().then(async () => {
          console.log("websocket conectado");
          if (connection.connectionState === "Connected") {
            await connection.invoke(
              "Enter",
              user.AccountGUID,
              user.SectionGUID,
              user.SectionType,
              user.ParentSectionGuid
            );
          }
        });
      }
    }
    if (props.previousEvent.GUID !== props.event.GUID) {
      if (connection.connectionStarted) {
        changeOfEvent(user, prevProps.event.GUID);
      }
    }

    return () => {
      const user = {
        AccountGUID: getBasicInfo().GUID,
        SectionGUID: getCurrentEventGUID(),
        SectionType: "Event",
        ParentSectionGuid: "",
      };
      exitUser(user);
    };
  }, []);

  // const setConnectedUsers = (data) => {
  //   setState(prev=>({...prev,
  //     connectedUsers: data,
  //   }));
  // };

  // const setConnection = (data) => {
  //   setState(prev=>({...prev,
  //     connection: data,
  //   }));
  // };

  const toggleNotesModal = () => {
    setState((prev) => ({ ...prev, notesModal: !prev.notesModal }));
  };

  const toggleMailModal = () => {
    if (state.mailModal)
      return setState((prev) => ({ ...prev, mailModal: !state.mailModal }));

    // Swal.fire({
    //   text: "How would you like to send this email?",
    //   imageUrl: IMAGES.LOGO_BLACK_P,
    //   imageWidth: 80,
    //   imageHeight: 80,
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "secondary",
    //   confirmButtonText: "Generate PDF",
    //   cancelButtonText: "As Body of Email",
    // }).then((result) => {
    //   if (result.value) {
    //     setExportType("export");
    //     setState((prev) => ({ ...prev, mailModal: !state.mailModal }));
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    setExportType("email");
    setState((prev) => ({ ...prev, mailModal: !state.mailModal }));
    //   }
    // });
  };

  const toggleMoveEventModal = () => {
    setState((prev) => ({ ...prev, moveEventModal: !state.moveEventModal }));
  };

  const toggleFilesModal = () => {
    setState((prev) => ({ ...prev, fileModal: !state.fileModal }));
  };

  const toggleMarkerModal = () => {
    setState((prev) => ({ ...prev, markerModal: !state.markerModal }));
  };

  const toggleTodoModal = () => {
    setState((prev) => ({ ...prev, todoModal: !state.todoModal }));
  };

  const toggleHistory = () => {
    setState((prev) => ({
      ...prev,
      showEventHistory: !state.showEventHistory,
    }));
  };

  const handlePublish = (event) => {
    let message = !event?.LastSync
      ? "publish this event"
      : "make this event private";
    Swal.fire({
      text: `Are you sure you want to ${message}?`,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "yes",
      cancelButtonText: "cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(toggleLastSync(event));
      }
    });
  };

  const handleAddTodo = (name, description) => {
    const allStaffs = [];
    const user = getBasicInfo();
    const organization = getOrganization();
    const event = getCurrentEventGUID();
    const list = {
      Date: new Date(),
      EventGUID: event,
      GUID: uuid.v4(),
      Name: name,
      Note: description,
    };
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
      OrganizationGUID: organization.GUID,
    };
    props.teams
      .filter((t) => t.Deleted === null)
      .forEach((team) => {
        team.Staffs.filter((s) => s.Deleted === null).map((s) =>
          allStaffs.push({
            GUID: uuid.v4(),
            AccountGUID: s.AccountGUID,
            Deleted: new Date(),
            TodoGUID: null,
          })
        );
      });
    dispatch(
      addList(newList, allStaffs, () => {
        ToastNotificationSuccess(Math.random(), "List created successfully");
      })
    );
  };

  const {
    event,
    toggleVisibilty,
    toggleSidebarEvents,
    showEventsSidebar,
    productionModal,
    toggleProductionModal,
    prevEvent,
    nextEvent,
    hotelPlaces,
    venuePlace,
    loading,
    addNote,
    notes,
    toggleLastSync,
    sendEmail,
    production,
    history,
    connectedUsers,
    deleteEvent,
    isMobile,
    prevNextState,
    handleNext,
    handlePrevious,
  } = props;

  const { notesModal, mailModal, todoModal, markerModal } = state;
  const currentUserMode = localStorage.getItem("LoginMode");
  const toggleRef = useRef();

  let styles = isMobile ? { top: 0, left: 0, width: "97%" } : {};

  return (
    <div
      className={`eventHeader ${
        production ? "pl-4 border-bottom-0" : "pr-4 pl-2"
      } py-2`}
    >
      {event && (
        <div className="row mx-0 justify-content-between">
          <div className="eventHeader__main col-7 mx-0 px-0">
            <ModalComponent
              modal={state.showEventHistory}
              toggle={() => toggleHistory()}
              childeren={<EventHistory toggle={() => toggleHistory()} />}
            />

            <ModalComponent
              modal={notesModal}
              toggle={() => toggleNotesModal()}
              childeren={
                <AddNotes
                  addNote={addNote}
                  event={event}
                  notes={notes}
                  toggle={() => toggleNotesModal()}
                />
              }
            />
            <ModalComponent
              modal={mailModal}
              toggle={() => handleCloseEmail(toggleMailModal)}
              childeren={
                <EmailModal
                  EventDetails
                  sendEmail={sendEmail}
                  fromSection="EVENT_DETAILS"
                  ExportType={exportType}
                  event={event}
                  toggle={() => toggleMailModal()}
                />
              }
            />
            {/* <ModalComponent
              modal={productionModal}
              toggle={() => toggleProductionModal()}
              childeren={<MoreDetails event={event} />}
            /> */}

            {/*FILES MODAL */}
            <ModalComponent
              modal={state.fileModal}
              toggle={() => toggleFilesModal()}
              childeren={
                <FilesModal toggle={() => toggleFilesModal()} event={event} />
              }
            />
            {/*END FILES MODAL */}

            <MoveEventModal
              show={state.moveEventModal}
              toggle={toggleMoveEventModal}
              event={event}
              venuePlace={venuePlace}
            />
            <ModalComponent
              modal={todoModal}
              toggle={() => toggleTodoModal()}
              childeren={
                <TodoModal
                  toggle={() => toggleTodoModal()}
                  handleAddTodo={handleAddTodo}
                />
              }
            />
            <ModalComponent
              modal={markerModal}
              toggle={() => toggleMarkerModal()}
              childeren={
                <MarkerModal
                  toggle={() => toggleMarkerModal()}
                  hotelPlaces={hotelPlaces}
                  venuePlace={venuePlace}
                  event={event}
                />
              }
            />
          </div>

          {production ? null : (
            <div
              className={`eventHeader__actions justify-content-between w-100 align-items-center ${
                isMobile ? "pt-5" : ""
              }`}
            >
              <div className="d-flex align-items-center w-75">
                <SandwichButton
                  expanded={props.expanded}
                  showExpanded={props.showExpanded}
                />
                {event !== null && event.Name !== undefined ? (
                  <div
                    className={`${isMobile ? "position-absolute" : ""}`}
                    style={styles}
                  >
                    <Event
                      key={event.GUID}
                      event={event}
                      noIcons
                      weather={props.weather}
                    />
                  </div>
                ) : (
                  <div
                    className={`${isMobile ? "position-absolute" : "w-50"}`}
                    style={styles}
                  >
                    <SkeletonLoading />
                  </div>
                )}
              </div>
              <div className={`d-flex`}>
                <div className="ml-auto overflow-auto">
                  <OnlineUsers data={connectedUsers} />
                </div>
                <button
                  className="btn btn-sm mx-1 py-2 px-2 btn-primary"
                  title="Event History"
                  type="button"
                  onClick={() => {
                    toggleHistory();
                  }}
                >
                  <i className="fas fa-history"></i>
                </button>
                <div className="btn-group">
                  <button
                    className={`btn btn-primary btn-sm mx-0`}
                    onClick={handlePrevious}
                    disabled={!prevNextState.prev || loading}
                  >
                    <img
                      src={IMAGES.ARROW_LEFT}
                      alt="dropdown"
                      width="16"
                      height="16"
                    />
                  </button>
                  <button
                    title="Switch Events"
                    className="btn btn-primary btn-sm mx-0"
                    style={{ borderRadius: 0 }}
                    onClick={() => toggleSidebarEvents()}
                  >
                    <CalendarBlank
                      width={15}
                      height={15}
                      htmlColor={showEventsSidebar ? "#00a2d4" : "#fff"}
                    />
                  </button>
                  <button
                    className="btn btn-primary  btn-sm mx-0"
                    onClick={handleNext}
                    disabled={!prevNextState.next || loading}
                  >
                    <img
                      src={IMAGES.ARROW_RIGHT}
                      alt="dropdown"
                      width="16"
                      height="16"
                    />
                  </button>
                </div>
                <button
                  className={
                    event.LastSync === null
                      ? "btn  btn-secondary ml-1 py-2 text-truncate"
                      : "btn   btn-success py-2 ml-1 text-truncate"
                  }
                  style={{ width: "118px" }}
                  onClick={() => handlePublish(event)}
                >
                  {event.LastSync === null ? (
                    <FormattedMessage id="private" defaultMessage="Private" />
                  ) : (
                    <FormattedMessage
                      id="published"
                      defaultMessage="Published"
                    />
                  )}
                  {event.LastSync === null ? (
                    <i className="ml-2 fas fa-toggle-off"></i>
                  ) : (
                    <i className=" ml-2 fas fa-toggle-on"></i>
                  )}
                </button>
                {/* {currentUserMode === "beta" ? */}
                <div
                  title="To-Do"
                  onClick={() => toggleTodoModal()}
                  className="btn btn-sm eventHeader__todo  ml-1 relative"
                >
                  <Counter count={event.TodoCount} />

                  <FormatListChecks htmlColor="#fff" />
                </div>
                {/* : null} */}
                <button
                  title="Sticky Notes"
                  onClick={() => toggleNotesModal()}
                  className="btn btn-sm eventHeader__note  ml-1 relative"
                >
                  <Counter
                    count={
                      notes.length === 0 && event.NotesCount === notes.length
                        ? 0
                        : notes.length > 0
                        ? notes.length
                        : event.NotesCount
                    }
                  />
                  <Sticker htmlColor="#fff" />
                </button>
                <button
                  onClick={() => toggleMarkerModal()}
                  className=" btn btn-sm eventHeader__map d-none "
                >
                  <MapMarkerRadius htmlColor="#fff" />
                </button>
                <button
                  className="btn btn-sm eventHeader__visible d-none"
                  onClick={() => toggleVisibilty()}
                >
                  <Eye htmlColor="#fff" />
                </button>
                <button
                  title="Export Event via Email"
                  onClick={() => toggleMailModal()}
                  className="btn btn-sm eventHeader__maximize ml-1 relative"
                >
                  <Export htmlColor="#fff" />
                </button>
                {/* {currentUserMode === "beta" ? */}
                <button
                  className=" btn btn-sm eventHeader__file ml-1 relative"
                  title="Files"
                  onClick={() => toggleFilesModal()}
                >
                  <Folder htmlColor="#fff" />
                </button>
                {/* :null} */}
                <DropDownComponent
                  className="btn btn-sm moreMenu  ml-1 relative"
                  color="#fff"
                  data={[
                    {
                      name: (
                        <FormattedMessage
                          id="visibleBy"
                          defaultMessage="Visible By"
                        />
                      ),
                      trigger: () => toggleVisibilty(),
                      border: true,
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="exportEmail"
                          defaultMessage="Export via Email"
                        />
                      ),
                      trigger: () => toggleMailModal(),
                      border: true,
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="moveEvent"
                          defaultMessage="Move to another workspace"
                        />
                      ),
                      trigger: () => toggleMoveEventModal(),
                      border: true,
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="Directions"
                          defaultMessage="Directions"
                        />
                      ),
                      trigger: () => toggleMarkerModal(),
                      border: true,
                      //  mode: "beta"
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="stickyNotes"
                          defaultMessage="Sticky Notes"
                        />
                      ),
                      trigger: () => toggleNotesModal(),
                      border: true,
                    },
                    {
                      name: (
                        <FormattedMessage id="to-Do" defaultMessage="To-Do's" />
                      ),
                      trigger: () => toggleTodoModal(),
                      border: true,
                      //  mode: "beta"
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="Guestlist"
                          defaultMessage="Guestlist"
                        />
                      ),
                      trigger: () => history.push(ROUTES.TICKETS_AND_PASSES),
                      border: true,
                    },
                    {
                      name: (
                        <FormattedMessage id="files" defaultMessage="Files" />
                      ),
                      trigger: () => console.log(),
                      border: true,
                      //  mode:"beta"
                    },
                    {
                      name: (
                        <FormattedMessage id="delete" defaultMessage="Delete" />
                      ),
                      trigger: () => deleteEvent(),
                      delete: true,
                    },
                  ].filter(
                    (x) =>
                      x.mode !== "beta" ||
                      (currentUserMode === "beta" && x.mode === currentUserMode)
                  )}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default EventHeader;
