import * as CONSTANT from "./constants";
import { setOrganization } from "../../../utils";

export function selectOrganization(organization, callback) {
  return (dispatch) => {
    dispatch({
      type: CONSTANT.SELECT_ORGANIZATION,
    });
    setOrganization(organization);
    callback();
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.SELECT_ORGANIZATION: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
