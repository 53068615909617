import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function editEvent(event) {
  const url = `/Events/Edit`;
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_CALENDAR_EVENT,
    });
    await db.table("events").put(event);
    const localEvent = await db
      .table("events")
      .where({ GUID: event.GUID })
      .first();
    try {
      debugger;
      const request = await api.post(url, localEvent);
      await db.table("events").put(request.data.Response);
      dispatch({
        type: constant.EDITED_CALENDAR_EVENT,
        data: localEvent,
      });
    } catch (error) {
      dispatch({
        type: constant.EDITING_CALENDAR_EVENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_CALENDAR_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_CALENDAR_EVENT: {
      return {
        ...state,
        loading: false,
        calendarEvents: state.calendarEvents.map((event) => {
          if (event.GUID === action.data.GUID) {
            event = action.data;
            return event;
          }
          return event;
        }),
      };
    }
    case constant.EDITING_CALENDAR_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
