import api from "../../../common/api";
import * as constant from "./constants";
const imageUrl = "/Organizations/UploadImage";

export function editLogo(organization, logo, success) {
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_LOGO_ORGANIZATION,
      });
      let data = new FormData();
      data.append("image", logo, organization);
      const request = await api.post(`${imageUrl}?id=${organization}`, data, {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      });
      dispatch({
        type: constant.EDITED_LOGO_ORGANIZATION,
        data: request.data,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.EDITING_LOGO_ORGANIZATION_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_LOGO_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_LOGO_ORGANIZATION: {
      return {
        ...state,
        loading: false,
        currentOrganization: {
          ...state.currentOrganization,
          Logo: action.data.Logo,
        },
        organizationsList: state.organizationsList.map((org) => {
          if (org.GUID === action.data.GUID) {
            org.Organization = action.data;
            return org;
          }

          return org;
        }),
      };
    }
    case constant.EDITING_LOGO_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
