import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function updateRequest(pass, success) {
  const url = "/Guestlist/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constants.UPDATING_TICKETS,
    });
    const updatedPass = {
      GUID: pass.GUID,
      EventGUID: pass.EventGUID,
      RequestAccountGUID: pass.RequestAccountGUID,
      PassType: pass.PassType,
      Passes: pass.Passes,
      Note: pass.Note,
      NoteConfirmed: pass.ConfirmedNote || pass.NoteConfirmed,
      ConfirmedNote: pass.ConfirmedNote || pass.NoteConfirmed,
      Notify: pass.Notify,
      ConfirmAccountGUID: pass.ConfirmAccountGUID,
      ConfirmedPasses: pass.ConfirmedPasses,
      Seat: pass.Seat,
      Row: pass.Row,
      Section: pass.Section,
      RequestedFor: pass.GuestNames,
      RequestedForLastName: pass.GuestLastName,
      Priority: pass.Priority,
      PickupLocation: pass.Pickup || pass.PickupLocation,
      Confirmed: pass.Confirmed,
      ConfirmDate: pass.ConfirmDate,
      RequestDate: pass.RequestDate,
      NotifyTo: pass.NotifyTo,
      Deleted: pass.Deleted,
    };
    await db.table("ticketsAndPasses").put(pass);
    const { data } = await apiService.post(url, updatedPass);
    console.log("request", data);
    dispatch({
      type: constants.UPDATED_TICKETS,
      data: pass,
    });
    success();
    try {
    } catch (error) {
      dispatch({
        type: constants.UPDATING_TICKETS_ERROR,
      });

      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.UPDATING_TICKETS: {
      return {
        ...state,
        loadingTickets: true,
      };
    }
    case constants.UPDATED_TICKETS: {
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: state.ticketsAndPasses.map((t) => {
          if (t.GUID === action.data.GUID) {
            t = action.data;
            return t;
          }
          return t;
        }),
      };
    }
    case constants.UPDATING_TICKETS_ERROR: {
      return {
        ...state,
        loadingTickets: false,
      };
    }
    default:
      return state;
  }
}
