export function getStartAndEndOfMonth(date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  // Calculate the start of the month
  const startOfMonth = new Date(year, month - 1, 1);

  // Calculate the end of the month
  const nextMonth = month + 1 > 11 ? 0 : month + 1;
  const nextYear = nextMonth === 0 ? year + 1 : year;
  const endOfMonth = new Date(nextYear, nextMonth + 1, 0);

  return {
    startOfMonth: startOfMonth,
    endOfMonth: endOfMonth,
  };
}
