import React, { Component } from "react";
import Subscription from "./Subscription";

class SubscriptionContainer extends Component {
  state = {};
  render() {
    return <Subscription />;
  }
}

export default SubscriptionContainer;
