import * as constant from "./constants";

export function linkableEvents(events) {
  return (dispatch) => {
    dispatch({
      type: constant.POPULATE_LINKABLE_EVENTS,
      data: events,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.POPULATE_LINKABLE_EVENTS: {
      return {
        ...state,
        linkableEvents: action.data,
      };
    }
    default:
      return state;
  }
}
