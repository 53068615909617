import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";

export const PrivateFlight = ({ stops }) => {
  const intl = useIntl();

  return (
    <>
      <div className="col-4 text-dark">
        <b>
          <small>
            <FormattedMessage id="Tail Number" defaultMessage="Tail Number" />{" "}
          </small>
        </b>
        <br />
        <p className="text-muted">{stops[0] && stops[0].AirlineFlight}</p>
      </div>
      <div className="col-4 text-dark">
        <b>
          <small>
            <FormattedMessage id="Call Sign" defaultMessage="Call Sign" />
            &nbsp;
            <i
              className="fas fa-info-circle"
              title={intl.formatMessage({
                id: "The call sign is a specialized form of nickname that is used as a substitute for the aviator’s given name",
              })}
            ></i>
          </small>
        </b>
        <br />
        <p className="text-muted">{stops[0] && stops[0].Airline}</p>
      </div>
      <div className="col-4 text-right text-dark">
        {stops[0] !== undefined &&
        stops[0].PrivateFlight &&
        stops[0].OperatedBy ? (
          <>
            <b>
              <small>
                <FormattedMessage id="Plane Type" defaultMessage="Plane Type" />{" "}
              </small>
            </b>
            <br />
            <p className="text-muted">{stops[0] && stops[0].OperatedBy}</p>
          </>
        ) : null}
      </div>
      {/*JUST ONE FLIGHT */}
      {stops.length === 1 &&
        stops.map((element, index) => {
          return (
            <div className="col-12 text-dark px-0 mx-0" key={index}>
              <div className="row">
                <div className="col-12">
                  <hr className="my-1 py-0" />
                </div>
                <div className="col-4">
                  <b>{element.From}</b>
                  <br />
                  <small className="text-muted">
                    {moment(element && element.DepartureDate).format(
                      "ddd, MMM DD, h:mm A"
                    )}
                  </small>
                </div>
                <div className="col-8">
                  <b>
                    {" "}
                    <small>
                      <FormattedMessage
                        id="Departure Information"
                        defaultMessage="Departure Information"
                      />
                    </small>
                  </b>
                  <br />
                  <small className="text-muted">{element.FromLongName}</small>
                  <br />
                  <small className="text-muted">{element.FromAddress}</small>
                </div>
                <div className="col-12">
                  <hr className="my-1 py-0" />
                </div>
                <div className="col-4">
                  <b>{element.To}</b>
                  <br />
                  <small className="text-muted">
                    {moment(element && element.ArrivalDate).format(
                      "ddd, MMM DD, h:mm A"
                    )}
                  </small>
                </div>
                <div className="col-8">
                  <b>
                    <small>
                      <FormattedMessage
                        id="Arrival Information"
                        defaultMessage="Arrival Information"
                      />
                    </small>
                  </b>
                  <br />
                  <small className="text-muted">{element.ToLongName}</small>
                  <br />
                  <small className="text-muted">{element.ToAddress}</small>
                </div>
              </div>
            </div>
          );
        })}
      {/*IF THERE MORE THAN ONE FLIGHT */}
      {stops.length > 1 &&
        stops.map((element, index) => {
          return (
            <div className="col-12 text-dark px-0 mx-0" key={index}>
              <div className="row">
                {index === 0 ? (
                  <>
                    <div className="col-12">
                      <hr className="my-1 py-0" />
                    </div>
                    <div className="col-4">
                      <b>{element.From}</b>
                      <br />
                      <small className="text-muted">
                        {moment(element && element.DepartureDate).format(
                          "ddd, MMM DD, h:mm A"
                        )}
                      </small>
                    </div>
                    <div className="col-8">
                      <small>
                        <FormattedMessage
                          id="Departure Information"
                          defaultMessage="Departure Information"
                        />
                      </small>
                      <br />
                      <small className="text-muted">
                        {element.FromLongName}
                      </small>
                      <br />
                      <small className="text-muted">
                        {element.FromAddress}
                      </small>
                    </div>
                    {index === stops.length - 2 ? (
                      <>
                        <div className="col-auto mx-0 px-0">
                          <span className="badge badge-danger">
                            <FormattedMessage id="Stop" defaultMessage="Stop" />
                          </span>
                        </div>
                        <div className="col-10">
                          <hr className="my-1 py-0" />
                        </div>
                        <div className="col-4">
                          <b>{element.To}</b>
                          <br />
                          <small className="text-muted">
                            {moment(element && element.ArrivalDate).format(
                              "ddd, MMM DD, h:mm A"
                            )}
                          </small>
                        </div>
                        <div className="col-8">
                          <small>
                            <FormattedMessage
                              id="Stop Information"
                              defaultMessage="Stop Information"
                            />
                          </small>
                          <br />
                          <small className="text-muted">
                            {element.ToLongName}
                          </small>
                          <br />
                          <small className="text-muted">
                            {element.ToAddress}
                          </small>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : index === stops.length - 1 ? (
                  <>
                    <div className="col-12">
                      <hr className="my-1 py-0" />
                    </div>
                    <div className="col-4">
                      <b>{element.To}</b>
                      <br />
                      <small className="text-muted">
                        {moment(element && element.ArrivalDate).format(
                          "ddd, MMM DD, h:mm A"
                        )}
                      </small>
                    </div>
                    <div className="col-8">
                      <small>
                        <FormattedMessage
                          id="Arrival Information"
                          defaultMessage="Arrival Information"
                        />
                      </small>
                      <br />
                      <small className="text-muted">{element.ToLongName}</small>
                      <br />
                      <small className="text-muted">{element.ToAddress}</small>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-2">
                      <span className="badge badge-danger">
                        <FormattedMessage id="Stop" defaultMessage="Stop" />
                      </span>
                    </div>
                    <div className="col-10">
                      <hr className="my-1 py-0" />
                    </div>
                    <div className="col-4">
                      <b>{element.From}</b>
                      <br />
                      <small className="text-muted">
                        {moment(element && element.FromDate).format(
                          "ddd, MMM DD, h:mm A"
                        )}
                      </small>
                    </div>
                    <div className="col-8">
                      <small>
                        <FormattedMessage
                          id="Stop Information"
                          defaultMessage="Stop Information"
                        />
                      </small>
                      <br />
                      <small className="text-muted">
                        {element.FromLongName}
                      </small>
                      <br />
                      <small className="text-muted">
                        {element.FromAddress}
                      </small>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
