import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function createRequest(pass, success) {
  const url = "/GuestList/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constants.CREATING_TICKET,
    });
    await db.table("ticketsAndPasses").put(pass);
    
    const request = await apiService.post(url, pass);
    debugger;
    dispatch({
      type: constants.CREATED_TICKET,
      data: pass,
    });
    success();
    try {
    } catch (error) {
      dispatch({
        type: constants.CREATING_TICKET_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.CREATING_TICKET: {
      return {
        ...state,
        loadingTickets: true,
      };
    }
    case constants.CREATED_TICKET: {
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: [...state.ticketsAndPasses, action.data],
      };
    }
    case constants.CREATING_TICKET_ERROR: {
      return {
        ...state,
        loadingTickets: false,
      };
    }
    default:
      return state;
  }
}
