import Dexie from "dexie";

export const db = new Dexie("plnifyDB");
function GenerateDatabase() {
  db.version(1).stores({
    events: "GUID,OrganizationGUID",
    hotels: "GUID",
    grounds: "GUID",
    venue: "GUID",
    organizations: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
  });

  db.version(2).stores({
    events: "GUID,OrganizationGUID",
    hotels: "GUID",
    grounds: "GUID",
    venue: "GUID",
    organizations: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
  });
  db.version(3).stores({
    events: "GUID,OrganizationGUID",
    hotels: "GUID",
    grounds: "GUID",
    venue: "GUID",
    organizations: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
  });

  db.version(4).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID",
    grounds: "GUID",
    venue: "GUID",
    organizations: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
  });

  db.version(5).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
  });

  db.version(6).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
  });

  db.version(7).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
  });

  db.version(8).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID"//This table is used to store all the records that are sent to the server 
  });
  db.version(9).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    eventHistory: "GUID,OrganizationGUID,Name, City, Country",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID"//This table is used to store all the records that are sent to the server 
  });

  db.version(10).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    eventHistory: "GUID,OrganizationGUID,Name, City, Country, lastDate",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID"//This table is used to store all the records that are sent to the server 
  });

  db.version(11).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    eventHistory: "GUID,OrganizationGUID,Name, City, Country, lastDate",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID",//This table is used to store all the records that are sent to the server 
    apiLog: "id"
  });


  db.version(12).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    eventHistory: "GUID,OrganizationGUID,Name, City, Country, lastDate",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID",//This table is used to store all the records that are sent to the server 
    apiLog: "id",
    venues:"GUID,EventGUID",
    airportList:"GUID",
    airlineList: "GUID"
  });


  db.version(13).stores({
    events: "GUID,OrganizationGUID,Name, City, Country",
    eventHistory: "GUID,OrganizationGUID,Name, City, Country, lastDate",
    event: "GUID,OrganizationGUID,Name, City, Country",
    hotels: "GUID, EventGUID",
    grounds: "GUID, EventGUID",
    flights: "GUID,EventGUID,Connection",
    venue: "GUID",
    organizations: "GUID",
    organization: "GUID",
    eventsTransactions: "GUID",
    groundsTransactions: "GUID, EventGUID",
    flightTransactions: "GUID",
    scheduleTransactions: "GUID",
    attachmentTransactions: "GUID",
    contactTransactions: "GUID",
    hotelsTransactions: "GUID, EventGUID",
    batchEvent: "GUID",
    appSettings: "AccountGUID",
    emailHistory: "GUID",
    productions: "GUID,EventGUID",
    schedules: "GUID, EventGUID",
    notes: "GUID, EventGUID",
    contacts: "GUID, EventGUID",
    attachments: "GUID, EventGUID",
    activeNotes: "GUID",
    todos: "GUID, EventGUID",
    localNotifications: "GUID, AccountGUID",
    ticketsAndPasses: "GUID, EventGUID",
    syncActivityLog : "GUID",//This table is used to store all the records that are sent to the server 
    apiLog: "id",
    venues:"GUID,EventGUID",
    airportList:"GUID",
    airlineList: "GUID",
    cities: "GUID",
    states: "GUID",
    countries: "GUID",
    mailDrafts: "AccountGUID"
  });
}

try {
  GenerateDatabase();
} catch (ex) {
  console.error(ex);
  //probable means issues with the migration, so.... delete entire database an recreate it again :)
  db.delete().then(() => {
    GenerateDatabase();
  });
}

export function deleteDatabase() {
  db.delete().then(() => {
    GenerateDatabase();
  });
}
export default db;
