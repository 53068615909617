import React, { useState } from "react";
import PropTypes from "prop-types";
import { Route, HashRouter, Redirect } from "react-router-dom";
import { TextInput, Loader } from "../../../components";
import { ROUTES, IMAGES } from "../../../constant";
import { CarouselComponent, ModalComponent } from "../../../components/common";
import ForgotPasswordContainer from "../forgotPassword/forgotPasswordContainer";
import SuccessPopup from "../forgotPassword/successPopup";
import FormField from "../../../components/form/formField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginSchema } from "../../../utils/validationsSchemas";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import MensajesIngles from "../../../language/en-Us.json";
import MensajesEspanol from "../../../language/es-Es.json";
import history from "../../../common/history";
import db from "../../../common/db";
// import { ToastNotificationSuccess } from "./common";
import { getBasicInfo } from "../../../utils";
import { BootStrapModal } from "../forgotPassword/BootStrapModal";
const items = [1, 2, 3, 4];

const Login = (props) => {
  const [modal, setModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [lenguage, setLenguage] = useState({
    locale: "en-Us",
    messages: MensajesIngles,
  });
  const {
    handleChange,
    onClickNavigation,
    togglePassword,
    showPassword,
    handleSubmit,
    loading,
    toggleRememberMe,
    rememberMeChecked,
    showSuccessPopup,
    auth,
    handleSuccess,
    dataStateLenguage,
  } = props;
  const lenguageData = async (lenguage) => {
    //  history.push(ROUTES.LOGIN)
    if (lenguage) {
      await db.table("appSettings").put({ AccountGUID: 1, lenguage });
      dataStateLenguage(lenguage);
    }
    window.location.reload();
  };
  const toggle = () => {
    setModal(!modal);
    handleChange("showSuccessPopup", false);
  };

  const setEmail = (value) => setForgotEmail(value);

  return (
    <div className="main-container">
      <div className="login-background">
        <Loader loading={loading} />

        <BootStrapModal
          open={modal}
          sent={showSuccessPopup}
          toggle={toggle}
          setEmail={setEmail}
          handleSuccess={handleSuccess}
          email={forgotEmail}
        />
        {/* <ModalComponent
          modal={modal}
          toggle={toggle}
          childeren={
            showSuccessPopup ? (
              <SuccessPopup email={forgotEmail} toggle={toggle} />
            ) : (
              <ForgotPasswordContainer
                toggle={toggle}
                setEmail={setEmail}
                handleSuccess={handleSuccess}
              />
            )
          }
        />  */}
        <div className="login-content">
          <div className="login-logo d-none">
            <img src={IMAGES.LOGO} width="107" alt="logo" />
          </div>
          <div className="inside-content">
            <div className="row">
              <div className="col-md-12">
                <div className="login-right bg-white rounded shadow-lg mt-4">
                  <div className="login-right-bg">
                    <div className="col-md-12 text-center">
                      <img
                        src={IMAGES.LOGO_GRADIENT}
                        className="mx-auto"
                        alt="logo"
                      />
                      <h4>
                        <FormattedMessage
                          id="welcome"
                          defaultMessage="Welcome"
                        />
                      </h4>
                      <p className="lead">
                        <FormattedMessage
                          id="slogan"
                          defaultMessage="The key to your successful events"
                        />
                      </p>
                    </div>
                    <div
                      className="right-content mx-auto"
                      style={{ maxWidth: "85%" }}
                    >
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                        }}
                        onSubmit={(values) => {
                          handleSubmit(values.email, values.password);
                        }}
                        validationSchema={loginSchema}
                      >
                        <Form className="login-form">
                          {auth.loginError ? (
                            <div className="alert alert-danger" role="alert">
                              Email and/or password are incorrect.
                            </div>
                          ) : null}

                          <FormField
                            fullWidth
                            label={
                              <label style={{ marginBottom: 12 }}>Email</label>
                            }
                          >
                            <span className="d-flex align-items-center position-relative border-light">
                              <i
                                className="fa fa-envelope position-absolute"
                                style={{ left: "12px", color: "#66799e" }}
                              ></i>
                              <Field
                                className="w-100 rounded-lg py-2"
                                style={{
                                  paddingLeft: "35px",
                                  border: "1px solid #66799e",
                                  color: "#66799e",
                                }}
                                type="text"
                                name="email"
                                placeholder="example@plnify.com"
                              />
                            </span>
                            <ErrorMessage
                              className="error-message"
                              name="email"
                              component="div"
                            />
                          </FormField>
                          <FormField
                            fullWidth
                            label={
                              <label
                                style={{ marginBottom: 12, marginTop: "15px" }}
                              >
                                Password
                              </label>
                            }
                          >
                            <span className="d-flex align-items-center position-relative">
                              <i
                                className="fa fa-unlock-alt position-absolute"
                                style={{ left: "12px", color: "#66799e" }}
                              ></i>
                              <Field
                                className="w-100 rounded-lg py-2"
                                style={{
                                  paddingLeft: "35px",
                                  border: "1px solid #66799e",
                                  color: "#66799e",
                                }}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder="Password"

                                // component={TextInput}
                              />
                              <span
                                className="position-absolute text-secondary"
                                style={{ right: "12px", cursor: "pointer" }}
                                onClick={() => togglePassword()}
                              >
                                <i
                                  className={
                                    showPassword
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                  style={{ color: "#66799e" }}
                                ></i>
                              </span>
                            </span>
                            <ErrorMessage
                              className="error-message"
                              name="password"
                              component="div"
                            />
                          </FormField>

                          <div className="remember-login-section">
                            <div
                              className="forgot-password link"
                              onClick={() => toggle()}
                            >
                              <p>
                                <FormattedMessage
                                  id="forgot.password"
                                  defaultMessage="Forgot Password"
                                />
                              </p>
                            </div>
                            <div className="mx-auto" style={{ width: "100%" }}>
                              {auth.logging ? (
                                <div className="spinner-main text-center">
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-dark py-3 border-0 rounded-lg"
                                  style={{ width: "100%" }}
                                  id="login"
                                >
                                  <FormattedMessage
                                    id="sign.in"
                                    defaultMessage="Sign in"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                  <div className="login-footer">
                    <div className="footer-inside pt-1">
                      <div
                        className="footer-content"
                        onClick={() => onClickNavigation(ROUTES.SIGNUP)}
                      >
                        <p>
                          <FormattedMessage
                            id="new.user.question"
                            defaultMessage="Are you a new User?"
                          />
                        </p>
                        <span>
                          <FormattedMessage
                            id="sign.up"
                            defaultMessage="Sign Up"
                          />
                        </span>
                      </div>
                      <div className="d-flex my-4 W-50 justify-content-center">
                        <span className="mx-3">
                          <a
                            style={{ textDecorationLine: "underline" }}
                            onClick={() => lenguageData("1")}
                          >
                            EN
                          </a>
                        </span>
                        <span className="mx-3">
                          <a
                            style={{ textDecorationLine: "underline" }}
                            onClick={() => lenguageData("2")}
                          >
                            ES
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.string,
  password: PropTypes.string,
  passwordError: PropTypes.string,
  handleChange: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  onClickNavigation: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  togglePassword: PropTypes.func,
  showPassword: PropTypes.bool,
  toggleRememberMe: PropTypes.func,
  rememberMeChecked: PropTypes.bool,
};

export default Login;
