import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGES } from "../../constant";
import * as actions from "../../page/todoPage/redux/actions";
import { Scrollbars } from "react-custom-scrollbars";
import {useIntl, FormattedMessage} from 'react-intl'

import { SimpleLoader, ToastNotificationSuccess } from "../common";
import { checkSelectionStatus, exist } from "../../common/helpers";
import GeneralUserItem from "../common/GeneralUserItem";

const onErrorImage = (sender) => {
  sender.target.src = IMAGES.PROFILE_ICON;
};

const getStaff = (staffs, toggleVisible, staffSelected) => {
  let selectColletion = staffSelected.map(item => (item?.AccountGUID))
  let selectColletionStaff = selectColletion[0] !== undefined ? selectColletion : staffSelected
  // let selectColletionStaff = selectColletion[0] !== undefined ? selectColletion : selectColletion.length > 2 ? selectColletion :staffSelected

  return (
    staffs.length > 0 &&
    staffs
      .sort((a, b) => a.Account.Name.localeCompare(b.Account.Name))
      .filter((s) => s.Deleted === null)
      .map((staff) => {
        return (
          <div
            key={staff.GUID}
            className={`${staffSelected.includes(staff.GUID) ? "selected" : ""
              } visible-info-main card shadow-sm p-0 my-1 mx-2 mr-3`}
          >
            <div
              className="card-body row p-1 d-flex align-items-center"
              onClick={() => {
                toggleVisible(staff);
              }}
            >
              <div className="pr-2 text-dark">
                {selectColletionStaff.includes(staff.AccountGUID) ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="far fa-square"></i>
                )}
              </div>

              <div className="col">

                <GeneralUserItem account={staff.Account}  />
              </div>
            </div>
          </div>
        );
      })
  );
};

const getEventList = (teams, toggleVisible, staffSelected, selectAll) => {
  return (
    teams &&
    teams
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .sort((a) => {
      if (a.Name === "Admin") return -1;
      if (a.Name) return 1;
    })
      .filter((t) => t.Name !== "Non-App User" && t.Staffs.length > 0)
      .map((teamData, index) => {
        return (<>
          <div className="row px-0" key={index}>
            <div className="col-auto px-0 bgTitleModal py-1 mt-4 text-dark">
              <button className="btn btn-sm text-secondary" onClick={() => selectAll(teamData)} type="button">
                {checkSelectionStatus(teamData.Staffs, null,staffSelected) === 0 ?
                  <i className="far fa-square"></i>
                  : checkSelectionStatus(teamData.Staffs, null, staffSelected) === 1 ?
                    <i className="fas fa-check-square"></i> :
                    <i className="fas fa-minus-square"></i>}
              </button>

            </div>
            <div className="col bgTitleModal py-2 mt-4  pl-0 text-dark"><h3>{teamData.Name}</h3></div>


          </div>
          {getStaff(teamData.Staffs, toggleVisible, staffSelected)}
        </>
        );
      })
  );
};

export class Assignees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffSelected: [],
    };
  }

  componentDidMount() {
    const {
      currentList: { TodoAssignees },
    } = this.props;
    const staffVisibles = TodoAssignees.filter((s) => s.Deleted === null).map((s) => s.AccountGUID);
    const staffObjects = TodoAssignees.map((a) => {
      if (staffVisibles.includes(a.AccountGUID)) {
        return {
          GUID: a.GUID,
          Deleted: null,
          TodoGUID: a.TodoGUID,
          AccountGUID: a.AccountGUID,
        };
      } else {
        return {
          GUID: a.GUID,
          Deleted: a.Deleted,
          TodoGUID: a.TodoGUID,
          AccountGUID: a.AccountGUID,
        };
      }
    });
    this.setState({
      staffSelected: staffVisibles,
      staffObjects,
    });
  }

  selectAll = (data) => {
    let SelectedItems = this.state.staffSelected;
    let filter = data.Staffs
    let checkSelection = checkSelectionStatus(data.Staffs, null, this.state.staffSelected);
    
    if (checkSelection === 0) {
      //We don't have anything,proceed to select all 
      filter.forEach(item => {

      //  if (!exist(SelectedItems, item)) {
          SelectedItems.push(item);

        //}
      })
      
      this.setState(
      {
        ...this.state,
        staffSelected: SelectedItems
      },
      () => {
        this.prepareAssignees();
      }
      );

    } else if (checkSelection === 1) {
      
      //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way      
      this.setState(
        {
        ...this.state,
        staffSelected: []
      },
      () => {
        this.prepareAssignees();
      });
    } else {
      //is mixed selected the ones missed
      
      filter.forEach(item => {

        if (!exist(SelectedItems, item)) {

          SelectedItems.push(item);
        } else {
          //Check if deleted
        }
      });
      
      this.setState(
        {
          ...this.state,
          staffSelected: SelectedItems
        },
        () => {
          this.prepareAssignees();
        }
        );
    }

  }
  toggleVisible = (role) => {
    
    const { staffSelected, staffObjects } = this.state;
    let selectColletion = staffSelected.map(item => (item?.AccountGUID))
    let selectColletionStaff = selectColletion[0] !== undefined ? selectColletion : staffSelected
    const selectedOne = staffObjects.find((x) => x.AccountGUID === role.AccountGUID) || role;
    const selectedStaff = {
      AccountGUID: selectedOne.AccountGUID,
    };
    let staffs = selectColletionStaff;
    if (staffs.includes(selectedStaff.AccountGUID)) {
      let filtered = staffs.filter((s) => s !== selectedStaff.AccountGUID);
      staffs = filtered;
    } else {
      let newOnes = staffs.concat(selectedStaff.AccountGUID);
      staffs = newOnes;
    }

    this.setState(
      {
        staffSelected: staffs,
      },
      () => {
        this.prepareAssignees();
      }
    );
  };

  prepareAssignees = () => {
    const { staffSelected, staffObjects } = this.state;
    const { currentList } = this.props;
    let selectColletion = staffSelected.map(item => (item?.AccountGUID))
  let selectColletionStaff = selectColletion[0] !== undefined ? selectColletion : staffSelected
    let selected = [];
    console.log(staffObjects)
    staffObjects.map((o) => {
      if (selectColletionStaff.includes(o.AccountGUID)) {
        selected.push({
          ...o,
          TodoGUID: currentList.GUID,
          AccountGUID: o.AccountGUID,
          Deleted: null,
          Account: null,
          Todo: null,
          Staff: null,
        });
      } else {
        selected.push({
          ...o,
          TodoGUID: currentList.GUID,
          AccountGUID: o.AccountGUID,
          Deleted: new Date(),
          Account: null,
          Todo: null,
          Staff: null,
        });
      }
    });
    this.props.actions.addAssignees(selected, currentList.GUID, () => {
      ToastNotificationSuccess(1, "Done");
      const globalCurrentList = this.props.todos.find(
        (t) => t.GUID === currentList.GUID
      );
      this.props.setCurrentList(globalCurrentList);
    });
  };

  render() {
    const { teams, loading, currentList } = this.props;
    const { staffSelected } = this.state;
    return (
      <div className="organization-transparent">
        <div className="event-page modalContainer">
          <div className="event-expennd-section">
            <div className="text-head">
              <div className="text-left">
                <h4 className="title"><FormattedMessage
                    id="assignees"
                    defaultMessage="Assignees"/></h4>
                <p>{currentList.Name}</p>
              </div>
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <div className="close-icon d-none">
                  <span className="close-round">
                    <img
                      src={IMAGES.CROSS_ICON}
                      alt="close"
                      width="16"
                      height="16"
                    />
                  </span>
                </div>
              )}
            </div>
            <Scrollbars style={{ height: "calc(100vh - 90px)" }}>
              {getEventList(
                teams,
                this.toggleVisible,
                staffSelected,
                this.selectAll
              )}
            </Scrollbars>


            <div className="submit-button px-2">

              <button
                type="button"
                onClick={() => this.props.toggle()}
                className="btn primary"
              >
  <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
              </button>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.event.event,
  teams: state.organizations.teams,
  common: state.common,
  visibles: [],
  loading: state.event.loading,
  todos: state.todo.todos,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignees);
