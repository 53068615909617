import api from "../../../common/api";
import uuid from "uuid";
import { getBasicInfo, getOrganization } from "../../../utils";

import * as constant from "./constants";
const sub_url = "/user/InviteUsers";

export function inviteUsers(users, defaultPassword, success) {
  return async (dispatch) => {
    dispatch({
      type: constant.INVITE_USER,
    });
    try {
      const finalUsers = users.map((user) => ({
        Email: user.Email,
        FirstName: user.Name,
        LastName: user.LastName,
        Phone: user.Phone,
        TeamName: user.TeamName,
        TypeOfAccess: user.TypeOfAccess,
        UserInvitingGUID: getBasicInfo().GUID,
        OrganizationGUID: getOrganization().GUID,
        Password: defaultPassword ? defaultPassword : "PLNIFY2019",
      }));
      await inviteUser(finalUsers);
      dispatch({
        type: constant.INVITE_USER_SUCCESS,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.INVITE_USER_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.INVITE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.INVITE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.INVITE_USER_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function inviteUser(users) {
  return api.post(sub_url, users);
}
