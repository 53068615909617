import React, { Component } from "react";
import Todo from "./Todo";
import { IMAGES } from "../../../constant/images";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { default as Navbar } from "../../../components/common/navbar";
import { ToastNotificationSuccess } from "../../../components/common";
import Swal from "sweetalert2";
import { getBasicInfo, getOrganization } from "../../../utils";
import db from "../../../common/db";
import uuid from "uuid";
import moment from "moment";

import * as actions from "../redux/actions";
import * as calendarActions from "../../calendar/redux/actions";
import * as organizationActions from "../../organization/redux/actions";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

class TodoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addListModal: false,
      addTaskModal: false,
      memberModals: false,
      currentList: null,
      editList: false,
      editListModal: false,
      taskEdit: false,
      taskEditModal: false,
      todoSelected: null,
      options: [],
    };
  }

  componentDidMount() {
    const user = getBasicInfo();
    const organization = getOrganization();
    this.props.calendarActions.getEvents(organization.GUID, user.GUID);
    this.props.actions.getListsOfTodo(null, user.GUID, false);
    this.props.organizationActions.getOrganizationList(organization.GUID);
    this.props.organizationActions.getCurrentOrganization(
      organization.GUID,
      user.GUID
    );
  }

  toggleAddList = () => {
    this.setState({
      addListModal: !this.state.addListModal,
    });
  };

  toggleAddTask = () => {
    this.setState({
      addTaskModal: !this.state.addTaskModal,
    });
  };

  setCurrentList = async (list) => {
    let event;

    //Update with remote data object
    await this.props.actions.getTodoDetails(list.GUID);

    let listLocal = await db.table("todos").where({ GUID: list.GUID }).first();
    if (list.EventGUID) {
      event = await db
        .table("events")
        .where({ GUID: listLocal.EventGUID })
        .first();
    }
    this.setState({
      currentList: { ...listLocal, Event: event },
    });
  };

  addList = (list) => {
    const allStaffs = [];
    const user = getBasicInfo();
    const organization = getOrganization();
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
      OrganizationGUID: organization.GUID,
    };
    this.props.teams
      .filter((t) => t.Deleted === null)
      .map((team) => {
        team.Staffs.filter((s) => s.Deleted === null).map((s) =>
          allStaffs.push({
            GUID: uuid.v4(),
            AccountGUID: s.AccountGUID,
            Deleted: new Date(),
            TodoGUID: null,
          })
        );
      });
    this.props.actions.addList(newList, allStaffs, () => {
      ToastNotificationSuccess(Math.random(), "List created successfully");
      this.setCurrentList(newList);
      this.toggleAddList();
    });
  };

  editList = (list, link) => {
    const user = getBasicInfo();
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
    };
    this.props.actions.editList(newList, async () => {
      ToastNotificationSuccess(Math.random(), "List edited successfully");
      await this.props.actions.getListsOfTodo(null, user.GUID, false);
      link ? link() : this.setEditList();
    });
  };

  deleteList = (list) => {
    const listToBeDeleted = {
      ...list,
      Deleted: new Date(),
      TodoTasks: null,
      TodoAssignees: null,
    };
    Swal.fire({
      text: "Are you sure you want to delete this list?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.actions.deleteList(
          listToBeDeleted,
          () =>
            ToastNotificationSuccess(
              Math.random(),
              "List deleted successfully"
            ),

          this.setState({
            todoSelected: this.state?.todos?.[0] || null,
            currentList: null,
          })
        );
      }
    });
  };

  addTask = (task, close, reset) => {
    const user = getBasicInfo();
    if (task.DueDate) {
      const date = moment(task.Date).format("YYYY-MM-DD");
      const time = moment(task.Time).format("HH:mm");
      task.Date = moment(date + "T" + time).toDate();
    }
    const updateList = this.state.currentList;
    updateList.TodoTasks.push(task);
    this.setState({
      currentList: updateList,
    });

    this.props.actions.addTask(task, async () => {
      ToastNotificationSuccess(Math.random(), "Task created successfully");
      await this.props.actions.getListsOfTodo(null, user.GUID, false);
      this.callToCurrent(task);
      if (close) {
        this.toggleAddTask();
      }
      // if (reset) {
      //   reset();
      // }
    });
  };

  editTask = (task) => {
    const {
      actions: { editTask },
    } = this.props;
    const user = getBasicInfo();
    if (task.DueDate) {
      const date = moment(task.Date).format("YYYY-MM-DD");
      const time = moment(task.Time).format("HH:mm");
      task.Date = moment(date + "T" + time).toDate();
    } else {
      task.Date = undefined;
    }
    const updatedTask = this.state.currentList;
    updatedTask.TodoTasks.push(task);
    editTask(task, async () => {
      ToastNotificationSuccess(Math.random(), "Task created successfully");
      await this.props.actions.getListsOfTodo(null, user.GUID, false);
      this.callToCurrent(task);
      this.toggleTaskEdit(null);
    });
  };

  toggleTask = (task) => {
    const user = getBasicInfo();
    const toggledTask = { ...task, Status: task.Status == 0 ? 1 : 0 };
    this.props.actions.toggleTask(toggledTask, async () => {
      await this.props.actions.getListsOfTodo(null, user.GUID, false);
      this.callToCurrent(task);
    });
  };

  deleteTask = (task) => {
    const user = getBasicInfo();
    const deletedTask = { ...task, Deleted: new Date() };
    const updatedTask = this.state.currentList;
    const tasks = updatedTask.TodoTasks.filter((t) => t.GUID !== task.GUID);
    updatedTask.TodoTasks = tasks;
    Swal.fire({
      text: "Are you sure you want to delete this task?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.actions.deleteTask(deletedTask, async () => {
          await this.props.actions.getListsOfTodo(null, user.GUID, false);
          this.setState({
            currentList: updatedTask,
          });
        });
      }
    });
  };

  callToCurrent = async (task) => {
    const current = await db
      .table("todos")
      .where({ GUID: task.TodoGUID })
      .first();
    this.setCurrentList(current);
  };

  toggleAssigneeModal = () => {
    this.setState({
      memberModals: !this.state.memberModals,
    });
  };

  setEdit = () => {
    this.setState({
      editList: !this.state.editList,
    });
  };

  setEditList = () => {
    this.setState(
      {
        editListModal: !this.state.editListModal,
      },
      this.setEdit
    );
  };

  setEditTask = () => {
    this.setState({
      taskEdit: !this.state.editList,
    });
  };

  toggleTaskEdit = (task) => {
    this.setState(
      {
        taskEditModal: !this.state.taskEditModal,
        todoSelected: task,
      },
      this.setEditTask
    );
  };

  render() {
    const { selectedList, events, todos, teams, loading } = this.props;
    return (
      <Todo
        {...this.state}
        toggleAddList={this.toggleAddList}
        toggleAddTask={this.toggleAddTask}
        setCurrentList={this.setCurrentList}
        deleteList={this.deleteList}
        addList={this.addList}
        addTask={this.addTask}
        toggleTask={this.toggleTask}
        deleteTask={this.deleteTask}
        toggleAssigneeModal={this.toggleAssigneeModal}
        setEditList={this.setEditList}
        editListFunc={this.editList}
        setEditTask={this.setEditTask}
        toggleTaskEdit={this.toggleTaskEdit}
        taskEditModal={this.state.taskEditModal}
        taskEdit={this.state.taskEdit}
        todoSelected={this.state.todoSelected}
        selectedList={selectedList}
        editTaskFunc={this.editTask}
        events={events}
        todos={todos}
        teams={teams}
        loading={loading}
        editList={this.state.editList}
        expanded={this.props.expanded}
        showExpanded={this.props.showExpanded}
        getListsOfTodo={this.props.actions.getListsOfTodo}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  todos: state.todo.todos,
  selectedList: state.todo.selectedList,
  events: state.calendar.calendarEvents,
  teams: state.organizations.teams,
  loading: state.todo.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    calendarActions: bindActionCreators({ ...calendarActions }, dispatch),
    organizationActions: bindActionCreators(
      { ...organizationActions },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar(TodoContainer));
