export const IMAGES = {
  MORE_DETAIL_IMG:require("../images/MoreDetails.png"),
  ATTACHMENTS_FILES: require("../images/filesright.png"),
  ROUND_CHECK: require("../images/check-round.png"),
  ROUND_UNCHECK: require("../images/uncheck-round.png"),
  CROSS_ICON: require("../images/cross-icon.png"),
  RADIO: require("../images/radio.png"),
  RADIO_SELECTED: require("../images/radio-selected.png"),
  EXPORT: require("../images/export.png"),
  DELETE: require("../images/delete.png"),
  CALENDER: require("../images/calendar-list.png"),
 // GROUND: require("../images/ground.png"),
  FLIGHTS: '../../../images/flight-empty.svg',//"require("../images/flights.png"),
  LOCATION: require("../images/location.png"),
  MORE_DOTS: require("../images/more-dots.svg"),
  WEATHER: require("../images/weather.png"),
  ZOOM: require("../images/zoom.png"),
  ARROW_LEFT: require("../images/arrow-left.png"),
  ARROW_RIGHT: require("../images/arrow-right.png"),
  DROP_DOWN_ARROW: require("../images/dropdown-arrow.png"),
  MIDDLE_GRAPHIC: require("../images/flight-icon-stop.png"),
  FLIGHT_MIDDLE: require("../images/Flighticonn.png"),
  FILTER: require("../images/filter.png"),
  GROUP: require("../images/Flighticonn.png"),
  BITMAP: require("../images/bitmap.png"),
  EYE_OPEN: require("../images/eye-open.png"),
  EYE_CLOSE: require("../images/eye-closed.png"),
  ORGANIZATION_PLACEHOLDER: require("../images/defaultImage@3x.png"),
  LOGO: require("../images/logo.png"),
  LOGO_COLOR: require("../images/plnifyLogoColor.png"),
  LOGO_BLACK_P: require("../images/logoPLNIFYBlackP.png"),
  LOGO_BLACK: require("../images/plnifyLogoBlack.png"),
  LOGO_ALT: require("../images/LogoInnerShadow1.png"),
  SUCCESS_IMAGE: require("../images/success-icon.png"),
  CHECK_SELECTED: require("../images/checkbox-selected.png"),
  CHECK_UNSELECTED: require("../images/checkbox-unselected.png"),
  EXPIRE_ICON: require("../images/expire-icon.png"),
  PASSWORD_SUCCESS: require("../images/password-success.png"),
  CALENDER_EXPAND: require("../images/calendar-expand.png"),
  SHARED_ICON: require("../images/elements-shared-icons-list.png"),
  TODO_LIST: require("../images/to-do_icon.png"),
  ADD_TODO: require("../images/addtodo_icon.png"),
  
  DASHBOARD: require("../images/view-dashboard-outline.png"),
  CALENDER_SVG: require("../images/Calendar_icon.svg"),
  TICKET_PASSES: require("../images/Guest_list_icon.png"),
  ORGANIZATION: require("../images/team.png"),
  MESSAGES: require("../images/messages.png"),
  NOTIFICATION: require("../images/Notification_icon.png"),
  ADD_EVENT: require("../images/add_event.png"),
  TODO: require("../images/to-do_icon.png"),
  INVITE_USER: require("../images/invite_icon.png"),
  EXPORT_CALENDER_SVG: require("../images/export_calendar_icon.png"),
  DROP_DOWN_SVG: require("../images/icon.svg"),
  NOTE: require("../images/event/note.svg"),
  MAP: require("../images/event/map.svg"),
  VISIBLE: require("../images/event/visible.svg"),
  EXPORT_SVG: require("../images/event/export.svg"),
  EDIT: require("../images/edit.svg"),
  GRAPHIC: require("../images/graphic.svg"),
  DASHBOARD_SELECTED: require("../images/dashboard-selected.svg"),
  RADIO_BUTTON: require("../images/radio.png"),
  RADIO_BUTTON_SELECTED: require("../images/radio-selected.png"),
  INFO: require("../images/info.png"),
  BACK: require("../images/back.png"),
  SUNNY: require("../images/sunny.png"),
  SEARCH_ICON: require("../images/search-icon.png"),
  ARROW_UP: require("../images/arrow-up.png"),
  ARROW_DOWN: require("../images/arrow-down.png"),
  MAP_ICON: require("../images/shape.png"),
  BACK_ICON: require("../images/back.png"),
  DUMMY_ICON: require("../images/dummy.jpg"),
  CHECK: require("../images/check.png"),
  UNCHECK: require("../images/uncheck.png"),
  PROFILE_ICON: require("../images/user-profile.png"),
  CROSS_BLACK: require("../images/cross-black.png"),
  VENUE_IMAGE_PLACEHOLDER: require("../images/VenueIllustrationTransparent.png"),//require("../images/VenuePlaceholder.png"),
  IMAGE_EMAIL_CONFIRMATION: require("../images/email_confirmation.png"),
  PDF: require("../images/001-pdf.png"),
  EXCEL: require("../images/003-xls.png"),
  WORD: require("../images/002-doctor.png"),
  PPT: require("../images/004-ppt.png"),
  LIGHT_ICON: require("../images/light.png"),
  DARK_ICON: require("../images/dark.png"),
  PNG: require("../images/001-png.png"),
  JPG: require("../images/002-jpg.png"),
  DROPBOX_ICON: require("../images/dropbox.png"),
  EMPTY_ITINERARIES: require("../images/itineraries-empty.svg"),
  EMPTY_CONTACTS: require("../images/contacts-empty.svg"),
  EMPTY_GROUNDS: "../images/ground-empty.svg",//require("../images/ground-empty.svg"),
  EMPTY_FLIGHTS: require("../images/flight-empty.png"),
  EMPTY_HOTELS: "../images/hotel-empty.svg",//require("../images/hotel-empty.svg"),
  EMPTY_ATTACHMENTS: '../../images/attachments-empty.svg',//require("../images/attachments-empty.svg"),
  EMPTY_NOTIFICATIONS: "../../images/notification.png",//require("../images/notification.png"),
  EMPTY_ITINERARIES_DARK: require("../images/ItineraryEmptyStage.png"),
  EMPTY_CONTACTS_DARK: require("../images/EventContactEmptyStage.png"),
  EMPTY_GROUNDS_DARK: require("../images/GroundEmptyStage.png"),
  EMPTY_HOTELS_DARK: require("../images/HotelEmptyStage.png"),
  EMPTY_ATTACHMENTS_DARK: require("../images/AttachmentEmptyStage.png"),
  UNION: require("../images/Groundplaceholder.png"),
  GROUND: require("../images/GroundIllustration.png"),
  GROUND_ICON_EMPTY: require("../images/GroundIcon.png"),
  HOTEL_PLACEHOLDER: require("../images/HotelIllustration.png"),
  HOTEL_PLACEHOLDER_DARK: require("../images/HotelIllustrationDark.png"),
  GROUND_PLACEHOLDER: require("../images/GroundIlustration.jpeg"),
  GROUND_PLACEHOLDER_DARK: require("../images/Updatedground.png"),
  GENERAL_NOTES: require("../images/GeneralNoteIcon.png"),
  FLIGHT_PLACEHOLDER: require("../images/flightLight.jpeg"),
  FLIGHT_PLACEHOLDER_DARK: require("../images/FlightDark.png"),
  DEFAULT_FILE: require("../images/UnkownFileIcon.png"),
  TODO_EMPTYSTATE: require("../images/TODO_emptystage.png"),
  TODOLEFT_EMPTYSTATE: require("../images/TODOLEFT_emptystage.svg"),
  TEAM_EMPTYSTATE: require("../images/TEAM_emptystate.svg"),
  GUESTLIST_EMPTYSTATE: require("../images/GuestList.png"),
  LOGIN_BG: require("../images/login-bg.png"),
  LOGO_GRADIENT: require("../images/logo-gradient.png"),
  ROUNDED_CHECKMARK: require("../images/rounded-signup.png"),
  LOGO_ONBOARDING: require("../images/plnify-slide.png"),
  WORKSPACES_ONBOARDING: require("../images/workspaces-slide.png"),
  IMPORT_ONBOARDING: require("../images/import-slide.png"),
  WINDOW_ONBOARDING: require("../images/window-slide.png"),
  ORGANIZATION_CREATE_PLACEHOLDER: require("../images/createorg-placeholder.png"),
  RECENT_MESSAGES_PLACEHOLDER: require("../images/MessageIllustration.png"),
  CALENDAR_LIST_PLACEHOLDER: require("../images/ListingCalendar.png"),
  APPLE_STORE: require("../images/applebadget.png"),
  PLAY_STORE: require("../images/googlebadget.png"),
  APPROVED_CIRCLE: require("../images/ApprovedCircleArrow.png"),
  NOT_FOUND_PAGE: require("../images/404.png"),
  WEATHER_BG: require("../images/weather_bg.png"),
  ACTIVITIES_PLACEHOLDER: require("../images/EmptyStateItinerary.png"),
  NOTES_EMPTY: require("../images/EmptyStageIllustrationStickies.png"),
};
