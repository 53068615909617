import initialState from "./initialState";
import { reducer as loginReducer } from "./login";
import { reducer as signUpReducer } from "./signup";
import { reducer as forgotPasswordReducer } from "./forgotPassword";
import { reducer as changePasswordReducer } from "./changePassword";
import { reducer as confirmedEmailReducer } from "./confirmEmail";

const reducers = [
  loginReducer,
  signUpReducer,
  forgotPasswordReducer,
  changePasswordReducer,
  confirmedEmailReducer
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
