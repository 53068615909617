import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import uuid from "uuid";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import { addContact } from "../../page/event/redux/addContact";
import { addEvent } from "../../page/event/redux/addEvent";
import { addHotel } from "../../page/event/redux/addHotel";
import { addItinerary } from "../../page/event/redux/addItinerary";
import { editAttachment } from "../../page/event/redux/editAttachment";
import { addTask } from "../../page/todoPage/redux/addTask";
import {
  getBasicInfo,
  getCurrentEventGUID,
  getOrganization,
} from "../../utils";
import { ToastNotificationSuccess } from "../common";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import CustomDropDrownMenu from "../common/CustomDropDrownMenu";

export const MoveEventModal = ({ show, toggle, event }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const [errors, setErrors] = useState({ event: "", workspace: "" });
  const [events, setEvents] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({
    Name: "Select destination workspace",
  });

  const [selectedEvent, setSelectedEvent] = useState({
    Name: "Select event to be moved",
  });

  const [isLoading, setIsLoading] = useState(false);

  const teams = useSelector((state) => state.organizations.teams);
  const GlobalState = useSelector((state) => state);

  useEffect(() => {
    (async () => {
      const currentOrganization = getOrganization().GUID;
      const organizations = (await db.table("organizations").toArray()).filter(
        (org) => org.GUID !== currentOrganization
      );
      const events = (await db.table("events").toArray()).filter(
        (event) =>
          event.Deleted === null &&
          event.OrganizationGUID === currentOrganization
      );
      setEvents(events);
      setOrganizations(organizations);

      const currentEventGUID = getCurrentEventGUID();
      const currentEvent = events
        .filter((event) => event.GUID === currentEventGUID)
        .reduce((accumulator, current) => ({ ...current }), {});

      setSelectedEvent({ ...currentEvent, variant: "secondary" });
    })();
  }, []);

  const handleSelectedOrg = (org, url) => {
    setSelectedOrg({ ...org, variant: "secondary", Logo: url });
  };

  const handleSelectedEvent = (event) => {
    setSelectedEvent({ ...event, variant: "secondary" });
  };

  const createItinerary = (itinerary, destination) => {
    let newRecord = {
      Date: itinerary.Date,
      Name: itinerary.Name,
      GUID: itinerary.GUID,
      EventGUID: destination,
    };

    dispatch(
      addItinerary(
        newRecord,
        destination,
        () => {}, //success,
        () => {} //Toggle
      )
    );
  };

  const createFlights = async (flights, destination) => {
    const updatedFlights = flights.map((flight) => {
      return { ...flight, EventGUID: destination };
    });

    await apiService.post("/flights/AddEditBatch", updatedFlights);
  };

  const createHotels = (hotels, destination) => {
    const updatedHotel = [{ ...hotels, EventGUID: destination }];

    dispatch(
      addHotel(
        updatedHotel,
        () => {}, //success,
        true //
      )
    );
  };

  const createTransportation = async (transportation, GUID) => {
    const updatedTransportation = { ...transportation, EventGUID: GUID };
    try {
      let query = [];
      query.push(updatedTransportation);
      await api.post("/grounds/AddEditBatch", query);
    } catch (ex) {
      console.error(ex);
    }
  };

  const createContact = (contact, GUID) => {
    delete contact.EventGUID;
    dispatch(addContact(contact, GUID, () => {}));
  };

  const createAttachment = (attachment, GUID) => {
    const updatedAttachment = { ...attachment, EventGUID: GUID };
    dispatch(editAttachment(updatedAttachment));
  };

  const createNote = async (note, GUID, OrgGUID) => {
    const newNote = {
      GUID: uuid.v4(),
      Name: note?.Name,
      Details: note?.Details,
      OrganizationGUID: OrgGUID,
      EventGUID: GUID,
      Deleted: null,
      Updated: null,
    };

    await db.table("notes").put(newNote);
    await api.post("/Note/AddEditBatch", [newNote]);
  };

  const createTodo = async (todo, GUID, OrgGUID) => {
    const currentUser = getBasicInfo().GUID;
    const newTodoGUID = uuid.v4();
    const newTodo = {
      Created: todo?.Created,
      CreatedByGUID: currentUser,
      Date: todo?.Date,
      Deleted: todo?.Deleted,
      EventGUID: GUID,
      GUID: newTodoGUID,
      Name: todo?.Name,
      Note: todo?.Note,
      OrganizationGUID: OrgGUID,
    };
    const postedTodo = await apiService.post("ToDo/AddEdit", newTodo);
    const createdTodo = await postedTodo?.data?.Response;
    const tasks = todo?.TodoTasks?.map((task) => ({
      ...task,
      GUID: uuid.v4(),
      TodoGUID: createdTodo.GUID,
    }));
    createdTodo.TodoTasks = tasks;
    await db.table("todos").put(createdTodo);
    tasks.forEach((task) => {
      if (task.Deleted === null) dispatch(addTask(task, () => {}));
    });
  };

  const deleteEvent = (event, callback) => {
    event.Deleted = new Date();

    console.log({ EventOnDelete: event });

    const url = `/Events/Edit`;
    try {
      db.table("events").put(event);
      db.table("event").put(event);
      api.post(url, {
        Name: event.Name,
        EventType: event.EventType,
        EventCapacity: event.EventCapacity,
        Date: `${event.Date}`,
        VenueGUID: event.VenueGUID,
        Notes: event.Notes,
        City: event.City,
        Country: event.Country,
        State: event.State,
        Status: event.Status,
        Latitude: event.Latitude,
        Longitude: event.Longitude,
        GUID: event.GUID,
        OrganizationGUID: event.OrganizationGUID,
        LastSync: event.LastSync,
        DateTime: event.DateTime,
        CreationDate: event.CreateDate,
        Deleted: event.Deleted,
        Image: event.Image,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets,
        WillCallCity: event.WillCallCity,
        WillCallHours: event.WillCallHours,
        WillCallState: event.WillCallState,
        WillCallAddress: event.WillCallAddress,
        WillCallCountry: event.WillCallCountry,
        WillZipCode: event.WillZipCode,
        WillCallPhone: event.WillCallPhone,
        WillCallComment: event.WillCallComment,
      });
      callback();
    } catch (error) {
      console.log("Error while deleting event " + error);
    }
  };

  const completeMove = () => {
    setIsLoading(true);
    if (!selectedOrg.GUID || !selectedEvent.GUID)
      return setErrors((prev) => ({
        ...prev,
        event: !selectedEvent.GUID ? true : false,
        workspace: !selectedOrg.GUID && selectedEvent.variant ? true : false,
      }));

    const currentUser = getBasicInfo().GUID;

    const {
      City,
      Country,
      Date,
      EventType,
      LastSync,
      Latitude,
      Longitude,
      Name,
      Notes,
      State,
      Status,
      VenueGUID,
    } = selectedEvent;

    const eventToMove = {
      City,
      Country,
      CreatedUserGUID: currentUser,
      Date,
      EventCapacity: "",
      EventType,
      LastSync,
      Latitude,
      Longitude,
      Name,
      Notes,
      OrganizationGUID: selectedOrg.GUID,
      State,
      Status,
      VenueGUID,
      VenueObj: {
        Address: selectedEvent?.Venue?.Address,
        AgeRestrictions: null,
        Capacity: selectedEvent?.Venue?.Capacity,
        City: selectedEvent?.Venue?.City,
        Country: selectedEvent?.Venue?.Country,
        Created: null,
        CreatedUserID: null,
        Deleted: selectedEvent?.Venue?.Deleted,
        DeletedUserID: null,
        Events: [],
        GUID: selectedEvent?.Venue?.GUID,
        GooglePlaceID: event?.Venue?.GooglePlaceID,
        Image: selectedEvent?.Venue?.Image,
        Latitude: selectedEvent?.Venue?.Latitude,
        Longitude: selectedEvent?.Venue?.Longitude,
        Name: selectedEvent?.Venue?.Name,
        Organization: null,
        OrganizationGUID: selectedEvent?.Venue?.OrganizationGUID,
        ProductionLaborDetails: [],
        State: selectedEvent?.Venue?.State,
        Type: selectedEvent?.Venue?.Type,
        Updated: selectedEvent?.Venue?.Updated,
        UpdatedUserID: null,
        ZipCode: selectedEvent?.Venue?.ZipCode,
      },
    };

    dispatch(
      addEvent(
        eventToMove,
        [],
        teams,
        (request) => {
          const GUID = request.GUID;

          GlobalState.event &&
            GlobalState?.event?.schedulesMasters?.forEach((itinerary) => {
              createItinerary(itinerary, GUID);
            });

          GlobalState?.event?.flights &&
            createFlights(GlobalState.event.flights, GUID);

          GlobalState?.event?.hotels &&
            GlobalState?.event?.hotels?.forEach((hotel) => {
              createHotels(hotel, GUID);
            });

          GlobalState?.event?.grounds &&
            GlobalState?.event?.grounds?.forEach((transportation) => {
              createTransportation(transportation, GUID);
            });

          GlobalState?.event?.contacts &&
            GlobalState?.event?.contacts?.forEach((contact) => {
              createContact(contact, GUID);
            });

          GlobalState?.event?.attachments &&
            GlobalState?.event?.attachments?.forEach((attachment) => {
              createAttachment(attachment, GUID);
            });

          GlobalState?.event?.notes &&
            GlobalState?.event?.notes?.forEach((note) => {
              createNote(note, GUID, selectedOrg.GUID);
            });

          GlobalState?.todo?.todos &&
            GlobalState?.todo?.todos?.forEach((todo) => {
              if (
                todo.Deleted === null &&
                todo.EventGUID === selectedEvent.GUID
              )
                createTodo(todo, GUID, selectedOrg.GUID);
            });

          deleteEvent(selectedEvent, () => {
            ToastNotificationSuccess(1, "Event moved succesfully.");
            setIsLoading(false);
            history.push("/calendar");
          });
        },
        EventType
      )
    );
  };

  const handleMove = async () => {
    Swal.fire({
      text: "Are you sure you want to move this event? This action will delete this event from your current workspace.",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        completeMove();
      }
    });
  };

  let organizationsWithImage = organizations.map((org) => {
    org.imgUrl =
      org?.Logo?.length > 40
        ? org.Logo
        : `https://service.plnify.com/files/${org.Logo}?w=25&h=25&mode=crop&autorotate=true`;
    return org;
  });

  const [searchEvent, setSearchEvent] = useState("");
  const handleSearchEvent = (e) => {
    setSearchEvent(e.target.value);
  };

  let filterEvents = events.filter((event) => {
    if (searchEvent === "") {
      return event;
    } else if (
      event.Name.toLowerCase().includes(searchEvent.toLowerCase()) ||
      event.City.toLowerCase().includes(searchEvent.toLowerCase())
    ) {
      return event;
    }
  });

  const [searchOrg, setSearchOrg] = useState("");
  const handleSearchOrg = (e) => {
    setSearchOrg(e.target.value);
  };

  let filterOrgs = organizationsWithImage.filter((org) => {
    if (searchOrg === "") {
      return org;
    } else if (org.Name.toLowerCase().includes(searchOrg.toLowerCase())) {
      return org;
    }
  });

  return (
    <Modal show={show} onHide={toggle} backdrop="static">
      <div
        className="bg-white text-dark d-flex flex-column justify-content-between border-0 rounded"
        style={{ minHeight: "150px" }}
      >
        <div className="text-head d-flex justify-content-between">
          <h4 className="font-weight-bold">Move event to another workspace</h4>
          <button className="btn btn-sm ml-2" onClick={toggle}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="d-flex p-3 flex-column ">
          <div className="d-flex flex-column mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <span className="mr-2 font-weight-bold">Event:</span>
              <CustomDropDrownMenu
                dropDownStyle={{
                  width: "100%",
                  overflow: "auto",
                  maxHeight: "200px",
                  position: "relative",
                }}
                items={filterEvents}
                hasSearch={true}
                selectedItem={selectedEvent}
                selectedItemProp={"Name"}
                handleSearch={handleSearchEvent}
                handleSelectedItem={handleSelectedEvent}
                search={searchEvent}
                DrownDownProp={"Name"}
              />
            </div>
            {errors.event && (
              <span className="text-danger" style={{ fontSize: "12px" }}>
                Please select a valid event
              </span>
            )}
          </div>

          {selectedEvent.variant && (
            <div className="d-flex flex-column mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <span className="mr-2 font-weight-bold">Workspace:</span>
                <CustomDropDrownMenu
                  dropDownStyle={{
                    width: "100%",
                    overflow: "auto",
                    maxHeight: "200px",
                    position: "relative",
                  }}
                  hasSearch={true}
                  items={filterOrgs}
                  selectedItem={selectedOrg}
                  selectedItemProp={"Name"}
                  handleSelectedItem={handleSelectedOrg}
                  handleSearch={handleSearchOrg}
                  search={searchOrg}
                  DrownDownProp={"Name"}
                />
              </div>

              {errors.workspace && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  Please select a valid workspace
                </span>
              )}
            </div>
          )}

          <div className="d-flex mt-3">
            <button
              className="btn btn-dark mr-2 border-0 w-50 p-3"
              onClick={toggle}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary border-0 w-50 d-flex justify-content-center align-items-center"
              onClick={handleMove}
            >
              {isLoading && (
                <div
                  className="spinner-border mr-2"
                  style={{ width: "20px", height: "20px", color: "white" }}
                ></div>
              )}
              Move
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
