import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";

export function deleteGroup(group, success) {
  const groupUrl = "/EventGroup/AddEditBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_GROUP,
      });
      await apiService.post(groupUrl, [
        { ...group, Events: null, Deleted: new Date() },
      ]);
      dispatch({
        type: constant.DELETED_GROUP,
      });
      dispatch({
        type: constant.UPDATE_DELETE_GROUPS,
        data: group,
      });
      success();
    } catch (e) {
      dispatch({
        type: constant.DELETING_GROUP_ERROR,
        error: e,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_GROUP: {
      return {
        ...state,
        deletingGroup: true,
      };
    }
    case constant.DELETED_GROUP: {
      return {
        ...state,
        deletingGroup: false,
      };
    }
    case constant.UPDATE_DELETE_GROUPS: {
      const coloringEvents = state.calendarEvents.map((event) => {
        if (event.EventGroupGUID === action.data.GUID) {
          event.EventGroup = null;
          event.EventGroupGUID = null;
          return event;
        }
        return event;
      });
      return {
        ...state,
        calendarEvents: coloringEvents,
        creatingGroup: false,
      };
    }
    case constant.DELETING_GROUP_ERROR: {
      return {
        ...state,
        deletingGroup: false,
      };
    }
    default:
      return state;
  }
}
