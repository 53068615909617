import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";

export function getGroups(organizationGUID) {
  const url = "/EventGroup/List?OrganizationGUID";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.GETTING_GROUPS,
      });
      const groupRequest = await apiService.get(`${url}=${organizationGUID}`);
      const cleanedEvents = groupRequest.data.Response.map((group) => {
        group.Events.filter((e) => e.Deleted === false);
      });
      const cleanedGroups = groupRequest.data.Response.filter(
        (g) => g.Deleted === null
      );
      dispatch({
        type: constant.GET_GROUPS,
        data: cleanedGroups,
      });
    } catch (e) {
      dispatch({
        type: constant.GETTING_GROUPS_ERROR,
        error: e,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_GROUPS: {
      return {
        ...state,
        eventGroups: action.data,
      };
    }
    default:
      return state;
  }
}
