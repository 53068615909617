import { INIT_EVENTS, LOADING_EVENTS, LOADED_EVENTS } from "./constants";
import db from "../../../common/db";

export function initEvents() {
  return async (dispatch) => {
    dispatch({
      type: INIT_EVENTS,
    });
    if ((await db.table("events").count()) >= 1) {
      dispatch({
        type: LOADED_EVENTS,
      });
    } else {
      dispatch({
        type: LOADING_EVENTS,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
