import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteItinerary(itinerary, success) {
  const url = `/ScheduleMaster/AddEdit`;
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_ITINERARY,
    });
    try {
      await db.table("schedules").delete(itinerary.GUID);
      dispatch({
        type: constant.DELETED_ITINERARY_SUCCESS,
        data: itinerary,
      });
      success();

      await api.post(url, {
        ...itinerary,
        Schedules: null,
        ScheduleMasterVisibleBies: null,
        Deleted: new Date(),
      });
    } catch (error) {
      dispatch({
        type: constant.DELETING_ITINERARY_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETED_ITINERARY_SUCCESS: {
      return {
        ...state,
        schedulesMasters: state.schedulesMasters.filter(
          (s) => s.GUID !== action.data.GUID
        ),
      };
    }
    default:
      return state;
  }
}
