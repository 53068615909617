import * as constant from "./constants";

export function clearNotes() {
  return (dispatch) => {
    dispatch({
      type: constant.CLEAR_NOTES,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CLEAR_NOTES: {
      return {
        ...state,
        notes: [],
      };
    }
    default:
      return state;
  }
}
