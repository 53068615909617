import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";
import uuid from "uuid";

export function addVehicle(vehicle, success) {
  const url = `/Grounds/AddEditVehicleBatch`;
  return async (dispatch) => {
    dispatch({
      type: constant.ADDING_VEHICLE,
    });
    try {
      const request = await api.post(url, [
        { Deleted: null, GUID: uuid.v4(), ...vehicle },
      ]);
      dispatch({
        type: constant.ADDED_VEHICLE,
        data: request.data.Response,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.ADDING_VEHICLE_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.ADDING_VEHICLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.ADDED_VEHICLE: {
      return {
        ...state,
        loading: false,
        grounds: state.grounds.map((g) => {
          if (g.GUID === action.data[0].GroundGUID) {
            return { ...g, GroundVehicles: action.data };
          }

          return g;
        }),
      };
    }
    case constant.ADDING_VEHICLE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
