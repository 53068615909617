import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import db from "../../common/db";
import { getBasicInfo } from "../../utils";
import { ToastNotificationSuccess } from "../common";

export const Settings = (props) => {
  const intl = useIntl()
  const basicInfoGUID = getBasicInfo().GUID;
  const [DataSubmit, setDataSubmit] = useState({});

  const handleSubmit = async (todo) => {
    const appSettings = await db.table("appSettings").where({AccountGUID: basicInfoGUID}).first();
    if (appSettings) {
     await db.table("appSettings").put({...appSettings,...todo});
    }else {
     await db.table("appSettings").put({AccountGUID: basicInfoGUID});
    }
    if(JSON.stringify(todo) !== JSON.stringify(appSettings)){
      ToastNotificationSuccess(1, "Settings Succesfully Updated")
      window.location.reload();
    }else {
      ToastNotificationSuccess(1, "Configuration not updated correctly") 
    }
  };
  const loadData = async () => {
    const appSettings = await db.table("appSettings").where({AccountGUID: basicInfoGUID}).first();
    if (appSettings) {
      let dataSettings = await db.appSettings.toArray();
    setDataSubmit(dataSettings[0]);
    }else {
      await db.table("appSettings").put({AccountGUID: basicInfoGUID});
    }
  
  }
  useEffect(() => {
    loadData();
  }, []);
  const initialValue = {
    Preferred:"",
    Temperature:"",
    metric:"",
  }
  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">

        <Formik
         enableReinitialize
            initialValues={DataSubmit ? DataSubmit : initialValue}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(props) => (
            <Form autoComplete="off" className="pt-1 row">
              <div className="col-6"> 
                <div>
                  <label className="mb-3"><FormattedMessage
    id="preferredDay"
    defaultMessage="Preferred Day"/></label>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    <Field as="select" name="Preferred" className="form-control">
                      <option value="0">{intl.formatMessage({id:"sunday"})}</option>
                      <option value="1">{intl.formatMessage({id:"monday"})}</option>
                    </Field>
                  </div>
                </div>
                </div>
                <div className="col-6"> 
                <div>
                  <label className="mb-3"><FormattedMessage
    id="temperatureScale"
    defaultMessage="Temperature Scale"/></label>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                     <Field as="select" name="Temperature"  className="form-control">
                      <option value="f">{}Fahrenheit</option>
                      <option value="c">Celsius</option>
                    </Field>
                  </div>
                </div>
                </div>
                <div className="col-6"> 
                <div>
                  <label className="mb-3"><FormattedMessage
    id="preferredMetricUnit"
    defaultMessage="Preferred metric unit"/></label>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    <Field as="select" name="metric" className="form-control">
                      <option value="k">{intl.formatMessage({id:"Kilometers"})}</option>
                      <option value="m">{intl.formatMessage({id:"Miles"})}</option>
                    </Field>
                  </div>
                </div>
              </div>

              <div className="col-6"> 
                <div>
                  <label className="mb-3"><FormattedMessage
    id="lenguage"
    defaultMessage="Language"/></label>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    <Field as="select" name="lenguage" className="form-control">
                      <option value="1">{intl.formatMessage({id:"EN"})}</option>
                      <option value="2">{intl.formatMessage({id:"ES"})}</option>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right">
                <button className="btn btn-primary" type="submit">Save</button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  )

}