import React, { useEffect, useState } from "react";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import { IMAGES } from "../../constant";
import { getOrganization } from "../../utils";
import TemplateItem from "./TemplateItem";
import TemplateActivity from "./TemplateActivity";
import { useIntl, FormattedMessage } from "react-intl";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Field, Form, Formik } from "formik";

const TemplatesModal = (props) => {
  let { toggle, templates, createFromTemplate } = props;
  const [stateTemplate, setStateTemplate] = useState([]);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);

  const loadTemplatesRemote = async () => {
    let theOrganization = getOrganization();
    const request = await apiService.get(
      "/ScheduleTemplate/List?OrganizationGUID=" + theOrganization.GUID
    );

    console.log({ request });
    setStateTemplate(request.data);
  };

  const addEditTemplate = async () => {
    let theOrganization = getOrganization();
    const request = await api.post(
      "/ScheduleTemplate/AddEdit",
      templateSelected
    );
    setStateTemplate(request.data);
    ToastNotificationSuccess(1, "Saved");
  };

  const viewDetailsOftheTemplate = async (obj, reload) => {
    setTemplateSelected(obj);
    setToggleDetails(!toggleDetails);
    if (reload) {
      await loadTemplatesRemote();
    }
  };

  const updateTemplate = async (selected) => {
    setTemplateSelected(selected);
    await loadTemplatesRemote();
  };
  useEffect(() => {
    loadTemplatesRemote();
  }, []);

  const [showTemplateLabel, setShowTemplateLabel] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const handleCreateTemplate = async ({ Label, Name }) => {
    let theOrganization = getOrganization();
    const request = await apiService.post(`/ScheduleTemplate/Edit`, {
      GUID: currentTemplate.GUID,
      Label: Label || currentTemplate.Label,
      Name: Name || currentTemplate.Name,
      OrganizationGUID: theOrganization.GUID,
    });

    handleAddEditTemplateLabel();
    loadTemplatesRemote();
  };

  const handleAddEditTemplateLabel = () => {
    setShowTemplateLabel(!showTemplateLabel);
  };


  return (
    <>
      <ModalComponent
        modal={showTemplateLabel}
        toggle={handleAddEditTemplateLabel}
        childeren={
          <div className="">
            <div className="event-page" style={{ overflow: "visible" }}>
              <div className="event-expennd-section">
                <div className="text-head">
                  <div className="text-left d-flex w-100 justify-content-between">
                    <h1>
                      <FormattedMessage
                        id="addLabel"
                        defaultMessage={
                          currentTemplate?.Name
                            ? "Edit Template"
                            : "Add Template"
                        }
                      />
                    </h1>
                    <button
                      type="button"
                      className="btn btn-sm btn-link ml-2"
                      onClick={() => handleAddEditTemplateLabel()}
                    >
                      <FontAwesomeIcon icon={faTimes} color="white" />
                    </button>
                  </div>
                </div>
                <div className="event-inside pl-0">
                  <div className="event-content " style={{ paddingBottom: 24 }}>
                    <Formik
                      initialValues={{
                        Label: currentTemplate?.Label || "",
                        Name: currentTemplate?.Name || "",
                      }}
                      onSubmit={(values) => handleCreateTemplate(values)}
                      validateOnBlur={false}
                      validateOnChange={false}
                    >
                      {(props) => (
                        <Form>
                          <div className="row">
                            <div className="col-12 my-3">
                              <div className="d-flex col-12 justify-content-between align-items-center">
                                <label>
                                  <FormattedMessage
                                    id="tags"
                                    defaultMessage="Label"
                                  />
                                </label>
                                <span
                                  className="text-black-50"
                                  style={{ fontSize: "10px" }}
                                >
                                  *Optional
                                </span>
                              </div>
                              <Field name="Label" className="form-control" />
                            </div>
                            <div className="col-12 my-3">
                              <div className="d-flex col-12 justify-content-between align-items-center">
                                <label>
                                  <FormattedMessage
                                    id="tags"
                                    defaultMessage="Name"
                                  />
                                </label>
                              </div>
                              <Field name="Name" className="form-control" />
                            </div>
                            <div className="col-12">
                              <button className="btn btn-primary" type="submit">
                                <FormattedMessage
                                  id="done"
                                  defaultMessage="Done"
                                />
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <div className="organization-transparent">
        <div className="event-page text-dark">
          <div className="event-expennd-section ">
            <div className="text-head">
              <div className="text-left">
                <h1>
                  <FormattedMessage id="template" defaultMessage="Templates" />
                </h1>
              </div>
              <div className="text-right">
                {toggleDetails ? (
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => viewDetailsOftheTemplate(null, true)}
                  >
                    <FormattedMessage
                      id="backToList"
                      defaultMessage="Back to list"
                    />
                  </button>
                ) : null}
              </div>
              <div
                className="close-icon d-none d-none"
                onClick={() => toggle()}
              >
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div>
            </div>
            <div className="event-inside">
              {toggleDetails ? (
                <>
                  <div className="my-4">
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        value={templateSelected.Name}
                        className="form-control"
                        onChange={(e) => {
                          setTemplateSelected({
                            ...templateSelected,
                            Name: e.target.value,
                          });
                        }}
                      />

                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button "
                          onClick={async () => {
                            addEditTemplate();
                          }}
                        >
                          Rename
                        </button>
                      </div>
                    </div>
                    {templateSelected?.ScheduleDetailsTemplates?.filter(
                      (a) => a.Deleted === null
                    )
                      .sort((a, b) => {
                        if (a.Date > b.Date) return 1;
                        if (a.Date < b.Date) return -1;
                      })
                      .map((activity, index) => (
                        <TemplateActivity
                          key={index}
                          activity={activity}
                          scheduleMaster={templateSelected}
                          updateTemplate={updateTemplate}
                        />
                        //   <Activity
                        //     key={activity.GUID}
                        //     activity={activity}
                        // //    deleteActivity={deleteActivity}
                        //   //  event={event.GUID}
                        //     //addActivity={addActivity}
                        //     //scheduleMaster={sideBarItemData}
                        //     editActivity={editActivity}
                        //     refreshSchedules={refreshSchedules}
                        //   />
                      ))}
                  </div>
                  <div>
                    <button
                      className="btn btn-block btn-primary mt-2"
                      onClick={() =>
                        createFromTemplate(templateSelected, () => toggle())
                      }
                    >
                      <FormattedMessage
                        id="importTemplate"
                        defaultMessage="Import Template to the Event"
                      />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {stateTemplate.length > 0 ? (
                    <>
                      {stateTemplate
                        .filter((t) => t.Name && t.Deleted === null)
                        .sort((a, b) => {
                          if (b.Created > a.Created) return 1;
                          if (b.Created < a.Created) return -1;
                        })
                        .map((t) => (
                          <TemplateItem
                            key={t.GUID}
                            template={t}
                            onClick={(e) => {
                              e.preventDefault();
                              viewDetailsOftheTemplate(t);
                              //createFromTemplate(t, () => toggle());
                            }}
                            updateTemplate={updateTemplate}
                            showTemplateLabel={showTemplateLabel}
                            handleCreateTemplate={handleCreateTemplate}
                            handleAddEditTemplateLabel={
                              handleAddEditTemplateLabel
                            }
                            setCurrentTemplate={setCurrentTemplate}
                          />
                        ))}
                    </>
                  ) : (
                    <>
                      <FormattedMessage
                        id="noTemplateYet"
                        defaultMessage="No template yet"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesModal;
