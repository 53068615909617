import React from "react";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import {
  DropDownComponent,
  ToastNotificationSuccess,
  ModalComponent,
} from "../common";
import api from "../../common/api";
import {useIntl, FormattedMessage} from 'react-intl'

const TemplateItem = (props) => {
  const intl = useIntl()
  function deleteConfirmation(activity) {
    Swal.fire({
      text: intl.formatMessage({id:"areYouDelete"})+activity.Name+"'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {

     //TODO review end point not working
      if (result.value) {
        console.log({request: activity.GUID})
        let request = await api.get("/ScheduleTemplates/RemoveTemplate?TemplateGUID="+ activity.GUID);
        if(request.data){
          props.updateTemplate(request.data)
          ToastNotificationSuccess(1, "Template deleted successfully")
        }
       
      }
    });
  }
  return (
    <div className="d-flex align-items-center flex-row template-item card p-1 shadow-sm my-2">
      <div className="flex-1">
        <a onClick={props.onClick} className="text-dark" href="#">
          {props.template.Name}
        </a>
      </div>
      <div className="flex-1 d-flex align-items-center justify-content-end">
        <DropDownComponent
          data={[
          
            {
              delete: true,
              name: <FormattedMessage
                id="delete"
                defaultMessage="Delete"/>,
              
              trigger: () => deleteConfirmation(props.template),
              //trigger: () => console.log("klk"),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TemplateItem;
