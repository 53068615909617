import moment from "moment";

export const TodoComment = (props) => {
  return (
    <div className="d-flex my-4">
      <div className="bg-secondary d-flex align-items-center justify-content-center rounded p-2 mr-2">
        <span
          className="text-uppercase text-white"
          style={{ fontSize: "12px", fontWeight: "bold" }}
        >
          {props?.FirstName[0]}
          {props?.LastName[0]}
        </span>
      </div>

      <div>
        <span className="d-flex">
          <h6 className="font-weight-bold">{`${props?.FirstName} ${props?.LastName}`}</h6>
          <p className="ml-2">
            {moment(props?.Date).format("MMM DD, YYYY hh:mm A")}
          </p>
        </span>
        <p>{props?.Comment}</p>
      </div>
    </div>
  );
};
