import React, { useState, useEffect } from "react";
import { CheckboxBlank, CheckboxMarked } from "mdi-material-ui";
import { randomImage } from "../common/randomImage";
import GeneralUserItem from "../common/GeneralUserItem";

const MailContactItem = (props) => {
  const [img, setImg] = useState(null);
  let { emails, staff, selectEmail } = props;
  let initials =
    (staff.Account.Name && staff.Account.Name.charAt(0).toUpperCase()) +
    (staff.Account.LastName && staff.Account.LastName.charAt(0).toUpperCase());
  useEffect(() => {
    setImg(randomImage(initials));
  }, []);
  return (
    <div
      onClick={() => selectEmail(emails, staff.Account.Email)}
      className="mailingList__member-root"
    >
      <div className="mailingList__member p-2 mx-2 shadow-sm my-1 bg-white rounded-lg">
        <div className="mailingList__check text-dark text-center">
          {emails?.includes(staff.Account.Email) ? (
            <i className="fas fa-check-square"></i>
          ) : (
            <i className="far fa-square uncheck"></i>
          )}
        </div>

        <div className="mailingList__details px-0">
          <GeneralUserItem account={staff.Account} showDetails />
        </div>
      </div>
    </div>
  );
};

export default MailContactItem;
