import { useState } from "@hookstate/core";
import React, { useEffect } from "react";

import { IMAGES } from "../../constant";
import EmptyState from "../../components/common/emptyState";

import { copyToClipboard, typeIconEquivalent } from "./helper";
import api, { apiService } from "../../common/api";
import { getBasicInfo, getOrganization } from "../../utils";
import { useIntl, FormattedMessage } from "react-intl";
function ListFolders(props) {
  const intl = useIntl();
  const state = useState({
    DestinationFolder: null,
    CurrentFolderPath: [],
    CurrentUser: null,
    Files: [],
    UI: {
      isLoading: false,
    },

    SelectedFolder: "/",
  });

  useEffect(() => {
    const currentOrganizationGUID = getOrganization().GUID;

    state.CurrentUser.set(getBasicInfo());
    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath.value));
    copy.push({ GUID: "/", Name: "/" });

    state.CurrentFolderPath.set(copy);
    console.log("New state", state.get());
    requestFolderRemote();
  }, []);

  const moveHandler = async () => {
    let items = [];
    console.log(
      "files to process",
      props.parentState,
      props.parentState.SelectedFiles
    );
    props.parentState.SelectedFiles.forEach((item) => {
      items.push({
        GUID: item.value,
        //  Type: item.Type.value
      });
    });
    console.log("destination ", state.DestinationFolder, items);

    state.UI.isLoading.set(!state.UI.isLoading.value);

    //We have to sent to api
    const request = await api.post(
      "/File/Move?Target=" +
        (state.DestinationFolder.GUID !== undefined
          ? state.DestinationFolder.GUID.value
          : ""),
      items
    );
    state.UI.isLoading.set(!state.UI.isLoading.value);
    props.toggle();
  };

  const requestFolderRemote = async () => {
    state.UI.isLoading.set(!state.UI.isLoading.value);
    const request = await apiService.get(
      "/File/List?OnlyFolder=true&UserID=" +
        state.CurrentUser.GUID.value +
        (state.SelectedFolder !== undefined &&
        state.SelectedFolder.value !== "/"
          ? "&FolderId=" + state.SelectedFolder.value
          : "&FolderId=")
    );
    state.UI.isLoading.set(!state.UI.isLoading.value);
    let response = request.data;
    console.log({ response });
    if (response.Status === "ok") {
      state.Files.set(response.Response.filter((item) => !item.Deleted));
      //   state.SelectedFile.set(null);
    }
  };

  const enterFolder = (item) => {
    state.SelectedFile.set(null);
    state.SelectedFolder.set(item.GUID);
    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath.value));
    var exist = copy.some((x) => x.GUID === item.GUID);
    if (exist) {
      //If exist we must go over the array and create a new one until this record
      let newArray = [];
      for (var i = 0; i < copy.length; i++) {
        newArray.push(copy[i]);
        if (copy[i].GUID === item.GUID) {
          break;
        }
      }

      copy = newArray;
    } else {
      copy.push({ GUID: item.GUID, Name: item.Name });
    }
    state.CurrentFolderPath.set(copy);

    console.log(
      "ITEMS",
      state.CurrentFolderPath.value,
      state.SelectedFolder.value
    );
    requestFolderRemote();
  };

  const requestFiles = () => {
    let files = [];
    state.Files.get().forEach((item) => {
      let newFile = (
        <div className="col-12 px-0 mx-0" key={item.GUID}>
          <div
            className={
              (item.GUID === state.SelectedFolder.value ? "active " : "") +
              "row "
            }
          >
            <div className="col-auto text-center">
              <i
                style={
                  item.Color
                    ? { color: item.Color }
                    : item.Type === "folder"
                    ? { color: "#E6E8EA" }
                    : null
                }
                className={typeIconEquivalent(item) + " fa-2x"}
              />
            </div>
            <div className="col-10">
              <h5
                className="title clickable mt-1"
                onDoubleClick={() => enterFolder(item)}
                onClick={() => {
                  console.log("Selected item ", item.GUID);
                  state.SelectedFolder.set(item.GUID);
                  let newobj = JSON.parse(JSON.stringify(item));
                  state.DestinationFolder.set(newobj);
                }}
              >
                {item.Name}
              </h5>
            </div>

            <div className="col-12">
              <hr className=" my-1 py-1" />
            </div>
          </div>
        </div>
      );
      files.push(newFile);
    });

    return files;
  };
  console.log("Selected folder", JSON.stringify(state.SelectedFolder.value));
  console.log("Selected files", JSON.stringify(state.SelectedFolder.value));

  return (
    <div className="card pickFolders">
      <div className="card-body modalScrollable">
        <div className="row">
          <div className="col-10">
            {/*NAVIGATION */}
            {state.CurrentFolderPath.value.length > 0 ? (
              <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb m-0 p-0">
                  {state.CurrentFolderPath.map((path) => {
                    console.log(path);
                    return path.GUID.value === "/" ? (
                      <li
                        key={path.GUID.value}
                        className="breadcrumb-item"
                        onClick={() => enterFolder(path.value)}
                      >
                        <i className="fa fa-home-alt text-secondary"></i>
                      </li>
                    ) : (
                      <li
                        key={path.GUID.value}
                        className="breadcrumb-item text-secondary"
                        onClick={() => enterFolder(path.value)}
                      >
                        {path.Name.value}
                      </li>
                    );
                  })}
                </ol>
              </nav>
            ) : null}
            {/*END NAVIGATION */}
          </div>
          <div className="col-2">
            {state.UI.isLoading.value ? (
              <div
                className="spinner-border spinner-border-sm ml-2 "
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
          </div>
          {
            state.Files.length > 0 ? (
              requestFiles()
            ) : (
              <EmptyState
                image={IMAGES.EMPTY_ATTACHMENTS}
                cta="Add a new attachment"
                text={intl.formatMessage({ id: "noAttachmentsYet" })}
              />
            )
            //Supposed to display empty state
          }
        </div>
      </div>
      <div className="card-footer text-right">
        {state.SelectedFolder.value !== undefined &&
        state.SelectedFolder.value !== null ? (
          <button
            className="btn btn-sm btn-primary ml-auto"
            onClick={() => {
              moveHandler();
            }}
          >
            <FormattedMessage id="moveHere" defaultMessage="Move here" />
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default ListFolders;
