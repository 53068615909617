import React, { Component } from "react";
import CollabInvite from "./CollabInvite";
import { default as Navbar } from "../../../components/common/navbar";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState } from "draft-js";

class CollabInviteContainer extends Component {
  constructor(props) {
    super(props);
    let text =
      "Starlin Gonzalez has invited you to collaborate in the attachments in the event The coolest event ever on tomorrow of the organization veryNice Productions";
    let content = ContentState.createFromText(text);
    this.state = {
      to: "",
      subject: "Invitation to collaborate",
      body: EditorState.createWithContent(content)
    };
  }

  componentDidMount() {
    let text =
      "Starlin Gonzalez has invited you to collaborate in the attachments in the event The coolest event ever on tomorrow of the organization veryNice Productions";
    ContentState.createFromText(text);
  }

  onChange = body => {
    this.setState({ body });
  };

  render() {
    return <CollabInvite onChange={this.onChange} {...this.state} />;
  }
}

export default Navbar(CollabInviteContainer);
