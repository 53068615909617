import * as constant from "./constants";
import api from "../../../common/api";

export function venueDetails(venue) {
  return async (dispatch) => {
    dispatch({
      type: constant.GETTING_VENUE,
    });
    try {
      const request = await api.get(`/Venues/Details?GUID=${venue}`);
      if (request.data === null) {
        return dispatch({
          type: constant.GET_VENUE_SUCCESSFUL,
          data: null,
        });
      }
      dispatch({
        type: constant.GET_VENUE_SUCCESSFUL,
        data: request.data,
      });
    } catch (error) {
      dispatch({
        type: constant.GET_VENUE_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_VENUE_SUCCESSFUL: {
      return {
        ...state,
        eventVenue: action.data,
        event: Object.assign({}, state.event, {
          Venue: action.data,
        }),
      };
    }
    default:
      return state;
  }
}
