import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteVehicle(vehicle, success) {
  const url = `/Grounds/AddEditVehicleBatch`;
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_VEHICLE,
    });
    try {
      const request = await api.post(url, [
        { ...vehicle, Deleted: new Date() },
      ]);
      dispatch({
        type: constant.DELETED_VEHICLE,
        data: request.data.Response,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.DELETING_VEHICLE_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_VEHICLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_VEHICLE: {
      return {
        ...state,
        loading: false,
        grounds: state.grounds.map((g) => {
          if (g.GUID === action.data[0].GroundGUID) {
            return { ...g, GroundVehicles: action.data };
          }

          return g;
        }),
      };
    }
    case constant.DELETING_VEHICLE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
