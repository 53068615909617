import React, { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";

import {
  CheckboxBlankCircleOutline,
  CheckboxMarkedCircleOutline,
  ChevronDown,
  MenuDown,
} from "mdi-material-ui";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import DatePicker from "react-datepicker";
import uuid from "uuid";
import { addTaskSchema } from "../../utils/validationsSchemas";
import { CustomInput } from "reactstrap";
import moment from "moment";
import { useIntl, injectIntl, FormattedMessage } from 'react-intl'

const AddTask = (props) => {
  const intl = useIntl()
  let { currentList, addTask, loading, edit, task, editTask } = props;
  useEffect(() => {
    document.getElementsByClassName("Name")[0].focus();
  }, []);
  return (
    <div className="">
      <div className="event-page" style={{ overflow: "visible" }}>
        <div className="event-expennd-section">
          <div className="text-head mb-2">
            <div className="text-left">
              <h5 className="title"><FormattedMessage
                id="addTask"
                defaultMessage="Add Task" /></h5>
              <p>{currentList && currentList.Name}</p>
            </div>
          </div>
          <Formik
            onSubmit={(data) => console.log(data)}
            enableReinitialize
            validationSchema={addTaskSchema}
            initialValues={
              edit
                ? {

                  ...task,
                  DueDate: task?.Date ? true : false,
                  Date: task?.Date ? moment(task?.Date).toDate("MMMM dd, yyyy") : "",
                  Time: task?.Date ? moment(task?.Date).toDate("h:mm aa") : "",
                }
                : {
                  GUID: uuid.v4(),
                  Name: "",
                  TodoGUID: currentList.GUID,
                  ParentTaskGUID: "",
                  DueDate: false,
                  Date: new Date(),
                  Time: new Date(),
                  Priority: 0,
                  Note: "",
                  Status: 0,
                  Assignees: null,
                }
            }
          >
            {(props) => (

              <Form>
                <div className="event-inside">
                  <div className="event-content">
                    <FormField fullWidth={true} label={intl.formatMessage({ id: "firstName.signUp" })}>
                      <Field
                        className="form-control Name"
                        type="text"
                        name="Name"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Name"
                        component="div"
                      />
                    </FormField>
                    <div className="addTask__label">
                      <CustomInput
                        checked={props.values.DueDate}
                        onChange={() => {
                          props.setFieldValue("DueDate", !props.values.DueDate)
                        }}
                     //   defaultChecked={props.values.DueDate}
                        type="switch"
                        id={"switch-currentList-uuid"}
                        name={`switch-duedate`}
                        label={
                          props.values.DueDate
                            ? <FormattedMessage
                              id="hideDateTime"
                              defaultMessage="Hide Due Date & Time" />
                            : <FormattedMessage
                              id="showDateTime"
                              defaultMessage="Show Due Date & Time" />
                        }
                      />
                    </div>
                    {props.values.DueDate ? (
                      <div className="row">
                        <div className="col px-0 pr-2 pt-2">
                          <div className="gray-border">
                            <label htmlFor={"checkinDate"}><FormattedMessage
                              id="dueDate"
                              defaultMessage="Due Date" /></label>
                            <div>
                              <input type="date"
                                className="form-control"
                                id={"checkinDate"}
                                value={moment(props.values.Date).format("YYYY-MM-DD")}
                                onChange={(selected) => {
                                  props.setFieldValue("Date", moment(selected.target.value).format("YYYY-MM-DD"));
                                  props.handleSubmit();
                                }}
                              />
                              <div
                                className="drop-icon d-none"
                                onClick={() =>
                                  document.getElementById("checkinDate").focus()
                                }
                              >
                                <MenuDown />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col px-1 pl-2 pt-3">
                          <div className="gray-border">
                            <label htmlFor="checkinTime"><FormattedMessage
                              id="dueTime"
                              defaultMessage="Due Time" /></label>
                            <DatePicker
                              id={"checkinTime"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={props.values.Time}
                              onChange={(selected) =>
                                props.setFieldValue("Time", selected)
                              }
                            />
                            <ErrorMessage
                              className="error-message"
                              name="checkinTime"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <label><FormattedMessage
                        id="priority"
                        defaultMessage="Priority" /></label>
                      <div className="d-flex align-items-center my-2">
                        <div className="d-flex align-items-center">
                          {props.values.Priority === 1 ? (
                            <CheckboxMarkedCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 1)}
                            />
                          ) : (
                            <CheckboxBlankCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 1)}
                            />
                          )}
                          <small className="ml-2 text-dark"><FormattedMessage
                            id="low"
                            defaultMessage="Low" /></small>
                        </div>
                        <div className="d-flex align-items-center ml-3">
                          {props.values.Priority === 2 ? (
                            <CheckboxMarkedCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 2)}
                            />
                          ) : (
                            <CheckboxBlankCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 2)}
                            />
                          )}
                          <small className="ml-2 text-dark "><FormattedMessage
                            id="medium"
                            defaultMessage="Medium" /></small>
                        </div>
                        <div className="d-flex align-items-center ml-3">
                          {props.values.Priority === 3 ? (
                            <CheckboxMarkedCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 3)}
                            />
                          ) : (
                            <CheckboxBlankCircleOutline
                              htmlColor="black"
                              onClick={() => props.setFieldValue("Priority", 3)}
                            />
                          )}
                          <small className="ml-2 text-dark"><FormattedMessage
                            id="high"
                            defaultMessage="High" /></small>
                        </div>
                      </div>
                    </div>

                    <div className="addTask__editor">
                      <label><FormattedMessage
                        id="additionalNotes"
                        defaultMessage="Additional Notes" /></label>
                      <textarea rows={4} className="form-control" value={props.values.Note}
                        onChange={content => {
                          props.setFieldValue("Note", content)
                        }}>

                      </textarea>
                    
                    </div>
                    <div className="button-section">
                      {edit ? (
                        <button
                          disabled={!props.isValid || loading}
                          onClick={() => editTask(props.values)}
                          type="submit"
                          className="btn primary"
                        >
                          <FormattedMessage
                            id="done"
                            defaultMessage="Done" />
                        </button>
                      ) : (
                        <>
                          <button
                            disabled={!props.isValid || loading}
                            type="submit"
                            className="btn secondary mr-2"
                            onClick={() => {
                              addTask(props.values, false, props.resetForm);
                            }}
                          >
                            <FormattedMessage
                              id="doneContinue"
                              defaultMessage="Done & Continue" />
                          </button>
                          <button
                            disabled={!props.isValid || loading}
                            onClick={() => addTask(props.values, true)}
                            type="submit"
                            className="btn primary"
                          ><FormattedMessage
                              id="done"
                              defaultMessage="Done" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}

          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
