import * as constant from "./constants";
import api from "../../../common/api";
import moment from "moment";
import db from "../../../common/db";

export function editActivity(activity, success, toggle, until) {
  const url = "/Schedule/AddEdit";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_ACTIVITY,
      });
      let start = `${moment(activity?.StartDate).format("YYYY-MM-DD")}T${moment(
        activity?.StartTime
      ).format("HH:mm:ss")}`;
      let end = `${moment(
        activity.UntilDate ? activity?.UntilDate : activity?.StartDate
      ).format("YYYY-MM-DD")}T${moment(activity.UntilTime).format("HH:mm:ss")}`;
      const startDate = start;
      const dateUntil = end;
      const editedActivity = {
        ...activity,
        Reminder: activity?.Reminder
          ? activity?.Reminder?.value
            ? activity?.Reminder?.value
            : null
          : null,
        Date: startDate,
        DateUntil: until ? dateUntil : null,
      };
      const baseSchedule = await db
        .table("schedules")
        .where({ GUID: activity?.ScheduleMasterGUID })
        .first();

      console.log({ baseSchedule });
      const localBase = {
        ...baseSchedule,
        Schedules: baseSchedule?.Schedules?.map((s) => {
          if (s.GUID === activity?.GUID) {
            s = editedActivity;
          }
          return s;
        }),
      };
      await db.table("schedules").put(localBase);
      dispatch({
        type: constant.EDITED_ACTIVITY_SUCCESS,
        data: editedActivity,
      });
      success();
      toggle();
      await api.post(url, editedActivity);
    } catch (error) {
      console.log({ ErrorWhileSaving: error });
      dispatch({
        type: constant.EDITING_ACTIVITY_ERROR,
        error: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_ACTIVITY: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        schedulesMasters: state.schedulesMasters.map((s) => {
          if (s.GUID === action.data.ScheduleMasterGUID) {
            s?.Schedules?.map((s) => {
              if (s.GUID === action.data.GUID) {
                Object.assign(s, action.data);
              }
              return s;
            });
          }
          return s;
        }),
      };
    }
    case constant.EDITING_ACTIVITY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
