import React, { useState, useEffect } from "react";
import { DropDownComponent } from "../common";
import { randomImage } from "../common/randomImage";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
const ContactItem = (props) => {
  const intl = useIntl();
  let {
    contact,
    handleSidebarItem,
    sideBarItemData,
    deleteContact,
    toggleExport,
  } = props;

  const [img, setImg] = useState(null);
  const history = useHistory();

  let initials =
    (contact.Name && contact.Name.charAt(0).toUpperCase()) +
    (contact.LastName && contact.LastName.charAt(0));
  let subTitle = "";

  useEffect(() => {
    setImg(randomImage(initials));
  }, []);

  if (contact.Position) {
    subTitle = contact.Position;
  }
  if (
    contact.Email !== undefined &&
    contact.Email !== null &&
    contact.Email.length > 0
  ) {
    subTitle += (subTitle.length > 0 ? " | " : "") + "Email: " + contact.Email;
  }
  if (
    contact.MobilePhone !== undefined &&
    contact.MobilePhone !== null &&
    contact.MobilePhone.length > 0
  ) {
    subTitle +=
      (subTitle.length > 0 ? " | " : "") +
      intl.formatMessage({ id: "mobile" }) +
      ": " +
      contact.MobilePhone;
  }
  if (
    contact.OfficePhone !== undefined &&
    contact.OfficePhone !== null &&
    contact.OfficePhone.length > 0
  ) {
    subTitle +=
      (subTitle.length > 0 ? " | " : "") +
      intl.formatMessage({ id: "office" }) +
      ": " +
      contact.OfficePhone +
      (contact.OfficePhoneExtension
        ? " ext. " + contact.OfficePhoneExtension
        : "");
  }

  return (
    <div
      className={`contactItem__root clickableItem px-0 d-flex justify-content-between ${
        sideBarItemData && sideBarItemData.GUID === contact.GUID ? "active" : ""
      }`}
      onClick={() => handleSidebarItem("CONTACTS", contact)}
    >
      <div className="mx-0 px-0 d-flex align-items-center" style={{ width: "95%" }}>
        <div className="px-0">
          <button
            className="btn btn-sm text-secondary"
            onClick={() => props.toggleSelect(contact)}
          >
            {props.checkSelectionStatus(
              props.contacts,
              contact,
              props.selectedItems
            ) === 0 ? (
              <i className="far fa-square uncheck"></i>
            ) : props.checkSelectionStatus(
                props.contacts,
                contact,
                props.selectedItems
              ) === 1 ? (
              <i className="fas fa-check-square"></i>
            ) : (
              <i className="fas fa-minus-square"></i>
            )}
          </button>
        </div>
        <div className="mr-2">
          <img src={img} alt={initials} className=" avatar" height="35" />
        </div>
        <div className="text-truncate px-1">
          <h6>{`${contact.Name} ${contact.LastName}`}</h6>
          {subTitle ? (
            <p
              className="subTitle text-truncate"
              title={subTitle}
            >{`${subTitle}`}</p>
          ) : null}
        </div>
      </div>
      <div
        className="mx-0 px-0 pr-3 __se__float-leftml-auto"
        style={{ width: "5%" }}
      >
        <DropDownComponent
          className="float-right"
          data={[
            {
              name: (
                <FormattedMessage
                  id="copyToAnotherEvent"
                  defaultMessage="Copy to another event"
                />
              ),
              border: true,
              trigger: () => toggleExport(),
            },
            {
              delete: true,
              name: <FormattedMessage id="delete" defaultMessage="Delete" />,
              trigger: () => deleteContact(contact),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ContactItem;
