import React from "react";
import { IMAGES } from "../../../constant";

const Slide = ({ title, logo, text, cta, trigger, index }) => {
  return (
    <div className="onboarding__slide">
      {index !== 0 && (
        <div className="createOrganization__logo ">
          <img src={IMAGES.LOGO_ALT} />
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <h2>{title}</h2>
        </div>
        <div className="col-12">

          <img src={logo} className="mx-auto" style={{maxHeight:"269px"}} alt=""/>
        </div>
        <div className="col-12">
        <p>{text}</p>
      </div>
      <div className="col-12 my-4">
        <button
          onClick={() => trigger()}
          type="button"
          className="btn btn-lg btn-primary w-200"
        >
          {cta}
        </button>
      </div>
      </div>
     
    
    </div>
  );
};

export default Slide;
