import React from "react";

const EmptyState = (props) => {
  let { image, cta, text, actionBtn } = props;
  return (
    <>
    <div className="text-center emptyState__root relative pb-4">
      <img src={image} className="img-fluid-files mb-1" />
      <p className="mt-0">{text}</p>
      {actionBtn && 
      <div className="w-100 d-flex justify-content-center">
        <button className={actionBtn?.customClass ?? "btn btn-primary mt-2"} onClick={actionBtn.action}>{actionBtn.title}</button>
      </div>}
    </div>
    </>
  );
};

export default EmptyState;
