import React, { useState, useEffect, useRef, useMemo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import TodoItem from "../../../components/todo/TodoItem";
import { TaskTable } from "../../../components/todo/TaskTable";
import AddTask from "../../../components/todo/AddTask";
import AddList from "../../../components/todo/AddList";
import Assignees from "../../../components/todo/Assignees";
import AvatarsRow from "../../../components/common/avatarsRow";
import {
  ToastNotificationSuccess,
  ModalComponent,
  ToastNotificationError,
} from "../../../components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUserCheck,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { cleanDelete } from "../../../utils";
import { IMAGES } from "../../../constant";
import { Progress } from "reactstrap";
import Popup from "reactjs-popup";
import TemplatesModal from "../../../components/todo/TemplatesModal";
import Skeleton from "react-loading-skeleton";
import { getBasicInfo, getOrganization } from "../../../utils";
import api, { apiService } from "../../../common/api";
//import DragAndDropSort from "../../../components/common/DragAndDropSort";
//import { HTML5Backend } from "react-dnd-html5-backend";
//import { DndProvider } from "react-dnd";
//import { useDrag, useDrop } from 'react-dnd';
import { useIntl, injectIntl, FormattedMessage } from "react-intl";
import Table from "react-bootstrap/Table";
import { AddEditTaskTable } from "../../../components/todo/AddEditTable";
import uuid from "uuid";
import * as actions from "../redux/actions";
import * as commonActions from "../../event/redux/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import SharingHub from "../../../components/common/sharingHub";
import Swal from "sweetalert2";
import shareApi from "../../../common/shareApi";
import { event } from "jquery";
import { SandwichButton } from "../../../components/common/SandwichButton";
import TodosComments from "../../../components/common/TodosComments";
import { ConsoleNetworkOutline } from "mdi-material-ui";

// common/DragAndDropSort
const Todo = (props) => {
  const [templatesModal, setTemplatesModal] = useState(false);
  const [showTodoComments, setShowTodoComments] = useState(false);
  const dispatch = useDispatch();

  let {
    toggleAddList,
    addListModal,
    toggleAddTask,
    addTaskModal,
    events,
    todos,
    setCurrentList,
    currentList,
    addList,
    deleteList,
    teams,
    options,
    loading,
    addTask,
    toggleTask,
    deleteTask,
    toggleAssigneeModal,
    memberModals,
    editList,
    editListModal,
    setEditList,
    editListFunc,
    //setEditTask,
    toggleTaskEdit,
    taskEditModal,
    todoSelected,
    taskEdit,
    editTaskFunc,
    getListsOfTodo,
  } = props;
  const [taskItem, setTaskItem] = useState([]);
  const [linkList, setLinkList] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [fistRender, setFirstRender] = useState(true);
  const [isAddingNewList, setIsAddingNewList] = useState(false);
  function getPercentageDone(tasks) {
    const tasksTotal = cleanDelete(tasks);
    const tasksDone = tasksTotal?.filter((t) => t.Status).length;
    const total = (tasksDone / tasksTotal?.length) * 100;
    let percentages = {
      Complete: isNaN(total) ? 0 : Math.round(total),
      Incomplete: isNaN(total) ? 100 : 100 - Math.round(total),
    };

    return percentages;
  }
  const organization = getOrganization();

  const createTemplate = async (TodoTemplate) => {
    const request = await apiService.get(
      `/ToDo/CreateTemplate?OrganizationGUID=${TodoTemplate.OrganizationGUID}&TodoGUID=${TodoTemplate.GUID}`
    );
  };

  const createFromTemplate = (itinerary, toggle) => {
    const { event } = props;
    dispatch(
      commonActions.createFromTemplate(itinerary?.GUID, event.GUID, () => {
        ToastNotificationSuccess(1, "Itinerary created");
        toggle();
      })
    );
  };
  // const loadData = async () => {
  //   const request = await api.get(`/ToDo/ToDosTemplateForUser?AccountGUID=`, {
  //     params: {
  //       OrganizationGUID: getBasicInfo().GUID,
  //     },
  //   });
  //   console.log(request);
  // };
  // useEffect(() => {
  //   loadData();
  // }, []);
  useEffect(() => {
    setTaskItem(currentList?.TodoTasks);
  }, [currentList, todos]);

  //   const [{ isDragging }, drag] = useDrag({
  //     item: { name: 'Any custom name', type: 'Irrelevant' },
  //     collect: (monitor) => ({
  //         isDragging: monitor.isDragging(),
  //     }),
  //     end:(item,monitor)=>{
  //       const dropResult = monitor.getDropResult();
  //       console.log("dropResult",dropResult)
  //       if(dropResult){
  //         setTaskItem(item)
  //       }else{
  //         setTaskItem({})
  //       }
  //     },
  // });
  // const [{ canDrop,isOver }, drop] = useDrop({
  //       accept: 'Irrelevant',
  //       drop:() => ({name:'some name'}),
  //       collect:(monitor) => ({
  //         isOver: monitor.isOver(),
  //         canDrop: monitor.canDrop(),

  //       }),
  //   });

  const HandleAddTask = () => {
    setEditMode(true);
    const currentEditableItem = taskItem.filter((task) => task.isEditable);

    if (currentEditableItem.length > 0) {
      return ToastNotificationError(
        Math.random(),
        "Must finish editing current task before performing this action."
      );
    }
    // setTaskItem
    const newItem = {
      Created: null,
      Date: "",
      Deleted: null,
      GUID: uuid.v4(),
      TodoGUID: currentList.GUID || "",
      DueDate: currentList?.Event?.Date
        ? moment(currentList.Event.Date).format("YYYY-MM-DD")
        : null,
      Date: new Date(),
      Time: new Date(),
      Name: "",
      Note: "",
      ParentTaskGUID: "",
      Priority: 0,
      Status: 0,
      TodoGUID: "",
      Assignees: null,
      isEditable: true,
      isNew: true,
    };

    setTaskItem((prev) => {
      let newState = prev.length > 0 ? JSON.parse(JSON.stringify(prev)) : [];
      newState.length > 0
        ? newState?.unshift(newItem)
        : newState?.push(newItem);
      return newState;
    });
  };

  const handleSaveTask = async (task, isEditable, isNew) => {
    task = JSON.parse(
      JSON.stringify({
        ...task,
        DueDate: new Date(`${task.DueDate}T${task.time}`),
      })
    );

    console.log({ task });
    if (task.Name === "")
      return ToastNotificationError(
        Math.random(),
        "Please add at least the task name before saving."
      );
    setEditMode(false);

    let Assignees = "";
    if (!task?.Assignees) {
      Assignees = "";
    } else {
      switch (typeof task?.Assignees) {
        case "object":
          Assignees = JSON.stringify([
            {
              GUID: task?.Assignees?.GUID,
              Email: task?.Assignees?.Email,
              LastName: task?.Assignees?.LastName,
              Name: task?.Assignees?.Name,
            },
          ]);
          break;
        case "string":
          if (task?.Assignees?.length > 0) {
            Assignees = task?.Assignees;
          } else {
            Assignees = "";
          }
          break;
        default:
          break;
      }
    }

    // const Assigneess =
    //   typeof task?.Assignees === "object"
    //     ? JSON.stringify([
    //         {
    //           GUID: task?.Assignees?.GUID,
    //           Email: task?.Assignees?.Email,
    //           LastName: task?.Assignees?.LastName,
    //           Name: task?.Assignees?.Name,
    //         },
    //       ])
    //     : task?.Assignees;

    const taskToSave = {
      ...task,
      Assignees,
      isEditable: false,
      isNew: false,
      TodoGUID: currentList.GUID,
    };

    delete taskToSave.isEditable;
    delete taskToSave.isNew;

    if (isEditable && !isNew) {
      editTask(taskToSave);
    } else {
      addTask(taskToSave, false);
    }
  };

  function editTask(task) {
    const user = getBasicInfo();
    if (task?.DueDate) {
      const date = moment(task.DueDate).format("YYYY-MM-DD");
      const time = moment(task.DueDate).format("HH:mm");
      task.Date = moment(date + "T" + time).toDate();
    } else {
      task.Date = null;
    }
    dispatch(
      actions.editTask(task, () => {
        activateEditMode(task, false);
        ToastNotificationSuccess(Math.random(), "Task updated successfully");
        dispatch(actions.getListsOfTodo(currentList.GUID, user.GUID, false));
        // toggleTaskEdit(null);
      })
    );
  }

  function activateEditMode(task, active) {
    const taskIndex = [];
    taskItem.forEach((item, index) => {
      if (item.GUID === task.GUID) taskIndex.push(index);
    });
    const [todo] = taskIndex;
    setTaskItem((prev) => {
      const newState = prev;
      newState[todo] = { ...task, isEditable: active };
      const newObject = JSON.parse(JSON.stringify(newState));
      currentList.TodoTasks = newObject;
      return newObject;
    });
  }

  const handleEditTask = (task) => {
    setEditMode(true);
    const currentEditableItem = taskItem.filter((task) => task.isEditable);

    if (currentEditableItem.length > 0) {
      return ToastNotificationError(
        Math.random(),
        "Must finish editing current task before editing another."
      );
    }
    activateEditMode(task, true);
  };

  const handleCancelTask = (task) => {
    setEditMode(false);
    if (!task.isNew) {
      activateEditMode(task, false);
    } else {
      setTaskItem((prev) => prev.filter((item) => item?.GUID !== task?.GUID));
    }
  };

  const [showComplete, setShowComplete] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [todoComments, setTodoComments] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [linkIds, setLinkIds] = useState([]);

  const handleCompleteShow = () => {
    setShowComplete((prev) => !prev);
  };

  const handleShare = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let innerSelectedLink = null;
    let permission = 0;

    const linksArray = linkIds || [];

    // const response = await shareApi.get(
    //   `collab/links?SectionId=${currentList.GUID}`
    // );

    // const links = await response.data;

    // const parsedLinks = typeof links;
    // console.log({ parsedLinks, links, response });

    const shareLink = async () => {
      if (!navigator.canShare) return handleShareModal();

      const { response } = !innerSelectedLink
        ? (
            await shareApi.post("collab/GenerateLink", {
              GUID: uuid.v4(),
              SectionType: "todo",
              SectionGUID: currentList.GUID, //example id of the todo
              SharedByAccountGUID: currentUser, // id of the user who shared this
              OrganizationGUID: currentOrganization, //id of the organization
              ParentSectionGUID: null, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 1, // 0 = readonly ; 1= read and write
            })
          ).data
        : { response: { GUID: innerSelectedLink } };

      navigator.canShare();

      const shareData = {
        title: "Plnify",
        text: "Check your pending task.",
        url: `${window.location.protocol}//${window.location.host}/c/${response.GUID}`,
      };

      try {
        await navigator.share(shareData);
        // ToastNotificationSuccess(1,"Link shared successfully")
      } catch (err) {
        ToastNotificationSuccess(2, `Error: ${err}`);
      }
    };

    Swal.fire({
      title: "Select a Link to Share",
      input: "select",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      inputOptions: {
        NewLink: "Create New Link",
        ...linkIds,
      },
      inputPlaceholder: "Select a link",
      confirmButtonText: "Create",
      showCancelButton: true,
      showDenyButton: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve(
              "You need to select a link or select the create new one option."
            );
          }
        });
      },
    }).then((result) => {
      if (result.isDismissed) return;
      if (result.value !== "NewLink") {
        setSelectedLink(() => {
          innerSelectedLink = linksArray[result.value];
          return innerSelectedLink;
        });
      }

      shareLink();
    });
  };

  const handleShareModal = () => {
    setShowShare((prev) => !prev);
  };

  const copyToClipboard = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let permission = 0;

    const { response } = selectedLink
      ? { response: { GUID: selectedLink } }
      : (
          await shareApi.post("collab/GenerateLink", {
            GUID: uuid.v4(),
            SectionType: "todo",
            SectionGUID: currentList.GUID, //example id of the todo
            SharedByAccountGUID: currentUser, // id of the user who shared this
            OrganizationGUID: currentOrganization, //id of the organization
            ParentSectionGUID: null, //sent it null
            Deleted: null, //sent it null
            Updated: null, //sent it null
            Created: null, //sent it null
            PermissionType: permission, // 0 = readonly ; 1= read and write
          })
        ).data;
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/c/${response.GUID}`
    );
    setShowShare((prev) => !prev);
    ToastNotificationSuccess(1, `Link copy to clipboard`);
  };

  const handleLinkList = () => {
    setLinkList((prev) => !prev);
  };

  const completedTask = useMemo(
    () =>
      taskItem?.length > 0
        ? taskItem
            ?.filter((element) => element.Deleted === null)
            ?.filter((todo) => todo.Status === 1)
        : [],
    [taskItem]
  );
  const pendingTask = useMemo(
    () =>
      taskItem?.length > 0
        ? taskItem
            ?.filter((element) => element.Deleted === null)
            ?.filter((todo) => todo.Status === 0)
        : [],
    [taskItem]
  );

  const toggleTodoComments = () => {
    setShowTodoComments((prev) => !prev);
  };

  useEffect(() => {
    if (fistRender && todos.length > 0) {
      const firstItem = todos.filter(
        (todo) =>
          todo.Deleted === null && todo.OrganizationGUID === organization.GUID
      );
      setCurrentList(firstItem[0]);
      setFirstRender(false);
    }
  }, [todos]);

  //this refresh the todos for realtiem view with collabs
  useEffect(() => {
    const refreshTodos = async () => {
      //Avoid refresh if editMode.
      if (editMode || isAddingNewList) return;
      const user = getBasicInfo();
      const request = await apiService.get(
        `ToDo/ToDosForUser?AccountGUID=${user.GUID}`
      );
      const todos = await request.data.Response;

      const filteredTodos = todos.filter((todo) => todo.Deleted === null);
      const [currentTodo] =
        currentList &&
        filteredTodos.filter((todo) => todo?.GUID === currentList?.GUID);

      if (currentTodo) setCurrentList(currentTodo);
    };

    const interval = setInterval(refreshTodos, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentList, editMode, isAddingNewList, sectionId]);

  useEffect(() => {
    (async () => {
      const url = "collab/listcommentsbysectionid";
      const todoGUID = sectionId ?? props?.currentList?.GUID;

      if (todoGUID) {
        try {
          const response = await apiService.get(url + `?SectionId=${todoGUID}`);
          const comments = (await response.data.Response) || [];
          const linkIds = comments.map((comment) => {
            return comment.CollabGUID;
          });

          setTodoComments(comments);
          setLinkIds([...new Set(linkIds)]);
        } catch (error) {
          console.log(error);
          setTodoComments([]);
          setLinkIds(null);
        }
      }
    })();
  }, [currentList, sectionId]);

  let FilteredComments = sectionId
    ? todoComments.filter((comment) => comment.CollabGUID === sectionId)
    : todoComments;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <ModalComponent
        modal={templatesModal}
        toggle={() => setTemplatesModal(!templatesModal)}
        childeren={
          <TemplatesModal
            createFromTemplate={createFromTemplate}
            // templates={templates}
            toggle={() => setTemplatesModal(!templatesModal)}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleAddTask()}
        modal={false}
        childeren={
          <AddTask
            loading={loading}
            addTask={addTask}
            currentList={currentList}
            toggle={() => toggleAddTask()}
          />
        }
      />

      <ModalComponent
        toggle={() => toggleTaskEdit()}
        modal={taskEditModal}
        childeren={
          <AddTask
            task={todoSelected}
            edit={taskEdit}
            loading={loading}
            addTask={addTask}
            currentList={currentList}
            editTask={editTaskFunc}
            toggle={() => toggleTaskEdit()}
          />
        }
      />
      <ModalComponent
        toggle={() => {
          setIsAddingNewList((prev) => !prev);
          setEditList();
        }}
        modal={editListModal}
        childeren={
          <AddList
            currentList={currentList}
            edit={editList}
            loading={loading}
            options={options}
            events={events}
            addList={addList}
            teams={teams}
            editList={editListFunc}
            toggle={() => {
              setIsAddingNewList((prev) => !prev);
              setEditList();
            }}
            title={"Edit To-Do"}
          />
        }
      />

      <ModalComponent
        toggle={() => {
          setIsAddingNewList((prev) => !prev);
          handleLinkList();
        }}
        modal={linkList}
        childeren={
          <AddList
            currentList={currentList}
            edit={editList}
            loading={loading}
            options={options}
            events={events}
            addList={addList}
            teams={teams}
            editList={editListFunc}
            toggle={() => {
              setIsAddingNewList((prev) => !prev);
              handleLinkList();
            }}
            title={"Link To-Do"}
          />
        }
      />
      <ModalComponent
        toggle={() => {
          setIsAddingNewList((prev) => !prev);
          toggleAddList();
        }}
        modal={addListModal}
        childeren={
          <AddList
            loading={loading}
            options={options}
            events={events}
            addList={addList}
            teams={teams}
            toggle={() => {
              setIsAddingNewList((prev) => !prev);
              toggleAddList();
            }}
          />
        }
      />

      <ModalComponent
        modal={showShare}
        toggle={() => handleShareModal()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            // Item={""}
            toggle={() => handleShareModal()}
            // ParentSectionGUID={"guid"}
            // Type="ATTACHMENT"
            // sectionGUID={"attachment.GUID"}

            Type="SHARECOLLAB"
            shareApi={
              selectedLink
                ? {
                    link: selectedLink,
                    data: currentList?.TodoTasks || [],
                    GUID: uuid.v4(),
                    SectionType: "todo",
                    SectionGUID: selectedLink, //example id of the todo
                    SharedByAccountGUID: getBasicInfo()?.GUID, // id of the user who shared this
                    OrganizationGUID: getOrganization()?.GUID, //id of the organization
                    ParentSectionGUID: null, //sent it null
                    Deleted: null, //sent it null
                    Updated: null, //sent it null
                    Created: null, //sent it null
                    PermissionType: 1,
                    user: getBasicInfo(),
                  }
                : {
                    GUID: uuid.v4(),
                    SectionType: "todo",
                    SectionGUID: currentList?.GUID, //example id of the todo
                    SharedByAccountGUID: getBasicInfo()?.GUID, // id of the user who shared this
                    OrganizationGUID: getOrganization()?.GUID, //id of the organization
                    ParentSectionGUID: null, //sent it null
                    Deleted: null, //sent it null
                    Updated: null, //sent it null
                    Created: null, //sent it null
                    PermissionType: 1, // 0 = readonly ; 1= read and write
                    user: getBasicInfo(),
                    data: currentList?.TodoTasks || [],
                  }
            }
            copyToClipBoard={copyToClipboard}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleAssigneeModal()}
        modal={memberModals}
        childeren={
          <Assignees
            setCurrentList={setCurrentList}
            currentList={currentList}
            toggle={() => toggleAssigneeModal()}
          />
        }
      />
      <ModalComponent
        toggle={() => {
          toggleTodoComments();
          setSectionId(null);
        }}
        modal={showTodoComments}
        childeren={
          <TodosComments
            linkIds={linkIds}
            sectionId={sectionId}
            setSectionId={setSectionId}
            currentList={currentList}
            comments={FilteredComments}
            toggle={() => {
              toggleTodoComments();
              setSectionId(null);
            }}
          />
        }
      />
      <div className="main-container">
        <div className="main-with-header">
          <div className="page-header pt-4">
            <div className="d-flex align-items-center">
              <SandwichButton
                expanded={props.expanded}
                showExpanded={props.showExpanded}
              />
              <div className="main-text">
                <h1>
                  <FormattedMessage
                    id="toDoLists"
                    defaultMessage="To-Do Lists"
                  />
                </h1>
              </div>
            </div>
            <div className="content-right"></div>
          </div>
          <div className="main-first-container">
            <div className="row">
              <div className="col-md-3 px-2 br-6">
                <div className="list">
                  <div className="list-header">
                    <div className="main-text">
                      <h3 className="title">
                        <FormattedMessage id="to-Do" defaultMessage="To-Do" />{" "}
                        (s)
                      </h3>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <button
                        disabled={true}
                        className="btn secondary mr-2 text-capitalize d-none"
                      >
                        Templates
                      </button>
                      <button
                        disabled={loading}
                        onClick={() => toggleAddList()}
                        className="btn btn-primary btn-sm "
                      >
                        <FontAwesomeIcon icon={faPlus} color="white" />
                      </button> */}
                      <div className="">
                        <Popup
                          trigger={
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                            >
                              <i className="fa fa-plus" />
                            </button>
                          }
                          on="click"
                          position="bottom right"
                          arrow={false}
                        >
                          {(open) => (
                            <>
                              <button
                                onClick={() => {
                                  open();
                                  setIsAddingNewList(true);
                                  toggleAddList();
                                }}
                                className="btn btn-block flex-1"
                                type="button"
                              >
                                <FormattedMessage
                                  id="new"
                                  defaultMessage="New"
                                />
                              </button>
                              <button
                                onClick={() => {
                                  open();
                                  setTemplatesModal(!templatesModal);
                                }}
                                className="btn btn-block flex-1 mt-1"
                                type="button"
                              >
                                <FormattedMessage
                                  id="template"
                                  defaultMessage="Template"
                                />
                              </button>
                            </>
                          )}
                        </Popup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="list-content"
                    style={{ height: "calc(100vh - 180px)" }}
                  >
                    {todos === null ||
                    todos === undefined ||
                    todos.length === 0 ? (
                      <div className="row h-100 d-flex align-items-center justify-content-center">
                        <img
                          src={IMAGES.TODOLEFT_EMPTYSTATE}
                          className="emptyStateStyle mt-0"
                          alt=""
                        />
                      </div>
                    ) : (
                      <Scrollbars
                        style={{ width: "100%", height: "calc(97vh - 138px)" }}
                      >
                        {/* {loading ? (
                          <>
                            <div className="chat__item">
                              <Skeleton count={2} />
                            </div>
                            <div className="chat__item">
                              <Skeleton count={2} />
                            </div>
                            <div className="chat__item">
                              <Skeleton count={2} />
                            </div>
                          </>
                        ) : ( */}
                        {todos &&
                          organization &&
                          todos
                            .filter(
                              (x) => x.OrganizationGUID === organization.GUID
                            )
                            .map((todo) => (
                              <TodoItem
                                key={todo.GUID}
                                todo={todo}
                                setCurrentList={setCurrentList}
                                currentList={currentList}
                                deleteList={deleteList}
                                setEditList={setEditList}
                                setLinkList={handleLinkList}
                                setIsAddingNewList={setIsAddingNewList}
                                createTemplate={createTemplate}
                                handleShareModal={handleShareModal}
                                linkIds={linkIds}
                              />
                            ))}
                      </Scrollbars>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-9 px-2 br-6">
                <div className="list">
                  <div className="list-header">
                    <div className="main-text d-flex align-items-center">
                      <div>
                        <h5 className="title">
                          {currentList
                            ? currentList.Name + " Tasks"
                            : "Select a To-Do"}
                        </h5>

                        <small>
                          {currentList &&
                            currentList.Event &&
                            `Linked to: ${currentList.Event.Name}`}
                        </small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center w-50 justify-content-end">
                      {currentList && (
                        <div className="d-flex text-right w-50 justify-content-end">
                          {!isMobile && (
                            <div className="w-50 d-inline-block ">
                              <Progress
                                value={
                                  currentList?.TodoTasks &&
                                  getPercentageDone(currentList?.TodoTasks)
                                    .Complete
                                }
                              />
                            </div>
                          )}
                          <span className="d-inline-block ml-2 text-muted">
                            {currentList?.TodoTasks &&
                              getPercentageDone(currentList?.TodoTasks)
                                .Complete}{" "}
                            %
                          </span>
                        </div>
                      )}
                      {/* {currentList &&
                        currentList.TodoAssignees !== undefined &&
                        currentList.TodoAssignees.length > 0 && (
                          <div
                            style={{ position: "relative", bottom: 2 }}
                            onClick={() => toggleAssigneeModal()}
                          >
                            <AvatarsRow
                              staffs={currentList.TodoAssignees.filter(
                                (a) => a.Deleted === null
                              )}
                              position="right"
                            />
                          </div>
                        )} */}

                      {currentList && (
                        <div className="d-flex align-items-center">
                          <button
                            className="ml-2 btn btn-secondary btn-sm"
                            onClick={() => handleShare()}
                          >
                            <FontAwesomeIcon icon={faShareAlt} color="white" />
                          </button>

                          {/* <button
                            className="ml-2 btn btn-secondary btn-sm position-relative"
                            title="See Comments"
                            onClick={toggleTodoComments}
                            disabled={FilteredComments.length <= 0}
                          >
                            <span className="counter position-absolute top-0">
                              {FilteredComments.length}
                            </span>
                            <i className="fa fa-comment"></i>
                          </button> */}

                          {/* <button
                            className="ml-2 btn btn-secondary btn-sm"
                            onClick={() => toggleAssigneeModal()}
                          >
                            <FontAwesomeIcon icon={faUserCheck} color="white" />
                          </button> */}
                          <button
                            disabled={!currentList}
                            onClick={() => HandleAddTask()}
                            className="ml-2 btn btn-primary btn-sm "
                          >
                            {isMobile ? (
                              <FontAwesomeIcon icon={faPlus} color="white" />
                            ) : (
                              <FormattedMessage
                                id="addTask"
                                defaultMessage="Add Task"
                              />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="list-content"
                    style={{ height: "calc(100vh - 138px)" }}
                  >
                    {currentList === null ||
                    (currentList &&
                      currentList?.TodoTasks?.length === 0 &&
                      taskItem?.length === 0) ? (
                      <>
                        <div className="flex-1 h-50 d-flex align-items-center justify-content-center">
                          <img
                            src={IMAGES.TODO_EMPTYSTATE}
                            className="emptyStateStyleToDo"
                            alt=""
                          />
                        </div>
                        <br />
                        <div className="flex-1 h-50 d-flex align-items-center justify-content-center">
                          <h6 className="text-muted">
                            <FormattedMessage
                              id="noTo-DoAddedYet"
                              defaultMessage="No To-Do's added yet"
                            />
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Scrollbars
                        style={{ width: "100%", height: "calc(100vh - 138px)" }}
                      >
                        <div //ref={drag}
                        >
                          {!showComplete && pendingTask?.length < 1 ? (
                            <div className="alert alert-info text-center font-weight-bold">
                              No Pending Tasks
                            </div>
                          ) : (
                            pendingTask?.length > 0 && (
                              <Table striped="columns">
                                <thead>
                                  <tr className="list-subheader">
                                    <th>
                                      <FormattedMessage
                                        id="incomplete"
                                        defaultMessage="Complete"
                                      />
                                    </th>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th className="text-center">Assignees</th>
                                    {/* <th>Location</th> */}
                                    <th className="text-center"> Priority</th>
                                    <th className="text-center">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pendingTask?.map((todo) => {
                                    return (
                                      <TaskTable
                                        key={todo.GUID}
                                        todo={todo}
                                        toggleTask={toggleTask}
                                        deleteTask={deleteTask}
                                        toggleTaskEdit={toggleTaskEdit}
                                        editMode={editMode}
                                        handleCancelTask={handleCancelTask}
                                        handleSaveTask={handleSaveTask}
                                        handleEditTask={handleEditTask}
                                        handleShareModal={handleShareModal}
                                        isLoading={loading}
                                      />
                                    );
                                  })}
                                </tbody>
                              </Table>
                            )
                          )}
                        </div>

                        {completedTask?.length > 0 && showComplete && (
                          <div //ref={drop}
                          >
                            <Table striped="columns">
                              <thead>
                                <tr className="list-subheader">
                                  <th>
                                    <FormattedMessage
                                      id="completed"
                                      defaultMessage="Complete"
                                    />
                                  </th>
                                  <th>Title</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th className="text-center">Assignees</th>
                                  {/* <th>Location</th> */}
                                  <th className="text-center"> Priority</th>
                                  <th className="text-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {completedTask?.map((todo) => (
                                  <TaskTable
                                    key={todo.GUID}
                                    todo={todo}
                                    toggleTask={toggleTask}
                                    deleteTask={deleteTask}
                                    toggleTaskEdit={toggleTaskEdit}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        )}
                        {completedTask?.length > 0 && (
                          <div
                            className="d-flex justify-content-center"
                            style={{ width: "100%" }}
                          >
                            <button
                              className="btn btn-secondary m-3"
                              onClick={handleCompleteShow}
                            >
                              {showComplete ? "Hide Complete" : "Show Complete"}
                            </button>
                          </div>
                        )}
                      </Scrollbars>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Todo;
