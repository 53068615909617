import React, { Component } from "react";
import { IMAGES } from "../../../constant";
import { Scrollbars } from "react-custom-scrollbars";
import EventFormHorizontal from "../../../components/event/eventFormHorizontal";
import uuid from "uuid";
import moment from "moment";
import { ModalComponent, SimpleLoader } from "../../../components/common";
import BatchEventsModal from "../../../components/event/BatchEventsModal";
import { getOrganization } from "../../../utils";
import db from "../../../common/db";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import {injectIntl, FormattedMessage} from 'react-intl'
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export class AddMultipleEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchModal: false,
      caretOpen: false,
    };
  }

  componentDidMount() {
    this.mountingData();
  }

  componentWillUnmount() {
    this.unmountingData();
  }

  toggleCaret = () => {
    this.setState({
      caretOpen: !this.state.caretOpen,
    });
  };

  toggleBatchModal = () => {
    this.setState({
      batchModal: !this.state.batchModal,
    });
  };

  mountingData = async () => {
    const { addEventMultiple } = this.props;
    await db
      .table("batchEvent")
      .toArray()
      .then((res) => {
        if (res.length === 0 ) {
          this.toggleBatchModal(true);
        } else {
          res.forEach((e) => addEventMultiple(e));
        }
      });
  };

  unmountingData = () => {
    this.props.clearMultipleState();
  };

  testValidation = () => {
    //Event Name,Event Type, City, Country  border on card with errors
    //Put the plus and the clear all inside a three dot
    const { multipleEvents, updateMultipleEvent, addMultipleEvents } =
      this.props;
    const events = multipleEvents;

   
  
    events.map((item) => {
      item.LastSync = moment().format();
      if (!item.Name || !item.Country || !item.City || !item.EventType) {
        let errorMsg = "";
          if(!item.Name){
            errorMsg += this.props.intl.formatMessage({id:"nameRequired"});
          }
          if(!item.EventType){
            errorMsg +=errorMsg.length >0 ? ", "+this.props.intl.formatMessage({id:"Event Type is required"}): this.props.intl.formatMessage({id:"Event Type is required"});
          }

          if(!item.City){
            errorMsg +=errorMsg.length >0 ? ", "+this.props.intl.formatMessage({id:"City is required"}): this.props.intl.formatMessage({id:"City is required"});
          }
          if(!item.Country){
            errorMsg +=errorMsg.length >0 ? ", "+this.props.intl.formatMessage({id:"Country is required"}): this.props.intl.formatMessage({id:"Country is required"});
          }
        
        db.table("batchEvent")
          .update(item.GUID, { ...item, Error: true, ErrorMsg: errorMsg })
          .then(() => updateMultipleEvent({ ...item, Error: true,ErrorMsg: errorMsg }));
      } else {
        db.table("batchEvent")
          .update(item.GUID, { ...item, Error: false })
          .then(() => updateMultipleEvent({ ...item, Error: false }));
      }
    });

    setTimeout(addMultipleEvents, 600);
  };

  render() {
    const {
      toggle,
      addEventMultiple,
      multipleEvents,
      clearAll,
      updateEvent,
      addBunchEvents,
      removeEvent,
      loading,
      openBatchModal = true,
    } = this.props;
    let { batchModal, caretOpen } = this.state;
    batchModal = openBatchModal === false ? openBatchModal: batchModal;

    return (
      <div className="contact-transparent">
        <ModalComponent
          modal={batchModal}
          toggle={() => this.toggleBatchModal()}
          childeren={
            <BatchEventsModal
              toggle={() => this.toggleBatchModal()}
              addEventMultiple={addEventMultiple}
              clearAll={clearAll}
              addBunchEvents={addBunchEvents}
              multipleEvents={multipleEvents}
            />
          }
          footer={
            <div className="multipleEvents__footer d-flex align-items-center justify-content-end pr-2 py-2">
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <button
                  disabled={multipleEvents.length < 1}
                  onClick={() => this.testValidation()}
                  className="btn primary w-200"
                >
                  <FormattedMessage
                  id="Add To Calendar"
                  defaultMessage="Add To Calendar"/>
                </button>
              )}
            </div>
          }
        />
        <div className="event-page modalContainer">
          <div className="event-expennd-section multiple-contact">
            <div className="text-head">
              <div className="text-left">
                <h5 className="title"><FormattedMessage
                  id="Add Multiple Dates"
                  defaultMessage="Add Multiple Dates"/></h5>
              </div>
              <div className="">
                <button
                  onClick={() => clearAll()}
                  className="btn secondary w-160 capital mr-2"
                >
                  <FormattedMessage
                  id="Clear All"
                  defaultMessage="Clear All"/>
                </button>

                <ButtonDropdown isOpen={caretOpen} toggle={this.toggleCaret}>
                  <button
                    onClick={() =>
                      addEventMultiple({
                        Name:
                          multipleEvents.length > 0 &&
                          multipleEvents[multipleEvents.length - 1].Name &&
                          multipleEvents[multipleEvents.length - 1].Name
                            ? multipleEvents[multipleEvents.length - 1].Name
                            : "",
                        Date:
                          multipleEvents.length > 0 &&
                          multipleEvents[multipleEvents.length - 1].Date &&
                          multipleEvents[multipleEvents.length - 1].Date
                            ? moment(
                                multipleEvents[multipleEvents.length - 1].Date
                              )
                                .add(1, "days")
                                .toDate()
                            : moment().toDate(),
                        GUID: uuid.v4(),
                        EventType:
                          multipleEvents.length > 0 &&
                          multipleEvents[multipleEvents.length - 1].EventType &&
                          multipleEvents[multipleEvents.length - 1].EventType
                            ? multipleEvents[multipleEvents.length - 1]
                                .EventType
                            : "",
                        EventTypeName:
                          multipleEvents.length > 0 &&
                          multipleEvents[multipleEvents.length - 1]
                            .EventTypeName &&
                          multipleEvents[multipleEvents.length - 1]
                            .EventTypeName
                            ? multipleEvents[multipleEvents.length - 1]
                                .EventTypeName
                            : "",
                        City: "",
                        Country: "",
                        Latitude: "",
                        State: "",
                        Longitude: "",
                        VenueGUID: "",
                        Venue: { Name: "" },
                        VenueObj:{},
                        OrganizationGUID: getOrganization().GUID,
                        Status: "Confirmed",
                      })
                    }
                    className="btn btn-primary block capital btn-action"
                    style={{ fontSize: 15 }}
                  >
                    <i className="fa fa-plus" />
                  </button>
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: "#2c9a67",
                      borderColor: "#2c9a67",
                    }}
                    color="primary"
                  />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.toggleBatchModal()}>
                    <FormattedMessage
                  id="Add Multiple"
                  defaultMessage="Add Multiple"/>
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <div
                className="close-icon d-none"
                onClick={() => {
                  toggle();
                }}
              >
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div>
            </div>

            <Scrollbars style={{ height: "calc(100vh - 110px)" }}>
              {multipleEvents
                .sort(
                  (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()
                )
                .filter(x => x.OrganizationGUID === getOrganization().GUID)
                .map((_event) => (
                  <EventFormHorizontal
                    key={_event.GUID}
                    event={_event}
                    updateEvent={updateEvent}
                    removeEvent={removeEvent}
                  />
                ))}
            </Scrollbars>
            <div className="multipleEvents__footer d-flex align-items-center justify-content-end pr-2 py-2">
              {loading ? (
                <div className="d-flex align-items-center justify-content-center mx-auto text-center">
                  <SimpleLoader loading={loading} />
                </div>
              ) : (
                <button
                  disabled={multipleEvents.length < 1}
                  onClick={() => this.testValidation()}
                  className="btn primary w-200"
                >
                  <FormattedMessage
                  id="Add To Calendar"
                  defaultMessage="Add To Calendar"/>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(AddMultipleEvent);
