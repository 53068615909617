import React, { useEffect, useState } from "react";
import HotelItem from "./hotelItem";
import EmptyState from "../common/emptyState";
import { IMAGES } from "../../constant";
import { Scrollbars } from "react-custom-scrollbars";

import {
  checkSelectionStatus,
  exist,
  hookStateToObject,
} from "../../common/helpers";
import Swal from "sweetalert2";
import api from "../../common/api";
import ExportToEvent from "../common/ExportToEvent";
import { ModalComponent } from "..";
import db from "../../common/db";
import { useIntl, FormattedMessage } from "react-intl";

import EmptyStateImg from "../../images/hotel-empty.svg";
import MoveCopyToAnotherItem from "../common/MoveCopyToAnotherItem";

const Hotel = (props) => {
  let {
    toggleModal,
    toggleMoveModal,
    hotels,
    handleSidebarItem,
    deleteHotel,
    toggleLink,
    toggleExport,
    toggleNewMembersModal,
    sideBarItemData,
    editModal,
    toggleExportReservations,
    toggleLabelForm,
  } = props;

  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showMoveUser, setShowMoveUser] = useState(false);
  const [Hotels, setHotels] = useState(props.hotels);
  const [state, setState] = useState({
    SelectedItems: [],
    UI: {
      showExportToEvent: false,
      exportMode: "",
      showMoveUser: false,
    },
  });

  //
  //TODO: check why this method get's called so often
  function setHotel() {
    try {
      if (Hotels && Hotels.filter((x) => x.Deleted === null).length) {
        //    handleSidebarItem("HOTEL", Hotels.filter(x => x.Deleted === null)[0]);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  const intl = useIntl();
  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let query = {
          Items: "",
          Events: "",
        };

        state.SelectedItems.forEach((item) => {
          query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;
        });

        await api.post("/Hotels/DeleteBatch", query);
        //setTimeout(async () => {

        await HotelsRemote();
        //await reloadHotels();
        //  props.actions.getHotel(props.originEvent.GUID);
        setState({
          ...state,
          SelectedItems: [],
        });
      }
    });
  };

  const selectAll = async () => {
    try {
      let SelectedItems = state.SelectedItems; //JSON.parse(JSON.stringify(state.SelectedItems.value));
      await reloadHotels();
      let checkSelection = checkSelectionStatus(Hotels, null, SelectedItems);

      if (checkSelection === 0) {
        //We don't have anything,proceed to select all
        Hotels.forEach((item) => {
          if (!exist(SelectedItems, item)) {
            SelectedItems.push(item);
          }
        });

        setState({
          ...state,
          SelectedItems: SelectedItems,
        });
      } else if (checkSelection === 1) {
        //everyone is in here, deselect all from this team, for users it means mark as deleted, it doesn't matter how many records we re-create for this... is the safest way
        setState({
          ...state,
          SelectedItems: [],
        });
      } else {
        //is mixed selected the ones missed
        Hotels.forEach((item) => {
          if (!exist(SelectedItems, item)) {
            SelectedItems.push(item);
          } else {
            //Check if deleted
          }
        });
        setState({
          ...state,
          SelectedItems: SelectedItems,
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleSelect = (item) => {
    try {
      let SelectedItems = state.SelectedItems;

      if (exist(SelectedItems, item)) {
        if (
          SelectedItems.filter(
            (x) => x.GUID === item.GUID && x.Deleted === null
          )[0]
        ) {
          SelectedItems = SelectedItems.filter((x) => x.GUID !== item.GUID);
        }
      } else {
        SelectedItems.push(item);
      }
      setState({
        ...state,
        SelectedItems: SelectedItems,
      });
    } catch (ex) {
      console.error(ex);
    }
    console.log("new state", state);
  };

  const reloadHotels = async () => {
    let theEvent = props.originEvent;
    console.log("the event", props, theEvent);

    if (theEvent.GUID) {
      let items = await db
        .table("hotels")
        .where({ EventGUID: theEvent.GUID })
        .toArray();
      let filterDeleted = items.filter((x) => x.Deleted === null);

      console.log("hotels reload", filterDeleted);
      setHotels(filterDeleted);

      if (filterDeleted.length === 0) {
        handleSidebarItem("EVENT_DETAILS", {});
      }
      setHotel();
    }
  };

  const toggleExportToEvent = async (exportMode, reload) => {
    setState((prev) => {
      console.log({ state, prev });
      return {
        ...prev,
        UI: {
          ...prev.UI,
          showExportToEvent: !prev.UI.showExportToEvent,
          exportMode: exportMode,
        },
      };
    });
    if (reload) {
      await HotelsRemote();

      setState((prev) => {
        return {
          ...prev,
          SelectedItems: [],
          showExportToEvent: !prev.UI.showExportToEvent,
        };
      });
      //props.actions.getHotel(props.originEvent.GUID);
      //await reloadHotels();
      //PENDING === props.actions.getContacts(props.originEvent)
    }
  };

  const toggleExportUsers = (hotel) => {
    setSelectedHotel(hotel);
    setShowMoveUser(!showMoveUser);
  };

  const HotelsRemote = async () => {
    const request = await api.get("/Hotels/HotelForEvent", {
      params: { EventGUID: props.originEvent.GUID },
    });
    let cleanedHotel = request.data.response;

    await db.table("hotels").bulkPut(cleanedHotel);
    await reloadHotels();
  };

  useEffect(() => {
    console.log("reload hotels", props.originEvent, props.hotels);
    setTimeout(async () => {
      await reloadHotels();
    }, 500);
  }, [props.originEvent, props.hotels]);

  //Delete Selected Items on Delete Key Down
  useEffect(() => {
    const handleDeleteKey = ({ key }) => {
      if (key === "Delete") {
        deleteAllSelected();
      }
    };
    const rootElement = document.getElementById("root");
    rootElement.addEventListener("keydown", handleDeleteKey);

    return () => {
      rootElement.removeEventListener("keydown", handleDeleteKey);
    };
  }, [state.SelectedItems]);

  return (
    <>
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            //  loading={loading}
            exportMode={state.UI.exportMode}
            items={state.SelectedItems}
            toggle={toggleExportToEvent}
            section="hotel"
          />
        }
      />

      <ModalComponent
        toggle={toggleExportUsers}
        modal={showMoveUser}
        childeren={
          <MoveCopyToAnotherItem
            originEvent={props.originEvent}
            selectedItem={selectedHotel}
            toggle={toggleExportUsers}
            section="hotel"
          />
        }
      />

      <div className="resource shadow-sm">
        <div className="resource__header" onClick={() => setHotel()}>
          <div className="row">
            <div className="col-auto px-0 py-1">
              <button
                className="btn btn-sm text-secondary"
                onClick={() => selectAll()}
                type="button"
              >
                {checkSelectionStatus(Hotels, null, state.SelectedItems) ===
                0 ? (
                  <i className="far fa-square"></i>
                ) : checkSelectionStatus(Hotels, null, state.SelectedItems) ===
                  1 ? (
                  <i className="fas fa-check-square"></i>
                ) : (
                  <i className="fas fa-minus-square"></i>
                )}
              </button>
            </div>

            <div className="col-5 px-0 py-1">
              <div className="head-left pt-1">
                <h4>
                  <FormattedMessage id="hotels" defaultMessage="Hotels" />
                </h4>
              </div>
            </div>

            <div className="col-auto px-0 pr-3 text-right ml-auto py-1">
              <div className="resource_button-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => toggleModal()}
                  type="button"
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>

            {checkSelectionStatus(Hotels, null, state.SelectedItems) !== 0 ? (
              <div className="col-12 bg-subOption text-dark text-center py-2">
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("export")}
                >
                  <FormattedMessage
                    id="moveToAnotherEvent"
                    defaultMessage="Move to another event"
                  />
                </button>
                <button
                  className="btn btn-secondary mx-2 btn-sm"
                  onClick={() => toggleExportToEvent("copy")}
                >
                  <FormattedMessage
                    id="copyToAnotherEvent"
                    defaultMessage="Copy to another event"
                  />
                </button>
                <button
                  className="btn btn-danger mx-2 btn-sm"
                  onClick={() => deleteAllSelected()}
                >
                  <FormattedMessage
                    id="deleteSelected"
                    defaultMessage="Delete Selected"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="resource__body selectorContainer">
          {Hotels.length === 0 ? (
            <EmptyState
              image={EmptyStateImg}
              cta="Add a new hotel"
              text="No hotels added yet"
              actionBtn={{
                action: toggleModal,
                title: "Add Hotels",
              }}
            />
          ) : (
            <Scrollbars style={{ width: "100%", height: 250 }}>
              {Hotels
                //.filter(x => x.Deleted === null)
                .sort((a, b) => {
                  if (a?.Checkin > b?.Checkin) return 1;
                  if (a?.Checkin < b?.Checkin) return -1;
                })
                .map((hotelRecord, index) => {
                  let hotel = hotelRecord;
                  return (
                    <HotelItem
                      deleteHotel={deleteHotel}
                      handleSidebarItem={handleSidebarItem}
                      key={index}
                      hotel={hotel}
                      hotels={Hotels}
                      toggleMoveModal={toggleMoveModal}
                      toggleLink={toggleLink}
                      toggleExport={toggleExport}
                      sideBarItemData={sideBarItemData}
                      toggleNewMembersModal={toggleNewMembersModal}
                      editModal={editModal}
                      toggleExportReservations={toggleExportReservations}
                      toggleLabelForm={toggleLabelForm}
                      deleteAllSelected={deleteAllSelected}
                      toggleSelect={toggleSelect}
                      checkSelectionStatus={checkSelectionStatus}
                      selectedItems={state.SelectedItems}
                      toggleExportUsers={toggleExportUsers}
                    />
                  );
                })}
            </Scrollbars>
          )}
        </div>
      </div>
    </>
  );
};

export default Hotel;
