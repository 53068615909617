import React from "react";
import { Modal } from "reactstrap";

export const ModalComponent = (props) => {
  const { toggle, modal = false, childeren, clear, className, footer } = props;
  return (
    <Modal
      isOpen={modal}
      {...props}
      fade={false}
      className={className}
      modalClassName={clear ? "clear" : ""}
      backdropClassName={clear ? "clear" : ""}
      toggle={toggle}
    >
      {childeren}
      {/*<ModalBody>        
          {//childeren}
        </ModalBody>
        <ModalFooter>
          {//footer}
        </ModalFooter>*/}
    </Modal>
  );
};
