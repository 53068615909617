import React, { useState, useEffect } from "react";
import AvatarsRow from "../common/avatarsRow";
import {
  ModalComponent,
  DropDownComponent,
  ToastNotificationSuccess,
} from "../common";
import DeleteHotel from "./deleteHotel";
import { PencilOutline, Check, DotsVertical } from "mdi-material-ui";
import { IMAGES } from "../../constant";
import { useIntl, FormattedMessage } from "react-intl";

const onErrorImage = (sender) => {
  sender.target.src = IMAGES.HOTEL_PLACEHOLDER;
};
const HotelItem = (props) => {
  const {
    hotel,
    toggleMoveModal,
    handleSidebarItem,
    deleteHotel,
    toggleLink,
    toggleExport,
    sideBarItemData,
    toggleNewMembersModal,
    hotels,
    editModal,
    toggleExportReservations,
    toggleLabelForm,
    toggleExportUsers,
  } = props;

  const [dropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal((deleteModal) => !deleteModal);
  };

  return (
    <>
      <ModalComponent
        toggle={toggleDeleteModal}
        modal={deleteModal}
        childeren={<DeleteHotel hotel={hotel} toggle={toggleDeleteModal} />}
      />

      <div
        onClick={() =>
          props.selectOnly
            ? props.pickTargetItem(hotel)
            : handleSidebarItem("HOTEL", hotel)
        }
        className={`hotelItem__root clickableItem px-0 ${
          (sideBarItemData && sideBarItemData.GUID === hotel.GUID) ||
          (props.selectedItem && props.selectedItem.GUID === hotel.GUID)
            ? "active"
            : ""
        }`}
      >
        {hotel && (
          <div className="px-0 py-0 border-0 row">
            {!props.selectOnly ? (
              <div className="col-auto px-0">
                <button
                  className="btn btn-sm text-secondary"
                  onClick={() => props.toggleSelect(hotel)}
                >
                  {props.checkSelectionStatus(
                    props.hotels,
                    hotel,
                    props.selectedItems
                  ) === 0 ? (
                    <i className="far fa-square uncheck"></i>
                  ) : props.checkSelectionStatus(
                      props.hotels,
                      hotel,
                      props.selectedItems
                    ) === 1 ? (
                    <i className="fas fa-check-square"></i>
                  ) : (
                    <i className="fas fa-minus-square"></i>
                  )}
                </button>
              </div>
            ) : (
              <div className="col-auto px-2"></div>
            )}
            <div className="mx-0 px-0 col-8  hotelItem__details">
              <div className="hotelItem__image mr-2">
              {hotel.Image ? (
                  <img
                    className=""
                    onError={onErrorImage}
                    src={hotel.Image}
                    alt={`${hotel.Name}`}
                  />
                ) : (
                  <img
                    className=""
                    onError={onErrorImage}
                    src={ IMAGES.HOTEL_PLACEHOLDER
                    }
                    alt={`${hotel.Name}`}
                  />
                )}
              </div>
              <div className="hotelItem__details-inner text-truncate">
                {hotel.Label && (
                  <div
                    className="labelContainer c-pointer my-1"
                    onClick={() => toggleLabelForm()}
                  >
                    {hotel.Label}
                  </div>
                )}
                <h6>{hotel.Name}</h6>
                <p>{`${hotel.Address}, ${hotel.City} ${
                  ", " + hotel.ZipCode === null ? "" : hotel.ZipCode
                }`}</p>
              </div>
            </div>
            <div className="px-0 col-md-3 hotelItem__right ml-auto pr-3">
              <div className="hotelItem__staffs">
                {hotel &&
                  hotel.HotelReservations &&
                  hotel.HotelReservations.filter((r) => r.Deleted === null)
                    .length > 0 && (
                    <AvatarsRow
                      hotel={true}
                      staffs={hotel.HotelReservations.filter(
                        (r) => r.Deleted === null
                      )}
                    />
                  )}
              </div>
              {!props.selectOnly ? (
                <div className="hotelItem__actions">
                  <DropDownComponent
                    className="float-right"
                    data={[
                      {
                        name: (
                          <FormattedMessage
                            id="addLabel"
                            defaultMessage="Add Label"
                          />
                        ),
                        trigger: () => toggleLabelForm(),
                        border: true,
                      },
                      {
                        name: (
                          <FormattedMessage
                            id="addTeamMembers"
                            defaultMessage="Add Team Members"
                          />
                        ),
                        trigger: () => toggleNewMembersModal(),
                        border: true,
                      },
                      {
                        name: (
                          <FormattedMessage
                            id="emailRoomList"
                            defaultMessage="Email Room List"
                          />
                        ),
                        trigger: () => toggleMoveModal(),
                        border: true,
                      },

                      {
                        name: (
                          <FormattedMessage
                            id="moveAnotherHotel"
                            defaultMessage="Move users to another hotel"
                          />
                        ),
                        trigger: () => toggleExportUsers(hotel),
                        border: true,
                      },

                      {
                        name: (
                          <FormattedMessage
                            id="delete"
                            defaultMessage="Delete"
                          />
                        ),
                        delete: true,
                        trigger: () => {
                          props.toggleSelect(hotel);
                          props.deleteAllSelected();
                        },
                      },
                    ]}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HotelItem;
