const uuidv1 = require('uuid/v1');

/**
 * @description function to get mimeType from base64 image
 * @param {*} encoded
 */
export function base64MimeType(encoded) {
    var result = null;
    if (typeof encoded !== 'string') {
        return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
        result = mime[1];
    }
    return result;
}

/**
 * @description function to select image
 * @param {*} e
 */
export  function onSelectFile(e) {
    return new Promise((resolve, reject) => {
        let data = {
            imageUrl: '',
            loaded: false,
            errorMessage: '',
            mimeType: '',
        }
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
             reader.addEventListener("load", () => {
                const mimeType = base64MimeType(reader.result)
                if (mimeType === ("image/png" || "image/jpeg" || "image/jpg")) {
                    data =  {
                        imageUrl: reader.result,
                        loaded: true,
                        isUploaded: false,
                        errorMessage: '',
                        mimeType: 'base64',
                        guid: uuidv1(),
                    }
                    resolve(data)
                } else {
                    data =  {
                        imageUrl: '',
                        loaded: true,
                        errorMessage: "File type not supported",
                        mimeType: ''
                    }
                    reject(data)
                }
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    })
}