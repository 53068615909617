import * as constants from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function makeMeet() {
  return (dispatch) => {
    dispatch({ type: constants.ACTIVATE_MEET });
  };
}

export function acceptMeet() {
  return (dispatch) => {
    dispatch({ type: constants.ACCEPT_MEET });
  };
}

export function declineMeet() {
  return (dispatch) => {
    dispatch({ type: constants.DECLINE_MEET });
  };
}

export function closeMeet() {
  return (dispatch) => {
    dispatch({ type: constants.CLOSE_MEET });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.ACCEPT_MEET: {
      return {
        ...state,
        status: "ACCEPTED",
      };
    }
    case constants.DECLINE_MEET: {
      return {
        ...state,
        status: "DECLINED",
      };
    }
    case constants.ACTIVATE_MEET: {
      return {
        ...state,
        status: "ONGOING",
      };
    }
    case constants.CLOSE_MEET: {
      return {
        ...state,
        status: "CLOSED",
      };
    }
    default:
      return state;
  }
}
