import React from "react";
import { IMAGES, ROUTES } from "../../../constant";
import SelectItem from "../../../components/organization/SelectItem";
import { Scrollbars } from "react-custom-scrollbars";
import { ModalComponent, Loader } from "../../../components/common";
import { Link } from "react-router-dom";
import { LocationExit, ArrowLeft } from "mdi-material-ui";
import Skeleton from "react-loading-skeleton";
import { useIntl, FormattedMessage } from "react-intl";

const SelectOrganization = (props) => {
  let { logout, organizationsList, loading, selectOrganization, history } =
    props;
  return (
    <div className="createOrganization">
      <div className="selectOrganization__container mx-auto col-md-6 p-0 col-6 col-lg-4 ">
        {/* <div
          onClick={() => history.goBack()}
          className="selectOrganization__back d-flex align-items-center pb-3"
        >
          <ArrowLeft />
          <span><FormattedMessage
          id="back"
          defaultMessage="Back"/></span>
        </div> */}
        <div className="selectOrganization__container-inner d-flex flex-column justify-content-center align-items-center">
          <div className="selectOrganization__body bg-white rounded-lg shadow-lg p-2">
            <div className="text-center my-4">
              <div className="createOrganization__logo ">
                <img src={IMAGES.LOGO_COLOR} alt="Plnify" className="mx-auto" />
              </div>

              <h5 className="title text-dark">
                <FormattedMessage
                  id="selectWorkspace"
                  defaultMessage="Select a workspace"
                />
              </h5>
            </div>
            <Scrollbars
              style={{
                height: 380,
                width: "100%",
                margin: "0 auto",
                marginBottom: "0",
              }}
            >
              {loading ? (
                <div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-1">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-1">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-1">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-1">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex flex-column align-items-center border h-100 mx-auto my-0 overflow-auto"
                  style={{ width: "90%", borderRadius: "10px" }}
                >
                  {organizationsList
                    .sort((a, b) => a.Name.localeCompare(b.Name))
                    .map((organization) => (
                      <SelectItem
                        customClass={"border w-100 p-0 m-0"}
                        setOrganization={selectOrganization}
                        history={history}
                        key={organization.GUID}
                        organization={organization}
                      />
                    ))}
                </div>
              )}
            </Scrollbars>
            <div className="mt-0 mb-2 text-center py-3">
              <Link
                to={ROUTES.CREATE_ORGANIZATION}
                className="btn btn-primary px-4 w-200"
              >
                <FormattedMessage id="create" defaultMessage="Create" />
              </Link>
            </div>
          </div>
          <div
            onClick={() => {
              logout();
            }}
            style={{ cursor: "pointer ", color: "black" }}
            className="text-center mt-4 text-uppercase font-weight-bold"
          >
            <LocationExit htmlColor={"black"} />{" "}
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectOrganization;
