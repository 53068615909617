import React, { useState, useEffect } from 'react';
import moment from 'moment';
import GeneralUserItem from "../common/GeneralUserItem";
import { ROOM_TYPES } from '../../constant';
import {useIntl, FormattedMessage} from 'react-intl'
import uuid from "uuid";




const AssignMembersHotelUser = props => {
    const intl = useIntl()
    const parsedStaff = props.parsedStaff;
    // console.log("account item ", parsedStaff, "exist", props.Reservation)

    const [state, setState] = useState(props.Reservation ? props.Reservation :
        {
            "GUID": uuid.v4(),
            "AccountGUID": parsedStaff.AccountGUID,
            "Number": "",
            "Room": "",
            "HotelGUID": props.hotel && props.hotel.GUID,
            "Updated": null,
            "Deleted": null,
            "CheckInDate": props.hotel && props.hotel.Checkin,
            "CheckOutDate": props.hotel && props.hotel.Checkout,
            "RoomType": null,
        })
    const loadRoomTypes = () => {
        let rooms = ROOM_TYPES.map((roomType, index) => {
            return (<option value={roomType.value} key={index}>{roomType.label}</option>)
        });

        return rooms;
    }

    useEffect(() => {
        if (props.Reservation) {
            setState(props.Reservation);
        }


    }, [props.Reservation])

    if (!props.hotel) {
        return <></>
    }
    return (<>
        <div className="row">
            <div className="col-12 my-1 px-0">
                <div
                    className={" visible-info-main card shadow-sm "}
                >
                    <div
                        className="card-body p-1"
                    >
                        <div className="row">
                            <div className="col-auto px-1 py-1 pt-2"

                                onClick={() => {
                                    //debugger;
                                    props.toggleMember(parsedStaff)
                                }}>
                                {props.doesExist ? (
                                    <i className="fas fa-check-square"></i>
                                ) : (
                                    <i className="far fa-square uncheck"></i>
                                )}
                            </div>
                            <div className='col px-0'>
                                <GeneralUserItem account={parsedStaff.Account} showShortName />
                            </div>
                            <div className="col-auto ml-auto">
                                <input type="date" className='form-control py-0'
                                    //   min={moment().format("YYYY-MM-DD")}
                                    disabled={!props.doesExist}
                                    value={state.CheckInDate ?
                                        moment(state.CheckInDate).format("YYYY-MM-DD") :
                                        moment(props.hotel.Checkin).format("YYYY-MM-DD")}
                                    onChange={e => {
                                        try {
                                            if (!e.target.value) {
                                                return;
                                            }
                                            let theState = state;
                                            let newDate = e.target.value;
                                            theState.CheckInDate = newDate;
                                            setState(theState);
                                            props.updateSelected(theState);
                                           
                                        } catch (ex) {
                                            console.error(ex);
                                        }
                                    }}

                                />
                            </div>
                            <div className="col-auto">
                                <input type="date" className='form-control py-0'
                                    //   min={moment().format("YYYY-MM-DD")}
                                    disabled={!props.doesExist}
                                    value={state.CheckOutDate ? moment(state.CheckOutDate).format("YYYY-MM-DD") :
                                        moment(props.hotel.Checkout).format("YYYY-MM-DD")}
                                    //value={moment(state.CheckOutDate.value).format("YYYY-MM-DD")}
                                    onChange={e => {
                                        try {
                                            if (!e.target.value) {
                                                return;
                                            }
                                            let theState = state;
                                            let newDate = e.target.value;
                                            theState.CheckOutDate = newDate;
                                            setState(theState);
                                            props.updateSelected(theState);

                                        } catch (ex) {
                                            console.error(ex);
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-auto">

                                <input type="text" className='form-control' placeholder='Rsv.'
                                    disabled={!props.doesExist}
                                    value={state.Number}
                                    onChange={e => {
                                        let copy = state;
                                        copy.Number = e.target.value;
                                        setState(copy);

                                        props.updateSelected(state);

                                    }}
                                />
                            </div>
                            <div className="col-auto">
                                <select className="form-control"
                                    value={state.RoomType === null ? "" : state.RoomType}
                                    onChange={e => {
                                        let copy = state;
                                        copy.RoomType = e.target.value;
                                        setState(copy);

                                        props.updateSelected(state);

                                    }}
                                    disabled={!props.doesExist}
                                >
                                    <option value="">{intl.formatMessage({id:"roomType"})}</option>
                                    {loadRoomTypes()}
                                </select>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default AssignMembersHotelUser;