import React, { useEffect, useState } from "react";
import { AccordionComponent } from "../AccordionComponent";
import api, { apiService } from "../../../common/api";
import { ToggleButton } from "react-bootstrap";
import { ExportChildItem } from "./ExportChildItem";

const PdfSections = {
  Header: "Header",
  Itineraries: "ScheduleMasterCount",
  Flights: "FlightsCount",
  Hotels: "HotelsCount",
  Transportation: "GroundCount",
  Contacts: "ContactsCount",
  Attachments: "AttachmentsCount",
};

const ApiUrlBySection = {
  Header: "/Event/GetEventHeader",
  Itineraries: "/ScheduleMaster/ScheduleForEvent",
  Flights: "/Flights/FlightsForEvent",
  Hotels: "/Hotels/HotelForEvent",
  Transportation: "/Grounds/GroundForEvent",
  Contacts: "/ProductionVenueContact/GetProductionVenueContact",
  Attachments: "/Attachment/Attachments",
};

export const ExportItemItinerary = ({
  section,
  sectionItems,
  sectionsParams,
  sectionName,
  setSectionsParams,
  isParentChecked,
  sectionKey,
}) => {
  const [selected, setSelected] = useState(false);
  const [sectionShow, setSectionShow] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);

  useEffect(() => {
    if (selected) {
      setSelectedSchedules(section.Schedules.map((schedule) => schedule.GUID));
    }
  }, [selected]);

  useEffect(() => {
    const selectedItinerary = sectionsParams?.Itineraries?.find(
      (itinerary) => itinerary.GUID === section.GUID
    );
    setSelected(selectedItinerary ? true : false);
    if (selectedItinerary) {
      setSelectedSchedules(selectedItinerary.Activities);
    }
  }, [sectionsParams, sectionShow]);

  const handleSectionShow = () => {
    setSectionShow((prev) => !prev);
  };

  const handleSelect = (e) => {
    e && e?.stopPropagation();

    setSelected((prev) => !prev);

    setSectionsParams((prev) => {
      let newItineraries = prev.Itineraries ? prev.Itineraries : [];

      if (selected) {
        newItineraries = newItineraries.filter(
          (itinerary) => itinerary.GUID !== section.GUID
        );
      } else {
        newItineraries.push({
          GUID: section.GUID,
          Name: section.Name,
          Activities: section.Schedules.map((schedule) => schedule.GUID),
        });
      }

      if (newItineraries.length === 0) {
        setSectionsParams((prev) => {
          return {
            ...prev,
            [sectionKey]: sectionsParams[sectionKey] ? false : true,
          };
        });
      }

      return {
        ...prev,
        Itineraries: newItineraries,
      };
    });
  };

  const handleParams = (schedule) => {
    setSectionsParams((prev) => {
      return {
        ...prev,
        Itineraries: prev.Itineraries.map((itinerary) => {
          if (itinerary.GUID === section.GUID) {
            let currentSchedules = itinerary.Activities ?? [];

            if (currentSchedules.includes(schedule)) {
              currentSchedules = currentSchedules.filter(
                (currentSchedule) => currentSchedule !== schedule
              );
            } else {
              currentSchedules.push(schedule);
            }

            if (currentSchedules.length === 0) {
              handleSelect();
            }
            setSelectedSchedules(currentSchedules);
            return {
              ...itinerary,
              Activities: currentSchedules,
            };
          }
          return itinerary;
        }),
      };
    });
  };

  React.useEffect(() => {
    if (!selectedSchedules?.includes(section.GUID) && isParentChecked) {
      handleSelect();
    }
  }, [isParentChecked]);

  return (
    <div className="bg-light w-100">
      <div className="w-100">
        <div
          className={`w-100 d-flex align-items-center px-4 py-2 border ${
            !isParentChecked ? "" : "clickable"
          }`}
          onClick={!isParentChecked ? () => {} : handleSectionShow}
          style={
            !isParentChecked
              ? { opacity: 0.5 }
              : { position: "relative", zIndex: 1 }
          }
        >
          <input
            style={{ width: "15px", height: "15px" }}
            className="mr-2 pointer"
            type="checkbox"
            checked={selected}
            onClick={!isParentChecked ? () => {} : handleSelect}
            onChange={() => {}}
          />
          <p className="ml-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            {section.Name}
          </p>

          <i
            className={`fa fa-angle-up collap-arrow ${
              !sectionShow ? "active" : "inactive"
            } ml-auto fa-lg`}
          ></i>
        </div>
        <div
          className={`export-child-item ${sectionShow ? "active" : "inactive"}`}
          style={{
            backgroundColor: "rgb(230 250 255)",
          }}
        >
          {sectionItems.length > 0
            ? sectionItems.map((item, index) => {
                return (
                  <ExportChildItem
                    key={index + item.Name}
                    item={item}
                    selectedItems={selectedSchedules}
                    handleParams={handleParams}
                    isParentChecked={selected}
                    setParentChecked={setSelected}
                    section={sectionName}
                    setSectionsParams={setSectionsParams}
                  />
                );
              })
            : sectionShow && (
                <p className="alert alert-info text-center m-0">
                  No Itineraries
                </p>
              )}
        </div>
      </div>
    </div>
  );
};
