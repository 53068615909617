import React from "react";
import { RadioboxBlank, RadioboxMarked } from "mdi-material-ui";
import { Event } from "./event";
import { checkIfEventExists, checkIfEventExistsGroup } from "../../utils";

const SelectableEvent = (props) => {
 // debugger;
  let { event, toggleEvent, eventsSelected, guidVersion , active } = props;

  
  return (
    <div className={"p-0 event__selectable relative d-flex align-items-center "+active}>
      <div className="left-icon text-center  cursor ml-2" onClick={() => toggleEvent(event)}>
        {guidVersion ? (
          checkIfEventExistsGroup(event.GUID, eventsSelected) ? (
            <i className="fas fa-check-square text-dark"></i>
          ) : (
            <i className="far fa-square uncheck text-dark"></i>
          )
        ) : checkIfEventExists(event.GUID, eventsSelected) ? (
          <i className="fas fa-check-square text-dark"></i>
        ) : (
          
          <i className="far fa-square uncheck text-dark"></i>
        )}
      </div>
      <div className="d-flex align-items-center flex-1 text-truncate">
        <Event key={event.GUID} event={event} noClickable  />
      </div>
    </div>
  );
};

export default SelectableEvent;
