import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
import React, { useEffect, useState } from "react";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { IMAGES } from "../../../constant";
import { Settings } from "./DocsComponent";
PdfViewerComponent.Inject(Toolbar);

export const PDFComponent = (props) => {
  const { id } = useParams();
  const apiBasePath = "https://service.plnify.com/api/";
  const [fileSelected, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(null);
  const { pdfFileId } = useParams();
  // useEffect(() => {
  //   //Now let's try to get the file and open
  //   let fullPath = "https://app.plnify.com/files/JavaScript%20Concepts.pdf"; ////`${Settings.BasePath}files/${props.file.path}`;
  //   setfileName("JavaScript Concepts");
  //   setFileSelected(fullPath);
  // }, []);

  const loadUI = () => {
    const filePath = pdfFileId ?? props?.file?.Path;
    return (
      <PdfViewerComponent
        // id="container"
        documentPath={filePath}
        serviceUrl={`${Settings.serviceApiUrl}file/pdf`}
        //serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
        resourceUrl="/ej2-pdfviewer-lib"
        style={{ height: "100%", width: "100%", overflow: "unset" }}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            Annotation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            FormFields,
            FormDesigner,
          ]}
        />
      </PdfViewerComponent>
    );
  };

  if (props.modal) {
    return (
      <Modal
        show={props.modal}
        onHide={props.toggle}
        className="overflow-hidden"
        size="xl"
        style={{ margin: 0, padding: 0 }}
      >
        <div
          className="e-documents-container bg-light d-flex flex-column align-items-center"
          style={{ width: "60vw", position: "relative", right: "-70px" }}
        >
          {!props.hideHeader && (
            <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
              <img
                src={IMAGES.LOGO_BLACK}
                alt="Plnify"
                height={60}
                width={130}
              />
              <p
                style={{ fontSize: "40px", fontWeight: "bold", color: "black" }}
              >
                PDF
              </p>
            </div>
          )}
          {loadUI()}
        </div>
      </Modal>
    );
  }
  return (
    <div className="e-documents-container w-100">
      {!props.hideHeader && (
        <div className="w-100 d-flex align-items-center justify-content-center px-5 px-2">
          <img src={IMAGES.LOGO_BLACK} alt="Plnify" height={60} width={130} />
          <p style={{ fontSize: "40px", fontWeight: "bold" }}>PDF</p>
        </div>
      )}
      <>{loadUI()}</>
    </div>
  );
};
