import React, { useEffect, useState } from "react";
import {
  MapComponent,
  ModalComponent,
  DropDownComponent,
  ToastNotificationSuccess,
} from "../../common";
import { IMAGES } from "../../../constant";
import Weather from "../weatherDetail/Weather";
import EditVenueModal from "../EditVenueModal";
import { useIntl, FormattedMessage } from "react-intl";
import MarkerModal from "../MarkerModal";
import LazyLoadImage from "../../common/LazyLoadImage";
import EditGroup from "../groups/EditGroup";
import * as actions from "../../../page/calendar/redux/editGroup";
import { resizeGroupImage } from "../../../utils/resizeGroupImage";
import { useDispatch } from "react-redux";
import { getGroups } from "../../../page/calendar/redux/getGroups";
import { getEventGroups } from "../../../utils/getEventGroups";
import EventGroupModal from "../EventGroupModal";
import { NotesContainer } from "../../notes/NotesContainer";

const onErrorImage = (sender) => {
  sender.target.src = IMAGES.VENUE_IMAGE_PLACEHOLDER;
};
const VenueDetail = (props) => {
  const keyGoogle = "AIzaSyAl35-aKfZA9eK9PtXT74aR_GHSDSZXqcc";
  const [imageSrc, setImageSrc] = useState(null);
  const [state, setState] = useState({ markerModal: false });
  const [modalOpen, setModalOpen] = useState(false);
  const [editVenueModal, setEditVenueModal] = useState(false);
  const { event, weather, venue, toggleVenueModal, deleteVenue } = props;
  const [showImageEdit, setShowImageEdit] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);

  const selectedEvents = props.eventsList.filter((event) => {
    return event.EventGroupGUID === props?.event?.EventGroupGUID;
  });
  const [groupState, setGroupState] = useState({
    editModal: true,
    editEventsSelected: selectedEvents || [],
    groupNameEdit: props?.event?.EventGroup?.Name || "",
    groupEditImageTarget: null,
    groupColorEdit: props?.event?.EventGroup?.Color || "",
    groupImageEdit: "",
    groupGUID: props?.event?.EventGroupGUID || "",
    groupOrg: props?.event?.OrganizationGUID || "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setGroupState({
      editModal: true,
      editEventsSelected: selectedEvents || [],
      groupNameEdit: props?.event?.EventGroup?.Name || "",
      groupEditImageTarget: null,
      groupColorEdit: props?.event?.EventGroup?.Color || "",
      groupImageEdit: "",
      groupGUID: props?.event?.EventGroupGUID || "",
      groupOrg: props?.event?.OrganizationGUID || "",
    });
  }, [props?.event]);

  const handleShowEditGroup = () => {
    setShowEditGroup((prev) => !prev);
  };

  const toggleEventEdit = (event) => {
    let events = groupState.editEventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let editedEvent;
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      let findedEvents = events.find((s) => s.GUID === event.GUID);
      if (findedEvents.Deleted === true) {
        editedEvent = { GUID: event.GUID, Deleted: null };
      } else if (findedEvents.Deleted === null) {
        editedEvent = { GUID: event.GUID, Deleted: true };
      }
      return setGroupState((prev) => ({
        ...prev,
        editEventsSelected: [...filtered, editedEvent],
      }));
    } else {
      let newOnes = events.concat({ GUID: event.GUID, Deleted: null });
      return setGroupState((prev) => ({
        ...prev,
        editEventsSelected: newOnes,
      }));
    }
  };

  if (venue !== null && venue.Image === null) {
    venue.Image = IMAGES.VENUE_IMAGE_PLACEHOLDER;
  }
  function toggleModal() {
    setModalOpen((modalOpen) => !modalOpen);
  }
  function toggleImage(e) {
    setImageSrc(e.target.src);
  }

  const toggleMarkerModal = () => {
    setState((prev) => ({ ...prev, markerModal: !state.markerModal }));
  };

  const editColorGroup = (value, from) => {
    setGroupState((prev) => ({ ...prev, groupColorEdit: value }));
  };

  const setGroupNameEdit = (value) => {
    setGroupState((prev) => ({ ...prev, groupNameEdit: value }));
  };

  const setGroupImageEdit = (event, type) => {
    const fileUploaded = type === "drop" ? event : event?.target?.files[0];

    console.log({ fileUploaded });
    setGroupState((prev) => {
      return { ...prev, groupEditImageTarget: fileUploaded };
    });
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setGroupState((prev) => {
          return { ...prev, groupImageEdit: reader.result };
        })
      );
      reader.readAsDataURL(fileUploaded);
    }
  };

  const handleSelectEvent = (event) => {
    let events = this.state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return this.setState({
        eventsSelected: filtered,
      });
    } else {
      let newOnes = events.concat(event);
      return this.setState({
        eventsSelected: newOnes,
      });
    }
  };

  const editGroup = () => {
    const {
      groupNameEdit,
      groupColorEdit,
      groupImageEdit,
      groupGUID,
      editEventsSelected,
      groupOrg,
      groupEditImageTarget,
    } = groupState;

    const groupObject = {
      groupNameEdit,
      groupColorEdit,
      groupImageEdit,
      groupGUID,
      editEventsSelected,
      groupOrg,
    };

    console.log({ groupState });

    if (groupImageEdit && groupEditImageTarget) {
      resizeGroupImage(
        groupEditImageTarget,
        groupObject,
        dispatch,
        handleShowEditGroup
      );
    } else {
      dispatch(
        actions.editGroup(
          {
            Name: groupNameEdit,
            GUID: groupGUID,
            Color: groupColorEdit,
            OrganizationGUID: groupOrg,
          },
          editEventsSelected,
          "",
          handleShowEditGroup,
          () => {
            ToastNotificationSuccess(
              Math.random(),
              "Group updated successfuly."
            );
            dispatch(getGroups(groupOrg));
          }
        )
      );
    }
  };

  const eventGroups = props.eventsList ? getEventGroups(props.eventsList) : [];

  let fullAddress = "";
  if (venue === null || venue.GUID === null) {
    fullAddress += event.City + "," + event.State + "," + event.Country;
  }
  if (venue !== null && venue.Address) {
    fullAddress += venue.Address;
  }
  if (venue !== null && venue.City) {
    fullAddress += fullAddress.length > 0 ? ", " + venue.City : venue.City;
  }
  if (venue !== null && venue.ZipCode) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + venue.ZipCode : venue.ZipCode;
  }
  if (venue !== null && venue.State) {
    fullAddress += fullAddress.length > 0 ? ", " + venue.State : venue.State;
  }
  if (venue !== null && venue.Country) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + venue.Country : venue.Country;
  }
  fullAddress = fullAddress.replace(" ,", ",");
  console.log("venue3", venue);

  const imgUrl = "https://service.plnify.com/files/";
  const groupImgUrl = props?.event?.EventGroup?.Image
    ? `${imgUrl}${props?.event?.EventGroup?.Image}?n=${Math.random()}`
    : "";

  if (!event.VenueGUID || (event.Venue !== null && event.Venue.City === null)) {
    let latLngPair = event.Latitude
      ? event.Latitude + "," + event.Longitude
      : event.City +
        (event.State ? "," + event.State : "") +
        ", " +
        event.Country;

    let mapimg =
      "https://maps.googleapis.com/maps/api/staticmap?center=" +
      latLngPair +
      "&zoom=16&size=600x300&maptype=roadmap&markers=color:red%7Clabel:Venue%7C" +
      latLngPair +
      "&key=" +
      keyGoogle;

    return (
      <div className="">
        <Weather weather={weather} />

        <div className="venueDetail__details shadow-sm">
          <div className="venueDetail__detail-inner d-flex align-items-start">
            <div className="mr-4">
              <img
                alt=""
                className="img-thumbnail"
                style={{ cursor: "pointer" }}
                onError={(e) => {
                  e.target.src = IMAGES.VENUE_IMAGE_PLACEHOLDER;
                }}
                onClick={(e) => {
                  toggleImage(e);
                  toggleModal();
                }}
                src={IMAGES.VENUE_IMAGE_PLACEHOLDER}
                width={175}
              />
            </div>
            <button
              type="button"
              className="btn btn-sm btn-primary btn-link"
              onClick={() => toggleVenueModal()}
            >
              <FormattedMessage id="addVenue" defaultMessage="Add a Venue" />
            </button>
          </div>
        </div>
        {/* <button
          className="btn btn-block p-0 mt-3"
          onClick={() => {
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${fullAddress}`,
              "MapWindow",
              "width=1280,height=840"
            );
          }}
        >
          <img
            className="img-fluid"
            alt=""
            src={mapimg}
            style={{ width: "100%", minHeight: "150px" }}
          />
        </button> */}

        <NotesContainer event={event} hasGroupImage={groupImgUrl} />
      </div>
    );
  }

  let latLngPair =
    venue && venue.Latitude
      ? venue.Latitude + "," + venue.Longitude
      : venue
      ? venue.City +
        (venue.State ? "," + venue.State : "") +
        ", " +
        venue.Country
      : event.City +
        (event.State ? "," + event.State : "") +
        ", " +
        event.Country;
  let mapimg =
    "https://maps.googleapis.com/maps/api/staticmap?center=" +
    latLngPair +
    "&zoom=16&size=600x300&maptype=roadmap&markers=color:red%7Clabel:Venue%7C" +
    latLngPair +
    "&key=" +
    keyGoogle;

  return (
    <div
      className="venueDetail"
      style={{
        overflowY: "auto",
      }}
    >
      <ModalComponent
        toggle={() => setModalOpen((modalOpen) => !modalOpen)}
        modal={modalOpen}
        childeren={
          <div className="image-main">
            <div className="d-flex align-items-center mb-2">
              <div className="close-icon d-none" onClick={() => toggleModal()}>
                <div className="close-bg">
                  <img
                    src={IMAGES.CROSS_ICON}
                    width="16"
                    height="16"
                    alt="close"
                  />
                </div>
              </div>
            </div>

            <img
              src={imageSrc}
              onError={onErrorImage}
              alt="Venue"
              className="img-fluid"
            />
          </div>
        }
      />
      <ModalComponent
        modal={editVenueModal}
        toggle={() => setEditVenueModal((editVenueModal) => !editVenueModal)}
        childeren={
          <EditVenueModal
            toggle={() =>
              setEditVenueModal((editVenueModal) => !editVenueModal)
            }
          />
        }
      />

      {/* <ModalComponent
        modal={showEditGroup}
        toggle={handleShowEditGroup}
        childeren={
          <EventGroupModal
            groupName={groupState.groupName}
            groupColor={groupState.groupColor}
            groupImage={groupState.groupImage}
            setColorGroup={editColorGroup}
            setGroupName={setGroupNameEdit}
            setGroupImage={setGroupImageEdit}
            eventsSelected={groupState.eventsSelected}
            toggleEvent={handleSelectEvent}
            eventsList={props.eventList}
            // createGroup={this.createGroup}
            // creatingGroup={creatingGroup}
            eventGroups={eventGroups}
            // deletingGroup={deletingGroup}
            // deleteGroup={this.deleteGroup}
            toggle={handleShowEditGroup}
            editGroup={true}
          />
        }
      /> */}

      <ModalComponent
        modal={state.markerModal}
        toggle={() => toggleMarkerModal()}
        childeren={
          <MarkerModal
            toggle={() => toggleMarkerModal()}
            hotelPlaces={props.hotelPlaces}
            venuePlace={props.venuePlace}
            event={event}
          />
        }
      />

      <ModalComponent
        modal={showEditGroup}
        toggle={handleShowEditGroup}
        childeren={
          <EditGroup
            eventsList={props.eventsList}
            editModal={groupState.editModal}
            groupNameEdit={groupState.groupNameEdit}
            groupColorEdit={groupState.groupColorEdit}
            groupImageEdit={groupState.groupImageEdit || groupImgUrl}
            editEventsSelected={groupState.editEventsSelected}
            setGroupName={setGroupNameEdit}
            setGroupImage={setGroupImageEdit}
            setColorGroup={editColorGroup}
            toggleEvent={toggleEventEdit}
            editGroup={editGroup}
            toggle={handleShowEditGroup}
          />
        }
      />

      {groupImgUrl && (
        <div
          className="d-flex justify-content-center"
          style={{ height: "300px", width: "100%", marginBottom: "4px" }}
          onMouseEnter={() => setShowImageEdit(true)}
          onMouseLeave={() => setShowImageEdit(false)}
        >
          {showImageEdit && (
            <button
              onClick={handleShowEditGroup}
              className="btn btn-secondary position-absolute"
              style={{ right: "5px", top: "5px" }}
            >
              <i className="fas fa-pen"></i>
            </button>
          )}
          {/* <img
            width="100%"
            src={groupImgUrl}
            alt="group image"
            style={{ objectFit: "cover", objectPosition: "top" }}
          /> */}
          <LazyLoadImage
            src={groupImgUrl}
            style={{ objectFit: "cover", objectPosition: "top", width: "100%" }}
          />
        </div>
      )}
      <Weather weather={weather} />

      {venue && venue.City ? (
        <>
          <div
            className="shadow-sm relative overflow-hidden"
            // style={{ height: "calc(100vh - 600px)" }}
          >
            <div className="venueDetail__detail-inner d-flex">
              <div className="mr-4">
                {event.Image ? (
                  <div>
                    <img
                      onError={onErrorImage}
                      className="img-thumbnail"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        toggleImage(e);
                        toggleModal();
                      }}
                      src={venue.Image}
                      // onError={onErrorImage}
                      width={250}
                      alt=""
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className="img-thumbnail"
                      style={{ cursor: "pointer" }}
                      src={IMAGES.VENUE_IMAGE_PLACEHOLDER}
                      width={250}
                    />
                  </div>
                )}
              </div>

              <div>
                <div className="d-flex align-items-center">
                  <h6 className="mr-auto">{venue.Name}</h6>
                  <div
                    style={{
                      position: "absolute",
                      top: 1,
                      right: 1,
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <DropDownComponent
                      data={[
                        {
                          name: (
                            <FormattedMessage id="Edit" defaultMessage="Edit" />
                          ),
                          trigger: () =>
                            setEditVenueModal(
                              (editVenueModal) => !editVenueModal
                            ),
                        },
                        {
                          name: (
                            <FormattedMessage
                              id="openGoogle"
                              defaultMessage="Open in Google"
                            />
                          ),
                          trigger: (e) => {
                            e.preventDefault();
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${
                                venue.Name + " " + fullAddress
                              }`,
                              "MapWindow",
                              "width=1280,height=840"
                            );
                          },
                        },
                        {
                          name: (
                            <FormattedMessage
                              id="remove"
                              defaultMessage="Remove"
                            />
                          ),
                          trigger: () => deleteVenue(),
                          border: true,
                          delete: true,
                        },
                      ]}
                    />
                  </div>
                </div>

                {venue && venue.Address && (
                  <div className="subTitle mt-1">
                    {venue ? `${venue.Address}` : "-"}
                  </div>
                )}
                <p className="subTitle">
                  {venue
                    ? `${venue.City && venue?.City + ","} ${
                        venue?.State !== null ? venue?.State + "," : ""
                      } ${venue.Country || "-"}`
                    : "-"}{" "}
                  {venue.ZipCode ? venue.ZipCode : ""}
                </p>
                <div className="mt-3 d-flex align-items-start">
                  {venue.Type ? (
                    <p className={venue.Capacity > 0 ? "subTitle" : "subTitle"}>
                      <b style={{ color: "black" }}>Type: </b>
                      <br />
                      {venue.Type}
                    </p>
                  ) : null}
                  {venue.Capacity >= 0 ? (
                    <p className={"ml-3 subTitle d-block"}>
                      <b style={{ color: "black" }}>Venue Capacity: </b>
                      <br />
                      {venue.Capacity === 0 ? "-" : venue.Capacity}
                    </p>
                  ) : null}
                </div>

                <div className="mt-3 d-flex align-items-start">
                  <button
                    className="btn btn-success mt-2 border-0"
                    type="button"
                    onClick={() => {
                      let fullAddress = "";
                      fullAddress =
                        venue.Address +
                        ", " +
                        venue.City +
                        ", " +
                        venue.Country;
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${
                          venue.Name + " " + fullAddress
                        }`,
                        "MapWindow",
                        "width=1280,height=840"
                      );
                    }}
                  >
                    Open in Maps
                  </button>
                  <button
                    className="btn btn-info mt-2 border-0 ml-2"
                    type="button"
                    onClick={() => toggleMarkerModal()}
                  >
                    Check distance
                  </button>
                </div>
              </div>
            </div>
            {/* <button
              className="btn btn-block p-0 mt-3"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${
                    venue.Name + " " + fullAddress
                  }`,
                  "MapWindow",
                  "width=1280,height=840"
                );
              }}
            >
              <img
                className="img-fluid"
                alt=""
                src={mapimg}
                style={{ width: "100%", minHeight: "150px" }}
              />
            </button> */}

            <NotesContainer event={event} hasGroupImage={groupImgUrl} />
          </div>
        </>
      ) : (
        <div>
          <h1 className="text-secondary">No Venue assigned to this event.</h1>
        </div>
      )}
    </div>
  );
};

export default VenueDetail;
