import React from "react";

import DatePicker from "react-datepicker";
import moment from "moment";
import { Formik, Form, ErrorMessage } from "formik";
import ReactQuill from "react-quill";
import { DropDownList } from "../common";
import { useIntl, FormattedMessage } from "react-intl";

//Airline = Call Sign, Tail Number = AirlineFlight, OperatedBy = Plane Type
//LongName = FBO
const PrivateFlightForm = (props) => {
  const state = props.parentState;
  const { searchAirports, CurrentFlight, submitFlight, setParentState } = props;

  return (
    <Formik
      onSubmit={submitFlight}
      enableReinitialize
      initialValues={CurrentFlight}
    >
      {(formikValues) => {
        return (
          <div className="formContainer">
            <div className="formContainer-body">
              <Form autoComplete="off" className="row">
                <div className="col-6 my-2">
                  <label htmlFor="AirlineFlight">
                    <FormattedMessage
                      id="tailNumber"
                      defaultMessage="Tail Number"
                    />
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      id="AirlineFlight"
                      //value={state.UI.CurrentFlight.AirlineFlight.value}
                      value={formikValues.values.AirlineFlight}
                      onChange={
                        (e) => {
                          formikValues.setFieldValue(
                            "AirlineFlight",
                            e.target.value
                          );
                          formikValues.submitForm();
                        }
                        // state.UI.CurrentFlight.AirlineFlight.set(e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="col-6 my-2">
                  <label htmlFor="Airline">
                    <FormattedMessage
                      id="callSign"
                      defaultMessage="Call Sign"
                    />
                    &nbsp;
                    <i
                      className="fas fa-info-circle"
                      title="The call sign is a specialized form of nickname that is used as a substitute for the aviator’s given name"
                    ></i>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder={"Optional"}
                    value={formikValues.values.Airline}
                    onChange={(e) => {
                      formikValues.setFieldValue("Airline", e.target.value);
                      formikValues.submitForm();
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label htmlFor="OperatedBy">
                    <FormattedMessage
                      id="planeType"
                      defaultMessage="Plane Type"
                    />
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    value={formikValues.values.OperatedBy}
                    onChange={(e) => {
                      formikValues.setFieldValue("OperatedBy", e.target.value);
                      formikValues.submitForm();
                    }}
                    // value={state.UI.CurrentFlight.OperatedBy.value ? state.UI.CurrentFlight.OperatedBy.value : ""}
                    // onChange={async (e) => {
                    //     state.UI.CurrentFlight.OperatedBy.set(e.target.value);

                    // }}
                  />
                </div>

                <div className="col-12 bgTitleModal py-1 mt-2">
                  {props?.CurrentFlight &&
                  props?.parentState?.Flights?.length > 1 ? (
                    props.parentState.Flights.findIndex(
                      (x) => x.GUID === props.CurrentFlight.GUID
                    ) > 0 ? (
                      <h3>
                        <FormattedMessage
                          id="stopInformation"
                          defaultMessage="Stop Information"
                        />
                      </h3>
                    ) : (
                      <h3>
                        <FormattedMessage
                          id="departureInformation"
                          defaultMessage="Departure Information"
                        />
                      </h3>
                    )
                  ) : (
                    <h3>
                      <FormattedMessage
                        id="departureInformation"
                        defaultMessage="Departure Information"
                      />
                    </h3>
                  )}
                </div>
                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="departureAirport"
                      defaultMessage="Departure Airport"
                    />
                    {formikValues.values.From &&
                      formikValues.values.From.length > 0 && (
                        <i
                          className="ml-2 fas fa-info-circle text-dark"
                          title={formikValues.values.FromLongName}
                        ></i>
                      )}
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.From}
                    onChange={(e) => {
                      formikValues.setFieldValue("From", e.target.value);
                      if (e.target.value.length >= 3) {
                        searchAirports("from", e.target.value);
                      } else {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, AirportsFrom: [] },
                          };
                        });
                      }
                      formikValues.submitForm();
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, AirportsFrom: [] },
                          };
                        });
                      }, 1500);
                    }}
                    // value={state.UI.CurrentFlight.From.value}
                    className="form-control"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="From"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.AirportsFrom}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = state.UI.AirportsFrom;
                      formikValues.setFieldValue("From", airports[i].IATA);
                      let fullAddress =
                        airports[i].City + ", " + airports[i].Country;
                      formikValues.setFieldValue("FromAddress", fullAddress);
                      formikValues.setFieldValue(
                        "FromLatitude",
                        airports[i].Latitude
                      );
                      formikValues.setFieldValue(
                        "FromLongitude",
                        airports[i].Longitude
                      );

                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: { ...prev.UI, AirportsFrom: [] },
                        };
                      });
                      formikValues.submitForm();
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="departureFBO"
                      defaultMessage="Departure FBO"
                    />
                    {formikValues.values.FromLongName ? (
                      <span
                        className="badge badge-sm clickable"
                        onClick={() => {
                          formikValues.setFieldValue("FromLongName", "");
                          formikValues.setFieldValue("FromAddress", "");
                          formikValues.setFieldValue("FromLatitude", "");
                          formikValues.setFieldValue("FromLongitude", "");
                          formikValues.submitForm();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.FromLongName}
                    title={formikValues.values.FromLongName}
                    className="form-control"
                    onChange={(e) => {
                      formikValues.setFieldValue(
                        "FromLongName",
                        e.target.value
                      );

                      if (e.target.value.length >= 3) {
                        props.searchFBO(
                          "from",
                          e.target.value,
                          formikValues.values
                        );
                      } else {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, FromLongName: [], FromFBO: [] },
                          };
                        });
                      }
                      formikValues.submitForm();
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, FromFBO: [] },
                          };
                        });
                      }, 1500);
                    }}
                  />

                  <ErrorMessage
                    className="error-message"
                    name="From"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.FromFBO}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = state.UI.FromFBO;
                      let address =
                        (airports[i].Address ? airports[i].Address : "") +
                        (airports[i].City ? ", " + airports[i].City : "") +
                        (airports[i].ZipCode
                          ? ", " + airports[i].ZipCode
                          : "") +
                        (airports[i].State ? ", " + airports[i].State : "") +
                        (airports[i].Country ? ", " + airports[i].Country : "");

                      formikValues.setFieldValue(
                        "FromLongName",
                        airports[i].Name
                      );
                      formikValues.setFieldValue("FromAddress", address);

                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: { ...prev.UI, FromFBO: [] },
                        };
                      });

                      formikValues.submitForm();
                      debugger;
                    }}
                  />
                </div>

                <div className="col-12 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage id="Address" defaultMessage="Address" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formikValues.values.FromAddress}
                    onChange={(e) => {
                      formikValues.setFieldValue("FromAddress", e.target.value);
                      //state.UI.CurrentFlight.FromAddress.set(e.target.value);
                      formikValues.submitForm();
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label htmlFor={"checkinDate"}>
                    <FormattedMessage
                      id="departureDate"
                      defaultMessage="Departure Date"
                    />
                  </label>
                  <div className="leftSide__date">
                    <input
                      type="date"
                      className="form-control underline py-0"
                      value={
                        formikValues.values.DepartureDate &&
                        moment(formikValues.values.DepartureDate).format(
                          "YYYY-MM-DD"
                        )
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }
                          let finalValue =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.DepartureDate).format(
                              "HH:mm:ss"
                            );
                          let finalValueArrival =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.ArrivalDate).format(
                              "HH:mm:ss"
                            );
                          formikValues.setFieldValue(
                            "DepartureDate",
                            finalValue
                          );
                          formikValues.setFieldValue(
                            "ArrivalDate",
                            finalValueArrival
                          );

                          //state.UI.CurrentFlight.DepartureDate.set(finalValue);
                          //state.UI.CurrentFlight.ArrivalDate.set(finalValueArrival);
                          formikValues.submitForm();
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-6 my-2">
                  <label htmlFor="DepartureTime">
                    <FormattedMessage
                      id="departureTime"
                      defaultMessage="Departure Time"
                    />
                  </label>

                  <DatePicker
                    className="form-control underline"
                    popperPlacement="top-start"
                    id={"checkinTime"}
                    selected={
                      formikValues.values.DepartureDate &&
                      moment(formikValues.values.DepartureDate).toDate()
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(e) => {
                      try {
                        let newValue =
                          moment(formikValues.values.DepartureDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment(e).format("HH:mm:ss");
                        formikValues.setFieldValue("DepartureDate", newValue);
                        //    state.UI.CurrentFlight.DepartureDate.set(newValue);
                        formikValues.submitForm();
                      } catch (ex) {
                        console.error(ex);
                        debugger;
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="DepartureDate"
                    component="div"
                  />
                </div>
                <div className="col-12 bgTitleModal py-1 mt-2">
                  {props?.CurrentFlight &&
                  props?.parentState?.Flights?.length > 1 ? (
                    props?.parentState?.Flights.findIndex(
                      (x) => x.GUID === props?.CurrentFlight?.GUID
                    ) <
                    props.parentState.Flights.length - 1 ? (
                      <h3>
                        <FormattedMessage
                          id="stopInformation"
                          defaultMessage="Stop Information"
                        />
                      </h3>
                    ) : (
                      <h3>
                        <FormattedMessage
                          id="arrivalInformation"
                          defaultMessage="Arrival Information"
                        />
                      </h3>
                    )
                  ) : (
                    <h3>
                      <FormattedMessage
                        id="arrivalInformation"
                        defaultMessage="Arrival Information"
                      />
                    </h3>
                  )}
                </div>
                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="arrivalAirport"
                      defaultMessage="Arrival Airport"
                    />
                    {formikValues.values.ToLongName &&
                      formikValues.values.ToLongName.length > 0 && (
                        <i
                          className="ml-2 fas fa-info-circle text-dark"
                          title={formikValues.values.ToLongName}
                        ></i>
                      )}
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.To}
                    className="form-control"
                    onChange={(e) => {
                      formikValues.setFieldValue("To", e.target.value);

                      if (e.target.value.length >= 3) {
                        searchAirports("to", e.target.value);
                      } else {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, AirportsTo: [] },
                          };
                        });
                      }
                      formikValues.submitForm();
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, AirportsTo: [] },
                          };
                        });
                      }, 1500);
                    }}
                  />

                  <ErrorMessage
                    className="error-message"
                    name="To"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.AirportsTo}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = state.UI.AirportsTo;

                      let address =
                        (airports[i].Address ? airports[i].Address : "") +
                        (airports[i].City ? ", " + airports[i].City : "") +
                        (airports[i].ZipCode
                          ? ", " + airports[i].ZipCode
                          : "") +
                        (airports[i].State ? ", " + airports[i].State : "") +
                        (airports[i].Country ? ", " + airports[i].Country : "");

                      formikValues.setFieldValue("To", airports[i].IATA);
                      formikValues.setFieldValue("ToAddress", address);
                      formikValues.setFieldValue(
                        "ToLatitude",
                        airports[i].Latitude
                      );
                      formikValues.setFieldValue(
                        "ToLongitude",
                        airports[i].Longitude
                      );
                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: { ...prev.UI, AirportsTo: [] },
                        };
                      });

                      formikValues.submitForm();
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="arrivalFBO"
                      defaultMessage="Arrival FBO"
                    />
                    {formikValues.values.ToLongName ? (
                      <span
                        className="badge badge-sm clickable"
                        onClick={() => {
                          formikValues.setFieldValue("ToLongName", "");
                          formikValues.setFieldValue("ToAddress", "");

                          formikValues.submitForm();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.ToLongName}
                    title={formikValues.values.ToLongName}
                    className="form-control"
                    onChange={(e) => {
                      //state.UI.CurrentFlight.ToLongName.set(e.target.value);
                      formikValues.setFieldValue("ToLongName", e.target.value);

                      if (e.target.value.length >= 3) {
                        props.searchFBO(
                          "to",
                          e.target.value,
                          formikValues.values
                        );
                      } else {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, ToLongName: [] },
                          };
                        });
                      }
                      formikValues.submitForm();
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: { ...prev.UI, ToFBO: [] },
                          };
                        });
                      }, 1500);
                    }}
                  />

                  <ErrorMessage
                    className="error-message"
                    name="To"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.ToFBO}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = state.UI.ToFBO;

                      let address =
                        (airports[i].Address !== null
                          ? airports[i].Address
                          : "") +
                        (airports[i].City !== null
                          ? ", " + airports[i].City
                          : "") +
                        (airports[i].ZipCode !== null
                          ? ", " + airports[i].ZipCode
                          : "") +
                        (airports[i].State !== null
                          ? ", " + airports[i].State
                          : "") +
                        (airports[i].Country !== null
                          ? ", " + airports[i].Country
                          : "");

                      formikValues.setFieldValue(
                        "ToLongName",
                        airports[i].Name
                      );
                      formikValues.setFieldValue("ToAddress", address);

                      //state.UI.CurrentFlight.ToLongName.set(airports[i].Name);
                      //state.UI.CurrentFlight.ToAddress.set(address);
                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: { ...prev.UI, ToFBO: [] },
                        };
                      });
                    }}
                  />
                </div>

                <div className="col-12 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage id="address" defaultMessage="Address" />
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.ToAddress}
                    className="form-control"
                    onChange={(e) => {
                      formikValues.setFieldValue("ToAddress", e.target.value);
                      //state.UI.CurrentFlight.ToAddress.set(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 my-2">
                  <label htmlFor={"checkinDate"}>
                    <FormattedMessage
                      id="arrivalDate"
                      defaultMessage="Arrival Date"
                    />
                  </label>
                  <div className="rightSide__date">
                    <input
                      type="date"
                      className="form-control underline py-0"
                      value={
                        formikValues.values.ArrivalDate &&
                        moment(formikValues.values.ArrivalDate).format(
                          "YYYY-MM-DD"
                        )
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }
                          let finalValue =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.ArrivalDate).format(
                              "HH:mm:ss"
                            );
                          formikValues.setFieldValue("ArrivalDate", finalValue);
                          //state.UI.CurrentFlight.ArrivalDate.set(finalValue);
                          formikValues.submitForm();
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-6 my-2">
                  <label htmlFor="ArrivalDate">
                    <FormattedMessage
                      id="arrivalTime"
                      defaultMessage="Arrival Time"
                    />
                  </label>

                  <DatePicker
                    className="form-control underline"
                    popperPlacement="top-start"
                    id={"checkinTime"}
                    selected={
                      formikValues.values.ArrivalDate
                        ? moment(formikValues.values.ArrivalDate).toDate()
                        : new Date()
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(e) => {
                      try {
                        let newValue =
                          moment(formikValues.values.ArrivalDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment(e).format("HH:mm:ss");
                        //state.UI.CurrentFlight.ArrivalDate.set(newValue);
                        debugger;
                        formikValues.setFieldValue("ArrivalDate", newValue);
                        formikValues.submitForm();
                      } catch (ex) {
                        console.error(ex);
                        debugger;
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="ArrivalTime"
                    component="div"
                  />
                </div>

                <div className="col-12 my-2">
                  <label>
                    <FormattedMessage
                      id="estimatedDuration"
                      defaultMessage="Estimated Duration"
                    />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      formikValues.values.Duration
                        ? formikValues.values.Duration
                        : ""
                    }
                    onChange={(e) => {
                      formikValues.setFieldValue("Duration", e.target.value);
                      //state.UI.CurrentFlight.Duration.set(e.target.value)}
                      formikValues.submitForm();
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="Duration"
                    component="div"
                  />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default PrivateFlightForm;
