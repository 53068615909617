import React, { Component } from "react";
import Dashboard from "./dashboard";
import { connect } from "react-redux";
import { logoutUser } from "../../utils";
import history from "../../components/common/history";
import { default as Navbar } from "../../components/common/navbar";
import { getAccessToken } from "../../utils";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    history.push("/calendar");
  }

  logout = () => {
    history.push("/");
    logoutUser();
  };

  render() {
    return <Dashboard logoutUser={this.logout} />;
  }
}

const mapStateToProps = (state) => ({
  loading: false,
});

const mapDispatchToProps = {
  getOrganizations: () => console.log("get organization action"),
  fetchCurrentOrganization: () => console.log("get organization action"),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar(DashboardComponent));
