import React from "react";
import { TicketConfirmationOutline } from "mdi-material-ui";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";

const InfoBox = ({ info, title, children, reveal }) => {
  return (
    <div className="card  my-2 shadow-sm">
      <div className="card-body p-1 px-2">
        <h5 className="title text-truncate">{title}</h5>
          {children}
      </div>
    </div>
   
   
  );
};

export default InfoBox;
