import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";
import uuid from "uuid";

export function editGroup(groupObject, events, groupImage, close, success) {
  const groupUrl = "/EventGroup/AddEditBatch";
  const eventsUrl = "/EventGroup/AddEditEventBatch ";
  const uploadUrl = "/EventGroup/Upload";

  return async (dispatch) => {
    try {
      dispatch({
        type: constant.CREATING_GROUPS,
      });
      await apiService.post(groupUrl, [groupObject]);
      const eventsToGroup = events.map((event) => ({
        EventGUID: event.GUID,
        EventGroupGUID: groupObject.GUID,
        Deleted: event.Deleted ? true : false,
      }));

      await apiService.post(eventsUrl, eventsToGroup);

      if (groupImage) {
        groupImage.name = uuid.v4();
        let data = new FormData();
        data.append(
          "image",
          groupImage,
          `.${groupImage?.type?.replace(/image\//g, "")}`
        );
        const ImageUpload = await apiService.post(
          `${uploadUrl}?EventGroupId=${groupObject.GUID}`,
          data,
          {
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          }
        );
        groupObject.Image = ImageUpload.data.Image;
      }
      close();

      success();
      dispatch({
        type: constant.CREATED_GROUPS,
      });
      const eventsArray = events.map((event) => event.GUID);

      dispatch({
        type: constant.UPDATING_GROUPS_EVENTS,
        data: eventsArray,
        group: groupObject,
      });
    } catch (e) {
      dispatch({
        type: constant.CREATING_GROUPS_ERROR,
        error: e,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_GROUPS: {
      return {
        ...state,
        creatingGroup: true,
      };
    }
    case constant.CREATED_GROUPS: {
      return {
        ...state,
        creatingGroup: false,
      };
    }
    case constant.CREATING_GROUPS_ERROR: {
      return {
        ...state,
        creatingGroup: false,
      };
    }
    case constant.UPDATING_GROUPS_EVENTS: {
      const coloringEvents = state.calendarEvents.map((event) => {
        if (action.data.includes(event.GUID)) {
          event.EventGroup = action.group;
          event.EventGroupGUID = action.group.GUID;
          return event;
        }
        return event;
      });

      return {
        ...state,
        calendarEvents: coloringEvents,
        creatingGroup: false,
      };
    }
    default:
      return state;
  }
}
