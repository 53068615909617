import "../../scss/style.scss";
import { toast } from "react-toastify";

// Toast Notification Error Component

export const ToastNotificationError = (id, message) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastId: id,
  });
};

// Toast Notification Success Component

export const ToastNotificationSuccess = (id, message) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastId: id,
  });
};
