import {useIntl, FormattedMessage} from 'react-intl'
export const OPTION = [
  { value: "Confirmed", label:<FormattedMessage
  id="confirmed"
  defaultMessage="Confirmed"/>},
  { value: "Postponed", label: <FormattedMessage
  id="postponed"
  defaultMessage="Postponed"/> },
  { value: "Tentative/Hold", label: <FormattedMessage
  id="tentativeHold"
  defaultMessage="Tentative/Hold"/> },
  { value: "Cancelled", label: <FormattedMessage
  id="cancelled"
  defaultMessage="Cancelled"/> },
];
