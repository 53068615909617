import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function editAttachment(attachment) {
  const url = "/Attachment/AddEditForEventWithoutImage";
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_ATTACHMENT,
    });
    try {
      const request = await apiService.post(url, attachment);
      dispatch({
        type: constant.EDITED_ATTACHMENT_SUCCESS,
        data: request.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_ATTACHMENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_ATTACHMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        attachments: state.attachments.map((attachment) => {
          if (attachment.GUID === action.data.GUID) {
            attachment = action.data;
          }
          return attachment;
        }),
      };
    }
    case constant.EDITING_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
