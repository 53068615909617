import React from "react";
import { IMAGES } from "../../constant";
import { checkIfHotelExists } from "../../utils";
import { RadioboxBlank, RadioboxMarked } from "mdi-material-ui";
import moment from "moment";

const SelectableHotel = (props) => {
  let { toggleHotel, hotel, hotelSelected } = props;
  return (
    <div className="selectableHotel__root">
      <div
        className="selectableHotel__toggler"
        onClick={() => toggleHotel(hotel)}
      >
        {checkIfHotelExists(hotel.GUID, hotelSelected) ? (
          <RadioboxMarked />
        ) : (
          <RadioboxBlank />
        )}
      </div>
      <div className="selectableHotel__details">
        <h6>{hotel.Name}</h6>
        <p>
          {hotel.Address} | Checkin:{" "}
          {moment(hotel.Checkin).format("MMMM DD, YYYY")} | Checkout:{" "}
          {moment(hotel.Checkout).format("MMMM DD, YYYY")}
        </p>
      </div>
    </div>
  );
};

export default SelectableHotel;
