const uuidv1 = require('uuid/v1');

export const setFlightDetails = (data, flight, isStop) => {
    return {
        ...data,
        guid: isStop ? uuidv1() : data.guid || flight.guid,
        flightNumber: flight.airlineFlight || '',
        labelField: flight.label || '',
        faFlightID: flight.faFlightID || '',
        fromField: flight.fromLocation || '',
        toField: flight.toLocation || '',
        operatedField: flight.operatedBy || '',
        departureField: new Date(flight.departureDate),
        arrivalField: new Date(flight.arrivalDate),
        airline: flight.airline || '',
        estimetedField: flight.duration || '',
        addtionalNotesField: flight.additionalNotes || '',
        departureTimeZone: flight.departureTimeZone || '',
        arrivalTimeZone: flight.arrivalTimeZone || '',
        departureTimeZoneHourFromGmt: flight.departureTimeZoneHourFromGmt || '',
        arrivalTimeZoneHourFromGmt: flight.arrivalTimeZoneHourFromGmt || '',
        originCity: flight.originCity || '',
        destinationCity: flight.destinationCity || '',
        origin: flight.origin || '',
        destination: flight.destination || '',
        planeType: flight.planeType || '',
        connection: flight.connection || '',
        stops: flight.stops || [],
        airlineErr: '',
        flightNumberError: '',
        toErr: '',
        fromErr: '',
        departureTimeErr: '',
        departureErr: '',
        arrivalErr: '',
        arrivalTimeErr: '',
        departureFBO: flight.departureFBO || '',
        departureFBOError: '',
        arrivalFBO: flight.arrivalFBO || '',
        arrivalFBOError: '',
        departureAddress: flight.departureAddress || '',
        departureAddressError: '',
        arrivalAddress: flight.arrivalAddress || '',
        arrivalAddressError: '',
    };
};

export const setFlightDetailsPayload = (isPrivate, isReturn, currentEvent_guid, data, isRoundTrip) => {
    let flightDetails = {
        guid: data.guid || uuidv1(),
        arrivalAddress: data.arrivalAddress,
        departureAddress: data.departureAddress,
        privateFlight: isPrivate,
        arrivalFBO: data.arrivalFBO,
        departureFBO: data.departureFBO,
        airline: data.airline,
        airlineFlight: data.flightNumber,
        planeType: data.planeType,
        label: data.labelField,
        connection: data.connection,
        operatedBy: data.operatedField,
        fromLocation: data.fromField,
        toLocation: data.toField,
        faFlightID: data.faFlightID,
        departureDate: data.departureField,
        arrivalDate: data.arrivalField,
        departureTime: data.departureField,
        arrivalTime: data.arrivalField,
        duration: data.estimetedField,
        destinationCity: data.destinationCity,
        originCity: data.originCity,
        additionalNotes: data.addtionalNotesField,
        departureTimeZone: data.departureTimeZone,
        departureTimeZoneHourFromGmt: data.departureTimeZoneHourFromGmt,
        arrivalTimeZone: data.arrivalTimeZone,
        arrivalTimeZoneHourFromGmt: data.arrivalTimeZoneHourFromGmt,
        isDepartureFlight: isReturn,
        eventGuid: currentEvent_guid,
        flightAwareId: null,
        departureFlight: isReturn,
        roundTrip: isRoundTrip,
        stops: data.stops || [],
        origin: data.origin,
        destination: data.destination,
    }
    return flightDetails;
}

export const setPayloadData = (isReturn, isPrivate, currentEvent, data, isRoundTrip) => {
    let departData = null;
    let returnData = null;
    if (isRoundTrip) {
        departData = setFlightDetailsPayload(isPrivate, isReturn, currentEvent.guid, data.departData, isRoundTrip)
        returnData = setFlightDetailsPayload(isPrivate, isReturn, currentEvent.guid, data.returnData, isRoundTrip)
    } else {
        departData = !isReturn ? setFlightDetailsPayload(isPrivate, isReturn, currentEvent.guid, data.departData, isRoundTrip) : null
        returnData = isReturn ? setFlightDetailsPayload(isPrivate, isReturn, currentEvent.guid, data.returnData, isRoundTrip) : null
    }
    let payload = {
        depart: departData,
        return: returnData,
        roundTrip: isRoundTrip,
        privateFlight: isPrivate,
        eventGuid: currentEvent.guid,
        departureFlight: isReturn,
    }

    return payload
}

export const setEditPayloadData = (isReturn, isPrivate, data, isRoundTrip, flight) => {
    let updated_data = isReturn ? data.returnData : data.departData;
    let payLoad = setFlightDetailsPayload(isPrivate, isReturn, flight.eventGuid, updated_data, isRoundTrip)
    return payLoad;
}

export const setEditStopPayload = (isPrivate, isReturn, data, isRoundTrip, flight) => {
    let payload = setFlightDetailsPayload(isPrivate, isReturn, flight.eventGuid, data, isRoundTrip)
    payload = { ...data, ...payload }
    return payload;
}
