import React from "react";
import Draggable from "react-draggable";
import { DragHorizontal, Close } from "mdi-material-ui";
const backgroundUrl =
  "https://omoto.io/academy/wp-content/uploads/2018/01/Customer-service.jpg?x71100";

class CallCard extends React.Component {
  constructor(props) {
    super(props);
  }
  loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };
  initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await this.loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI("jitsi.debian.social", {
      parentNode: document.getElementById("meet2"),
      roomName: "plnify",
      interfaceConfigOverwrite: {
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        HIDE_INVITE_MORE_HEADER: true,
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "hangup",
          "desktop",
          "fullscreen",
        ],
      },
    });
    _jitsi.addListener("videoConferenceLeft", (args) => {
      this.props.closeCall();
    });
    this.setState({ jitsiObj: _jitsi });
  };

  componentDidMount() {
    this.initialiseJitsi();
  }

  render() {
    return (
      <Draggable bounds="body">
        <div className="meet__window card shadow-sm">
          <div className="card-header text-center relative">
            <DragHorizontal />
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 5,
                cursor: "pointer",
              }}
              onClick={() => this.props.closeCall()}
            >
              <Close />
            </div>
          </div>

          <div className="card-body p-0">
            <div
              id="meet2"
              className="row mx-0"
              style={{ height: "100%", width: "100%" }}
            ></div>
          </div>
        </div>
      </Draggable>
    );
  }
}

export default CallCard;
