import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import uuid from "uuid";

export function addFlight(data, passengers, event, success) {
  const url = "/Flights/AddEditBatch";
  const staffUrl = "/FlightStaff/AddEditBatch";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_FLIGHT,
    });
    let preparedData;
    let finalStaffs = [];
    passengers.forEach((p) => {
      finalStaffs.push({ ...p, FlightGUID: data[0].GUID });
    });
    if (data.length > 1) {
      let connection = uuid.v4();
      preparedData = data.map((f) => ({
        ...f,
        Connection: connection,
        EventGUID: event.GUID,
      }));
    } else {
      let connection = uuid.v4();
      preparedData = data.map((f) => ({
        ...f,
        EventGUID: event.GUID,
        Connection: f.Connection ? f.Connection : connection,
      }));
    }
    try {
      const request = await apiService.post(url, preparedData);
      await api.post(staffUrl, finalStaffs);
      dispatch({
        type: constant.CREATED_FLIGHT,
        data: request.data.Response.filter((d) => d.Deleted === null),
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.CREATING_FLIGHT_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_FLIGHT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_FLIGHT: {
      return {
        ...state,
        loading: false,
        flights: action.data,
      };
    }
    case constant.CREATING_FLIGHT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
