import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";
import uuid from "uuid";
import moment from "moment";

export function addEvent(data, staffs, teams, success, eventType) {
  const url = "/Events/Add";
  return async (dispatch) => {
    dispatch({ type: constant.CREATING_EVENT });
    try {
      let finalStaffs = [];
      delete data.Venue;
      data.EventType = eventType ?? data?.EventType?.value;
      let eventGUID = uuid.v4();

      if (staffs.length === 0) {
        teams.map((team) => {
          team.Staffs.forEach((staff) => finalStaffs.push(staff.GUID));
        });
        finalStaffs = finalStaffs.map((s) => ({
          StaffGUID: s,
          Deleted: null,
          GUID: uuid.v4(),
          EventGUID: eventGUID,
        }));
      } else if (staffs.length !== 0) {
        finalStaffs = staffs.map((s) => ({
          StaffGUID: s,
          Deleted: null,
          GUID: uuid.v4(),
          EventGUID: eventGUID,
        }));
      }
      const eventDate = moment.utc(data.Date).format(`YYYY-MM-DD`);
      let finalEvent = [
        {
          ...data,
          GUID: eventGUID,
          VisibleBy: finalStaffs,
          Date: `${eventDate}T23:00:00`,
        },
      ];

      db.table("eventsTransactions")
        .add(finalEvent[0])
        .then(() => console.log("event saved"));
      const request = await api.post(url, finalEvent);
      db.table("eventsTransactions")
        .delete(finalEvent[0].GUID)
        .then(() => console.log("event cleared"));

      dispatch({
        type: constant.CREATED_EVENT_SUCCESS,
        data: request.data[0],
      });
      success(request.data[0]);
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_EVENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CREATING_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
