import React, { useState } from "react";
import { InformationOutline, BadgeAccountOutline } from "mdi-material-ui";
import UserTabs from "../../organization/UserTabs";
import { DropDownComponent, ModalComponent } from "../../common";

import { randomImage } from "../../common/randomImage";

const UserItem = ({ user, toggleAdmin, deleteUser }) => {
  const [showProfile, setShowProfile] = useState(false);
  let fullName = user.Account.Name + " " + user.Account.LastName;
  let initials =
    (user.Account.Name && user.Account.Name.charAt(0).toUpperCase()) +
    (user.Account.LastName && user.Account.LastName.charAt(0));
  let img = randomImage(initials);

  return (
    <div className={`py-2 userTeam`} key={user.guid}>
      <ModalComponent
        modal={showProfile}
        toggle={() => setShowProfile(!showProfile)}
        childeren={
          <UserTabs
            user={user.Account}
            toggle={() => setShowProfile(!showProfile)}
          />
        }
      />
      <div className="d-flex justify-content-between align-items-center px-1">
        <div className="userTeam__user">
          <div className="userTeam__profilePicture">
            {user?.Account?.Picture &&
            user?.Account?.Picture?.includes("http") ? (
              <img
                src={user.Account.Picture}
                alt={fullName}
                className="img-fluid avatar"
              />
            ) : user.Account.Picture ? (
              <img
                src={`https://app.plnify.com/photos/${user.Account.Picture}?w=75&h=75&mode=crop&autorotate=true`}
                alt={fullName}
                className="img-fluid avatar"
              />
            ) : (
              <img src={img} alt={fullName} className="img-fluid avatar" />
            )}
          </div>
          <div>
            <div className="userTeam__name title">
              <h6 className="d-flex align-items-center text-dark">
                {fullName}
                <button
                  title="Click to see user details"
                  className="ml-2 btn-reset icon-svg"
                  onClick={() => setShowProfile((showProfile) => !showProfile)}
                >
                  <InformationOutline />
                </button>
                {user.isAdmin ? <BadgeAccountOutline /> : null}
              </h6>
            </div>
          </div>
        </div>
        <div>
          <button className="teamItem__more btn-reset mx-1">
            <DropDownComponent
              data={[
                {
                  name: user.isAdmin
                    ? "Remove admin access to this channel"
                    : "Grant admin access to this channel",
                  border: true,
                  trigger: () => toggleAdmin(user.accountGuid),
                },
                {
                  delete: true,
                  name: "Remove user",
                  trigger: () => deleteUser(user.accountGuid),
                },
              ]}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
