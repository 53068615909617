import moment from "moment";
import React from "react";

export const DropDownList = (props) => {
  let { searchList, setData, displayValue, cursor, isFlight } = props;
  return (
    <ul className="dropdown-list">
      {searchList?.length > 0 &&
        searchList.map((data, i) => {
          if(props.Sender === "AirlineFlightNumber"){
           
            return (
              <li key={Math.random()}>
                <a onMouseDown={() => setData(i)}> {data.From + " - "+ data.To +" : "+ moment(data.DepartureDate).format("hh:mm A")}</a>
              </li>
            );
          }else{
            return (
              <li key={Math.random()}>
                <a onMouseDown={() => setData(i)}> {data[displayValue]}</a>
              </li>
            );
          }
         
        })}
    </ul>
  );
};
