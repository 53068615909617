import { ToastNotificationSuccess } from "../components";
import db from "./db";
import moment from "moment";

export const sortArray = (records, field, isNumber) => {
  if (isNumber) {
    return records.sort((a, b) => {
      if (a[field] && b[field]) {
        var nameA = a[field]; // ignore upper and lowercase
        var nameB = b[field]; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      // names must be equal
      return 0;
    });
  }
  if (field === "FLIGHTMEMBER") {
    return records.sort((a, b) => {
      var nameA = a?.Staff?.Name.toUpperCase(); // ignore upper and lowercase
      var nameB = b?.Staff?.Name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  if (field === "FLIGHTMEMBER_ASSIGN") {
    return records.sort((a, b) => {
      var nameA = a?.Account?.Name.toUpperCase(); // ignore upper and lowercase
      var nameB = b?.Account?.Name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  if (field === "HOTELMEMBERS") {
    return records.sort((a, b) => {
      var nameA = a?.Account?.Name.toUpperCase(); // ignore upper and lowercase
      var nameB = b?.Account?.Name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  return records.sort((a, b) => {
    if (a[field] && b[field]) {
      var nameA = a[field].toUpperCase(); // ignore upper and lowercase
      var nameB = b[field].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }

    // names must be equal
    return 0;
  });
};

export const CopyContent = (str) => {
  navigator.clipboard.writeText(str);
  ToastNotificationSuccess(1, "Copied to Clipboard");
};

export default (Helpers) => {};

export const organizationUsers = async (orgId) => {
  let org = await db.table("organization").where({ GUID: orgId }).first();
  if (org !== null) {
    //filter only  active roles
    let roles = org.Roles.filter((x) => x.Deleted === null);
    //roles = sortArray(roles, "Name");
    //filter only active users

    let usersRoles = roles; //.filter(x => x.Staffs.length > 0 && x.Staffs.filter(y => y.Deleted === null).length > 0);
    if (usersRoles) {
      usersRoles = sortArray(usersRoles, "Name");
      return usersRoles;
    }
    //console.log("ORG", org, "ROLES", roles, "users", users);
  }
};

export const extractContentText = (s) => {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const scrollNearTo = (scrollContainerRef, arrayEvents, id) => {
  let element = scrollContainerRef;
  let elementEstimatedHeight = 68;
  //find first index of dates in the array of events
  let theIndex = findWithAttr(arrayEvents, id);

  if (theIndex > 0) {
    //check if we have more than 10 events ahead
    if (arrayEvents.length - theIndex > 10) {
      element.scrollTop(elementEstimatedHeight * (theIndex + 10));
    } else if (theIndex - 5 > 0) {
      //check if we have 10 events below so we can try to center
      element.scrollTop(elementEstimatedHeight * (theIndex + 5));
    } else {
      element.scrollTop(elementEstimatedHeight * theIndex);
    }
  }
};

const findWithAttr = (array, id) => {
  for (var i = 0; i < array.length; i += 1) {
    var record = array[i];
    if (id === undefined || id === null) {
      let now = moment().format("YYYY-MM-DD");
      if (moment(record["Date"]).format("YYYY-MM-DD") === now) {
        return i;
      }
    }
    if (record["GUID"] === id) {
      return i;
    }
  }
  return -1;
};

///THIS FUNCTION IS USED TO CHECK IF A RECORD IS CONTAINED IN A COLLECTION , RETURN THE STATUS
// 0 = NONE 1 =ALL RECORDS ARE SELECTED 2 = PARTIAL SELECTED
export const checkSelectionStatus = (
  collection,
  single,
  selectedCollection
) => {
  console.log({
    collection,
    single,
    selectedCollection,
  });
  try {
    if (single) {
      //Single record check
      if (exist(selectedCollection, single)) {
        return 1;
      } else {
        return 0;
      }
    } else {
      let filter = collection.filter(
        (x) => x.Deleted === undefined || x.Deleted === null
      );
      let count = 0;
      filter.forEach((item) => {
        if (exist(selectedCollection, item)) {
          count++;
          //Check if deleted
        }
      });
      // console.log("colleciton", collection, count, "filter", filter, filter.length)
      if (count === 0) {
        return 0; //none
      } else if (filter && filter.length === count) {
        return 1; //total
      } else {
        return 2; //partial
      }
    }
  } catch (ex) {
    console.error(ex);
    return 0;
  }
};

//THIS FUNCTION EXIST TO CHECK FOR A SPECIFIC  ITEM IN A COLLECTION
export const exist = (selectedCollection, item) => {
  let items = selectedCollection; //JSON.parse(JSON.stringify(state.SelectedItems.value));
  if (!items) {
    return null;
  }
  let record =
    items &&
    items.length > 0 &&
    items.filter(
      (x) =>
        x.GUID === item.GUID && (x.Deleted === undefined || x.Deleted === null)
    )[0];

  return record;
};

//Convert a hook state to a javascript object
export const hookStateToObject = (obj) => {
  // if (typeof obj === 'object'){
  //   return obj
  // }
  if (obj === null) {
    return null;
  }
  if (obj.__isProxy === undefined) {
    return obj;
  }
  // console.log("hook state to obj ", obj);
  let collection = JSON.parse(JSON.stringify(obj.value));
  // console.log("hooksState", obj, collection)
  return collection;
};

//convert celsius to Fahrenheit
export const celsiusToFahrenheit = (val) => {
  return val * 1.18 + 32;
};

export const PassTypesModel = {
  Pass: "Pass",
  Ticket: "Ticket",
  VIP: "VIP Pass",
  MeetAndGreet: "Meet & Greet",
  WorkingPass: "Working Pass",
  "All Access": "All Access",
  Media: "Media",
  Backstage: "Backstage",
  Other: "Other",
};

export const PassTypesModelAsArray = () => {
  let a = [];
  for (let obj in PassTypesModel) {
    a.push(obj);
  }
  a = sortArray(a);
  return a;
};

export const VenueFullAddress = (venue) => {
  let fullAddress = "";
  if (venue !== null && venue.Address) {
    fullAddress += venue.Address;
  }
  if (venue !== null && venue.City) {
    fullAddress += fullAddress.length > 0 ? ", " + venue.City : venue.City;
  }
  if (venue !== null && venue.ZipCode) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + venue.ZipCode : venue.ZipCode;
  }
  if (venue !== null && venue.State) {
    fullAddress += fullAddress.length > 0 ? ", " + venue.State : venue.State;
  }
  if (venue !== null && venue.Country) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + venue.Country : venue.Country;
  }
  fullAddress = fullAddress.replace(" ,", ",");

  return fullAddress;
};

export const HotelFullAddress = (place) => {
  let fullAddress = "";
  if (place !== null && place.Address) {
    fullAddress += place.Address;
  }
  if (place !== null && place.City) {
    fullAddress += fullAddress.length > 0 ? ", " + place.City : place.City;
  }
  if (place !== null && place.ZipCode) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + place.ZipCode : place.ZipCode;
  }
  if (place !== null && place.State) {
    fullAddress += fullAddress.length > 0 ? ", " + place.State : place.State;
  }
  if (place !== null && place.Country) {
    fullAddress +=
      fullAddress.length > 0 ? ", " + place.Country : place.Country;
  }
  fullAddress = fullAddress.replace(" ,", ",");

  return fullAddress;
};

export const KMToMiles = (km) => {
  return Math.round(km * 0.621371, 2);
};

export const DropboxKey = "kyctwd70lfed6dj";

export const getStatusRequestGuestList = (condition) => {
  if (condition.ConfirmedPasses === null) {
    return "pending";
  }

  if (condition.ConfirmedPasses > 0) {
    return "approved";
  }

  if (condition.ConfirmedPasses === 0) {
    return "rejected";
  }
};
