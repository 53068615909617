import React, { useEffect, useState } from "react";

import FormField from "../form/formField";
import { IMAGES } from "../../constant";
import { createTeamSchema } from "../../utils/validationsSchemas";
import { ToastNotificationSuccess, SimpleLoader } from "../common";
import { Scrollbars } from "react-custom-scrollbars";
import { InfoTip } from "../common";
import { PrinterPos, RadioboxBlank, RadioboxMarked } from "mdi-material-ui";
import api from "../../common/api";
import uuid from "uuid";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

import { Item } from "react-contexify";
import db from "../../common/db";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CreateTeamForm = ({
  toggle,
  teams,
  createTeam,
  currentOrganization,
  rolesToSee,
  toggleTeam,
  loading,
  roleToEdit,
  checkTeam,
  setTeams,
  editTeam,
}) => {
  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
    AllTheTeams: [],
    Role: roleToEdit,
  });
  const intl = useIntl();
  const toggleCheck = (selectedTeam) => {
    let copy = JSON.parse(JSON.stringify(state.AllTheTeams));

    copy.forEach((team, index) => {
      if (team.CanViewRoleGUID === selectedTeam.CanViewRoleGUID) {
        copy[index].Deleted =
          team.Deleted === null ? moment().format("YYYY-MM-DD") : null;
      }
    });
    setState((prev) => {
      return {
        ...prev,
        AllTheTeams: copy,
      };
    });
  };
  const loadOtherTeams = () => {
    let obj = JSON.parse(JSON.stringify(state.AllTheTeams));

    teams.forEach((team) => {
      obj.push({
        RoleGUID: roleToEdit.GUID,
        CanViewRoleGUID: team.GUID,
        GUID: uuid.v4(),
        Deleted: null,
        Name: team.Name,
      });
    });
    obj = obj.sort((a, b) => a.Name.localeCompare(b.Name));

    console.log({ teams, obj });
    setState((prev) => {
      return {
        ...prev,
        AllTheTeams: obj,
      };
    });
  };
  const loadDetails = async () => {
    const request = await api.get("/Roles/Details?GUID=" + roleToEdit.GUID);

    setState((prev) => {
      return {
        ...prev,
        UI: { ...prev.UI, isLoading: !prev.UI.isLoading },
      };
    });

    let response = request.data;

    if (response.Status === "ok") {
      let obj = JSON.parse(JSON.stringify(state.AllTheTeams));
      teams
        .filter((x) => x.GUID !== roleToEdit.GUID)
        .forEach((team) => {
          let exists = response.Response.Role_CanViewRole1.filter(
            (x) => x.RoleGUID == team.GUID
          );
          let exist = null;

          if (exists.length > 0) {
            exist = exists[0];
          }
          let record = {
            RoleGUID: roleToEdit.GUID,
            CanViewRoleGUID: team.GUID,
            GUID: exist !== null ? exist.GUID : uuid.v4(),
            Deleted: exist !== null ? exist.Deleted : null,
            Name: team.Name,
          };
          //Check if already there
          let existInCollection = obj.filter(
            (x) =>
              x.RoleGUID === roleToEdit.GUID && x.CanViewRoleGUID === team.GUID
          )[0];
          if (existInCollection !== null) {
            existInCollection = record;
          } else {
            obj.push(record);
          }
        });
      obj = obj.sort((a, b) => a.Name.localeCompare(b.Name));
      setState((prev) => {
        return {
          ...prev,
          AllTheTeams: obj,
        };
      });

      // requestFolderRemote();
    }
  };

  const handleSubmit = async () => {
    try {
      let theRole = JSON.parse(JSON.stringify(state.Role));

      //Add the can view
      theRole.Role_CanViewRole1 = JSON.parse(JSON.stringify(state.AllTheTeams));

      const request = await api.post("/Roles/AddEdit", theRole);

      if (request.data.Status === "ok") {
        //Saving locally

        toggle(true);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const SelectAll = () => {
    let copy = JSON.parse(JSON.stringify(state.AllTheTeams));

    copy.forEach((team, index) => {
      copy[index].Deleted = null;
    });

    setState((prev) => {
      return {
        ...prev,
        AllTheTeams: copy,
      };
    });
  };
  useEffect(() => {
    setTeams(teams);
    loadOtherTeams();
    // if (roleToEdit) {
    //   loadDetails();
    // }
  }, []);

  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100">
              <div className="d-flex justify-content-between">
                <h1>
                  <FormattedMessage id="team" defaultMessage="Team" />
                </h1>

                <button
                  type="button"
                  className="btn btn-sm btn-link ml-2"
                  onClick={() => toggle()}
                >
                  <FontAwesomeIcon icon={faTimes} color="black" />
                </button>
              </div>
              <p className="d-none">
                <FormattedMessage
                  id="fillNewTeam"
                  defaultMessage="Fill out the below details to add a new team."
                />
              </p>
            </div>
            <div className="d-none close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-2">
            <div className="event-content">
              <div className="col-12 py-2">
                <label>
                  <FormattedMessage id="teamName" defaultMessage="Team Name" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name your group"
                  value={state.Role.Name}
                  onChange={(e) => {
                    let copy = JSON.parse(JSON.stringify(state.Role));
                    copy.Name = e.target.value;
                    setState((prev) => {
                      return { ...prev, Role: copy };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bgTitleModal py-1">
            <div className="col-auto text-dark">
              <FormattedMessage id="visibleBy" defaultMessage="Visible by" />
            </div>
            <div className="col-auto ml-auto">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => SelectAll()}
              >
                <FormattedMessage id="selectAll" defaultMessage="Select all" />
              </button>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content">
              <Scrollbars style={{ width: "100%", height: "calc(34vh)" }}>
                {state?.AllTheTeams?.map((team) => {
                  console.log(team.Name + " Is checked " + team.Deleted);
                  return (
                    <div key={team.GUID} className="createTeam__item p-0 row">
                      <div className="col-10 p-2">
                        <p>{team.Name}</p>
                      </div>
                      <div className="col-2 text-right">
                        <button
                          className="btn btn-sm text-secondary"
                          onClick={() => toggleCheck(team)}
                        >
                          {team.Deleted === null ? (
                            <i className="fas fa-check-square"></i>
                          ) : (
                            <i className="far fa-square uncheck"></i>
                          )}
                        </button>
                      </div>
                      <div className="col-12 px-0">
                        <hr className="p-0 m-0" />
                      </div>
                    </div>
                  );
                })}
              </Scrollbars>
            </div>

            <div className="button-section py-3 d-flex align-items-center justify-content-center">
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mx-2"
                  onClick={() => handleSubmit()}
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeamForm;
