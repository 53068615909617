import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import { arraysEqual } from "../../../utils";

import * as constant from "./constants";
const subUrl = "Events/List";
///TODO: Create a local event action only
export function getEvents(organization, userGuid, from, to) {
  return async (dispatch) => {
    const event = await db
      .table("events")

      .where({ OrganizationGUID: organization })
      .sortBy("Date");
    //   console.log("the events ====", event);
    //.toArray();
    const localEventsToCompare = event;

    dispatch({
      type: constant.FETCHED_EVENTS,
      data: event,
    });

    try {
      const eventsUrl = `${subUrl}?organizationguid=${organization}&accountguid=${userGuid}&from=${from}&until=${to}&flushsafari=${Math.random()}`;
      const request = await apiService.get(eventsUrl);
      dispatch({
        type: constant.FETCHING_EVENTS,
      });
      if (request.data.status === "ok") {
        if (arraysEqual(request.data.response, localEventsToCompare)) {
          dispatch({
            type: constant.FETCHED_EVENTS,
            data: event,
          });
        } else {
          await db.table("events").clear();
          await db.table("events").bulkPut(request.data.response);
          const event = await db.table("events").toArray();
          dispatch({
            type: constant.FETCHED_EVENTS,
            data: event,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constant.FETCHING_EVENTS_ERROR,
        data: event,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.FETCHING_EVENTS: {
      return {
        ...state,
        loadingCalendar: true,
      };
    }
    case constant.FETCHED_EVENTS: {
      const events = action.data
        .filter((e) => e.Deleted === null && e.Date !== null)
        .sort(
          (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()
        );
      //.reverse();
      return {
        ...state,
        calendarEvents: events,
        loadingCalendar: false,
      };
    }
    case constant.FETCHING_EVENTS_ERROR: {
      const events = action.data;
      return {
        ...state,
        calendarEvents: events,
        loadingCalendar: false,
      };
    }
    case constant.CREATED_EVENT_SUCCESS: {
      return {
        ...state,
        calendarEvents: [...state.calendarEvents, action.data],
      };
    }
    default:
      return state;
  }
}
