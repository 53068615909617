import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Airplane,
  Bus,
  Domain,
  ClipboardList,
  AccountBox,
  Attachment,
} from "mdi-material-ui";
import AddMeetingModal from "../meet/AddMeetingModal";
import { ModalComponent } from "../common";

const CalendarItem = (props) => {
  const [meetingModal, setMeetingModal] = useState(false);
  let { event } = props;
  if (event.EventType === "Meeting") {
    return (
      <div
        onClick={() => setMeetingModal(!meetingModal)}
        className="clean-link inner event-container relative"
      >
        <ModalComponent
          modal={meetingModal}
          toggle={() => setMeetingModal(!meetingModal)}
          childeren={
            <AddMeetingModal
              onlyToRead
              event={event}
              toggle={() => setMeetingModal(!meetingModal)}
            />
          }
        />
        <span
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: 4,
            height: "100%",
            backgroundColor: event.EventGroup && event.EventGroup.Color,
          }}
        ></span>
        <span className={`event-status ${event.Status}`}></span>
        <div className="event-title">{event.Name}</div>
        <div className="event-location">
          {event.Venue
            ? `${event.Venue.City ? event.Venue.City + ", " : ""}${
                event.Venue.State ? event.Venue.State + ", " : ""
              }${event.Venue.Country ? event.Venue.Country : ""}`
            : `${event.City ? event.City + ", " : ""} ${
                event.State ? event.State + ", " : ""
              } ${event.Country ? event.Country : ""}`}
        </div>
      </div>
    );
  }
  return (
    <Link
      className="clean-link inner event-container relative"
      to={`/calendar/${event.GUID}`}
    >
      <span
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: 4,
          height: "100%",
          backgroundColor: event.EventGroup && event.EventGroup.Color,
        }}
      ></span>
      <span className={`event-status ${event.Status}`}></span>
      <div className="event-title">{event.Name}</div>
      <div className="event-location">
        {event.Venue
          ? `${event.Venue.City ? event.Venue.City + ", " : ""}${
              event.Venue.State ? event.Venue.State + ", " : ""
            }${event.Venue.Country ? event.Venue.Country : ""}`
          : `${event.City ? event.City + ", " : ""} ${
              event.State ? event.State + ", " : ""
            } ${event.Country ? event.Country : ""}`}
      </div>
      <div className="event-icons d-flex align-items-center ">
        {event.FlightsCount > 0 && (
          <div className="icon-section">
            <Airplane htmlColor="#FFF" />
          </div>
        )}
        {event.GroundCount > 0 && (
          <div className="icon-section">
            <Bus htmlColor="#FFF" />
          </div>
        )}
        {event.HotelsCount > 0 && (
          <div className="icon-section">
            <Domain htmlColor="#FFF" />
          </div>
        )}
        {event.ScheduleMasterCount > 0 && (
          <div className="icon-section">
            <ClipboardList htmlColor="#FFF" />
          </div>
        )}
        {event.ContactsCount > 0 && (
          <div className="icon-section">
            <AccountBox htmlColor="#FFF" />
          </div>
        )}
        {event.AttachmentsCount > 0 && (
          <div className="icon-section">
            <Attachment htmlColor="#FFF" />
          </div>
        )}
      </div>
    </Link>
  );
};

export default CalendarItem;
