import React, { useState } from "react";
import { TextInput } from "../../../components/form";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";

const options = [
  { value: "emmanuel@plantechnologies.com", label: "Emmanuel Jimenez" },
  { value: "sonia@plantechnologies.com", label: "Sonia Flores" },
  { value: "victor@plantechnologies.com", label: "Victor Martinez" }
];

const CollabInvite = ({ to, subject, body, onChange }) => {
  return (
    <div className="dashboard-screen">
      <div className="calendar-page">
        <div className="calendar-head">
          <div className="calendar-text">
            <h1>Settings Page</h1>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-7">
            <form className="form">
              <Select isMulti name="Recipients" options={options} />
              <TextInput
                header={"To: "}
                type="text"
                id={"eventName"}
                maxLength={200}
                value={to}
                placeholder="me@mail.com"
              />
              <TextInput
                header={"Subject: "}
                type="text"
                id={"eventName"}
                maxLength={200}
                value={subject}
                placeholder="Invitation to Collaborate in Plnify, with --"
              />
              <Editor
                toolbar={{
                  options: ["blockType", "fontSize", "fontFamily", "list"]
                }}
                editorClassName="collabEditor__editor"
                toolbarClassName="collabEditor__toolbar"
                onEditorStateChange={onChange}
                editorState={body}
              />
            </form>
          </div>
          <div className="col-md-5">
            <div className="collaborationDetail__invitationDetails">
              <div className="flex">Category of Collaboration: Attachments</div>
              <div className="flex">Event: </div>
              <div className="flex">Date: </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollabInvite;
