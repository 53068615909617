import * as constant from "./constants";
import api from "../../../common/api";
import uuid from "uuid";

export function exportReservations(staffs, hotels, success) {
  const url = "/HotelReservation/AddEditMultiple";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EXPORTING_RESERVATIONS,
      });
      let finalReservations = [];
      hotels.map((h) => {
        staffs
          .filter((s) => s.Deleted === null)
          .map((s) => {
            finalReservations.push({
              ...s,
              HotelGUID: h,
              GUID: uuid.v4(),
              Account: null,
            });
          });
      });
      console.log(finalReservations);
      await api.post(url, finalReservations);
      dispatch({
        type: constant.EXPORTED_RESERVATIONS,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EXPORTING_RESERVATIONS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_RESERVATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EXPORTED_RESERVATIONS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EXPORTING_RESERVATIONS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
