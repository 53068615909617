import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useBetaFeature } from "../../hooks/useBetaFeature";

export default function NavItem(props) {
  const { destination, icon, name, onClick, type, fontAwesomeIcon, mode } =
    props;
  // debugger;
  const { isChecked } = useBetaFeature();

  return type !== "ACTION" ? (
    <NavLink
      className={`navItem px-2 relative ${
        mode === "Beta" && !isChecked && "beta active"
      } `}
      to={destination}
      activeClassName="active"
      // data-tip={name}
    >
      <div className="navItem__icon-container">
        <i className={fontAwesomeIcon}></i>
      </div>
      <p className="text-truncate">{name}</p>
      {mode && <span className="badge badge-info ml-2">Beta</span>}
      {/* <ReactTooltip place={"right"} effect="solid" /> */}
    </NavLink>
  ) : (
    <div
      className="navItem px-2"
      onClick={() => onClick()}
      //data-tip={name}
    >
      <div className="navItem__icon-container">
        <i className={fontAwesomeIcon}></i>
      </div>
      <p className="text-truncate">{name}</p>

      {/* <ReactTooltip place={"right"} effect="solid" /> */}
    </div>
  );
}

NavItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.object,
  destination: PropTypes.string,
  show: PropTypes.bool,
};
