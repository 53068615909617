import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import api, { apiService } from "../../common/api";

import moment from "moment";
import { FormattedMessage } from "react-intl";
import uuid from "uuid";
import { IMAGES } from "../../constant";
import { ModalComponent } from "../common";

import Swal from "sweetalert2";
import StopNavigation from "./stopNavigation";

import AddEditLabel from "./AddEditLabel";

import mainState from "../../common/mainState";
//REDUX THINGS

import { useDispatch } from "react-redux";
import * as actions from "../../page/event/redux/actions";
import AssignMembersFlight from "./AssignMembersFlight";
import PrivateFlightForm from "./PrivateFlightForm";
import { useBetaFeature } from "../../hooks/useBetaFeature";
//END REDUX THINGS

const AddEditPrivateFlights = (props) => {
  let connection = uuid.v4();
  const [state, setState] = useState({
    UI: {
      isLoading: false,
      isLoadingAirlines: false,
      Page: "flight", // // search,flight/connection/assign members,
      CurrentFlightIndex: 0,
      CurrentFlight: {},
      AirportsFrom: [],
      AirportsTo: [],

      FromFBO: [],
      ToFBO: [],
      ReloadFlights: false,
      Label: "",
      ShowAddEditLabel: false,
      ShowMembersModal: false,
      Teams: [],

      staffSelected: [],
    },
    Airlines: [],
    OperatedByAirlines: [],
    APIFlights: [],
    Flights: [],
    ConnectionGUID: connection,
  });
  const globalState = useHookState(mainState);

  const dispatch = useDispatch();

  useEffect(() => {
    //Check for passed flights
    if (props.selectedFlights !== undefined) {
      setState((prev) => ({
        ...prev,
        Flights: props.selectedFlights,
        UI: {
          ...prev.UI,
          CurrentFlight: props.selectedFlights[0],
          Page: "flight",
        },
      }));
    } else {
      let initialFlight = {
        GUID: uuid.v4(),
        EventGUID: props.event.GUID,
        AirlineFlight: "",
        Airline: "",
        DepartureDate: props.event.Date,
        ArrivalDate: props.event.Date,
        Connection: connection,
        Deleted: null,
        PrivateFlight: true,
      };
      let flights = JSON.parse(JSON.stringify(state.Flights));
      flights.push(initialFlight);

      setState((prev) => ({
        ...prev,
        Flights: flights,
        UI: {
          ...prev.UI,
          CurrentFlight: initialFlight,
        },
      }));
    }

    let flights = JSON.parse(JSON.stringify(state.Flights));
    if (flights.length > 0 && flights[0].Label) {
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          Label: flights[0].Label,
        },
      }));
    }
  }, []);

  //This is in the api of venues
  const searchFlight = async (sender, item) => {
    let query = "";

    var matches =
      sender === "FlightNumber"
        ? item.AirlineFlight.match(/(\d+)/)
        : item.AirlineFlight.match(/(\d+)/);

    if (matches) {
      let numbers = matches[0];
      query =
        item.ICAO === undefined && item.IATA === undefined
          ? sender === "FlightNumber"
            ? item.FlightNumber
            : item.AirlineFlight
          : (item.ICAO ? item.ICAO : item.IATA) + numbers;
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          isLoading: !prev.UI.isLoading,
        },
      }));
      const flights = await api.get("/Flight/FlightDetailsExternal", {
        params: { Query: query },
      });
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          isLoading: !prev.UI.isLoading,
        },
      }));

      if (flights.status === 200) {
        setState((prev) => ({
          ...prev,
          APIFlights: flights.data,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          APIFlights: [],
        }));
      }

      let newFlight = {
        GUID: uuid.v4(),
        EventGUID: props.event.GUID,
        AirlineFlight: item.AirlineFlight,
        Airline: item.Airline,
        DepartureDate: props.event.Date,
        Connection: state.ConnectionGUID,
        Deleted: null,
        PrivateFlight: true,
      };
      let parsedDate =
        moment(props.event.Date)
          // .subtract(1, "days")
          .format("YYYY-MM-DD") +
        "T" +
        "08:00:00 AM";
      newFlight.DepartureDate = parsedDate;
      newFlight.ArrivalDate = parsedDate;

      if (state.APIFlights.length === 0) {
        //We don't find anything, create it manually
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            CurrentFlight: newFlight,
          },
        }));

        let flights = JSON.parse(JSON.stringify(state.Flights));
        flights.push(newFlight);

        setState((prev) => ({
          ...prev,
          Flights: flights,
          UI: {
            ...prev.UI,
            Page: "flight",
          },
        }));
        // state.UI.ReloadFlights.set(true); we don't need to call it here
      } else if (state.APIFlights.length === 1) {
        //Only one file, we must go to the details auto
        let selectedFlight = JSON.parse(JSON.stringify(state.APIFlights[0]));

        selectAFlight(
          selectedFlight,
          sender === "FlightNumber" ? "add" : "update"
        );
      } else {
        //We must display the list to the user so he can pick the desired flight
      }
    }
  };

  const searchAirports = async (target, value) => {
    const airports = await api.get(`/Airports/GetAirports?Search=${value}`);
    if (target === "from") {
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          AirportsFrom: airports.data,
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          AirportsTo: airports.data,
        },
      }));
    }
  };

  const searchFBO = async (target, value, obj) => {
    try {
      let query = value;
      if (target === "from") {
        if (obj.From !== null) {
          query = query + ", " + obj.From;
          if (obj.FromAddress !== null) {
            query = query + ", " + obj.FromAddress;
          }
        }
      } else {
        if (obj.To !== null) {
          query = query + ", " + obj.To;
          if (obj.ToAddress !== null) {
            query = query + ", " + obj.ToAddress;
          }
        }
      }
      const fbos = await api.get("/Venues/Venues?Search=" + query);

      if (target === "from") {
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            FromFBO: fbos.data.Response,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            ToFBO: fbos.data.Response,
          },
        }));
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const searchAirlines = async (sender, item) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoadingAirlines: !prev.UI.isLoadingAirlines,
      },
    }));

    const airlines = await api.get(`/Airlines/GetAirlines?Search=${item}`);
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoadingAirlines: prev.UI.isLoadingAirlines,
      },
    }));
    if (sender === "airline") {
      setState((prev) => ({
        ...prev,
        Airlines: airlines.data,
      }));
    } else if (sender === "operatedby") {
      setState((prev) => ({
        ...prev,
        OperatedByAirlines: airlines.data,
      }));
    }
  };

  //Means we select a flight from flightaware api
  const selectAFlight = (item, mode) => {
    console.log(item);
    let record = JSON.parse(JSON.stringify(item));
    let current = JSON.parse(JSON.stringify(state.UI.CurrentFlight));
    current.GUID = mode === "add" ? uuid.v4() : current.GUID;
    current.Connection = state.ConnectionGUID;
    current.Airline = record.Airline;
    current.OperatedBy = record.OperatedBy;
    current.AirlineFlight = record.AirlineFlight;
    current.From = record.From;
    current.FromLatitude = record.FromLatitude;
    current.FromLongitude = record.FromLongitude;
    current.FromAddress = record.FromAddress;
    current.FromLongName = record.FromLongName;
    current.DepartureTimeZone = record.DepartureTimeZone;
    //Put a date close to the event date
    let parsedDate =
      moment(props.event.Date)
        //  .subtract(1, "days")
        .format("YYYY-MM-DD") +
      "T" +
      moment(record.DepartureDate).format("hh:mm:ss");
    current.DepartureDate = parsedDate; //record.DepartureDate;

    current.To = record.To;
    current.ToLatitude = record.ToLatitude;
    current.ToLongitude = record.ToLongitude;
    current.ToAddress = record.ToAddress;
    current.ToLongName = record.ToLongName;
    current.ArrivalTimeZone = record.ArrivalTimeZone;
    let parsedDateArrival =
      moment(props.event.Date)
        //  .subtract(1, "days")
        .format("YYYY-MM-DD") +
      "T" +
      moment(record.ArrivalDate).format("hh:mm:ss");
    current.ArrivalDate = parsedDateArrival; //record.ArrivalDate;
    current.ArrivalNote = "";
    current.Image = record.Image;
    current.Duration = record.Duration;

    current.Deleted = null;

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        CurrentFlight: current,
      },
    }));

    let flights = JSON.parse(JSON.stringify(state.Flights));

    setState((prev) => ({
      ...prev,
      APIFlights: [],
    }));

    if (mode === "add") {
      flights.push(current);

      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          Page: "flight",
        },
      }));
    } else {
      //We have to find the flight and update
      let index = flights.findIndex((x) => x.GUID === current.GUID);
      flights[index] = current;
      // state.UI.Page.set("connection");
    }
    setState((prev) => ({
      ...prev,
      Flights: flights,
    }));

    //  state.UI.ReloadFlights.set(true);
  };

  const SelectFlight = (flight) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        CurrentFlight: flight,
      },
    }));
  };

  const flightScreen = () => {
    let CurrentFlight = state.UI.CurrentFlight;
    CurrentFlight.ToLongName = "";
    let ui = (
      <PrivateFlightForm
        CurrentFlight={CurrentFlight}
        parentState={state}
        setParentState={setState}
        searchAirports={searchAirports}
        searchAirlines={searchAirlines}
        searchFlight={searchFlight}
        deleteFlight={deleteFlight}
        selectAFlight={selectAFlight}
        submitFlight={submitFlight}
        searchFBO={searchFBO}
      />
    );
    return ui;
  };

  const deleteFlight = (item) => {
    let flights = Object.values(state.Flights);
    let flightIndex = flights.findIndex((x) => x.GUID === item.GUID);
    Swal.fire({
      text:
        "Are you sure you want to delete 'Flight no. " +
        (flightIndex + 1) +
        " " +
        item.From +
        " - " +
        item.To +
        "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        setState((prev) => {
          flights.splice(flightIndex, 1);
          return {
            ...prev,
            Flights: flights,
            UI: {
              ...prev.UI,
              CurrentFlight: flights[0],
            },
          };
        });
      }
    });
  };
  const AddStop = () => {
    console.log("STATE BEFORE UPDATED STATE", state);
    //Convert the state to objet to deal with it better
    let flights = Object.values(state.Flights);
    let currentFlight = flights[flights.length - 1]; //Last record
    //Save the changes on the current flight
    let index = flights.findIndex((x) => x.GUID === currentFlight.GUID);
    //  flights[index] = currentFlight;
    //Save previous before change
    //    state.Flights.set(flights);
    //  debugger;
    //Now start updating the info based on the new record information
    let newFlight = { ...currentFlight };
    newFlight.GUID = uuid.v4();
    newFlight.DepartureDate = currentFlight.ArrivalDate;
    newFlight.From = currentFlight.To;
    newFlight.FromAddress = currentFlight.ToAddress;
    newFlight.FromLatitude = currentFlight.ToLatitude;
    newFlight.FromLongitude = currentFlight.ToLongitude;
    newFlight.FromLongName = currentFlight.ToLongName;
    newFlight.Duration = "";
    newFlight.Label = "";
    newFlight.AirlineFlight = currentFlight.AirlineFlight.replace(/[0-9]/g, "");
    newFlight.To = "";
    newFlight.ToAddress = "";
    newFlight.ToLatitude = null;
    newFlight.Connection = flights[0].Connection;
    newFlight.ToLongitude = null;
    newFlight.ToLongName = null;

    flights.push(newFlight);

    setState((prev) => ({
      ...prev,
      Flights: flights,
      UI: {
        ...prev.UI,
        CurrentFlight: newFlight,
      },
    }));
  };

  const submitFlight = (obj) => {
    try {
      //debugger;
      //Convert the state to objet to deal with it better
      let parsedState = state;
      //console.log("the current state", parsedState, obj);
      //debugger;
      //Check if exist in the current state
      if (parsedState.Flights.length > 0) {
        //Find the current flight and start updating it
        let exist = parsedState.Flights.findIndex((x) => x.GUID === obj.GUID);
        if (exist !== -1) {
          console.log(
            "previous flight ",
            parsedState.Flights[exist],
            "new value",
            obj,
            "current flight",
            parsedState.UI.CurrentFlight
          );
          obj.GUID = parsedState.Flights[exist].GUID;
          parsedState.Flights[exist] = obj;
          //    debugger;
          //      parsedState.UI.CurrentFlight = obj;
          setState(parsedState);
          // state.UI.CurrentFlight.set(obj);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  const ToggleAddEditLabel = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowAddEditLabel: !prev.UI.ShowAddEditLabel,
      },
    }));
  };

  const SaveTheFlights = async () => {
    let flights = JSON.parse(JSON.stringify(state.Flights));
    //Check the object of flights
    flights.forEach((flight) => {
      flight.EventGUID = props.event.GUID;
      flight.PrivateFlight = true;
      flight.FlightStaffs = [];
    });
    //Assign the label to the first one
    flights[0].Label = state.UI.Label;

    //Send to the server
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoading: !prev.UI.isLoading,
      },
    }));
    let request = await apiService.post("/flights/AddEditBatch", flights);
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoading: !prev.UI.isLoading,
      },
    }));
    if (props.IsEditing) {
      props.toggle(true);
    } else {
      //  props.toggle();
      toggleMemberModal();
      //Pass to the next screen
    }
  };

  const toggleMemberModal = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowMembersModal: !prev.UI.ShowMembersModal,
      },
    }));
  };
  // const teams = async () => {
  //     let organization = getOrganization();
  //     let teamRecords = (await db.table("organization").where({ GUID: organization.GUID }).first()).Roles;
  //     console.log("TEAMS", teamRecords);
  //     state.UI.Teams.set(teamRecords.filter(x => x.Deleted === null));
  //     return teamRecords;
  // }

  const updatePassenger = (reservation) => {
    let staffs = state.UI.staffSelected.map((staff) => {
      if (staff.StaffGUID === reservation.GUID) {
        staff = { ...staff, ...reservation };
        return staff;
      }
      return staff;
    });
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        staffSelected: staffs,
      },
    }));
  };
  const toggleSelectMember = (staff) => {
    if (staff === undefined) {
      return;
    }
    let selected = JSON.parse(JSON.stringify(state.UI.staffSelected));
    if (selected.length > 0) {
      //Check if exist
      let exist = selected.findIndex((x) => x.StaffGUID === staff.GUID);
      if (exist.length !== -1 && selected[exist] !== undefined) {
        //Exist
        //Must be deleted
        if (selected[exist].Deleted !== null) {
          selected[exist].Deleted = null;
        } else {
          selected[exist].Deleted = moment().format("YYYY-MM-DDThh:mm:ss");
        }
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            staffSelected: selected,
          },
        }));
      } else {
        //dont exist
        let item = {
          StaffGUID: staff.GUID,
          FlightGUID: state.Flights[0].GUID,
          GUID: uuid.v4(),
          Deleted: null,
          ReservationNumber: null,
          SeatNumber: null,
          TicketNumber: null,
        };

        selected.push(item);

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            staffSelected: selected,
          },
        }));
      }
    } else {
      let item = {
        StaffGUID: staff.GUID,
        FlightGUID: state.Flights[0].GUID,
        GUID: uuid.v4(),
        Deleted: null,
        ReservationNumber: null,
        SeatNumber: null,
        TicketNumber: null,
      };

      selected.push(item);

      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          staffSelected: selected,
        },
      }));
    }
    console.log("staff selected", state.UI.staffSelected);
  };

  const saveMembers = async (skip) => {
    try {
      debugger;
      if (skip !== true) {
        let staff = JSON.parse(JSON.stringify(state.UI.staffSelected));
        await api.post("/FlightStaff/AddEditBatch", staff);
      }
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          ShowMembersModal: false,
        },
      }));
      //let flight = JSON.parse(JSON.stringify(state.Flights[0].value));

      globalState.EventsUI.ReloadFlight.set(true);
      dispatch(actions.getFlights(props.event.GUID));
      //props.handleSidebarItem("FLIGHT_LIST", flight);
      props.toggle();
    } catch (ex) {
      console.log(ex);
      debugger;
    }
  };

  const { isChecked } = useBetaFeature();

  return (
    <div className="organization-transparent">
      <ModalComponent
        modal={state.UI.ShowAddEditLabel}
        toggle={() => ToggleAddEditLabel()}
        childeren={
          <AddEditLabel toggle={ToggleAddEditLabel} parentState={state} />
        }
      />

      <ModalComponent
        modal={state.UI.ShowMembersModal}
        toggle={toggleMemberModal}
        childeren={
          <AssignMembersFlight
            privateFlight={true}
            flight={state.Flights[0]}
            eventStaffs={state.UI.Teams}
            toggle={toggleMemberModal}
            setCurrentState={props.setFlights}
            handleSidebarItem={props.handleSidebarItem}
            //   addFlight={addFlight}
            arrayToBeSelected={state.UI.staffSelected}
            stops={state.Flights.filter(
              (x) => x.GUID !== state.Flights[0].GUID
            )}
            toggleMember={toggleSelectMember}
            updatePassenger={updatePassenger}
            saveMembers={saveMembers}
            parentToggle={props.toggle}
            actions={actions}
            //loading={loading}
            edit={false}
            donable
          />
        }
      />

      <div className={`event-page text-dark beta ${!isChecked && "active"}`}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left align-items-center d-flex">
              <h5 className="title">
                <FormattedMessage
                  id="addPrivateFlight"
                  defaultMessage="Add Private Flight"
                />
              </h5>
              {isChecked && <span className="badge badge-info ml-2">Beta</span>}
              {state.Flights.length > 0 && state.Flights[0].Label ? (
                <small className="text-muted">{state.UI.Label}</small>
              ) : null}
            </div>
            <div className="text-right">
              {state.UI.Page === "flight" ? (
                <button
                  className="btn btn-block btn-primary btn-sm"
                  onClick={() => {
                    //We have to add the current flight to the list (first checking that the id does not exist) and then adding a new record

                    ToggleAddEditLabel();
                  }}
                >
                  {state.Flights.length > 0 && state.Flights[0].Label ? (
                    <FormattedMessage
                      id="editLabel"
                      defaultMessage="Edit Label"
                    />
                  ) : (
                    <FormattedMessage
                      id="addLabel"
                      defaultMessage="Add Label"
                    />
                  )}
                </button>
              ) : null}
            </div>
          </div>
          <div className="modal-body p-1 px-0">
            <StopNavigation
              parentState={state}
              SelectFlight={SelectFlight}
              AddStop={AddStop}
              deleteFlight={deleteFlight}
            />

            {state.UI.Page === "flight" ? flightScreen() : null}
          </div>
          {state.UI.Page !== "search" ? (
            <div className="modal-footer text-right">
              <div className="col-12 px-1">
                <button
                  className="btn btn-block btn-primary"
                  readOnly={state.UI.isLoading}
                  type="button"
                  onClick={() => SaveTheFlights()}
                >
                  <FormattedMessage id="done" defaultMessage="Done" /> &amp;
                  Assign
                  {state.UI.isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddEditPrivateFlights;
