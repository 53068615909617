import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import uuid from "uuid";
import db from "../../../common/db";

export function addAttachment(event, attachment, success, refresh) {
  const url = "/Attachment/AddEditForEventWithoutImage";
  return async (dispatch) => {
    dispatch({
      type: constant.ADDING_ATTACHMENT,
    });
    try {
      const fileUploadResponse = await addingFile("", attachment.file);

      await addingAttachment(
        url,
        event,
        { ...attachment, path: fileUploadResponse.data.Response },
        attachment.file
      );

      dispatch({
        type: constant.ADDED_ATTACHMENT_SUCCESS,
      });
      success();
      refresh();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.ADDING_ATTACHMENT_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.ADDING_ATTACHMENT: {
      return {
        ...state,
        loading: true,
        addingAttachment: true,
      };
    }
    case constant.ADDED_ATTACHMENT_SUCCESS:
    case constant.ADDING_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: false,
        addingAttachment: false,
      };
    }
    default:
      return state;
  }
}

function addingAttachment(url, event, attachment) {
  return apiService.post(url, {
    guid: uuid.v4(),
    name: attachment.name,
    note: attachment.note,
    eventGUID: event,
    path: attachment.path,
  });
}

function addingFile(data, file) {
  let bodyData = new FormData();
  bodyData.append("path", file);
  return apiService.post(`/File/Upload/`, bodyData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
