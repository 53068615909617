import React from "react";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import {
  DropDownComponent,
  ToastNotificationSuccess,
  ModalComponent,
} from "../common";
import api, { apiService } from "../../common/api";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
const TemplateItem = (props) => {
  let {
    showTemplateLabel,
    handleCreateTemplate,
    handleAddEditTemplateLabel,
    setCurrentTemplate,
  } = props;
  const intl = useIntl();
  function deleteConfirmation(activity) {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + activity.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        let request = await api.get(
          "/ScheduleTemplates/RemoveTemplate?TemplateGUID=" + activity.GUID
        );
        if (request.data) {
          props.updateTemplate(request.data);
          ToastNotificationSuccess(1, "Template deleted successfully");
        }
      }
    });
  }

  function handleEditTemplateName(template) {
    Swal.fire({
      title: "Edit Template Name",
      input: "text",
      inputValue: template.Name,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save",
    }).then(async (result) => {
      if (result.value) {
        console.log({ template });
        const request = await apiService.post(`/ScheduleTemplate/Edit`, {
          GUID: template.GUID,
          Label: template.Label,
          Name: result.value,
          OrganizationGUID: template.OrganizationGUID,
        });

        if (request.data) {
          props.updateTemplate(request.data);
          ToastNotificationSuccess(1, "Template name updated successfully");
        }
      }
    });
  }

  function handleAddOrEditTemplate() {
    setCurrentTemplate(props.template);
    handleAddEditTemplateLabel();
  }

  return (
    <div className="d-flex align-items-center flex-row template-item card  p-2 shadow-sm my-2">
      <div className="d-flex flex-column">
        {props.template.Label && (
          <span
            onClick={() => {
              setCurrentTemplate(props.template);
              handleAddEditTemplateLabel();
            }}
            class="c-pointer labelContainer text-center p-0 m-0 d-block px-2 py-1"
            title={props.template.Label}
          >
            <p className="text-center text-truncate">{props.template.Label}</p>
          </span>
        )}
        <a onClick={props.onClick} className="text-dark" href="#">
          {props.template.Name}
        </a>
        {props?.template?.CreatedAccount?.Name && (
          <span
            className="text-muted text-truncate"
            style={{ fontSize: "12px" }}
          >{`Created By: ${props.template.CreatedAccount.Name} ${props.template.CreatedAccount.LastName}`}</span>
        )}
      </div>
      <div className="flex-1 d-flex align-items-center justify-content-end">
        {props.template.Created && (
          <p
            className="text-muted text-truncate mr-2"
            style={{ fontSize: "11px" }}
          >
            {moment(props.template.Created).format("MMM DD, YYYY - hh:mm A")}
          </p>
        )}
        <DropDownComponent
          data={[
            {
              delete: false,
              name: (
                <FormattedMessage
                  id="addlabel"
                  defaultMessage={"Edit Template"}
                />
              ),

              trigger: () => handleAddOrEditTemplate(),
            },
            {
              delete: true,
              name: <FormattedMessage id="delete" defaultMessage="Delete" />,

              trigger: () => deleteConfirmation(props.template),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TemplateItem;
