import React from "react";
import { RadioboxBlank, RadioboxMarked } from "mdi-material-ui";
import { Event } from "./event";
import { checkIfEventExists, checkIfEventExistsGroup } from "../../utils";

const SelectableVisibleEvent = (props) => {
 // debugger;
  let { event, toggleEvent, eventsSelected, guidVersion,events } = props;

  console.log({EventExist: checkIfEventExistsGroup(event.GUID, eventsSelected), events, eventsSelected})


  return (
    <div className="p-0 event__selectable relative d-flex align-items-center ">
      <div className="left-icon text-center  cursor ml-2" onClick={() => toggleEvent(event)}>
        {guidVersion ? (
          checkIfEventExistsGroup(event.GUID, eventsSelected) ? (
            <i className="far fa-square uncheck text-dark"></i>
            
             
          ) : (
            <i className="fas fa-check-square text-dark"></i>
           
          )
        ) : checkIfEventExists(event.GUID, eventsSelected) ? (
         <i className="fas fa-check-square text-dark"></i> 
         
        ) : (
             <i className="far fa-square uncheck text-dark"></i>
         
        )}
      </div>
      <div className="d-flex align-items-center flex-1 text-truncate">
        <Event key={event.GUID} event={event} noClickable noIcons />
      </div>
    </div>
  );
};

export default SelectableVisibleEvent;