import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../common/api";

export default function AddEditFolder(props) {
  const [state, setState] = useState({
    Model: {
      Name: props?.Model?.Name,
      GUID: props?.Model?.GUID,
      Type: props?.Model?.Type,
      UserGUID: props?.Model?.UserGUID,
      ParentGUID: props?.Model?.ParentGUID || null,
    },
    IsLoading: false,
  });

  useEffect(() => {
    let focus = document.getElementById("Name");
    if (focus !== null) {
      focus.focus();
      console.log("props state", state);
    }
  }, []);

  const handleSubmit = async () => {
    setState((prev) => ({
      ...prev,
      IsLoading: !prev.IsLoading,
    }));
    if (
      state.Model.ParentGUID !== undefined &&
      state.Model.ParentGUID !== null &&
      state.Model.ParentGUID.length === 0
    ) {
      setState((prev) => ({
        ...prev,
        Model: { ...prev.Model, ParentGUID: null },
      }));
    }
    let copy = JSON.parse(JSON.stringify(state.Model));
    console.log("file to be added", copy, state.Model);

    //We have to sent to api
    const request = await api.post(`/File/AddEditFileWithoutFile`, copy);

    setState((prev) => ({
      ...prev,
      IsLoading: !prev.IsLoading,
    }));
    if (request.data.Status === "ok") {
      props.toggle(request.data.Response);
    }
    //props.toggle();
  };

  const handleNameChange = (e) => {
    const value = e?.target?.value || "";
    setState((prev) => ({
      ...prev,
      Model: { ...prev?.Model, Name: value },
    }));
  };
  const handleLinkChange = (e) => {
    const value = e?.target?.value || "";
    setState((prev) => ({
      ...prev,
      Model: { ...prev?.Model, Path: value },
    }));
  };

  return (
    <div className="">
      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left d-flex w-100 justify-content-between">
              <h1 className="title">Link</h1>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => props.toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row">
              <div className="col-12">
                <label className="d-flex align-items-center">Name</label>
                <input
                  id="Name"
                  type="text"
                  className="form-control"
                  value={state?.Model?.Name}
                  onChange={handleNameChange}
                />
              </div>

              <div className="col-12 my-2">
                <label className="d-flex align-items-center">
                  Paste your URL here
                </label>
                <input
                  id="Link"
                  type="text"
                  className="form-control"
                  value={state?.Model?.Path}
                  onChange={handleLinkChange}
                />
              </div>
              <div className="col-12 text-right">
                {state.IsLoading ? (
                  <div
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    state?.IsLoading === true ||
                    state?.Model?.Name?.length === 0
                  }
                  type="button"
                  onClick={
                    props?.handleLinkEdit
                      ? () => props.handleLinkEdit(state?.Model)
                      : () => handleSubmit()
                  }
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
