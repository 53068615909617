import moment from "moment";
import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../../constant";
import { FormattedMessage } from "react-intl";
import { Table } from "reactstrap";
import { TaskViewTable } from "../../../../components/todo/TaskViewTable";
import { ToastNotificationSuccess } from "../../../../components";
import { useDispatch } from "react-redux";
import { TodoAccordion } from "../../../../components/todo/TodoAccordion";
import { apiService } from "../../../../common/api";
import shareApi from "../../../../common/shareApi";
import { useLocation } from "react-router-dom";

export const TodosView = ({
  loggedIn,
  TodoActions,
  currentWitdh,
  state,
  setParentState,
  setComments,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [localTodos, setLocalTodos] = useState(state.data);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const toggleTask = async (task) => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`);
      return;
    }

    const toggledTask = { ...task, Status: task.Status === 0 ? 1 : 0 };
    // dispatch(TodoActions.toggleTask(toggledTask, async () => {

    await apiService.post("ToDo/AddEditTask", [toggledTask]);
    // }));

    setLocalTodos((prev) => {
      const Tasks = prev.TodoTasks;
      let TaskToUpdate = {};
      Tasks.forEach((todo, index) => {
        if (todo.GUID === task.GUID) {
          TaskToUpdate = { ...todo, index, Status: todo.Status === 0 ? 1 : 0 };
        }
      });
      Tasks[TaskToUpdate.index] = TaskToUpdate;
      const newState = { ...prev, task: { ...prev.task, TodoTasks: Tasks } };
      return newState;
    });
  };

  const todoTaskClean = localTodos.TodoTasks?.filter(
    (todoTask) => todoTask.Deleted === null && todoTask.Status === 0
  );

  useEffect(() => {
    setParentState((prev) => {
      return { ...prev, dataToShare: todoTaskClean };
    });
  }, []);

  const todoTaskCleanCompleted = localTodos.TodoTasks?.filter(
    (todoTask) => todoTask.Deleted === null && todoTask.Status === 1
  );

  useState(() => {
    const refreshTodos = async () => {
      const collabId = location.pathname.replace("/c/", "");
      const collabData = await shareApi.get(`collab/Details?Id=${collabId}`);
      const {
        Data,
        SectionType,
        SectionGUID,
        SharedByAccountGUID,
        Deleted,
        Section,
      } = await collabData?.data?.response;

      const shareRequest = await shareApi.get(
        `collab/ListComments?CollabGUID=${collabId}`
      );

      const comments = (await shareRequest?.data?.Response) || [];

      setComments(comments.sort((a, b) => new Date(b.Date) - new Date(a.Date)));
      setLocalTodos(Data?.filter((data) => data.Deleted === null)?.[0]);
    };

    const refresTodos = setInterval(refreshTodos, 10000);

    return () => {
      clearInterval(refresTodos);
    };
  }, []);

  return (
    <>
      <div className="mb-3">
        <h5 className="font-weight-bold">{state?.data?.Name}</h5>
        {/* <span>{moment(state?.data?.taskDate).format("MMM DD, YYYY")}</span> */}
      </div>

      {todoTaskClean?.length === 0 && todoTaskCleanCompleted?.length === 0 ? (
        <div className="d-flex h-50 flex-column w-100 h-75 align-items-center justify-content-center">
          <img src={IMAGES.TODO_EMPTYSTATE} className="w-50 mb-5" />

          <h6 className="text-muted">
            <FormattedMessage
              id="noTo-DoAddedYet"
              defaultMessage="No To-Do's added yet"
            />
          </h6>
        </div>
      ) : (
        <div style={{ height: "calc(100svh - 310px)", overflowY: "auto" }}>
          <div className="mb-3">
            <div //ref={drag}
            >
              {todoTaskClean?.length > 0 && (
                <Table className="border">
                  <thead>
                    <tr className="list-subheader">
                      <th style={{ fontSize: "14px" }}>
                        <FormattedMessage
                          id="incomplete"
                          defaultMessage="Complete"
                        />
                      </th>
                      <th className="text-center" style={{ fontSize: "14px" }}>
                        Time
                      </th>
                      <th className="text-center" style={{ fontSize: "14px" }}>
                        Title
                      </th>
                      <th className="text-center" style={{ fontSize: "14px" }}>
                        Description
                      </th>
                      {/* <th className="text-center" style={{ fontSize: "14px" }}>
                        Priority
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {todoTaskClean?.map((todo) => {
                      let assignee = todo?.Assignees
                        ? JSON.parse(todo.Assignees)?.[0]
                        : "";

                      return (
                        <TaskViewTable
                          key={todo.GUID}
                          todo={todo}
                          toggleTask={toggleTask}
                          assignee={todo.Assignees}
                          mobile={isMobile}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </div>

          {todoTaskCleanCompleted?.length > 0 ? (
            <>
              <span className="text-muted">COMPLETED</span>
              <TodoAccordion
                todos={todoTaskCleanCompleted}
                toggleTask={toggleTask}
                assignees={state?.data?.TodoAssignees}
              />
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
