import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import moment from "moment";
import db from "../../../common/db";

export function editItinerary(itinerary, success, toggleItem) {
  const url = "/ScheduleMaster/AddEdit";
  const finalItinerary = {
    ...itinerary,
    Date: new Date(itinerary.Date),
  };

  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_ITINERARY,
    });

    let editedItinerary = {};
    try {
      const request = await apiService.post(url, finalItinerary);
      if (request.status === 200 || request.status === 201) {
        const currentSchedule = await db
          .table("schedules")
          .get(request.data.Response.GUID);

        editedItinerary = { ...currentSchedule, ...request.data.Response };

        await db.table("schedules").put(editedItinerary);
      }
      dispatch({
        type: constant.EDITED_ITINERARY,
        data: editedItinerary,
      });
      success();
      toggleItem();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_ITINERARY_ERROR,
        error: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_ITINERARY: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_ITINERARY: {
      const updated = action.data;
      return {
        ...state,
        loading: false,
        schedulesMasters: state.schedulesMasters.map((s) => {
          if (s.GUID === updated.GUID) {
            return { ...updated, Name: updated.Name, Date: updated.Date };
          }

          return s;
        }),
      };
    }
    case constant.EDITING_ITINERARY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
