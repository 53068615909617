import * as Yup from "yup";
import { useIntl, FormattedMessage } from "react-intl";
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
});

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Name is too short")
    .required(
      <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
    ),
  lastName: Yup.string()
    .min(2, "Name is too short")
    .required(
      <FormattedMessage
        id="lastNameRequired"
        defaultMessage="Last Name is required"
      />
    ),
  email: Yup.string()
    .email("Must be a valid email")
    .required(
      <FormattedMessage id="emailRequired" defaultMessage="Email is required" />
    ),
  confirmEmail: Yup.string().oneOf(
    [Yup.ref("email"), null],
    "Email must match"
  ),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
      "Password must have a capital letter and a number."
    )
    .required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
});

export const addListSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Name is too short")
    .required(
      <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
    ),
});

export const socialMediaSchema = Yup.object().shape({
  Facebook: Yup.string().required("Social media is required"),
  Instagram: Yup.string().required("Social media is required"),
  Twitter: Yup.string().required("Social media is required"),
  Youtube: Yup.string().required("Social media is required"),
});

export const changePasswordSchema = Yup.object().shape({
  Password: Yup.string().required("Password is required"),
  NewPassword: Yup.string()
    // .matches(
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
    //   "Password must have a capital letter and a number."
    // )
    .required("New Password is required"),
  ConfirmNewPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword"), null], "Passwords must match")
    .required("Confirm the new password"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be valid")
    .required(
      <FormattedMessage id="emailRequired" defaultMessage="Email is required" />
    ),
  password: Yup.string().required("Password required"),
});

export const broadcastMessageSchema = Yup.object().shape({
  to: Yup.string().required("Message recipient is required").nonNullable(),
  // title: Yup.string()
  //   .required(
  //     <FormattedMessage
  //       id="titleRequired"
  //       defaultMessage="Message title is required"
  //     />
  //   )
  //   .min(2, "Title is too short"),
  channels: Yup.string().required("Please select at least one channel"),
  message: Yup.string()
    .required("Message is required")
    .max(140, "Message is too long"),
});

export const createOrganizationSchema = Yup.object().shape({
  organizationName: Yup.string().required("Organization name is required"),
});

export const createTeamSchema = Yup.object().shape({
  teamName: Yup.string().required("Name of your team is required"),
});

export const customVenueSchema = Yup.object().shape({
  Name: Yup.string().required(
    <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
  ),
  City: Yup.string().required("City is required"),
  Country: Yup.string().required("Country is required"),
  Address: Yup.string().required("Address is required"),
  ZipCode: Yup.string().required("Zip code is required"),
  Capacity: Yup.number().optional().nullable(),
  Type: Yup.string().required("Type is required"),
  otherType: Yup.string().when("Type", {
    is: "Other",
    then: (schema) => schema.required("Type is required"),
    otherwise: (schema) => schema.optional(),
  }),
  State: Yup.string().required("State is required"),
});

export const addActivitySchema = Yup.object().shape({
  Name: Yup.string()
    .max(100, "Maximum 100 characters long")
    .required("Description is required"),
  StartTime: Yup.string().required("Start time is required"),
  StartDate: Yup.string().required("Start date is required"),
});

export const addScheduleMasterSchema = Yup.object().shape({
  Name: Yup.string().required("Title is required"),
  Date: Yup.string().required("Start date is required"),
});

export const addHotelSchema = Yup.object().shape({
  Name: Yup.string().required(
    <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
  ),
});

export const addEventSchema = Yup.object().shape({
  Date: Yup.string().required("Date is required"),
  Name: Yup.string().required(
    <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
  ),
  City: Yup.string().required("City is required"),
  Country: Yup.string().required("Country is required"),
  EventType: Yup.string().required("Event type is required"),
});

export const shortActivitySchema = Yup.object().shape({
  Name: Yup.string().required("Description is required"),
});

export const eventHorizontalSchema = Yup.object().shape({
  City: Yup.string().required("City is required"),
  Country: Yup.string().required("Country is required"),
  Name: Yup.string().required("Name of the event is required"),
});

export const addTaskSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Name is too short")
    .required(
      <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
    ),
});

export const newPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
      "Password must have a capital letter and a number."
    )
    .required("Password is required"),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

export const addTicketsSchema = Yup.object().shape({
  // GuestNames: Yup.string().required("Field is required"),
  RequestedFor: Yup.string().required("Field is required"),
  //RequestedForLastName: Yup.string().required("Field is required"),
  // Passes: Yup.number().required("Provide an amount of passes"),
  // PassType: Yup.string().required("Field is required"),
});

export const editTicketSchema = Yup.object().shape({
  GuestNames: Yup.string().required("Field is required"),
  //RequestedForLastName: Yup.string().required("Field is required"),
  // ConfirmedPasses: Yup.number().required("Provide an amount of passes"),
  // PassType: Yup.string().required("Field is required"),
});

export const compsTicketSchema = Yup.object().shape({
  Comps: Yup.number()
    .min(1, "Must be greater than 0 or empty")
    .optional()
    .nullable(),
  ReturnedComps: Yup.number()
    .min(1, "Must be greater than 0 or empty")
    .optional()
    .nullable(),
});

export const confirmedNoteSchema = Yup.object().shape({
  ConfirmedNote: Yup.string().required("Field is required"),
});

export const addContactSchema = Yup.object().shape({
  Name: Yup.string().required(
    <FormattedMessage id="nameRequired" defaultMessage="Name is required" />
  ),
  LastName: Yup.string().required(
    <FormattedMessage
      id="lastNameRequired"
      defaultMessage="Last Name is required"
    />
  ),
  MobilePhone: Yup.string(),
  Email: Yup.string(),
});
