import { IMAGES } from "./images";
import { useIntl, FormattedMessage } from "react-intl";
//This is going to be used like to select the module to direct the user to.

export const navPaths = {
  NOTIFICATIONS: "NOTIFICATIONS",
  ADD_EVENT: "ADD_EVENT",
  ADD_TODO: "ADD_TODO",
  INVITE_USERS: "INVITE_USERS",
  EXPORT_CALENDAR: "EXPORT_CALENDAR",
  ADD_MEETING: "ADD_MEETING",
  MESSAGES: "MESSAGES",
  BROADCAST: "BROADCAST",
};

//List of "frequent actions"
//DESTINATION IS NOT BEING USED AT ALL, I CHOOSE TO PUT SOME SORT OF CONSTANT TO HANDLE THE VALUE RECEIVED
export const navActions = [
  {
    name: <FormattedMessage id="addEvent" defaultMessage="Add Event" />,
    destination: navPaths.ADD_EVENT,
    icon: IMAGES.ADD_EVENT,

    fontAwesomeIcon: "far fa-calendar-plus",
  },
  {
    name: <FormattedMessage id="exportCalendar" defaultMessage="Export" />,
    destination: navPaths.EXPORT_CALENDAR,
    icon: IMAGES.CALENDER_SVG,
    fontAwesomeIcon: "fas fa-file-export",
  },
  {
    name: <FormattedMessage id="addTodo" defaultMessage="Add To-Do" />,
    destination: navPaths.ADD_TODO,
    icon: IMAGES.ADD_TODO,
    fontAwesomeIcon: "fas fa-clipboard-check",
    //mode : "beta"
  },
  {
    name: <FormattedMessage id="invite" defaultMessage="Invite" />,
    destination: navPaths.INVITE_USERS,
    icon: IMAGES.INVITE_USER,
    fontAwesomeIcon: "fas fa-user-plus",
  },
  {
    name: <FormattedMessage id="broadcastMessage" defaultMessage="Broadcast" />,
    destination: navPaths.BROADCAST,
    // icon: IMAGES.MESSAGES,
    fontAwesomeIcon: "fas fa-broadcast-tower",
  },
  // {
  //   name:<FormattedMessage
  //   id="addMeeting"
  //   defaultMessage="Add Meeting"/> ,
  //   destination: navPaths.ADD_MEETING,
  //   icon: IMAGES.ADD_TODO,
  //  // mode:"beta",
  //   fontAwesomeIcon: "far fa-handshake"
  // },
];
