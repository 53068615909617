import React from "react";
import { FormattedMessage } from "react-intl";
import { extractContentText } from "../../common/helpers";
import { fileIcon } from "../../page/files/helper";
import { DropDownComponent } from "../common";

const AttachmentsItem = (props) => {
  let {
    attachment,
    handleSidebarItem,
    sideBarItemData,
    confirmDeleteAttachment,
  } = props;

  return (
    <div
      className={` px-0 row mx-0 clickableItem attachmentItem__root py-1 ${
        sideBarItemData && sideBarItemData.GUID === attachment.GUID
          ? "active"
          : ""
      }`}
      onClick={() => handleSidebarItem("ATTACHMENT", attachment)}
    >
      <div className="col-auto px-0">
        <button
          className="btn btn-sm text-secondary"
          onClick={() => props.toggleSelect(attachment)}
        >
          {props.checkSelectionStatus(
            props.attachments,
            attachment,
            props.selectedItems
          ) === 0 ? (
            <i className="far fa-square uncheck"></i>
          ) : props.checkSelectionStatus(
              props.attachments,
              attachment,
              props.selectedItems
            ) === 1 ? (
            <i className="fas fa-check-square"></i>
          ) : (
            <i className="fas fa-minus-square"></i>
          )}
        </button>
      </div>
      <div className="col-9 my-1  d-flex align-items-center px-0">
        <div className="d-inline-block mr-2 ">
          <i className={fileIcon(attachment) + " fa-2x"}></i>
          {/* {attachment.Path && attachment.Path.includes("dropbox")
            ? iconPreview(
                attachment.Path && attachment.Path.split("?").pop(),
                attachment
              )
            : iconPreview(
                attachment.Path && attachment.Path.split(".").pop(),
                attachment
              )} */}
        </div>
        <div className="text-truncate">
          <h6 className="text-truncate">
            <a
              target="_blank"
              className="clean-link"
              href={
                (attachment.Path && attachment.Path?.includes("dropbox")) ||
                attachment.Path?.includes("google") ||
                attachment.Path?.includes("http") ||
                attachment.Path?.includes("1drv")
                  ? `${attachment.Path}`
                  : attachment.Path?.includes("pdf")
                  ? `/files/pdf/${attachment.Path}`
                  : `https://service.plnify.com/files/${attachment.Path}`
              }
            >
              {attachment.Name ? attachment.Name : attachment.Path}
            </a>
          </h6>

          {attachment.Note && (
            <div className="text-truncate subTitle">
              {extractContentText(attachment.Note)}
            </div>
          )}
        </div>
      </div>
      <div className="col-2 ml-auto ">
        <DropDownComponent
          className="float-right"
          data={[
            // {
            //   name: "Copy to Other Events",
            //   trigger: () => toggleExport(),
            // },
            // {
            //   name: "Move to another event",
            //   trigger: () => toggleMove(),
            //   border: true,
            // },
            {
              delete: true,
              name: <FormattedMessage id="delete" defaultMessage="Delete" />,
              trigger: () => confirmDeleteAttachment(attachment),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AttachmentsItem;
