import React from "react";
import parse from "html-react-parser";
import { IMAGES } from "../../../constant";

const TermsAndCondition = (props) => {
  let { data, id, back, isElectron } = props;
  return (
    <div className={`terms-bg-light`}>
      {isElectron && (
        <div className="terms-head">
          <div className="close-bg" onClick={() => back()}>
            <img src={IMAGES.CROSS_ICON} width="16" height="16" alt="close" />
          </div>
        </div>
      )}
      {data && parse(data.body)}
    </div>
  );
};

export default TermsAndCondition;
