import * as types from "./constants";
import api from "../../../common/api";

export function guestListByUser(eventGUID, accountGUID) {
  const url = `/Guestlist/General?EventGUID=${eventGUID}&AccountGUID=${accountGUID}`;
  return async (dispatch) => {
    dispatch({
      type: types.SELECT_EVENT_GUID,
    });
    try {
      const passesAndTickets = await fetchingTicketsAndPasses(url);
      dispatch({
        type: types.TICKETS_PASSES_FETCHED,
        data: passesAndTickets.data.Response,
      });
    } catch (error) {
      dispatch({
        type: types.TICKETS_PASSES_FETCHING_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case types.SELECT_EVENT_GUID: {
      return {
        ...state,
        selectedEvent: action.data,
        loadingTickets: true,
      };
    }
    case types.TICKETS_PASSES_FETCHED: {
      const ticketsAndPassesData = action.data;
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: ticketsAndPassesData,
      };
    }
    case types.TICKETS_PASSES_FETCHING_ERROR: {
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: [],
      };
    }
    default:
      return state;
  }
}

function fetchingTicketsAndPasses(url) {
  return api.get(url);
}
