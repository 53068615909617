import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  ModalComponent,
  Loader,
  ToastNotificationSuccess,
  IframeGoogleDoc,
} from "../../../components/common";
import { IMAGES } from "../../../constant";
import RequestPasses from "../../../components/guestList/RequestPasses";
import EditTicketForm from "../../../components/guestList/EditTicketForm";
import TicketFormResponse from "../../../components/guestList/TicketFormResponse";
import RequestDetails from "../../../components/guestList/RequestDetails";
import Comps from "../../../components/guestList/Comps";
import AdminNote from "../../../components/guestList/AdminNote";
import BoxOffice from "../../../components/guestList/BoxOffice";
import GuestItem from "../../../components/guestList/GuestItem";
import InfoBox from "../../../components/guestList/InfoBox";
import SideEvents from "../../../components/event/SidebarEvents";
import {
  cleanDelete,
  getBasicInfo,
  getOrganization,
  guestListStats,
} from "../../../utils";
import GuestListEvents from "../../../components/guestList/GuestListEvents";
import { Cog, Email, CalendarBlank, Export } from "mdi-material-ui";
import { Event } from "../../../components/event/event";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import EmailModal from "../../../components/hotel/emailModal";
import GuestlistSettings from "../../../components/guestList/GuestlistSettings";
import api, { apiService } from "../../../common/api";
import GenerateLabels from "../../../components/guestList/GenerateLabels";
import seatMapIcon from "../../../images/seatMapIcon.png";
import Swal from "sweetalert2";
import { useIntl, FormattedMessage } from "react-intl";
import GuestListPlainView from "./GuestListPlainView";
import { SandwichButton } from "../../../components/common/SandwichButton";
import SharingHub from "../../../components/common/sharingHub";
import uuid from "uuid";
import shareApi from "../../../common/shareApi";
import FilterModal from "../../../components/calendar/FilterModal";
import { handleCloseEmail } from "../../../utils/closeMailModal";
import Draggable from "react-draggable";
import LazyLoadImage from "../../../components/common/LazyLoadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faShare } from "@fortawesome/free-solid-svg-icons";

const GuestList = (props) => {
  const intl = useIntl();
  const [clickToSeeDetails, setclickToSeeDetails] = useState(false);
  const [chooseEvent, setChooseEvent] = useState(false);
  const [emptyStateButton, setEmptyStateButton] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSettings, setDropdownSettings] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const [dropdownExport, setDropdownExport] = useState(false);
  const [showExportLabel, setShowExportLabel] = useState(false);
  const [seatMapDropdown, setSeatMapDropdown] = useState(false);
  const [showPlainListView, setShowPlainListView] = useState(false);

  const [sortBy, setSortBy] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  let {
    selectEvent,
    selectedEvent,
    ticketsAndPasses,
    eventList,
    loadingTickets,
    prevEvent,
    toggleSidebarEvents,
    showEventsSidebar,
    toggleMailModal,
    showEmailModal,
    nextEvent,
    setCurrentEvent,
    deleteElement,
    rejectAll,
    confirmAll,
    updateRequest,
    createTicket,
    toggleModalTicket,
    modalTicket,
    teams,
    ticket,
    toggleEditModal,
    editModal,
    ticketSelected,
    requestSelected,
    setRequestGroup,
    approval,
    toggleResponseModal,
    adminNote,
    toggleAdminNote,
    responseModal,
    tickets,
    updateComps,
    editBoxOffice,
    toggleBoxOffice,
    toggleComps,
    mode,
    setMode,
    continueAndAddNewRequest,
    newRequested,
  } = props;

  const [records, setRecords] = useState(ticketsAndPasses);
  const [searchQuery, setSearchQuery] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showSeatMapPreview, setShowSeatMapPreview] = useState(false);
  const [hideEvents, setHideEvents] = useState();
  const [rawQty, setRawQty] = useState(0);

  useEffect(() => {
    const preferences = JSON.parse(
      localStorage.getItem("GUEST_LIST_PREFERENCE")
    ) || {
      plainListView: false,
      showSideBar: true,
      sortBy: 0,
      filterBy: "",
    };
    setShowPlainListView(preferences.plainListView);
    setHideEvents(preferences.showSideBar);
    setSortBy(preferences.sortBy);
    setFilterQuery(preferences.filterBy);
  }, []);

  const cleanedEvents = eventList
    .filter((event) => event.EventType !== "Day Off")
    .filter((event) => event.EventType !== "Travel Day")
    .filter((event) => event.EventType !== "Travel");
  const stats = guestListStats(tickets);
  const [compsNumber, setCompsNumber] = useState(0);
  const calculateComps = () => {
    try {
      let request = [];
      let count = 0;
      if (ticketsAndPasses.length > 0) {
        ticketsAndPasses.forEach((eachRequest) => {
          request = eachRequest.filter(
            (x) =>
              x.Deleted === null &&
              x.Confirmed === true &&
              x.PassType === "Ticket"
          );
          request.forEach((item) => {
            count += item.ConfirmedPasses;
          });
        });
        setCompsNumber(count);
      }
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };
  const formatedVenueAddress = () => {
    let address = "";
    address =
      selectedEvent?.Venue?.Address !== null && selectedEvent?.Venue?.Address;
    if (selectedEvent?.Venue?.City) {
      address =
        address +
        (address?.length > 0 ? ", " : "") +
        selectedEvent?.Venue?.City;
    }
    if (selectedEvent?.Venue?.State) {
      address =
        address +
        (address?.length > 0 ? ", " : "") +
        selectedEvent?.Venue?.State;
    }

    if (selectedEvent?.Venue?.ZipCode) {
      address =
        address +
        (address?.length > 0 ? ", " : "") +
        selectedEvent?.Venue?.ZipCode;
    }

    if (selectedEvent?.Venue?.Country) {
      address =
        address +
        (address?.length > 0 ? ", " : "") +
        selectedEvent?.Venue?.Country;
    }

    return address;
  };

  const toggleAllowRequest = async () => {
    let allow = props.selectedEvent.AllowUsersToRequestTickets === true;
    let result = await apiService.get(
      "/GuestList/AllowTicketRequest?EventGUID=" +
        props.selectedEvent.GUID +
        "&AllowRequest=" +
        !allow
    );
    if (result.data.Status === "ok") {
      props.selectEvent(result.data.Response);
    }
    // props.eventDetail(props.selectedEvent.GUID);
    // setTimeout(()=>{
    //   props.selectEvent(props.selectedEvent);
    // }, 1500)
  };

  const toggleExportExcel = () => {
    window.open(
      "https://service.plnify.com/api/guestlist/generateexcel?eventGUID=" +
        props.selectedEvent.GUID
    );
  };
  const toggleGenerateLabel = () => {
    setShowExportLabel(!showExportLabel);
  };

  function search(query, filter) {
    try {
      let recordsFiltering = ticketsAndPasses;
      let filt = [];
      query = query.toLowerCase();
      //debugger;
      if (query.length > 1) {
        recordsFiltering.forEach((item) => {
          let filterResults = item.filter(
            (y) =>
              (y.RequestByName &&
                y.RequestByName.toLowerCase().includes(query)) ||
              (y.GuestNames && y.GuestNames.toLowerCase().includes(query)) ||
              (y.GuestLastName && y.GuestLastName.toLowerCase().includes(query))
          );
          if (filterResults.length > 0) {
            filt.push(filterResults);
          }
        });
      } else {
        recordsFiltering.forEach((item) => {
          let filterResults = item;
          if (filterResults.length > 0) {
            filt.push(filterResults);
          }
        });
      }
      setFilterQuery(filter);
      if (filter && filter.length > 0) {
        //debugger;
        let lastFilter = [];
        switch (filter) {
          case "Priority":
            filt.forEach((item) => {
              let filterResults = item.filter((y) => y.Priority);
              if (filterResults.length > 0) {
                lastFilter.push(filterResults);
              }
            });
            //filt = filt.filter(x=>x.Priority);
            break;
          case "Declined":
            filt.forEach((item) => {
              let filterResults = item.filter(
                (y) => y.Confirmed === false && y.ConfirmedPasses === 0
              );
              if (filterResults.length > 0) {
                lastFilter.push(filterResults);
              }
            });
            //   filt = filt.filter(x=>x.Confirmed === false);
            break;
          case "Approved":
            filt.forEach((item) => {
              let filterResults = item.filter((y) => y.Confirmed === true);
              if (filterResults.length > 0) {
                lastFilter.push(filterResults);
              }
            });
            //filt = filt.filter(x=>x.Confirmed === true);
            break;
          case "Pending":
            filt.forEach((item) => {
              let filterResults = item.filter(
                (y) =>
                  !y.Confirmed &&
                  y.ConfirmedDate === null &&
                  y.ConfirmedPasses === null
              );
              if (filterResults.length > 0) {
                lastFilter.push(filterResults);
              }
            });
            //filt = filt.filter(x=>x.Confirmed === true);
            break;
          default:
            break;
        }
        filt = lastFilter;
      }
      setRecords(filt);
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  }

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const onChangeUploadSeatMap = (e) => {
    if (e.target.files.length) {
      const arrFiles = Array.from(e.target.files);
      arrFiles.map(async (file, index) => {
        window.URL.createObjectURL(file);
        let query = "Id=" + props.selectedEvent.GUID + "&filename=" + file.name;

        let bodyData = new FormData();
        bodyData.append("path", file);
        let request = await api.post("guestlist/uploadfile?" + query, bodyData);
        //debugger;
        //props.eventDetail(props.selectedEvent.GUID);
        props.reloadSelectedEvent(request.data);
        //ToastNotificationSuccess(1, "Attachment succesfully created")
      });
    }
  };

  const removeSeatMap = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to remove the seat map for this event",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        let query = "EventGUID=" + props.selectedEvent.GUID;
        let request = await api.get("/GuestList/RemoveSeatingMap?" + query);
        if (request.status === 200) {
          props.reloadSelectedEvent(request.data);
          // this.props.actions.selectEvent(
          //   localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
          // );
        }
        //this.updateRequest(item);
      }
    });
  };

  useEffect(() => {
    if (ticketsAndPasses.length >= 0) {
      const newState = JSON.parse(JSON.stringify(ticketsAndPasses));
      setRecords([...newState]);
      calculateComps();
    }
  }, [ticketsAndPasses]);

  const setGuestListPreference = (key, value) => {
    const preferences = {
      showSideBar: hideEvents,
      sortBy: sortBy,
      filterBy: filterQuery,
      plainListView: showPlainListView,
    };
    preferences[key] = value;
    localStorage.setItem("GUEST_LIST_PREFERENCE", JSON.stringify(preferences));
  };

  const toggleSideEvents = () => {
    setHideEvents((prev) => {
      setGuestListPreference("showSideBar", !prev);
      return !prev;
    });
  };

  const handleSortBy = (e) => {
    setGuestListPreference("sortBy", e.target.value);
    setSortBy(e.target.value);
  };

  const handleFilterBy = (e) => {
    setGuestListPreference("filterBy", e.target.value);
    search(searchQuery, e.target.value);
  };

  const handlePlainView = (e) => {
    setGuestListPreference("plainListView", !showPlainListView);
    setShowPlainListView(!showPlainListView);
  };

  const [guestListScroll, setGuestListScroll] = useState(false);
  const guestListRef = useRef();
  const handleGuestListScroll = () => {
    const element = guestListRef.current;
    const scrollPercentage =
      (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
    const shouldScroll = scrollPercentage > 60;
    if (shouldScroll !== guestListScroll) {
      setGuestListScroll(shouldScroll);
    }
  };

  function flattenArrayOfArrays(arrayOfArrays) {
    return arrayOfArrays.reduce((accumulator, currentArray) => {
      return accumulator.concat(currentArray);
    }, []);
  }

  const handleShareLink = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let permission = 0;

    Swal.fire({
      title: "How many tickets can be requested using this link?",
      input: "number",
      inputLabel: "Enter Tickets Quantity",
      inputPlaceholder: "Ticket Qty",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#03C04A",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Share",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const qty = result.value;
        if (qty < 1) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please add a ticket amount before sharing.",
          });
          return;
        }
        (async () => {
          setRawQty(qty);
          if (!navigator.canShare) return handleShareModal();
          const { response } = (
            await shareApi.post("collab/GenerateLink", {
              GUID: uuid.v4(),
              SectionType: "guestList",
              SectionGUID: selectedEvent.GUID, //example id of the todo
              SharedByAccountGUID: currentUser, // id of the user who shared this
              OrganizationGUID: currentOrganization, //id of the organization
              ParentSectionGUID: selectedEvent.GUID, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 1, // 0 = readonly ; 1= read and write
              Raw: qty,
            })
          ).data;

          navigator.canShare();

          const shareData = {
            title: "Plnify",
            text: "Create your ticket request.",
            url: `${window.location.protocol}//${window.location.host}/c/${response.GUID}`,
          };

          try {
            await navigator.share(shareData);
            // ToastNotificationSuccess(1,"Link shared successfully")
          } catch (err) {
            ToastNotificationSuccess(2, `Error: ${err}`);
          }
        })();
      }
    });
  };

  const iframePreview = (icon, file) => {
    switch (icon) {
      case "jpg":
      case "png":
      case "jpeg":
        return (
          <div className="image-viewer">
            <LazyLoadImage src={`https://service.plnify.com/files/${file}`} />
          </div>
        );
      case "pdf":
        return (
          <IframeGoogleDoc url={`https://service.plnify.com/files/${file}`} />
        );

      case "mp3":
        return (
          <div className="w-100 d-flex flex-column align-items-center justify-content-between">
            {/* <img className="w-25" src={IMAGES.DEFAULT_FILE} /> */}
            <audio controls className="w-100">
              <source
                src={`https://service.plnify.com/files/${file}`}
                type="audio/ogg"
              />
              <source
                src={`https://service.plnify.com/files/${file}`}
                type="audio/mpeg"
              />
            </audio>
          </div>
        );

      default:
        return (
          <IframeGoogleDoc url={`https://service.plnify.com/files/${file}`} />
        );
    }
  };

  const openSeatMapPreview = (item) => {
    console.log({ item });
    return iframePreview(item.split(".").pop(), item);
  };

  const copyToClipboard = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let permission = 0;

    const { response } = (
      await shareApi.post("collab/GenerateLink", {
        GUID: uuid.v4(),
        SectionType: "guestList",
        SectionGUID: selectedEvent.GUID, //example id of the todo
        SharedByAccountGUID: currentUser, // id of the user who shared this
        OrganizationGUID: currentOrganization, //id of the organization
        ParentSectionGUID: selectedEvent.GUID, //sent it null
        Deleted: null, //sent it null
        Updated: null, //sent it null
        Created: null, //sent it null
        PermissionType: permission, // 0 = readonly ; 1= read and write
      })
    ).data;
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/c/${response.GUID}`
    );
    setShowShare((prev) => !prev);
    ToastNotificationSuccess(1, `Link copy to clipboard`);
  };

  const handleShareModal = () => {
    setShowShare((prev) => !prev);
  };

  const handleSeatMapPreview = () => {
    setShowSeatMapPreview((prev) => !prev);
  };

  return !selectedEvent ? (
    <>
      <ModalComponent
        toggle={toggleSidebarEvents}
        modal={showEventsSidebar}
        childeren={
          <SideEvents
            toggle={toggleSidebarEvents}
            events={cleanedEvents}
            selectedEvent={selectedEvent}
            setCurrentEvent={setCurrentEvent}
            renderBy={"guestList"}
            fromGuestList
            noClickable
          />
        }
      />
      <div className="row h-100 d-flex flex-column align-items-center justify-content-center my-4">
        <img
          src={IMAGES.GUESTLIST_EMPTYSTATE}
          className="emptyStateStyle mt-0"
          alt=""
        />
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={() => toggleSidebarEvents()}
        >
          Click to select an event
        </button>
      </div>
    </>
  ) : (
    <>
      <ModalComponent
        modal={showShare}
        toggle={() => handleShareModal()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            // Item={""}
            toggle={() => handleShareModal()}
            // ParentSectionGUID={"guid"}
            // Type="ATTACHMENT"
            // sectionGUID={"attachment.GUID"}

            Type="SHARECOLLAB"
            shareApi={{
              GUID: uuid.v4(),
              SectionType: "guestList",
              SectionGUID: selectedEvent?.GUID, //example id of the todo
              SharedByAccountGUID: getBasicInfo()?.GUID, // id of the user who shared this
              OrganizationGUID: getOrganization()?.GUID, //id of the organization
              ParentSectionGUID: selectedEvent.GUID, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 1, // 0 = readonly ; 1= read and write
              user: getBasicInfo(),
              Raw: rawQty,
            }}
            copyToClipBoard={copyToClipboard}
          />
        }
      />

      <ModalComponent
        toggle={() => toggleEditModal(ticket)}
        modal={editModal}
        childeren={
          <EditTicketForm
            toggle={() => toggleEditModal(ticket)}
            ticket={ticket}
            updateTicket={updateRequest}
            createTicket={createTicket}
            teams={teams}
            loading={loadingTickets}
            mode={mode}
          />
        }
      />

      <ModalComponent
        toggle={() => toggleResponseModal(null)}
        modal={responseModal}
        childeren={
          <TicketFormResponse
            toggle={() => toggleResponseModal(null)}
            ticket={ticket}
            updateRequest={updateRequest}
            teams={teams}
            loading={loadingTickets}
            approval={approval}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleModalTicket()}
        modal={modalTicket}
        childeren={
          <RequestPasses
            teams={teams}
            loading={loadingTickets}
            createTicket={createTicket}
            event={selectedEvent}
            mode={mode}
            continueAndAddNewRequest={continueAndAddNewRequest}
            newRequested={newRequested}
            toggle={() => toggleModalTicket()}
          />
        }
      />
      <ModalComponent
        toggle={() =>
          setclickToSeeDetails((clickToSeeDetails) => !clickToSeeDetails)
        }
        modal={clickToSeeDetails}
        childeren={
          <RequestDetails
            toggle={() =>
              setclickToSeeDetails((clickToSeeDetails) => !clickToSeeDetails)
            }
            requestSelected={requestSelected}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleComps()}
        modal={props.compsModal}
        childeren={
          <Comps
            loading={loadingTickets}
            updateComps={updateComps}
            event={selectedEvent}
            toggle={() => toggleComps()}
          />
        }
      />
      <ModalComponent
        toggle={() => toggleAdminNote({})}
        modal={adminNote}
        childeren={
          <AdminNote
            loading={loadingTickets}
            updateRequest={updateRequest}
            ticket={ticket}
            userNote={props.userNote}
            toggle={() => toggleAdminNote(ticket)}
          />
        }
      />

      <ModalComponent
        toggle={() => toggleBoxOffice()}
        modal={props.boxOfficeModal}
        childeren={
          <BoxOffice
            loading={loadingTickets}
            editBoxOffice={editBoxOffice}
            event={selectedEvent}
            venue={selectedEvent && selectedEvent.Venue}
            toggle={() => toggleBoxOffice()}
          />
        }
      />

      <ModalComponent
        modal={showSeatMapPreview}
        toggle={handleSeatMapPreview}
        childeren={
          <Draggable
            axis="x"
            defaultPosition={{ x: 0, y: 0 }}
            position={null}
            grid={[25, 25]}
            scale={1}
          >
            <div className="viewer-documents d-flex flex-column">
              <div className="viewer-documents-drag"></div>
              {showSeatMapPreview &&
                openSeatMapPreview(props.selectedEvent.SeatingMap)}
            </div>
          </Draggable>
        }
      />

      <ModalComponent
        toggle={toggleSidebarEvents}
        modal={showEventsSidebar}
        childeren={
          <SideEvents
            toggle={toggleSidebarEvents}
            events={cleanedEvents}
            selectedEvent={selectedEvent}
            setCurrentEvent={setCurrentEvent}
            renderBy={"guestList"}
            fromGuestList
            noClickable
          />
        }
      />
      <ModalComponent
        modal={showEmailModal}
        toggle={() => handleCloseEmail(toggleMailModal)}
        childeren={
          <EmailModal
            //EventDetails
            //   sendEmail={sendEmail}
            fromSection="GUESTLIST"
            event={selectedEvent}
            selectedEvent={props.selectedEvent}
            toggle={() => toggleMailModal()}
          />
        }
      />

      <ModalComponent
        modal={showSettings}
        toggle={toggleSettings}
        childeren={
          <GuestlistSettings
            event={selectedEvent}
            selectedEvent={props.selectedEvent}
            toggle={toggleSettings}
          />
        }
      />

      <ModalComponent
        modal={showExportLabel}
        toggle={toggleGenerateLabel}
        childeren={
          <GenerateLabels
            event={selectedEvent}
            records={props.tickets}
            //selectedEvent={props.selectedEvent}
            toggle={toggleGenerateLabel}
          />
        }
      />

      <div className="main-container guestList">
        <div className="main-with-header">
          <div className="page-header">
            <div className="d-flex">
              <SandwichButton
                expanded={props.expanded}
                showExpanded={props.showExpanded}
              />

              <div className="main-text">
                <h1>
                  <FormattedMessage
                    id="guestlist"
                    defaultMessage="Guest list"
                  />
                </h1>
              </div>
            </div>
            <div className="content-right formAlternativeStyle">
              {showPlainListView ? (
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon2">
                      Sort by:{" "}
                    </span>
                  </div>
                  <select
                    className="custom-select form-control-sm"
                    value={sortBy}
                    onChange={handleSortBy}
                  >
                    <option value="0">-</option>
                    <option value="1">Request Date (Recent to Old)</option>
                    <option value="2">Request Date (Old to Recent)</option>
                    <option value="3">Guest Name (A-Z)</option>
                    <option value="4">Guest Name (Z-A)</option>
                  </select>
                </div>
              ) : null}
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={handlePlainView}
                style={{ height: "31px" }}
              >
                {showPlainListView === true ? (
                  <i className="fa fa-users"></i>
                ) : (
                  <i className="fa fa-list"></i>
                )}
              </button>
              {/* <button
                className="btn btn-primary btn-sm  "
                type="button"
                style={{ height: "31px" }}
                onClick={() => toggleSidebarEvents()}
                //style={{ padding: "3px 7px", borderRadius: 0 }}
              >
                <CalendarBlank
                  htmlColor={showEventsSidebar ? "#00a2d4" : "#fff"}
                />
              </button> */}
              <div className="btn-group ">
                <button
                  className="btn btn-primary btn-sm mx-0 d-none"
                  onClick={() => prevEvent()}
                >
                  <img
                    src={IMAGES.ARROW_LEFT}
                    alt="dropdown"
                    width="16"
                    height="16"
                  />
                </button>

                <button
                  className="btn btn-primary btn-sm mx-0 d-none "
                  onClick={() => nextEvent()}
                >
                  <img
                    src={IMAGES.ARROW_RIGHT}
                    alt="dropdown"
                    width="16"
                    height="16"
                  />
                </button>
              </div>
              <input
                type="text"
                style={{ height: "31px" }}
                placeholder={intl.formatMessage({ id: "Search by name" })}
                // placeholder={<FormattedMessage
                //   id="searchByName"
                //   defaultMessage="Search by name"/>}
                className="mx-2 form-control form-control-sm py-1"
                value={searchQuery ? searchQuery : ""}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  search(searchQuery);
                }}
              />
              <select
                className="form-control  form-control-sm"
                style={{ height: "31px" }}
                value={filterQuery ? filterQuery : ""}
                onChange={handleFilterBy}
              >
                <option value="">
                  {intl.formatMessage({ id: "filterBy" })}
                </option>
                <option value="Priority">
                  {intl.formatMessage({ id: "Priority" })}
                </option>
                <option value="Approved">
                  {intl.formatMessage({ id: "Approved" })}
                </option>
                <option value="Declined">
                  {intl.formatMessage({ id: "Declined" })}
                </option>
                <option value="Pending">
                  {intl.formatMessage({ id: "Pending" })}
                </option>
                <option value="">----</option>
                <option value="">
                  {intl.formatMessage({ id: "Reset Filter" })}
                </option>
              </select>

              <Dropdown
                className="d-block"
                isOpen={dropdownExport}
                toggle={() => setDropdownExport(!dropdownExport)}
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownExport}
                >
                  <button
                    type="button"
                    title="Export"
                    className="btn btn-sm mailBtn  mx-2"
                    style={{ height: "31px" }}
                  >
                    {/* <Export htmlColor="#fff" /> */}
                    <i className="fa fa-file-export text-white"></i>
                  </button>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => {
                      toggleMailModal();
                      setMode(true);
                    }}
                  >
                    {intl.formatMessage({
                      id: "Export For Box Office",
                      defaultMessage: "Export For Box Office",
                    })}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      toggleExportExcel();
                      setMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: "Export to Excel" })}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      toggleGenerateLabel();
                      setMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: "Print Labels" })}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                className="d-block"
                isOpen={dropdownSettings}
                toggle={() => setDropdownSettings(!dropdownSettings)}
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownSettings}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-primary "
                    style={{ height: "31px" }}
                  >
                    <Cog htmlColor="#fff" />
                  </button>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => {
                      toggleAllowRequest();
                      setMode(true);
                    }}
                  >
                    {props.selectedEvent.AllowUsersToRequestTickets === null ||
                    props.selectedEvent.AllowUsersToRequestTickets ? (
                      <FormattedMessage
                        id="disableTicketRequest"
                        defaultMessage="Disable Ticket Request"
                      />
                    ) : (
                      <FormattedMessage
                        id="enableTicketRequest"
                        defaultMessage="Enable Ticket Request"
                      />
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="main-first-container p-0 flex-column justify-content-start">
            <div className="row customToolbar d-flex align-items-center p-0">
              <div className="col-8 mx-0 px-0">
                {selectedEvent !== null && selectedEvent !== undefined ? (
                  <Event
                    key={selectedEvent.GUID}
                    event={selectedEvent}
                    noIcons
                  />
                ) : null}
              </div>
              <div className="col-4 text-right">
                {props.selectedEvent.AllowUsersToRequestTickets === false ? (
                  <span className="badge bg-danger text-light">
                    <FormattedMessage
                      id="ticketDisabled"
                      defaultMessage="Ticket Request Disabled"
                    />
                  </span>
                ) : (
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      className="btn btn-primary btn-sm mr-2"
                      onClick={handleShareLink}
                    >
                      {isMobile ? (
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                      ) : (
                        <span className="text-white">Share Link</span>
                      )}
                    </button>
                    <Dropdown
                      className="text-right d-block"
                      isOpen={dropdownOpen}
                      toggle={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            toggleModalTicket();
                            setMode(true);
                          }}
                        >
                          <FormattedMessage
                            id="tickets"
                            defaultMessage="Tickets"
                          />
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            toggleModalTicket();
                            setMode(false);
                          }}
                        >
                          <FormattedMessage
                            id="pases"
                            defaultMessage="Passes"
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>

            <div
              className={
                isMobile
                  ? "d-flex p-2 flex-column justify-content-between"
                  : "d-flex p-2"
              }
              style={
                isMobile
                  ? { height: "calc(100vh - 140px)" }
                  : { height: "calc(100vh - 150px)" }
              }
            >
              <div
                className={
                  hideEvents && !isMobile
                    ? "event-sidebar-container"
                    : "event-sidebar-container_expanded rounded-lg shadow-lg bg-white mr-4 overflow-hidden"
                }
                style={
                  isMobile ? { width: "100%", height: "20%" } : { width: "20%" }
                }
              >
                <SideEvents
                  eventConatinerClass="w-100 d-flex flex-column"
                  eventSecondContainerClass=""
                  eventThirdContainerClass=""
                  eventHeadContainerClass="d-flex justify-content-between align-items-center p-2 border-bottom"
                  eventContent="bg-white"
                  scrollbarsStyles={
                    isMobile
                      ? { height: "calc(20vh - 75px)" }
                      : { height: "calc(100vh - 210px)" }
                  }
                  toggle={toggleSidebarEvents}
                  events={cleanedEvents}
                  selectedEvent={selectedEvent}
                  setCurrentEvent={setCurrentEvent}
                  hideSearch
                  toggleSideEvents={toggleSideEvents}
                  fromGuestList
                  isMobile={isMobile}
                  renderBy={"guestList"}
                  noClickable
                />
              </div>

              <div
                className="d-flex flex-column rounded-lg shadow-lg bg-white overflow-hidden"
                style={
                  hideEvents && !isMobile
                    ? { width: "100%" }
                    : isMobile
                    ? { width: "100%", height: "78%" }
                    : { width: "78.5%" }
                }
              >
                <div className="row">
                  <div className="col-lg-3 col-md-3 pr-1">
                    {hideEvents && !isMobile && (
                      <button
                        title="Show Events"
                        className="btn btn-primary border-0 position-absolute event-sidebar-btn"
                        onClick={toggleSideEvents}
                        style={{ zIndex: "50", left: "-20px", top: "72px" }}
                      >
                        <i
                          className="fas fa-angle-right"
                          style={{ marginLeft: "13px" }}
                        ></i>
                      </button>
                    )}
                    <InfoBox
                      title={
                        <FormattedMessage
                          id="ticketsRequested"
                          defaultMessage="Tickets Requested"
                        />
                      }
                      children={
                        <div className="mt-1">
                          <div className="row mx-0">
                            <div className=" row col-6 mx-0 px-0  text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Normal"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className="d-inline ">
                                  <FormattedMessage
                                    id="totalReq"
                                    defaultMessage="Total Req"
                                  />
                                  :&nbsp;
                                  {stats.ticketsStats.total}
                                </span>
                              </div>
                            </div>

                            <div className="row col-6 mx-0 px-0  text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Tentative"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className="d-inline ">
                                  {" "}
                                  <FormattedMessage
                                    id="pending"
                                    defaultMessage="Pending"
                                  />
                                  :&nbsp;{stats.ticketsStats.pending}{" "}
                                </span>
                              </div>
                            </div>

                            <div className="row col-6 mx-0 px-0">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Confirmed"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className=" d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="approved"
                                    defaultMessage="Approved"
                                  />
                                  :&nbsp;{stats.ticketsStats.approved}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="row col-6 mx-0 px-0 text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Cancelled"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className=" d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="declined"
                                    defaultMessage="Declined"
                                  />
                                  :&nbsp;
                                  {stats.ticketsStats.rejected}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 px-1">
                    <InfoBox
                      title={
                        <FormattedMessage
                          id="passesRequested"
                          defaultMessage="Passes Requested"
                        />
                      }
                      children={
                        <div className="mt-1">
                          <div className="row mx-0">
                            <div className=" row col-6 mx-0 px-0  text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Normal"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className="  d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="totalReq"
                                    defaultMessage="Total Req"
                                  />
                                  :&nbsp;
                                  {stats.passesStats.total}
                                </span>
                              </div>
                            </div>

                            <div className="row col-6 mx-0 px-0  text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Tentative"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className=" d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="pending"
                                    defaultMessage="Pending"
                                  />
                                  :&nbsp;{stats.passesStats.pending}{" "}
                                </span>
                              </div>
                            </div>

                            <div className="row col-6 mx-0 px-0">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Confirmed"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className=" d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="approved"
                                    defaultMessage="Approved"
                                  />
                                  :&nbsp;{stats.passesStats.approved}
                                </span>
                              </div>
                            </div>
                            <div className="row col-6 mx-0 px-0 text-truncate">
                              <div className="col-auto px-1">
                                <i className="fa fa-square Cancelled"></i>
                              </div>
                              <div className="col-auto subTitle text-truncate px-0">
                                <span className=" d-none d-md-none d-lg-block">
                                  <FormattedMessage
                                    id="declined"
                                    defaultMessage="Declined"
                                  />
                                  :&nbsp;{stats.passesStats.rejected}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 px-1">
                    <div onClick={() => toggleComps()} className="clickable">
                      <InfoBox
                        title={
                          <FormattedMessage
                            id="ticketsComps"
                            defaultMessage="Comp Amount"
                          />
                        }
                        children={
                          <div className="mt-1">
                            <div className="row mx-0">
                              <div className="w-100 mx-0 px-0 py-1  text-truncate">
                                <span className="subTitle text-truncate">
                                  {selectedEvent.CompsCount !== null &&
                                  selectedEvent.CompsCount > 0 ? (
                                    <div className="d-flex flex-column">
                                      <span>
                                        Remaining Comps:{" "}
                                        {compsNumber > 0
                                          ? selectedEvent.CompsCount -
                                            compsNumber
                                          : selectedEvent.CompsCount}
                                        <small className="text-muted">
                                          {" "}
                                          out of {selectedEvent.CompsCount}
                                        </small>
                                      </span>
                                      <span className="text-danger">
                                        {`Returned Comps: ${
                                          selectedEvent.ReturnedCompsCount ?? 0
                                        }`}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="p-0">
                                      <span>-</span>
                                      <p className="p-0 m-0">&nbsp;</p>
                                    </div>
                                  )}
                                </span>
                              </div>
                              {compsNumber === selectedEvent.CompsCount && (
                                <div className="row col-12 mx-0 px-0  text-truncate">
                                  <br />
                                  <small className="text-muted">
                                    Insufficient Comps Available
                                  </small>
                                </div>
                              )}
                            </div>
                            <div className="row mx-0"></div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 px-1">
                    <div
                      onClick={() => toggleBoxOffice()}
                      className="clickable"
                    >
                      <InfoBox
                        title={
                          <FormattedMessage
                            id="boxOffice"
                            defaultMessage="Box Office Information"
                          />
                        }
                        children={
                          <div className="mt-1 py-1">
                            <div className="row mx-0">
                              <div className="row col-12 mx-0 px-0  text-truncate">
                                <span className="subTitle text-truncate">
                                  {
                                    <FormattedMessage
                                      id="Venue"
                                      defaultMessage="Venue"
                                    />
                                  }
                                  : {selectedEvent?.Venue?.Name}
                                </span>
                              </div>
                              <div className="row col-12 mx-0 px-0  text-truncate">
                                <span className="subTitle">
                                  {
                                    <FormattedMessage
                                      id="Address"
                                      defaultMessage="Address"
                                    />
                                  }
                                  :{" "}
                                  {selectedEvent.WillCallAddress
                                    ? selectedEvent.WillCallAddress
                                    : formatedVenueAddress()}
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-1 pl-1">
                    <div
                      className="card shadow-sm my-2 clickable"
                      style={{ height: "72.8px" }}
                    >
                      <div className="card-body p-0 ">
                        {props.selectedEvent.SeatingMap ? (
                          <>
                            <span
                              className="badge bg-warning text-dark floatTopRight"
                              onClick={() => {
                                removeSeatMap();
                              }}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </span>
                            <div
                              className="text-center mt-1"
                              onClick={() => {
                                handleSeatMapPreview();
                                // window.open(
                                //   "https://api.plnify.com/files/" +
                                //     props.selectedEvent.SeatingMap
                                // );
                              }}
                            >
                              <img
                                src={seatMapIcon}
                                alt="Click to Open"
                                className="img-fluid"
                              />
                              <br />
                              <small className="pb-2">Seating Map</small>
                            </div>
                          </>
                        ) : (
                          <span className="btn btn-block btn-file px-0">
                            <img
                              src={seatMapIcon}
                              alt="Upload seat map"
                              className="img-fluid"
                            />
                            <br />
                            <small className="pb-2">Seat Map</small>
                            <input
                              className=""
                              onChange={onChangeUploadSeatMap}
                              id="inputFiles"
                              type="file"
                              multiple
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row flex-1 overflow-auto`}
                  onScroll={handleGuestListScroll}
                  ref={guestListRef}
                >
                  <div className="col-12">
                    {chooseEvent && (
                      <GuestListEvents
                        toggle={() =>
                          setChooseEvent((chooseEvent) => !chooseEvent)
                        }
                        events={cleanDelete(cleanedEvents)}
                        selectEvent={selectEvent}
                      />
                    )}

                    {records.length === 0 ? (
                      <div className="row h-100 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={IMAGES.GUESTLIST_EMPTYSTATE}
                          className="emptyStateStyle mt-0"
                        />
                        <ModalComponent
                          modal={emptyStateButton}
                          toggle={() => setEmptyStateButton(!emptyStateButton)}
                          childeren={
                            <div className="vehicleModal__root">
                              <div
                                className="card shadow-sm my-2 p-1 c-pointer text-dark"
                                onClick={() => {
                                  toggleModalTicket();
                                  setMode(true);
                                  setEmptyStateButton(!emptyStateButton);
                                }}
                              >
                                Tickets
                              </div>
                              <div
                                className="card shadow-sm my-2 p-1 c-pointer text-dark"
                                onClick={() => {
                                  toggleModalTicket();
                                  setMode(false);
                                  setEmptyStateButton(!emptyStateButton);
                                }}
                              >
                                Passes
                              </div>
                            </div>
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          onClick={() => setEmptyStateButton(!emptyStateButton)}
                        >
                          <FormattedMessage
                            id="CreateYourFirstRequest"
                            defaultMessage="Create your first request"
                          />
                        </button>
                      </div>
                    ) : showPlainListView && records ? (
                      <GuestListPlainView
                        records={
                          records &&
                          flattenArrayOfArrays(records).filter(
                            (x) => x.Deleted === null
                          )
                        }
                        deleteElement={deleteElement}
                        toggleEditModal={toggleEditModal}
                        ticket={ticket}
                        ticketSelected={ticketSelected}
                        setRequestGroup={setRequestGroup}
                        toggleResponseModal={toggleResponseModal}
                        toggleAdminNote={toggleAdminNote}
                        toggleModalTicket={toggleModalTicket}
                        confirmAll={confirmAll}
                        rejectAll={rejectAll}
                        guestListScroll={guestListScroll}
                        sortBy={sortBy}
                      />
                    ) : (
                      records.map((item, index) => (
                        <div
                          key={index + item[0].GUID}
                          className="card shadow-sm my-3"
                        >
                          <div
                            className="card-body  p-0 table-responsive"
                            style={{ overflow: "unset" }}
                          >
                            <table
                              className="table mb-0"
                              style={{ overflow: "unset" }}
                            >
                              <tbody>
                                <GuestItem
                                  deleteElement={deleteElement}
                                  rejectAll={rejectAll}
                                  confirmAll={confirmAll}
                                  requests={item}
                                  clickToSeeDetails={setclickToSeeDetails}
                                  updateRequest={updateRequest}
                                  toggleEditModal={toggleEditModal}
                                  ticket={ticket}
                                  ticketSelected={ticketSelected}
                                  setRequestGroup={setRequestGroup}
                                  toggleResponseModal={toggleResponseModal}
                                  toggleAdminNote={toggleAdminNote}
                                  toggleModalTicket={toggleModalTicket}
                                  setMode={setMode}
                                  guestListScroll={guestListScroll}
                                />
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestList;
