import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteHotel(hotel, success) {
  const url = "/Hotel/AddEdit";

  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_HOTEL,
    });
    try {
      hotel.Deleted = new Date();
      await db.table("hotels").put(hotel);
    //  await db.table("hotels").delete(hotel.GUID);
      dispatch({
        type: constant.DELETED_HOTEL_SUCCESS,
        data: hotel,
      });
     let request= await api.post(url, {
        ...hotel,
        Deleted: new Date(),
        HotelReservations: null,
      });
      if(request.data){
        await db.table("hotels").put(request.data);
      }
      
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.DELETING_HOTEL_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_HOTEL: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_HOTEL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.DELETING_HOTEL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
