import React from "react";

import DatePicker from "react-datepicker";
import moment from "moment";

import { Formik, Form, ErrorMessage } from "formik";

import ReactQuill from "react-quill";
import { DropDownList } from "../common";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";

const ComercialFlightForm = (props) => {
  const intl = useIntl();
  const state = props.parentState;
  const {
    searchAirlines,
    searchAirports,
    searchFlight,
    CurrentFlight,
    selectAFlight,
    submitFlight,
    setParentState,
  } = props;

  console.log("the flight ", props, state.CurrentFlight);
  return (
    <Formik
      onSubmit={submitFlight}
      enableReinitialize
      initialValues={CurrentFlight}
    >
      {(formikValues) => {
        console.log("formikValues of the flight", formikValues);
        return (
          <div className="formContainer">
            <div className="formContainer-body">
              <Form autoComplete="off" className="row">
                <div className="col-6 my-2">
                  <label htmlFor="Airline">
                    <FormattedMessage id="airline" defaultMessage="Airline" />
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    value={formikValues.values.Airline}
                    onChange={async (e) => {
                      formikValues.setFieldValue("Airline", e.target.value);
                      formikValues.submitForm();
                      if (e.target.value.length >= 3) {
                        await searchAirlines("airline", e.target.value);
                      } else {
                        // state.Airlines.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            Airlines: [],
                          };
                        });
                      }
                    }}
                  />
                  <DropDownList
                    searchList={state.Airlines}
                    displayValue={"Name"}
                    setData={(i) => {
                      let selectedElement = state.Airlines[i];
                      formikValues.setFieldValue(
                        "Airline",
                        selectedElement.Name
                      );
                      formikValues.setFieldValue("ICAO", selectedElement.ICAO);
                      formikValues.setFieldValue("IATA", selectedElement.IATA);
                      formikValues.setFieldValue(
                        "AirlineFlight",
                        selectedElement.IATA
                          ? selectedElement.IATA
                          : selectedElement.ICAO
                      );
                      formikValues.submitForm();
                      //   state.Airlines.set([]);
                      setParentState((prev) => {
                        return {
                          ...prev,
                          Airlines: [],
                        };
                      });

                      setTimeout(() => {
                        document.getElementById("AirlineFlight").focus();
                      }, 500);
                    }}
                  />
                </div>
                <div className="col-6 my-2">
                  <label htmlFor="AirlineFlight">
                    <FormattedMessage
                      id="flightNumber"
                      defaultMessage="Flight Number"
                    />
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      id="AirlineFlight"
                      value={formikValues.values.AirlineFlight}
                      onChange={(e) =>
                        formikValues.setFieldValue(
                          "AirlineFlight",
                          e.target.value
                        )
                      }
                      onBlur={() => {
                        setTimeout(() => {
                          //   state.APIFlights.set([]);

                          setParentState((prev) => {
                            return {
                              ...prev,
                              APIFlights: [],
                            };
                          });
                        }, 1500);
                      }}
                    />

                    <DropDownList
                      searchList={state.APIFlights}
                      displayValue={"Name"}
                      Sender="AirlineFlightNumber"
                      setData={(i) => {
                        let selectedElement = state.APIFlights[i];
                        selectAFlight(selectedElement, "update");

                        // state.APIFlights.set([]);

                        setParentState((prev) => {
                          return {
                            ...prev,
                            APIFlights: [],
                          };
                        });

                        setTimeout(() => {
                          document.getElementById("AirlineFlight").focus();
                        }, 100);
                      }}
                    />

                    <button
                      className="btn btn-sm btn-primary"
                      type="button"
                      onClick={() => {
                        let item = formikValues.values;
                        searchFlight("AirlineFlight", item, false);
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>

                <div className="col-6 my-2">
                  <label htmlFor="OperatedBy">
                    <FormattedMessage
                      id="Operated By"
                      defaultMessage="Operated By"
                    />
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    //value={state.UI.CurrentFlight.OperatedBy.value}
                    value={formikValues.values.OperatedBy}
                    onChange={async (e) => {
                      formikValues.setFieldValue("OperatedBy", e.target.value);
                      formikValues.submitForm();
                      //state.UI.CurrentFlight.OperatedBy.set(e.target.value);
                      if (e.target.value.length >= 3) {
                        await searchAirlines("operatedby", e.target.value);
                      } else {
                        // state.OperatedByAirlines.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            OperatedByAirlines: [],
                          };
                        });
                      }
                    }}
                  />

                  <DropDownList
                    searchList={state.OperatedByAirlines}
                    displayValue={"Name"}
                    setData={(i) => {
                      let selectedElement = state.OperatedByAirlines[i];
                      formikValues.setFieldValue(
                        "OperatedBy",
                        selectedElement.Name
                      );
                      formikValues.setFieldValue("ICAO", selectedElement.ICAO);
                      formikValues.setFieldValue("IATA", selectedElement.IATA);
                      formikValues.setFieldValue(
                        "AirlineFlight",
                        selectedElement.IATA
                          ? selectedElement.IATA
                          : selectedElement.ICAO
                      );

                      formikValues.submitForm();
                      // state.UI.CurrentFlight.OperatedBy.set(selectedElement.Name);
                      // state.UI.CurrentFlight.ICAO.set(selectedElement.ICAO);
                      // state.UI.CurrentFlight.IATA.set(selectedElement.IATA);
                      // state.UI.CurrentFlight.AirlineFlight.set(selectedElement.IATA ? selectedElement.IATA : selectedElement.ICAO);
                      //   state.OperatedByAirlines.set([]);

                      setParentState((prev) => {
                        return {
                          ...prev,
                          OperatedByAirlines: [],
                        };
                      });

                      setTimeout(() => {
                        document.getElementById("AirlineFlight").focus();
                      }, 100);
                    }}
                  />
                </div>

                <div className="col-12 bgTitleModal py-1 mt-2">
                  <h3>
                    <FormattedMessage
                      id="Flight Information"
                      defaultMessage="Flight Information"
                    />
                  </h3>
                </div>
                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="Departure Airport"
                      defaultMessage="Departure Airport"
                    />
                    {formikValues.values.From &&
                      formikValues.values.From.length > 0 && (
                        <i
                          className="ml-2 fas fa-info-circle text-dark"
                          title={formikValues.values.FromLongName}
                        ></i>
                      )}
                  </label>
                  <input
                    type="text"
                    // Name="From"
                    value={formikValues.values.From}
                    className="form-control"
                    onChange={(e) => {
                      formikValues.setFieldValue(
                        "From",
                        e.target.value.toUpperCase()
                      );
                      if (e.target.value.length >= 3) {
                        searchAirports("from", e.target.value);
                      } else {
                        // state.UI.AirportsFrom.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: {
                              ...prev.UI,
                              AirportsFrom: [],
                            },
                          };
                        });
                      }
                      formikValues.submitForm();
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        // state.UI.AirportsFrom.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            APIFlights: [],
                            UI: {
                              ...prev.UI,
                              AirportsFrom: [],
                            },
                          };
                        });
                      }, 1500);
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="From"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.AirportsFrom}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = JSON.parse(
                        JSON.stringify(state.UI.AirportsFrom)
                      );
                      formikValues.setFieldValue("From", airports[i].IATA);
                      formikValues.setFieldValue(
                        "FromLongName",
                        airports[i].IATA
                      );
                      debugger;
                      let fullAddress =
                        airports[i].City + ", " + airports[i].Country;
                      formikValues.setFieldValue("FromAddress", fullAddress);
                      formikValues.setFieldValue(
                        "FromLatitude",
                        airports[i].Latitude
                      );
                      formikValues.setFieldValue(
                        "FromLongitude",
                        airports[i].Longitude
                      );

                      formikValues.submitForm();
                      //   state.UI.AirportsFrom.set([]);

                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: {
                            ...prev.UI,
                            AirportsFrom: [],
                          },
                        };
                      });
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label className="d-flex align-items-center">
                    <FormattedMessage
                      id="Arrival Airport"
                      defaultMessage="Arrival Airport"
                    />
                    {formikValues.values.ToLongName &&
                      formikValues.values.ToLongName.length > 0 && (
                        <i
                          className="ml-2 fas fa-info-circle text-dark"
                          title={formikValues.values.ToLongName}
                        ></i>
                      )}
                  </label>
                  <input
                    type="text"
                    value={formikValues.values.To}
                    className="form-control"
                    onChange={(e) => {
                      formikValues.setFieldValue(
                        "To",
                        e.target.value.toUpperCase()
                      );
                      //state.UI.CurrentFlight.To.set(e.target.value.toUpperCase());

                      formikValues.submitForm();
                      if (e.target.value.length >= 3) {
                        searchAirports("to", e.target.value);
                      } else {
                        // state.UI.AirportsTo.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: {
                              ...prev.UI,
                              AirportsTo: [],
                            },
                          };
                        });
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        // state.UI.AirportsTo.set([]);
                        setParentState((prev) => {
                          return {
                            ...prev,
                            UI: {
                              ...prev.UI,
                              AirportsTo: [],
                            },
                          };
                        });
                      }, 1500);
                    }}
                  />

                  <ErrorMessage
                    className="error-message"
                    name="To"
                    component="div"
                  />
                  <DropDownList
                    searchList={state.UI.AirportsTo}
                    displayValue={"Name"}
                    setData={(i) => {
                      let airports = JSON.parse(
                        JSON.stringify(state.UI.AirportsTo)
                      );

                      formikValues.setFieldValue("To", airports[i].IATA);
                      formikValues.setFieldValue(
                        "ToLongName",
                        airports[i].Name
                      );
                      let fullAddress =
                        airports[i].City + ", " + airports[i].Country;
                      formikValues.setFieldValue("ToAddress", fullAddress);
                      formikValues.setFieldValue(
                        "ToLatitude",
                        airports[i].Latitude
                      );
                      formikValues.setFieldValue(
                        "ToLongitude",
                        airports[i].Longitude
                      );
                      formikValues.submitForm();
                      //   state.UI.AirportsTo.set([]);
                      setParentState((prev) => {
                        return {
                          ...prev,
                          UI: {
                            ...prev.UI,
                            AirportsTo: [],
                          },
                        };
                      });
                    }}
                  />
                </div>

                <div className="col-6 my-2">
                  <label htmlFor={"checkinDate"}>
                    <FormattedMessage
                      id="Departure Date"
                      defaultMessage="Departure Date"
                    />
                  </label>
                  <div className="leftSide__date">
                    <input
                      type="date"
                      className="form-control underline"
                      value={
                        formikValues.values.DepartureDate &&
                        moment(formikValues.values.DepartureDate).format(
                          "YYYY-MM-DD"
                        )
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            console.log("won't proceed with the assignment");
                            return;
                          }

                          let finalValue =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.DepartureDate).format(
                              "HH:mm:ss"
                            );
                          let finalValueArrival =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.ArrivalDate).format(
                              "HH:mm:ss"
                            );
                          formikValues.setFieldValue(
                            "DepartureDate",
                            finalValue
                          );
                          formikValues.setFieldValue(
                            "ArrivalDate",
                            finalValueArrival
                          );
                          formikValues.submitForm();
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <label htmlFor={"checkinDate"}>
                    <FormattedMessage
                      id="Arrival Date"
                      defaultMessage="Arrival Date"
                    />
                  </label>
                  <div className="rightSide__date">
                    <input
                      type="date"
                      className="form-control underline"
                      value={
                        formikValues.values.ArrivalDate &&
                        moment(formikValues.values.ArrivalDate).format(
                          "YYYY-MM-DD"
                        )
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }
                          let finalValue =
                            e.target.value +
                            "T" +
                            moment(formikValues.values.ArrivalDate).format(
                              "HH:mm:ss"
                            );
                          formikValues.setFieldValue("ArrivalDate", finalValue);
                          // state.UI.CurrentFlight.ArrivalDate.set(finalValue);
                          formikValues.submitForm();
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <label htmlFor="DepartureTime">
                    <FormattedMessage
                      id="Departure Time"
                      defaultMessage="Departure Time"
                    />
                  </label>
                  <DatePicker
                    className="form-control underline"
                    popperPlacement="top-end"
                    id={"checkinTime"}
                    selected={
                      formikValues.values.DepartureDate &&
                      moment(formikValues.values.DepartureDate).toDate()
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(e) => {
                      try {
                        let newValue =
                          moment(formikValues.values.DepartureDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment(e).format("HH:mm:ss");
                        formikValues.setFieldValue("DepartureDate", newValue);
                        //    state.UI.CurrentFlight.DepartureDate.set(newValue);
                        formikValues.submitForm();
                      } catch (ex) {
                        console.error(ex);
                        debugger;
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="DepartureTime"
                    component="div"
                  />
                </div>
                <div className="col-6 my-2">
                  <label htmlFor="ArrivalDate">
                    <FormattedMessage
                      id="Arrival Time"
                      defaultMessage="Arrival Time"
                    />
                  </label>
                  <DatePicker
                    className="form-control underline"
                    popperPlacement="top-start"
                    id={"checkinTime"}
                    selected={
                      formikValues.values.ArrivalDate
                        ? moment(formikValues.values.ArrivalDate).toDate()
                        : new Date()
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(e) => {
                      try {
                        let newValue =
                          moment(formikValues.values.ArrivalDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment(e).format("HH:mm:ss");
                        //state.UI.CurrentFlight.ArrivalDate.set(newValue);
                        debugger;
                        formikValues.setFieldValue("ArrivalDate", newValue);
                        formikValues.submitForm();
                      } catch (ex) {
                        console.error(ex);
                        debugger;
                      }
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="ArrivalTime"
                    component="div"
                  />
                </div>

                <div className="col-12 my-2">
                  <label>
                    <FormattedMessage
                      id="Estimated Duration"
                      defaultMessage="Estimated Duration"
                    />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formikValues.values.Duration}
                    onChange={(e) => {
                      formikValues.setFieldValue("Duration", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="Duration"
                    component="div"
                  />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ComercialFlightForm;
