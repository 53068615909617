import * as CONSTANTS from "./constants";
import api from "../../../common/api";
import uuid from "uuid";

const sub_url = "user/AddEdit";

export function signUp(data, callback) {
  return async (dispatch) => {
    dispatch({
      type: CONSTANTS.SIGNING_USER,
    });
    const userData = {
      name: data.firstName,
      lastName: data.lastName,
      password: data.password,
      city: data.city,
      state: data.state,
      country: data.country,
      email: data.email,
      phone: `+1${data.phoneNumber}`,
      ocupation: data.jobTitle,
      guid: uuid.v4(),
    };
    try {
      const request = await api.post(sub_url, userData);
      if (request.data.status === "ok") {
        dispatch({
          type: CONSTANTS.SIGNED_USER,
          data: request.data,
        });
        callback();
      }
    } catch (error) {
      dispatch({
        type: CONSTANTS.SIGNING_USER_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANTS.SIGNING_USER: {
      return {
        ...state,
        logging: true,
      };
    }
    case CONSTANTS.SIGNED_USER:
    case CONSTANTS.SIGNING_USER_ERROR: {
      return {
        ...state,
        logging: false,
      };
    }
    default:
      return state;
  }
}
