import React, { useState } from "react";
import moment from "moment";
import {
  DropDownComponent,
  ToastNotificationSuccess,
  ModalComponent,
} from "../common";
import { Alarm, PencilOutline, Check } from "mdi-material-ui";
import ShowMoreText from "react-show-more-text";
import Swal from "sweetalert2";
import ActivityForm from "./activityform";
import ReactTooltip from "react-tooltip";
import { Form, Formik, Field, ErroField } from "formik";
import { shortActivitySchema } from "../../utils/validationsSchemas";
import { IMAGES } from "../../constant";
import { extractContentText } from "../../common/helpers";
import { useIntl, FormattedMessage } from 'react-intl'
import { deleteActivity } from "../../page/event/redux/deleteActivity";
import { useDispatch } from "react-redux";


const Activity = ({
  activity,
  // deleteActivity,
  addActivity,
  event,
  scheduleMaster,
  editActivity,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  function deleteConfirmation(activity) {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + activity.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        console.log({result})
        dispatch(deleteActivity(activity, () =>
          ToastNotificationSuccess(1, "Activity deleted successfully")
        ));
      }
    });
  }

  function activityEdit(activity, until) {
    editActivity(
      activity,
      () => ToastNotificationSuccess(1, `${activity.Name} succesfully edited`),
      () => console.log(""),
      until
    );
  }

  let activityObjectToEdit = activity
    ? {
      ...activity,
      Name: activity && activity.Name,
      StartDate: activity && moment(activity.Date).toDate(),
      StartTime: activity && moment(activity.Date).toDate(),
      UntilDate:
        activity && activity.DateUntil === null
          ? moment(activity.Date).toDate()
          : moment(activity.DateUntil).toDate(),
      UntilTime:
        activity && activity.DateUntil === null
          ? moment(activity.Date).toDate()
          : moment(activity.DateUntil).toDate(),
      Notes: activity && activity.Notes,
      EventGUID: activity && activity.EventGUID,
      ScheduleMasterGUID: activity && activity.ScheduleMasterGUID,
      CreatedUserGUID: activity && activity.CreatedUserGUID,
    }
    : null;

  const [createForm, setCreateForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  return (
    <div className="activitiesItem d-flex align-items-center px-2 py-1">
      <ModalComponent
        clear={true}
        modal={createForm}
        toggle={() => setCreateForm((createForm) => !createForm)}
        childeren={
          <ActivityForm
            expanded={document
              .getElementById("navbar")
              .classList.contains("expanded")}
            edit={true}
            activity={activity}
            toggle={() => setCreateForm((createForm) => !createForm)}
            addActivity={addActivity}
            editActivity={editActivity}
            scheduleMaster={scheduleMaster}
            event={event}
          />
        }
      />
      <div
        className="activitiesItem__time text-center"
        onClick={() => setCreateForm((createForm) => !createForm)}
      >
        {moment(activity.Date).format("LT")}

        {activity.DateUntil &&
          activity.DateUntil !== activity.Date &&
          "to " + activity.DateUntil && (
            <div>
              <p className="text-center divisorVertical">|</p>

              {moment(activity.DateUntil).format("LT")}
            </div>
          )}
      </div>
      <div className="activitiesItem__details text-truncate" title={activity.Name}

        onClick={() => setCreateForm((createForm) => !createForm)}
      >

        <div className="d-flex align-items-center">
          <p className="top text-truncate">{activity.Name}</p>
        </div>
        <div className="text">
          {activity.Notes && (
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass="d-inline"
              expanded={false}
              width={0}
            >
              <span dangerouslySetInnerHTML={{ __html: extractContentText(activity.Notes) }} />
            </ShowMoreText>
          )}
        </div>

      </div>

      <div className="activitiesItem__actions">
        {activity.Reminder && (
          <>
            <Alarm
              data-tip={`${activity.Reminder} minutes`}
              className="activitiesItem__alarm"
            />
            <ReactTooltip place={"left"} effect="solid" />
          </>
        )}
        <DropDownComponent
          data={[
            {
              name: <FormattedMessage
                id="editActivity"
                defaultMessage="Edit Activity" />,
              border: true,
              trigger: () => setCreateForm((createForm) => !createForm),
            },
            {
              name: <FormattedMessage
                id="delete"
                defaultMessage="Delete" />,
              delete: true,
              trigger: () => deleteConfirmation(activity),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Activity;
