import React, { Component } from "react";
import { IMAGES } from "../../constant";
import { ModalComponent } from "../common";

class Setting extends Component {
  state = {
    modal: false
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { title, description } = this.props;
    return (
      <div className="settings__card">
        <ModalComponent
          toggle={this.toggleModal}
          modal={this.state.modal}
          childeren={
            <div className="ground-transparent">
              <div className="event-page">
                <div className="event-expennd-section">
                  <div className="text-head ground">
                    <div className="text-left">
                      <h1>Example Modal</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <div className="settings__image">
          <img src={IMAGES.GRAPHIC} ALT={title} />
        </div>
        <div className="settings__link">
          <a onClick={() => this.toggleModal()}>{title}</a>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}

export default Setting;
