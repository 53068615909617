import React, { useState } from "react";
import { InformationOutline, CheckCircle } from "mdi-material-ui";
import UserTabs from "../organization/UserTabs";
import { DropDownComponent, ModalComponent } from "../common";
import GeneralUserItem from "../common/GeneralUserItem";
import { randomImage } from "../common/randomImage";
import { useIntl, FormattedMessage } from "react-intl";
const UserItem = ({ user, deleteStaff, editStaff, onClick }) => {
  const [showProfile, setShowProfile] = useState(false);
  const intl = useIntl();
  return (
    <div className="card shadow-sm my-1 userTeam">
      <ModalComponent
        modal={showProfile}
        toggle={() => setShowProfile(!showProfile)}
        childeren={
          <UserTabs
            user={user.Staff}
            toggle={() => setShowProfile(!showProfile)}
          />
        }
      />
      <div className="card-body p-0 mx-0 row text-dark">
        <div className="col-4">
          <GeneralUserItem
            account={user.Staff}
            showShortName
            onClick={() => editStaff(user)}
          />
        </div>
        <div
          className="col-auto text-center  text-truncate"
          title={user.ReservationNumber}
        >
          <p>{user.ReservationNumber}</p>
        </div>
        <div
          className="col-4  text-truncate text-left"
          title={user.TicketNumber}
        >
          {user.TicketNumber ? (
            <>
              <small className="text-muted smallest">
                {intl.formatMessage({ id: "Record Locator" })}
              </small>
              <p>{user.TicketNumber}</p>
            </>
          ) : null}
        </div>
        <div className="col-auto ml-auto text-right px-0 mx-0">
          <DropDownComponent
            data={[
              {
                delete: false,
                name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
                trigger: () => editStaff(user),
              },
              {
                delete: true,
                name: <FormattedMessage id="delete" defaultMessage="Delete" />,
                trigger: () => deleteStaff(user),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default UserItem;
