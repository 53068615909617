import api from "../../../common/api";
import { setOrganization } from "../../../utils";
import uuid from "uuid";
import db from "../../../common/db";

import * as constant from "./constants";

const urlOrganization = `/Organization/AddEditWithoutImage`;
const imageUrl = "/Organizations/UploadImage";

export function createOrganization(organization, image, callBack) {
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_ORGANIZATION,
    });
    try {
      const request = await creatingOrganization(urlOrganization, organization);
      if (request.statusCode === 200) {
        setOrganization(organization);
      }
      await db.table("organizations").put(organization);
      
      let data = new FormData();
      if(image !== undefined && image !== null &&  image.current !== null)
      {
        
        data.append("image", image, organization.Name);

        console.log({data, organization})
        await api.post(`${imageUrl}?id=${organization.GUID}`, data, {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        });
      }
      dispatch({
        type: constant.CREATED_ORGANIZATION,
      });
      dispatch({
        type: constant.SET_CURRENT_ORGANIZATION,
        data: organization,
      });
      callBack();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_ORGANIZATION_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_ORGANIZATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CREATING_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function creatingOrganization(url, organization) {
  return api.post(url, organization);
}
