import React from "react";
import {
  ChevronLeft,
  ChevronRight,
  ChevronDoubleLeft,
  ChevronDoubleRight,
} from "mdi-material-ui";

const CustomToolbar = (toolbar) => {
  const goToBackYear = () => {
    const now = new Date();
    toolbar.date.setYear(toolbar.date.getFullYear() - 1);
    toolbar.onNavigate("prev");
  };

  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToNextYear = () => {
    const now = new Date();
    toolbar.date.setYear(toolbar.date.getFullYear() + 1);
    toolbar.onNavigate("prev");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };
  return (
    <div className="customToolbar d-flex align-items-center">
      <h3 className="mr-auto">{toolbar.label}</h3>
      <button className="btn-reset mr-1" onClick={() => goToBackYear()}>
        <ChevronDoubleLeft />
      </button>
      <button className="btn-reset mr-1" onClick={() => goToBack()}>
        <ChevronLeft />
      </button>
      <button className="btn-reset mr-1" onClick={() => goToCurrent()}>
        Today
      </button>
      <button className="btn-reset mr-1" onClick={() => goToNext()}>
        <ChevronRight />
      </button>
      <button className="btn-reset " onClick={() => goToNextYear()}>
        <ChevronDoubleRight />
      </button>
    </div>
  );
};

export default CustomToolbar;
