import React, { useEffect, useState } from "react";
import AvatarsRow from "../common/avatarsRow";
import { IMAGES } from "../../constant";
import moment from "moment";
import { DropDownComponent } from "../common";
import Swal from "sweetalert2";
import api from "../../common/api";
import db from "../../common/db";
import { sortArray } from "../../common/helpers";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ControllerClassicOutline } from "mdi-material-ui";
import { parseDate } from "../../utils/parseDate";
import { connect } from "formik";

const FlightListItem = (props) => {
  const {
    flight,
    handleSidebarItem,
    // sideBarItemData,
    toggleLabelForm,
    //  deleteflight = { deleteflight },
  } = props;

  const [lastConnection, setLastConnection] = useState("");
  useEffect(() => {
    (async () => {
      const connections = await db
        .table("flights")
        .where({ Connection: flight.Connection })
        .toArray();
      if (connections.length > 1) {
        const itemWithLatestArrival = connections.reduce(
          (latestItem, currentItem) => {
            if (!latestItem) {
              return currentItem;
            }

            const latestArrivalDate = parseDate(latestItem.ArrivalDate);
            const currentArrivalDate = parseDate(currentItem.ArrivalDate);

            return currentArrivalDate > latestArrivalDate
              ? currentItem
              : latestItem;
          },
          null
        );
        setLastConnection({
          ...itemWithLatestArrival,
          stops: connections.length,
        });
      }
    })();
  }, [flight]);

  return flight.length <= 0 ? (
    <></>
  ) : (
    <div
      onClick={() => {
        handleSidebarItem("FLIGHT_LIST", flight);
      }}
      className={`hotelItem__root flight px-0 py-0 clickableItem row ${
        flight && props.selected ? "active" : ""
      }`}
    >
      <div className="col-auto px-0">
        <button
          className="btn btn-sm text-secondary"
          onClick={() => props.toggleSelect(flight)}
        >
          {props.checkSelectionStatus(
            props.flights,
            flight,
            props.selectedItems
          ) === 0 ? (
            <i className="far fa-square uncheck"></i>
          ) : props.checkSelectionStatus(
              props.flights,
              flight,
              props.selectedItems
            ) === 1 ? (
            <i className="fas fa-check-square"></i>
          ) : (
            <i className="fas fa-minus-square"></i>
          )}
        </button>
      </div>
      <div className="col px-0">
        <div className="flex-1 row">
          <div className="col-12 px-0">
            {props.flight.PrivateFlight ? (
              <span className="badge bg-secondary text-light py-1 mr-2">
                <FormattedMessage id="private" defaultMessage="Private" />
              </span>
            ) : null}
            {flight.Label && (
              <div
                onClick={() => props.toggleShowLabel()}
                className="c-pointer labelContainer my-1"
              >
                {flight.Label}
              </div>
            )}
          </div>

          <div className="col-4 px-0">
            <div className="flight-detail mt-1">
              <h6 className="text-truncate">
                {flight.From}
                <span title={flight.FromAddress}>{`   ${
                  flight.FromAddress
                    ? flight.FromAddress
                    : flight.FromAddress || ""
                }`}</span>
              </h6>
              <p
                className="text-truncate"
                title={moment(flight.DepartureDate).format("MMM DD, hh:mm a")}
              >
                {moment(flight.DepartureDate).format("MMM DD, hh:mm a")}
              </p>
            </div>
          </div>
          <div className="col-1 text-center px-0 ">
            <img src={IMAGES.GROUP} alt="dot" height="19" />
            {lastConnection && (
              <p className="stop">{lastConnection.stops - 1 + " stops"}</p>
            )}
          </div>
          <div className="col-4">
            <div className="flight-detail mt-1">
              <h6 className="text-truncate">
                {lastConnection ? lastConnection.to : flight.To}
                <span
                  title={
                    lastConnection ? lastConnection.ToAddress : flight.ToAddress
                  }
                >{`   ${
                  lastConnection
                    ? lastConnection.ToAddress
                    : flight.ToAddress || ""
                }`}</span>
              </h6>
              <p
                className="text-truncate"
                title={moment(
                  lastConnection
                    ? lastConnection.ArrivalDate
                    : flight.ArrivalDate
                ).format("MMM DD, hh:mm a")}
              >
                {moment(
                  lastConnection
                    ? lastConnection.ArrivalDate
                    : flight.ArrivalDate
                ).format("MMM DD, hh:mm a")}
              </p>
            </div>
          </div>
          <div className="col-auto ml-auto px-0 ">
            <div className="avatarsContainer">
              <AvatarsRow staffs={flight && flight.FlightStaffs} flight />
            </div>
          </div>
          <div className="col-1 px-0 pr-3">
            <div className="float-right">
              <DropDownComponent
                data={[
                  // {
                  //   name: (
                  //     <FormattedMessage
                  //       id="assignMembers"
                  //       defaultMessage="Assign members"
                  //     />
                  //   ),
                  //   trigger: () => {
                  //     // props.toggleSelect(flight, setState);
                  //     props.toggleNewMembersModal();
                  //   },
                  //   border: true,
                  // },
                  {
                    name: (
                      <FormattedMessage
                        id="editLabel"
                        defaultMessage="Edit Label"
                      />
                    ),
                    trigger: () => {
                      props.toggleShowLabel();
                      //props.toggleNewMembersModal()
                    },
                    border: true,
                  },
                  {
                    name: (
                      <FormattedMessage id="share" defaultMessage="Share" />
                    ),
                    trigger: () => {
                      props.shareFlight(flight);
                    },
                    border: true,
                  },
                  {
                    name: (
                      <FormattedMessage id="delete" defaultMessage="Delete" />
                    ),
                    delete: true,
                    trigger: () => {
                      props.toggleSelect(flight);
                      props.deleteAllSelected();
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightListItem;
