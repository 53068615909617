import React from "react";

export const CheckBox = (props) => {
  const { value, header, id, onChange } = props;
  return (
    <div className="form-check hotel-check">
      <input
        onChange={(e) => onChange(e)}
        className="form-check-input"
        type="checkbox"
        value={value}
        id={id}
        checked={value}
        autoComplete="off"
      />
      <label className="form-check-label" htmlFor={id}>
        {header}
      </label>
    </div>
  );
};
