import {useIntl, FormattedMessage} from 'react-intl'
export const status = [
  { id: 1, label: <FormattedMessage
    id="confirmed"
    defaultMessage="Confirmed"/>, value: ["Status", "Confirmed"] },
  { id: 2, label: <FormattedMessage
    id="cancelled"
    defaultMessage="Cancelled"/> , value: ["Status", "Cancelled"] },
  { id: 3, label: <FormattedMessage
    id="tentativeHold"
    defaultMessage="Tentative/Hold"/>, value: ["Status", "Tentative/Hold"] },
  { id: 4, label: <FormattedMessage
    id="postponed"
    defaultMessage="Postponed"/>, value: ["Status", "Postponed"] },
];
