import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useBetaFeature } from "../../hooks/useBetaFeature";

export default function NavAction(props) {
  const { name, icon, onClick, fontAwesomeIcon, betaClass } = props;
  useEffect(() => {}, []);

  const { isChecked } = useBetaFeature();
  return (
    <div
      className={`${betaClass} ${
        !isChecked && betaClass && "active"
      } navItem px-2`}
      onClick={() => onClick()}
    >
      <div className="navItem__icon-container">
        <i className={fontAwesomeIcon} />
      </div>
      <p className="text-truncate">{name}</p>
      <ReactTooltip place={"right"} effect="solid" />
      {isChecked && betaClass && (
        <span className="badge badge-info ml-2">Beta</span>
      )}
    </div>
  );
}

NavAction.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.object,
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  onClick: PropTypes.func,
};
