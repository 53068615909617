import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "mdi-material-ui";
import { DropDownComponent } from "../common";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";
import { StaffDropDown } from "./StaffDropDown";
import { useSelector } from "react-redux";
function getPriority(p) {
  if (p === 1) return "Low";
  if (p === 2) return "Medium";
  if (p === 3) return "High";
  return "";
}

function showPriority(p) {
  if (p === 1) return "!";
  if (p === 2) return "!!";
  if (p === 3) return "!!!";
  return "";
}

export const AddEditTaskTable = (props) => {
  
  const [expanded, setExpanded] = useState(false);
  let { todo, toggleTask, deleteTask, toggleTaskEdit, currentList } = props;


  return (
    <tr className="" style={{height:"3rem"}}>
      <td onClick={() => toggleTask(todo)} style={{verticalAlign:"middle", width:"2rem", textAlign:"center"}}>
        {todo.Status ? (
          <i className="fas fa-check-square pt-1" ></i>
        ) : (
          <i className="far fa-square uncheck pt-1"></i>
        )}
      </td>
      <td
        style={{ textDecoration: todo.Status ? "line-through" : "", verticalAlign:"middle", width:"15rem"}}
        onClick={() => toggleTaskEdit(todo, currentList)}
      >
        <span>{todo.Name}</span>
        <span>{todo.Note}</span>
      </td>
      <td style={{verticalAlign:"middle"}}>
        {todo?.Date
          ? moment(todo.Date).format("MMM, DD YYYY") + "-"
          : "Add Due Date"}
        {todo?.Date ? moment(todo.Date).format("hh:mm a") : ""}
      </td>
      <td style={{verticalAlign:"middle", width:"15rem"}}>  
        <StaffDropDown />
      </td>
      <td style={{verticalAlign:"middle", width:"10rem"}}> Location</td>
      <td className={`${getPriority(todo.Priority).toLowerCase()} mr-2"`} style={{verticalAlign:"middle"}}>
        {showPriority(todo.Priority)}
      </td>
      <td style={{verticalAlign:"middle"}}>
        <button className="teamItem__more btn-reset">
          <DropDownComponent
            data={[
              {
                name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
                border: true,
                trigger: () => toggleTaskEdit(todo, currentList),
              },
              {
                name: <FormattedMessage id="delete" defaultMessage="Delete" />,
                delete: true,
                trigger: () => deleteTask(todo),
              },
            ]}
          />
        </button>
      </td>
    </tr>
  );
};
