import React, { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ModalComponent } from "./modal";

export function ImageCrop({
  handleChange,
  dropImage,
  isNewDrop,
  setIsNewDrop,
}) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 2,
    height: 242,
    unit: "px",
    width: 484,
    x: 0,
    y: 0,
  });
  const [cropImage, setCroppedImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const imgRef = useRef(null);

  const onSelectFile = (e) => {
    handleChange(e);
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setCroppedImage(reader.result);
      });
      reader.readAsDataURL(e?.target?.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    imgRef.current = image;
  };

  const onCropComplete = async (crop) => {
    const croppedImage = await getCroppedImage(crop);
    setCroppedImage(croppedImage);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const getCroppedImage = (completedCrop) => {
    if (!imgRef.current || !completedCrop) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        setImageBlob(blob);
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      }, "image/png");
    });
  };

  const handleSave = () => {
    console.log({ imageBlob });
    handleChange(imageBlob, "drop");
    setSrc(null);
    setIsNewDrop(false);
  };

  useEffect(() => {
    if (dropImage && isNewDrop) {
      setSrc(dropImage);
      setIsNewDrop(false);
    }
  }, [dropImage]);

  console.log({ crop });
  return (
    <>
      <input type="file" accept="image/*" onChange={onSelectFile} />

      <ModalComponent
        toggle={() => setSrc(null)}
        modal={!!src}
        childeren={
          <div>
            {src && (
              <>
                <div className="d-flex flex-column align-items-center p-2 rounded-lg justify-content-between w-100 h-100">
                  <ReactCrop
                    src={src}
                    onImageLoaded={onImageLoaded}
                    crop={crop}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  />
                  <p>Image Preview:</p>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                      overflow: "hidden",
                      margin: "10px 0",
                    }}
                  >
                    <img
                      src={cropImage || src}
                      alt="crop image"
                      className="w-100 h-100"
                    />
                  </div>
                  <button
                    onClick={() => handleSave()}
                    className="btn btn-primary"
                  >
                    Save Image
                  </button>
                </div>
              </>
            )}
          </div>
        }
      />
    </>
  );
}
