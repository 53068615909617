import {
  NoteTextOutline,
  CheckCircle,
  CloseCircle,
  Bell as NotifyButton,
  Delete as DeleteButton,
  Star as FavoriteButton,
} from "mdi-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  PassTypesModelAsArray,
  PassTypesModel,
  getStatusRequestGuestList,
  sortArray,
} from "../../../common/helpers";
import Popup from "reactjs-popup";
import uuid from "uuid";
import { getBasicInfo } from "../../../utils";
import ShowMoreText from "react-show-more-text";

const GuestListPlainView = (props) => {
  const [state, setState] = useState(props.records);
  const intl = useIntl();

  const itemOptions = [
    {
      name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
      border: true,
      trigger: (item) => {
        props.toggleEditModal(item);
        // props.setMode(true);
      },
    },
    {
      name: (
        <FormattedMessage
          id="Add request for the same guest"
          defaultMessage="Add request for the same guest"
        />
      ),
      trigger: (item) => {
        let newItem = item;
        newItem.Notes = null;
        newItem.ConfirmedNote = "";
        newItem.ConfirmAccountGUID = null;
        newItem.GUID = uuid.v4();
        newItem.PassType = newItem.PassType !== "Ticket" ? "Ticket" : "Pass";
        newItem.Priority = null;
        props.toggleEditModal(newItem);
      },
      border: true,
    },
    {
      name: <FormattedMessage id="Approve" defaultMessage="Approve" />,
      border: true,
      trigger: (item) => props.toggleResponseModal(item, true),
    },
    {
      name: <FormattedMessage id="Decline" defaultMessage="Decline" />,
      border: true,
      trigger: (item) => props.toggleResponseModal(item, false),
    },
    {
      delete: true,
      name: <FormattedMessage id="delete" defaultMessage="Delete " />,
      border: true,
      trigger: (item) => props.deleteElement({ ...item, Deleted: new Date() }),
    },
  ];

  useEffect(() => {
    let copy = props.records;

    if (props.sortBy === "1") {
      //by date of request ascending

      copy = copy.sort((a, b) => (a.RequestDate > b.RequestDate ? 1 : -1)); //(copy, "RequestDate", true);
    } else if (props.sortBy === "2") {
      //by date of request ascending
      copy = copy
        .sort((a, b) => (a.RequestDate > b.RequestDate ? 1 : -1))
        .reverse();
    } else if (props.sortBy === "3") {
      //by guest ascending

      copy = copy.sort((a, b) => (a.GuestLastName > b.GuestLastName ? 1 : -1));
    } else if (props.sortBy === "4") {
      //by guest ascending
      let copy = state;
      copy = copy
        .sort((a, b) => (a.GuestLastName > b.GuestLastName ? 1 : -1))
        .reverse();
    }
    setState(copy);
  }, [props.sortBy, props.records]);

  return (
    <>
      <table className="table mb-0">
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`guestRequest child row ${
                    props.ticket && props.ticketSelected ? "active" : ""
                  }`}
                >
                  <td className="py-0 px-0 col-2">
                    <span
                      className={`${getStatusRequestGuestList(
                        item
                      )} px-2 d-inline-block`}
                      style={{ height: "100%" }}
                    >
                      <small className="text-muted">
                        <FormattedMessage
                          id="Guest Name"
                          defaultMessage="Guest Name"
                        />{" "}
                        &nbsp;
                        {item.RequestDate ? (
                          <span
                            className=""
                            title={moment(item.RequestDate).format(
                              "MMM DD, YYYY hh:mm a"
                            )}
                          >
                            ({moment(item.RequestDate).format("MMM DD, YYYY")})
                          </span>
                        ) : null}
                      </small>
                      <br />
                      <div className="d-flex justify-content-between">
                        <span>
                          {item.GuestLastName !== null
                            ? item.GuestLastName + ", "
                            : ""}
                          {item.GuestNames}
                        </span>
                        {item.Note && item.Note.length > 0 && (
                          <div
                            onClick={() => props.toggleAdminNote(item, true)}
                          >
                            <NoteTextOutline htmlColor="#fb9f00" />
                          </div>
                        )}
                      </div>
                    </span>
                  </td>
                  <td className="py-0 px-1 col-2">
                    <small className="text-muted">
                      <FormattedMessage id="requestedBy" />
                    </small>
                    <br />
                    {item.RequestByName !== null ? item.RequestByName : ""}
                  </td>
                  <td className="py-2 px-1 col text-center">{item.Passes}</td>
                  <td className="py-2 px-1 col-1">
                    <select
                      value={item.PassType}
                      onChange={(e) =>
                        props.updateRequest({
                          ...item,
                          PassType: e.target.value,
                        })
                      }
                      className="form-control inline"
                    >
                      {PassTypesModelAsArray().map((item) => {
                        return (
                          <option value={item}>{PassTypesModel[item]}</option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="py-2 px-1 col-1">
                    <select
                      onChange={(e) =>
                        props.updateRequest({ ...item, Pickup: e.target.value })
                      }
                      className="form-control inline"
                      value={item.PickupLocation}
                    >
                      <option value="-">-</option>
                      <option value="WillCall">Will Call</option>
                      <option value="In-Hand">In Hand</option>
                    </select>
                  </td>
                  {/* <td className="py-2 px-1 col-1 text-center">
                    {item.Note && item.Note.length > 0 ? (
                      <span
                        title={item.Note}
                        onClick={() => props.toggleAdminNote(item, true)}
                      >
                        <NoteTextOutline htmlColor="#fb9f00" />
                      </span>
                    ) : null}
                  </td> */}

                  <td
                    className="d-flex py-0 px-1 col-2 align-items-center justify-content-center"
                    title={item.ConfirmedNote}
                  >
                    {item.ConfirmedNote && item.ConfirmedNote.length > 0 ? (
                      <div
                        onDoubleClick={() => props.toggleAdminNote(item)}
                        className="w-100"
                      >
                        <ShowMoreText
                          className={"d-inline cursor-default w-100"}
                          anchorClass="d-inline text-primary anchor-class"
                          lines={2}
                          more="See"
                          less="Less"
                          expanded={false}
                          width={0}
                          children={
                            <p style={{ cursor: "default" }} className="w-100">
                              {item.ConfirmedNote}
                            </p>
                          }
                        />
                      </div>
                    ) : (
                      <button
                        className="btn align-self-center"
                        onClick={() => props.toggleAdminNote(item)}
                      >
                        <i className="fas fa-edit"></i>
                        {/* <u>
              <FormattedMessage
                id="Add Note"
                defaultMessage="Add Admin  Note"
              />
            </u> */}
                      </button>
                    )}
                  </td>
                  <td className="py-2 px-1 col-1 text-center">
                    {item.ConfirmAccountGUID ? (
                      <img
                        //title={item.ConfirmedAccountName + " ("+ moment(item.ConfirmedDate).utc().fromNow() +")"}
                        title={item.ConfirmedAccountName}
                        src={item.ConfirmedAccounImage}
                        alt={item.ConfirmedAccountName}
                        className="mx-2 avatar"
                        width="20"
                        height="20"
                      />
                    ) : null}
                  </td>

                  <td
                    className="py-2 px-1 col-1 text-center text-truncate"
                    title={`${item.Section ? "Section: " + item.Section : ""} ${
                      item.Row ? "Row: " + item.Row : ""
                    } ${item.Seat ? "Seat: " + item.Seat : ""}`}
                  >
                    <small>
                      {item.Section && item.Section !== null
                        ? "Section: " + item.Section
                        : null}
                      {item.Row && item.Row !== null ? " Row: " + item.Row : ""}
                      {item.Seat && item.Seat !== null
                        ? " Seat: " + item.Seat
                        : ""}
                    </small>
                  </td>
                  <td className="py-2 px-1 text-center col-auto">
                    <FavoriteButton
                      onClick={() =>
                        props.updateRequest({
                          ...item,
                          Priority: !item.Priority,
                        })
                      }
                      width="10"
                      height="10"
                      htmlColor={item.Priority ? "#fb9f00" : "#95979d"}
                    />
                  </td>
                  <td className="py-2 px-1 text-center col-auto">
                    <NotifyButton
                      onClick={() =>
                        props.updateRequest({ ...item, Notify: !item.Notify })
                      }
                      width="10"
                      height="10"
                      htmlColor={item.Notify ? "#01aeff" : "#95979d"}
                    />
                  </td>
                  <td
                    className="py-2 px-0 text-truncate py-1 text-right col-1"
                    style={{ overflow: "visible" }}
                  >
                    <div
                      className="btn-group align-items-center actionButtons"
                      style={{ overflow: "visible" }}
                    >
                      <CheckCircle
                        onClick={() => props.toggleResponseModal(item, true)}
                        className="mx-1 d-none d-lg-block d-xl-block"
                        htmlColor="#1ba863"
                        width="10"
                        height="10"
                      />
                      <CloseCircle
                        onClick={() => props.toggleResponseModal(item, false)}
                        className="mx-1 d-none d-lg-block d-xl-block "
                        htmlColor="#fe3d33"
                        width="10"
                        height="10"
                      />
                      <Popup
                        modal={false}
                        closeOnDocumentClick
                        position={
                          props.guestListScroll ? "top right" : "bottom right"
                        }
                        on="click"
                        trigger={
                          <button className="btn btn-sm  mr-2">
                            <i className="fas fa-ellipsis-h"></i>
                          </button>
                        }
                      >
                        {(open) => (
                          <div className="text-left">
                            {itemOptions.map((o) => (
                              <div
                                key={o.name}
                                className={`overflow-hidden text-wrap ${
                                  o.border ? "border-bottom " : ""
                                }  ${o.delete ? "delete " : ""} dropDownList`}
                                onClick={() => {
                                  o.trigger(item);
                                  open();
                                }}
                              >
                                {o.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </Popup>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default GuestListPlainView;
