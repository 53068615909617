import { LOGGED_USER, LOGIN_ERROR, LOGIN_USER } from "./constants";
import api, { APIHeader } from "../../../common/api";
import {
  setAccessToken,
  setBasicInfo,
  getRememberMeValue,
} from "../../../utils";
import queryString from "query-string";

const sub_url = "user/CanLogin";

export function login(username, password, callback) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER,
    });
    try {
    //  debugger;
      const loginRequest = await api.post(sub_url, {
        Email: username,
        Password: password,
      }, APIHeader);
      
      if (loginRequest.data.status === "ok") {
        getRememberMeValue(username, password);
        const user = loginRequest.data.response;
        setBasicInfo(user);
        let bodyUser = {
          grant_type: "password",
          username: username,
          password: password,
        };
        const tokenRequest = await api.post(
          `token`,
          queryString.stringify(bodyUser)
        );
        setAccessToken(tokenRequest.data.access_token);
        dispatch({
          type: LOGGED_USER,
          data: user,
        });
        callback();
      } else {
        dispatch({
          type: LOGIN_ERROR,
        });
      }
    } catch (error) {
      debugger;
      dispatch({
        type: LOGIN_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state,
        logging: true,
        loginError: false,
      };
    }
    case LOGGED_USER: {
      return {
        ...state,
        logging: false,
        loginError: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        logging: false,
        loginError: true,
      };
    }
    default:
      return state;
  }
}
