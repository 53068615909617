import * as constant from "./constants";
import api from "../../../common/api";

export function createFromTemplate(itinerary, event, success) {
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_FROM_TEMPLATE,
    });
    try {
      const request = await api.get("/ScheduleTemplates/AddToEvent", {
        params: {
          ScheduleTemplateGUID: itinerary,
          EventGUID: event,
        },
      });
      dispatch({
        type: constant.CREATED_FROM_TEMPLATE,
        data: request.data.response,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.CREATING_FROM_TEMPLATE_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_FROM_TEMPLATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_FROM_TEMPLATE: {
      return {
        ...state,
        loading: false,
        schedulesMasters: [...state.schedulesMasters, action.data],
      };
    }
    case constant.CREATING_FROM_TEMPLATE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
