import React, { useEffect } from "react";

import { GROUPS_COLORS } from "../../constant";
import { useState, createState } from "@hookstate/core";
import api from "../../common/api";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function AddEditFolder(props) {
  const state = useState({
    Model: JSON.parse(JSON.stringify(props.Model)),
    IsLoading: false,
  });

  useEffect(() => {
    let focus = document.getElementById("Name");
    if (focus !== null) {
      focus.focus();
    }
  }, []);

  const handleSubmit = async () => {
    state.IsLoading.set(!state.IsLoading.value);
    if (
      state.Model.ParentGUID.value !== undefined &&
      state.Model.ParentGUID.value !== null &&
      state.Model.ParentGUID.value.length === 0
    ) {
      state.Model.ParentGUID.set(null);
    }
    let copy = JSON.stringify(state.Model.value);
    //We have to sent to api
    const request = await api.post(`/File/AddEditFolder`, copy);
    state.IsLoading.set(!state.IsLoading.value);
    if (request.data.Status === "ok") {
      props.toggle(request.data.Response);
    }

    //props.toggle();
  };

  const selectColor = (color) => {
    state.Model.Color.set(color);
  };
  return (
    <div className="">
      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left d-flex w-100 justify-content-between">
              <h1 className="title">
                <FormattedMessage id="folder" defaultMessage="Folder" />
              </h1>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => props.toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row">
              <div className="col-12">
                <label className="d-flex align-items-center">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  id="Name"
                  type="text"
                  className="form-control"
                  value={state.Model.Name.value}
                  onChange={(e) => state.Model.Name.set(e.target.value)}
                />
              </div>

              <div className="col-12 my-2">
                <label className="d-flex align-items-center">
                  <FormattedMessage
                    id="selectColor"
                    defaultMessage="Select a color"
                  />
                </label>

                <div className="d-flex align-items-center">
                  {GROUPS_COLORS.map((color) => (
                    <button
                      onClick={() => selectColor(color)}
                      key={color}
                      style={{
                        backgroundColor: `${color}`,
                        border:
                          state.Model.Color.value === color
                            ? "3px solid black"
                            : "3px solid transparent",
                      }}
                      type="button"
                      className="clean-button btn-reset color-option pink"
                    ></button>
                  ))}
                  <input
                    type="color"
                    id="color-picker"
                    className="clean-button btn-reset color-option"
                    style={{
                      // backgroundColor: `${noteColor}`,

                      background:
                        "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
                      border: "3px solid transparent",
                    }}
                    onChange={(e) => selectColor(e.target.value)}
                  />

                  <button
                    onClick={() => selectColor(null)}
                    key="clean"
                    style={{
                      backgroundColor: `#ccc`,
                      border: !state.Model.Color.value
                        ? "3px solid black"
                        : "3px solid transparent",
                    }}
                    title="Remove color"
                    type="button"
                    className="clean-button btn-reset color-option text-danger bg-none"
                  ></button>
                </div>
              </div>
              <div className="col-12 text-right">
                {state.IsLoading.value ? (
                  <div
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    state.IsLoading.value === true ||
                    state.Model.Name.value.length === 0
                  }
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
