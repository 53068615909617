import { ErrorMessage, Field, Form } from "formik";
import React from "react";
import FormField from "../../form/formField";

// class RewardsFields extends Component {
const TravelDetails = (props) => {
  return (
    <div className="flex-1">
      <Form className="row">
        <div className="col-6">
          <FormField fullWidth={true} label="Passport No">
            <Field
              className="form-control"
              type="text"
              name="PassportNumber"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="PassportNumber"
              component="div"
            />
          </FormField>
          <FormField fullWidth={true} label="Date of Issue">
            <Field
              className="form-control"
              type="text"
              name="DateIssue"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="DateIssue"
              component="div"
            />
          </FormField>
          <FormField fullWidth={true} label="Nationality">
            <Field
              className="form-control"
              type="text"
              name="Nationality"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="Nationality"
              component="div"
            />
          </FormField>
        </div>
        <div className="col-6">
          <FormField fullWidth={true} label="Place of Issue">
            <Field
              className="form-control"
              type="text"
              name="PlaceIssue"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="PlaceIssue"
              component="div"
            />
          </FormField>
          <FormField fullWidth={true} label="Date of Expiration">
            <Field
              className="form-control"
              type="text"
              name="DateExpiration"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="DateExpiration"
              component="div"
            />
          </FormField>
          <FormField fullWidth={true} label="Upload Image">
            <Field
              className="form-control"
              type="file"
              name="PassportImage"
              autoComplete="off"
              disabled={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="PassportImage"
              component="div"
            />
          </FormField>
        </div>
        <div className="col-12">
          <FormField
            fullWidth={true}
            label="KTN Number (Global Entry/Nexis/Sentri)"
          >
            <Field
              className="form-control"
              type="text"
              name="KTN"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="KTN"
              component="div"
            />
          </FormField>
        </div>

        {!props.readOnly && (
          <div className="col-12">
            <button type="button" className="btn btn-primary btn-sm">
              Add Resident Info
            </button>
            <button type="button" className="btn btn-primary btn-sm ml-3">
              Add Passports
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default TravelDetails;
