import React, { Component } from "react";
import AddEvent from "./addEvent";
import AddVisibiltity from "./addVisibiltity";
import AddMultipleEvent from "./addMultipleEvent";
import { connect } from "react-redux";
import { logoutUser, getOrganization, getBasicInfo } from "../../../utils";
import { ToastNotificationSuccess } from "../../../components/common/toastNotification";
import { ModalComponent } from "../../../components/common/modal";
import history from "../../../components/common/history";
import * as commonActions from "../../common/redux/actions";
import * as actions from "../redux/actions";
import * as calendarActions from "../../calendar/redux/actions";
import { bindActionCreators } from "redux";
import db from "../../../common/db";
import Swal from "sweetalert2";
import uuid from "uuid";
import moment from "moment";

class AddEventComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      visibilityModal: false,
      visibleStaffs: [],
      loading: false,
      eventStaffs: {},
      checkImage: null,
      selectStaffsAll: false,
      eventStaffTeams: {},
      searchedStaff: {},
      initialCheckImage: null,
      addMultiple: false,
      multipleEvents: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  logout = () => {
    history.push("/");
    logoutUser();
  };

  toggleMultiple = () => {
    this.setState({
      addMultiple: !this.state.addMultiple,
    });
  };

  toggleVisibility = () => {
    this.setState({
      visibilityModal: !this.state.visibilityModal,
    });
  };

  addEventMultiple = async (event) => {
    const { teams } = this.props;
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut([...this.state.multipleEvents, event])
      .then(() =>
        this.setState({
          multipleEvents: [...this.state.multipleEvents, event],
        })
      );
  };

  clearMultipleState = () => {
    this.setState({
      multipleEvents: [],
    });
  };

  updateMultipleEvent = (event) => {
    let updatedEvents = this.state.multipleEvents;
    const newEvents = updatedEvents.map((e) =>
      e.GUID === event.GUID ? event : e
    );
    this.setState({ multipleEvents: newEvents });
  };

  addBunchEvents = async (events) => {
    console.log({ events });
    const actualEvents = this.state.multipleEvents;
    const updatedEvents = [...actualEvents, ...events];
    console.log({ actualEvents });
    console.log(updatedEvents);
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(updatedEvents)
      .then(() =>
        this.setState({
          multipleEvents: updatedEvents,
        })
      );
  };

  clearEllEvents = async () => {
    await db
      .table("batchEvent")
      .clear()
      .then(() =>
        this.setState({
          multipleEvents: [],
        })
      );
  };

  removeEvent = async (event) => {
    let events = this.state.multipleEvents;
    let filtered = events.filter((s) => s.GUID !== event.GUID);
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(filtered)
      .then(() =>
        this.setState({
          multipleEvents: filtered,
        })
      );
  };

  updateEvent = async (eventUpdated) => {
    let events = this.state.multipleEvents.map((event) =>
      event.GUID === eventUpdated.GUID ? (event = eventUpdated) : event
    );
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(events)
      .then(() =>
        this.setState({
          multipleEvents: events,
        })
      );
  };

  addMultipleEvents = (props) => {
    const { multipleEvents } = this.state;
    const { getEvents } = this.props.calendarActions;
    const { addMutipleEvents } = this.props.actions;
    const { toggleAddEvent, teams } = this.props;
    let finalStaffs = [];
    teams.map((team) => {
      team.Staffs.forEach((staff) => finalStaffs.push(staff.GUID));
    });
    finalStaffs = finalStaffs.map((s) => ({
      StaffGUID: s,
      Deleted: null,
      GUID: uuid.v4(),
    }));
    let finalEvents = multipleEvents
      .filter((event) => event.Name !== "")
      .map((event) => {
        const utcDate = `${moment(event.Date)
          .utc()
          .format("YYYY-MM-DD")}T23:00:00`;
        return {
          ...event,
          Date: utcDate,
          VisibleBy: finalStaffs.map((s) => ({
            ...s,
            GUID: uuid.v4(),
            EventGUID: event.GUID,
          })),
        };
      });
    const isNotValidYet = multipleEvents.some((item) => item.Error === true);
    if (isNotValidYet) {
      /* Swal.fire({
        title: "Verify",
        text: "There is some events must be completed before submit",
        icon: "info",
      });*/
    } else {
      let events = finalEvents.map((event) => {
        delete event.Venue;
        delete event.EventTypeName;
        delete event.Error;
        return {
          ...event,
        };
      });
      addMutipleEvents(events, () => {
        ToastNotificationSuccess(1, "Event added successfully.");
        toggleAddEvent();
        getEvents(getOrganization().GUID, getBasicInfo().GUID);
        this.clearEllEvents();
      });
    }
  };

  getVisibilityCount = () => {
    if (this.state.visibleStaffs.length === 0) {
      return "All";
    } else {
      return `${this.state.visibleStaffs.length} users`;
    }
  };

  toggleMember = (staff) => {
    let staffs = this.state.visibleStaffs;
    if (staffs.includes(staff.GUID)) {
      let filtered = staffs.filter((s) => s !== staff.GUID);
      return this.setState({
        visibleStaffs: filtered,
      });
    } else {
      let newOnes = staffs.concat(staff.GUID);
      return this.setState({
        visibleStaffs: newOnes,
      });
    }
  };

  toggleSelectAll = (team) => {
    let staffs = this.state.visibleStaffs;
    team.forEach((staff) => {
      if (staffs.includes(staff.GUID)) {
        let filtered = staffs.filter((s) => s !== staff.GUID);
        staffs = filtered;
      } else {
        staffs.push(staff.GUID);
      }
    });
    this.setState({
      visibleStaffs: staffs,
    });
  };

  render() {
    let {
      common,
      toggleAddEvent,
      teams,
      fromCalendar,
      selectedDate,
      loading,
      actions: { addEvent },
      commonActions: {
        searchCity,
        searchCountry,
        emptyCities,
        emptyCountries,
        searchVenues,
        emptyVenues,
      },
    } = this.props;
    let { multipleEvents } = this.state;

    return (
      <>
        <ModalComponent
          toggle={() => toggleAddEvent()}
          modal={this.state.modal}
          childeren={
            <AddEvent
              {...this.state}
              {...this.props}
              expanded={document
                .getElementById("navbar")
                .classList.contains("expanded")}
              toggleMultiple={() => this.toggleMultiple()}
              toggle={toggleAddEvent}
              getCities={searchCity}
              getCountrys={searchCountry}
              countries={common.countries}
              cities={common.cities}
              venues={common.venues}
              searchVenues={searchVenues}
              emptyCities={emptyCities}
              emptyVenues={emptyVenues}
              emptyCountries={emptyCountries}
              toggleVisibilty={() => this.toggleVisibility()}
              getVisibilityCount={this.getVisibilityCount}
              visibleStaffs={this.state.visibleStaffs}
              addEvent={addEvent}
              teams={this.props.teams}
              loading={loading}
              fromCalendar={fromCalendar}
              selectedDate={selectedDate}
            />
          }
        />
        <ModalComponent
          modal={this.state.addMultiple}
          toggle={() => this.toggleMultiple()}
          childeren={
            <AddMultipleEvent
              addEvent={addEvent}
              addEventMultiple={this.addEventMultiple}
              clearAll={this.clearEllEvents}
              multipleEvents={multipleEvents}
              toggle={this.toggleMultiple}
              updateEvent={this.updateEvent}
              addMultipleEvents={this.addMultipleEvents}
              addBunchEvents={this.addBunchEvents}
              removeEvent={this.removeEvent}
              loading={loading}
              updateMultipleEvent={this.updateMultipleEvent}
              clearMultipleState={this.clearMultipleState}
            />
          }
        />
        <ModalComponent
          modal={this.state.visibilityModal}
          toggle={() => this.toggleVisibility()}
          childeren={
            <AddVisibiltity
              toggleMember={this.toggleMember}
              toggleSelectAll={this.toggleSelectAll}
              arrayToBeSelected={this.state.visibleStaffs}
              eventStaffs={this.props.teams}
              toggle={() => this.toggleVisibility()}
              visibility={true}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  teams: state.organizations.teams,
  common: state.common,
  loading: state.event.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators({ ...commonActions }, dispatch),
    actions: bindActionCreators({ ...actions }, dispatch),
    calendarActions: bindActionCreators({ ...calendarActions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEventComponent);
