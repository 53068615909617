import * as CONSTANT from "./constants";

export function emptyHotels() {
  return (dispatch) => {
    dispatch({
      type: CONSTANT.CLEAR_ALL_HOTELS,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.CLEAR_ALL_HOTELS: {
      return {
        ...state,
        hotels: [],
      };
    }
    default:
      return state;
  }
}
