import React, { Component } from "react";
import uuid from "uuid";
import { getBasicInfo } from "../../utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../page/meet/redux/actions";

class UserItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
    };
  }

  toggleDropdown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  onStartChat = async () => {
    const conversationGUID = uuid.v4();
    const currentUser = getBasicInfo();
    const conversation = {
      GUID: conversationGUID,
      Name: `chat with ${this.props.user.accountName}`,
      CreationDate: new Date(),

      UnreadMessages: 0,
      EventGUID: null,
      ConversationMember: [
        {
          GUID: uuid.v4(),
          ConversationGUID: conversationGUID,
          AccountGuid: currentUser.GUID,
          isAdmin: true,
        },
        {
          GUID: uuid.v4(),
          ConversationGUID: conversationGUID,
          AccountGuid: this.props.user.accountGuid,
          isAdmin: true,
        },
      ],
    };
    console.log("CONVERSAtioN TO OPEN======");
    console.log(conversation);
    console.log(currentUser.GUID);

    await this.props.connection.invoke(
      "StartConversation",
      conversation,
      currentUser.GUID
    );
  };

  render() {
    return (
      <div
        onClick={() => this.onStartChat()}
        className="avatarItem relative float-left mx-1"
        title={this.props.user.accountName + " "+ this.props.user.accountEmail}
      >
        {this.props.fromSideBar ? (
          <div className="row sidebarOnline">
            <div className="avatarStatus float-left mt-1"></div>
            <label className="float-left ml-2">
              {this.props.user.accountName}
            </label>
          </div>
        ) : (
          <div className="">
            <img
              width="25"
              src={this.props.avatarUrl}
              className="avatar"
              alt={`call to ${this.props.user.accountName}`}
            />
            <div className="avatarStatus" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  connection: state.meet.connection,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserItem);
