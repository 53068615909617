export const INIT_EVENTS = "INIT_EVENTS";
export const LOADING_EVENTS = "LOADING_EVENTS";
export const LOADED_EVENTS = "LOADED_EVENTS";

export const GETTING_EVENTS = "GETTING_EVENTS";
export const GET_EVENTS_SUCCESSFULLY = "GET_EVENTS_SUCCESSFULLY";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESSFULL = "GET_EVENT_SUCCESSFULL";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const EDITING_EVENT = "EDITING_EVENT";
export const EDITED_EVENT = "EDITED_EVENT";
export const EDITING_EVENT_ERROR = "EDITING_EVENT_ERROR";

export const CLEAN_EVENT = "CLEAN_EVENT";

export const GET_SCHEDULES_MASTER = "GET_SCHEDULES_MASTER";
export const GET_SCHEDULES_MASTER_SUCCESS = "GET_SCHEDULES_MASTER_SUCCESS";
export const GET_SCHEDULES_MASTER_ERROR = "GET_SCHEDULES_MASTER_ERROR";
export const EXPORTING_SCHEDULES_MASTER = "EXPORTING_SCHEDULES_MASTER";
export const EXPORTED_SCHEDULES_MASTER = "EXPORTED_SCHEDULES_MASTER";
export const EXPORTING_SCHEDULES_MASTER_ERROR =
  "EXPORTING_SCHEDULES_MASTER_ERROR";

export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const GET_ATTACHMENTS_SUCCESS = "GET_ATTACHMENTS_SUCCESS";
export const GET_ATTACHMENTS_ERROR = "GET_ATTACHMENTS_ERROR";

export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_ERROR = "GET_CONTACTS_ERROR";

export const GET_GROUNDS = "GET_GROUNDS";
export const GET_GROUNDS_SUCCESS = "GET_GROUNDS_SUCCESS";
export const GET_GROUNDS_ERROR = "GET_GROUNDS_ERROR";

export const GET_HOTEL = "GET_HOTEL";
export const GET_HOTEL_SUCCESS = "GET_HOTEL_SUCCESS";
export const GET_HOTEL_ERROR = "GET_HOTEL_ERROR";

export const POPULATE_VENUES = "POPULATE_VENUES";
export const EMPTY_VENUES = "EMPTY_VENUES";

export const GETTING_VENUE = "GETTING_VENUE";
export const GET_VENUE_SUCCESSFUL = "GET_VENUE_SUCCESSFUL";
export const GET_VENUE_ERROR = "GET_VENUE_ERROR";
export const CREATING_VENUE = "CREATING_VENUE";
export const CREATED_VENUE_SUCCESS = "CREATED_VENUE_SUCCESS";
export const CREATING_VENUE_ERROR = "CREATING_VENUE_ERROR";
export const EDITING_VENUE = "EDITING_VENUE";
export const EDITED_VENUE_SUCCESS = "EDITED_VENUE_SUCCESS";
export const EDITING_VENUE_ERROR = "EDITING_VENUE_ERROR";

export const CREATING_CONTACT = "CREATING_CONTACT";
export const CREATED_CONTACT = "CREATED_CONTACT";
export const CREATING_CONTACT_ERROR = "CREATING_CONTACT_ERROR";
export const EDITED_CONTACT = "EDITED_CONTACT";
export const DELETING_CONTACT = "DELETING_CONTACT";
export const DELETING_CONTACT_SUCCESS = "DELETING_CONTACT_SUCCESS";
export const DELETING_CONTACT_ERROR = "DELETING_CONTACT_ERROR";

export const CREATING_ITINERARY = "CREATING_ITINERARY";
export const CREATED_ITINERARY_SUCCESS = "CREATED_ITINERARY_SUCCESS";
export const CREATING_ITINERARY_ERROR = "CREATING_ITINERARY_ERROR";
export const DELETING_ITINERARY = "DELETING_ITINERARY";
export const DELETED_ITINERARY_SUCCESS = "DELETED_ITINERARY_SUCCESS";
export const DELETING_ITINERARY_ERROR = "DELETING_ITINERARY_ERROR";
export const EDITING_ITINERARY = "EDITING_ITINERARY";
export const EDITED_ITINERARY = "EDITED_ITINERARY";
export const EDITING_ITINERARY_ERROR = "EDITING_ITINERARY_ERROR";

export const CREATING_ACTIVITY = "CREATING_ACTIVITY";
export const CREATED_ACTIVITY_SUCCESS = "CREATED_ACTIVITY_SUCCESS";
export const CREATING_ACTIVITY_ERROR = "CREATING_ACTIVITY_ERROR";
export const DELETING_ACTIVITY = "DELETING_ACTIVITY";
export const DELETED_ACTIVITY_SUCCESS = "DELETED_ACTIVITY_SUCCESS";
export const DELETING_ACTIVITY_ERROR = "DELETING_ACTIVITY_ERROR";
export const EDITING_ACTIVITY = "EDITING_ACTIVITY";
export const EDITED_ACTIVITY_SUCCESS = "EDITED_ACTIVITY_SUCCESS";
export const EDITING_ACTIVITY_ERROR = "EDITING_ACTIVITY_ERROR";
export const DELETING_ALL_ACTIVITIES = "DELETING_ALL_ACTIVITIES";
export const DELETED_ALL_ACTIVITIES = "DELETED_ALL_ACTIVITIES";
export const DELETING_ALL_ACTIVITIES_ERROR = "DELETING_ALL_ACTIVITIES_ERROR";

export const SAVING_TEMPLATE = "SAVING_TEMPLATE";
export const SAVED_TEMPLATE = "SAVED_TEMPLATE";

export const ADDING_ATTACHMENT = "ADDING_ATTACHMENT";
export const ADDED_ATTACHMENT_SUCCESS = "ADDED_ATTACHMENT_SUCCESS";
export const ADDING_ATTACHMENT_ERROR = "ADDING_ATTACHMENT_ERROR";
export const EDITING_ATTACHMENT = "EDITING_ATTACHMENT";
export const EDITED_ATTACHMENT_SUCCESS = "EDITED_ATTACHMENT_SUCCESS";
export const EDITING_ATTACHMENT_ERROR = "EDITING_ATTACHMENT_ERROR";
export const DELETING_ATTACHMENT = "DELETING_ATTACHMENT";
export const DELETED_ATTACHMENT_SUCCESS = "DELETED_ATTACHMENT_SUCCESS";
export const DELETING_ATTACHMENT_ERROR = "DELETING_ATTACHMENT_ERROR";

export const CREATING_HOTEL = "CREATING_HOTEL";
export const CREATED_HOTEL_SUCCESS = "CREATED_HOTEL_SUCCESS";
export const CREATED_HOTEL_SUCCESS_LOCAL = "CREATED_HOTEL_SUCCESS_LOCAL";
export const CREATING_HOTEL_ERROR = "CREATING_HOTEL_ERROR";
export const DELETING_HOTEL = "DELETING_HOTEL";
export const DELETED_HOTEL_SUCCESS = "DELETED_HOTEL_SUCCESS";
export const DELETING_HOTEL_ERROR = "DELETING_HOTEL_ERROR";
export const EDITING_HOTEL = "EDITING_HOTEL";
export const EDITED_HOTEL = "EDITED_HOTEL";
export const EDITING_HOTEL_ERROR = "EDITING_HOTEL_ERROR";

export const CREATING_GROUND = "CREATING_GROUND";
export const CREATED_GROUND_SUCCESS = "CREATED_GROUND_SUCCESS";
export const CREATING_GROUND_ERROR = "CREATING_GROUND_ERROR";
export const DELETING_GROUND = "DELETING_GROUND";
export const DELETED_GROUND_SUCCESS = "DELETED_GROUND_SUCCESS";
export const DELETING_GROUND_ERROR = "DELETING_GROUND_ERROR";
export const EDITING_GROUND = "EDITING_GROUND";
export const EDITED_GROUND = "EDITED_GROUND";
export const EDITING_GROUND_ERROR = "EDITING_GROUND_ERROR";
export const EDITING_PASSENGERS = "EDITING_PASSENGERS";
export const EDITED_PASSENGERS = "EDITED_PASSENGERS";
export const EDITING_PASSENGERS_ERROR = "EDITING_PASSENGERS_ERROR";
export const CLEANING_PASSENGERS = "CLEANING_PASSENGERS";
export const CLEANED_PASSENGERS = "CLEANED_PASSENGERS";
export const DELETING_PASSENGER = "DELETING_PASSENGER";
export const DELETED_PASSENGER = "DELETED_PASSENGER";
export const DELETING_PASSENGER_ERROR = "DELETING_PASSENGER_ERROR";

export const SET_VENUE = "SET_VENUE";

export const CREATING_EVENT = "CREATING_EVENT";
export const CREATED_EVENT_SUCCESS = "CREATED_EVENT_SUCCESS";
export const CREATING_EVENT_ERROR = "CREATING_EVENT_ERROR";
export const CREATING_MULTI_EVENTS = "CREATING_MULTI_EVENTS";
export const CREATED_MULTI_EVENTS_SUCCESS = "CREATED_MULTI_EVENTS_SUCCESS";
export const CREATING_MULTI_EVENTS_ERROR = "CREATING_MULTI_EVENTS_ERROR";

export const GET_VISIBLES = "GET_VISIBLES";
export const GET_VISIBLES_SUCCESS = "GET_VISIBLES_SUCCESS";
export const GET_VISIBLES_ERROR = "GET_VISIBLES_ERROR";
export const EDITING_VISIBLES = "EDITING_VISIBLES";
export const EDITED_VISIBLES = "EDITED_VISIBLES";
export const EDITING_VISIBLES_ERROR = "EDITING_VISIBLES_ERROR";

export const FETCHED_EXTERNAL_FLIGHTS = "FETCHED_EXTERNAL_FLIGHTS";
export const FETCHING_EXTERNAL_FLIGHTS = "FETCHING_EXTERNAL_FLIGHTS";
export const FETCHING_EXTERNAL_FLIGHTS_ERROR =
  "FETCHING_EXTERNAL_FLIGHTS_ERROR";
export const FETCHED_EXTERNAL_FLIGHTS_EMPTY = "FETCHED_EXTERNAL_FLIGHTS_EMPTY";

export const TOGGLING_EVENT = "TOGGLING_EVENT";
export const TOGGLED_EVENT = "TOGGLED_EVENT";
export const TOGGLED_EVENT_NULL = "TOGGLED_EVENT_NULL";
export const TOGGLING_EVENT_ERROR = "TOGGLING_EVENT_ERROR";

export const CREATING_NOTE = "CREATING_NOTE";
export const CREATED_NOTE = "CREATED_NOTE";
export const CREATING_NOTE_ERROR = "CREATING_NOTE_ERROR";
export const GETTING_NOTES = "GETTING_NOTES";
export const GET_NOTES = "GET_NOTES";
export const GETTING_NOTES_ERROR = "GETTING_NOTES_ERROR";
export const EDITING_NOTES = "EDITING_NOTES";
export const EDITED_NOTES = "EDITED_NOTES";
export const EDITING_NOTES_ERROR = "EDITING_NOTES_ERROR";
export const DELETING_NOTES = "DELETING_NOTES";
export const DELETED_NOTES = "DELETED_NOTES";
export const DELETING_NOTES_ERROR = "DELETING_NOTES_ERROR";
export const UPDATING_NOTES = "UPDATING_NOTES";
export const UPDATED_NOTES = "UPDATED_NOTES";
export const UPDATING_NOTES_ERROR = "UPDATING_NOTES_ERROR";

export const GETTING_TEMPLATES = "GETTING_TEMPLATES";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GETTING_TEMPLATES_ERROR = "GETTING_TEMPLATES_ERROR";

export const CREATING_FROM_TEMPLATE = "CREATING_FROM_TEMPLATE";
export const CREATED_FROM_TEMPLATE = "CREATED_FROM_TEMPLATE";
export const CREATING_FROM_TEMPLATE_ERROR = "CREATING_FROM_TEMPLATE_ERROR";

export const POPULATE_LINKABLE_EVENTS = "POPULATE_LINKABLE_EVENTS";

export const EDITING_RESERVATIONS = "EDITING_RESERVATIONS";
export const EDITED_RESERVATIONS = "EDITED_RESERVATIONS";
export const EDITING_RESERVATIONS_ERROR = "EDITING_RESERVATIONS_ERROR";
export const EXPORTING_RESERVATIONS = "EXPORTING_RESERVATIONS";
export const EXPORTED_RESERVATIONS = "EXPORTED_RESERVATIONS";
export const EXPORTING_RESERVATIONS_ERROR = "EXPORTING_RESERVATIONS_ERROR";
export const DELETING_ALL_RESERVATIONS = "DELETING_ALL_RESERVATIONS";
export const DELETED_ALL_RESERVATIONS = "DELETED_ALL_RESERVATIONS";
export const DELETING_ALL_RESERVATIONS_ERROR =
  "DELETING_ALL_RESERVATIONS_ERROR";
export const DELETING_RESERVATION = "DELETING_RESERVATION";
export const DELETED_RESERVATION = "DELETED_RESERVATION";
export const DELETING_RESERVATION_ERROR = "DELETING_RESERVATION_ERROR";

export const ADDING_VEHICLE = "ADDING_VEHICLE";
export const ADDED_VEHICLE = "ADDED_VEHICLE";
export const ADDING_VEHICLE_ERROR = "ADDING_VEHICLE_ERROR";
export const EDITING_VEHICLE = "EDITING_VEHICLE";
export const EDITED_VEHICLE = "EDITED_VEHICLE";
export const EDITING_VEHICLE_ERROR = "EDITING_VEHICLE_ERROR";
export const DELETING_VEHICLE = "DELETING_VEHICLE";
export const DELETED_VEHICLE = "DELETED_VEHICLE";
export const DELETING_VEHICLE_ERROR = "DELETING_VEHICLE_ERROR";

export const GET_FLIGHTS = "GET_FLIGHTS";
export const GET_FLIGHTS_SUCCESS = "GET_FLIGHTS_SUCCESS";
export const GET_FLIGHTS_ERROR = "GET_FLIGHTS_ERROR";
export const DELETING_ALL_PASSENGERS = "DELETING_ALL_PASSENGERS";
export const DELETED_ALL_PASSENGERS = "DELETED_ALL_PASSENGERS";
export const DELETING_ALL_PASSENGERS_ERROR = "DELETING_ALL_PASSENGERS_ERROR";
export const DELETING_FLIGHT_PASSENGER = "DELETING_FLIGHT_PASSENGER";
export const DELETED_FLIGHT_PASSENGER = "DELETED_FLIGHT_PASSENGER";
export const DELETING_FLIGHT_PASSENGER_ERROR =
  "DELETING_FLIGHT_PASSENGER_ERROR";
export const CREATING_FLIGHT = "CREATING_FLIGHT";
export const CREATED_FLIGHT = "CREATED_FLIGHT";
export const CREATING_FLIGHT_ERROR = "CREATING_FLIGHT_ERROR";
export const EDITING_FLIGHT_PASSENGERS = "EDITING_FLIGHT_PASSENGERS";
export const EDITED_FLIGHT_PASSENGERS = "EDITED_FLIGHT_PASSENGERS";
export const EDITING_FLIGHT_PASSENGERS_ERROR =
  "EDITING_FLIGHT_PASSENGERS_ERROR";
export const EDITING_FLIGHT = "EDITING_FLIGHT";
export const EDITED_FLIGHT = "EDITED_FLIGHT";
export const EDITING_FLIGHT_ERROR = "EDITING_FLIGHT_ERROR";
export const DELETING_FLIGHT = "DELETING_FLIGHT";
export const DELETED_FLIGHT = "DELETED_FLIGHT";
export const DELETING_FLIGHT_ERROR = "DELETING_FLIGHT_ERROR";

export const EXPORTING_RESOURCE = "EXPORTING_RESOURCE";
export const EXPORTED_RESOURCE = "EXPORTED_RESOURCE";
export const EXPORTING_RESOURCE_ERROR = "EXPORTING_RESOURCE_ERROR";
