import * as constants from "./constants";
import api from "../../../common/api";

export function getNotifications(userGUID) {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GETTING_NOTIFICATIONS,
      });
      const request = await api.get(
        `/Notification/GetNotifications?AccountGUID=${userGUID}`
      );
      dispatch({
        type: constants.GET_NOTIFICATIONS,
        data: request.data,
      });
      console.log(request);
    } catch (error) {
      dispatch({
        type: constants.GETTING_NOTIFICATIONS_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.GETTING_NOTIFICATIONS: {
      return {
        ...state,
        loadingNotifications: true,
      };
    }
    case constants.GET_NOTIFICATIONS: {
      return {
        ...state,
        loadingNotifications: false,
        notifications: action.data,
      };
    }
    case constants.GETTING_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        loadingNotifications: false,
      };
    }
    default:
      return state;
  }
}
