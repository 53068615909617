import React, { useState, useEffect, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { CheckBox, DropDownListSub } from "../../components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../components/form/formField";
import moment from "moment";
import LinkModal from "../../components/common/linkModal";
import { ModalComponent } from "../../components/common";
import { Scrollbars } from "react-custom-scrollbars";
import api from "../../common/api";
import { SimpleLoader } from "../../components/common";
import { addHotelSchema } from "../../utils/validationsSchemas";
import DatePicker from "react-datepicker";
import { ChevronDown } from "mdi-material-ui";
import AddEditLabel from "../../components/hotel/AddEdiLabel";

import { useDebouncedCallback } from "use-debounce";
import { useIntl, FormattedMessage } from "react-intl";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddHotel = (props) => {
  let {
    toggleModal,
    event,
    addHotel,
    addHotelLocal,
    toggleLinkModal,
    linkModal,
    loading,
    eventsSelected,
    setHotelData,
    setToDate,
    setFromDate,
    linkableEvents,
    toggleEvent,
    edit,
    hotel,
    editHotel,

    labelModal,
    toggleLabelModal,
    label,
    onChangeLabel,
    setLabel,

    toggleReservationsModal,
  } = props;
  const [hotelResults, setHotelResult] = useState([]);
  const [loadingHotels, setLoadingHotels] = useState(false);
  useEffect(() => {
    document.getElementsByClassName("Name")[0].focus();
    if (edit) {
      setLabel(hotel.Label);
    }
  }, []);

  const searchForHotels = useDebouncedCallback(async (query) => {
    query = query
      .replaceAll("null", "")
      .replaceAll(", ,", ",")
      .replaceAll("&", "and");
    setLoadingHotels(true);
    try {
      const hotels = await api.get(`/venues/hotelsautocomplete?query=${query}`);
      setHotelResult(hotels.data.results);
      setLoadingHotels(false);
    } catch (error) {
      console.log({ error });
    }
  }, 300);

  return (
    <div className="hotel-transparent">
      <div className="event-page">
        <div className="event-expennd-section event-sidebar">
          {/* Begin container modal  */}

          <div className="text-head headerBg">
            <div className="text-left ">
              <h1>
                <FormattedMessage id="addHotel" defaultMessage="Add Hotel" />
              </h1>
              <small className="text-muted">
                {edit && label ? label : edit ? hotel.Label : ""}
              </small>
            </div>
            <div className="text-right">
              <button
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  marginTop: 8,
                  marginBottom: 8,
                }}
                onClick={() => toggleLabelModal()}
                type="button"
                className="btn btn-primary btn-sm"
              >
                {label ? (
                  <FormattedMessage
                    id="editLabel"
                    defaultMessage="Edit Label"
                  />
                ) : (
                  <FormattedMessage id="addLabel" defaultMessage="Add Label" />
                )}
              </button>
            </div>
          </div>
          <Formik
            enableReinitialize
            validationSchema={addHotelSchema}
            validateOnBlur={false}
            onSubmit={(values) =>
              edit ? editHotel(values, false, label) : addHotel(values)
            }
            initialValues={
              edit
                ? {
                    ...hotel,
                    Checkout: moment(hotel.Checkout).toDate(),
                    CheckoutTime: moment(hotel.Checkout).toDate(),
                    Checkin: moment(hotel.Checkin).toDate(),
                    CheckinTime: moment(hotel.Checkin).toDate(),
                    HotelReservations: null,
                  }
                : {
                    EventGUID: event.GUID,
                    Address: "",
                    Name: "",
                    Phone: "",
                    City: "",
                    Country: "",
                    State: "",
                    Label: "",
                    ZipCode: "",
                    Series: "",
                    Checkout: moment(event.Date).add(1, "days").toDate(),
                    CheckoutTime: moment(event.Date)
                      .set({ hour: 12, minute: 0 })
                      .toDate(),
                    Checkin: moment(event.Date).subtract(1, "days").toDate(),
                    CheckinTime: moment(event.Date)
                      .set({ hour: 15, minute: 0 })
                      .toDate(),
                    Website: "",
                    Gym: false,
                    Laundry: false,
                    BusinessCenter: false,
                    Spa: false,
                    Pool: false,
                    RestaurantBar: false,
                    Breakfast: false,
                    Internet: false,
                    Lunch: false,
                    Parking: false,
                    Dinner: false,
                    //Note: "",
                  }
            }
          >
            {(props) => (
              <Form autoComplete="off">
                <ModalComponent
                  toggle={() => toggleLinkModal()}
                  modal={linkModal}
                  childeren={
                    <LinkModal
                      eventsSelected={eventsSelected}
                      arrayEvents={linkableEvents}
                      reservationStepText={"Assign Members"}
                      reservationStep={true}
                      nextStep={() => toggleReservationsModal()}
                      toggle={() => toggleLinkModal()}
                      toggleEvent={toggleEvent}
                      skipStep={true}
                      loading={loading}
                    />
                  }
                />
                <ModalComponent
                  modal={labelModal}
                  toggle={() => toggleLabelModal()}
                  childeren={
                    <AddEditLabel
                      label={label}
                      onChangeLabel={onChangeLabel}
                      toggle={() => toggleLabelModal()}
                    />
                  }
                />
                <Scrollbars style={{ height: "calc(100vh - 125px)" }}>
                  <>
                    <div className="event-inside hotel row">
                      <FormField
                        fullWidth={true}
                        label={
                          <FormattedMessage
                            id="Hotel Name"
                            defaultMessage="Hotel Name"
                          />
                        }
                      >
                        <div className="tinyLoader__container">
                          <SimpleLoader loading={loadingHotels} />
                        </div>

                        <input
                          autoComplete="off"
                          className="form-control Name"
                          type="text"
                          id={"Name" + moment().format("YYYYMMDDHHSS")}
                          //name="Name"
                          name={"Name" + moment().format("YYYYMMDDHHSS")}
                          value={props.values.Name}
                          onFocus={(e) => {
                            e.target.setAttribute("autocomplete", "off");
                          }}
                          onChange={(e) => {
                            props.setFieldValue("Name", e.currentTarget.value);
                            if (e.currentTarget.value.length >= 3) {
                              searchForHotels.callback(
                                e.currentTarget.value.indexOf(",") > -1
                                  ? e.currentTarget.value
                                  : e.currentTarget.value +
                                      " " +
                                      (event.Venue && event.Venue.City !== null
                                        ? ", " +
                                          event.Venue.City +
                                          ", " +
                                          event.Venue.State +
                                          ", " +
                                          event.Venue.Country
                                        : ", " +
                                          event.City +
                                          ", " +
                                          event.State +
                                          ", " +
                                          event.Country)
                              );
                            } else if (e.currentTarget.value.length === 0) {
                              setHotelResult([]);
                            }
                          }}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Name"
                          component="div"
                        />
                        <DropDownListSub
                          searchList={hotelResults}
                          setData={async (id) => {
                            let selectedHotel = hotelResults[id];

                            let request = await api.get(
                              `/venues/googlevenuedetails?query=${selectedHotel.place_id}`
                            );
                            setHotelData(request.data.result, props);
                            setHotelResult([]);
                          }}
                          displayValue={"name"}
                          subDisplayValue={"formatted_address"}
                        />
                      </FormField>
                    </div>
                    <div className="text-head hotel">
                      <div className="text-left">
                        <h3>
                          <FormattedMessage
                            id="hotelInformation"
                            defaultMessage="Hotel Information"
                          />
                        </h3>
                      </div>
                    </div>

                    <div className="event-inside hotel">
                      <div className="event-content">
                        <div className="row">
                          <FormField
                            additionalClasses="px-0 pr-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="Address"
                                defaultMessage="Address"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="Address"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Address"
                              component="div"
                            />
                          </FormField>
                          <FormField
                            additionalClasses="px-0 pl-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="city"
                                defaultMessage="City"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="City"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="City"
                              component="div"
                            />
                          </FormField>
                          <FormField
                            additionalClasses="px-0 pr-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="state.signUp"
                                defaultMessage="State"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="State"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="State"
                              component="div"
                            />
                          </FormField>

                          <FormField
                            additionalClasses="px-0 pr-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="zipCode"
                                defaultMessage="Zip Code"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="ZipCode"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="ZipCode"
                              component="div"
                            />
                          </FormField>
                          <FormField
                            additionalClasses="px-0 null"
                            fullWidth={true}
                            label={
                              <FormattedMessage
                                id="country.signUp"
                                defaultMessage="Country"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="Country"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Country"
                              component="div"
                            />
                          </FormField>
                        </div>
                        <div className="row">
                          <FormField
                            additionalClasses="px-0 pr-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="phoneNumber.signUp"
                                defaultMessage="Phone Number"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="Phone"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Phone"
                              component="div"
                            />
                          </FormField>
                          <FormField
                            additionalClasses="px-0 pr-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="website"
                                defaultMessage="Website"
                              />
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="Website"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Website"
                              component="div"
                            />
                          </FormField>
                        </div>
                      </div>
                    </div>
                    <div className="text-head hotel">
                      <div className="text-left">
                        <h3>
                          <FormattedMessage
                            id="checkInformation"
                            defaultMessage="Check In Information"
                          />
                        </h3>
                      </div>
                    </div>

                    <div className="event-inside hotel">
                      <div className="event-content">
                        <div className="form-hotel">
                          <div className="row">
                            <div className="col px-0 pt-2">
                              <div>
                                <label htmlFor="checkinTime">
                                  <FormattedMessage
                                    id="checkTime"
                                    defaultMessage="Check In Time"
                                  />
                                </label>
                                {true && (
                                  <DatePicker
                                    id={"checkinTime"}
                                    selected={props.values.CheckinTime}
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "CheckinTime",
                                        moment(e).toDate()
                                      );
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                )}
                                <ErrorMessage
                                  className="error-message"
                                  name="checkinTime"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col pr-0 pt-2">
                              <>
                                <label htmlFor={"checkinDate"}>
                                  <FormattedMessage
                                    id="checkDate"
                                    defaultMessage="Check In Date"
                                  />
                                </label>
                                <div>
                                  <input
                                    type="date"
                                    id={"checkinDate"}
                                    className="form-control underline py-0"
                                    value={moment(props.values.Checkin).format(
                                      "YYYY-MM-DD"
                                    )}
                                    onChange={(e) => {
                                      let date = e.target.value;
                                      try {
                                        props.setFieldValue(
                                          "Checkin",
                                          date
                                          // +
                                          //   "T" +
                                          //   moment(props.values.Checkin).format(
                                          //     "HH:mm:ss"
                                          //   )
                                        );
                                      } catch (ex) {
                                        console.error(ex);
                                        debugger;
                                      }
                                    }}
                                  />
                                  {/* <DatePicker
                                    id={"checkinDate"}
                                    dateFormat="MMMM dd, yyyy"
                                    showPopperArrow={false}
                                    selected={moment(
                                      props.values.Checkin
                                    ).toDate()}
                                    onChange={(e) => {
                                      setFromDate(e);
                                      props.setFieldValue(
                                        "Checkin",
                                        moment(e).format("YYYY-MM-DD")
                                      );
                                    }}
                                  /> */}
                                  {/* <div
                                    className="drop-icon"
                                    onClick={() =>
                                      document
                                        .getElementById("checkinDate")
                                        .focus()
                                    }
                                  >
                                    <ChevronDown />
                                  </div> */}
                                </div>
                              </>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col px-0 pt-2">
                              <div>
                                <label htmlFor="CheckoutTime">
                                  <FormattedMessage
                                    id="checkOutTime"
                                    defaultMessage="Check Out Time"
                                  />
                                </label>
                                {true && (
                                  <DatePicker
                                    id={"CheckoutTime"}
                                    selected={props.values.CheckoutTime}
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "CheckoutTime",
                                        moment(e).toDate()
                                      );
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                )}
                                <ErrorMessage
                                  className="error-message"
                                  name="checkinTime"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col pr-0 pt-2">
                              <>
                                <label htmlFor={"checkOutDate"}>
                                  <FormattedMessage
                                    id="checkOutDate"
                                    defaultMessage="Check Out Date"
                                  />
                                </label>
                                <div>
                                  {/* <DatePicker
                                    id={"checkOutDate"}
                                    dateFormat="MMMM dd, yyyy"
                                    showPopperArrow={false}
                                    selected={moment(
                                      props.values.Checkout
                                    ).toDate()}
                                    onChange={(e) => {
                                      setToDate(e);
                                      props.setFieldValue(
                                        "Checkout",
                                        moment(e).format("YYYY-MM-DD")
                                      );
                                    }}
                                  />
                                  <div
                                    className="drop-icon"
                                    onClick={() =>
                                      document
                                        .getElementById("checkOutDate")
                                        .focus()
                                    }
                                  >
                                    <ChevronDown />
                                  </div> */}
                                  <input
                                    type="date"
                                    id={"checkoutDate"}
                                    className="form-control underline pt-0 pb-0"
                                    value={moment(props.values.Checkout).format(
                                      "YYYY-MM-DD"
                                    )}
                                    onChange={(e) => {
                                      let date = e.target.value;
                                      try {
                                        props.setFieldValue(
                                          "Checkout",
                                          date
                                          // +
                                          //   "T" +
                                          //   moment(
                                          //     props.values.Checkout
                                          //   ).format("HH:mm:ss")
                                        );
                                      } catch (ex) {
                                        console.error(ex);
                                        debugger;
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-head hotel">
                      <div className="text-left">
                        <h3>
                          <FormattedMessage
                            id="included"
                            defaultMessage="Included"
                          />
                        </h3>
                      </div>
                    </div>

                    <div className="event-inside hotel">
                      <div className="event-content">
                        <div className="form-hotel">
                          <div className="row">
                            <div className="col-md-4">
                              <CheckBox
                                header={"Breakfast"}
                                id={"breakfast"}
                                value={props.values.Breakfast}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Breakfast",
                                    !props.values.Breakfast
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Wi-Fi"}
                                id={"internet"}
                                value={props.values.Internet}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Internet",
                                    !props.values.Internet
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Lunch"}
                                id={"lunch"}
                                value={props.values.Lunch}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Lunch",
                                    !props.values.Lunch
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Parking"}
                                id={"parking"}
                                value={props.values.Parking}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Parking",
                                    !props.values.Parking
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Dinner"}
                                id={"dinner"}
                                value={props.values.Dinner}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Dinner",
                                    !props.values.Dinner
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-head hotel">
                      <div className="text-left">
                        <h3>
                          <FormattedMessage
                            id="amenities"
                            defaultMessage="Amenities"
                          />
                        </h3>
                      </div>
                    </div>

                    <div className="event-inside hotel">
                      <div className="event-content">
                        <div className="form-hotel">
                          <div className="row">
                            <div className="col-md-4">
                              <CheckBox
                                header={"Gym"}
                                id={"gym"}
                                value={props.values.Gym}
                                onChange={(e) =>
                                  props.setFieldValue("Gym", !props.values.Gym)
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Laundry"}
                                id={"laundry"}
                                value={props.values.Laundry}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Laundry",
                                    !props.values.Laundry
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Business Center"}
                                id={"business"}
                                value={props.values.BusinessCenter}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "BusinessCenter",
                                    !props.values.BusinessCenter
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Spa"}
                                id={"spa"}
                                value={props.values.Spa}
                                onChange={(e) =>
                                  props.setFieldValue("Spa", !props.values.Spa)
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Pool"}
                                id={"pool"}
                                value={props.values.Pool}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "Pool",
                                    !props.values.Pool
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <CheckBox
                                header={"Restaurant/Bar"}
                                id={"bar"}
                                value={props.values.RestaurantBar}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "RestaurantBar",
                                    !props.values.RestaurantBar
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Scrollbars>

                <div className="event-inside hotel">
                  <div className="event-content">
                    <div
                      className="button-section py-0"
                      style={{ display: "block" }}
                    >
                      {edit ? (
                        loading ? (
                          <div className="text-center">
                            <SimpleLoader loading={loading} />
                          </div>
                        ) : (
                          <button
                            disabled={!props?.values?.Name?.length}
                            type="submit"
                            className="btn primary block mt-3"
                          >
                            <FormattedMessage id="done" defaultMessage="Done" />
                          </button>
                        )
                      ) : linkableEvents.length > 0 ? (
                        <button
                          disabled={!props?.values?.Name?.length || loading}
                          onClick={() => toggleLinkModal()}
                          type="button"
                          className="btn primary block mt-3"
                        >
                          Done & Link
                        </button>
                      ) : (
                        <button
                          disabled={!props?.values?.Name?.length || loading}
                          onClick={() => {
                            addHotelLocal(props.values);
                          }}
                          type="button"
                          className="btn primary block mt-3"
                        >
                          <FormattedMessage
                            id="Done & Assign"
                            defaultMessage="Done & Assign"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          {/* End container modal */}
        </div>
      </div>
    </div>
  );
};

export default AddHotel;