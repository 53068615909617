import { useState as Hookstate } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import { IMAGES } from "../../constant";
import { getBasicInfo, getOrganization } from "../../utils";
import Popup from "reactjs-popup";
import AddEditFolder from "./AddEditFolder";
import DropboxChooser from "../../components/common/DropboxChooser";
import AddEditLink from "./AddEditLink";
import {
  ModalComponent,
  ToastNotificationSuccess,
  IframeGoogleDoc,
} from "../../components/common";
import Navbar from "../../components/common/navbar";
import uuid from "uuid";
import api, { apiService } from "../../common/api";
import Swal from "sweetalert2";
import EmptyState from "../../components/common/emptyState";
import DragAndDrop from "../../components/common/DragAndDrop";
import ExportToEvent from "../../components/common/ExportToEvent";
import * as organizationActions from "../organization/redux/actions";
import db from "../../common/db";
import AssignToEvents from "./AssignToEvents";
import { Progress } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import { copyToClipboard, openInNewTab, typeIconEquivalent } from "./helper";
import FileDetails from "./fileDetails";
import ListFolders from "./listFolders";
import { useIntl, FormattedMessage } from "react-intl";

import EMPTY_ATTACHMENTS from "../../images/attachments-empty.svg";
import { DropboxKey } from "../../common/helpers";
import { SandwichButton } from "../../components/common/SandwichButton";
import { useGooglePicker } from "../../hooks/useGooglePicker";
import { googleApi } from "../../constant/googleApi";
import { OneDrivePicker } from "../../components/common/OneDrivePicker";
import { useHistory } from "react-router-dom";
import { useBetaFeature } from "../../hooks/useBetaFeature";
import { EditAttachmentModal } from "../../components/attachments/EditAttachment";
import { DocsComponent } from "./editors/DocsComponent";
import { SpreadSheets } from "./editors/SpreadSheets";
import { PDFComponent } from "./editors/PDFComponent";
import { Drag } from "mdi-material-ui";
import { FileGripComponent } from "./FileGripComponent";
import { getAttachmentsEvent } from "../event/redux/getAttachmentsEvent";

function Files(props) {
  const state = Hookstate({
    CurrentFolderPath: [],
    folderNavigationHistory: [],
    Files: [],
    UI: {
      showAddEditFolder: false,
      isLoading: false,
      ShowPickFolder: false,
      Progress: 0,
    },
    CurrentUser: {},
    ChoosenFolder: {},
    SelectItemDelete: {},
    SelectedFolder: "/",
    SelectedFile: null,
    showViewerModal: false,
    previewFile: null,
    ChoosenLink: null,
    SelectedFiles: [],
    showVisibleByEvents: false,
    Events: [],
    showDocView: false,
    showSheetView: false,
    showPDFView: false,
  });

  const [showEditAttachment, setShowEditAttachment] = useState(false);

  const toggleEditAttachment = () => {
    setShowEditAttachment((prev) => !prev);
  };

  const [isLocalDraggin, setIsLocalDraggin] = useState(false);
  const [localDraggedFile, setLocalDraggedFile] = useState(null);
  const [currentView, setCurrentView] = useState("");

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    const currentOrganizationGUID = getOrganization().GUID;
    const basicInfoGUID = getBasicInfo().GUID;
    props.organizationActions.getOrganizationList(basicInfoGUID);
    props.organizationActions.getCurrentOrganization(
      currentOrganizationGUID,
      basicInfoGUID
    );

    state.CurrentUser.set(getBasicInfo());
    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath.value));
    copy.push({ GUID: "/", Name: "/" });
    state.CurrentFolderPath.set(copy);
    requestFolderRemote();
  }, []);

  useEffect(() => {
    const currentView = localStorage.getItem("fileView");
    console.log({ currentView });
    setCurrentView(currentView || "list");
  }, []);

  const openItem = (item) => {
    //First check the type
    switch (item.Type) {
      case "folder":
        enterFolder(item);
        break;
      case "file":
      case "link":
        openFile(item);

        break;
    }
  };

  const enterFolder = (item) => {
    state.SelectedFile.set(null);
    state.SelectedFolder.set(item.GUID);
    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath.value));
    var exist = copy.some((x) => x.GUID === item.GUID);
    if (exist) {
      //If exist we must go over the array and create a new one until this record
      let newArray = [];
      for (var i = 0; i < copy.length; i++) {
        newArray.push(copy[i]);
        if (copy[i].GUID === item.GUID) {
          break;
        }
      }

      copy = newArray;
    } else {
      copy.push({ GUID: item.GUID, Name: item.Name });
    }

    state.CurrentFolderPath.set(copy);

    let historyFolders = JSON.parse(
      JSON.stringify(state.folderNavigationHistory.value)
    );
    let firstFolder = copy.length > 1 ? copy[1] : null;

    historyFolders = historyFolders?.[1]?.GUID;
    firstFolder = firstFolder?.GUID;

    if (
      (firstFolder && historyFolders !== firstFolder) ||
      state.folderNavigationHistory.length < copy.length
    ) {
      state.folderNavigationHistory.set(copy);
    }

    requestFolderRemote();
  };
  const openFile = (item) => {
    state.SelectedFile.set(JSON.parse(JSON.stringify(item)));
    openInNewTab(item);
  };

  const toggleEvents = async () => {
    state.showVisibleByEvents.set(!state.showVisibleByEvents.value);
    if (state.showVisibleByEvents.value) {
      const currentOrganizationGUID = getOrganization().GUID;
      const event = await db
        .table("events")

        .where({ OrganizationGUID: currentOrganizationGUID, Deleted: null })

        .sortBy("Date");
      state.Events.set(event);
      /*   .toArray();
               var parsed = event.filter(x=> x.Date !== null);
               state.Events.set(parsed);*/
    } else {
      await requestFolderRemote();
    }
  };

  const openPreview = () => {
    let item = state.SelectedFile.value;
    let icon = item.Path.split(".").pop();
    let result = null;
    switch (icon) {
      case "jpg":
      case "png":
      case "jpeg":
        result = (
          <div className="image-viewer">
            <img
              className="img-fluid"
              src={
                item.Path.includes("google") || item.Path.includes("1drv")
                  ? item.Path
                  : `https://service.plnify.com/files/${item.Path}`
              }
            />
          </div>
        );

      default:
        result = (
          <IframeGoogleDoc
            url={
              item.Path.includes("google") || item.Path.includes("1drv")
                ? item.Path
                : `https://service.plnify.com/files/${item.Path}`
            }
          />
        );
    }

    return result;
  };
  const intl = useIntl();
  const deleteFile = (item) => {
    Swal.fire({
      text:
        !item?.length >= 1
          ? intl.formatMessage({ id: "areYouDelete" }) + item.Name + "'?"
          : "Are you sure you want to deleted selected files?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        if (item?.length >= 1) {
          state.UI.isLoading.set(true);
          item?.forEach(async (itemAll, index) => {
            const request = await api.get("/File/DeleteFile?Id=" + itemAll);
            let response = request.data;
            if (response.Status === "ok") {
              requestFolderRemote();
            }
          });
          state.UI.isLoading.set(false);
        } else {
          state.UI.isLoading.set(true);
          item = { ...item };
          const request = await api.get("/File/DeleteFile?Id=" + item.GUID);
          let response = request.data;
          state.UI.isLoading.set(false);
          if (response.Status === "ok") {
            requestFolderRemote();
          }
        }
      }
    });
  };

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const fileRef = React.useRef(null);

  const handleLocalDrag = (item) => {
    sessionStorage.setItem("draggedFile", JSON.stringify(item));
  };

  const moveHandler = async (folderGUID, itemGUID) => {
    try {
      state.UI.isLoading.set(!state.UI.isLoading.value);
      const request = await api.post(`/File/Move?Target=${folderGUID}`, [
        { GUID: itemGUID },
      ]);
      state.UI.isLoading.set(!state.UI.isLoading.value);
      requestFolderRemote();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDrop = async (files, folder) => {
    const localDraggedFile = JSON.parse(sessionStorage.getItem("draggedFile"));

    if (files) {
      onChangeDrop(files, folder.GUID);
    } else {
      moveHandler(folder.GUID, localDraggedFile.GUID);
    }
    setIsLocalDraggin(false);
    setLocalDraggedFile(null);
    sessionStorage.removeItem("draggedFile");
  };

  const requestFiles = () => {
    let files = state.Files.get().map((item, index) => {
      const selectedFile = state?.SelectedFile?.value;
      let newFile = (
        <div
          key={item.GUID}
          draggable={item.Type === "folder" ? false : true}
          ref={fileRef}
          onDragStart={
            item.Type !== "folder"
              ? (e) => {
                  handleLocalDrag(item);
                }
              : () => {}
          }
          onDragEnd={
            item.Type !== "folder"
              ? (e) => {
                  setIsLocalDraggin(false);
                }
              : () => {}
          }
          className={
            selectedFile?.GUID === item.GUID
              ? `${
                  currentView === "list"
                    ? "col-12 px-0 py-2 border-bottom mx-0"
                    : "file-grid-item"
                } active clickable`
              : `${
                  currentView === "list"
                    ? "col-12 px-0 py-2 border-bottom mx-0"
                    : "file-grid-item"
                } clickable`
          }
          onClick={() => {
            state.SelectedFile.set(null); //this is for reset purposes
            state.SelectedFile.set(JSON.parse(JSON.stringify(item)));
          }}
          onDoubleClick={() => {
            const fileExt = item.Path.split(".").pop();
            if (fileExt.includes("doc") || fileExt.includes("docx")) {
              toggleDocView();
            } else if (fileExt.includes("xls") || fileExt.includes("xlsx")) {
              toggleSheetView();
            } else if (fileExt.includes("pdf")) {
              togglePDFView();
            } else {
              openItem(item);
            }
          }}
        >
          <div className="row filesContainer">
            {currentView === "list" && (
              <div className="col-auto text-center px-0">
                <button
                  className="btn btn-sm"
                  onClick={() => MarkAsSelected(item)}
                >
                  {state.SelectedFiles.value.includes(item.GUID) ? (
                    <i className="fas fa-check-square checked text-secondary"></i>
                  ) : (
                    <i className="far fa-square uncheck  text-secondary"></i>
                  )}
                </button>
              </div>
            )}
            <div
              className={`${
                currentView === "list"
                  ? "col-auto text-center"
                  : "text-center col-12 fs-2xl"
              }`}
            >
              <i
                style={
                  item.Color
                    ? { color: item.Color }
                    : item.Type === "folder"
                    ? { color: "#E6E8EA" }
                    : null
                }
                className={typeIconEquivalent(item) + " fa-2xl"}
              />
            </div>
            <div className={currentView === "list" ? "col-8" : "col-10 m-auto"}>
              <h5
                title={item.Name}
                className={
                  currentView === "list"
                    ? "title mt-1 "
                    : "title mt-1 text-truncate"
                }
                onClick={() => {
                  state.SelectedFile.set(null); //this is for reset purposes
                  state.SelectedFile.set(JSON.parse(JSON.stringify(item)));
                }}
                // onDoubleClick={() => {
                //   openItem(item);
                // }}
              >
                {item.Name}
                {item?.EventShared?.length > 4 ? (
                  <button
                    className="btn btn-sm  ml-2 p-1"
                    onClick={() => {
                      state.SelectedFile.set(null); //this is for reset purposes
                      // state.SelectedFile.set(JSON.parse(JSON.stringify(item)));
                      MarkAsSelected(item);
                      toggleEvents();
                    }}
                  >
                    <i className="fas fa-link text-secondary fa-xs"></i>
                  </button>
                ) : null}
              </h5>
            </div>
            <div className="col-2 text-right ml-auto">
              <Popup
                trigger={
                  <button type="button" className="btn btn-sm">
                    <i className="fa fa-ellipsis-v" />
                  </button>
                }
                on="click"
                position="bottom right"
                arrow={false}
              >
                {(open) => (
                  <>
                    {item.Type === "folder" ? (
                      <button
                        onClick={() => {
                          openFolderForEdit({
                            Name: item.Name,
                            GUID: item.GUID,
                            Color: item.Color,
                            UserGUID: item.UserId,
                            ParentGUID: item.ParentGUID,
                            EventShared: item.EventShared,
                            Note: item.Note,
                          });
                          open();
                        }}
                        className="btn btn-block flex-1"
                        type="button"
                      >
                        {" "}
                        <FormattedMessage id="Edit" defaultMessage="Edit" />
                      </button>
                    ) : null}
                    {item.Type !== "folder" ? (
                      <button
                        type="button"
                        className="btn btn-block flex-1"
                        onClick={() => {
                          copyToClipboard(item);
                          open();
                        }}
                        title="Copy to clipboard"
                      >
                        <FormattedMessage id="share" defaultMessage="Share" />
                      </button>
                    ) : null}

                    {item.Type !== "folder" && (
                      <button
                        className="btn btn-block flex-1"
                        type="button"
                        onClick={() => {
                          toggleEditAttachment();
                          open();
                        }}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      className="btn btn-block flex-1"
                      type="button"
                      onClick={() => {
                        //Select the item
                        MarkAsSelected(item, true);
                        //Toggle folders seleciton
                        toggleEvents();
                        open();
                      }}
                    >
                      Link to events
                    </button>
                    <button
                      className="btn btn-block flex-1"
                      type="button"
                      onClick={() => {
                        //Select the item
                        MarkAsSelected(item, true);
                        //Toggle folders seleciton
                        toggleListFolder();
                        open();
                      }}
                    >
                      <FormattedMessage id="move" defaultMessage="Move" />
                    </button>

                    <button
                      onClick={() => {
                        if (item.Type === "folder") {
                          deleteFolderRemote(item);
                        } else {
                          deleteFile(item);
                        }
                        open();
                      }}
                      className="btn btn-block flex-1 mt-1"
                      type="button"
                    >
                      <FormattedMessage id="delete" defaultMessage="Delete" />
                    </button>
                  </>
                )}
              </Popup>
            </div>
            {/* <div className="col-12">
              <hr className=" my-1 py-1" />
            </div> */}
          </div>
        </div>
      );

      if (item.Type === "folder") {
        return (
          <div className="row">
            <DragAndDrop
              handleDragOut={handleDragOut}
              handleDropCalendarEvent={(e) => handleDrop(e, item)}
              fontSize={18}
              className="col-12 px-0  mx-0"
            >
              {newFile}
            </DragAndDrop>
          </div>
        );
      } else {
        return newFile;
      }
    });

    return files;
  };

  const SelectAll = () => {
    let objs = state.Files.value;

    let selectedFields = [];
    if (state.SelectedFiles.value.length === objs.length) {
      //Un selected all

      state.SelectedFiles.set([]);
    } else if (state.SelectedFiles.value.length === 0) {
      //Select all

      objs.forEach((record) => {
        selectedFields.push(record.GUID);
      });

      state.SelectedFiles.set(selectedFields);
    } else {
      //un select all
      state.SelectedFiles.set([]);
    }
  };
  const MarkAsSelected = (record, onlyThisFile) => {
    let SelectItemDeleteAll = JSON.parse(JSON.stringify(record));
    let selectedFields = JSON.parse(JSON.stringify(state.SelectedFiles.value));

    if (onlyThisFile !== undefined && onlyThisFile === true) {
      selectedFields.push(record.GUID);
    } else {
      if (selectedFields.includes(record.GUID)) {
        selectedFields = selectedFields.filter((x) => x !== record.GUID); //We are removing the existing file
      } else {
        selectedFields.push(record.GUID);
      }
    }
    state.SelectedFiles.set(selectedFields);
    state.SelectItemDelete.set(SelectItemDeleteAll);
  };

  const openFolderForEdit = (choosenFolder) => {
    if (choosenFolder !== null) {
      state.ChoosenFolder.set(choosenFolder);
    }
    toggleAddEditFolder();
  };
  const toggleAddEditFolder = () => {
    state.UI.showAddEditFolder.set(!state.UI.showAddEditFolder.value);
    if (!state.UI.showAddEditFolder.value) {
      requestFolderRemote();
    }
  };

  const toggleListFolder = () => {
    state.UI.ShowPickFolder.set(!state.UI.ShowPickFolder.value);
    if (!state.UI.ShowPickFolder.value) {
      requestFolderRemote();
    }
  };
  const openLinkForEdit = (choosen) => {
    if (choosen !== null) {
      state.ChoosenLink.set(choosen);
    }
    toggleAddEditLink();
  };
  const toggleAddEditLink = () => {
    state.UI.showAddEditLink.set(!state.UI.showAddEditLink.value);
    if (!state.UI.showAddEditLink.value) {
      requestFolderRemote();
    }
  };

  const requestFolderRemote = async () => {
    state.SelectedFiles.set([]);
    state.UI.isLoading.set(!state.UI.isLoading.value);
    const request = await apiService.get(
      "/File/List?UserID=" +
        state.CurrentUser.GUID.value +
        (state.SelectedFolder.value !== "/"
          ? "&FolderId=" + state.SelectedFolder.value
          : "&FolderId=")
    );
    state.UI.isLoading.set(!state.UI.isLoading.value);
    let response = request.data;
    console.log({ response });
    if (response.Status === "ok") {
      state.Files.set(response.Response.filter((item) => !item.Deleted));
      //   state.SelectedFile.set(null);
    }
  };

  const deleteFolderRemote = (item, deleteType) => {
    item = deleteType === "multiple" ? item : [item.GUID];
    Swal.fire({
      text:
        intl.formatMessage({ id: "areYouDelete" }) +
        "selected folder(s)" +
        "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        state.UI.isLoading.set(!state.UI.isLoading.value);
        item.forEach(async (itemAll, index) => {
          const request = await api.get("/File/DeleteFolder?Id=" + itemAll);
          let response = request.data;
          if (response.Status === "ok") {
            requestFolderRemote();
          }
        });
        state.UI.isLoading.set(!state.UI.isLoading.value);
        // const request = await api.get("/File/DeleteFolder?Id=" + item.GUID);
        // state.UI.isLoading.set(!state.UI.isLoading.value);
        // let response = request.data;
        // if (response.Status === "ok") {
        //     requestFolderRemote();
        //     //state.SelectedFile.set(null);
        // }
      }
    });
  };

  const AddAttachments = (e) => {
    var theFiles = Array.from(e.target.files);

    theFiles?.forEach(async (item) => {
      let newItem = {
        GUID: uuid.v4(),
        Name: item.name,
        Note: "",
        Path: item.name,
        UserGUID: state.CurrentUser.GUID.value,
        ParentGUID:
          state.SelectedFolder.value === "/"
            ? null
            : state.SelectedFolder.value,
        Type: "file",
      };
      state.UI.isLoading.set(!state.UI.isLoading.value);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 101) {
            state.UI.Progress.set(percent);
          }
          if (percent == 100) {
            state.UI.Progress.set(0);
          }
        },
      };
      const bodyData = new FormData();
      bodyData.append("path", item);
      const responseFile = await apiService.post(`/File/upload/`, bodyData);
      const requestGeneral = await api.post("/File/AddEditFileWithoutFile", {
        ...newItem,
        Path: responseFile.data.Response,
      });

      state.UI.isLoading.set(!state.UI.isLoading.value);
      await requestFolderRemote();
    });
  };

  const onChangeDrop = (files, folderGUID) => {
    let theFiles = Array.from(files);
    theFiles?.forEach(async (item) => {
      let newItem = {
        GUID: uuid.v4(),
        Name: item.name,
        Note: "",
        Path: item.name,
        UserGUID: state.CurrentUser.GUID.value,
        ParentGUID: folderGUID
          ? folderGUID
          : state.SelectedFolder.value === "/"
          ? null
          : state.SelectedFolder.value,
        Type: "file",
      };
      state.UI.isLoading.set(!state.UI.isLoading.value);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 101) {
            state.UI.Progress.set(percent);
          }
          if (percent == 100) {
            state.UI.Progress.set(0);
          }
        },
      };

      const bodyData = new FormData();
      bodyData.append("path", item);
      const responseFile = await apiService.post(`/File/upload/`, bodyData);
      const requestGeneral = await apiService.post(
        "/File/AddEditFileWithoutFile",
        {
          ...newItem,
          Path: responseFile.data.Response,
        }
      );

      state.UI.isLoading.set(!state.UI.isLoading.value);
      await requestFolderRemote();
    });
  };
  const toggleExportToEvent = (exportMode, reload) => {
    state.UI.showExportToEvent.set(!state.UI.showExportToEvent.value);
    state.UI.exportMode.set(exportMode);
    if (reload) {
      props.actions.getAttachmentsEvent(props.originEvent.GUID);
    }
  };
  const uploadDropbox = (files) => {
    let theFiles = files;
    theFiles?.forEach(async (item) => {
      let newItem = {
        GUID: uuid.v4(),
        Name: item.name,
        Note: "",
        Path: item.link || item.url || item.webUrl,
        UserGUID: state.CurrentUser.GUID.value,
        ParentGUID:
          state.SelectedFolder.value === "/"
            ? null
            : state.SelectedFolder.value,
        Type: "link",
      };
      state.UI.isLoading.set(!state.UI.isLoading.value);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 101) {
            state.UI.Progress.set(percent);
          }
          if (percent == 100) {
            state.UI.Progress.set(0);
          }
        },
      };
      const requestGeneral = await api.post(
        "/File/AddEditFileWithoutFile",
        newItem
      );
      if (requestGeneral.data.Status === "ok") {
        // var responseGeneral = requestGeneral.data.Response;
        // //Upload the actual file now
        // debugger;
        // let bodyData = new FormData();
        // bodyData.append("path", item);
        // let responseFile = await api.post(`/File/UploadFile/${responseGeneral.GUID}`, bodyData, options, {
        //     headers: { "Content-Type": "multipart/form-data" },
        // });
      }
      state.UI.isLoading.set(!state.UI.isLoading.value);
      await requestFolderRemote();
    });
  };

  const deleteAllSelected = () => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete all selected items",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let query = {
          Items: "",
          Events: "",
        };

        state.SelectedItems.value.forEach((item) => {
          query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;
        });
        await apiService.post("/attachment/DeleteBatch", query);
        props.actions.getAttachmentsEvent(props.originEvent.GUID);
      }
    });
  };

  const developerKey =
    process.env.REACT_APP_GOOGLE_DEV_KEY || googleApi.GOOGLE_DEV_KEY;
  const cliendId =
    process.env.REACT_APP_GOOGLE_CLIENT_ID || googleApi.GOOGLE_CLIENT_ID;

  function handleGoogleDrive(data) {
    if (data.action === "cancel") {
      return console.log("User clicked cancel/close button");
    }
    uploadDropbox(data.docs);
  }
  const { handleOpenGooglePicker } = useGooglePicker(
    cliendId,
    developerKey,
    handleGoogleDrive
  );

  function handleOneDrive(data) {
    uploadDropbox(data);
  }

  const history = useHistory();
  const { isChecked } = useBetaFeature();

  const handleFileNavitation = (toFolder) => {
    const index = state.CurrentFolderPath.length;
    const currentFolder = JSON.parse(
      JSON.stringify(state.CurrentFolderPath[index - 1]?.value)
    );
    const folder = state.CurrentFolderPath[index - 2];
    const history = JSON.parse(
      JSON.stringify(state?.folderNavigationHistory?.value)
    );

    if (toFolder === "next") {
      const lastFolder = history[history.length - 1];
      let currenFolderIndex = 0;
      history.forEach((folder, index) => {
        if (folder.GUID === currentFolder.GUID) currenFolderIndex = index;
      });

      if (!lastFolder || lastFolder?.GUID === currentFolder?.GUID) return;

      const nextFolder = state.folderNavigationHistory[currenFolderIndex + 1];
      enterFolder(nextFolder.value);
    } else {
      enterFolder(folder.value);
    }
  };

  function getIsNextDisable() {
    const index = state.CurrentFolderPath.length;
    const currentFolder = JSON.parse(
      JSON.stringify(state?.CurrentFolderPath[index - 1]?.value) || null
    );

    const folderHistory = JSON.parse(
      JSON.stringify(state?.folderNavigationHistory?.value)
    );

    const lastFolder = folderHistory[folderHistory.length - 1];
    let currenFolderIndex = 0;
    folderHistory.forEach((folder, index) => {
      if (folder.GUID === currentFolder.GUID) currenFolderIndex = index;
    });

    return (
      state.folderNavigationHistory.length < state.CurrentFolderPath.length ||
      !lastFolder ||
      lastFolder?.GUID === currentFolder?.GUID
    );
  }

  const isNextDisable = getIsNextDisable();

  const handleFileEdit = async (values) => {
    let newItem = {
      GUID: values.GUID,
      Name: values.Name,
      Note: values.Note,
      Path: values.Path,
      UserGUID: values.UserId,
      ParentGUID: values.ParentGUID,
      Type: values.Type,
      EventShared: values.EventShared,
    };
    const requestGeneral = await api.post(
      "/File/AddEditFileWithoutFile",
      newItem
    );
    state.SelectedFile.set(values);
    await requestFolderRemote();
  };

  const toggleDocView = () => {
    state.showDocView.set(!state.showDocView.value);
  };

  const toggleSheetView = () => {
    state.showSheetView.set(!state.showSheetView.value);
  };

  const togglePDFView = () => {
    state.showPDFView.set(!state.showPDFView.value);
  };

  const toggleView = () => {};
  const spreadSheetRef = React.useRef();

  console.log({ state });

  return (
    <div className=" ">
      {/*MODALS AREA */}

      {state.showDocView.value && (
        <DocsComponent
          toggle={toggleDocView}
          file={state.SelectedFile.value}
          modal={state.showDocView.value}
        />
      )}

      {state.showSheetView.value && (
        <SpreadSheets
          toggle={toggleSheetView}
          file={state.SelectedFile.value}
          forwardRef={spreadSheetRef}
          modal={state.showSheetView.value}
        />
      )}

      {state.showPDFView.value && (
        <PDFComponent
          toggle={togglePDFView}
          file={state.SelectedFile.value}
          modal={state.showPDFView.value}
        />
      )}

      <EditAttachmentModal
        toggle={toggleEditAttachment}
        show={showEditAttachment}
        attachment={JSON.parse(JSON.stringify(state.SelectedFile.value))}
        type={"FILE"}
        handleSubmit={handleFileEdit}
      />
      <ModalComponent
        modal={state.UI.ShowPickFolder.value}
        toggle={() => toggleListFolder()}
        childeren={
          <ListFolders toggle={toggleListFolder} parentState={state} />
        }
      />
      <ModalComponent
        modal={state.UI.showAddEditLink.value}
        toggle={() => toggleAddEditLink()}
        childeren={
          <AddEditLink
            Model={state.ChoosenLink.value}
            toggle={toggleAddEditLink}
          />
        }
      />

      <ModalComponent
        modal={state.UI.showAddEditFolder.value}
        toggle={() => toggleAddEditFolder()}
        childeren={
          <AddEditFolder
            Model={state.ChoosenFolder.value}
            toggle={toggleAddEditFolder}
          />
        }
      />
      <ModalComponent
        modal={state.showViewerModal.value}
        toggle={() => state.showViewerModal.set(!state.showViewerModal.value)}
        childeren={
          <Draggable
            axis="x"
            defaultPosition={{ x: 0, y: 0 }}
            position={null}
            grid={[25, 25]}
            scale={1}
          >
            <div className="viewer-documents d-flex flex-column">
              <div className="viewer-documents-drag"></div>
              {state.showViewerModal.value && openPreview()}
            </div>
          </Draggable>
        }
      />

      <ModalComponent
        modal={state.showVisibleByEvents.value}
        toggle={() => toggleEvents()}
        childeren={
          <AssignToEvents parentState={state} toggleEvents={toggleEvents} />
        }
      />
      <ModalComponent
        toggle={toggleExportToEvent}
        modal={state.UI.showExportToEvent.value}
        childeren={
          <ExportToEvent
            originEvent={props.originEvent}
            //  loading={loading}
            exportMode={state.UI.exportMode.value}
            items={state.SelectedItems.value}
            toggle={toggleExportToEvent}
            section="attachment"
            //  toggleEvent={this.toggleEvent}
            // eventsSelected={eventsSelected}
            //submit={exportToEvents}
          />
        }
      />
      {/*END MODALS AREA*/}
      {/**HEADER */}
      <div className=" row mx-0 page-header">
        <div className="d-flex align-items-center">
          <SandwichButton
            expanded={props.expanded}
            showExpanded={props.showExpanded}
          />
          <div className="col-4  px-0">
            <h1 className="mr-2">
              <FormattedMessage id="files" defaultMessage="Files" />
            </h1>
          </div>
        </div>
        <div className="col-4 text-center">
          <img src={IMAGES.LOGO_BLACK} alt="Plnify" style={{ width: "75px" }} />
        </div>
        <div className="text-right col-4 px-0 "></div>
      </div>
      {/**END HEADER */}
      <div className="row customToolbar mx-0 justify-content-between">
        <div className="d-flex px-1 align-items-center justify-content-center">
          {state.CurrentFolderPath.value.length > 0 ? (
            <nav aria-label="breadcrumb" className="">
              <ol className="breadcrumb m-0 p-0 align-items-center justify-content-between w-100">
                <button
                  className="btn btn-group"
                  onClick={handleFileNavitation}
                  disabled={
                    state.CurrentFolderPath.value.length > 1 ? false : true
                  }
                >
                  <i
                    className="fa fa-arrow-left text-secondary"
                    style={{ cursor: "pointer" }}
                  ></i>
                </button>
                <button
                  className="btn btn-group"
                  onClick={() => handleFileNavitation("next")}
                  disabled={isNextDisable ? true : false}
                >
                  <i
                    className="fa fa-arrow-right text-secondary"
                    style={{ cursor: "pointer" }}
                  ></i>
                </button>

                {state.CurrentFolderPath.map((path) => {
                  return path.GUID.value === "/" ? (
                    <button
                      key={path.GUID.value}
                      className="btn btn-group"
                      onClick={() => enterFolder(path.value)}
                      disabled={
                        state.CurrentFolderPath.value.length > 1 ? false : true
                      }
                    >
                      <i
                        className="fa fa-solid fa-home text-secondary"
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      ></i>
                    </button>
                  ) : (
                    <li
                      key={path.GUID.value}
                      className="breadcrumb-item text-secondary"
                      onClick={() => enterFolder(path.value)}
                    >
                      {path.Name.value}
                    </li>
                  );
                })}
              </ol>
            </nav>
          ) : null}
        </div>
        <div className={`${"col-2 text-right align-items-center"}`}>
          {/* {state.SelectedFiles.value.length > 0 ?
                        <>
                            <button className="btn btn-sm btn-secondary mr-2" onClick={() => toggleEvents()}>
                                Link
                            </button>
                            <button className="btn btn-sm btn-secondary mr-2" onClick={() => toggleListFolder()}>
                                Move
                            </button>
                        </> :
                        null} */}
        </div>
        <div>
          <button
            title="List View"
            className="btn btn-group "
            onClick={() => {
              setCurrentView("list");
              localStorage.setItem("fileView", "list");
            }}
          >
            <i className="fa fa-list" style={{ cursor: "pointer" }}></i>
          </button>
          <button
            title="Grid View"
            className="btn btn-group"
            onClick={() => {
              setCurrentView("grid");
              localStorage.setItem("fileView", "grid");
            }}
          >
            <svg
              width={15}
              height={15}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="grid-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="svg-inline--fa fa-grid-2 fa-lg"
            >
              <path
                fill="currentColor"
                d="M224 80c0-26.5-21.5-48-48-48H80C53.5 32 32 53.5 32 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM288 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z"
                class=""
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <div className="row mt-4 containerFullHeight">
        <div className="col-8">
          <div
            className="card shadow-sm"
            style={{ height: "calc(100vh - 160px)" }}
          >
            <div className="card-body overflow50">
              {/*FILES */}
              {
                state.Files.length > 0 ? (
                  <div className="row">
                    <div className="d-flex justify-content-between w-100 pr-1">
                      <div className="d-flex">
                        <div className="col-auto px-0 mb-2">
                          {currentView === "list" && (
                            <button
                              className="btn btn-sm text-secondary"
                              onClick={() => SelectAll()}
                            >
                              {state.SelectedFiles.length === 0 ? (
                                <i className="far fa-square uncheck"></i>
                              ) : state.SelectedFiles.length ===
                                state.Files.length ? (
                                <i className="fas fa-check-square"></i>
                              ) : (
                                <i className="fas fa-minus-square"></i>
                              )}
                            </button>
                          )}
                        </div>
                        <div className="col-10 mb-2">
                          <small>
                            <FormattedMessage id="name" defaultMessage="Name" />
                          </small>
                          {state.UI.isLoading.value ? (
                            <div
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-10 mb-2">
                          {state.UI.Progress.value > 0 ? (
                            <div className="w-25 d-inline-block" role="status">
                              <Progress value={state.UI.Progress.value} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <Popup
                        trigger={
                          <button
                            type="button"
                            className="btn btn-primary mx-1 border-0"
                          >
                            <i className="fa fa-plus" />
                          </button>
                        }
                        on="click"
                        position="bottom right"
                        arrow={false}
                      >
                        {(open) => (
                          <>
                            <button
                              onClick={() => {
                                open();
                                openFolderForEdit({
                                  Name: "",
                                  GUID: uuid.v4(),
                                  Color: null,
                                  UserGUID: state.CurrentUser.GUID.value,
                                  ParentGUID:
                                    state.SelectedFolder.value !== "/"
                                      ? state.SelectedFolder.value
                                      : "",
                                });
                              }}
                              className="btn btn-block flex-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="newFolder.file"
                                defaultMessage="New Folder"
                              />
                            </button>

                            <button
                              onClick={() => {
                                open();
                                openLinkForEdit({
                                  Name: "",
                                  GUID: uuid.v4(),
                                  Type: "link",
                                  UserGUID: state.CurrentUser.GUID.value,
                                  ParentGUID:
                                    state.SelectedFolder.value !== "/"
                                      ? state.SelectedFolder.value
                                      : "",
                                });
                              }}
                              className="btn btn-block flex-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="addLink.file"
                                defaultMessage="Add Link"
                              />
                            </button>

                            <DropboxChooser
                              appKey={DropboxKey}
                              success={(files) => {
                                console.log("the files", files);
                                uploadDropbox(files);
                                open();
                              }}
                              cancel={() => console.log("cancel")}
                              multiselect={true}
                              extensions={["*"]}
                            >
                              <button
                                className="btn btn-block flex-1 mt-1"
                                type="button"
                              >
                                <FormattedMessage
                                  id="uploadDropbox"
                                  defaultMessage="Upload from Dropbox"
                                />
                              </button>
                            </DropboxChooser>

                            <button
                              className="btn btn-block flex-1 my-1"
                              onClick={() => {
                                handleOpenGooglePicker();
                                open();
                              }}
                            >
                              Upload from Google
                            </button>

                            {/* <OneDrivePicker
                  closeMenu={open}
                  handleOneDrive={handleOneDrive}
                /> */}

                            <button className="btn btn-block btn-file">
                              <FormattedMessage
                                id="uploadFile.file"
                                defaultMessage="Upload file "
                              />
                              (s)
                              <input
                                className=""
                                onChange={AddAttachments}
                                id="inputFiles"
                                type="file"
                                multiple
                              />
                            </button>

                            {isChecked && (
                              <button
                                className="btn btn-block flex-1"
                                type="button"
                                onClick={() => history.push("/files/sheets")}
                              >
                                <i className="fa fa-table mr-1"></i>New Sheet
                                <span className="badge badge-info text-white font-weight-bold px-1 py-1 ml-1">
                                  Beta
                                </span>
                              </button>
                            )}
                            {isChecked && (
                              <button
                                className="btn btn-block flex-1"
                                type="button"
                                onClick={() => history.push("/files/docs")}
                              >
                                <i className="fa fa-file mr-1"></i>New Document
                                <span className="badge badge-info text-white font-weight-bold px-1 py-1 ml-1">
                                  Beta
                                </span>
                              </button>
                            )}
                          </>
                        )}
                      </Popup>
                    </div>
                    {state.SelectedFiles.value.length > 0 ? (
                      <div className="col-12 bg-subOption text-dark text-center py-2 px-2">
                        <button
                          className="btn btn-secondary mx-2 btn-sm"
                          onClick={() => toggleListFolder("export")}
                        >
                          <FormattedMessage
                            id="moveFile"
                            defaultMessage="Move File"
                          />
                        </button>
                        <button
                          className="btn btn-secondary mx-2 btn-sm"
                          onClick={() => toggleEvents("copy")}
                        >
                          <FormattedMessage
                            id="linkFileEvents"
                            defaultMessage="Link File to Events"
                          />
                        </button>
                        <button
                          className="btn btn-danger mx-2 btn-sm"
                          onClick={() => {
                            if (
                              state.SelectItemDelete.value.Type === "folder"
                            ) {
                              deleteFolderRemote(
                                state.SelectedFiles.value,
                                "multiple"
                              );
                            } else {
                              deleteFile(state.SelectedFiles.value);
                            }
                          }}
                        >
                          <FormattedMessage
                            id="deleteSelected"
                            defaultMessage="Delete Selected"
                          />
                        </button>
                      </div>
                    ) : null}

                    <div className="row">
                      <DragAndDrop
                        handleDragOut={handleDragOut}
                        handleDropCalendarEvent={onChangeDrop}
                        fontSize={18}
                        className="col-12 px-0  mx-0"
                      >
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          style={{
                            margin: "20px 0",
                            height: "250px",
                            width: "100%",
                            border: "2px dashed #ccc",
                          }}
                        >
                          <i className="text-primary fa fa-cloud-upload-alt text-white fa-5x"></i>
                          Drop your files here
                        </div>
                      </DragAndDrop>
                    </div>
                    {currentView === "grid" && (
                      <FileGripComponent>{requestFiles()}</FileGripComponent>
                    )}
                    {currentView === "list" && requestFiles()}
                  </div>
                ) : (
                  <>
                    <Popup
                      trigger={
                        <div className="d-flex w-100 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary mx-1 border-0"
                          >
                            <i className="fa fa-plus" />
                          </button>
                        </div>
                      }
                      on="click"
                      position="bottom right"
                      arrow={false}
                    >
                      {(open) => (
                        <>
                          <button
                            onClick={() => {
                              open();
                              openFolderForEdit({
                                Name: "",
                                GUID: uuid.v4(),
                                Color: null,
                                UserGUID: state.CurrentUser.GUID.value,
                                ParentGUID:
                                  state.SelectedFolder.value !== "/"
                                    ? state.SelectedFolder.value
                                    : "",
                              });
                            }}
                            className="btn btn-block flex-1"
                            type="button"
                          >
                            <FormattedMessage
                              id="newFolder.file"
                              defaultMessage="New Folder"
                            />
                          </button>

                          <button
                            onClick={() => {
                              open();
                              openLinkForEdit({
                                Name: "",
                                GUID: uuid.v4(),
                                Type: "link",
                                UserGUID: state.CurrentUser.GUID.value,
                                ParentGUID:
                                  state.SelectedFolder.value !== "/"
                                    ? state.SelectedFolder.value
                                    : "",
                              });
                            }}
                            className="btn btn-block flex-1"
                            type="button"
                          >
                            <FormattedMessage
                              id="addLink.file"
                              defaultMessage="Add Link"
                            />
                          </button>

                          <DropboxChooser
                            appKey={DropboxKey}
                            success={(files) => {
                              console.log("the files", files);
                              uploadDropbox(files);
                              open();
                            }}
                            cancel={() => console.log("cancel")}
                            multiselect={true}
                            extensions={["*"]}
                          >
                            <button
                              className="btn btn-block flex-1 mt-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="uploadDropbox"
                                defaultMessage="Upload from Dropbox"
                              />
                            </button>
                          </DropboxChooser>

                          <button
                            className="btn btn-block flex-1 my-1"
                            onClick={() => {
                              handleOpenGooglePicker();
                              open();
                            }}
                          >
                            Upload from Google
                          </button>

                          {/* <OneDrivePicker
                  closeMenu={open}
                  handleOneDrive={handleOneDrive}
                /> */}

                          <button className="btn btn-block btn-file">
                            <FormattedMessage
                              id="uploadFile.file"
                              defaultMessage="Upload file "
                            />
                            (s)
                            <input
                              className=""
                              onChange={AddAttachments}
                              id="inputFiles"
                              type="file"
                              multiple
                            />
                          </button>

                          {isChecked && (
                            <button
                              className="btn btn-block flex-1"
                              type="button"
                              onClick={() => history.push("/files/sheets")}
                            >
                              <i className="fa fa-table mr-1"></i>New Sheet
                              <span className="badge badge-info text-white font-weight-bold px-1 py-1 ml-1">
                                Beta
                              </span>
                            </button>
                          )}
                          {isChecked && (
                            <button
                              className="btn btn-block flex-1"
                              type="button"
                              onClick={() => history.push("/files/docs")}
                            >
                              <i className="fa fa-file mr-1"></i>New Document
                              <span className="badge badge-info text-white font-weight-bold px-1 py-1 ml-1">
                                Beta
                              </span>
                            </button>
                          )}
                        </>
                      )}
                    </Popup>

                    <DragAndDrop
                      handleDragOut={handleDragOut}
                      handleDropCalendarEvent={onChangeDrop}
                      fontSize={18}
                      className="col-12 px-0  mx-0 h-100"
                    >
                      <EmptyState
                        image={EMPTY_ATTACHMENTS}
                        cta="Add a new attachment"
                        text={intl.formatMessage({ id: "noAttachmentsYet" })}
                      />
                    </DragAndDrop>
                  </>
                )

                //Supposed to display empty state
              }
            </div>
          </div>
        </div>

        <div className="col-4">
          <div
            className="card shadow-sm "
            style={{ height: "calc(100vh - 160px)" }}
          >
            <div className="card-body">
              {state.SelectedFile.value === null ? (
                <EmptyState
                  image={EMPTY_ATTACHMENTS}
                  cta="Add a new attachment"
                  text="No attachments selected"
                />
              ) : (
                <FileDetails
                  parentState={state}
                  requestFolderRemote={requestFolderRemote}
                  toggleEvents={toggleEvents}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(
      { ...organizationActions },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar(Files));
