import * as types from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function selectEvent(eventGUID) {
  const url = `/Guestlist/General?EventGUID=${eventGUID}`;
  return async (dispatch) => {
    dispatch({
      type: types.SELECT_EVENT_GUID,
    });
    try {
      
      const localTickets = await db
        .table("ticketsAndPasses")
        .where({ EventGUID: eventGUID })
        .toArray();
      dispatch({
        type: types.TICKETS_PASSES_FETCHED,
        data: localTickets,
      });
      const {
        data: { Response },
      } = await fetchingTicketsAndPasses(url);
      dispatch({
        type: types.TICKETS_PASSES_FETCHED,
        data: Response,
      });
      if (Response.length) {
        await db.table("ticketsAndPasses").bulkPut(Response);
      }
    } catch (error) {
      const localTickets = await db.table("ticketsAndPasses").toArray();
      dispatch({
        type: types.TICKETS_PASSES_FETCHED,
        data: localTickets,
      });
      dispatch({
        type: types.TICKETS_PASSES_FETCHING_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case types.SELECT_EVENT_GUID: {
      return {
        ...state,
        selectedEvent: action.data,
        loadingTickets: true,
      };
    }
    case types.TICKETS_PASSES_FETCHED: {
      const ticketsAndPassesData = action.data;
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: ticketsAndPassesData,
      };
    }
    case types.TICKETS_PASSES_FETCHING_ERROR: {
      return {
        ...state,
        loadingTickets: false,
        ticketsAndPasses: [],
      };
    }
    default:
      return state;
  }
}

function fetchingTicketsAndPasses(url) {
  return apiService.get(url);
}
