import React, { useEffect, useState } from "react";
import { Link, Events, scroller } from "react-scroll";
import { SimpleLoader } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import api from "../../common/api";
import { useBetaFeature } from "../../hooks/useBetaFeature";

const EventNavigation = (props) => {
  let {
    options,
    loading,
    layout,
    layoutProduction,
    layoutEvent,
    currentElement,
    handleNavigationSelect,
    history,
    scrollToElement,
    selected,
    setSelected,
    isMobile,
  } = props;

  const location = useLocation();
  const navigate = history;

  const { isChecked } = useBetaFeature();

  const handleSelect = ({ target }, name) => {
    console.log({ target, name });
    name = name.toLocaleLowerCase();
    setSelected(name);
    handleNavigationSelect(name);
    navigate.push(`#${name}`);
  };

  useEffect(() => {
    let section = !location.hash.replace("#", "")
      ? "general"
      : location.hash.replace("#", "");
    setSelected((prev) => section);
    handleNavigationSelect(section);
    scrollToElement(!location.hash ? "#general" : location.hash);
  }, [location]);

  return (
    <div className="eventNavigation__container">
      {layout === "EVENT" ? (
        <>
          {options.map((option, index) => (
            <Link
              title={option?.name?.props?.defaultMessage}
              containerId="listSection"
              smooth={true}
              offset={-20}
              duration={500}
              className={
                selected.toLocaleLowerCase() === option.id.toLocaleLowerCase()
                  ? "active_link"
                  : ""
              }
              to={option.id}
              // spy={true}
              key={option.id}
              onClick={(event) => {
                handleSelect(event, option.id);
                props.toggleProductionModal(false);
              }}
            >
              <span className="d-none d-md-none d-lg-block">{option.name}</span>
              <span className="d-block d-md-block d-lg-none">
                <i className={option.icon}></i>
              </span>
            </Link>
          ))}
          {/* <a className=" text-truncate"
            onClick={() => props.handleSidebarItem("MSG", "")}>
            {<FormattedMessage
              id="msg"
              defaultMessage="Msg" />}
          </a> */}
          <a
            className={`beta ${!isChecked ? "active" : ""} ${
              selected === "moreDetails" ? "active_link" : ""
            } text-truncate`}
            onClick={() => {
              props.toggleProductionModal(true);
              setSelected("moreDetails");
            }}
          >
            {!isMobile ? (
              <>
                <FormattedMessage
                  id="advancedDetails"
                  defaultMessage="Advanced Details"
                />
                <span className="badge badge-info mx-2">Beta</span>
              </>
            ) : (
              <i className="fas fa-info-circle"></i>
            )}
          </a>
        </>
      ) : null}

      {loading && <SimpleLoader loading={loading} />}
    </div>
  );
};

export default EventNavigation;
