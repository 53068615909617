import React from "react";
import { DropDownComponent } from "../common";
import moment from "moment";
import { useDispatch } from "react-redux";
import { enableChatWindow } from "../../page/meet/redux/chatHandlers";

const ChatSidebarItem = ({
  name,
  lastMessage,
  lastMessageDate,
  chat,
  enableChat,
  toggleMessages,
  unreadMessages,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className="chat__item d-flex align-items-center justify-content-between"
      onClick={() => {
        dispatch(enableChatWindow(enableChat, chat));
        toggleMessages();
      }}
    >
      <div className="text-truncate" style={{ flex: 60, paddingRight: 24 }}>
        <div>
          <h6 className="text-truncate">{name}</h6>
          <div className="text-truncate">
            <small className="text-muted">{lastMessage}</small>
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-end"
        style={{ flex: 40 }}
      >
        <div className="d-inline text-right">
          {unreadMessages === 0 ? null : (
            <>
              <span className="badge badge-sm badge-danger">
                {unreadMessages}
              </span>
              <br />
            </>
          )}
          <small className="text-muted">
            {moment(lastMessageDate).fromNow()}
          </small>
        </div>
        <DropDownComponent
          className="ml-2"
          data={[
            {
              name: "Assign/Change users",
              border: true,
              trigger: () => console.log("users"),
            },
            {
              delete: true,
              name: "Delete Chat",
              trigger: () => console.log("delete chat"),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ChatSidebarItem;
