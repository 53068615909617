import React, { useEffect, useState } from "react";
import { apiService } from "../../common/api";
import { Event } from "../event/event";
import { MoveHotelChildItem } from "./MoveHotelChildItem";

const ApiUrlBySection = {
  // Header: "/Event/GetEventHeader",
  Itineraries: "/ScheduleMaster/ScheduleForEvent",
  Flights: "/Flights/FlightsForEvent",
  Hotels: "/Hotels/HotelForEvent",
  Transportation: "/Grounds/GroundForEvent",
  Contacts: "/ProductionVenueContact/GetProductionVenueContact",
  Attachments: "/Attachment/Attachments",
  // Upcoming: "/Event/GetUpcomingEvents",
};

export const MoveHotelItem = ({
  icon,
  title,
  event,
  toggle,
  isSelected,
  isParentChecked,
  setSectionsParams,
  sectionsParams,
  selectedEvent,
  handleSelectEvent,
  handleParams,
}) => {
  const [sectionData, setSectionData] = useState([]);
  const [sectionShow, setSectionShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSectionShow = () => {
    setSectionShow((prev) => !prev);
  };

  const handleSelect = (e) => {
    e?.stopPropagation();
    handleSelectEvent(event);
  };

  const fetchSectionData = async () => {
    const apiToUse = apiService;

    const { data } = await apiToUse.get(
      `${ApiUrlBySection["Hotels"]}?EventGUID=${selectedEvent?.GUID}`
    );

    let stateData = data.response;

    setSectionData({
      section: title,
      data: stateData?.filter((item) => !item.Deleted),
    });
  };

  useEffect(() => {
    if (selectedEvent) {
      fetchSectionData();
    }
    setSectionShow(false);
  }, [selectedEvent]);

  useEffect(() => {
    if (title !== "Itineraries") {
      const updatedSelectedItems = sectionData?.data?.map((item) => {
        setSectionsParams((prev) => {
          return {
            ...prev,
            [title]: prev[title]?.concat(item.GUID),
          };
        });
        return item.GUID;
      });
      setSelectedItems(updatedSelectedItems);
    }
  }, [sectionData, selectedEvent]);

  const renderChildItems = () => {
    return (
      <div className="bg-light">
        {sectionData?.data &&
          selectedItems?.length > 0 &&
          sectionData?.data?.map((section, index) => (
            <MoveHotelChildItem
              key={index + section.GUID}
              item={section}
              event={event.GUID}
              section={"Hotels"}
              selectedItems={selectedItems || []}
              setSectionsParams={setSectionsParams}
              sectionsParams={sectionsParams}
              isParentChecked={isParentChecked}
              handleParams={handleParams}
            />
          ))}
      </div>
    );
  };

  return (
    <div className="d-flex flex-column w-100" style={{ transition: "all 1s" }}>
      <div
        className="d-flex align-items-center px-3 py-1 border clickable w-100 justify-content-between"
        onClick={handleSectionShow}
        style={{ position: "relative", zIndex: 100 }}
      >
        <div className="d-flex align-items-center">
          <input
            style={{ width: "15px", height: "15px" }}
            className="mr-2 pointer"
            type="checkbox"
            checked={isParentChecked}
            onChange={(e) => {
              handleSelect(e);

              if (!sectionShow && !isParentChecked) {
                handleSectionShow();
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          {typeof icon === "function" ? (
            icon()
          ) : (
            <i className={`fa ${icon}`}></i>
          )}
          <p className="ml-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            <Event
              clickable
              noClickable
              event={event}
              toggle={toggle}
              selectOnly
              setCurrentEvent={() => {}}
              isSelected={isSelected}
            />
          </p>
        </div>
        <div className="d-flex align-items-center">
          <p className="mr-4">{`Hotels: ${event.HotelsCount}`}</p>
          {
            <i
              className={`fa fa-angle-up collap-arrow ${
                !sectionShow ? "active" : "inactive"
              } ml-auto fa-lg`}
            ></i>
          }
        </div>
      </div>

      <div
        className={`export-item-conatiner ${
          sectionShow ? "active" : "inactive"
        }`}
      >
        {renderChildItems()}
      </div>
    </div>
  );
};
