import React, { useEffect, useState } from "react";

import { IMAGES } from "../../constant";
import { ToastNotificationSuccess, SimpleLoader } from "../common";
import { Scrollbars } from "react-custom-scrollbars";
import {useIntl, FormattedMessage} from 'react-intl'

import api, { apiService } from "../../common/api";
import { useFormik } from "formik";
import GeneralUserItem from "../common/GeneralUserItem";

const AddEditSingleAssigneeFlight = props => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false);



  const handleSubmit = async (selected) => {
    try {

      setIsLoading(!isLoading);

          await apiService.post("/Flights/AddEditStaffBatch", [selected]);
          setIsLoading(!isLoading);

     

        props.toggle(selected, true);
     
    } catch (ex) {
      console.error(ex)
    }
  }

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: props.model ,
    // validationSchema,
    onSubmit: handleSubmit,
  });


  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <div className="d-flex align-items-center">
                <h1><FormattedMessage
                id="Edit"
                defaultMessage="Edit"/></h1>


              </div>

            </div>
            <div className="d-none close-icon d-none" onClick={() => props.toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content text-dark">
              <Scrollbars style={{ width: "100%", height: "calc(20vh)" }}>
                <div className="row py-2">
                  <div className="col-12 my-2">
                  <GeneralUserItem account={props.model.Staff} fromSection="ASSIGNMEMBERSFLIGHT" />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor={"checkinDate"}>
                    <FormattedMessage
                id="Reservation"
                defaultMessage="Reservation"/>
                    </label>
                    <div className="leftSide__date">
                      <input type="text" className='form-control'
                        placeholder={intl.formatMessage({id:"Record Locator"})}

                        value={formik.values.TicketNumber === null ? "" : formik.values.TicketNumber}
                        onChange={e => {
                          formik.setFieldValue("TicketNumber", e.target.value.toUpperCase());
                        }}

                      />

                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div className="button-section py-3 d-flex align-items-center justify-content-center">
              {isLoading ? (
                <SimpleLoader loading={isLoading} />
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mx-2" onClick={() => formik.handleSubmit()}
                >
    <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                </button>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditSingleAssigneeFlight;
