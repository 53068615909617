export const ROOM_TYPES = [
  { label: "Single", value: "Single" },
  { label: "Double", value: "Double" },
  { label: "Triple", value: "Triple" },
  { label: "Quad", value: "Quad" },
  { label: "Queen", value: "Queen" },
  { label: "King", value: "King" },
  { label: "Twin", value: "Twin" },
  { label: "Hollywood Twin Room", value: "Hollywood Twin Room" },
  { label: "Double-Double", value: "Double-Double" },
  { label: "Studio", value: "Studio" },
  { label: "Suite", value: "Suite" },
  { label: "Junior Suite", value: "Junior Suite" },
  { label: "Presidential Suite", value: "Presidential Suite" },
  { label: "Connecting Rooms", value: "Connecting Rooms" },
  { label: "Murphy Room", value: "Murphy Room" },
  { label: "Cabana", value: "Cabana" },
  { label: "Villa", value: "Villa" },
  { label: "Executive Floor", value: "Executive Floor" },
];
