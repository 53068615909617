import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteContact(contact, success) {
  const url = "/ProductionVenueContact/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_CONTACT,
    });
    try {
      const contactToDelete = { ...contact, Deleted: new Date() };
      await db.table("contacts").delete(contact.GUID);
      dispatch({
        type: constant.DELETING_CONTACT_SUCCESS,
        data: contactToDelete,
      });
      success();
      await api.post(url, contactToDelete);
    } catch (error) {
      dispatch({ type: constant.DELETING_CONTACT_ERROR, data: error });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_CONTACT_SUCCESS: {
      return {
        ...state,
        contacts: state.contacts.filter((c) => c.GUID !== action.data.GUID),
      };
    }
    default:
      return state;
  }
}
