import React, { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { addScheduleMasterSchema } from "../../utils/validationsSchemas";
import moment from "moment";
import { IMAGES } from "../../constant";
import DatePicker from "react-datepicker";
import { ChevronDown } from "mdi-material-ui";
import { SimpleLoader } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

const AddItinerary = (props) => {
  let {
    header,
    addItinerary,
    event,
    toggle,
    scheduleMaster,
    edit,
    templates,
    createFromTemplate,
    loading,
    initialValue,
  } = props;
  const [hasLabel, setHasLabel] = useState();
  const [label, setLabel] = useState("");
  useEffect(() => {
    document.getElementById("name").focus();
  }, []);

  const handleAddTag = () => {
    setHasLabel(true);
  };

  return (
    <div className="">
      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100 d-flex justify-content-between">
              <div>
                <h1>
                  <FormattedMessage
                    id="addNewItinerary"
                    defaultMessage={`${header ? header : "Add New Itinerary"}`}
                  />
                </h1>
                {hasLabel ? (
                  <div className="d-flex mt-1">
                    <input
                      type="text"
                      className="form-control pr-2"
                      autoFocus
                      placeholder="Add your label here"
                      value={label}
                      onChange={({ target }) => setLabel(target?.value)}
                    />
                    <button
                      className="btn border-0 position-relative"
                      style={{ right: 22 }}
                      onClick={() => setHasLabel(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-primary mt-1"
                    onClick={handleAddTag}
                  >
                    Add Label
                    <FontAwesomeIcon icon={faTag} className="ml-1 text-white" />
                  </button>
                )}
              </div>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </button>
            </div>
            <div className="close-icon d-none d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="itineraries__form">
            <Formik
              validateOnBlur={false}
              validationSchema={addScheduleMasterSchema}
              initialValues={
                initialValue ?? {
                  Name: "",
                  Date: moment(event.Date).format("YYYY-MM-DD"),
                }
              }
              onSubmit={(values) => addItinerary({ ...values, label }, ()=>toggle())}
            >
              {(props) => (
                <Form autoComplete="off">
                  <div className="row">
                    <div className="col-6 align-items-center">
                      <FormField
                        fullWidth={true}
                        additionalClasses="px-0 pr-2"
                        label={
                          <FormattedMessage id="Title" defaultMessage="Title" />
                        }
                      >
                        <Field
                          id="name"
                          className="form-control"
                          type="text"
                          name="Name"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Name"
                        />
                      </FormField>
                    </div>

                    <div className="col-6 px-0 pl-2 relative">
                      <label htmlFor={"date"}>
                        <FormattedMessage id="date" defaultMessage="Date" />
                      </label>
                      <div>
                        <input
                          type="date"
                          className="form-control underline pt-0 pb-1"
                          value={moment(props.values.Date).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            try {
                              if (!e.target.value) {
                                return;
                              }
                              let finalValue = e.target.value;
                              props.setFieldValue(
                                "Date",
                                moment(finalValue).format("YYYY-MM-DD")
                              );
                            } catch (ex) {
                              console.error(ex);
                              debugger;
                            }
                          }}
                        />
                        {/* <DatePicker
                          id={"date"}
                          dateFormat="MMMM dd, yyyy"
                          showPopperArrow={false}
                          selected={moment(props.values.Date).toDate()}
                          onChange={(e) => {
                            props.setFieldValue(
                              "Date",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                        /> */}
                        {/* <div
                          className="drop-icon"
                          onClick={() =>
                            document.getElementById("date").focus()
                          }
                        >
                          <ChevronDown />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center text-center">
                        <SimpleLoader loading={loading} />
                      </div>
                    ) : (
                      <button type="submit" className="py-2 btn primary block">
                        <FormattedMessage id="done" defaultMessage="Done" />
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItinerary;
