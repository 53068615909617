import * as constant from "./constants";
import api from "../../../common/api";
import uuid from "uuid";
import db from "../../../common/db";
import moment from "moment";

export function addGround(ground, event, staffs, vehicles, success, local) {
  const url = "/Ground/AddEdit";
  const urlStaff = "/Grounds/AddEditStaffBatch";
  const urlVehicles = "/Grounds/AddEditVehicleBatch";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_GROUND,
    });
    try {
      const request = await api.post(url, {
        ...ground,
        PickupDate: `${moment(ground.PickUpDate).format("YYYY-MM-DD")}T${moment(
          ground.PickupDate
        ).format("HH:mm")}`,
      });
      const vehiclesArray = vehicles.map((v) => ({
        ...v,
        GroundGUID: request.data.GUID,
        Deleted: null,
        Updated: null,
        Created: new Date(),
      }));
      const vehicleRequest = await api.post(urlVehicles, vehiclesArray);
      const arrayStaffs = staffs.map((staff) => ({
        GroundGUID: request.data.GUID,
        StaffGUID: staff.StaffGUID,
        GUID: staff.GUID,
        Deleted: null,
      }));
      const staffRequest = await api.post(urlStaff, arrayStaffs);
      dispatch({
        type: constant.CREATED_GROUND_SUCCESS,
        data: {
          ...request.data,
          GroundVehicles: vehicleRequest.data.Response,
          GroundStaff: staffRequest.data.Response,
        },
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.CREATING_GROUND_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_GROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_GROUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        grounds: [...state.grounds, action.data],
      };
    }
    case constant.CREATING_GROUND_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
