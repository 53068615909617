import * as CONSTANT from "./constants";
import api from "../../../common/api";

export function searchHotels(query) {
  const url = `/venues/hotelsautocomplete?query=`;
query = query.replace("null", "").replace(", ,", ",");
  return async (dispatch) => {
    dispatch({ type: CONSTANT.SEARCHING_HOTELS });
    try {
      const request = await api.get(`${url}${query}`);
      dispatch({
        type: CONSTANT.POPULATE_HOTELS,
        data: request.data.results,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.SEARCHING_HOTELS: {
      return {
        ...state,
        searchingHotels: true,
      };
    }
    case CONSTANT.POPULATE_HOTELS: {
      return {
        ...state,
        hotels: action.data,
        searchingHotels: false,
      };
    }
    default:
      return state;
  }
}
