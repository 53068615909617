import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../page/meet/redux/actions";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { getBasicInfo } from "../../utils";
import UserItem from "./UserItem";

import { randomImage } from "../common/randomImage";
class OnlineUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
    };
  }

  toggleDropdown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  render() {
    const { data } = this.props;
    const currentUserGUID = getBasicInfo().GUID;
    return(<></>)
    return (
      <div className="mr-2">
        {data
          .filter((user) => user.accountGuid !== currentUserGUID)
          .map((user) => {
            let initials = user.accountName;
          let img = randomImage(initials);
            let avatarUrl = user.accountImage.includes("https")
              ? user.accountImage
              : user.accountImage === null?
              img :
              "https://app.plnify.com/photos/" +
                user.accountImage +
                "?w=75&h=75&mode=crop&autorotate=true";
            return (
              <UserItem
                fromSideBar={this.props.fromSideBar}
                key={user.accountGuid}
                avatarUrl={avatarUrl}
                user={user}
                makeMeet={this.props.actions.makeMeet}
              />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineUsers);
