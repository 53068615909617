import * as constant from "./constants";
import api from "../../../common/api";
import moment from "moment";

export function editGround(ground, success) {
  const url = `/Ground/AddEdit`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_GROUND,
      });
      const request = await api.post(url, {
        ...ground,
        PickupDate: moment(ground.PickupDate).format("YYYY-MM-DDTHH:mm"),
        GroundStaff: null,
        GroundVehicles: null,
      });
      dispatch({
        type: constant.EDITED_GROUND,
        data: request.data,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_GROUND_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_GROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_GROUND: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EDITING_GROUND_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
