import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { CheckBox } from "..";
import { IMAGES } from "../../constant";
import { getBasicInfo } from "../../utils";
import db from "../../common/db";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const StartingWeekModal = (props) => {
  const intl = useIntl();
  const basicInfoGUID = getBasicInfo().GUID;
  const [dayStartingSaved, setDayStartingSaved] = useState({});
  let { toggle } = props;

  async function saveDayOfTheWeek(day) {
    const appSettings = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    if (appSettings) {
      await db.table("appSettings").put({ ...appSettings, Preferred: day });
      window.location.reload();
    } else {
      await db.table("appSettings").put({ AccountGUID: basicInfoGUID });
    }
  }
  const loadData = async () => {
    const appSettings = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    if (appSettings) {
      setDayStartingSaved(appSettings);
    } else {
      await db.table("appSettings").put({ AccountGUID: basicInfoGUID });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  if (dayStartingSaved.Preferred == undefined) {
    setDayStartingSaved({
      ...dayStartingSaved,
      Preferred: 0,
    });
  }
  console.log(dayStartingSaved.Preferred);
  return (
    <div className="vehicleModal__root">
      <div
        className="d-flex align-items-center"
        style={{ padding: "8px 14px" }}
      >
        <div className=" mr-auto d-flex justify-content-between w-100">
          <h5 className="title">
            <FormattedMessage
              id="selectStartingDayWeek"
              defaultMessage="Select starting day of the week"
            />
          </h5>
          <button
            type="button"
            className="btn btn-sm btn-link ml-2"
            onClick={() => toggle()}
          >
            <FontAwesomeIcon icon={faTimes} color="black" />
          </button>
        </div>
        <div className="close-icon d-none d-none" onClick={() => toggle()}>
          <span
            className="close-round"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
          >
            <img src={IMAGES.CROSS_ICON} alt="close" width="16" height="16" />
          </span>
        </div>
      </div>
      <Formik
        onSubmit={(values) => {
          saveDayOfTheWeek(values.dayOfWeek);
        }}
        initialValues={{
          dayOfWeek: dayStartingSaved.Preferred
            ? parseInt(dayStartingSaved.Preferred)
            : 0,
        }}
      >
        {(props) => (
          <Form style={{ padding: "0 14px" }}>
            <div className="row">
              <div className="col-6">
                <CheckBox
                  header={intl.formatMessage({ id: "sunday" })}
                  id={"sunday"}
                  value={dayStartingSaved.Preferred == 0}
                  onChange={(e) => {
                    props.setFieldValue("dayOfWeek", 0);
                    props.submitForm();
                    // toggle();
                  }}
                />
              </div>
              <div className="col-6">
                <CheckBox
                  header={intl.formatMessage({ id: "monday" })}
                  id={"monday"}
                  value={dayStartingSaved.Preferred == 1}
                  onChange={(e) => {
                    props.setFieldValue("dayOfWeek", 1);
                    props.submitForm();
                    // toggle();
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StartingWeekModal;
