import * as CONSTANTS from "./constants";
import api from "../../../common/api";
const sub_url = "user/GetAccountWithEmailToken?Token=";

export function confirmEmail(token) {
  return async dispatch => {
    try {
      await api.get(`${sub_url}${token}`);
      dispatch({
        type: CONSTANTS.CONFIRMED_EMAIL
      });
    } catch (e) {
      dispatch({
        type: CONSTANTS.CONFIRMED_EMAIL_ERROR
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
