import { useState as useHookState } from "@hookstate/core";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import uuid from "uuid";
import api, { apiService } from "../../common/api";
import { IMAGES } from "../../constant";
import { DropDownList, ModalComponent } from "../common";

import { FormattedMessage } from "react-intl";

import Swal from "sweetalert2";
import AddEditLabel from "./AddEditLabel";
import ComercialFlightForm from "./ComercialFlightForm";
import StopNavigation from "./stopNavigation";

import db from "../../common/db";
import mainState from "../../common/mainState";
//REDUX THINGS

import { useDispatch } from "react-redux";
import * as actions from "../../page/event/redux/actions";
import { getOrganization } from "../../utils";
import AssignMembersFlight from "./AssignMembersFlight";
//END REDUX THINGS

const AddEditFlights = (props) => {
  const [state, setState] = useState({
    UI: {
      isLoading: false,
      isLoadingAirlines: false,
      Page: "search", // search,flight/connection/assign members,
      CurrentFlightIndex: 0,
      CurrentFlight: {},
      AirportsFrom: [],
      AirportsTo: [],
      ReloadFlights: false,
      Label: "",
      ShowAddEditLabel: false,
      ShowMembersModal: false,
      Teams: [],
      staffSelected: [],
    },
    Airlines: [],
    OperatedByAirlines: [],
    APIFlights: [],
    Flights: [],
    ConnectionGUID: uuid.v4(),
    AfterSearch: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      //Check for passed flights
      if (props.selectedFlights !== undefined) {
        setState((prev) => ({ ...prev, Flights: props.selectedFlights }));
        if (props.selectedFlights.length > 0) {
          setState((prev) => ({
            ...prev,
            UI: {
              ...prev.UI,
              Label: props.selectedFlights[0].Label,
              CurrentFlight: props.selectedFlights[0],
              Page: "flight",
            },
          }));
        }
      }
    } catch (ex) {
      console.log("error on effect addedit flight", ex);
    }
  }, []);

  const [globalState, setGlobalState] = useState(mainState);
  //This is in the api of flightware
  const searchFlight = async (sender, item, newFlight) => {
    let query = "";
    var matches = item?.AirlineFlight?.match(/(\d+)/);

    if (matches) {
      let numbers = matches[0];
      query =
        item.ICAO === undefined && item.IATA === undefined
          ? item.AirlineFlight
          : (item.ICAO ? item.ICAO : item.IATA) + numbers;

      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        };
      });

      const flights = await apiService.get("/Flights/FlightDetailsExternal", {
        params: { Query: query },
      });

      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        };
      });

      if (flights.status === 200) {
        setState((prev) => {
          const APIFlights = flights?.data;
          return {
            ...prev,
            APIFlights,
          };
        });
      } else {
        setState((prev) => ({
          ...prev,
          APIFlights: [],
        }));
      }

      if (state.APIFlights.length === 0) {
        //We don't find anything, create it manually
      } else if (state.APIFlights.length === 1) {
        //Only one file, we must go to the details auto
        let selectedFlight = state?.APIFlights[0];

        selectAFlight(selectedFlight, newFlight ? "add" : "update");
        setState((prev) => {
          return {
            ...prev,
            UI: {
              ...prev.UI,
              Page: "flight",
            },
          };
        });
      } else {
        //We must display the list to the user so he can pick the desired flight
      }
    }

    setState((prev) => ({
      ...prev,
      AfterSearch: true,
    }));
  };

  const createFlightManually = () => {
    let newFlight =
      state.UI.CurrentFlight !== null
        ? state.UI.CurrentFlight
        : {
            GUID: uuid.v4(),
            EventGUID: props.event.GUID,
            AirlineFlight: state.UI.CurrentFlight.AirlineFlight,
            Airline: state.UI.CurrentFlight.Airline,
            DepartureDate: props.event.Date,
            ArrivalDate: props.event.Date,
            Connection: state.ConnectionGUID,
            Deleted: null,
          };
    if (!newFlight.DepartureDate) {
      newFlight = {
        GUID: uuid.v4(),
        EventGUID: props.event.GUID,
        AirlineFlight: state.UI.CurrentFlight.AirlineFlight,
        Airline: state.UI.CurrentFlight.Airline,
        DepartureDate: props.event.Date,
        ArrivalDate: props.event.Date,
        Connection: state.ConnectionGUID,
        Deleted: null,
      };
    }
    // newFlight.Connection = newFlight.Connection?
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        CurrentFlight: newFlight,
      },
    }));
    let flights = state.Flights;
    let checkIfExist = flights.filter((x) => x.GUID === newFlight.GUID);
    if (checkIfExist.length > 0) {
      flights.filter((x) => x.GUID === newFlight.GUID)[0] = newFlight;
    } else {
      flights.push(newFlight);
    }

    setState((prev) => ({
      ...prev,
      Flights: flights,
    }));

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        Page: "flight",
      },
    }));
    // state.UI.ReloadFlights.set(true); we don't need to call it here
  };

  const searchAirports = async (target, value) => {
    const airports = await api.get(`/Airports/GetAirports?Search=${value}`);
    if (target === "from") {
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          AirportsFrom: airports.data,
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          AirportsTo: airports.data,
        },
      }));
    }
  };

  const searchAirlines = async (sender, item) => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoadingAirlines: !prev.UI.isLoadingAirlines,
      },
    }));

    const airlines = await api.get(`/Airlines/GetAirlines?Search=${item}`);

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoadingAirlines: !prev.UI.isLoadingAirlines,
      },
    }));
    if (sender === "airline") {
      setState((prev) => ({
        ...prev,
        Airlines: airlines.data,
      }));
    } else if (sender === "operatedby") {
      setState((prev) => ({
        ...prev,
        OperatedByAirlines: airlines.data,
      }));
    }
  };

  //Means we select a flight from flightaware api
  const selectAFlight = (item, mode) => {
    let record = { ...item };
    let current = { ...state?.UI?.CurrentFlight };

    current.GUID = mode === "add" ? uuid.v4() : current.GUID;
    current.Connection = state.ConnectionGUID;
    current.Airline = record.Airline;
    current.OperatedBy = record.OperatedBy;
    current.AirlineFlight = record.AirlineFlight;
    current.From = record.From;
    current.FromLatitude = record.FromLatitude;
    current.FromLongitude = record.FromLongitude;
    current.FromAddress = record.FromAddress;
    current.FromLongName = record.FromLongName;
    current.DepartureTimeZone = record.DepartureTimeZone;
    //Put a date close to the event date
    let parsedDate =
      moment(props.event.Date)
        //  .subtract(1, "days")
        .format("YYYY-MM-DD") +
      "T" +
      moment(record.DepartureDate).format("HH:mm:ss");
    //first lets validate is not a connection if is a connection must take previous flight date
    if (state.Flights.length > 0) {
      parsedDate =
        moment(current.ArrivalDate)
          //  .subtract(1, "days")
          .format("YYYY-MM-DD") +
        "T" +
        moment(record.DepartureDate).format("HH:mm:ss");
    }
    current.DepartureDate = parsedDate; //record.DepartureDate;

    current.To = record.To;
    current.ToLatitude = record.ToLatitude;
    current.ToLongitude = record.ToLongitude;
    current.ToAddress = record.ToAddress;
    current.ToLongName = record.ToLongName;
    current.ArrivalTimeZone = record.ArrivalTimeZone;
    let mantain = moment(record.DepartureDate) > moment(record.ArrivalDate);
    let parsedArrivalDate =
      mantain === true
        ? moment(parsedDate).add(-1, "days")
        : moment(parsedDate);
    let parsedDateArrival =
      moment(parsedArrivalDate)
        //    .subtract(1, "days")
        .format("YYYY-MM-DD") +
      "T" +
      moment(record.ArrivalDate).format("HH:mm:ss");
    current.ArrivalDate = parsedDateArrival; //record.ArrivalDate;
    //  current.ArrivalNote = "";
    current.Image = record.Image;
    current.Duration = record.Duration;

    current.Deleted = null;

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        CurrentFlight: current,
      },
    }));
    let flights = state.Flights;
    setState((prev) => ({
      ...prev,
      APIFlights: [],
    }));

    if (mode === "add") {
      flights.push(current);
      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          Page: "flight",
        },
      }));
    } else {
      //We have to find the flight and update
      let index = flights.findIndex((x) => x?.GUID === current?.GUID);
      flights[index] = current;
      // state.UI.Page.set("connection");
    }
    setState((prev) => ({
      ...prev,
      Flights: flights,
    }));

    //  state.UI.ReloadFlights.set(true);
  };

  //SEARCH PAGE
  const startScreen = () => {
    return (
      <Formik
        //  onSubmit={searchHandler}
        initialValues={{ Airline: "", FlightNumber: "", IATA: "", ICAO: "" }}
      >
        {(values) => (
          <Form autoComplete="off" className="row">
            <div className="col-12">
              <label htmlFor="airline">
                <FormattedMessage id="airline" defaultMessage="Airline" />
              </label>
              <Field
                autoComplete="off"
                //    id={idAutocomplete}
                className="form-control"
                type="text"
                name="Airline"
                value={state.UI.CurrentFlight.Airline}
                onChange={async (e) => {
                  const { name, value } = e?.target;

                  setState((prev) => ({
                    ...prev,
                    UI: {
                      ...prev.UI,
                      CurrentFlight: {
                        ...prev.UI.CurrentFlight,
                        Airline: value,
                      },
                    },
                  }));
                  if (value.length >= 3) {
                    await searchAirlines("airline", value);
                  } else {
                    setState((prev) => ({
                      ...prev,
                      Airlines: [],
                    }));
                  }
                }}
              />

              <DropDownList
                searchList={state.Airlines}
                displayValue={"Name"}
                setData={(i) => {
                  let selectedElement = state.Airlines[i];
                  setState((prev) => ({
                    ...prev,
                    UI: {
                      ...prev.UI,
                      CurrentFlight: {
                        ...prev.UI.CurrentFlight,
                        Airline: selectedElement.Name,
                        ICAO: selectedElement.ICAO,
                        IATA: selectedElement.IATA,
                        AirlineFlight: selectedElement.IATA
                          ? selectedElement.IATA
                          : selectedElement.ICAO,
                      },
                    },
                    Airlines: [],
                  }));
                  setTimeout(() => {
                    document.getElementById("flightNumberComplete").focus();
                  }, 100);
                }}
              />
            </div>
            <div className="col-12 my-2">
              <label>
                <FormattedMessage
                  id="flightNumber"
                  defaultMessage="Flight Number"
                />
              </label>
              <Field
                id="flightNumberComplete"
                autoComplete="off"
                //    id={idAutocomplete}
                className="form-control"
                type="text"
                name="AirlineFlight"
                value={state.UI.CurrentFlight.AirlineFlight}
                onChange={async (e) => {
                  const { name, value } = e?.target;
                  try {
                    setState((prev) => ({
                      ...prev,
                      UI: {
                        ...prev.UI,
                        CurrentFlight: {
                          ...prev.UI.CurrentFlight,
                          AirlineFlight: value,
                        },
                      },
                      AfterSearch: false,
                    }));
                  } catch (ex) {
                    console.error(ex);
                  }
                }}
              />
            </div>
            <div className="col-12 my-4">
              {state.AfterSearch && state.APIFlights.length === 0 ? (
                <div className="card shadow-sm mt-4">
                  <div className="card-body px-1">
                    <p className="text-center">
                      <i className="fas fa-exclamation-triangle text-warning fa-3x mb-2"></i>
                      <br />
                      <FormattedMessage
                        id="noResultsFlightaware"
                        defaultMessage="No Flight Results Found from Flightaware"
                      />
                    </p>
                    <p className="text-center">
                      <button
                        className="btn btn-primary text-light my-2 "
                        disabled={state.UI.isLoading}
                        onClick={() => {
                          createFlightManually();
                        }}
                      >
                        {state.UI.isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        Add Manually
                      </button>
                    </p>
                  </div>
                </div>
              ) : null}
              {state.AfterSearch === false ? (
                <button
                  className="btn btn-primary btn-block text-light "
                  disabled={state.UI.isLoading}
                  onClick={() => {
                    let item = state.UI.CurrentFlight;
                    searchFlight("AirlineFlight", item, true);
                  }}
                >
                  {state.UI.isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  Continue
                </button>
              ) : null}
            </div>

            {state.APIFlights.length > 0 ? (
              <div className="col-12 ">
                <h4 className="title mt-4">Flight Aware results</h4>
                <hr className="my-2" />
                {state.APIFlights.map((flight) => {
                  return (
                    <div
                      key={uuid.v4()}
                      className="card shadow-sm my-1 clickable"
                      onClick={() => {
                        selectAFlight(flight, "add");
                        /*  setMainFlight(
                                                { ...flight.flights[0], ICAO: props.values.ICAO },
                                                props
                                              );*/
                      }}
                    >
                      <div className="card-body py-1 px-2">
                        <h5 className="title">
                          {flight.From} - {flight.To}
                        </h5>
                        <p className="subTitle">
                          {moment(flight.DepartureDate).format("LT")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    );
  };

  const SelectFlight = (flight) => {
    console.log("flight selected", flight);
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        CurrentFlight: flight,
      },
    }));
  };

  const flightScreen = () => {
    let CurrentFlight = state.UI.CurrentFlight;
    //CurrentFlight.ToLongName = "";
    let ui = (
      <ComercialFlightForm
        CurrentFlight={CurrentFlight}
        parentState={state}
        setParentState={setState}
        searchAirports={searchAirports}
        searchAirlines={searchAirlines}
        searchFlight={searchFlight}
        deleteFlight={deleteFlight}
        selectAFlight={selectAFlight}
        submitFlight={submitFlight}
        bindData={props.bindData}
      />
    );
    return ui;
  };

  const deleteFlight = (item) => {
    let flights = structuredClone(state.Flights);
    let flightIndex = flights.findIndex((x) => x.GUID === item.GUID);
    Swal.fire({
      text:
        "Are you sure you want to delete 'Flight no. " +
        (flightIndex + 1) +
        " " +
        item.From +
        " - " +
        item.To +
        "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        flights[flightIndex].Deleted = new Date();

        setState((prev) => {
          return {
            ...prev,
            Flights: flights,
            UI: {
              ...prev.UI,
              CurrentFlight: flights[0],
            },
          };
        });
      }
    });
  };
  const AddStop = () => {
    //Convert the state to objet to deal with it better
    let flights = structuredClone(state.Flights);
    let currentFlight = flights[flights.length - 1]; //Last record
    //Save the changes on the current flight
    let index = flights.findIndex((x) => x.GUID === currentFlight.GUID);
    //Now start updating the info based on the new record information
    let newFlight = { ...currentFlight };
    newFlight.GUID = uuid.v4();
    newFlight.DepartureDate = currentFlight.ArrivalDate;
    newFlight.From = currentFlight.To;
    newFlight.FromAddress = currentFlight.ToAddress;
    newFlight.FromLatitude = currentFlight.ToLatitude;
    newFlight.FromLongitude = currentFlight.ToLongitude;
    newFlight.FromLongName = ""; //currentFlight.FromLongName;
    newFlight.Duration = "";
    newFlight.Label = "";
    newFlight.AirlineFlight = currentFlight.AirlineFlight.replace(/[0-9]/g, "");
    newFlight.To = "";
    newFlight.ToAddress = null;
    newFlight.ToLatitude = null;
    newFlight.Connection = flights[0].Connection;
    newFlight.ToLongitude = null;
    newFlight.ToLongName = null;

    flights.push(newFlight);
    setState((prev) => ({
      ...prev,
      Flights: flights,
      UI: {
        ...prev.UI,
        CurrentFlight: newFlight,
      },
    }));
  };

  const submitFlight = (obj) => {
    try {
      //Convert the state to objet to deal with it better
      let parsedState = state;
      //console.log("the current state", parsedState, obj);
      //debugger;
      //Check if exist in the current state
      if (parsedState.Flights.length > 0) {
        //Find the current flight and start updating it
        let exist = parsedState.Flights.findIndex((x) => x.GUID === obj.GUID);
        if (exist !== -1) {
          // console.log(
          //   "previous flight ",
          //   parsedState.Flights[exist],
          //   "new value",
          //   obj,
          //   "current flight",
          //   parsedState.UI.CurrentFlight
          // );
          obj.GUID = parsedState.Flights[exist].GUID;
          parsedState.Flights[exist] = obj;
          //debugger;
          //      parsedState.UI.CurrentFlight = obj;
          setState(parsedState);

          // state.UI.CurrentFlight.set(obj);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  const ToggleAddEditLabel = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowAddEditLabel: !prev.UI.ShowAddEditLabel,
      },
    }));
  };

  const SaveTheFlights = async (reload) => {
    let flights = structuredClone(state.Flights);
    flights && SelectFlight(flights[0]);
    //Check the object of flights
    flights.forEach((flight) => {
      flight.Connection = flights[0].Connection; //All the connections should be the same
      flight.EventGUID = props.event.GUID;
      flight.FlightStaffs = [];
    });
    //Assign the label to the first one
    flights[0].Label = state.UI.Label;

    //Send to the server
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoading: !prev.UI.isLoading,
      },
    }));
    let request = await apiService.post("/flights/AddEditBatch", flights);

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        isLoading: !prev.UI.isLoading,
      },
    }));

    if (reload) {
      dispatch(actions.getFlights(props.event.GUID));
    }
    if (props.IsEditing) {
      props.toggle(true);
    } else {
      //TODO Implement localstate rather than global to avoid re-renders
      props.setFlights((prev) => {
        let newState = prev ?? "";
        newState.push(flights[0]);
        return newState;
      });
      toggleMemberModal();
      //Pass to the next screen
    }
  };

  const toggleMemberModal = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowMembersModal: !prev.UI.ShowMembersModal,
      },
    }));
  };

  const teams = async () => {
    let organization = getOrganization();
    let teamRecords = (
      await db.table("organization").where({ GUID: organization.GUID }).first()
    ).Roles;

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        Teams: teamRecords.filter((x) => x.Deleted === null),
      },
    }));
    return teamRecords;
  };

  const updatePassenger = (reservation) => {
    //debugger;
    let staffs = state.UI.staffSelected.map((staff) => {
      if (staff.StaffGUID === reservation.GUID) {
        staff = { ...staff, ...reservation };
        return staff;
      }
      return staff;
    });

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        staffSelected: staffs,
      },
    }));
  };
  const toggleSelectMember = (staff) => {
    try {
      if (staff === undefined) {
        return;
      }
      let selected = state.UI.staffSelected;
      if (selected.length > 0) {
        //Check if exist
        let exist = selected.findIndex((x) => x.StaffGUID === staff.GUID);
        if (exist.length !== -1 && selected[exist] !== undefined) {
          //Exist
          //Must be deleted
          if (selected[exist].Deleted !== null) {
            selected[exist].Deleted = null;
          } else {
            selected[exist].Deleted = moment().format("YYYY-MM-DDThh:mm:ss");
          }
          setState((prev) => ({
            ...prev,
            UI: {
              ...prev.UI,
              staffSelected: selected,
            },
          }));
        } else {
          //dont exist
          let item = {
            StaffGUID: staff.GUID,
            FlightGUID: state.Flights[0].GUID,
            GUID: uuid.v4(),
            Deleted: null,
            ReservationNumber: null,
            SeatNumber: null,
            TicketNumber: null,
          };

          selected.push(item);
          setState((prev) => ({
            ...prev,
            UI: {
              ...prev.UI,
              staffSelected: selected,
            },
          }));
        }
      } else {
        let item = {
          StaffGUID: staff.GUID,
          FlightGUID: state.Flights[0].GUID,
          GUID: uuid.v4(),
          Deleted: null,
          ReservationNumber: null,
          SeatNumber: null,
          TicketNumber: null,
        };

        selected.push(item);
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            staffSelected: selected,
          },
        }));
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const saveMembers = async (skip) => {
    if (skip !== true) {
      let staff = state.UI.staffSelected;
      await api.post("/FlightStaff/AddEditBatch", staff);
    }

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowMembersModal: false,
      },
    }));
    //let flight = JSON.parse(JSON.stringify(state.Flights[0].value));

    setGlobalState((prev) => ({
      ...prev,
      EventsUI: { ...prev.EventsUI, ReloadFlight: true },
    }));
    dispatch(actions.getFlights(props.event.GUID));

    props.toggle();
  };

  console.log({ state });
  return (
    <div className="organization-transparent">
      <ModalComponent
        modal={state.UI.ShowAddEditLabel}
        toggle={() => ToggleAddEditLabel()}
        childeren={
          <AddEditLabel
            toggle={ToggleAddEditLabel}
            parentState={state}
            setParentState={setState}
          />
        }
      />

      <ModalComponent
        modal={state.UI.ShowMembersModal}
        toggle={toggleMemberModal}
        childeren={
          <AssignMembersFlight
            handleSidebarItem={props.handleSidebarItem}
            flight={state.Flights[0]}
            toggle={toggleMemberModal}
            teams={teams}
            eventStaffs={teams}
            toggleMember={toggleSelectMember}
            arrayToBeSelected={state.UI.staffSelected}
            currentPassengers={[]}
            edit={true}
            parentToggle={props.toggle}
            saveMembers={saveMembers}
            actions={actions}
            setCurrentState={props.setFlights}
            updatePassenger={updatePassenger}
            stops={state.Flights.filter(
              (x) => x.GUID !== state?.Flights[0]?.GUID
            )}
            handleSide
            donable
          />
        }
      />

      <div className="event-page text-dark">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title">
                <FormattedMessage
                  id="addCommercialFlight"
                  defaultMessage="Add Commercial Flight"
                />
              </h5>
              {state?.UI?.Label ? (
                <small className="text-muted">{state.UI.Label}</small>
              ) : null}
            </div>
            <div className="text-right">
              {state.UI.Page === "flight" ? (
                <button
                  className="btn btn-block btn-primary btn-sm"
                  onClick={() => {
                    //We have to add the current flight to the list (first checking that the id does not exist) and then adding a new record

                    ToggleAddEditLabel();
                  }}
                >
                  {state.UI.Label ? (
                    <FormattedMessage
                      id="editLabel"
                      defaultMessage="Edit Label"
                    />
                  ) : (
                    <FormattedMessage
                      id="addLabel"
                      defaultMessage="Add Label"
                    />
                  )}
                </button>
              ) : null}
            </div>
          </div>
          <div className="modal-body p-1 px-0">
            <StopNavigation
              parentState={state}
              SelectFlight={SelectFlight}
              AddStop={AddStop}
              deleteFlight={deleteFlight}
            />
            {state.UI.Page === "search" ? startScreen() : null}
            {state.UI.Page === "flight" ? flightScreen() : null}
          </div>
          {state.UI.Page !== "search" ? (
            <div className="modal-footer text-right">
              <div className="col-12 px-1">
                {props.IsEditing ? (
                  <button
                    className="btn btn-block btn-primary"
                    readOnly={state.UI.isLoading}
                    type="button"
                    onClick={() => SaveTheFlights(true)}
                  >
                    <FormattedMessage id="done" defaultMessage="Done" />
                    {state.UI.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </button>
                ) : (
                  <button
                    className="btn btn-block btn-primary"
                    readOnly={state.UI.isLoading}
                    type="button"
                    onClick={() => SaveTheFlights()}
                  >
                    <FormattedMessage id="done" defaultMessage="Done" /> &amp;
                    Assign
                    {state.UI.isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

///The redux thing

export default AddEditFlights;

//END redux thing
//export default AddEditFlights;
