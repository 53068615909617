import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function sendEmail({
  GUID,
  EmailAddress,
  Title,
  SenderId,
  Content,
  success,
  error,
}) {
  const url = "/Email/Send";

  return async (dispatch) => {
    const email = { GUID, EmailAddress, Title, SenderId, Content };
    try {
      dispatch({
        type: constant.EXPORTING_EVENT,
      });
      await db.table("emailHistory").put({ ...email, Sent: new Date() });
      const request = await api.post(url, {
        EmailAddress,
        Title,
        SenderId,
        Content,
      });
      if (request.status === 200) {
        await db.table("emailHistory").put({ ...email, Sent: true });
        success();
      } else {
        await db
          .table("emailHistory")
          .put({ ...email, Sent: false, Error: true });
        error();
      }
      dispatch({
        type: constant.EXPORTED_EVENT,
      });
    } catch (error) {
      dispatch({
        type: constant.EXPORTING_EVENT_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_EVENT: {
      return {
        ...state,
        sendingEmail: true,
      };
    }
    case constant.EXPORTED_EVENT: {
      return {
        ...state,
        sendingEmail: false,
      };
    }
    case constant.EXPORTING_EVENT_ERROR: {
      return {
        ...state,
        sendingEmail: false,
      };
    }
    default:
      return state;
  }
}
