import api from "../../../common/api";
import * as constant from "./constants";

export function toggleStaff(staff) {
  const url = staff.Active
    ? "Staff/Desactivate?staffguid"
    : "Staff/Activate?staffguid";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.TOGGLING_STAFF,
      });
      const request = await api.get(`${url}=${staff.GUID}`);
      dispatch({
        type: constant.TOGGLED_STAFF,
        data: staff,
      });
    } catch (error) {
      dispatch({
        type: constant.TOGGLING_STAFF_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.TOGGLING_STAFF: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.TOGGLED_STAFF: {
      return {
        ...state,
        loading: false,
        teams: state.teams.map((team) => {
          if (team.GUID === action.data.RoleGUID) {
            team.Staffs.map((staff) => {
              if (staff.GUID === action.data.GUID) {
                staff.Active = !staff.Active;
                return staff;
              }

              return staff;
            });
          }

          return team;
        }),
      };
    }
    case constant.TOGGLING_STAFF_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
