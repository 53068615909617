import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteNote(note, success) {
  const url = `/Note/AddEditBatch`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_NOTES,
      });
      
      
      let request= await api.post(url, [{ ...note, Deleted: new Date() }]);
      if(request.data){
        request.data.forEach(async item=>{
          if(item.Deleted !== null){
            await db.activeNotes.put(item);
          }
          await db.notes.put(item)
        })
      }
      debugger;
      dispatch({
        type: constant.DELETED_NOTES,
        data: note,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.DELETING_NOTES_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_NOTES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_NOTES: {
      return {
        ...state,
        loading: false,
        notes: state.notes.filter((n) => n.GUID !== action.data.GUID),
      };
    }
    case constant.DELETING_NOTES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
