import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "../event/Marker";



const googleKey = "AIzaSyAl35-aKfZA9eK9PtXT74aR_GHSDSZXqcc"
export const MapComponent = props => {
  const GoogleMapRef = React.createRef();
  const [center, setCenter] = useState({ lat: 40.6971494, lng: -74.2598655 });
  const [zoom, setZoom] = useState(14);


  useEffect(() => {

    if (props.event) {
      if (props.event.Venue && props.event.Venue.Latitude) {
        setCenter({ lat: props.event.Venue.Latitude, lng: props.event.Venue.Longitude });
      } else if (props.event.Latitude) {
        setCenter({ lat: props.event.Latitude, lng: props.event.Longitude });
      }
    }
  }, [props.event])

  useEffect(() => {
    console.log("enter on click on item", props.CenterMap)
    let parsed = JSON.parse(JSON.stringify((props.CenterMap)));
    if (parsed) {
      setCenter(parsed);
    }
  }, [props.CenterMap]);
  // const handleApiLoaded = (map, maps) => {

  //   let copy = state;

  //   copy.map = map;
  //   copy.maps = maps;
  //   console.log("value of map ====");
  //   console.log(copy.value);


  //   this.setState(copy);

  // }

  return (
    <div className="col-12 map p-0">

      <GoogleMapReact
        ref={GoogleMapRef}
        bootstrapURLKeys={{ key: googleKey }}
        defaultCenter={{ lat: 40.6971494, lng: -74.2598655 }}
        center={center}
        defaultZoom={zoom}

      >
        {props.Points && props.Points.map((point, index) => {
          return (<Marker
            key={index}
            index={index}
            text={point.place.Name}
            lat={point.place.Latitude}
            lng={point.place.Longitude}
          />)
        })}
        {/* <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          /> */}
      </GoogleMapReact>


    </div>)
}
