export const GETTING_ALL_CITIES = "GETTING_ALL_CITIES";
export const GETTING_ALL_CITIES_ERROR = "GETTING_ALL_CITIES_ERROR";
export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const CLEAR_ALL_CITIES = "CLEAR_ALL_CITIES";

export const GETTING_ALL_COUNTRIES = "GETTING_ALL_COUNTRIES";
export const GETTING_ALL_COUNTRIES_ERROR = "GETTING_ALL_COUNTRIES_ERROR";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const CLEAR_ALL_COUNTRIES = "CLEAR_ALL_COUNTRIES";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const EXPANDED_SIDEBAR = "EXPANDED_SIDEBAR";
export const SLIM_SIDEBAR = "SLIM_SIDEBAR";

export const POPULATE_VENUES = "POPULATE_VENUES";
export const CLEAR_ALL_VENUES = "CLEAR_ALL_VENUES";

export const SEARCHING_HOTELS = "SEARCHING_HOTELS";
export const POPULATE_HOTELS = "POPULATE_HOTELS";
export const CLEAR_ALL_HOTELS = "CLEAR_ALL_HOTELS";

export const MOVING_RESOURCE = "MOVING_RESOURCE";
export const MOVED_RESOURCE = "MOVED_RESOURCE";
export const MOVING_RESOURCE_ERROR = "MOVING_RESOURCE_ERROR";

export const EXPORTING_RESOURCE = "EXPORTING_RESOURCE";
export const EXPORTED_RESOURCE = "EXPORTED_RESOURCE";
export const EXPORTING_RESOURCE_ERROR = "EXPORTING_RESOURCE_ERROR";

export const EXPORTING_EVENT = "EXPORTING_EVENT";
export const EXPORTED_EVENT = "EXPORTED_EVENT";
export const EXPORTING_EVENT_ERROR = "EXPORTING_EVENT_ERROR";

export const SHARING_LINK = "SHARING_LINK";
export const SHARED_LINK = "SHARED_LINK";
export const SHARING_LINK_ERROR = "SHARING_LINK_ERROR";

export const DELETING_SHARING_LINK = "DELETING_SHARING_LINK";
export const DELETED_SHARING_LINK = "DELETED_SHARING_LINK";
export const DELETING_SHARING_LINK_ERROR = "DELETING_SHARING_LINK_ERROR";

export const SET_NEW_NOTE = "SET_NEW_NOTE";
export const REMOVE_NOTE = "REMOVE_NOTE";
export const CLEAR_NOTES = "CLEAR_NOTES";
export const SET_ACTIVE_NOTES = "SET_ACTIVE_NOTES";
export const DELETING_NOTES = "DELETING_NOTES";
export const DELETED_NOTES = "DELETED_NOTES";
export const DELETING_NOTES_ERROR = "DELETING_NOTES_ERROR";

export const GETTING_NOTIFICATIONS = "GETTING_NOTIFICATIONS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GETTING_NOTIFICATIONS_ERROR = "GETTING_NOTIFICATIONS_ERROR";

export const CLEANING_SESSION = "CLEANING_SESSION";
export const CLEANED_SESSION = "CLEANED_SESSION";
