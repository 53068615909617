import React, { createRef, useEffect, useRef, useState } from "react";

import GoogleMapReact from 'google-map-react';
import Marker from "../../../components/event/Marker";
import moment from "moment";
import { Event } from "../../../components/event/event";
import Scrollbars from "react-custom-scrollbars";



const googleKey = "AIzaSyAl35-aKfZA9eK9PtXT74aR_GHSDSZXqcc"
const CalendarMapView = props => {
    let containerScroller = useRef();
    const GoogleMapRef = createRef;

    const [center, setCenter] = useState({ lat: 40.6971494, lng: -74.2598655 });
    const [zoom, setZoom] = useState(15);

    const [theMap, setTheMap] = useState(null);
    const [theMapsReference, setTheMapsReference] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);
    const handleApiLoaded = (map, mapsReference) => {
        setTheMap(map);
        setTheMapsReference(mapsReference);
    }


    let scrollNearTo = () => {
        let element = containerScroller.current;
        let elementEstimatedHeight = 58;
        //find first index of dates in the array of events
        if (props.events.length === 0) {
          // console.log("we don't have the local events yet")
          return;
        }

        let now = moment().format("YYYY-MM-DD");
        let selectedEvent = props.events.filter(x => x.Date >= now)[0];
        let theIndex = props?.events?.findIndex((e) => e?.GUID === selectedEvent?.GUID)
        //console.log("we found the event", selectedEvent, "Position", theIndex, "on the collection", records)
    
        if (theIndex > 0) {
          //check if we have more than 10 events ahead
          if (props.events.length - theIndex > 10) {
            element.scrollTop(elementEstimatedHeight * (theIndex + 10));
          } else if (theIndex - 5 > 0) {
            //check if we have 10 events below so we can try to center
            element.scrollTop(elementEstimatedHeight * (theIndex + 5));
    
          } else {
            element.scrollTop(elementEstimatedHeight * theIndex);
          }
    
        }
    
      }

    useEffect(() => {
        //Move center to next upcoming event;
        let now = moment().format("YYYY-MM-DD");
        let upcomingEvent = props.events.filter(x => x.Date >= now)[0];
        if (upcomingEvent) {
            setCurrentEvent(upcomingEvent);
            setCenter({
                lat: upcomingEvent.Latitude,
                lng: upcomingEvent.Longitude
            })
        }
        scrollNearTo();

    }, [])

    return (
        <div className="calendarContainer">
            <div className="mapEventContainer" >
            <Scrollbars ref={containerScroller}>
                {props.events && props.events.map((theEvent, index) => {
                    return (
                        <div className="card mb-2 clickable"
                            onClick={()=>{
                                setCenter({
                                    lat: theEvent.Latitude,
                                    lng : theEvent.Longitude
                                })
                            }}
                        >
                            <div className="card-body p-1">
                                <Event key={index} event={theEvent}  noIcons  
                                noClickable
                              setCurrentEvent={currentEvent}
                                />
                            </div>
                        </div>
                    )
                })}
                </Scrollbars>
            </div>
            <div className="mapContainer">
                <GoogleMapReact
                    ref={GoogleMapRef}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    style={{ width: "100%", minHeight: "300px", height: "100%", position: "relative", borderRadius: '50px' }}

                    bootstrapURLKeys={{ key: googleKey }}
                    defaultCenter={{ lat: 40.6971494, lng: -74.2598655 }}
                    center={center}
                    defaultZoom={zoom}

                >
                    {props.events && props.events.map((point, index) => {
                        return (<Marker
                            onClick={props.toggleEditEvent}
                            key={index}
                            index={index}
                            event={point}
                            text={point.Name}
                            lat={point.Latitude}
                            lng={point.Longitude}
                        />)
                    })}
                    {/* <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          /> */}
                </GoogleMapReact>
            </div>
        </div>
    )
}

export default CalendarMapView;