import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

export const DropDownListSub = (props) => {
  let {
    searchList,
    setData,
    displayValue,
    cursor,
    isFlight,
    subDisplayValue,
  } = props;
  return (
    <ul className="dropdown-list">
      {searchList.length > 0 &&
        searchList.map((data, i) => {
          return (
            <li key={Math.random()}>
              <a onMouseDown={() => setData(i)}> {data[displayValue]}</a>
              <span>{data[subDisplayValue]}</span>
            </li>
          );
        })}
    </ul>
  );
};
