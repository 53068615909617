import React, { useState, useEffect } from "react";
import { IMAGES } from "../../constant";

import {randomImage} from '../common/randomImage';

export default function UserItem(props) {
  const { show, user, toggleProfile } = props;
  const [ userObject, setUserObject ] = useState({ fullName: '', img: '', initials: ''})

  useEffect(() => {
    let fullName = user.Name + " "+ user.LastName.charAt(0);
    let initials=(user.Name && user.Name.charAt(0).toUpperCase())+(user.LastName && user.LastName.charAt(0));
    let img = randomImage(initials);
  
    setUserObject({
      fullName,
      initials,
      img,

    })
  }, [])


  return (
    <div onClick={() => toggleProfile()} className="userItem sidebarUserItem p-2">
      <div className="userItem__avatar">
        {userObject.img ? (
          <img
            src={userObject.img}
            
            alt={userObject.fullName} title={userObject.fullName}
            className="img-fluid avatar"
          />
        ) : (
          <img
            src={userObject.img}
            alt={userObject.fullName} title={userObject.fullName}
            className="img-fluid avatar"
          />
        )}
      </div>
      <h6
        style={{ display: show ? "block" : "none" }}
        className="userItem__text text-truncate"
      >
        {userObject && `${userObject.fullName}`}
      </h6>
    </div>
  );
}
