import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";

const ImportModal = ({ toggle, parseAndPrepareIcsFile, skipIcs }) => {
  return (
    <div className="vehicleModal__root " style={{ padding: "12px 18px" }}>
      <div className="d-flex w-100 justify-content-between">
        <h5 style={{ color: "black" }}>
          <FormattedMessage
            id="importYourFile"
            defaultMessage="Import your ics file"
          />
        </h5>
        <button
          type="button"
          className="btn btn-sm btn-link ml-2"
          onClick={() => toggle()}
        >
          <FontAwesomeIcon icon={faTimes} color="black" />
        </button>
      </div>
      <hr />
      <p className="text-dark">
        <FormattedMessage
          id="grabYourFile"
          defaultMessage="Grab your .ics file and we do the job to import your new events."
        />
      </p>
      <div className="text-center my-2">
        <label for="ics-upload" className="btn btn-lg btn-primary">
          <i className="fa fa-calendar-plus text-white mr-2" />
          <FormattedMessage
            id="importYourFile"
            defaultMessage="Import your .ICS file"
          />
        </label>
        <input
          className="hide d-none"
          id="ics-upload"
          type="file"
          accept="text/calendar"
          onChange={(e) => parseAndPrepareIcsFile(e.target.files[0])}
        />
      </div>
      <hr />
      <div className="text-right">
        <button
          onClick={() => skipIcs()}
          type="button"
          className="btn btn-link text-warning d-none"
        >
          <FormattedMessage id="Skip" defaultMessage="Skip" />
        </button>
      </div>
    </div>
  );
};

export default ImportModal;
