export const TYPES_VENUE = [
  "Amphitheater",
  "Arena",
  "Ballroom",
  "Bar",
  "Club",
  "Convention Center",
  "Outdoor",
  "Parking Lot",
  "Ballroom",
  "Private Location",
  "Stadium",
  "Theater",
];
