import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import uuid from "uuid";
import db from "../../../common/db";

export function addCustomVenue(venue, callback, event) {
  const url = "/Venues/AddEdit";
  const urlEvent = "/Events/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_VENUE,
    });
    try {
      delete event.Venue;
      delete event.VenueGUID;
      const request = await apiService.post(url, { ...venue, GUID: uuid.v4() });
      dispatch({
        type: constant.CREATED_VENUE_SUCCESS,
        data: request.data,
      });
      callback();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_VENUE_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_VENUE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_VENUE_SUCCESS: {
      console.log(action.data.GUID);
      return {
        ...state,
        loading: false,
        eventVenue: action.data,
        event: Object.assign({}, state.event, {
          Venue: action.data,
          VenueGUID: action.data.GUID,
        }),
      };
    }
    case constant.CREATING_VENUE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
