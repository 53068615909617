import React, { Component } from "react";
import PropTypes from "prop-types";
import ChangePassword from "./ChangePassword";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ROUTES } from "../../../constant";
import history from "../../../components/common/history";
import { default as Navbar } from "../../../components/common/navbar";
import { getToken } from "../../../utils";
import { ToastNotificationSuccess } from "../../../components/common/toastNotification";

import * as authActions from "../redux/actions";

class ChangePasswordContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.props.auth.requested === true) {
      ToastNotificationSuccess(1, "You password has been changed succesfully");
    }
  }

  render() {
    const { authActions, history, auth } = this.props;
    return (
      <ChangePassword
        loading={auth.logging}
        requested={auth.requested}
        history={history}
        changePassword={authActions.changePassword}
        token={getToken(this.props.location.search)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators({ ...authActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);
