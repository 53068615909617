import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { IMAGES } from "../../constant";
import CopyContent from "../../common/helpers";
let jitsiObj = null;

const Meeting = (props) => {
  const [state, setState] = useState({
    StartMeeting: false,
    isMuted: false,
    isVideoOff: false,
    Username: "",
  });
  useEffect(() => {
    initialiseJitsi("preMeetVideo", false);

    let meetingInfo = JSON.parse(window.localStorage.getItem("meetingInfo"));
    if (!meetingInfo) {
      meetingInfo = { Name: "" };
      window.localStorage.setItem("meetingInfo", JSON.stringify(meetingInfo));
    } else {
      setState((prev) => ({
        ...prev,
        Username: meetingInfo.Name,
      }));
    }
  }, []);
  const loadJitsiScript = async () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async (target, initMeeting, id) => {
    setState((prev) => ({
      ...prev,
      StartMeeting: initMeeting,
    }));
    if (!window.JitsiMeetExternalAPI || initMeeting) {
      await loadJitsiScript();
    }
    let name = state.Username;
    if (id === undefined) {
      id = v4();
    }
    const container = document.getElementById(target);

    const _jitsi = new window.JitsiMeetExternalAPI("jitsi.debian.social", {
      parentNode: container,
      configOverwrite: {
        startWithAudioMuted: state.isMuted,
        startWithVideoMuted: state.isVideoOff,
        toolbarConfig: {
          alwaysVisible: true,
        },
      },
      roomName: id,
      userInfo: {
        displayName: name,
      },
    });
    _jitsi.addListener("audioMuteStatusChanged", (ev) => {
      setState((prev) => ({
        ...prev,
        isMuted: ev.muted,
      }));

      console.log("toggle ev", ev);
    });
    _jitsi.addListener("videoMuteStatusChanged", (ev) => {
      setState((prev) => ({
        ...prev,
        isVideoOff: ev.muted,
      }));

      console.log("toggle ev", ev);
    });
    jitsiObj = _jitsi;
    let muted = await jitsiObj.isAudioMuted();
    setState((prev) => ({
      ...prev,
      isMuted: muted,
    }));

    let videoOff = await jitsiObj.isVideoMuted();
    setState((prev) => ({
      ...prev,
      isVideoOff: videoOff,
    }));

    if (!muted && state.isMuted) {
      toggleMute();
    }

    if (!videoOff && state.isVideoOff) {
      toggleVideo();
    }
  };

  const toggleMute = async () => {
    let audio = await jitsiObj.executeCommand("toggleAudio");

    let muted = await jitsiObj.isAudioMuted();
    setState((prev) => ({
      ...prev,
      isMuted: muted,
    }));
  };

  const toggleVideo = async () => {
    let video = await jitsiObj.executeCommand("toggleVideo");

    let videoOff = await jitsiObj.isVideoMuted();
    setState((prev) => ({
      ...prev,
      isVideoOff: videoOff,
    }));
  };
  let secondStyle = {
    backgroundImage: "url(" + IMAGES.LOGO_BLACK_P + ")",
  };
  return (
    <>
      <div className="noteWatermark" style={secondStyle}></div>
      <div className="row justify-content-center  mx-0">
        <div className="col-12">
          <div
            className={
              state.StartMeeting
                ? "dashboard-screen"
                : "dashboard-screen d-none"
            }
          >
            <div
              id="meet2"
              className=""
              style={{ height: "100vh", width: "100vw" }}
            ></div>
          </div>
        </div>
        <div
          className={
            !state.StartMeeting
              ? "dashboard-screen"
              : "dashboard-screen d-none"
          }
        >
          <div id="PreMeeting" className="row mx-0" style={{ height: "100vh" }}>
            <div className="col-12 col-md-6 mx-auto my-auto">
              <h4 className="display-2"></h4>
              <div className="card shadow-lg ">
                <div className="card-body row">
                  <div className="col-6">
                    {/* VIDEO */}
                    <div
                      id="preMeetVideo"
                      className="row mx-0"
                      style={{
                        height: "300px",
                        minHeight: "200px",
                        background: "black",
                      }}
                    ></div>
                  </div>
                  <div className="col-6 ">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="title">JOIN MEETING</h5>
                      </div>
                      <div className="col-12">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Name"
                          name="Name"
                          autoFocus
                          value={state.Username}
                          onChange={(e) => {
         
                            setState((prev) => ({
                                ...prev,
                                Username: e.target.value,
                              }));
                            
                            let meetingInfo = JSON.parse(
                              window.localStorage.getItem("meetingInfo")
                            );
                            meetingInfo.Name = e.target.value;
                            window.localStorage.setItem(
                              "meetingInfo",
                              JSON.stringify(meetingInfo)
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-2">
                        <label>Meeting Link</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              "https://web.plnify.com/#meet/" +
                              props.match.params.id
                            }
                          />
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              CopyContent(
                                "https://web.plnify.com/#meet/" +
                                  props.match.params.id
                              );
                            }}
                          >
                            <i className="fa fa-copy" />
                          </button>
                        </div>
                      </div>
                      <div className="col-12 text-center my-2">
                        <button
                          className="btn btn-secondary mx-1"
                          onClick={() => toggleMute()}
                        >
                          {state.isMuted ? (
                            <i className="fa fa-microphone-slash" />
                          ) : (
                            <i className="fa fa-microphone" />
                          )}
                        </button>
                        <button
                          className="btn btn-secondary mx-1"
                          onClick={() => toggleVideo()}
                        >
                          {state.isVideoOff ? (
                            <i className="fa fa-video-slash" />
                          ) : (
                            <i className="fa fa-video" />
                          )}
                        </button>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-block"
                          onClick={async () =>
                            await initialiseJitsi(
                              "meet2",
                              true,
                              props.match.params.id
                            )
                          }
                        >
                          Start Meeting
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Meeting;
