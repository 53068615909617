import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";
import moment from "moment";

function addHotelLocal(hotels) {
  let finalHotels = hotels.map((hotel) => ({
    ...hotel,
    Checkout: `${moment(hotel.Checkout).format("YYYY-MM-DD")}T${moment(
      hotel.CheckoutTime
    ).format("HH:mm")}`,
    Checkin: `${moment(hotel.Checkin).format("YYYY-MM-DD")}T${moment(
      hotel.CheckinTime
    ).format("HH:mm")}`,
    HotelReservations: [],
  }));
  return db.table("hotelsTransactions").bulkPut(finalHotels);
}

export function addHotel(hotels, success, localFirst) {
  console.log({ hotels, success, localFirst });

  const url = "/Hotels/AddEditBatch";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_HOTEL,
    });
    try {
      if (localFirst) {
        await addHotelLocal(hotels);
        const localHotels = await db.table("hotelsTransactions").toArray();
        dispatch({
          type: constant.CREATED_HOTEL_SUCCESS_LOCAL,
          data: localHotels,
        });
      }

      const localHotels = await db.table("hotelsTransactions").toArray();
      const finalHotels = localHotels.map((hotel) => ({
        ...hotel,
        HotelReservations: null,
      }));
      const request = await api.post(url, finalHotels);
      let responseHotels = request.data.Response.filter(
        (h) => h.Deleted === null
      );

      dispatch({
        type: constant.CREATED_HOTEL_SUCCESS,
        data: responseHotels,
      });
      await db.table("hotelsTransactions").clear();
      success();
    } catch (error) {
      dispatch({
        type: constant.CREATING_HOTEL_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_HOTEL: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_HOTEL_SUCCESS_LOCAL: {
      return {
        ...state,
        loading: true,
        hotels: [...state.hotels, action.data[0]],
      };
    }
    case constant.CREATED_HOTEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        hotels: action.data,
      };
    }
    case constant.CREATING_HOTEL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
