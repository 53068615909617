export const GETTING_ORGANIZATIONS = "GETTING_ORGANIZATIONS";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GETTING_ORGANIZATIONS_ERROR = "GETTING_ORGANIZATIONS_ERROR";

export const GETTING_CURRENT_ORGANIZATION = "GETTING_CURRENT_ORGANIZATION";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const GETTING_CURRENT_ORGANIZATION_ERROR =
  "GETTING_CURRENT_ORGANIZATION_ERROR";

export const EDITING_ORGANIZATION = "EDITING_ORGANIZATION";
export const EDITED_ORGANIZATION = "EDITED_ORGANIZATION";
export const EDITING_ORGANIZATION_ERROR = "EDITING_ORGANIZATION_ERROR";
export const CREATING_ORGANIZATION = "CREATING_ORGANIZATION";
export const CREATED_ORGANIZATION = "CREATED_ORGANIZATION";
export const CREATING_ORGANIZATION_ERROR = "CREATING_ORGANIZATION_ERROR";
export const EDITING_LOGO_ORGANIZATION = "EDITING_LOGO_ORGANIZATION";
export const EDITED_LOGO_ORGANIZATION = "EDITED_LOGO_ORGANIZATION";
export const EDITING_LOGO_ORGANIZATION_ERROR =
  "EDITING_LOGO_ORGANIZATION_ERROR";
export const DELETING_ORGANIZATION = "DELETING_ORGANIZATION";
export const DELETED_ORGANIZATION = "DELETED_ORGANIZATION";
export const DELETING_ORGANIZATION_ERROR = "DELETING_ORGANIZATION_ERROR";

export const ADD_TEAM = "ADD_TEAM";
export const ADDING_TEAM = "ADDING_TEAM";
export const ADDING_TEAM_ERROR = "ADDING_TEAM_ERROR";
export const SET_DEFAULT_ORGANIZATION_TEAMS = "SET_DEFAULT_ORGANIZATION_TEAMS";
export const CHANGE_TEAMS = "CHANGE_TEAMS";
export const CHANGE_TEAMS_ERROR = "CHANGE_TEAMS_ERROR";
export const CHANGING_TEAMS = "CHANGING_TEAMS";
export const EDITING_TEAM = "EDITING_TEAM";
export const EDITED_TEAM = "EDITED_TEAM";
export const EDITING_TEAM_ERROR = "EDITING_TEAM_ERROR";
export const DELETING_TEAM = "DELETING_TEAM";
export const DELETED_TEAM = "DELETED_TEAM";
export const DELETING_TEAM_ERROR = "DELETING_TEAM_ERROR";

export const CHECK_ACCOUNT = "CHECK_ACCOUNT";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const CLEAN_INVITES = "CLEAN_INVITES";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

export const TOGGLING_NOTIFICATIONS_TEAM = "TOGGLING_NOTIFICATIONS_TEAM";
export const TOGGLED_NOTIFICATIONS = "TOGGLED_NOTIFICATIONS";

export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION";

export const TOGGLING_STAFF = "TOGGLING_STAFF";
export const TOGGLED_STAFF = "TOGGLED_STAFF";
export const TOGGLING_STAFF_ERROR = "TOGGLING_STAFF_ERROR";

export const DELETING_STAFF = "DELETING_STAFF";
export const DELETED_STAFF = "DELETED_STAFF";
export const DELETING_STAFF_ERROR = "DELETING_STAFF_ERROR";
