import * as constant from "./constants";
import api from "../../../common/api";

export function getNotes(event) {
  const url = `/Note/NotesForEvent`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.GETTING_NOTES,
      });
      const request = await api.get(url, { params: { EventGUID: event } });
      
      const notesCleaned = request.data.filter((n) => n.Deleted === null);
      dispatch({
        type: constant.GET_NOTES,
        data: notesCleaned,
      });
    } catch (error) {
      dispatch({
        type: constant.GETTING_NOTES_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GETTING_NOTES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.GET_NOTES: {
      const notes = action.data;
      return {
        ...state,
        loading: false,
        notes,
      };
    }
    case constant.GETTING_NOTES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
