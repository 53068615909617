import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../page/calendar/redux/actions";
import { getBasicInfo, getOrganization } from "../../../utils";
import SelectableVisibleEvent from "../../event/SelectableVisibleEvent";
import { SimpleLoader } from "../simpleLoader";

const ExportModalComponent = (props) => {
  // const [state, setState] = useState({
  //   fromDate: null,
  //   toDate: null,
  //   pageNumber: 1,
  //   pageSize: 15,
  // });

  const {
    selectedTeam,
    team,
    eventsSelected,
    events,
    toggleEvent,
    submit,
    loading,
    title,
  } = props;

  return (
    <div className="organization-transparent">
      <div className="event-page">
        <div className="event-expennd-section move-modal">
          <div className="text-head headerBg">
            <div className="text-left">
              <h1>
                {title ? (
                  title
                ) : (
                  <FormattedMessage
                    id="copyToAnotherEvent"
                    defaultMessage="Copy to another event"
                  />
                )}
              </h1>
              <p>{team && selectedTeam && selectedTeam.Name} </p>
            </div>
          </div>

          <div className="event-inside move px-1">
            <div className="move-section">
              <Scrollbars
                style={{ width: "100%", height: `calc(100vh - 120px)` }}
              >
                {events?.length > 0 &&
                  events
                    .sort((a, b) => {
                      if (a.Date < b.Date) return 1;
                      if (a.Date > b.Date) return -1;
                      return 0;
                    })
                    .filter((event) => event.Status === "Confirmed")
                    .map((event) => (
                      <div className="card my-1 shadow-sm " key={event.GUID}>
                        <div className="card-body p-0">
                          <SelectableVisibleEvent
                            event={event}
                            toggleEvent={toggleEvent}
                            eventsSelected={eventsSelected}
                          />
                        </div>
                      </div>
                    ))}
              </Scrollbars>
            </div>
          </div>

          <div className="event-content">
            {loading ? (
              <SimpleLoader loading={loading} />
            ) : (
              <div className="two-buttons">
                <button
                  onClick={() => submit()}
                  type="button"
                  className="btn primary"
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExportModalComponent;
