import React, { Component } from "react";
import { default as Navbar } from "../../components/common/navbar";

import Profile from "./Profile";

class ProfileContainer extends Component {
  render() {
    return <Profile />;
  }
}

export default Navbar(ProfileContainer);
