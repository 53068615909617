import * as constant from "./constants";
import api from "../../../common/api";

export function getVisibles(event) {
  const url = "/Events/VisibleBy";
  return async (dispatch) => {
    dispatch({
      type: constant.GET_VISIBLES,
    });
    const request = await api.get(url, {
      params: {
        EventGUID: event,
      },
    });
    const staffs = request.data.response.map((s) => s);
    dispatch({
      type: constant.GET_VISIBLES_SUCCESS,
      data: staffs,
    });
    try {
    } catch (error) {
      dispatch({
        type: constant.GET_VISIBLES_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_VISIBLES_SUCCESS: {
      return {
        ...state,
        visibles: action.data,
      };
    }
    default:
      return state;
  }
}
