import React, { Component } from "react";
import AdminOrganizations from "./AdminOrganizations";

class AdminOrganizationsContainer extends Component {
  state = {};
  render() {
    return <AdminOrganizations />;
  }
}

export default AdminOrganizationsContainer;
