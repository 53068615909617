export const EVENT_COLOR = {
  Confirmed: "green-left",
  Postponed: "purple-left",
  Cancelled: "red-left",
  "Tentative/Hold": "yellow-left",
};

export const EVENT_COLOR_ROUND = {
  Confirmed: "green-round",
  Postponed: "purple-round",
  Cancelled: "red-round",
  "Tentative/Hold": "yellow-round",
};

export const EVENT_TYPES = [
  "After Party",
  "Award Show",
  "Band & Dance Rehearsal",
  "Band Rehearsal",
  "Club Appearance",
  "Concert",
  "Dance Rehearsal",
  "Day Off",
  "Festival",
  "Guest Appearance",
  "Load-In",
  "Load-Out",
  "Media Day",
  "Media Tour",
  "Meet & Greet",
  "Meeting",
  "One-Off",
  "Other",
  "Pre-Production",
  "Pre-Rig",
  "Private Event",
  "Production Rehearsal",
  "Radio Interview",
  "Radio Promo",
  "Radio Show",
  "Recording",
  "Rehearsals",
  "Residency",
  "Show Day",
  "Soundcheck",
  "Soundcheck/Load In",
  "Speaking Engagement",
  "Tour",
  "Travel Day",
  "Travel Home",
  "Travel/Day Off",
  "TV Interview",
  "TV Performance",
  "TV Promo",
  "TV Show",
  "Video Shoot",
];
