import React from "react";
import { UserComponent } from "./UserComponent";

export const UserGroupComponent = ({
  organization,
  HandleSelectAll,
  HandleSelectUser,
}) => {
  return (
    <div className="row px-0 selectorContainer" key={organization.GUID}>
      <div className="col-auto px-0 text-right  bgTitleModal py-1 mt-4">
        <button
          className="btn btn-sm text-secondary"
          disabled={organization.Staffs.length === 0}
          onClick={() =>
            HandleSelectAll({
              ...organization,
              isChecked: !organization.isChecked,
            })
          }
        >
          <input
            type="checkbox"
            checked={organization.isChecked}
            disabled={organization.Staffs.length === 0}
          />
        </button>
      </div>
      <div className="col bgTitleModal py-2 mt-4 text-black-50">
        <h3>{organization.Name}</h3>
      </div>
      {organization.Staffs.filter((staff) => staff.Deleted === null).map(
        (user) => {
          return (
            <UserComponent
              user={user}
              organization={organization}
              HandleSelectUser={HandleSelectUser}
            />
          );
        }
      )}
    </div>
  );
};
