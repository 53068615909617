import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import uuid from "uuid";
import api from "../../common/api";
import db from "../../common/db";
import { organizationUsers, sortArray } from "../../common/helpers";
import { getBasicInfo, getOrganization } from "../../utils";
import SelectMembersHotel from "../hotels/SelectMembersHotel";
import { MoveHotelItem } from "./MoveHotelItem";
import { ToastNotificationSuccess } from "./toastNotification";
import Swal from "sweetalert2";
import { Name } from "ajv";
import { IMAGES } from "../../constant";
import { ConsoleNetworkOutline } from "mdi-material-ui";
import moment from "moment";

//Object to receive
//Item = the object to work with
//Type = the type of object passed, helpful for identify how we can work the items

const HotelsToMultipleEventsModal = (props) => {
  const [state, setState] = useState({
    UI: {
      Current: props.Item,
      ShareViaEmail: false,
      ShareViaLink: false,
    },
    AccountGUID: "",
    SelectedUsers: [],
    SelectedRoles: [],
    SelectedEvents: [],
    SelectedHotels: [],
  });
  let containerScroller = useRef();

  const [localEvents, setLocalEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [teams, setTeams] = useState([]);

  let scrollNearTo = (records) => {
    let element = containerScroller.current;
    let elementEstimatedHeight = 58;
    if (records.length === 0) {
      return;
    }
    let theIndex = records.findIndex((e) => e.GUID === props.originEvent.GUID);
    if (theIndex > 0) {
      if (records.length - theIndex > 10) {
        element.scrollTop(elementEstimatedHeight * (theIndex + 10));
      } else if (theIndex - 5 > 0) {
        element.scrollTop(elementEstimatedHeight * (theIndex + 5));
      } else {
        element.scrollTop(elementEstimatedHeight * theIndex);
      }
    }
  };

  const bindData = async () => {
    try {
      setIsLoading(true);
      let currentOrganization = getOrganization();
      let localEvents = await db
        .table("events")
        .where({ OrganizationGUID: currentOrganization.GUID })
        .toArray();
      if (localEvents?.length > 0) {
        localEvents = localEvents.filter(
          (x) => x.Deleted === null && x.Date !== null
        );
        localEvents = sortArray(localEvents, "Date");
        let updatedEvents = localEvents
          .filter((event) => {
            return event.HotelsCount > 0;
          })
          .filter((event) => {
            const startOfMonth = new Date();
            startOfMonth.setDate(1);
            startOfMonth.setHours(0, 0, 0, 0);
            return new Date(event.Date) >= startOfMonth;
          });
        setLocalEvents(updatedEvents);
        setTimeout(() => {
          scrollNearTo(localEvents);
        }, 1000);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    let payload = [];

    state.SelectedHotels.forEach((hotel) => {
      state.SelectedUsers.forEach((user) => {
        payload.push({
          GUID: uuid.v4(),
          AccountGUID: user.AccountGUID,
          HotelGUID: hotel.hotelGUID,
          CheckInDate: moment(hotel.checkIn).format("YYYY-MM-DD"),
          CheckOutDate: moment(hotel.checkOut).format("YYYY-MM-DD"),
          Room: user.Room,
          Number: user.Number,
          RoomType: user.RoomType,
        });
      });
    });

    let usersHTML = "";

    state.SelectedUsers.forEach((user, index) => {
      usersHTML = usersHTML.concat(
        `<div class="d-flex flex-column mb-1">
          <p class="w-100">${index + 1}. ${user.Name}</p>
          <p class="pl-3">${user.Email}</p>
        </div>`
      );
    });

    let hotelsHTML = "";

    state.SelectedHotels.forEach((hotel, index) => {
      hotelsHTML = hotelsHTML.concat(
        `<p class="w-100">${index + 1}. ${hotel.hotelName}</p>`
      );
    });

    try {
      Swal.fire({
        title: "Summary",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,

        html: `<div class="d-flex flex-column">
          <span>Are you sure you want to add the following users to the selected hotels?</span>
          <div class="w-100 d-flex flex-column text-left pl-3" >
          <span class="text-left mb-1 mt-3">Users (${state.SelectedUsers.length}):</span>
          <div class="w-100 d-flex flex-column text-left pl-3" style="
            max-height: 150px;
            overflow: auto;
          ">${usersHTML}</div>
          </div>
          <div class="w-100 d-flex flex-column text-left pl-3">
          <span class="text-left mb-1 mt-3">Hotels (${state.SelectedHotels.length}):</span>
          <div class="w-100 d-flex flex-column text-left pl-3" style="
            max-height: 150px;
            overflow: auto;
          ">${hotelsHTML}</div>
          </div>
        </div>`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.post("/HotelReservations/AddEditMultiple", payload);
          ToastNotificationSuccess(
            uuid.v4(),
            "Users added to hotels successfully"
          );
        }
      });
    } catch (ex) {
      console.error(ex);
    }
    props.toggle();
  };

  useEffect(() => {
    (async () => {
      let basicInfo = getBasicInfo();
      let theOrganization = getOrganization();

      let users = await organizationUsers(theOrganization.GUID);
      let filteredUsers = await users.map((user) => {
        return {
          ...user,
          Staffs: user.Staffs.filter((x) => x.Deleted === null),
        };
      });

      let finalUsers = filteredUsers.filter((x) => x.Staffs.length > 0);

      setTeams(finalUsers);

      setState({
        ...state,
        AccountGUID: basicInfo.GUID,
      });

      bindData();
    })();
  }, []);

  const checkExist = (staff) => {
    try {
      if (!props.active) {
        return;
      }
      let users = state.SelectedUsers;
      let record = users.findIndex(
        (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
      );
      return record >= 0;
    } catch (ex) {
      console.error(ex);
    }
  };

  const selectAllUsers = (role) => {
    let selectedRole = state.SelectedRoles;
    let selectedUsers = state.SelectedUsers;
    const isRoleSelected = selectedRole.includes(role.GUID);
    if (!isRoleSelected) {
      selectedRole.push(role.GUID);
      role.Staffs.forEach((staff) => {
        if (
          !selectedUsers.some(
            (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
          ) &&
          !checkExist(staff)
        ) {
          selectedUsers.push({
            HotelGUID: props?.hotel?.GUID,
            GUID: uuid.v4(),
            AccountGUID: staff.AccountGUID,
            Deleted: null,
            Room: "",
            Number: "",
            RoomType: "",
            CheckInDate: props?.hotel?.Checkin,
            CheckOutDate: props?.hotel?.Checkout,
            Name: `${staff.Account.Name} ${staff.Account.LastName}`,
            Email: staff.Account.Email,
          });
        }
      });
    } else {
      selectedRole = selectedRole.filter((x) => x !== role.GUID);
      selectedUsers = selectedUsers.filter(
        (x) => !role.Staffs.some((staff) => staff.AccountGUID === x.AccountGUID)
      );
    }
    setState({
      ...state,
      SelectedRoles: selectedRole,
      SelectedUsers: selectedUsers,
    });
  };

  const handleSelectUser = (staff) => {
    console.log({ staff });
    let updatedSelectedUsers = state.SelectedUsers;
    let updatedSelectedRoles = state.SelectedRoles;
    const checkSelection = updatedSelectedUsers.filter(
      (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
    ).length;
    if (checkSelection === 0) {
      if (!checkExist(staff)) {
        updatedSelectedUsers.push({
          HotelGUID: props?.hotel?.GUID,
          GUID: uuid.v4(),
          AccountGUID: staff.AccountGUID,
          Deleted: null,
          Room: "",
          Number: "",
          RoomType: "",
          CheckInDate: props?.hotel?.Checkin,
          CheckOutDate: props?.hotel?.Checkout,
          Name: `${staff.Account.Name} ${staff.Account.LastName}`,
          Email: staff.Account.Email,
        });
      }
    } else if (checkSelection > 0) {
      updatedSelectedUsers = updatedSelectedUsers.filter(
        (x) => x.AccountGUID !== staff.AccountGUID
      );
    }
    const roleGUID = staff.RoleGUID;
    const role = teams?.find((team) => team.GUID === roleGUID);
    const allRoleUsersSelected = role.Staffs.every((roleStaff) =>
      updatedSelectedUsers.some(
        (x) => x.AccountGUID === roleStaff.AccountGUID && x.Deleted === null
      )
    );
    if (!allRoleUsersSelected) {
      updatedSelectedRoles = updatedSelectedRoles.filter((x) => x !== roleGUID);
    } else if (!updatedSelectedRoles.includes(roleGUID)) {
      updatedSelectedRoles.push(roleGUID);
    }
    setState({
      ...state,
      SelectedUsers: updatedSelectedUsers,
      SelectedRoles: updatedSelectedRoles,
    });
  };

  const updateSelectedUser = (staff, field, value) => {
    let updatedSelectedUsers = state.SelectedUsers;
    let checkSelection = updatedSelectedUsers.filter(
      (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
    ).length;

    if (checkSelection > 0) {
      let index = updatedSelectedUsers.findIndex(
        (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
      );
      updatedSelectedUsers[index][field] = value;
    }

    setState({
      ...state,
      SelectedUsers: updatedSelectedUsers,
    });
  };

  const handleSelectEvent = (event) => {
    let eventGUID = event.GUID;
    let selectedEvents = state?.SelectedEvents;
    let selectedHotels = state?.SelectedHotels;

    if (selectedEvents?.includes(eventGUID)) {
      selectedEvents = selectedEvents.filter((x) => x !== eventGUID);
    } else {
      selectedEvents.push(eventGUID);
    }

    let updatedHotels = [];

    selectedEvents.forEach((event) => {
      let newHotels = selectedHotels.filter((x) => x.eventGUID === event);
      updatedHotels = updatedHotels.concat(newHotels);
    });

    setState({
      ...state,
      SelectedEvents: selectedEvents,
      SelectedHotels: updatedHotels,
    });
  };

  const handleSelectHotel = (hotel) => {
    let hotelGUID = hotel.hotelGUID;
    let selectedHotels = state?.SelectedHotels;
    let selectedEvents = state?.SelectedEvents;

    if (selectedHotels?.find((x) => x.hotelGUID === hotelGUID)) {
      selectedHotels = selectedHotels.filter((x) => x.hotelGUID !== hotelGUID);
    } else {
      selectedHotels.push(hotel);
    }

    let updatedHotels = [];

    selectedEvents.forEach((event) => {
      let newHotels = selectedHotels.filter((x) => x.eventGUID === event);
      updatedHotels = updatedHotels.concat(newHotels);
    });

    setState({
      ...state,
      SelectedHotels: updatedHotels,
    });
  };

  return (
    <div
      className="organization-transparent"
      style={{
        width: "100%",
      }}
    >
      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section " style={{ width: "100%" }}>
          <div className="text-head">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <h1 className="title">
                <FormattedMessage
                  id="export"
                  defaultMessage="Add Users To Hotels"
                />
              </h1>
              <button
                className="btn btn-sm btn-danger border-0"
                onClick={props.toggle}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row">
              <div className="col-4">
                <div style={{ height: "calc(100vh - 145px)" }}>
                  <small className="d-flex justify-content-between w-100 align-items-center">
                    <b>
                      1.
                      <FormattedMessage
                        id="selectWhereCopy"
                        defaultMessage="Select Users"
                      />
                    </b>
                    {/* <p>
                      {state.SelectedUsers.length === 1
                        ? `${state.SelectedUsers.length} user selected`
                        : `${state.SelectedUsers.length} users selected`}
                    </p> */}
                  </small>
                  <br />
                  <Scrollbars style={{ width: "100%", height: "calc(100%)" }}>
                    {teams?.map((team, index) => {
                      return (
                        <div className="row px-0 " key={index}>
                          <div className="col-auto px-0 mx-0 bgTitleModal py-1 mt-4">
                            <button
                              className="btn btn-sm text-secondary"
                              onClick={() => selectAllUsers(team)}
                            >
                              {state.SelectedRoles.includes(team.GUID) ? (
                                <i className="fas fa-check-square"></i>
                              ) : (
                                <i className="far fa-square uncheck"></i>
                              )}
                            </button>
                          </div>
                          <div className="col bgTitleModal py-1 mt-4 ">
                            <h3 className="pt-2">{team.Name}</h3>
                          </div>
                          {team.Staffs.filter((x) => x.Deleted === null).map(
                            (item, index) => {
                              const exist =
                                state.SelectedUsers.filter(
                                  (selected) =>
                                    selected?.AccountGUID === item?.AccountGUID
                                ).length > 0;

                              let parsedStaff = item;

                              let usersSelected = state.SelectedUsers;
                              let Reservation = null;
                              if (exist) {
                                let record = usersSelected.filter(
                                  (x) =>
                                    x.AccountGUID === parsedStaff.AccountGUID
                                )[0];
                                Reservation = record;
                              }

                              return (
                                <SelectMembersHotel
                                  key={index}
                                  doesExist={exist}
                                  selectedItems={state.SelectedUsers}
                                  parsedStaff={parsedStaff}
                                  hotel={{}}
                                  toggleMember={handleSelectUser}
                                  updateSelected={updateSelectedUser}
                                  Reservation={Reservation}
                                />
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                  </Scrollbars>
                </div>
              </div>
              <div className="col-8 px-0">
                {/* Events */}

                <div style={{ height: "calc(100vh - 125px)" }}>
                  <small>
                    <b>
                      2.
                      <FormattedMessage id="selectTheEvent" />
                    </b>
                  </small>
                  <br />
                  <Scrollbars
                    style={{ width: "100%", height: "calc(100%)" }}
                    ref={containerScroller}
                  >
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "calc(100vh - 125px)" }}
                      >
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      localEvents &&
                      localEvents
                        .filter((event) => event.HotelsCount > 0)
                        .map((event) => {
                          // let theDate = new Date(event.Date);
                          let classforEvent =
                            props.originEvent &&
                            event.GUID === props.originEvent.GUID
                              ? "active"
                              : "";

                          let isSelected =
                            selectedEvents.findIndex(
                              (x) => x.GUID === event.GUID
                            ) >= 0;

                          return (
                            <MoveHotelItem
                              event={event}
                              toggle={props.toggle}
                              isSelected={isSelected}
                              selectedEvent={event}
                              title={event.Name}
                              handleSelectEvent={handleSelectEvent}
                              selectedItems={selectedTarget}
                              setSectionsParams={() => {}}
                              sectionsParams={selectedTarget}
                              isParentChecked={state?.SelectedEvents?.includes(
                                event.GUID
                              )}
                              handleParams={handleSelectHotel}
                            />
                          );
                        })
                    )}
                  </Scrollbars>
                </div>
              </div>

              {/* <div className="col-4">
                <div style={{ height: "calc(100vh - 125px)" }}>
                  <small>
                    <b>
                      3.
                      <FormattedMessage
                        id="selectWhereCopy"
                        defaultMessage="Select Hotels"
                      />
                    </b>
                  </small>
                  <br />
                  <Scrollbars style={{ width: "100%", height: "calc(100%)" }}>
                    <div className="card shadow-sm my-1">
                      <div className="card-body p-0">
                        {props.section === "hotel"
                          ? hotels &&
                            hotels.length > 0 &&
                            hotels.map((hotel) => {
                              return (
                                <HotelItem
                                  hotel={hotel}
                                  selectOnly
                                  pickTargetItem={pickTargetItem}
                                  selectedItem={selectedTarget}
                                />
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div> */}
            </div>
            <div className="col-12 text-right">
              <hr />
              <div className="d-flex justify-content-end">
                <div>
                  <span>
                    {state.SelectedUsers.length === 1
                      ? `${state.SelectedUsers.length} user selected`
                      : `${state.SelectedUsers.length} users selected`}
                    {" / "}
                    {state.SelectedHotels.length === 1
                      ? `${state.SelectedHotels.length} hotel selected`
                      : `${state.SelectedHotels.length} hotels selected`}
                  </span>
                </div>

                <button
                  className="btn btn-primary ml-2"
                  onClick={() => handleSubmit()}
                  disabled={
                    state.SelectedUsers.length === 0 ||
                    state.SelectedEvents.length === 0
                  }
                >
                  <FormattedMessage id="done" defaultMessage="Continue" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelsToMultipleEventsModal;
