import React, { useState, useEffect } from "react";
import { IMAGES } from "../../constant";
import { Scrollbars } from "react-custom-scrollbars";
import { DropDownList } from "..";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { customVenueSchema } from "../../utils/validationsSchemas";
import { PencilOutline } from "mdi-material-ui";
import { ModalComponent } from "../common";
import EditVenueModal from "./EditVenueModal";
import Select from "react-select";
import { TYPES_VENUE } from "../../constant";
import { useIntl, FormattedMessage } from "react-intl";
import * as commonActions from "../../page/common/redux/actions";
import { useDispatch } from "react-redux";
import * as actions from "../../page/event/redux/actions";

const VenueModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let {
    toggle,
    setVenue,
    cities,
    emptyCities,
    emptyCountries,
    countries,
    getCities,
    getCountrys,
    addCustomVenue,
    event,
    emptyVenues,
    editVenueAction,
    handleSidebarItem,
    venue,
  } = props;
  const [venueName, setVenueName] = useState("");
  const [manualVenue, setManualVenue] = useState(false);
  const [editVenue, setEditVenue] = useState(false);
  const [venueToEdit, setVenueToEdit] = useState(null);

  useEffect(() => {
    if (venue) {
      setVenueName(venue);
      dispatch(actions.getVenues(venue));
    }
  }, [venue]);

  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", place.split(",")[1].trim());
      props.setFieldValue("Country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", "");
      props.setFieldValue("Country", place.split(",")[1].trim());
    }
  }

  function changeFields(props, venue) {
    props.setFieldValue("Venue.Name", venue.Name);
    props.setFieldValue("Venue.City", venue.City);
    props.setFieldValue("Venue.State", venue.State);
    props.setFieldValue("Venue.Country", venue.Country);
    props.setFieldValue("VenueGUID", venue.GUID);
    props.setFieldValue("Venue.Name", venue.Name);
    props.setFieldValue("Venue.City", venue.City);
    props.setFieldValue("Venue.State", venue.State);
    props.setFieldValue("Venue.Country", venue.Country);
    props.setFieldValue("VenueGUID", venue.GUID);
  }

  const [showCapacity, setShowCapacity] = useState(false);

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1>
                <FormattedMessage id="venues" defaultMessage="Venues" />
              </h1>
            </div>
            <div className="close-icon ">
              <button
                onClick={() => setManualVenue((manualVenue) => !manualVenue)}
                className="btn btn-primary float-right"
              >
                {manualVenue ? (
                  <FormattedMessage
                    id="backSearchVenue"
                    defaultMessage="Back to Search venue"
                  />
                ) : (
                  <i className="fa fa-plus" />
                )}
              </button>
            </div>
          </div>
          <div className="event-inside py-2">
            <ModalComponent
              modal={editVenue}
              toggle={() => setEditVenue((editVenue) => !editVenue)}
              childeren={
                <EditVenueModal
                  toggle={() => setEditVenue((editVenue) => !editVenue)}
                  venueToEdit={venueToEdit}
                  editMode
                />
              }
            />

            {manualVenue ? (
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={customVenueSchema}
                onSubmit={(values) => {
                  dispatch(
                    actions.addCustomVenue(
                      { ...values, State: null, Type: null },
                      // () => toggle(),
                      () => {
                        setManualVenue(false);
                        setVenueName(values?.Name);
                        if (values?.Name?.length >= 3) {
                          dispatch(actions.getVenues(values?.Name));
                        } else if (values?.Name?.length < 3) {
                          dispatch(commonActions.emptyVenues());
                        }
                      },
                      event
                    )
                  );
                }}
                initialValues={{
                  Name: "",
                  ZipCode: "",
                  City: "",
                  State: "",
                  Country: "",
                  Address: "",
                  Capacity: 0,
                  Type: "",
                  otherType: "",
                }}
              >
                {(props) => (
                  <Form autoComplete="off" className="row mx-0">
                    <FormField
                      fullWidth={true}
                      label={
                        <FormattedMessage id="name" defaultMessage="Name" />
                      }
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="Name"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Name"
                        component="div"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      label={
                        <FormattedMessage
                          id="Address"
                          defaultMessage="Address"
                        />
                      }
                    >
                      <Field
                        className="form-control text-black-50"
                        type="text"
                        name="Address"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Address"
                        component="div"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      label={
                        <FormattedMessage
                          id="city.signUp"
                          defaultMessage="City"
                        />
                      }
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="City"
                        onChange={(e) => {
                          props.setFieldValue("City", e.currentTarget.value);
                          if (e.currentTarget.value.length > 3) {
                            dispatch(
                              commonActions.searchCity(e.currentTarget.value)
                            );
                          }
                        }}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="City"
                        component="div"
                      />
                      <DropDownList
                        searchList={cities}
                        setData={(id) => {
                          setStateOrNotState(cities[id].Address, props);
                          dispatch(commonActions.emptyCities());
                        }}
                        displayValue={"Address"}
                      />
                    </FormField>
                    <FormField
                      fullWidth={false}
                      label={
                        <FormattedMessage
                          id="stateProvinceRegion"
                          defaultMessage="State / Province / Region"
                        />
                      }
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="State"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="State"
                        component="div"
                      />
                    </FormField>
                    <FormField
                      fullWidth={false}
                      label={
                        <FormattedMessage
                          id="zipCode"
                          defaultMessage="Zip Code"
                        />
                      }
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="ZipCode"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="ZipCode"
                        component="div"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      label={
                        <FormattedMessage
                          id="country.signUp"
                          defaultMessage="Country"
                        />
                      }
                    >
                      <Field
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        name="Country"
                        onChange={(e) => {
                          props.setFieldValue("country", e.currentTarget.value);
                          if (e.currentTarget.value.length > 3) {
                            dispatch(
                              commonActions.searchCountry(e.currentTarget.value)
                            );
                          }
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Country"
                        component="div"
                      />
                      <DropDownList
                        searchList={countries}
                        setData={(id) => {
                          props.setFieldValue("country", countries[id].Name);
                          dispatch(commonActions.emptyCountries());
                        }}
                        displayValue={"Name"}
                      />
                    </FormField>
                    <FormField
                      fullWidth={false}
                      label={
                        <div className="px-0 pl-1 d-flex">
                          <FormattedMessage
                            id="capacity"
                            defaultMessage="Capacity"
                          />
                          <div className="ml-2 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="input "
                              onClick={() => setShowCapacity((prev) => !prev)}
                            />
                            <label className="ml-1">Add Capacity</label>
                          </div>
                        </div>
                      }
                      additionalClasses="col-6 px-0 pr-1"
                      style={{ position: "relative", top: 14 }}
                    >
                      {showCapacity && (
                        <>
                          <Field
                            autoComplete="off"
                            className="form-control"
                            type="number"
                            name="Capacity"
                          />

                          <ErrorMessage
                            className="error-message"
                            name="Capacity"
                            component="div"
                          />
                        </>
                      )}
                    </FormField>
                    <div className="mb-3 border-bottom col-6 col-6 px-0 pl-1">
                      <label>
                        <FormattedMessage id="type" defaultMessage="Type" />
                      </label>
                      <Select
                        placeholder={
                          TYPES_VENUE
                            ? TYPES_VENUE.find((o) => o === props.values.Type)
                            : intl.formatMessage({ id: "selectVenueType" })
                        }
                        value={
                          TYPES_VENUE
                            ? TYPES_VENUE.find((o) => o === props.values.Type)
                            : ""
                        }
                        onChange={(value) => {
                          props.setFieldValue("Type", value.value);
                        }}
                        options={[
                          ...TYPES_VENUE.map((type) => ({
                            value: type,
                            label: type,
                          })).sort((a, b) => (a.label > b.label ? 1 : -1)),
                          {
                            value: "Other",
                            label: intl.formatMessage({ id: "Other" }),
                          },
                        ]} // sort by label
                      />

                      <ErrorMessage
                        className="error-message"
                        name="Type"
                        component="div"
                      />
                    </div>

                    {props.values.Type === "Other" && (
                      <div className="mb-3 col-6 col-6 px-0 pl-1">
                        <Field
                          className="form-control p-2"
                          type="text"
                          name="otherType"
                          placeholder={intl.formatMessage({
                            id: "Enter Type",
                          })}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="otherType"
                          component="div"
                        />
                      </div>
                    )}
                    <button type="submit" className="btn primary block">
                      <FormattedMessage id="add" defaultMessage="Add" />
                    </button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <form>
                  <label hrmlfor="venueSearch">
                    <FormattedMessage
                      id="searchVenue"
                      defaultMessage="Search the venue"
                    />
                  </label>
                  <input
                    autoComplete="off"
                    value={venueName}
                    maxLength={100}
                    type="text"
                    className="form-control"
                    id="venueSearch"
                    onChange={(e) => {
                      setVenueName(e.currentTarget.value);
                      if (e.currentTarget.value.length >= 3) {
                        dispatch(actions.getVenues(e.currentTarget.value));
                      } else if (e.currentTarget.value.length < 3) {
                        dispatch(commonActions.emptyVenues());
                      }
                    }}
                  />
                </form>
                <div>
                  <Scrollbars
                    style={{
                      width: "100%",
                      height: "calc(100vh - 110px)",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {props.venues && props.venues.length ? (
                      props.venues.map((venue) => {
                        let fullAddress = "";
                        if (venue !== null && venue.Address) {
                          fullAddress += venue.Address;
                        }
                        if (venue !== null && venue.City) {
                          fullAddress +=
                            fullAddress.length > 0
                              ? ", " + venue.City
                              : venue.City;
                        }
                        if (venue !== null && venue.ZipCode) {
                          fullAddress +=
                            fullAddress.length > 0
                              ? ", " + venue.ZipCode
                              : venue.ZipCode;
                        }
                        if (venue !== null && venue.State) {
                          fullAddress +=
                            fullAddress.length > 0
                              ? ", " + venue.State
                              : venue.State;
                        }
                        if (venue !== null && venue.Country) {
                          fullAddress +=
                            fullAddress.length > 0
                              ? ", " + venue.Country
                              : venue.Country;
                        }
                        fullAddress = fullAddress.replace(" ,", ",");
                        let thirdLine = "";
                        if (venue !== null && venue.Type) {
                          thirdLine = "Type: " + venue.Type;
                        }

                        if (venue !== null && venue.Capacity) {
                          thirdLine +=
                            thirdLine.length > 0
                              ? " | Capacity: " + venue.Capacity
                              : "Capacity: " + venue.Capacity;
                        }
                        return (
                          <div
                            className="venues__item card shadow-sm my-1"
                            key={venue.GUID}
                          >
                            <div className="card-body p-1 mx-0 row align-items-center">
                              <div className="col-10">
                                <h5
                                  className="title"
                                  onClick={() => {
                                    changeFields(props, venue);
                                    dispatch(
                                      setVenue(venue, event, () => {
                                        // handleSidebarItem("EVENT_DETAILS", {});
                                        toggle();
                                      })
                                    );
                                    dispatch(commonActions.emptyVenues());
                                  }}
                                >
                                  {venue.Name}
                                </h5>
                                <p className="text-truncate subTitle">
                                  {fullAddress}
                                </p>
                                <p className="text-truncate subTitle">
                                  {thirdLine}
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(
                                        `https://www.google.com/maps/search/?api=1&query=${
                                          venue.Name + ", " + fullAddress
                                        }`,
                                        "MapWindow",
                                        "width=1280,height=840"
                                      );
                                    }}
                                    className="btn btn-link  altLinkSmall p-0 ml-2 "
                                    title="Open in google"
                                  >
                                    <i className="fas fa-external-link-square-alt"></i>
                                  </button>
                                </p>
                              </div>
                              <div
                                className=" col-2 text-right"
                                onClick={() => {
                                  setEditVenue((editVenue) => !editVenue);
                                  setVenueToEdit(venue);
                                }}
                              >
                                <PencilOutline htmlColor="#000" />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="h-100 d-flex justify-content-center">
                        <div
                          className="text-center"
                          style={{ alignSelf: "center" }}
                        >
                          <img
                            src={IMAGES.VENUE_IMAGE_PLACEHOLDER}
                            className="img-fluid"
                            title="venue"
                          />
                        </div>
                      </div>
                    )}
                  </Scrollbars>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueModal;
