import React from "react";

export const ExportTab = ({ title, icon, setIsActive, isActive }) => {
  return (
    <div
      className={`d-flex align-items-center mr-3 px-4 clickable  ${
        isActive ? "border-primary text-primary" : ""
      }`}
      style={{
        cursor: "pointer",
        transition: "all 0.5s",
        borderBottom: isActive ? "4px solid" : "4px solid transparent",
      }}
      onClick={() => setIsActive(title)}
    >
      <i className={`mr-2 fa ${icon}`}></i>
      <span>{title}</span>
    </div>
  );
};
