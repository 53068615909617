import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChatBox from "react-chat-plugin";
import {
  Close,
  WindowMinimize,
  WindowMaximize,
  Phone,
  Image,
} from "mdi-material-ui";
import {
  isEqual,
  convertMessagesToReadable,
  sortByDate,
  converToChatMessage,
  getBasicInfo,
} from "../../utils";
import uuid from "uuid";
import * as actions from "../../page/meet/redux/actions";
import { ModalComponent } from "../common";
import ConversationDetail from "./modal/ConversationDetail";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      minimized: false,
      currentUser: null,
      detailModal: false,
      conversation: null
    };
  }

  componentDidMount() {
    const { connection, conversation } = this.props;
    const currentUser = getBasicInfo();
    this.setState({
      currentUser,conversation:conversation
    });
    this.enterConversation(this.props.conversation);
    connection.invoke(
      "Messages",
      conversation.conversationGUID,
      currentUser.GUID,
      0,
      20
    );
  }

  componentDidUpdate(prevProps) {
    const { connection, conversation } = this.props;
    if (
      !isEqual(
        prevProps.conversation.conversationGUID,
        conversation.conversationGUID
      )
    ) {
      this.leaveConversation(prevProps.conversation);
      this.enterConversation(this.props.conversation);
      connection.invoke(
        "Messages",
        conversation.conversationGUID,
        this.state.currentUser.GUID,
        0,
        20
      );
    }
    if (!isEqual(prevProps.chatMessages, this.props.chatMessages)) {
      const messages = this.props.chatMessages
        ? convertMessagesToReadable(sortByDate(this.props.chatMessages))
        : [];
      this.setState({
        messages,
      });
    }
  }

  componentWillUnmount() {
    this.leaveConversation(this.props.conversation);
  }

  enterConversation = async (conversation) => {
    const { connection } = this.props;

    await connection.invoke("EnterConversation", conversation.conversationGUID);
    console.log("ping enter");
    await connection.invoke(
      "ConversationDetails",
      conversation.conversationGUID
    );
  };

  leaveConversation = async (conversation) => {
    const { connection } = this.props;
    //if(connection.)
    await connection.invoke("LeaveConversation", conversation.conversationGUID);
  };

  onToggleMinimized = () => {
    this.setState({
      minimized: !this.state.minimized,
    });
  };

  onSendMessage = async (message) => {
    const { currentUser, connection, conversation } = this.props;

    const messageToSend = {
      Guid: uuid.v4(),
      SenderAccountGuid: currentUser.GUID,
      ConversationGuid: conversation.conversationGUID,
      Message: message,
      Date: new Date(),
      Image: null, 
    };
    console.log("MESSAGE SENT SendMessage");
    console.log(messageToSend);

    await connection.invoke("SendMessage", messageToSend, false);
    messageToSend.message = message;
    messageToSend.senderAccount = currentUser;
    const convertedMessage = converToChatMessage(messageToSend);
    return this.setState({
      messages: [...this.state.messages, convertedMessage],
    });
  };

  toggleDetailModal = () => {
    this.setState({
      detailModal: !this.state.detailModal,
    });
  };

  render() {
    const {
      enableChatWindow,
      actions: {closeChatWindows},
      conversation,
      conversationDetails,
      connection,
    } = this.props;

     //Validate the name 

     let name = "";
     if(this.state.conversation  !== null){
      if(this.state.conversation.name === undefined){

        console.log("Conversation obj :", this.state.conversation);
      }
      if(this.state.conversation.name !== null ){
        name = this.state.conversation.name
      }else if(conversation.conversationMember !== undefined && 
        conversation.conversationMember.length >= 2){
        conversation.conversationMember.forEach(item=>{
          name+=(name.length>0? ", ":"")+item.accountGu.name;
        });
      }else{
        name ="-";
      }
    }

     console.log("Conversation name :", name);
     
    const { minimized, currentUser } = this.state;
    if (currentUser) {
      return (
        <div
          className={`chat__window ${minimized ? "minimized" : ""} shadow-lg`}
        >
          <ModalComponent
            modal={this.state.detailModal}
            toggle={() => this.toggleDetailModal()}
            childeren={
              <ConversationDetail
                conversation={conversationDetails}
                connection={connection}
                toggle={() => this.toggleDetailModal()}
              />
            }
          />
          <div className="chat__header px-2 text-dark">
            <p
              title={conversation && conversation.name}
              className="text-truncate"
              style={{ flex: 60 }}
            >
              <span
                className="c-pointer"
                onClick={() => this.toggleDetailModal()}
              >
                {name}
              </span>
            </p>
            <div
              style={{
                flex: 40,
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {minimized ? null : (
                <button className="btn btn-sm d-none" onClick={() => this.props.actions.makeMeet()}  >
                <i className="fa fa-phone"></i>
            </button>
               
              )}

              {minimized ? (
                   <button className="btn btn-sm" onClick={() => this.onToggleMinimized()}  >
                   <i className="far fa-window-maximize"></i>
               </button>
              ) : (
                <button className="btn btn-sm" onClick={() => this.onToggleMinimized()}  >
                  <i className="far fa-window-minimize"></i>
              </button>
                
              )}
              <button className="btn btn-sm" onClick={() => closeChatWindows(false)} >
                  <i className="fa fa-times"/>
              </button>
            </div>
          </div>
          {minimized ? null : (
            <ChatBox
              onSendMessage={this.onSendMessage}
              messages={this.state.messages}
              userId={currentUser.GUID}
              width={"400px"}
              height={"400px"}
            />
          )}

          {minimized ? null : (
            <div className="d-none chat__pic-uploader">
              <label htmlFor="file-upload" className="custom-file-upload">
                <Image />
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  chatMessages: state.meet.chatMessages,
  conversationDetails: state.meet.conversationDetails,
  connection: state.meet.connection,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Chat);
