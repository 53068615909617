import React from "react";
import { Bell } from "mdi-material-ui";
import moment from "moment";
import api from "../../common/api";
import { ToastNotificationSuccess } from "..";
import { getBasicInfo } from "../../utils";

const LogItem = (props) => {
  console.log("we got here", props)

const Restore = async item=>{
  let localUser = getBasicInfo();
  let request = await api.get("/activityLog/Restore?GUID="+item.GUID+"&AccountGUID="+localUser.GUID);
  if(request.data.Status === "ok"){
    ToastNotificationSuccess(1, "Item succefully restored. Screen will reload in 5 seconds");
    setTimeout(()=>{
      window.location.reload();
    }, 5000);
  }
}

  return (
    <div className="card shadow-sm my-1 text-dark mx-4">

      <div className="card-body p-1 px-2">
        <div className="row">
          <div className="col">
            <p className="pb-2">
              {props.item.Content}
              <br />
              <small className="text-muted">
                {props.item.DateOffset ?
                  moment(props.item.DateOffset).fromNow() :
                  moment(props.item.Date).fromNow()
                }
              </small>
            </p>
          </div>
          <div className="col-auto ml-auto">
                {props.item.SectionReference.includes("Delete")?
                <button className="btn btn-sm btn-secondary" type="button"
                onClick={()=> Restore (props.item)}
                >
                  Undo
                </button>: null}
          </div>
        </div>


      </div>
    </div>
  );
};

LogItem.propTypes = {};

export default LogItem;
