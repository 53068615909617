import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function updateNote(note) {
  const url = `/Note/AddEditBatch`;
  return async (dispatch) => {
    dispatch({
      type: constant.UPDATING_NOTES,
    });
    try {
      await db.table("notes").put(note);
      await db.table("activeNotes").put(note);
      await api.post(url, [note]);
      dispatch({
        type: constant.UPDATED_NOTES,
        data: note,
      });
    } catch (error) {
      dispatch({
        type: constant.UPDATING_NOTES_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.UPDATING_NOTES: {
      return {
        ...state,
      };
    }
    case constant.UPDATED_NOTES: {
      return {
        ...state,
        notes: state.notes.map((note) => {
          if (note.GUID === action.data.GUID) {
            note = action.data;
            return note;
          }

          return note;
        }),
      };
    }
    case constant.UPDATING_NOTES_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
