import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

export const DropDownVenue = (props) => {
  let {
    searchList,
    setData,
    displayValue,
    cursor,
    isFlight,
    subDisplayValue,
  } = props;
  
 
  return (
    <ul className="dropdown-list">
      {searchList && searchList.length > 0 &&
        searchList.map((data, i) => {
          let fullAddress = "";
          if(data !== null && data.Address){
            fullAddress += data.Address;
          }
          if(data !== null && data.City){
            fullAddress +=fullAddress.length> 0? ", "+data.City: data.City;
          }
          if(data !== null && data.ZipCode){
            fullAddress +=fullAddress.length> 0? ", "+data.ZipCode: data.ZipCode;
          }
          if(data !== null && data.State){
            fullAddress +=fullAddress.length> 0? ", "+data.State: data.State;
          }
          if(data !== null && data.Country){
            fullAddress +=fullAddress.length> 0? ", "+data.Country: data.Country;
          }
          fullAddress = fullAddress.replace(" ,", ",");
          let thirdLine = "";
          if(data !== null && data.Type){
            thirdLine = "Type: "+ data.Type;
          }

          if(data !== null && data.Capacity){
            thirdLine  +=thirdLine.length> 0? " | Capacity: "+data.Capacity: "Capacity: "+data.Capacity;
          }
          return (
            <li key={Math.random()}>
              <a onMouseDown={() => setData(i)}> 
                <h5 className="title text-truncate" title={data.Name}>{data.Name}</h5>
                <span className="text-truncate subTitle" title={fullAddress}>{fullAddress}</span>
              </a>
              <small className="text-muted row">
                <div className="col px-0 text-truncate" title={thirdLine}>
                  <span>
                    {thirdLine}
                  </span>
                </div>
                <div className="ml-auto col text-right pr-0">
                  <button  
                  onClick={
                    
                      (e) => {
                        e.preventDefault();
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${data.Name+", "+fullAddress}`,
                          "MapWindow",
                          "width=1280,height=840"
                        );
                      }
                    
                  }
                  className="btn btn-link  altLinkSmall p-0  " title="Open in google">
                    <i className="fas fa-external-link-square-alt"></i>
                  </button>
                </div>
              </small>
              
              <hr className="my-1 p-0"/>
            </li>
          );
        })}
    </ul>
  );
      }