import * as constants from "./constants";
import { apiService } from "../../../common/api";

export function acceptTickets(pass) {
  const url = `/Guestlist/AddEdit`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.UPDATING_TICKETS,
      });
      const request = await apiService.post(url, pass);
      dispatch({
        type: constants.UPDATED_TICKETS,
      });
    } catch (error) {
      dispatch({
        type: constants.UPDATING_TICKETS_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.UPDATING_TICKETS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.UPDATED_TICKETS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.UPDATING_TICKETS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
