import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import { IframeGoogleDoc, ToastNotificationSuccess } from "../common";
import { FileDetailComponent } from "../common/FileDetailComponent";
import LazyLoadImage from "../common/LazyLoadImage";

const iconPreview = (icon, attachment) => {
  switch (icon) {
    case "dl=0":
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.DROPBOX_ICON} alt="" />
        </div>
      );
    case "pdf":
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.PDF} alt="" />
        </div>
      );
    case "doc":
    case "docx":
    case "rtf":
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.WORD} alt="" />
        </div>
      );
    case "ppt":
    case "pptx":
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.PPT} />
        </div>
      );
    case "xls":
    case "xlsx":
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.EXCEL} />
        </div>
      );
    case "jpg":
    case "png":
    case "jpeg":
      return (
        <div className="attachment__icon">
          {/* <img
            className="img-fluid"
            src={`https://app.plnify.com/files/${attachment.Path}`}
          /> */}

          <LazyLoadImage
            src={`https://service.plnify.com/files/${attachment.Path}`}
          />
        </div>
      );
    default:
      return (
        <div className="attachment__icon">
          <img className="img-fluid" src={IMAGES.DEFAULT_FILE} />
        </div>
      );
  }
};

const allowedFormats = ["jpg", "png", "jpeg", "pdf", "mp3"];

const iframePreview = (icon, attachment) => {
  switch (icon) {
    case "jpg":
    case "png":
    case "jpeg":
      return (
        <div className="image-viewer">
          <LazyLoadImage
            src={`https://service.plnify.com/files/${attachment.Path}`}
          />
        </div>
      );
    case "pdf":
      return (
        <IframeGoogleDoc
          url={`https://app.plnify.com/files/${attachment.Path}`}
        />
      );

    case "mp3":
      return (
        <div className="w-100 d-flex flex-column align-items-center justify-content-between">
          {/* <img className="w-25" src={IMAGES.DEFAULT_FILE} /> */}
          <audio controls className="w-100">
            <source
              src={`https://app.plnify.com/files/${attachment.Path}`}
              type="audio/ogg"
            />
            <source
              src={`https://app.plnify.com/files/${attachment.Path}`}
              type="audio/mpeg"
            />
          </audio>
        </div>
      );

    default:
      return (
        <IframeGoogleDoc
          url={`https://app.plnify.com/files/${attachment.Path}`}
        />
      );
  }
};

const AttachmentModal = (props) => {
  let {
    attachment,
    editAttachment,
    deleteAttachment,
    attachments,
    handleSidebarItem,
  } = props;

  const dispatch = useDispatch();

  const intl = useIntl();
  function confirmDeleteAttachment(attachment) {
    Swal.fire({
      text:
        intl.formatMessage({ id: "Are you sure you want to delete" }) +
        attachment.Name +
        "?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          deleteAttachment(attachment, () => {
            ToastNotificationSuccess(1, "Attachment deleted");
            handleSidebarItem(
              attachments[1] ? "ATTACHMENT" : "EVENT_DETAILS",
              attachments[1] ? attachments[1] : {}
            );
            //  handleSidebarItem("ATTACHMENT", attachments[1]);
          })
        );
      }
    });
  }

  const handleSubmit = (values) => {
    dispatch(editAttachment(values));
    handleSidebarItem("ATTACHMENT", values);
    ToastNotificationSuccess(Math.random(), "Attachment edited successfully.");
  };

  return (
    <FileDetailComponent
      type={"ATTACHMENT"}
      file={attachment}
      editAttachment={editAttachment}
      confirmDeleteAttachment={confirmDeleteAttachment}
      handleSidebarItem={handleSidebarItem}
      handleSubmit={handleSubmit}
    />
  );
};

export default AttachmentModal;
