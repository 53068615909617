import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import api from "../../common/api";
import db from "../../common/db";
import { sortArray } from "../../common/helpers";
import { IMAGES } from "../../constant";
import * as actions from "../../page/event/redux/actions";
import { getOrganization } from "../../utils";

import uuid from "uuid";

const VisibleByItinerary = (props) => {
  // const teams = useSelector((state) => state.organizations.teams);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    teamsArray: [],
    Teams: [],
    SelectedItems: [],
  });
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      try {
        let currentOrganization = getOrganization();
        //Now search it locally
        let localOrganization = await db
          .table("organization")
          .where({ GUID: currentOrganization.GUID })
          .first();

        if (localOrganization) {
          //Load local teams
          let TeamsItems = localOrganization.Roles.filter(
            (x) => x.Deleted === null
          );
          TeamsItems = sortArray(TeamsItems, "Name");
          setState((prev) => ({ ...prev, Teams: TeamsItems }));

          //This is because a weird logistic inside here.
          let visibleByCollection =
            props.scheduleMaster.ScheduleMasterVisibleBies.filter(
              (r) => r.Deleted === null
            );
          const visiblesRoles = visibleByCollection.map((role) => role.GUID);
          setState((prev) => ({ ...prev, teamsArray: visiblesRoles }));
        }
        requestVisibleBy();
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  const requestVisibleBy = async () => {
    let request = await api.get(
      "ScheduleMasterVisibleBy/GetScheduleMasterVisibleBys?ScheduleMasterGUID=" +
        props.scheduleMaster.GUID
    );
    // console.log("response of the request", request, state);
    if (request.status === 200) {
      setState((prev) => ({
        ...prev,
        teamsArray: request.data
          .filter((x) => x.Deleted === null)
          .map((r) => r.RoleGUID),
      }));
      // console.log("response of the request", request, this.state);
    }
  };

  const toggleVisible = (role) => {
    const { teamsArray } = state;
    let roles = teamsArray;
    if (roles.includes(role.GUID)) {
      let filtered = roles.filter((s) => s !== role.GUID);
      return setState((prev) => ({ ...prev, teamsArray: filtered }));
    } else {
      let newOnes = roles.concat(role.GUID);
      return setState((prev) => ({ ...prev, teamsArray: newOnes }));
    }
  };

  const newVisiblesBy = () => {
    const { teamsArray } = state;
    const finalVisibles = state.Teams.map((team) => {
      if (teamsArray.includes(team.GUID)) {
        return {
          Deleted: null,
          GUID: uuid.v4(),
          RoleGUID: team.GUID,
          ScheduleMasterGUID: props.scheduleMaster.GUID,
        };
      } else {
        return {
          GUID: uuid.v4(),
          RoleGUID: team.GUID,
          ScheduleMasterGUID: props.scheduleMaster.GUID,
          Deleted: moment().format("YYYY-MM-DDTHH:mm:ss"),
        };
      }
    });
    dispatch(actions.editVisiblesItineraries(finalVisibles));
    props.toggle();
  };

  const { toggle, scheduleMaster } = props;

  return (
    scheduleMaster.ScheduleMasterVisibleBies !== undefined && (
      <div className="organization-transparent">
        <div className="event-page">
          <div className="event-expennd-section">
            <div className="text-head">
              <div className="text-left">
                <h1>{scheduleMaster.Name}</h1>
              </div>
              <div className="close-icon d-none" onClick={() => toggle()}>
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div>
            </div>
            <div className="event-inside px-3">
              <Scrollbars style={{ height: "calc(100vh - 85px)" }}>
                {state.Teams &&
                  sortArray(state.Teams, "Name").map((team) => {
                    let exist = state.teamsArray.indexOf(team.GUID);
                    console.log(
                      "team exist",
                      team.GUID,
                      state.teamsArray,
                      exist
                    );
                    return (
                      <div
                        key={team.GUID}
                        className="card shadow-sm my-1 text-dark d-flex align-items-center justify-content-between py-1 clickable"
                        onClick={() => toggleVisible(team)}
                      >
                        <div className="card-body row p-1">
                          <div className="col-auto">
                            {exist >= 0 ? (
                              <i className="fas fa-check-square"></i>
                            ) : (
                              <i className="far fa-square uncheck"></i>
                            )}
                          </div>
                          <div className="col">{team.Name}</div>
                        </div>
                      </div>
                    );
                  })}
                {/* {scheduleMaster.ScheduleMasterVisibleBies && sortArray( scheduleMaster.ScheduleMasterVisibleBies, "Name").map((team) => (
                <div
                  key={team.GUID}
                  className="card shadow-sm my-1 text-dark d-flex align-items-center justify-content-between py-1 clickable"
                  onClick={() => this.toggleVisible(team)}
                >
                  <div className="card-body row p-1">
                    <div className="col-auto">
                      {this.state.teamsArray.includes(team.GUID) ? (
                        <i className="fas fa-check-square"></i>
                      ) : (
                        <i className="far fa-square uncheck"></i>
                      )}
                    </div>
                    <div className="col">
                      {teams.find((t) => t.GUID === team.RoleGUID).Name}
                    </div>
                  </div>

                </div>
              ))} */}
              </Scrollbars>
              <button
                onClick={() => newVisiblesBy()}
                className="btn btn-primary btn-block mt-2"
              >
                <FormattedMessage id="done" defaultMessage="Done" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default VisibleByItinerary;
