import { FormattedMessage } from "react-intl";
import Swal from "sweetalert2";
import { IMAGES } from "../constant";
import uuid from "uuid";
import { getBasicInfo } from ".";
import db from "../common/db";
import { ConsoleNetworkOutline } from "mdi-material-ui";

export const handleCloseEmail = (
  toggleMailModal,
  currentEmail,
  attachments
) => {
  createDraftEmail(currentEmail, attachments);
  toggleMailModal();
  // Swal.fire({
  //   text: "Closing this email will result in the lost of your changes, Do you want to save this email as a draft?",
  //   imageUrl: IMAGES.LOGO_BLACK_P,
  //   imageWidth: 80,
  //   imageHeight: 80,
  //   showCancelButton: true,
  //   confirmButtonColor: "#3085d6",
  //   cancelButtonColor: "#d33",
  //   confirmButtonText: "Yes",
  //   cancelButtonText: "No",
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     createDraftEmail();
  //     toggleMailModal();
  //   } else {
  //     toggleMailModal();
  //   }
  // });
};

export async function getDraftsEmails(userGUID) {
  const drafts = await db
    .table("mailDrafts")
    .where({ AccountGUID: userGUID })
    .first();
  return drafts?.drafts || [];
}

export async function getCurrentEmailState(attachments) {
  const userGUID = getBasicInfo().GUID;
  const EmailModal = document.getElementById("emailBodyEditor_rte-edit-view");
  const emailContent = EmailModal?.innerHTML;
  const allInputs = document.querySelectorAll(".hotelEmail__field");

  const emailHeader = {
    to: "",
    subject: "",
    attachments: "",
  };

  //Getting Subject and To from Inputs on Email Modal.
  allInputs.forEach((input) => {
    if (input.outerText.includes("To:")) {
      let emailArray = input?.outerText?.replace("To:", "")?.split("\n");
      emailHeader.to = emailArray.filter(function (item) {
        return (
          item.trim() !== "" && item.trim() !== "Remove" && !item.includes("×")
        );
      });
    } else if (input.outerText.includes("Subject:")) {
      emailHeader.subject = input.children?.[1]?.defaultValue;
    } else if (input.outerText.includes("Attachments:")) {
      emailHeader.attachments = attachments || [];
    }
  });

  const email = {
    GUID: uuid.v4(),
    Title: emailHeader.subject,
    Message: emailContent,
    Receivers: emailHeader?.to?.length > 0 ? emailHeader?.to?.join(";") : null,
    attachments: emailHeader.attachments || [],
    Date: new Date(),
    SenderAccountGUID: userGUID,
    Sent: false,
    Deleted: null,
  };

  const allElements = document?.querySelectorAll(".e-dropdown-popup");
  const allInputEmails = document?.querySelectorAll("input");
  allInputEmails?.forEach((input) => {
    if (input?.id?.includes("emailBodyEditor")) {
      input.remove();
    }
  });

  allElements?.forEach((element) => {
    element.remove();
  });
  return email;
}
export async function createDraftEmail(currentEmail, attachments) {
  console.log({ currentEmail, attachments });
  const userGUID = getBasicInfo().GUID;
  const email = await getCurrentEmailState(attachments);
  const drafts = await getDraftsEmails(userGUID);

  const exist = drafts.filter((email) => email.GUID === currentEmail?.id);

  if (exist.length > 0) {
    await updateDraftEmail(drafts, userGUID, currentEmail?.id, {
      ...email,
      Deleted: exist[0].Deleted,
    });
  } else {
    drafts.push(email);
    await saveDraftEmail(drafts, userGUID);
  }
}

export async function saveDraftEmail(drafts, AccountGUID) {
  await db.table("mailDrafts").put({ drafts, AccountGUID });
}

export async function updateDraftEmail(drafts, AccountGUID, draftGUID, email) {
  const updatedEmails = drafts.map((draft) => {
    if (draft.GUID === draftGUID) {
      return {
        ...email,
        GUID: draftGUID,
      };
    } else {
      return draft;
    }
  });

  await saveDraftEmail(updatedEmails, AccountGUID);
}

export async function deleteDraftEmail(selectedDraft, requestRemote) {
  const { GUID, SenderAccountGUID } = selectedDraft;
  const currentEmail = getCurrentEmailState();
  currentEmail.GUID = GUID;

  const drafts = await getDraftsEmails(SenderAccountGUID);

  const updatedEmails = drafts.map((draft) => {
    if (draft.GUID === GUID) {
      return {
        ...draft,
        Deleted: new Date(),
      };
    }
    return draft;
  });

  await saveDraftEmail(updatedEmails, SenderAccountGUID);
  await requestRemote();
}

export async function deleteFromDraft(selectedDraft, requestRemote) {
  Swal.fire({
    text: "This action will permanently delete this email. Do you want to proceed?",
    imageUrl: IMAGES.LOGO_BLACK_P,
    imageWidth: 80,
    imageHeight: 80,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      permanentlyDeleteEmail(selectedDraft, requestRemote);
    }
  });
}

async function permanentlyDeleteEmail(selectedDraft, requestRemote) {
  const { GUID, SenderAccountGUID } = selectedDraft;
  const currentEmail = getCurrentEmailState();
  currentEmail.GUID = GUID;
  const drafts = await getDraftsEmails(SenderAccountGUID);
  const updatedEmails = drafts.filter((draft) => draft.GUID !== GUID);
  await saveDraftEmail(updatedEmails, SenderAccountGUID);
  await requestRemote();
}

export async function restoreFromDraftEmail(selectedDraft, requestRemote) {
  const { GUID, SenderAccountGUID } = selectedDraft;
  const currentEmail = getCurrentEmailState();
  currentEmail.GUID = GUID;

  const drafts = await getDraftsEmails(SenderAccountGUID);

  const updatedEmails = drafts.map((draft) => {
    if (draft.GUID === GUID) {
      return {
        ...draft,
        Deleted: null,
      };
    }
    return draft;
  });

  await saveDraftEmail(updatedEmails, SenderAccountGUID);
  await requestRemote();
}
