import {
  REQUESTED_PASSWORD,
  REQUEST_PASSWORD,
  REQUEST_PASSWORD_ERROR,
} from "./constants";
import api from "../../../common/api";

export function forgotPassword(email, callback) {
  return async (dispatch) => {
    dispatch({
      type: REQUEST_PASSWORD,
    });

    try {
      const request = await api.get(`user/PasswordRecovery?Email=${email}`);
      if (request.data === true) {
        callback();
      }
      dispatch({
        type: REQUESTED_PASSWORD,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_PASSWORD_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REQUEST_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUESTED_PASSWORD: {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
