import * as constant from "./constants";
import api from "../../../common/api";

export function editReservations(staffs, hotel, success) {
  const url = "/HotelReservations/AddEditMultiple";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_RESERVATIONS,
      });
      const preparedStaffs = staffs.map((s) => ({
        ...s,
        HotelGUID: hotel.GUID,
        Account: null,
      }));
      const request = await api.post(url, preparedStaffs);
      dispatch({
        type: constant.EDITED_RESERVATIONS,
        data: request.data.response,
        hotel: hotel,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_RESERVATIONS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_RESERVATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_RESERVATIONS: {
      return {
        ...state,
        loading: false,
        hotels: state.hotels.map((h) => {
          if (h.GUID === action.hotel.GUID) {
            return { ...h, HotelReservations: action.data };
          }

          return h;
        }),
      };
    }
    case constant.EDITING_RESERVATIONS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
