import { Close } from "mdi-material-ui";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constant";
import * as actions from "../../page/organization/redux/actions";
import { getOrganization } from "../../utils";
import OrganizationButton from "../organization/OrganizationButton";
import history from "./history";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrganizationSidebar = (props) => {
  const { organizations, showOrganizationList, toggleOrganizations, expanded } = props;
  const currentOrganizationGUID = useSelector(
    (state) => state.organizations.currentOrganization.GUID
  );
  const currentOrganization = useSelector(
    (state) => state.organizations.currentOrganization
  );

  const dispatch = useDispatch();
  const changeOrganization = (organization) => {
    if (getOrganization().GUID === organization.GUID) {
      return;
    }
    dispatch(actions.selectOrganization(organization, () => history.push("/")));
  };

  return (
    <div
      className={`${
        expanded ? "active" : ""
      } organizationSidebar__root pt-2`}
    >
      <button
        className="btn-reset d-none"
        type="button"
        onClick={() => toggleOrganizations()}
      >
        <Close />
      </button>
      <Scrollbars
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: "rgba(255,255,255,0.5)",
              width: "2px",
              borderRadius: "1.5px",
              opacity: 0,
            }}
          />
        )}
        style={{
          width: "100%",
          height: "calc(100% - 110px)",
        }}
      >
        <div className="organizationSidebar__inner">
          {currentOrganization && (
            <OrganizationButton
              organization={currentOrganization}
              changeOrganization={changeOrganization}
              current={currentOrganizationGUID}
            />
          )}
          {organizations
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .filter((org) => org.GUID !== currentOrganization.GUID)
            .map((org) => (
              <OrganizationButton
                key={org.GUID}
                organization={org}
                changeOrganization={changeOrganization}
              />
            ))}
        </div>
      </Scrollbars>
      <button
        className="btn btn-primary btn-sm mx-3  mt-4 "
        onClick={() => history.push(ROUTES.CREATE_ORGANIZATION)}
      >
        <FontAwesomeIcon icon={faPlus} color="white" />
      </button>
    </div>
  );
};

export default OrganizationSidebar;
