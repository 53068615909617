import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function editEvent(event, success) {
  const url = `/Events/Edit`;
  return async (dispatch) => {
    dispatch({
      type: constant.UPDATING_COMPS,
    });
    try {
      await apiService.post(url, event);
      dispatch({
        type: constant.UPDATED_COMPS,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.UPDATING_COMPS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.UPDATING_COMPS: {
      return {
        ...state,
        loadingTickets: true,
      };
    }
    case constant.UPDATED_COMPS: {
      return {
        ...state,
        loadingTickets: false,
      };
    }
    case constant.UPDATING_COMPS_ERROR: {
      return {
        ...state,
        loadingTickets: false,
      };
    }
    default:
      return state;
  }
}
