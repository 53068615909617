
import React, { useEffect, useRef, useState } from 'react';
import { getBasicInfo, getOrganization } from "../../utils";
import {useIntl, FormattedMessage} from 'react-intl'
import db from '../../common/db';
import { sortArray } from '../../common/helpers';
import { Event } from "../event/event";
import { Scrollbars } from "react-custom-scrollbars";
import HotelItem from '../hotel/hotelItem';
import * as actions from "../../page/event/redux/actions";
import API from '../../common/api';
//Object to receive
//Item = the object to work with
//Type = the type of object passed, helpful for identify how we can work the items

const GenerateLabels = (props) => {
    const intl = useIntl()
    const [state, setState] = useState({
        UI: {
            Current: props.Item,
            ShareViaEmail: false,
            ShareViaLink: false,
        },
        AccountGUID: ""
    });
    let containerScroller = useRef();


    const [selectedEvent, setSelectedEvent] = useState(props.originEvent);
    const [content, setcontent] = useState("");
    const [StartFrom, setStartFrom] = useState("");
    const [filter, setFilter] = useState("");


    const GeneratEcontent = () => {
        let html = "";
        html = `<!doctype html>
<html lang=""en"">
<head>
    <meta charset=""utf-8"">
    <title>HTML & CSS Avery Labels (5260) by MM at Boulder Information Services</title>
    <style>
    body {
        width: 8.5in;
        margin: 0.7in 0.2in 0in 0.2in;
        }
    .label{
        /* Avery 5260 labels -- CSS and HTML by MM at Boulder Information Services */
        width: 2.035in; /* plus .7 inches from padding */
        height: 0.80in; /* plus .125 inches from padding */
        padding: .125in .35in 0.125in .35in;
        float: left;
        text-align: center;
        overflow: hidden;
        outline: 1px dotted; /* outline doesn't occupy space like border does */
        }
.spacing{
width: 0.125in; 
height:1in;
        float: left;
}
        .page-break  {
            clear: left;
            display:block;
            page-break-after:always;
        }
.PageStartSpacing{
height:0.7in;
}
@media print{
.label{
        /* Avery 5260 labels -- CSS and HTML by MM at Boulder Information Services */
        width: 2.035in; /* plus .7 inches from padding */
        height: 0.80in; /* plus .125 inches from padding */
        padding: .125in .35in 0.125in .35in;
        float: left;
        text-align: center;
        overflow: hidden;
        outline: 0px dotted; /* outline doesn't occupy space like border does */
        }
}
    </style>
</head>
<body>`;


        let columns = 3;
        let count = 1;
        if (props.records.length === 0) {
            return;
        }
        if (StartFrom > 0) {
            for (var i = 1; i < StartFrom; i++) {
                html += "<div class=\"label\"></div>";
                if (count < columns) {
                    html += "<div class=\"spacing\"></div>";
                    count++;
                }
                else {
                    count = 1;
                }
            }
        }
        let copy = props.records;
        let toFilter = filter.toLowerCase().trim();
        if(toFilter.length>0){
            toFilter = toFilter.toLowerCase();
            copy = copy.filter(x=> (x.GuestLastName && x.GuestLastName.toLowerCase().includes(toFilter)) || 
            (x.GuestNames && x.GuestNames.toLowerCase().includes(toFilter))
            )
        }else{
            
        }
        

        for (let i = 1; i <= copy.length; i++) {
            let TextReplace = "";
            if (i <=copy.length) {
                let existItem = copy[i - 1];

                if (existItem != null) {
                    TextReplace = "<b>" + (existItem.GuestLastName ? existItem.GuestLastName + ", " : "") + existItem.GuestNames + "</b></br><small>Request By " + existItem.RequestByName + "</small><br/>" + existItem.Passes;
                }
            }
            html += "<div class=\"label\">" + TextReplace + "</div>";
            if (count < columns) {
                html += "<div class=\"spacing\"></div>";
                count++;
            }
            else {
                count = 1;
            }
            if ((i + StartFrom) % 30 == 0) {
                //Debug.WriteLine("Page Break");
                html += "<div class=\"page-break\"></div><div class=\"PageStartSpacing\"></div>";
            }
        }

        html += `</body>
</html>`;

        setcontent(html);
    }

    const handleSubmit = async () => {
        document.getElementById("frameContainer").contentWindow.print();
        props.toggle();
    }



    useEffect(() => {

        var basicInfo = getBasicInfo();
        setState({
            ...state,
            AccountGUID: basicInfo.GUID
        });

        GeneratEcontent();

    }, []);


    return (<div className='organization-transparent'>

        <div className="event-page" style={{ overflow: "revert" }}>
            <div className="event-expennd-section " style={{ width: "816px" }}>
                <div className="text-head">
                    <div className="text-left text-dark">
                        <h1 className="title"><FormattedMessage
                  id="Print Labels"
                  defaultMessage="Print Labels"/></h1>
                        <small>
                        <FormattedMessage
                  id="Format"
                  defaultMessage="Format"/>:
                            <a href="https://www.avery.com/products/labels/5260" target='_blank' className="ml-2 btn btn-sm btn-primary">
                                Avery 5260
                            </a>
                        </small>
                    </div>

                    <div className='text-right text-dark'>
                        <div className='input-group'>
                            <input type="text" placeholder={intl.formatMessage({id:"column, row"})} className='form-control mr-2'
                                value={StartFrom}
                                onChange={e => {
                                    setStartFrom(e.target.value);
                                    GeneratEcontent();
                                }}
                            ></input>
                            <input type="text" placeholder={intl.formatMessage({id:"Guest Name"})} className='form-control'
                            value={filter}
                            onChange={e=>{
                                setFilter(e.target.value);
                                GeneratEcontent();
                            }}
                            ></input>
                        </div>

                    </div>
                </div>
                <div className="body text-dark p-2">

                    <div className="row">
                        <div className="col-12 px-0" style={{ height: "80vh" }}>
                            <iframe style={{ height: "100%", width: "100%", border: "0px" }} srcDoc={content} id="frameContainer"></iframe>

                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <hr />
                        <button className="btn btn-primary"
                            onClick={() => handleSubmit()}

                        >
                          <FormattedMessage
                  id="Print"
                  defaultMessage="Print"/>  
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default GenerateLabels;

