import React, { Component } from "react";
import AddContact from "./addContact";

class AddContactComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { addContact, event, loading,sideBarItemData,handleSidebarItem } = this.props;
    return (
      <AddContact
        event={event}
        addContact={addContact}
        loading={loading}
        sideBarItemData={sideBarItemData}
        handleSidebarItem={handleSidebarItem}
        toggle={this.props.toggle}
      />
    );
  }
}

export default AddContactComponent;
