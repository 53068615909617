const initialState = {
  event: {},
  eventVenue: null,
  weather: null,
  schedulesMasters: [],
  attachments: [],
  contacts: [],
  hotels: [],
  grounds: [],
  flights: [],
  venues: [],
  visibles: [],
  notes: [],
  templates: [],
  loadingEvent: false,
  loading: false,
  loadingFlights: false,
  addingAttachment: false,
  flightStatus: "normal",
  searchedFlights: [],
  linkableEvents: [],
};

export default initialState;
