import React from "react";

import Setting from "../../components/settings/Setting";
import SubscriptionContainer from "../../components/settings/subscription/SubscriptionContainer";
import ProfileContainer from "../../components/settings/profile/ProfileContainer";
import PaymentsContainer from "../../components/settings/paymentsOptions/PaymentsContainer";
import ChangePasswordContainer from "../../components/settings/changePassword/ChangePasswordContainer";
import AdminOrganizationsContainer from "../../components/settings/administrationOrganizations/AdminOrganizationsContainer";

const Profile = () => {
  return (
    <div className="dashboard-screen">
      <div className="calendar-page">
        <div className="calendar-head">
          <div className="calendar-text">
            <h1>Settings Page</h1>
          </div>
        </div>
        <div className="row settings-page">
          <div className="col-md-3 mt-3">
            <Setting
              title="Admin your Organizations"
              description="A mere description for this component"
              children={<AdminOrganizationsContainer />}
            />
          </div>
          <div className="col-md-3 mt-2">
            <Setting
              title="Change password"
              description="A mere description for this component"
              children={<ChangePasswordContainer />}
            />
          </div>
          <div className="col-md-3 mt-3">
            <Setting
              title="Payment options"
              description="A mere description for this component"
              children={<PaymentsContainer />}
            />
          </div>
          <div className="col-md-3 mt-3">
            <Setting
              title="Profile"
              description="A mere description for this component"
              children={<ProfileContainer />}
            />
          </div>
          <div className="col-md-3 mt-3">
            <Setting
              title="Subscriptions"
              description="A mere description for this component"
              children={<SubscriptionContainer />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
