import * as constants from "./constants";

export function enableChatWindow(chatEnabled, conversation) {
  if (chatEnabled) {
    return (dispatch) => {
      dispatch({
        type: constants.CHANGE_CONVERSATION,
        data: conversation,
      });
    };
  }
  return (dispatch) => {
    dispatch({
      type: constants.ENABLE_CHAT,
      data: conversation,
    });
  };
}

export function setConnection(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CONNECTION,
      data,
    });
  };
}

export function setChatMessages(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CHAT_MESSAGES,
      data,
    });
  };
}

export function setConnectedUsers(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CONNECTED_USERS,
      data,
    });
  };
}

export function setRecentConversations(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_RECENT_CONVERSATIONS,
      data,
    });
  };
}

export function closeChatWindows() {
  return (dispatch) => {
    dispatch({
      type: constants.CLOSE_CHAT,
    });
  };
}

export function setChats(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CHATS,
      data,
    });
  };
}

export function setConversationDetails(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CONVERSATION_DETAILS,
      data,
    });
  };
}

export function refreshChats(data) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_CHAT_REFRESHED,
      data,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.SET_CONNECTION: {
      return {
        ...state,
        connection: action.data,
      };
    }
    case constants.SET_CHAT_MESSAGES: {
      return {
        ...state,
        chatMessages: action.data,
      };
    }
    case constants.SET_CONNECTED_USERS: {
      return {
        ...state,
        connectedUsers: action.data,
      };
    }
    case constants.SET_RECENT_CONVERSATIONS: {
      return {
        ...state,
        recentConversations: action.data,
      };
    }
    case constants.CLOSE_CHAT: {
      return {
        ...state,
        enableChat: false,
        conversationDetails: null,
        conversation: null,
      };
    }
    case constants.ENABLE_CHAT: {
      return {
        ...state,
        enableChat: true,
        conversation: action.data,
      };
    }
    case constants.CHANGE_CONVERSATION: {
      return {
        ...state,
        conversation: action.data,
      };
    }
    case constants.SET_CHATS: {
      return {
        ...state,
        chats: action.data,
      };
    }
    case constants.SET_CONVERSATION_DETAILS: {
      return {
        ...state,
        conversationDetails: action.data,
      };
    }
    case constants.SET_CHAT_REFRESHED: {
      return {
        ...state,
        recentConversations: state.recentConversations.map((c) => {
          if (c.conversationGUID === action.data.guid) {
            c = { ...action.data, conversationGUID: action.data.guid };
            return c;
          }

          return c;
        }),
      };
    }
    default:
      return state;
  }
}
