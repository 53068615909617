import * as constant from "./constants";
import api from "../../../common/api";
import uuid from "uuid";
import { getOrganization } from "../../../utils";
import db from "../../../common/db";

export function addNote(event, success) {
  const url = `/Note/AddEditBatch`;
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_NOTE,
    });
    try {
      const note = {
        GUID: uuid.v4(),
        Name: "",
        Detail: "",
        OrganizationGUID: getOrganization().GUID,
        EventGUID: event,
        Deleted: null,
        Updated: null,
      };
      await db.table("notes").put(note);
      dispatch({
        type: constant.CREATED_NOTE,
        data: note,
      });
      success();
      await api.post(url, [note]);
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.CREATING_NOTE_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_NOTE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_NOTE: {
      return {
        ...state,
        loading: false,
        notes: [...state.notes, action.data],
      };
    }
    case constant.CREATING_NOTE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
