import * as constant from "./constants";
import db from "../../../common/db";

export function setActiveNotes() {
  return async (dispatch) => {
    let notes = await db.table("activeNotes").toArray();
    notes = notes.filter(x=>x.Deleted === null);
    console.log("active notes", notes)
    dispatch({
      type: constant.SET_ACTIVE_NOTES,
      data: notes,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.SET_ACTIVE_NOTES: {
      const notes = action.data;
      return {
        ...state,
        notes,
      };
    }
    default:
      return state;
  }
}
