import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function clearSchedules(itinerary, success) {
  const url = `/schedule/removeActivities?ScheduleGUID`;
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_ALL_ACTIVITIES,
    });
    try {
      const schedule = itinerary.GUID;
      await api.get(`${url}=${schedule}`);
      dispatch({
        type: constant.DELETED_ALL_ACTIVITIES,
        data: schedule,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.DELETING_ALL_ACTIVITIES_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_ALL_ACTIVITIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_ALL_ACTIVITIES: {
      return {
        ...state,
        loading: false,
        schedulesMasters: state.schedulesMasters.map((s) => {
          if (s.GUID === action.data) {
            s = { ...s, Schedules: [] };
            return s;
          }
          return s;
        }),
      };
    }
    case constant.DELETING_ALL_ACTIVITIES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
