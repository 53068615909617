import * as constant from "./constants";
import api from "../../../common/api";

export function editVisiblesItineraries(visibles) {
  return async (dispatch) => {
    try {
      const request = await api.post(
        "/Schedules/AddEditVisibleBy",
        visibles
      );
      console.log(request.data);
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
