import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function getAttachmentsEvent(event) {
  return async (dispatch) => {
    dispatch({
      type: constant.GET_ATTACHMENTS,
    });
    try {
      const localAttachments = await db
        .table("attachments")
        .where({ EventGUID: event })
        .toArray();
      dispatch({
        type: constant.GET_ATTACHMENTS_SUCCESS,
        data: localAttachments,
      });
      const request = await apiService.get("/Attachment/Attachments", {
        params: { EventGUID: event },
      });
      const remoteAttachments = request.data.filter((a) => a.Deleted === null);
      await db.table("attachments").bulkPut(remoteAttachments);
      dispatch({
        type: constant.GET_ATTACHMENTS_SUCCESS,
        data: remoteAttachments,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        attachments: action.data,
      };
    }
    default:
      return state;
  }
}
