import React, { useEffect } from "react";
import GeneralUserItem from "../common/GeneralUserItem";
import uuid from "uuid";
import { useState } from "@hookstate/core";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";

const AssignMembersFlightUser = (props) => {
  const parsedStaff = props.parsedStaff;
  const intl = useIntl();
  // console.log("account item ", parsedStaff, "exist", props.Reservation, "flight ", props)

  const state = useState(
    props.Reservation
      ? props.Reservation
      : {
          GUID: uuid.v4(),
          StaffGUID: parsedStaff.GUID,
          ReservationNumber: "",
          SeatNumber: "",
          FlightGUID: parsedStaff.FlightGUID, //props.flight.GUID,
          Updated: null,
          Deleted: null,
          TicketNumber: null,
        }
  );

  useEffect(() => {
    if (props.Reservation) {
      setTimeout(() => {
        state.set(props.Reservation);
      }, 500);
    }
    console.log("the state ", state.value);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 my-1 px-0">
          <div
            className={
              (props.exist(parsedStaff) ? "selected" : "") +
              " visible-info-main card shadow-sm "
            }
          >
            <div className="card-body p-1">
              <div className="row">
                <div
                  className="col-auto px-1 py-1 pt-2"
                  onClick={() => props.toggleMember(parsedStaff)}
                >
                  {props.exist(parsedStaff) ? (
                    <i className="fas fa-check-square"></i>
                  ) : (
                    <i className="far fa-square uncheck"></i>
                  )}
                </div>
                <div
                  className="col px-0"
                  //title={parsedStaff.GUID + " account " + parsedStaff.AccountGUID}
                >
                  <GeneralUserItem
                    account={parsedStaff.Account}
                    showShortName
                    fromSection="ASSIGNMEMBERSFLIGHT"
                  />
                </div>
                <div
                  className={
                    "col-auto ml-auto mt-1 " +
                    (props.flight !== undefined && !props.flight.PrivateFlight
                      ? ""
                      : " d-none")
                  }
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "Record Locator" })}
                    disabled={!props.exist(parsedStaff)}
                    value={
                      state.TicketNumber.value === null
                        ? ""
                        : state.TicketNumber.value
                    }
                    onChange={(e) => {
                      state.TicketNumber.set(e.target.value.toUpperCase());

                      props.updateSelected(
                        JSON.parse(JSON.stringify(state.value))
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignMembersFlightUser;
