import React from "react";
import Popup from "reactjs-popup";
import { randomImage } from "./randomImage";
import { IMAGES } from "../../constant";
import GeneralUserItem from "./GeneralUserItem";

const onErrorImage = (sender) => {
  console.log("error on image", sender);
  sender.target.src = IMAGES.PROFILE_ICON;
};

const AvatarsRow = ({ staffs, grounds, hotel, position, flight }) => {
  // console.log("staffs to show", staffs,"Is an array",  Array.isArray(staffs))
  staffs = staffs.filter((x) => x.Deleted === null);
  const remainings = staffs.map((s) => {
    let account;

    if (s.Account === undefined) {
      account = s;
    } else {
      account = s?.Account;
    }
    if (flight) {
      account = s?.Staff;
    }
    if (hotel) {
    }
    let fullName = account?.Name + " " + account?.LastName;

    let initials =
      (account?.Name && account?.Name.charAt(0).toUpperCase()) +
      (account?.LastName && account?.LastName.charAt(0));
    let img = randomImage(initials);
    return { ...account, img, fullName };
  });

  let summaryToShow =
    remainings.length > 2 ? remainings.slice(0, 2) : remainings;

  return (
    <ul className="avatars">
      {/* {remainings.splice(0, 2) && remainings.splice(0, 2).map((user,index) => { */}
      {summaryToShow &&
        summaryToShow.map((user, index) => {
          let img = user.Picture
            ? user.Picture.includes("http")
              ? user.Picture
              : `https://app.plnify.com/photos/${user.Picture}?w=100&h=100&mode=crop&autorotate=true`
            : "";
          return (
            <li className="avatars__item" key={index}>
              {grounds ? (
                user.Picture ? (
                  <img
                    onError={onErrorImage}
                    src={img}
                    className="img-fluid avatar"
                    alt={user.fullName}
                    title={user.fullName}
                  />
                ) : (
                  <img
                    onError={onErrorImage}
                    src={user.img}
                    className="img-fluid avatar"
                    alt={user.fullName}
                    title={user.fullName}
                  />
                )
              ) : user && user.Picture === null ? (
                <img
                  onError={onErrorImage}
                  src={user.img}
                  className="img-fluid avatar"
                  alt={user.fullName}
                  title={user.fullName}
                />
              ) : (
                <img
                  onError={onErrorImage}
                  src={user.img || img}
                  className="img-fluid avatar"
                  alt={user.fullName}
                  title={user.fullName}
                />
              )}
            </li>
          );
        })}
      {remainings.length > 0 &&
        remainings.length - summaryToShow.length > 0 && (
          <Popup
            position={`${position || "left"} top`}
            on="click"
            modal={true}
            trigger={
              <li className="avatars__item">
                <img
                  src={`https://placehold.co/100x100/EEE/31343C?font=open-sans&text=%2B${
                    remainings.length - summaryToShow.length
                  }`}
                  className="img-fluid avatar"
                  alt="-"
                  title="Click to see remaining members"
                />
              </li>
            }
          >
            <div>
              {remainings.map((r, indexRemaining) => (
                <GeneralUserItem account={r} key={indexRemaining} />
              ))}
            </div>
          </Popup>
        )}
    </ul>
  );
};

export default AvatarsRow;
