import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { IMAGES } from "../../constant";
import SelectableHotel from "./SelectableHotel";
import { SimpleLoader } from "../common";
import {useIntl, FormattedMessage} from 'react-intl'

const ExportReservations = (props) => {
  let {
    hotelSelected,
    hotel,
    toggleHotel,
    toggle,
    arrayOfHotels,
    loading,
    exportReservations,
  } = props;
  return (
    <div className="organization-transparent">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1>Copy users to a new Hotel</h1>
              <p>{hotel.Name}</p>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="pt-2" style={{ height: "calc(100vh - 120px)" }}>
            <Scrollbars>
              {arrayOfHotels
                .filter((h) => h.Deleted === null)
                .filter((h) => h.GUID !== hotel.GUID)
                .map((hotel) => (
                  <SelectableHotel
                    key={hotel.GUID}
                    hotel={hotel}
                    toggleHotel={toggleHotel}
                    hotelSelected={hotelSelected}
                    hotels={arrayOfHotels}
                  />
                ))}
            </Scrollbars>
          </div>
          <div className="d-flex align-items-center justify-content-end py-3 px-4">
            {loading ? (
              <SimpleLoader loading={loading} />
            ) : (
              <button
                disabled={arrayOfHotels.length === 0}
                type="button"
                onClick={() => exportReservations()}
                className="btn primary "
              >
  <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportReservations;
