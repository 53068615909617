import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import uuid from "uuid";
import db from "../../../common/db";

export function exportItinerary(
  itinerary,
  type,
  arrayOfEvents,
  success,
  refresh
) {
  const url = "/ScheduleMaster/CopyToAnotherEvent";
  return async (dispatch) => {
    dispatch({
      type: constant.EXPORTING_SCHEDULES_MASTER,
    });
    try {
      arrayOfEvents.forEach(async (event) => {
        await apiService.post(
          `${url}?ScheduleMasterGUID=${itinerary.GUID}&TargetEventGUID=${event.GUID}`
        );
      });
      dispatch({
        type: constant.EXPORTING_SCHEDULES_MASTER,
      });
      success();
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_RESOURCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EXPORTED_RESOURCE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EXPORTING_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
