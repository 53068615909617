import React from "react";

const Dashboard = (props) => {
  let { logoutUser } = props;
  return (
    <div className="main-container">
      <div className="dashboard-screen">
        <div className="dashboard-content">
          <p>Dashboard Screen Under Construction</p>
          <br></br>
          <p style={{ cursor: "pointer" }} onClick={() => logoutUser()}>
            logout
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
