import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function removeAllFlightStaff(staffs, success) {
  const url = "/Flights/AddEditStaffBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_ALL_PASSENGERS,
      });
      const finalStaffs = staffs.map((s) => ({
        ...s,
        Deleted: s.Deleted ? s.Deleted : new Date(),
        ReservationNumber: "",
        SeatNumber: "",
        TicketNumber: "",
      }));
      await apiService.post(url, finalStaffs);
      dispatch({
        type: constant.DELETED_ALL_PASSENGERS,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.DELETING_ALL_PASSENGERS_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_ALL_PASSENGERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_ALL_PASSENGERS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.DELETING_ALL_PASSENGERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
