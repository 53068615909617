import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../page/meet/redux/actions";
import InvitationCard from "./cards/InvitationCard";
import CallCard from "./cards/CallCard";

class MeetWindow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { status } = this.props;
    if (status === "ACCEPTED") {
      return (
        <CallCard
          closeCall={this.props.actions.closeMeet}
          cancelCall={this.props.actions.declineMeet}
        />
      );
    }
    if (status === "ONGOING") {
      return (
        <InvitationCard
          acceptCall={this.props.actions.acceptMeet}
          rejectCall={this.props.actions.declineMeet}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  status: state.meet.status,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MeetWindow);
