import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function editVenue(venue, success) {
  const url = "/Venues/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_VENUE,
    });
    try {
      const request = await apiService.post(url, {
        ...venue,
        ProductionLaborDetails: null,
        Events: null,
      });
      dispatch({
        type: constant.EDITED_VENUE_SUCCESS,
        data: request.data,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_VENUE_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_VENUE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        eventVenue: action.data,
        event: { ...state.event, Venue: action.data },
      };
    }
    case constant.EDITING_VENUE_ERROR: {
      return {
        loading: false,
        ...state,
      };
    }
    default:
      return state;
  }
}
