import React, { Component } from "react";
import WalkThrough from "./walkthrough";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGES, ROUTES } from "../../constant";
import {
  ModalComponent,
  ToastNotificationSuccess,
} from "../../components/common";
import { getBasicInfo, logoutUser } from "../../utils";
import CreateOrganizationContainer from "../organization/createOrganization/CreateOrganizationContainer";
import OnboardingWorkspace from "../../components/onboarding/OnboardingWorkspace";
import OnboardingImport from "../../components/onboarding/OnboardingImport";
import ImportModal from "../../components/onboarding/ImportModal";
import db from "../../common/db";
import Swal from "sweetalert2";
import uuid from "uuid";
import moment from "moment";
import ical from "cal-parser";

import * as orgActions from "../organization/redux/actions";
import * as eventActions from "../event/redux/actions";
import { AccordionSummary } from "@material-ui/core";

class WalkThroughComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animating: false,
      activeIndex: 0,
      createOrganizationModal: false,
      importEventsModal: false,
      selectOrganization: false,
      selectedWorkspace: null,
      addMultiple: false,
      multipleEvents: [],
      defaultOrg: null,
      importingModal: false,
    };
  }

  componentDidUpdate() {
    if (this.state.activeIndex === 2 && this.state.defaultOrg === null) {
      this.addDefaultOrganization();
    }
  }

  addDefaultOrganization = () => {
    const currentUser = getBasicInfo().GUID;
    const defaultOrg = {
      name: "Default Organization",
      GUID: uuid.v4(),
      CreatorGUID: currentUser,
      active: true,
    };
    this.setState({
      defaultOrg,
    });
  };

  next = () => {
    const { animating, activeIndex } = this.state;
    if (animating) return;
    const nextIndex =
      activeIndex === this.items.length - 1 ? 0 : activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  previous = () => {
    debugger;
    const { animating, activeIndex } = this.state;

    if (animating) return;
    const nextIndex =
      activeIndex === 0 ?0 : activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  goToIndex = (newIndex) => {
    const { animating } = this.state;

    if (animating) return;
    this.setState({
      activeIndex: newIndex,
    });
  };

  setAnimating = (animating) => {
    this.setState({
      animating,
    });
  };

  onClickNavigation = (routes) => {
    this.props.history.push({ pathname: routes });
  };

  toggleCreateOrganization = () => {
    this.setState({
      createOrganizationModal: !this.state.createOrganizationModal,
    });
  };

  toggleImportEvents = () => {
    this.setState({
      importEventsModal: !this.state.importEventsModal,
    });
  };

  toggleSelectOrganization = () => {
    this.setState({
      selectOrganization: !this.state.selectOrganization,
    });
  };

  selectWorkspace = (workspace) => {
    this.setState({
      selectedWorkspace: workspace.GUID,
    });
  };

  addEventMultiple = async (event) => {
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut([...this.state.multipleEvents, event])
      .then(() =>
        this.setState({
          multipleEvents: [...this.state.multipleEvents, event],
        })
      );
  };

  clearMultipleState = () => {
    this.setState({
      multipleEvents: [],
    });
  };

  updateMultipleEvent = (event) => {
    let updatedEvents = this.state.multipleEvents;
    const newEvents = updatedEvents.map((e) =>
      e.GUID === event.GUID ? event : e
    );
    this.setState({ multipleEvents: newEvents });
  };

  addBunchEvents = async (events) => {
    const actualEvents = this.state.multipleEvents;
    const updatedEvents = [...actualEvents, ...events];
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(updatedEvents)
      .then(() =>
        this.setState({
          multipleEvents: updatedEvents,
        })
      );
  };

  clearEllEvents = async () => {
    await db
      .table("batchEvent")
      .clear()
      .then(() =>
        this.setState({
          multipleEvents: [],
        })
      );
  };

  removeEvent = async (event) => {
    let events = this.state.multipleEvents;
    let filtered = events.filter((s) => s.GUID !== event.GUID);
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(filtered)
      .then(() =>
        this.setState({
          multipleEvents: filtered,
        })
      );
  };

  updateEvent = async (eventUpdated) => {
    let events = this.state.multipleEvents.map((event) =>
      event.GUID === eventUpdated.GUID ? (event = eventUpdated) : event
    );
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(events)
      .then(() =>
        this.setState({
          multipleEvents: events,
        })
      );
  };

  addMultipleEvents = (props) => {
    const { multipleEvents } = this.state;
    const { addMutipleEvents } = this.props.eventActions;
    let finalEvents = multipleEvents
      .filter((event) => event.Name !== "")
      .map((event) => {
        const utcDate = `${moment(event.Date)
          .utc()
          .format("YYYY-MM-DD")}T23:00:00`;
        return {
          ...event,
          Date: utcDate,
          VisibleBy: [],
        };
      });
    const isNotValidYet = multipleEvents.some((item) => item.Error === true);
    if (isNotValidYet) {
      Swal.fire({
        title: "Verify",
        text: "There is some events must be completed before submit",
        icon: "info",
      });
    } else {
      let events = finalEvents.map((event) => {
        delete event.Venue;
        delete event.EventTypeName;
        delete event.Error;
        return {
          ...event,
        };
      });
      addMutipleEvents(events, () => {
        ToastNotificationSuccess(1, "Events added successfully.");
        this.clearEllEvents();
      });
    }
  };

  toggleMiniImport = () => {
    this.setState({
      importingModal: !this.state.importingModal,
    });
  };

  parseAndPrepareIcsFile = async (file) => {
    const result = await file.text();
    const parsedResult = ical.parseString(result);
    const events = parsedResult.events.map((event) => ({
      Name: event.summary.value,
      Date: event.dtstart.value,
      GUID: event.uid.value,
    }));
    this.addBunchEvents(events);
    this.toggleMiniImport();
    this.toggleImportEvents();
  };

  skipIcs = () => {
    this.toggleMiniImport();
    this.toggleImportEvents();
  };

  items = [
    {
      title: `Welcome ${getBasicInfo().Name}!`,
      text: "Your turnkey solution for events",
      cta: "Lets get started",
      logo: IMAGES.LOGO_ONBOARDING,
      trigger: () => this.goToIndex(1),
    },
    {
      title: "Easily manage multiple workspaces",
      text: "Start by creating your first Plnify workspace",
      cta: "Create",
      logo: IMAGES.WORKSPACES_ONBOARDING,
      trigger: this.toggleCreateOrganization,
    },
    {
      title: "Plan your events with the Plnify calendar",
      text:
        "Add your events so you can easily manage and share them from anywhere",
      cta: "Import your events",
      logo: IMAGES.IMPORT_ONBOARDING,
      trigger: this.toggleMiniImport,
    },
    {
      title: "One window endless capabilities",
      text: "You're ready to get your team Plnify'd!",
      cta: "Let's go",
      logo: IMAGES.WINDOW_ONBOARDING,
      trigger: () => this.onClickNavigation(ROUTES.SELECT_ORGANIZATION),
    },
  ];

  logout = () => {
    logoutUser();
    this.props.history.push("/");
  };

  render() {
    return (
      <div>
        <ModalComponent
          modal={this.state.createOrganizationModal}
          toggle={() => this.toggleCreateOrganization()}
          childeren={
            <CreateOrganizationContainer
              history={this.props.history}
              toggleBack={() => this.toggleCreateOrganization()}
              toggleSelect={() => this.toggleSelectOrganization()}
              goToIndex={() => this.goToIndex(2)}
              isBackButton
            />
          }
        />
        <ModalComponent
          modal={this.state.selectOrganization}
          toggle={() => this.toggleSelectOrganization()}
          childeren={
            <OnboardingWorkspace
              selectWorkspace={this.selectWorkspace}
              toggleBack={() => this.toggleSelectOrganization()}
              history={this.props.history}
            />
          }
        />
        <ModalComponent
          modal={this.state.importEventsModal}
          toggle={() => this.toggleImportEvents()}
          childeren={
            <OnboardingImport
              toggle={() => this.toggleImportEvents()}
              multipleEvents={this.state.multipleEvents}
              updateMultipleEvent={this.updateMultipleEvent}
              clearMultipleState={this.clearMultipleState}
              updateEvent={this.updateEvent}
              addMultipleEvents={this.addMultipleEvents}
              addBunchEvents={this.addBunchEvents}
              removeEvent={this.removeEvent}
              addEventMultiple={this.addEventMultiple}
              clearAll={this.clearEllEvents}
              defaultOrg={this.state.defaultOrg}
            />
          }
        />
        <ModalComponent
          modal={this.state.importingModal}
          toggle={() => this.toggleMiniImport()}
          childeren={
            <ImportModal
              toggle={() => this.toggleMiniImport()}
              parseAndPrepareIcsFile={this.parseAndPrepareIcsFile}
              skipIcs={this.skipIcs}
            />
          }
        />
        <WalkThrough
          {...this.state}
          next={this.next}
          previous={this.previous}
          goToIndex={this.goToIndex}
          onClickNavigation={this.onClickNavigation}
          setAnimating={this.setAnimating}
          items={this.items}
          toggleImportEvents={this.toggleImportEvents}
          toggleCreateOrganization={this.toggleCreateOrganization}
          logOut={this.logout}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organizations,
  loading: state.organizations.loading,
  teams: state.organizations.teams,
});

function mapDispatchToProps(dispatch) {
  return {
    orgActions: bindActionCreators({ ...orgActions }, dispatch),
    eventActions: bindActionCreators({ ...eventActions }, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalkThroughComponent);
