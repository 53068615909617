import { Form, Formik } from "formik";
import { DeleteOutline } from "mdi-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../../constant";
import * as actions from "../../../page/event/redux/actions";
import { ModalComponent, ToastNotificationSuccess } from "../../common";

import Slider from "react-slick";
import uuid from "uuid";
import { sortArray } from "../../../common/helpers";
import { SimpleLoader } from "../../common";
import AssignMembersGround from "../../grounds/AssignMembersGround";
import Vehicle from "../vehicle";
import VehicleModal from "../vehicleModal";

import GeneralUserItem from "../../common/GeneralUserItem";
// import { Editor } from "@tinymce/tinymce-react";
import { FormattedMessage, useIntl } from "react-intl";
import db from "../../../common/db";
import { groupBy } from "../../../utils";
import AddEditGround from "../../grounds/AddEditGround";

import "react-quill/dist/quill.snow.css";
import RichTextComponent from "../../common/RichTextEditorComponent";

export function GroundDetail(props) {
  const [state, setState] = useState({
    passengers: false,
    notes: false,
    vehicleModal: false,
    addVehicle: false,
    editMode: false,
    addGround: false,
    vehicleSelected: null,
    staffSelected: [],
    PickupPlace: null,
    DropoffPlace: null,
  });

  const teams = useSelector((state) => state.organizations.teams);
  const grounds = useSelector((state) => state.event.grounds);

  const currentGround = grounds?.filter(
    (ground) => ground.GUID === props.sideBarItemData.GUID
  )[0];

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    getGroundPlace("pickup");
    getGroundPlace("dropoff");
  }, [currentGround]);

  function toggleAddVehicle() {
    setState((prev) => {
      return { ...prev, addVehicle: !prev.addVehicle };
    });
  }

  function toggleVehicleModal(vehicle) {
    setState((prev) => {
      return {
        ...prev,
        vehicleModal: !prev.vehicleModal,
        vehicleSelected: vehicle,
      };
    });
  }

  function togglePassengers() {
    setState((prev) => {
      return {
        ...prev,
        passengers: !prev.passengers,
      };
    });
  }

  function toggleMember(staff) {
    //Search all current selected
    let staffs = state.staffSelected.map((staff) => ({
      ...staff,
      Account: null,
      Staff: null,
    }));

    let exist = staffs.filter(
      (x) => x.StaffGUID === staff.GUID && x.Deleted === null
    );
    if (exist.length > 0) {
      //If exist must be removed

      staffs = staffs.filter((x) => x.StaffGUID !== staff.GUID);
    } else {
      //Add
      staffs.push({
        StaffGUID: staff.GUID,
        GUID: uuid.v4(),
        Deleted: null,
      });
    }
    let copy = { ...state };
    copy.staffSelected = staffs;
    setState((prev) => {
      return { ...prev, copy };
    });
  }

  function editPassengers() {
    const { staffSelected } = state;
    let passengers = staffSelected.map((staff) => ({
      ...staff,
      GroundGUID: props.sideBarItemData && currentGround?.GUID,
    }));
    dispatch(
      actions.editPassengers(passengers, () => {
        dispatch(actions.getGrounds(props.event.GUID));
        ToastNotificationSuccess(1, "Updated members");
        togglePassengers();
        props.handleSidebarItem(
          "GROUND",
          grounds.find((g) => g.GUID === currentGround?.GUID)
        );
      })
    );
  }

  function addPassengers(staffs) {
    setState((prev) => {
      return {
        ...prev,
        staffSelected: staffs,
      };
    });
  }

  function deletePassenger(passenger) {
    let p = { ...passenger, Deleted: new Date() };
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this passenger?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deletePassenger(p, () => {
            ToastNotificationSuccess(1, "Member deleted successfully");
            props.handleSidebarItem(
              "GROUND",
              grounds.find((g) => g.GUID === currentGround?.GUID)
            );
            dispatch(actions.getGrounds(props.event.GUID));
          })
        );
      }
    });
  }

  function cleanPassengers(passengers) {
    Swal.fire({
      text: intl.formatMessage({ id: "Are you sure you want to remove all?" }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.cleanPassengers(passengers, () => {
            ToastNotificationSuccess(1, "Members deleted successfully");
            props.handleSidebarItem(
              "GROUND",
              grounds.find((g) => g.GUID === currentGround?.GUID)
            );
          })
        );
      }
    });
  }

  function toggleNotes() {
    setState((prev) => {
      return {
        ...prev,
        notes: !prev.notes,
      };
    });
  }

  function addVehicle(vehicle) {
    dispatch(
      actions.addVehicle(vehicle, () => {
        ToastNotificationSuccess(1, "Vehicle created successfully");
        toggleAddVehicle();
        props.handleSidebarItem(
          "GROUND",
          grounds.find((g) => g.GUID === currentGround?.GUID)
        );
      })
    );
  }

  function editVehicle(vehicle) {
    dispatch(
      actions.editVehicle(vehicle, () => {
        ToastNotificationSuccess(1, "Vehicle edited successfully");
        toggleVehicleModal(null);
        props.handleSidebarItem(
          "GROUND",
          grounds.find((g) => g.GUID === currentGround?.GUID)
        );
      })
    );
  }

  function deleteVehicle(vehicle) {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this vehicle?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteVehicle(vehicle, () => {
            ToastNotificationSuccess(1, "Vehicle deleted successfully");
            props.handleSidebarItem(
              "GROUND",
              grounds.find((g) => g.GUID === currentGround?.GUID)
            );
          })
        );
      }
    });
  }

  function toggleGroundModal() {
    setState((prev) => {
      return {
        ...prev,
        addGround: !prev.addGround,
      };
    });
  }

  function editModal(ground) {
    props.handleSidebarItem("GROUND", ground);
    setState((prev) => {
      return {
        ...prev,
        editMode: true,
      };
    });

    toggleGroundModal();
  }

  function closeEdit() {
    setState((prev) => {
      return {
        ...prev,
        editMode: false,
      };
    });

    toggleGroundModal();
  }

  function editGround(ground, fromNotes) {
    const { event } = props;
    dispatch(
      actions.editGround(ground, () => {
        ToastNotificationSuccess(1, "Vehicle deleted successfully");
        dispatch(actions.getGrounds(event.GUID));
        fromNotes && toggleGroundModal(true);
        props.handleSidebarItem(
          "GROUND",
          grounds.find((g) => g.GUID === currentGround?.GUID)
        );
      })
    );
  }

  async function getGroundPlace(mode) {
    let copy = {};
    if (mode === "pickup") {
      if (currentGround?.Pickup === "Other") {
        copy.PickupPlace = currentGround?.PickupGUID;
      } else if (currentGround?.Pickup === "Venue") {
        copy.PickupPlace = props.event.Venue.Name;
      } else if (currentGround?.Pickup === "Hotel") {
        //Search for the hotels
        let records = await db
          .table("hotels")
          .where({ EventGUID: props.event.GUID })
          .toArray();
        records = records.sort((a, b) => {
          if (a.Name > b.Name) {
            return 1;
          } else {
            return -1;
          }
        });
        let selected = records.filter(
          (x) => x.GUID === currentGround?.PickupGUID
        )[0];
        if (selected !== undefined && selected !== null) {
          copy.PickupPlace = selected.Name;
        }
      } else if (currentGround?.Pickup === "Airport") {
        let records = await db
          .table("flights")
          .where({ EventGUID: props.event.GUID })
          .toArray();
        let airports = [];
        records.forEach((flight, index) => {
          console.log("flight", flight, "index", index);
          //Departure
          let placeDeparture = flight.FromLongName ? flight.FromLongName : "";
          if (placeDeparture.length > 0) {
            placeDeparture += " (" + flight.From + ")";
          } else {
            placeDeparture += flight.From;
          }
          airports.push({
            ...flight,
            GUID: flight.GUID + ";" + flight.From,
            Name: placeDeparture,
          });
          //Arrival
          let place = flight.ToLongName ? flight.ToLongName : "";
          if (place.length > 0) {
            place += " (" + flight.To + ")";
          } else {
            place += flight.To;
          }
          airports.push({
            ...flight,
            GUID: flight.GUID + ";" + flight.To,
            Name: place,
          });
        });
        //Sort

        airports = airports.sort((a, b) => {
          if (a.Name > b.Name) {
            return 1;
          } else {
            return -1;
          }
        });
        if (props.sideBarItemData !== undefined) {
          let selected = airports.filter(
            (x) => x.GUID === currentGround?.PickupGUID
          )[0];
          if (selected !== undefined && selected !== null) {
            copy.PickupPlace = selected.Name;
          }
        }
      }
    } else {
      if (currentGround?.Dropoff === "Other") {
        copy.DropoffPlace = currentGround?.DropoffGUID;
      } else if (currentGround?.Dropoff === "Venue") {
        copy.DropoffPlace = props.event.Venue.Name;
      } else if (currentGround?.Dropoff === "Hotel") {
        //Search for the hotels
        let records = await db
          .table("hotels")
          .where({ EventGUID: props.event.GUID })
          .toArray();
        records = records.sort((a, b) => {
          if (a.Name > b.Name) {
            return 1;
          } else {
            return -1;
          }
        });
        let selected = records.filter(
          (x) => x.GUID === currentGround?.DropoffGUID
        )[0];
        if (selected !== undefined && selected !== null) {
          copy.DropoffPlace = selected.Name;
        }
      } else if (currentGround?.Dropoff === "Airport") {
        //We have to search for the airports (arrival)
        //Select all the flights in the event, take only the arrival
        let records = await db
          .table("flights")
          .where({ EventGUID: props.event.GUID })
          .toArray();
        let grouped = groupBy(records, "Connection");
        console.log("grouped flights", grouped);
        let airports = [];
        Object.values(grouped).forEach((item) => {
          console.log("connection ", item);
          var lastItem = item[item.length - 1];
          airports.push({
            ...lastItem,
            GUID: lastItem.GUID,
            Name:
              lastItem.To +
              ", " +
              (lastItem.ToLongName ? lastItem.ToLongName : ""),
          });
          //debugger;
        });
        //Sort
        airports = airports.sort((a, b) => {
          if (a.Name > b.Name) {
            return 1;
          } else {
            return -1;
          }
        });
        let selected = airports.filter(
          (x) => x.GUID === currentGround?.DropoffGUID
        )[0];

        if (selected !== undefined && selected !== null) {
          copy.DropoffPlace = selected.Name;
        }
      }
    }

    setState((prev) => {
      return { ...prev, ...copy };
    });
  }

  function loadAssignedAmount() {
    try {
      let ground = currentGround;
      if (ground.value) {
      }
      console.log("the ground", ground, ground.value);
      let staff = ground.GroundStaff.filter((x) => x.Deleted === null);
      staff = sortArray(staff, "Name");
      //  staff = staff.sort((a, b) => a.Name.localeCompare(b.Name)).filter((s) => s.Deleted === null);
      return (
        <>
          {staff.length} Assigned Member
          {staff.length > 1 && "s"}
        </>
      );
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  const { loading, event } = props;

  const {
    staffSelected,
    passengers,
    notes,
    vehicleModal,
    vehicleSelected,
    editMode,
  } = state;
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 500,
    slidesToShow:
      currentGround?.GroundVehicles?.filter((g) => g.Deleted === null)
        .length === 1
        ? 1
        : 2,
    slidesToScroll: 1,
  };

  const saveNote = (note) => {
    console.log(note);
  };

  return (
    <div className="groundDetail__root row mx-0">
      <ModalComponent
        toggle={toggleVehicleModal}
        modal={vehicleModal}
        childeren={
          <VehicleModal
            loading={loading}
            ground={currentGround}
            vehicle={vehicleSelected}
            toggle={toggleVehicleModal}
            editVehicle={editVehicle}
          />
        }
      />
      <ModalComponent
        toggle={toggleAddVehicle}
        modal={state.addVehicle}
        childeren={
          <VehicleModal
            loading={loading}
            add={true}
            ground={currentGround}
            toggle={toggleAddVehicle}
            addVehicle={addVehicle}
          />
        }
      />
      <ModalComponent
        toggle={togglePassengers}
        modal={state.passengers}
        childeren={
          <AssignMembersGround
            toggle={togglePassengers}
            // parentState={state}
            donable
            usersSelected={state.staffSelected}
            ground={currentGround}
            event={props.event}
            //  parentToggle={props.toggle}
          />
          // <AddGroundsPassengers
          //   toggle={this.togglePassengers}
          //   title={"Assign Members"}
          //   eventStaffs={teams}
          //   arrayToBeSelected={staffSelected}
          //   toggleMember={this.toggleMember}
          //   currentReservations={currentGround.GroundStaff}
          //   addPassengers={this.addPassengers}
          //   donable={true}
          //   edit={true}
          //   submit={this.editPassengers}
          //   loading={loading}
          // />
        }
      />

      {/*<ModalComponent
          toggle={editMode ? this.closeEdit : this.toggleGroundModal}
          modal={this.state.addGround}
          childeren={
            <AddGroundComponent
              event={event}
              teams={this.props.teams}
              toggle={editMode ? this.closeEdit : this.toggleGroundModal}
              ground={props.sideBarItemData}
              editGround={this.editGround}
              editMode={editMode}
              loading={loading}
              getGrounds={getGrounds}
            />
          }
        />*/}

      <ModalComponent
        toggle={editMode ? closeEdit : toggleGroundModal}
        modal={state.addGround}
        childeren={
          <AddEditGround
            event={event}
            teams={teams}
            toggle={editMode ? closeEdit : toggleGroundModal}
            ground={currentGround}
            editGround={editGround}
            editMode={editMode}
            loading={loading}
            getGrounds={actions.getGrounds}
            handleSidebarItem={props.handleSidebarItem}
          />
        }
      />

      <div className="col-10">
        <h5 className="contentTitle">
          <FormattedMessage
            id="groundInfo"
            defaultMessage="Transportation Info"
          />
        </h5>
      </div>
      <div className="col-2 text-right">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => editModal(currentGround)}
        >
          <FormattedMessage id="Edit" defaultMessage="Edit" />
        </button>
      </div>
      <div className="col-12">
        <div className="hotelDetail__picture c-pointer">
          <img
            className="img-fluid"
            src={IMAGES.GROUND_PLACEHOLDER}
            alt="Ground Illustration"
          />
          {currentGround?.Label && (
            <div class="c-pointer labelContainer my-1">
              {currentGround?.Label}
            </div>
          )}
        </div>
      </div>
      <div className="col-5 text-dark mt-2">
        <p className="text-dark">
          <b>{currentGround?.Pickup}</b>
        </p>
        <p className="text-dark">{state?.PickupPlace}</p>
      </div>
      <div className="col-2 text-center mt-2">
        <img className="mx-2" width="65" src={IMAGES.UNION} alt="" />
      </div>
      <div className="col-5 text-right text-dark mt-2">
        <p>
          <b>{currentGround?.Dropoff}</b>
        </p>
        <p className="text-dark">{state?.DropoffPlace}</p>
      </div>
      <div className="col-12">
        <hr className="my-2" />
      </div>

      <div className="col-6 text-dark">
        <p>
          <b>
            <FormattedMessage
              id="transportationType"
              defaultMessage="Transportation Type"
            />
          </b>
          <br />
          {currentGround?.Company}
        </p>
      </div>

      {currentGround?.Company === "Car Service" && (
        <div className="col-6 text-right text-dark">
          <p>
            <b>
              <FormattedMessage id="reservation" defaultMessage="Reservation" />
            </b>
            <br />
            {currentGround?.Reservation ? currentGround?.Reservation : "-"}
          </p>
        </div>
      )}
      <div className="col-12">
        <hr className="my-2" />
      </div>

      <div className="col-6 text-dark">
        <p>
          <b>
            <FormattedMessage id="pickupDate" defaultMessage="Pickup Date" />
          </b>
          <br />
          {moment(currentGround?.PickupDate).format("MMM DD, YYYY")}
        </p>
      </div>

      <div className="col-6 text-right text-dark">
        <p>
          <b>
            <FormattedMessage id="pickupTime" defaultMessage="Pickup Time" />
          </b>
          <br />
          {moment(currentGround?.PickupDate).format("hh:mm a")}
        </p>
      </div>

      <div className="col-12">
        <hr className="my-2" />
      </div>

      <div className="col-3 text-dark">
        <p>
          <b>
            <FormattedMessage id="contact" defaultMessage="Driver's Name" />
          </b>
          <br />
          {currentGround?.DriverName}
        </p>
      </div>

      <div className="col-3 text-center text-dark">
        <p>
          <b>
            <FormattedMessage
              id="driverNumber"
              defaultMessage="Driver's Contact"
            />
          </b>
          <br />
          {currentGround?.Contact}
        </p>
      </div>

      <div className="col-3 text-center text-dark">
        <p>
          <b>
            <FormattedMessage id="vehicleType" defaultMessage="Vehicle Type" />
          </b>
          <br />
          {currentGround?.TypeVehicle}
        </p>
      </div>

      <div className="col-3 text-right text-dark">
        <p>
          <b>
            <FormattedMessage id="distance" defaultMessage="Distance" />
          </b>
          <br />
          {currentGround?.Distance ? currentGround?.Distance : "-"}
        </p>
      </div>

      <div className="groundDetail__notes col-12 mt-2 mb-4">
        <div
          onClick={() => toggleNotes()}
          className="hotelDetail__notes-head text-center px-2 py-1 d-inline my-2 "
        >
          <i className="fa fa-align-left d-inline mr-2"></i>
          <label className="d-inline title text-light">
            <FormattedMessage id="notes" defaultMessage="General Notes" />
          </label>
          <div className="d-inline">
            {notes ? (
              <i className="fas fa-caret-up  ml-2"></i>
            ) : (
              <i className="fas fa-caret-down ml-2"></i>
            )}
          </div>
        </div>
        {notes && (
          <Formik
            onSubmit={(values) =>
              editGround(
                { ...values, GroundStaff: null, GroundVehicles: null },
                false
              )
            }
            initialValues={props.sideBarItemData}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <Form style={{ padding: "20px 0px" }}>
                  {/* <ReactQuill
                    theme="snow"
                    value={props?.values?.Note}
                    onChange={(content) => {
                      props.setFieldValue("Note", content);
                    }}
                  /> */}

                  <RichTextComponent
                    toolbarSettings={{
                      items: [
                        "Bold",
                        "Italic",
                        "Underline",
                        "StrikeThrough",
                        "|",
                        "Formats",
                        "Alignments",
                        "OrderedList",
                        "UnorderedList",
                        "|",
                        "CreateLink",
                        "Image",
                        "|",
                        "SourceCode",
                        "Undo",
                        "Redo",
                      ],
                    }}
                    value={props?.values?.Note}
                    handleChange={({ value }) => {
                      props.setFieldValue("Note", value);
                    }}
                  />

                  <div className="text-right mb-2">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <button
                        className="btn primary text-capitalize w-140 mt-2"
                        type="submit"
                      >
                        Update notes
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}{" "}
      </div>

      {/* <div className="col-12 text-dark">
        <h6 className="text-capitalize">
          {
            currentGround?.GroundVehicles?.filter(
              (v) => v.Deleted === null
            ).length
          }{" "}
          {currentGround?.GroundVehicles?.filter(
            (v) => v.Deleted === null
          ).length === 1 ? (
            <FormattedMessage id="vehicle" defaultMessage="Vehicle" />
          ) : (
            <FormattedMessage id="vehicles" defaultMessage="Vehicles" />
          )}
        </h6>
      </div> */}

      <div className="groundDetail__vehicles col-12 text-dark">
        {currentGround?.GroundVehicles?.filter(
          (x) => x.DriverName !== undefined && x.Deleted === null
        )
          .sort((a, b) => a.DriverName.localeCompare(b.DriverName))
          .map((vehicle) => (
            <Vehicle
              key={vehicle.GUID}
              vehicle={vehicle}
              ground={currentGround}
              toggleVehicleModal={toggleVehicleModal}
              deleteVehicle={deleteVehicle}
              editVehicle={editVehicle}
            />
          ))}
        <Slider {...settings}></Slider>
      </div>
      <div className="col my-2">
        <h6 className="text-capitalize">{loadAssignedAmount()}</h6>
      </div>
      <div className="col-auto mt-2 text-right">
        <button
          onClick={() =>
            cleanPassengers(
              currentGround?.GroundStaff?.filter((s) => s.Deleted === null)
            )
          }
          className="btn btn-danger btn-sm "
        >
          <FormattedMessage id="removeAll" defaultMessage="Remove All" />
        </button>

        <button
          onClick={() => togglePassengers()}
          className="ml-2 btn btn-primary btn-sm"
        >
          <i className="fa fa-plus"></i>{" "}
          <FormattedMessage
            id="assignMembers"
            defaultMessage="Assign Members"
          />
        </button>
      </div>

      {/* <UserItem key={staff.GUID} user={staff} deleteStaff={() => this.deletePassenger(staff)} /> */}

      <div className="col-12 my-2">
        {currentGround?.GroundStaff?.filter((x) => x.Name !== undefined)
          .sort((a, b) => a.Name.localeCompare(b.Name))
          .filter((s) => s.Deleted === null)
          .map((staff) => (
            <div
              key={staff.GUID}
              className="groundDetail__member card shadow-sm my-1"
            >
              <div className="card-body px-2 py-1 row">
                <GeneralUserItem account={staff} />
                {/* onClick={togglePassengers} */}
                <button
                  onClick={() => deletePassenger(staff)}
                  className="btn-link btn groundDetail__delete"
                >
                  <DeleteOutline />
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default GroundDetail;
