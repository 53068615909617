import api from "../../../common/api";
import db from "../../../common/db";
import * as constant from "./constants";

export function deleteOrganization(organization, user, callBack) {
  return async (dispatch) => {
    const urlOrganization = `/Organizations/Delete?GUID=${organization}&DeletedBy=${user}`;
    dispatch({
      type: constant.DELETING_ORGANIZATION,
    });
    try {
      await api.get(urlOrganization);
      await db.table("organizations").delete(organization);
      dispatch({
        type: constant.DELETED_ORGANIZATION,
      });
      callBack();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.DELETING_ORGANIZATION_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_ORGANIZATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.DELETING_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
