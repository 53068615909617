import React, { useEffect, useState } from "react";
import RequestPasses from "../../../../components/guestList/RequestPasses";
import TicketForm from "../../../../components/guestList/TicketForm";
import uuid from "uuid";
import { getBasicInfo } from "../../../../utils";
import { ToastNotificationSuccess } from "../../../../components";
import { IMAGES, LOCAL_STORAGE } from "../../../../constant";
import { createRequest } from "../../../ticketsAndPasses/redux/createRequest";
import { useDispatch, useSelector } from "react-redux";
import { selectEvent } from "../../../ticketsAndPasses/redux/selectEvent";
import { apiService } from "../../../../common/api";
import { GuestListTable } from "../components/GuestListTable";
import { Modal } from "react-bootstrap";
import { updateRequest } from "../../../ticketsAndPasses/redux/updateRequest";
import Swal from "sweetalert2";

export const GuestListView = ({
  state,
  setParentState,
  loggedIn,
  currentWitdh,
  event,
  EventGUID,
  CollabGUID,
  getCollabData,
  user,
}) => {
  const dispatch = useDispatch();
  const teams = [];
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const createTicket = (pass, newOne) => {
    setIsLoading(true);
    const newPass = {
      ...pass,
      Note: `${pass.Note} | First Name: ${user.firstName} | Last Name: ${
        user.lastName
      } | Email: ${user.email} | GUID: ${user.GUID || user.guestGUID} | type: ${
        user.type
      }`.trim(),
      EventGUID: state.SectionGUID,
      GUID: uuid.v4(),
      RequestedFor: pass.RequestedFor,
      RequestedForLastName: pass.RequestedForLastName,
      RequestAccountGUID: state.SharedByAccountGUID,
      ConfirmDate: null,
      RequestDate: new Date(),
      Confirmed: false,
      ConfirmedPasses: null,
      ConfirmAccountGUID: null,
      NoteConfirmed: null,
      CollabGUID: CollabGUID,
    };
    dispatch(
      createRequest(newPass, () => {
        setIsLoading(false);
        ToastNotificationSuccess(Math.random(), "Created successfully");
        getCollabData();
        setShow(false);
      })
    );
  };

  const deleteTicket = (pass) => {
    pass.Deleted = new Date();
    Swal.fire({
      title: "Delete Ticket",
      text: "Are you sure you want to delete this ticket?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          updateRequest(pass, () => {
            setIsLoading(false);
            ToastNotificationSuccess(
              Math.random(),
              "Ticket deleted successfully."
            );
            getCollabData();
            setShow(false);
          })
        );
      }
    });
  };

  return (
    <div className="event-expennd-section">
      <Modal
        show={show}
        onEscapeKeyDown={() => setShow(false)}
        onExit={() => setShow(false)}
        backdrop="static"
      >
        <div className="w-100 bg-white text-black-50 rounded-lg overflow-hidden ">
          <div className="text-head">
            <h1>{"Tickets Request"}</h1>
            <button
              className="btn btn-sm btn-link text-black-50"
              onClick={() => setShow(false)}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div className="px-4">
            <TicketForm
              teams={teams}
              createTicket={createTicket}
              mode={"Tickets Request"}
              event={event}
              requestType={true}
              hideRequestBy={true}
              hidePickupLocation={true}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Modal>
      <h3 className="mb-3 font-weight-bold">Guest List</h3>

      {state.data?.length > 0 ? (
        <>
          <div className="text-head">
            <h1>{"Tickets Requested"}</h1>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setShow(true)}
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <GuestListTable data={state.data} deleteTicket={deleteTicket} />
        </>
      ) : (
        <>
          <div className="text-head">
            <h1>{"Tickets Request"}</h1>
          </div>
          <TicketForm
            teams={teams}
            createTicket={createTicket}
            mode={"Tickets Request"}
            event={event}
            requestType={true}
            hideRequestBy={true}
            hidePickupLocation={true}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};
