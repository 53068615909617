import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import AddHotelComponent from "../../page/addHotel/addHotelContainer";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import UserItem from "./UserItem";
import RoomListModal from "./roomListModal";

import { FormattedMessage, useIntl } from "react-intl";
import "react-quill/dist/quill.snow.css";
import uuid from "uuid";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import { usePrevious } from "../../hooks/usePrevious";
import * as commonActions from "../../page/common/redux/actions";
import * as actions from "../../page/event/redux/actions";
import { handleCloseEmail } from "../../utils/closeMailModal";
import AddEditSingleAssigneeHotel from "../hotels/AddEditSingleAssigneeHotel";
import AssignMembersHotel from "../hotels/AssignMembersHotel";
import EmailModal from "./emailModal";
import DragAndDrop from "../common/DragAndDrop";
import { extractContentText } from "../../common/helpers";
import { fileIcon } from "../../page/files/helper";

const onErrorImage = (sender) => {
  sender.target.src = IMAGES.HOTEL_PLACEHOLDER;
};

const HotelDetail = (props) => {
  // const teams = useSelector((state) => state.organizations.teams);
  // const events = useSelector((state) => state.calendar.calendarEvents);
  const event = useSelector((state) => state.event.event);
  // const loading = useSelector((state) => state.event.loading);
  const hotels = useSelector((state) => state.event.hotels);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [state, setState] = useState({
    editMode: false,
    included: true,
    addNewsModal: false,
    modal: false,
    roomList: false,
    hotelImage: false,
    imgSrc: null,
    staffSelected: [],
    showAssignMembers: false,
    showExportRoomlist: false,
    showSingleStaff: false,
    SingleStaffSelected: null,
    Hotel: props.hotel,
  });

  const prevProps = usePrevious(props);
  useEffect(() => {
    try {
      const { hotel, handleSidebarItem } = props;
      setState((prev) => ({
        ...prev,
        notes: false,
        Hotel: hotel,
      }));

      hotels.find((g) => g.GUID === hotel.GUID);
      if (JSON.stringify(prevProps.hotels) !== JSON.stringify(hotels)) {
        if (hotels.length === 0) {
          handleSidebarItem("EVENT_DETAILS", {});
        } else {
          setState((prev) => ({
            ...prev,
            Hotel: hotel,
          }));
        }
      }
    } catch (ex) {
      console.log("hotel error", ex);
    }
  }, [props]);

  const toggleShowExportRoomList = () => {
    setState((prev) => ({
      ...prev,
      showExportRoomlist: !prev.showExportRoomlist,
    }));
  };

  const toggleHotelImage = (image) => {
    setState((prev) => ({
      ...prev,
      hotelImage: !prev.hotelImage,
      imgSrc: image,
    }));
  };

  const toggleRoomList = () => {
    setState((prev) => ({ ...prev, roomList: !prev.roomList }));
  };

  const toggleModal = () => {
    setState((prev) => ({ ...prev, modal: !prev.modal }));

    if (!state.modal) {
      reloadHotels();
    }
  };

  const toggleAssignMembers = (obj) => {
    setState((prev) => ({
      ...prev,
      showAssignMembers: !prev.showAssignMembers,
    }));

    // console.log("display assign members", state.showAssignMembers, obj);
    //MEans we need to reload

    if (obj) {
      dispatch(actions.getHotel(event.GUID));
      // this.props.handleSidebarItem("HOTEL", {
      //   ...this.props.hotel,
      //   HotelReservations: [],
      // });
      setTimeout(() => {
        reloadHotels();
      }, 1000);
    }
  };

  // const toggleSelectAll = (team) => {
  //   const singleMembers = team.map((member) => ({ ...member, Account: null }));
  //   singleMembers.forEach((s) => {
  //     toggleHotelAssign(s);
  //   });
  // };

  // const toggleHotelAssign = (staff) => {
  //   //Search all current selected
  //   let staffs = state.staffSelected.map((staff) => ({
  //     ...staff,
  //     Account: null,
  //     Staff: null,
  //   }));

  //   let exist = staffs.filter(
  //     (x) => x.AccountGUID === staff.AccountGUID && x.Deleted === null
  //   );
  //   if (exist.length > 0) {
  //     //If exist must be removed

  //     staffs = staffs.filter((x) => x.AccountGUID !== staff.AccountGUID);
  //   } else {
  //     //Add
  //     staffs.push({
  //       AccountGUID: staff.AccountGUID,
  //       GUID: uuid.v4(),
  //       Deleted: null,
  //       Room: "",
  //       Number: "",
  //       CheckinDate: props.hotel.Checkin,
  //       CheckoutDate: props.hotel.Checkout,
  //     });
  //   }
  //   staffs = sortArray(staffs, "HOTELMEMBERS");
  //   setState((prev) => ({ ...prev, staffSelected: staffs }));
  // };

  const addReservations = (staffs) => {
    setState((prev) => ({ ...prev, staffSelected: staffs }));
  };

  const cleanReservations = () => {
    setState((prev) => ({ ...prev, staffSelected: [] }));
  };

  const cleanHotel = (hotel) => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to clean the hotels?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        props.removeAllReservations(hotel.HotelReservations, hotel, () => {
          props.handleSidebarItem("HOTEL", {
            ...hotel,
            HotelReservations: [],
          });
          ToastNotificationSuccess(1, "Reservations has been deleted");
        });
      }
    });
  };

  const deleteMember = (reservation) => {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this reservation?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.removeReservation(reservation, props.hotel, () => {
            ToastNotificationSuccess(1, "Reservation has been deleted");
            dispatch(actions.getHotel(event.GUID));
            setTimeout(() => {
              props.handleSidebarItem("HOTEL", state.Hotel);
              reloadHotels();
            }, 1000);
          })
        );
      }
    });
  };

  const updateReservation = (reservation) => {
    let staffs = state.staffSelected.map((staff) =>
      staff.AccountGUID === reservation.AccountGUID
        ? (staff = reservation)
        : staff
    );
    setState((prev) => ({ ...prev, staffSelected: staffs }));
  };

  // const updateReservations = () => {
  //   const { sideBarItemData } = props;
  //   dispatch(
  //     actions.editReservations(state.staffSelected, sideBarItemData, () => {
  //       ToastNotificationSuccess(1, "Updated reservations");
  //       dispatch(actions.getHotel(event.GUID));

  //       //  this.toggleNewMembersModal();
  //     })
  //   );
  // };

  const reloadHotels = async () => {
    let item = await db
      .table("hotels")
      .where({ GUID: props.hotel.GUID })
      .first();
    setState((prev) => ({ ...prev, Hotel: item }));
  };

  const updateReservationsRoomList = () => {
    const { sideBarItemData } = props;
    dispatch(
      actions.editReservations(state.staffSelected, sideBarItemData, () => {
        ToastNotificationSuccess(1, "Updated reservations");
        dispatch(actions.getHotel(event.GUID));
        toggleRoomList();
      })
    );
  };

  const toggleEditModal = async (hotel) => {
    if (hotel) {
      props.handleSidebarItem("HOTEL", hotel);
    }
    setState((prev) => ({ ...prev, editMode: !prev.editMode }));
    toggleModal();
    await reloadHotels();
  };

  const editHotel = (hotel, fromNotes, label) => {
    if (label) hotel.Label = label;

    dispatch(
      actions.editHotel(hotel, fromNotes, () => {
        ToastNotificationSuccess(1, "Updated Hotel");
        dispatch(actions.getHotel(event.GUID));
        setTimeout(() => {
          !fromNotes && toggleEditModal();
        }, 1000);
      })
    );
  };

  const editStaff = (staff, reload) => {
    setState((prev) => ({
      ...prev,
      SingleStaffSelected: staff,
      showSingleStaff: !prev.showSingleStaff,
    }));

    if (reload) {
      dispatch(actions.getHotel(event.GUID));
    }
  };

  const { sideBarItemData } = props;
  const hotel = state.Hotel;
  const { hotelImage, imgSrc } = state;

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const [hotelFiles, setHotelFiles] = useState([]);

  const loadHotelFiles = async () => {
    const response = await apiService.get(
      `/Attachment/Attachments?EventGUID=${event.GUID}&HotelGUID=${hotel.GUID}`
    );
    setHotelFiles(response.data.filter((x) => x.Deleted === null));
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const onChangeDrop = async (files) => {
    try {
      let theFiles = Array.from(files);
      const response = await Promise.all(
        theFiles?.map(async (item) => {
          let newItem = {
            guid: uuid.v4(),
            name: item.name,
            note: "",
            path: item.name,
            eventGUID: event.GUID,
            HotelGUID: hotel.GUID,
          };

          const bodyData = new FormData();
          bodyData.append("path", item);
          const responseFile = await apiService.post(`/File/upload/`, bodyData);

          const requestGeneral = await apiService.post(
            "/attachment/AddEditForEventWithoutImage",
            {
              ...newItem,
              Path: responseFile.data.Response,
            }
          );
        })
      );

      if (response.length > 0) {
        loadHotelFiles();
        ToastNotificationSuccess(1, "Files uploaded successfully");
      }
    } catch (ex) {
      console.log("Error", ex);
      ToastNotificationError(1, "Error uploading files");
    }
  };

  useEffect(() => {
    loadHotelFiles();
  }, [hotel]);

  function confirmDeleteAttachment(attachment) {
    Swal.fire({
      text:
        intl.formatMessage({ id: "Are you sure you want to delete" }) +
        attachment.Name +
        "?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteAttachment(attachment, () => {
            ToastNotificationSuccess(1, "Attachment deleted");
            loadHotelFiles();
          })
        );
      }
    });
  }

  //TODO: CHECK DATA HERE.

  return (
    hotel && (
      <div className="hotelDetail__root row mx-0">
        <ModalComponent
          toggle={() => handleCloseEmail(toggleShowExportRoomList)}
          modal={state.showExportRoomlist}
          childeren={
            <EmailModal
              subject={hotel ? hotel.Name + " - Roomlist" : ""}
              hotel={hotel}
              fromSection="HOTEL_ROOMLIST"
              //teams={this.props.teams}
              toggle={toggleShowExportRoomList}
            />
          }
        />

        <ModalComponent
          toggle={editStaff}
          modal={state.showSingleStaff}
          childeren={
            <AddEditSingleAssigneeHotel
              model={state.SingleStaffSelected}
              toggle={editStaff}
            />
          }
        />

        <ModalComponent
          modal={hotelImage}
          toggle={() => toggleHotelImage()}
          childeren={
            <div className="image-main">
              <div className="d-flex align-items-center mb-2"></div>

              <img
                src={imgSrc}
                alt="Venue"
                className="fluid-img img-thumbnail"
              />
            </div>
          }
        />
        <ModalComponent
          toggle={toggleAssignMembers}
          modal={state.showAssignMembers}
          childeren={
            <AssignMembersHotel
              hotel={sideBarItemData}
              toggle={toggleAssignMembers}
              originEvent={event}
              active={state.showAssignMembers}
              edit={true}
            />
          }
        />
        <ModalComponent
          toggle={toggleEditModal}
          modal={state.modal}
          childeren={
            <AddHotelComponent
              searchHotels={commonActions.searchHotels}
              emptyHotels={commonActions.emptyHotels}
              addHotel={actions.addHotel}
              toggle={toggleEditModal}
              event={event}
              edit={state.editMode}
              hotel={sideBarItemData}
              editHotel={editHotel}
            />
          }
        />
        <ModalComponent
          modal={state.roomList}
          toggle={toggleRoomList}
          childeren={
            <RoomListModal
              currentReservations={
                sideBarItemData && sideBarItemData.HotelReservations
                  ? sideBarItemData.HotelReservations
                  : []
              }
              addReservations={addReservations}
              updateReservation={updateReservation}
              updateReservations={updateReservationsRoomList}
              cleanReservations={cleanReservations}
              hotelDetails={sideBarItemData}
              peoples={state.staffSelected.filter((p) => p.Deleted === null)}
              toggle={toggleRoomList}
            />
          }
        />
        <div className="col-6">
          <h5 className="contentTitle">
            <FormattedMessage id="hotelInfo" defaultMessage="Hotel Info" />
          </h5>
        </div>
        <div className="col-6 text-right">
          <button
            className="btn btn-sm btn-secondary  shareBg mr-2"
            type="button"
            onClick={() => toggleShowExportRoomList()}
          >
            <i className="fa fa-share-alt"></i>
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => toggleEditModal(sideBarItemData)}
          >
            <FormattedMessage id="Edit" defaultMessage="Edit" />
          </button>
        </div>
        <div className="col-6">
          <div className="hotelDetail__picture c-pointer">
            <img
              onError={onErrorImage}
              onClick={
                hotel && hotel.Image
                  ? () => toggleHotelImage(hotel.Image)
                  : null
              }
              alt=""
              className=""
              src={
                hotel && hotel.Image ? hotel.Image : IMAGES.HOTEL_PLACEHOLDER
              }
            />
          </div>
        </div>
        <div className="col-6 text-right pt-4 ">
          <p>
            <button
              className="btn btn-success mt-4"
              type="button"
              onClick={() => {
                let fullAddress = "";
                fullAddress =
                  hotel.Address + ", " + hotel.City + ", " + hotel.Country;
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${
                    hotel.Name + " " + fullAddress
                  }`,
                  "MapWindow",
                  "width=1280,height=840"
                );
              }}
            >
              <FormattedMessage id="openinMaps" defaultMessage="Open in Maps" />
            </button>
          </p>
        </div>
        <div className="col-12 mt-2">
          <h5 className="title">{hotel.Name}</h5>
          <p className="subTitle text-truncate">
            {hotel.Address}, {hotel.City}, {hotel.Country}
          </p>

          <hr />
        </div>
        <div className="col-6 text-dark">
          <p>
            <b>
              <FormattedMessage id="checkIn" defaultMessage="Check In" />
            </b>
          </p>
          <p className="text-dark">
            {moment(hotel?.Checkin).format("ddd, h a")}
          </p>
          <p>{moment(hotel?.Checkin).format("MMMM DD, YYYY")}</p>
        </div>
        <div className="col-6 text-right text-dark">
          <p>
            <b>
              <FormattedMessage id="checkOut" defaultMessage="Check Out" />
            </b>
          </p>
          <p className="text-dark">
            {moment(hotel?.Checkout).format("ddd, hh a")}
          </p>
          <p>{moment(hotel?.Checkout).format("MMMM DD, YYYY")}</p>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-6 text-dark">
          <p>
            <b>
              <FormattedMessage
                id="phoneNumber.signUp"
                defaultMessage="Phone Number"
              />
            </b>
          </p>
          <p className="text-muted">{hotel.Phone}</p>
        </div>
        <div className="col-6 text-dark text-right">
          <a
            target="_blank"
            href={hotel.Website}
            className=" text-primary d-inline link"
          >
            <i className="fas fa-globe mr-1"></i>{" "}
            <FormattedMessage id="website" defaultMessage="Website" />
          </a>
        </div>

        <div className="hotelDetail__perks col-12 mt-3">
          <div className="hotelDetail__perks-header text-center px-2 py-1 d-inline">
            <label className="d-inline title text-light">
              <FormattedMessage id="amenities" defaultMessage="Amenities" />{" "}
              &amp; <FormattedMessage id="included" defaultMessage="Included" />
            </label>
          </div>

          <div className="hotelDetail__perks-inner">
            {hotel.BusinessCenter && (
              <span className="hotelItem__label">
                <FormattedMessage
                  id="businessCenter"
                  defaultMessage="Business Center"
                />
              </span>
            )}
            {hotel.Gym && (
              <span className="hotelItem__label">
                <FormattedMessage id="gym" defaultMessage="Gym" />
              </span>
            )}
            {hotel.Laundry && (
              <span className="hotelItem__label">
                <FormattedMessage id="laundry" defaultMessage="Laundry" />
              </span>
            )}
            {hotel.Pool && (
              <span className="hotelItem__label">
                <FormattedMessage id="pool" defaultMessage="Pool" />
              </span>
            )}
            {hotel.RestaurantBar && (
              <span className="hotelItem__label">
                <FormattedMessage
                  id="restaurant/Bar"
                  defaultMessage="Restaurant/Bar"
                />
              </span>
            )}
            {hotel.Spa && (
              <span className="hotelItem__label">
                <FormattedMessage id="spa" defaultMessage="Spa" />
              </span>
            )}
          </div>
          <div className="hotelDetail__perks-inner">
            {hotel.Internet && <span className="hotelItem__label">Wifi</span>}
            {hotel.Breakfast && (
              <span className="hotelItem__label">
                <FormattedMessage id="breakfast" defaultMessage="Breakfast" />
              </span>
            )}
            {hotel.Dinner && (
              <span className="hotelItem__label">
                <FormattedMessage id="dinner" defaultMessage="Dinner" />
              </span>
            )}
            {hotel.Lunch && (
              <span className="hotelItem__label">
                <FormattedMessage id="lunch" defaultMessage="Lunch" />
              </span>
            )}
            {hotel.Parking && (
              <span className="hotelItem__label">
                <FormattedMessage id="parking" defaultMessage="Parking" />
              </span>
            )}
          </div>
        </div>

        <div className="hotelDetail__members mt-2 col-12">
          <div className="assignedHotelMember__root d-flex align-items-center py-1">
            {hotel !== undefined && hotel.HotelReservations !== undefined ? (
              <div className="assignedHotelMember__details">
                <h6 className="f-13">
                  {hotel &&
                    hotel.HotelReservations &&
                    hotel.HotelReservations.filter((m) => m.Deleted === null)
                      .length}{" "}
                  Member
                  {hotel &&
                    hotel.HotelReservations &&
                    hotel.HotelReservations.filter((m) => m.Deleted === null)
                      .length > 1 &&
                    "s"}{" "}
                  Assigned
                </h6>
              </div>
            ) : null}

            <div className="d-flex align-items-center ml-auto">
              <button
                className="btn btn-secondary btn-sm mr-2"
                type="button"
                onClick={() => toggleRoomList()}
              >
                <FormattedMessage id="roomList" defaultMessage="Room List" />
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => toggleAssignMembers()}
              >
                <i className="fa fa-plus" />{" "}
                <FormattedMessage
                  id="assignMembers"
                  defaultMessage="Assign Members"
                />
              </button>
              <DropDownComponent
                className="mx-2 pr-1 btn btn-sm"
                data={[
                  {
                    name: (
                      <FormattedMessage
                        id="roomList"
                        defaultMessage="Room List"
                      />
                    ),
                    trigger: () => toggleRoomList(),
                  },
                  {
                    name: (
                      <FormattedMessage
                        id="removeAll"
                        defaultMessage="Remove All"
                      />
                    ),
                    trigger: () => cleanHotel(hotel),
                    border: true,
                    delete: true,
                  },
                ]}
              />
            </div>
          </div>
          {hotel?.HotelReservations?.filter(
            (x) => x.Deleted === null && x.Account
          )
            .sort((a, b) => a.Account.Name.localeCompare(b.Account.Name))
            .filter((m) => m.Deleted === null)
            .map((member) => (
              <UserItem
                deleteStaff={deleteMember}
                key={member.GUID}
                user={member}
                editStaff={editStaff}
              />
            ))}
        </div>
        <div className="col-12">
          <hr />
          <h6 className="f-13">Files</h6>
          <div className="row">
            <DragAndDrop
              handleDragOut={handleDragOut}
              handleDropCalendarEvent={onChangeDrop}
              fontSize={18}
              className="col-12 px-0  mx-0"
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{
                  margin: "20px 0",
                  height: "200px",
                  width: "100%",
                  border: "2px dashed #ccc",
                  color: "black",
                }}
              >
                <i className="text-primary fa fa-cloud-upload-alt text-white fa-5x"></i>
                Drop your hotel related files here
              </div>
            </DragAndDrop>
          </div>
          <div className="d-flex flex-column">
            {hotelFiles?.map((file) => (
              <div className="row bg-white shadow-sm rounded p-2 my-1">
                <div className="col-9 my-1  d-flex align-items-center px-0">
                  <div className="d-inline-block mr-2 ">
                    <i className={fileIcon(file) + " fa-2x"}></i>
                    {/* {file.Path && file.Path.includes("dropbox")
            ? iconPreview(
                file.Path && file.Path.split("?").pop(),
                file
              )
            : iconPreview(
                file.Path && file.Path.split(".").pop(),
                file
              )} */}
                  </div>
                  <div className="text-truncate">
                    <h6 className="text-truncate">
                      <a
                        target="_blank"
                        className="clean-link"
                        href={
                          (file.Path && file.Path?.includes("dropbox")) ||
                          file.Path?.includes("google") ||
                          file.Path?.includes("http") ||
                          file.Path?.includes("1drv")
                            ? `${file.Path}`
                            : `https://service.plnify.com/files/${file.Path}`
                        }
                      >
                        {file.Name ? file.Name : file.Path}
                      </a>
                    </h6>

                    {file.Note && (
                      <div className="text-truncate subTitle">
                        {extractContentText(file.Note)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-2 ml-auto ">
                  <DropDownComponent
                    className="float-right"
                    data={[
                      // {
                      //   name: "Copy to Other Events",
                      //   trigger: () => toggleExport(),
                      // },
                      // {
                      //   name: "Move to another event",
                      //   trigger: () => toggleMove(),
                      //   border: true,
                      // },
                      {
                        delete: true,
                        name: (
                          <FormattedMessage
                            id="delete"
                            defaultMessage="Delete"
                          />
                        ),
                        trigger: () => confirmDeleteAttachment(file),
                      },
                    ]}
                  />
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </div>
    )
  );
};
export default HotelDetail;
