import React, { useState } from "react";
import { IMAGES } from "../../constant";
import { Link } from "react-router-dom";
import history from "../common/history";
import {
  Airplane,
  Bus,
  Domain,
  ClipboardList,
  AccountBox,
  Attachment,
} from "mdi-material-ui";
import { VenueFullAddress } from "../../common/helpers";
import moment from "moment";
import { ModalComponent } from "../common";
import AddMeetingModal from "../meet/AddMeetingModal";
import { FormattedMessage } from "react-intl";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import DragAndDrop from "../common/DragAndDrop";
export const Event = (props) => {
  const [meetingModal, setMeetingModal] = useState(false);

  let {
    event,
    noIcons,
    iconsToRemove = [],
    toggle,
    fromSidebar,
    fromGuestList,
    setCurrentEvent,
    renderBy,
    hasDragAndDrop,
  } = props;

  const { showDrop, handleDragOut, handleDrop, handleDragIn } =
    useDragAndDrop(event);

  const navigate = (guid) => {
    history.push(`/calendar/${guid}`);
    toggle();
  };

  const isMeeting = event?.EventType === "Meeting";

  const subTitleComplete = () => {
    let subTitle =
      (event?.Date ? moment(event.Date).format("ddd") : "-") +
      " | " +
      (event?.EventType ? event.EventType : "-") +
      " | ";

    if (event?.Venue && event.Venue.Name) {
      subTitle += VenueFullAddress(event.Venue);
    } else {
      subTitle +=
        `${event?.City ? event.City + ", " : ""}` +
        `${event?.State ? event.State + ", " : ""}` +
        `${event?.Country ? event.Country : ""}` +
        " " +
        (event?.Date ? moment(event.Date).format("MMM DD, YYYY") : "-") +
        (props.weather
          ? " | " +
            (props.weather.TimeZoneCode ? props.weather.TimeZoneCode : "-")
          : "");
    }

    return subTitle;
  };

  return (
    <div
      className={
        "row EventInstance overflow-hidden" +
        (props.clickable ? " clickable" : "") +
        (props.selectOnly && props.isSelected ? " active" : "")
      }
      onDragEnterCapture={handleDragIn}
      onClick={
        renderBy === "guestList" ? () => setCurrentEvent(event?.GUID) : () => {}
      }
    >
      {hasDragAndDrop && (
        <DragAndDrop
          handleDragOut={handleDragOut}
          handleDropCalendarEvent={handleDrop}
          fontSize={18}
        >
          <div
            style={{
              ...showDrop.style,
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 999,
            }}
          ></div>
        </DragAndDrop>
      )}
      <ModalComponent
        modal={meetingModal}
        toggle={() => setMeetingModal(!meetingModal)}
        childeren={
          <AddMeetingModal
            onlyToRead
            event={event}
            toggle={() => setMeetingModal(!meetingModal)}
          />
        }
      />
      <div
        onClick={
          isMeeting
            ? () => setMeetingModal(!meetingModal)
            : props.selectOnly
            ? () => setCurrentEvent(event)
            : () => {}
        }
        className={noIcons ? "col-12 p-0" : "col-10 p-0"}
      >
        <div className="row d-flex align-items-center">
          <div className="ml-2 icon d-inline-block">
            <img src={IMAGES.CALENDER} alt="calendar" width="45" />
            <span
              title={event?.EventGroup && event?.EventGroup?.Name}
              style={{
                backgroundColor: event?.EventGroup && event?.EventGroup?.Color,
              }}
            ></span>
            <div className="calendar-inside" style={{ zIndex: 11 }}>
              <h3 className="">
                {event?.Date === null ? "-" : moment(event?.Date).format("MMM")}
              </h3>
              <p>
                {event?.Date === null ? "-" : moment(event?.Date).format("DD")}
              </p>
            </div>
          </div>
          <div className="ml-2 mt-2 px-0 col text-truncate">
            <div className="ml-2 mt-2 px-0 col text-truncate">
              {fromSidebar ? (
                <span
                  style={{ color: "black" }}
                  onClick={
                    props.noClickable === undefined
                      ? () => navigate(event?.GUID)
                      : () => {}
                  }
                >
                  {event?.Name}
                </span>
              ) : (
                <h6>
                  {props.noClickable === undefined ? (
                    <Link
                      className="clean-link "
                      to={`/calendar/${event?.GUID}`}
                    >
                      {event?.Name}
                    </Link>
                  ) : (
                    event?.Name
                  )}
                </h6>
              )}

              <p className="text-truncate" title={subTitleComplete()}>
                {subTitleComplete()}
              </p>
            </div>
            {!noIcons ? (
              <div className="d-flex align-items-baseline col-4 mb-2 icons-container text-right px-2">
                {!iconsToRemove.includes("published") &&
                event?.LastSync !== undefined &&
                event?.LastSync !== null ? (
                  <div className=" d-inline badge badge-success mr-2">
                    <FormattedMessage
                      id="published"
                      defaultMessage="Published"
                    />
                  </div>
                ) : (
                  !iconsToRemove.includes("private") && (
                    <div className="d-inline badge badge-secondary mr-2">
                      <FormattedMessage id="private" defaultMessage="Private" />
                    </div>
                  )
                )}
                {!iconsToRemove.includes("flights") &&
                  !noIcons &&
                  event?.FlightsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <Airplane />
                    </div>
                  )}
                {!noIcons && event?.GroundCount > 0 && !props.fromGuestList && (
                  <div className="icon-section d-inline">
                    <Bus />
                  </div>
                )}
                {!noIcons && event?.HotelsCount > 0 && !props.fromGuestList && (
                  <div className="icon-section d-inline">
                    <Domain />
                  </div>
                )}
                {!noIcons &&
                  event?.ScheduleMasterCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <ClipboardList />
                    </div>
                  )}
                {!iconsToRemove.includes("account") &&
                  !noIcons &&
                  event?.ContactsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <AccountBox />
                    </div>
                  )}
                {!iconsToRemove.includes("attachment") &&
                  !noIcons &&
                  event?.AttachmentsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <Attachment />
                    </div>
                  )}
                {!noIcons &&
                  event?.GuestListCount > 0 &&
                  props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <i
                        className="fa fa-ticket"
                        style={{ color: "#4d4d4d" }}
                      ></i>
                    </div>
                  )}
                {!noIcons && (
                  <span
                    className={` d-inline-block event-status ml-2 ${event?.Status}`}
                    title={event?.Status}
                  ></span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
