import React, { useState, useEffect } from "react";
import { IMAGES } from "../../constant";
import { Scrollbars } from "react-custom-scrollbars";

const EventTypesModal = (props) => {
  let { toggle, eventTypeList } = props;
  const [searchType, setSearchType] = useState("");
  const [eventsType, setEventsType] = useState([]);
  const filteredEventsType =
    searchType.length === 0
      ? eventsType
      : eventsType.filter((type) => {
          return type.toLowerCase().includes(searchType.toLowerCase());
        });

  useEffect(() => {
    setEventsType(eventTypeList);
  }, []);

  function createEventType(e) {
    if (e.keyCode === 13) {
      alert(searchType);
      toggle();
    }
  }

  function clickCreateEventType(e) {
    e.preventDefault();
    alert(searchType);
    toggle();
  }

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1>Event Type</h1>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div
            className="event-inside"
            style={{ height: "calc(100vh - 190px)" }}
          >
            <div className="mb-3">
              <label>Search event type</label>
              <input
                onKeyDown={(e) => createEventType(e)}
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
                className="form-control"
                placeholder="e.g. Concert"
              />
            </div>
            <div className="event-content">
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                {eventsType && filteredEventsType.length > 0 ? (
                  filteredEventsType.map((eventType) => (
                    <div
                      onClick={() => {
                        props.setFieldValue("EventType", eventType);
                        toggle();
                      }}
                      className="eventType__item"
                      key={eventType}
                    >
                      {eventType}
                    </div>
                  ))
                ) : (
                  <div>
                    <a
                      href="#"
                      className="text-primary"
                      onClick={(e) => clickCreateEventType(e)}
                    >{`Press 'enter' or click here to create: ${searchType}`}</a>
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTypesModal;
