import React from "react";

const EmptyStateMoreDetail = (props) => {
  let { image, cta, text, showSideBar, toggleSideBar} = props;
  return (
    <div
      className="text-center emptyState__root_detail mx-auto d-flex align-items-center position-relative"
      style={{}}
    >
      {!showSideBar && (
        <button
          title="Show Events"
          className="btn btn-primary border-0 position-absolute event-sidebar-btn"
          onClick={toggleSideBar}
          style={{ zIndex: "50", left: "-20px", top: "45%" }}
        >
          <i className="fas fa-angle-right" style={{ marginLeft: "13px" }}></i>
        </button>
      )}
      <img src={image} className="img-fluid-files mb-1 mx-auto" />
      <p className="mt-1 text-center">{text}</p>
      <button
        className={
          "btn px-2 d-flex justify-content-center align-items-center self-center mt-2"
        }
        style={{
          backgroundColor: "#ff5100",
          color: "#000",
          padding: "7px 0.5rem",
          width: "fit-content",
        }}
        title="Add new item"
        onClick={() => props.handleAdd()}
      >
        <i className="fa fa-plus mr-2" />
        <p className="text-white">{cta}</p>
      </button>
    </div>
  );
};

export default EmptyStateMoreDetail;
