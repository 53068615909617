import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import db from "../../../common/db";
import { Event } from "../../../components/event/event";
import { IMAGES } from "../../../constant";
import { getBasicInfo, getOrganization } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import api, { apiService } from "../../../common/api";
import { addContact } from "../redux/addContact";
import { editAttachment } from "../redux/editAttachment";
import { addTask } from "../../todoPage/redux/addTask";
import { addItinerary } from "../redux/addItinerary";
import { addHotel } from "../redux/addHotel";
import uuid from "uuid";
import { addEvent } from "../redux/addEvent";
import { ToastNotificationSuccess } from "../../../components";
import { useHistory } from "react-router-dom";

export const MoveEvent = ({ events, toggle }) => {
  const activeEvents = events.filter((event) => event.Deleted === null);

  const [organizations, setOrganizations] = useState([]);
  const [filterEvents, setFilterEvents] = useState(activeEvents || []);
  const [tempFilterEvents, setTempFilterEvents] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({
    Org: "",
    isSelected: false,
  });

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [error, setError] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.organizations.teams);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const currentOrganization = getOrganization().GUID;
      const organizations = (await db.table("organizations").toArray()).filter(
        (org) => org.GUID !== currentOrganization
      );
      setOrganizations(organizations);
    })();
  }, []);

  const handleSelectOrg = (Org) => {
    setSelectedOrg({ Org, isSelected: true });
    setError("");
  };

  const handleSelectEvent = (Event, index) => {
    const newState = filterEvents;
    newState[index] = { ...Event, isSelected: !filterEvents[index].isSelected };
    setFilterEvents([...newState]);
    setSelectedEvents(newState.filter((event) => event.isSelected === true));
    setError("");
  };

  const handleSearchEvent = (e) => {
    const { value } = e?.target;
    setSearchEvent(value);

    const searchArray = filterEvents.filter((event) =>
      event.Name.toLowerCase().includes(value.toLowerCase())
    );

    if (searchArray.length === 0) return setTempFilterEvents("No Event Found");

    setTempFilterEvents(searchArray);
  };

  const handleSelectTempEvent = (Event, index) => {
    const newState = tempFilterEvents;
    newState[index] = {
      ...Event,
      isSelected: !tempFilterEvents[index].isSelected,
    };
    setTempFilterEvents([...newState]);

    setFilterEvents((prev) => {
      const newFilterState = prev.map((event) => {
        if (event.GUID === Event.GUID) {
          return { ...event, isSelected: !event.isSelected };
        }
        return event;
      });

      setSelectedEvents(
        newFilterState.filter((event) => event.isSelected === true)
      );
      setError("");

      return [...newFilterState];
    });
  };

  const handleClear = () => {
    setSelectedOrg({ Org: "", isSelected: false });
    setSelectedEvents([]);
    setTempFilterEvents([]);
    setSearchEvent("");
    setFilterEvents(activeEvents);
    setError("");
  };

  const createItinerary = (itinerary, destination) => {
    let newRecord = {
      Date: itinerary.Date,
      Name: itinerary.Name,
      GUID: itinerary.GUID,
      EventGUID: destination,
    };

    dispatch(
      addItinerary(
        newRecord,
        destination,
        () => {}, //success,
        () => {} //Toggle
      )
    );
  };

  const createFlights = async (flights, destination) => {
    const updatedFlights = flights.map((flight) => {
      return { ...flight, EventGUID: destination };
    });

    await apiService.post("/flights/AddEditBatch", updatedFlights);
  };

  const createHotels = (hotels, destination) => {
    const updatedHotel = [{ ...hotels, EventGUID: destination }];

    dispatch(
      addHotel(
        updatedHotel,
        () => {}, //success,
        true //
      )
    );
  };

  const createTransportation = async (transportation, GUID) => {
    const updatedTransportation = { ...transportation, EventGUID: GUID };
    try {
      let query = [];
      query.push(updatedTransportation);
      await api.post("/grounds/AddEditBatch", query);
    } catch (ex) {
      console.error(ex);
    }
  };

  const createContact = (contact, GUID) => {
    delete contact.EventGUID;
    dispatch(addContact(contact, GUID, () => {}));
  };

  const createAttachment = (attachment, GUID) => {
    const updatedAttachment = { ...attachment, EventGUID: GUID };
    dispatch(editAttachment(updatedAttachment));
  };

  const createNote = async (note, GUID, OrgGUID) => {
    const newNote = {
      GUID: uuid.v4(),
      Name: note?.Name,
      Details: note?.Details,
      OrganizationGUID: OrgGUID,
      EventGUID: GUID,
      Deleted: null,
      Updated: null,
    };

    await db.table("notes").put(newNote);
    await api.post("/Note/AddEditBatch", [newNote]);
  };

  const createTodo = async (todo, GUID, OrgGUID) => {
    const currentUser = getBasicInfo().GUID;
    const newTodoGUID = uuid.v4();
    const newTodo = {
      Created: todo?.Created,
      CreatedByGUID: currentUser,
      Date: todo?.Date,
      Deleted: todo?.Deleted,
      EventGUID: GUID,
      GUID: newTodoGUID,
      Name: todo?.Name,
      Note: todo?.Note,
      OrganizationGUID: OrgGUID,
    };
    const postedTodo = await apiService.post("ToDo/AddEdit", newTodo);
    const createdTodo = await postedTodo?.data?.Response;
    const tasks = todo?.TodoTasks?.map((task) => ({
      ...task,
      GUID: uuid.v4(),
      TodoGUID: createdTodo.GUID,
    }));
    createdTodo.TodoTasks = tasks;
    await db.table("todos").put(createdTodo);
    tasks.forEach((task) => {
      if (task.Deleted === null) dispatch(addTask(task, () => {}));
    });
  };

  const deleteEvent = (event, callback) => {
    event.Deleted = new Date();
    const url = `/Events/Edit`;
    try {
      db.table("events").put(event);
      db.table("event").put(event);
      api.post(url, {
        Name: event.Name,
        EventType: event.EventType,
        EventCapacity: event.EventCapacity,
        Date: `${event.Date}`,
        VenueGUID: event.VenueGUID,
        Notes: event.Notes,
        City: event.City,
        Country: event.Country,
        State: event.State,
        Status: event.Status,
        Latitude: event.Latitude,
        Longitude: event.Longitude,
        GUID: event.GUID,
        OrganizationGUID: event.OrganizationGUID,
        LastSync: event.LastSync,
        DateTime: event.DateTime,
        CreationDate: event.CreateDate,
        Deleted: event.Deleted,
        Image: event.Image,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets,
        WillCallCity: event.WillCallCity,
        WillCallHours: event.WillCallHours,
        WillCallState: event.WillCallState,
        WillCallAddress: event.WillCallAddress,
        WillCallCountry: event.WillCallCountry,
        WillZipCode: event.WillZipCode,
        WillCallPhone: event.WillCallPhone,
        WillCallComment: event.WillCallComment,
      });
      callback();
    } catch (error) {
      console.log("Error while deleting event " + error);
    }
  };

  const completeMove = () => {
    const currentUser = getBasicInfo().GUID;

    selectedEvents.forEach(async (selectedEvent) => {
      const {
        City,
        Country,
        Date,
        EventType,
        LastSync,
        Latitude,
        Longitude,
        Name,
        Notes,
        State,
        Status,
        VenueGUID,
        GUID,
      } = selectedEvent;

      const eventToMove = {
        City,
        Country,
        CreatedUserGUID: currentUser,
        Date,
        EventCapacity: "",
        EventType,
        LastSync,
        Latitude,
        Longitude,
        Name,
        Notes,
        OrganizationGUID: selectedOrg.Org.GUID,
        State,
        Status,
        VenueGUID,
        VenueObj: {
          Address: selectedEvent?.Venue?.Address,
          AgeRestrictions: null,
          Capacity: selectedEvent?.Venue?.Capacity,
          City: selectedEvent?.Venue?.City,
          Country: selectedEvent?.Venue?.Country,
          Created: null,
          CreatedUserID: null,
          Deleted: selectedEvent?.Venue?.Deleted,
          DeletedUserID: null,
          Events: [],
          GUID: selectedEvent?.Venue?.GUID,
          GooglePlaceID: selectedEvent?.Venue?.GooglePlaceID,
          Image: selectedEvent?.Venue?.Image,
          Latitude: selectedEvent?.Venue?.Latitude,
          Longitude: selectedEvent?.Venue?.Longitude,
          Name: selectedEvent?.Venue?.Name,
          Organization: null,
          OrganizationGUID: selectedEvent?.Venue?.OrganizationGUID,
          ProductionLaborDetails: [],
          State: selectedEvent?.Venue?.State,
          Type: selectedEvent?.Venue?.Type,
          Updated: selectedEvent?.Venue?.Updated,
          UpdatedUserID: null,
          ZipCode: selectedEvent?.Venue?.ZipCode,
        },
      };

      const currentSchedule = await db
        .table("schedules")
        .where({ EventGUID: GUID })
        .toArray();

      const flights = await db
        .table("flights")
        .where({ EventGUID: GUID })
        .toArray();

      const hotels = await db
        .table("hotels")
        .where({ EventGUID: GUID })
        .toArray();

      const grounds = await db
        .table("grounds")
        .where({ EventGUID: GUID })
        .toArray();

      const contacts = await db
        .table("contacts")
        .where({ EventGUID: GUID })
        .toArray();

      const attachments = await db
        .table("attachments")
        .where({ EventGUID: GUID })
        .toArray();

      const notes = await db
        .table("notes")
        .where({ EventGUID: GUID })
        .toArray();

      const todos = await db
        .table("todos")
        .where({ EventGUID: GUID })
        .toArray();

      dispatch(
        addEvent(
          eventToMove,
          [],
          teams,
          (request) => {
            const newGUID = request.GUID;

            currentSchedule?.forEach((itinerary) => {
              createItinerary(itinerary, newGUID);
            });

            flights.length > 0 && createFlights(flights, newGUID);

            hotels.length > 0 &&
              hotels.forEach((hotel) => {
                createHotels(hotel, newGUID);
              });

            grounds.length > 0 &&
              grounds.forEach((transportation) => {
                createTransportation(transportation, newGUID);
              });

            contacts.length > 0 &&
              contacts.forEach((contact) => {
                createContact(contact, newGUID);
              });

            attachments.length > 0 &&
              attachments.forEach((attachment) => {
                createAttachment(attachment, newGUID);
              });

            notes.length > 0 &&
              notes.forEach((note) => {
                createNote(note, newGUID, selectedOrg.Org.GUID);
              });

            todos.length > 0 &&
              todos?.forEach((todo) => {
                if (
                  todo.Deleted === null &&
                  todo.EventGUID === selectedEvent.GUID
                )
                  createTodo(todo, GUID, selectedOrg.Org.GUID);
              });

            deleteEvent(selectedEvent, () => {});
          },
          EventType
        )
      );
    });

    ToastNotificationSuccess(1, "Event moved succesfully.");
  };

  const handleMove = async () => {
    const text = `Are you sure you want to move ${
      selectedEvents.length > 1
        ? `these ${selectedEvents.length} events?`
        : "this event?"
    } This action will delete selected events from your current workspace.`;

    if (!selectedEvents || !selectedOrg.isSelected)
      return setError(
        "Please ensure that you have selected at least one event and the destination workspace."
      );
    setIsLoading(true);
    setError("");

    Swal.fire({
      text,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        completeMove();
        toggle();
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }

      setIsLoading(false);
    });
  };

  return (
    <div className="move__event__container">
      <h4 className="font-weight-bold text-center border-bottom p-4">
        Move Event To Another Workspace
      </h4>
      <div className="move__event__select d-flex flex-column justify-content-between w-100 overflow-hidden flex-xl-row flex-xl-wrap">
        <div className="h-100 col-xl-8 p-0">
          <div className="d-flex justify-content-between w-100 border px-2 py-2 align-items-center">
            <span className="font-weight-bold w-100">
              Select Event To Be Moved
            </span>
            <input
              type="search"
              name="searchEvent"
              className="form-control border p-2 rounded"
              placeholder="Search Event"
              value={searchEvent}
              onChange={(e) => handleSearchEvent(e)}
            />
          </div>
          <div
            className="overflow-auto w-100 px-3"
            style={{ height: "calc(95% - 8px)" }}
          >
            {filterEvents.length === 0 ||
            tempFilterEvents === "No Event Found" ? (
              <div className="alert alert-info text-center mt-1 w-100">
                {"No Event Found"}
              </div>
            ) : tempFilterEvents.length > 0 ? (
              tempFilterEvents.map((event, index) => {
                return (
                  <div
                    className={`card my-1 shadow-sm d-flex flex-row align-items-center w-100 ${
                      event.isSelected ? "event-active" : ""
                    }`}
                    key={event.GUID}
                    onClick={() => handleSelectTempEvent(event, index)}
                    style={{ cursor: "pointer" }}
                  >
                    <button
                      className="btn btn-sm text-secondary d-flex align-center justify-content-center"
                      style={{ width: "25px", height: "25px" }}
                    >
                      <input
                        type="checkbox"
                        name="checkEvent"
                        checked={!!event.isSelected}
                      />
                    </button>
                    <Event event={event} noIcons noClickable />
                  </div>
                );
              })
            ) : (
              filterEvents.map((event, index) => {
                return (
                  <div
                    className={`card my-1 shadow-sm d-flex flex-row align-items-center ${
                      event.isSelected ? "event-active" : ""
                    }`}
                    key={event.GUID}
                    onClick={() => handleSelectEvent(event, index)}
                    style={{ cursor: "pointer" }}
                  >
                    <button
                      className="btn btn-sm text-secondary d-flex align-center justify-content-center"
                      style={{ width: "25px", height: "25px" }}
                    >
                      <input
                        type="checkbox"
                        name="checkEvent"
                        checked={!!event.isSelected}
                        onChange={() => {}}
                      />
                    </button>
                    <Event event={event} noIcons noClickable />
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="text-dark p-2 border-left col-xl-4">
          <span className="font-weight-bold">Select workspace</span>
          <div
            className="overflow-auto"
            style={{ maxHeight: "99%", height: "100%" }}
          >
            {organizations?.map((org) => {
              const imgURL =
                org?.Logo?.length > 40
                  ? org.Logo
                  : `https://service.plnify.com/files/${org.Logo}?w=25&h=25&mode=crop&autorotate=true`;

              return (
                <div
                  className={`card my-1 shadow-sm border flex-row align-items-center px-2 ${
                    selectedOrg.isSelected &&
                    selectedOrg?.Org?.GUID === org.GUID
                      ? "event-active"
                      : ""
                  }`}
                  style={{ height: "3.175rem", cursor: "pointer" }}
                  onClick={() => handleSelectOrg(org)}
                  key={org.GUID}
                >
                  <img
                    src={imgURL}
                    className="rounded-pill mr-2"
                    style={{ width: "30px" }}
                  />
                  <span
                    className={`${
                      selectedOrg.isSelected &&
                      selectedOrg?.Org?.GUID === org.GUID
                        ? "font-weight-bold"
                        : "text-dark"
                    }`}
                  >
                    {org.Name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column w-100 border bg-white align-items-center justify-content-center"
        style={{ zIndex: 50, height: "50px" }}
      >
        {error && (
          <small
            className="alert alert-danger position-absolute"
            style={{ bottom: 40 }}
          >
            {error}
          </small>
        )}
        <div className="w-100 d-flex px-4 py-2 flex-wrap align-items-center justify-content-around">
          <button
            className="btn btn-danger p-2 m-1 border-0"
            style={{ width: "25%" }}
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            className="btn btn-secondary p-2 m-1 border-0"
            style={{ width: "25%" }}
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="btn btn-primary p-2 m-1 border-0"
            style={{ width: "25%" }}
            disabled={selectedEvents.length === 0}
            onClick={handleMove}
          >
            {isLoading && (
              <div
                className="spinner-border mr-2 text-white"
                style={{ width: "15px", height: "15px" }}
              ></div>
            )}
            Move
          </button>
        </div>

        {selectedEvents.length > 0 && !error && (
          <span
            className="text-center position-absolute alert alert-info"
            style={{ bottom: 40 }}
          >
            {selectedEvents.length > 1
              ? `${`You have selected ${selectedEvents.length} events ${
                  selectedOrg?.Org?.Name ? `to be moved to ` : ""
                }`} `
              : `${`You have selected ${selectedEvents.length} event ${
                  selectedOrg?.Org?.Name ? `to be moved to ` : ""
                }`} `}
            <em className="font-weight-bold text-primary">
              {selectedOrg?.Org?.Name}
            </em>
          </span>
        )}
      </div>
    </div>
  );
};
