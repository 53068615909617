import React from "react";

const AdminOrganizations = () => {
  return (
    <div className="ground-transparent">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head ground">
            <div className="text-left">
              <h1>Admin your organizations</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrganizations;
