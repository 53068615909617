import { createState } from '@hookstate/core';
import { FastForward } from 'mdi-material-ui';

const mainState = createState({
    Notifications:[],
    NotificationsUI:{
      updates: true,
      Loading: false
    },
    EventsUI:{
      ReloadFlight: false,
      ReloadGround: false,
      
      SelectedGround: null,
      ReloadHotel: false,
      SelectedHotel : null,

      ReloadAttachment:false,
      
    }
    
  });
  

  export default mainState;