import React, { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { addTicketsSchema } from "../../utils/validationsSchemas";
import { getBasicInfo } from "../../utils";
import { DropDownList, ModalComponent, SimpleLoader } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import CustomDropDrownMenu from "../common/CustomDropDrownMenu";
import AddEditFolder from "../../page/files/AddEditLink";
import Select from "react-select";

const TicketForm = ({
  mode,
  createTicket,
  event,
  loading,
  teams,
  continueAndAddNewRequest,
  newRequested,
  requestType,
  hideRequestBy,
  hidePickupLocation,
  isLoading,
}) => {
  useEffect(() => {
    !hideRequestBy && document.getElementById("first").focus();
  }, []);
  const user = getBasicInfo();

  const compareLabels = (a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  };

  teams = teams.sort((a, b) => compareLabels(a, b));

  return (
    <Formik
      enableReinitialize
      initialValues={{
        RequestedFor: "",
        RequestedForLastName: "",
        Passes: 1,
        PickupLocation: "Will Call",
        NotifyTo: "",
        Note: "",
        NoteConfirmed: "",
        Priority: null,
        PassType: mode ? "Ticket" : "Pass",
        GUID: "",
        EventGUID: event?.GUID,
        Seat: "",
        Row: "",
        Section: "",
        RequestAccountGUID: user?.GUID,
      }}
      onSubmit={(values, { resetForm }) => {
        createTicket(values);
        resetForm();
      }}
      validationSchema={addTicketsSchema}
    >
      {(propsFormik) => {
        return (
          <Form className="mt-3">
            <div className="event-inside">
              <div className="event-content">
                {!hideRequestBy && (
                  <div className="row">
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0 pr-2"
                      label={
                        <FormattedMessage
                          id="requestedBy"
                          defaultMessage="Requested By"
                        />
                      }
                    >
                      <Select
                        placeholder={
                          propsFormik.values.RequestAccountGUID
                            ? teams.find(
                                (m) =>
                                  m.value ===
                                  propsFormik.values.RequestAccountGUID
                              ).label
                            : "Select"
                        }
                        id="first"
                        className="form-control border-bottom no-outline"
                        isSearchable={true}
                        options={teams}
                        onChange={(value) => {
                          propsFormik.setFieldValue(
                            "RequestAccountGUID",
                            value.value
                          );
                        }}
                      />
                      {/* <option
                          selected="selected"
                          value={getBasicInfo()?.GUID}
                        >
                          {user?.Name} {user?.LastName} ({user?.Email})
                        </option>

                        {teams.map((t) => (
                          <option key={t?.value} value={t?.value}>
                            {t?.label}
                          </option>
                        ))} */}
                    </FormField>
                  </div>
                )}
                <div className="row ">
                  <FormField
                    additionalClasses="px-0 pr-2"
                    fullWidth={false}
                    label={
                      <FormattedMessage
                        id="guestFirstName"
                        defaultMessage="Guest First Name"
                      />
                    }
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="RequestedFor"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="RequestedFor"
                      component="div"
                    />
                  </FormField>
                  <FormField
                    fullWidth={false}
                    label={
                      <FormattedMessage
                        id="guestLastName"
                        defaultMessage="Guest Last Name"
                      />
                    }
                    additionalClasses="px-0 pl-2"
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="RequestedForLastName"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="RequestedForLastName"
                      component="div"
                    />
                  </FormField>
                </div>
                <div className="row">
                  <FormField
                    fullWidth={false}
                    additionalClasses="px-0 pr-2"
                    label={
                      <FormattedMessage
                        id="typeRequest"
                        defaultMessage="Type of Request"
                      />
                    }
                  >
                    {requestType ? (
                      <Field
                        className="form-control"
                        style={{ paddingBottom: "0.95rem " }}
                        as="select"
                        name="PassType"
                      >
                        <option value="Ticket">Ticket</option>
                        <option value="Pass">Pass</option>
                      </Field>
                    ) : mode ? (
                      <Field
                        className="form-control"
                        type="text"
                        name="PassType"
                        disabled
                      />
                    ) : (
                      <Field
                        className="form-control"
                        style={{ paddingBottom: "0.75rem " }}
                        as="select"
                        name="PassType"
                      >
                        <option value="All Access">All Access</option>
                        <option value="Backstage">Backstage</option>
                        <option value="Guest">Guest</option>
                        <option value="Media">Media</option>
                        <option value="MeetAndGreet">Meet and Greet</option>
                        <option value="Other">Other</option>
                        <option value="ticket">Ticket</option>
                        <option value="VIP">VIP</option>
                        <option value="WorkingPass">Working</option>
                        <option value="Pass">Pass</option>
                      </Field>
                    )}
                    <ErrorMessage
                      className="error-message"
                      name="PassType"
                      component="div"
                    />
                  </FormField>
                  <FormField
                    fullWidth={false}
                    additionalClasses="px-0 pl-2"
                    label={
                      <FormattedMessage id="amount" defaultMessage="Amount" />
                    }
                  >
                    <Field
                      className="form-control"
                      type="number"
                      min="0"
                      name="Passes"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Passes"
                    />
                  </FormField>
                </div>
                <div className="row ">
                  {!hidePickupLocation && (
                    <FormField
                      additionalClasses="px-0 pr-2"
                      fullWidth={true}
                      label={
                        <FormattedMessage
                          id="pickupLocation"
                          defaultMessage="Pickup Location"
                        />
                      }
                    >
                      <Field
                        className="form-control"
                        as="select"
                        name="PickupLocation"
                      >
                        <option value="null">-</option>
                        <option value="WillCall">Will Call</option>
                        <option value="In-Hand">In-Hand</option>
                      </Field>
                      <ErrorMessage
                        className="error-message"
                        name="PickupLocation"
                        component="div"
                      />
                    </FormField>
                  )}
                  <FormField
                    fullWidth={false}
                    label={
                      <FormattedMessage
                        id="notifyTo"
                        defaultMessage="Notify To"
                      />
                    }
                    additionalClasses="px-0 pl-2 d-none"
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="NotifyTo"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="NotifyTo"
                      component="div"
                    />
                  </FormField>
                </div>
                {/* {propsFormik.values.PassType !=="Ticket"? null : ( */}
                <div className="row shrinker d-none">
                  <FormField
                    additionalClasses="col-md-4 px-0 pl-2"
                    fullWidth={false}
                    label={
                      <FormattedMessage id="Section" defaultMessage="Section" />
                    }
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="Section"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="Section"
                      component="div"
                    />
                  </FormField>
                  <FormField
                    additionalClasses="col-md-4 px-0 pr-2"
                    fullWidth={false}
                    label={<FormattedMessage id="row" defaultMessage="Row" />}
                  >
                    <Field className="form-control" type="text" name="Row" />
                    <ErrorMessage
                      className="error-message"
                      name="Row"
                      component="div"
                    />
                  </FormField>
                  <FormField
                    additionalClasses="col-md-4 px-0 pl-2"
                    fullWidth={false}
                    label={<FormattedMessage id="Seat" defaultMessage="Seat" />}
                  >
                    <Field className="form-control" type="text" name="Seat" />
                    <ErrorMessage
                      className="error-message"
                      name="Seat"
                      component="div"
                    />
                  </FormField>
                </div>
                {/* )} */}

                <FormField fullWidth={true} label="Note">
                  <Field className="form-control" type="text" name="Note" />
                  <ErrorMessage
                    className="error-message"
                    component="div"
                    name={<FormattedMessage id="notes" defaultMessage="Note" />}
                  />
                </FormField>

                <FormField fullWidth={true} label="Admin Note">
                  <Field
                    className="form-control"
                    type="text"
                    name="NoteConfirmed"
                  />
                  <ErrorMessage
                    className="error-message"
                    component="div"
                    name="NoteConfirmed"
                  />
                </FormField>
              </div>
              <div className="py-3">
                {loading ? (
                  <SimpleLoader loading={loading} />
                ) : (
                  <div className="row mx-0">
                    {newRequested || requestType ? null : (
                      <div className="col-6 pl-0">
                        <button
                          disabled={!propsFormik.isValid}
                          onClick={() => {
                            propsFormik.validateForm();
                            if (propsFormik.isValid) {
                              continueAndAddNewRequest(propsFormik.values, () =>
                                propsFormik.setValues({
                                  ...propsFormik.values,
                                  PassType: mode ? "Pass" : "Ticket",
                                })
                              );
                            }
                          }}
                          type="button"
                          className="btn btn-secondary btn-block"
                        >
                          {mode ? "Add Passes" : "Add Ticket"}
                        </button>
                      </div>
                    )}

                    <div
                      className={`${
                        newRequested || requestType
                          ? "col-12 px-0"
                          : "col-6 pr-0"
                      } `}
                    >
                      <button
                        disabled={
                          propsFormik.isValid !== undefined &&
                          !propsFormik.isValid
                        }
                        type="submit"
                        className="btn primary block font-weight-bold  btn-block d-block align-items-center"
                      >
                        {isLoading && (
                          <span
                            className="spinner-border mr-2 "
                            style={{ width: "20px", height: "20px" }}
                          ></span>
                        )}
                        {<FormattedMessage id="done" defaultMessage="Done" />}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TicketForm;
