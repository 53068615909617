import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
// import FormField from "../../form/formField";
import api from "../../common/api";
import { getBasicInfo } from "../../utils";
import { ToastNotificationSuccess } from "../common";
import Preferences from "./forms/Preferences";
import RewardsField from "./forms/RewardsFields";
import TravelDetails from "./forms/TravelDetails";

const TravelProfile = ({ onlyRead, ...props }) => {
  const [userInfo, setUserInfo] = useState();
  const [ActiveArea, setActiveArea] = useState("Rewards");

  const [Data, setData] = useState({});
  const basicInfoGUID = getBasicInfo();

  const handleSubmit = async (values) => {
    // console.log("fuera",values)
    if (ActiveArea === "Rewards") {
      const request = await api.post(`/UserTravelInfo/AddEdit`, {
        ...Data,
        ...values,
      });
      if (request.statusText === "OK") {
        ToastNotificationSuccess(1, "Succesfully Updated");
      }
    }

    if (ActiveArea === "TravelDetails") {
      console.log({ ActiveArea, values });

      // const request = await api.post(`/UserPassport/AddEdit`, {
      //   ...Data,
      //   ...values,
      // });
      // if (request.statusText === "OK") {
      //   ToastNotificationSuccess(1, "Succesfully Updated");
      // }
    }

    if (ActiveArea === "Preferences") {
      const request = await api.post(`/UserPreferences/AddEdit`, {
        ...Data,
        ...values,
      });
      if (request.statusText === "OK") {
        ToastNotificationSuccess(1, "Succesfully Updated");
      }
    }
  };
  const loadData = async () => {
    if (ActiveArea === "Rewards") {
      // console.log("entre rew")

      const remoteData = await api.get(
        `/UserTravelInfo/GetInfo?AccountGUID=` + basicInfoGUID.GUID
      );
      setData(remoteData?.data.Response);
    }
    if (ActiveArea === "TravelDetails") {
      const remoteData = await api.get(
        `/UserPassport/GetInfo?AccountGUID=` + basicInfoGUID.GUID
      );
      setData(remoteData?.data.Response);
      // console.log(remoteData)
    }
    if (ActiveArea === "Preferences") {
      //  console.log("entre pre")
      const remoteData = await api.get(
        `/UserPreferences/GetInfo?AccountGUID=` + basicInfoGUID.GUID
      );
      setData(remoteData?.data.Response);
    }
  };

  useEffect(() => {
    loadData();
    setUserInfo(basicInfoGUID);
  }, []);
  useEffect(() => {
    loadData();
  }, [ActiveArea]);

  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">
        <Formik
          initialValues={Data ? Data : null}
          enableReinitialize
          onSubmit={(values) => handleSubmit(values)}
        >
          {(propsFormik) => (
            <Form autoComplete="off" className="pt-1 row mx-0">
              <div className="col-12 mb-2">
                <button
                  className={
                    ActiveArea === "Rewards"
                      ? "btn mx-2 btn-primary"
                      : "btn mx-2"
                  }
                  type="button"
                  onClick={() => setActiveArea("Rewards")}
                >
                  Reward Programs
                </button>
                <button
                  className={
                    ActiveArea === "TravelDetails"
                      ? "btn mx-2 btn-primary"
                      : "btn mx-2"
                  }
                  type="button"
                  onClick={() => setActiveArea("TravelDetails")}
                >
                  Travel Details
                </button>
                <button
                  className={
                    ActiveArea === "Preferences"
                      ? "btn mx-2 btn-primary"
                      : "btn mx-2"
                  }
                  type="button"
                  onClick={() => setActiveArea("Preferences")}
                >
                  Preferences
                </button>
              </div>
              {ActiveArea === "Rewards" ? (
                <RewardsField userInfo={userInfo} readOnly={onlyRead} />
              ) : null}
              {ActiveArea === "TravelDetails" ? (
                <TravelDetails
                  propsFormik={propsFormik}
                  userInfo={userInfo}
                  readOnly={onlyRead}
                />
              ) : null}
              {ActiveArea === "Preferences" ? (
                <Preferences
                  propsFormik={propsFormik}
                  userInfo={userInfo}
                  readOnly={onlyRead}
                />
              ) : null}

              {!onlyRead && (
                <div className="col-12 text-right">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TravelProfile;
