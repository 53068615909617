import React, { useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { SimpleLoader } from "../common";
import {useIntl, FormattedMessage} from 'react-intl'

const BoxOffice = ({ venue, event, toggle, loading, editBoxOffice }) => {
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title"><FormattedMessage
                  id="Box Office"
                  defaultMessage="Box Office"/></h5>
              {event.Venue?
              <small className="text-muted">{event.Venue.Name}</small>:null}
            </div>
            <div
              className="close-icon d-none d-none"
              onClick={() => toggle()}
            ></div>
          </div>
          <Formik
            initialValues={{
              WillCallHours: event.WillCallHours,
              WillCallCity: event.WillCallCity
                ? event.WillCallCity
                : venue.City,
              WillCallState: event.WillCallState
                ? event.WillCallState
                : venue.State,
              WillCallAddress: event.WillCallAddress
                ? event.WillCallAddress
                : venue.Address,
              WillCallCountry: event.WillCallCountry
                ? event.WillCallCountry
                : venue.Country,
              WillCallZipCode: event.WillCallZipCode
                ? event.WillCallZipCode
                : venue.ZipCode,
              WillCallPhone: event.WillCallPhone,
              WillCallComment: event.WillCallComment,
            }}
            onSubmit={(values) => editBoxOffice(values)}
          >
            {(props) => (
              <Form className="mt-3">
                <div className="event-inside">
                  <div className="event-content">
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0"
                      label={<FormattedMessage
                        id="Address"
                        defaultMessage="Address"/>}
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="WillCallAddress"
                      />
                    </FormField>
                    <div className="row">
                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pr-2"
                        label={<FormattedMessage
                          id="city.signUp"
                          defaultMessage="City"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="WillCallCity"
                        />
                      </FormField>

                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pl-2"
                        label={<FormattedMessage
                          id="state"
                          defaultMessage="State"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="WillCallState"
                        />
                      </FormField>
                    </div>
                    <div className="row">
                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pr-2"
                        label={<FormattedMessage
                          id="zipCode"
                          defaultMessage="Zip Code"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="WillZipCode"
                        />
                      </FormField>

                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pl-2"
                        label={<FormattedMessage
                          id="country.signUp"
                          defaultMessage="Country"/>}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="WillCallCountry"
                        />
                      </FormField>
                    </div>
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0"
                      label={<FormattedMessage
                        id="Hours of Operation"
                        defaultMessage="Hours of Operation"/>}
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="WillCallHours"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0"
                      label={<FormattedMessage
                        id="Phone Number"
                        defaultMessage="Phone Number"/>}
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="WillCallPhone"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0"
                      label={<FormattedMessage
                        id="Comments"
                        defaultMessage="Comments"/>}
                    >
                      <Field
                        className="form-control"
                        as="textarea"
                        name="WillCallComment"
                      />
                    </FormField>
                  </div>
                  <div className="py-3">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                          <button
                            type="submit"
                            className="btn btn-primary font-weight-bold  btn-block"
                         ><FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BoxOffice;
