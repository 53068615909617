import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function editVehicle(vehicle, success) {
  const url = `/Grounds/AddEditVehicleBatch`;
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_VEHICLE,
    });
    try {
      const request = await api.post(url, [vehicle]);
      dispatch({
        type: constant.EDITED_VEHICLE,
        data: request.data.Response,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.EDITING_VEHICLE,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_VEHICLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_VEHICLE: {
      return {
        ...state,
        loading: false,
        grounds: state.grounds.map((g) => {
          if (g.GUID === action.data[0].GroundGUID) {
            return { ...g, GroundVehicles: action.data };
          }

          return g;
        }),
      };
    }
    case constant.EDITING_VEHICLE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
