
import React, { useEffect, useRef, useState } from 'react';
import { getBasicInfo, getOrganization } from "../../utils";

import db from '../../common/db';
import { sortArray } from '../../common/helpers';
import { Event } from "../event/event";
import { Scrollbars } from "react-custom-scrollbars";
import HotelItem from '../hotel/hotelItem';
import * as actions from "../../page/event/redux/actions";
import API from '../../common/api';
import { useIntl, FormattedMessage } from 'react-intl'

//Object to receive
//Item = the object to work with
//Type = the type of object passed, helpful for identify how we can work the items

const MoveCopyToAnotherItem = (props) => {
    const [state, setState] = useState({
        UI: {
            Current: props.Item,
            ShareViaEmail: false,
            ShareViaLink: false,
        },
        AccountGUID: ""
    });
    let containerScroller = useRef();

    const [localEvents, setLocalEvents] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(props.originEvent);

    let scrollNearTo = (records) => {
        let element = containerScroller.current;
        let elementEstimatedHeight = 58;
        //find first index of dates in the array of events
        if (records.length === 0) {
            // console.log("we don't have the local events yet")
            return;
        }
        let theIndex = records.findIndex((e) => e.GUID === props.originEvent.GUID)
        //console.log("we found the event", selectedEvent, "Position", theIndex, "on the collection", records)

        if (theIndex > 0) {
            //check if we have more than 10 events ahead
            if (records.length - theIndex > 10) {
                element.scrollTop(elementEstimatedHeight * (theIndex + 10));
            } else if (theIndex - 5 > 0) {
                //check if we have 10 events below so we can try to center
                element.scrollTop(elementEstimatedHeight * (theIndex + 5));

            } else {
                element.scrollTop(elementEstimatedHeight * theIndex);
            }

        }

    }

    const bindData = async () => {
        //Load events locally
        let currentOrganization = getOrganization();

        let localEvents = await db.table("events").where({ OrganizationGUID: currentOrganization.GUID }).toArray();

        if (localEvents?.length > 0) {
            localEvents = localEvents.filter(x => x.Deleted === null && x.Date !== null);
            localEvents = sortArray(localEvents, "Date")
            setLocalEvents(localEvents);
            //  console.log("local events found", localEvents);
            //navigating 
            setTimeout(() => {
                scrollNearTo(localEvents);
            }, 1000);


            if (props.section === "hotel") {
                await bindDataHotel(selectedEvent);
            }
        }
    }
    const bindDataHotel = async (obj) => {
        //console.log("hotel", selectedEvent.GUID, obj.GUID);
        let localHotels = await db.table("hotels").where({ EventGUID: obj.GUID }).toArray();
        localHotels = localHotels.filter(x => x.Deleted === null);
        //debugger;
        console.log("HOTELS ===", localHotels, "EVENTS", obj);
        setHotels(localHotels);
    }

    const bindDataHotelRemote = async obj => {
        const request = await API.get("/Hotels/HotelForEvent", {
            params: { EventGUID: obj.GUID },
        });
        let cleanedHotel = request.data.response;

        await db.table("hotels").bulkPut(cleanedHotel);
        await bindDataHotel(obj);
    }


    const pickTargetItem = item => {
        setSelectedTarget(item);
    }
    const handleSubmit = async () => {
        await API.get("/Hotels/MoveUsers?CurrentHotelGUID=" + props.selectedItem.GUID + "&TargetHotelGUID=" + selectedTarget.GUID);

        actions.getHotel(props.originEvent.GUID);
        props.toggle();
    }

    const selectCurrentEvent = async opt => {

        setSelectedEvent(opt);
        if (props.section === "hotel") {
            setHotels([]);

            await bindDataHotel(opt);
            await bindDataHotelRemote(opt);
        }
        setSelectedTarget(null);

    }


    useEffect(() => {

        var basicInfo = getBasicInfo();
        setState({
            ...state,
            AccountGUID: basicInfo.GUID
        });

        bindData();

    }, []);


    return (<div className='organization-transparent'>

        <div className="event-page" style={{ overflow: "revert" }}>
            <div className="event-expennd-section " style={{ width: "70vw" }}>
                <div className="text-head">
                    <div className="text-left">
                        <h1 className="title"><FormattedMessage
                            id="export"
                            defaultMessage="Export" /></h1>
                    </div>
                </div>
                <div className="body text-dark p-2">

                    <div className="row">
                        <div className="col-6 px-0">
                            {/* Events */}

                            <div
                                style={{ height: "calc(100vh - 125px)" }}
                            >
                                <small>
                                    <b>1.<FormattedMessage
                                        id="selectTheEvent" /></b>
                                </small>
                                <br />
                                <Scrollbars style={{ width: "100%", height: "calc(100%)" }} ref={containerScroller}>
                                    {localEvents &&
                                        localEvents.map((event) => {
                                            // let theDate = new Date(event.Date);
                                            let classforEvent = props.originEvent && event.GUID === props.originEvent.GUID ? "active" : "";

                                            return (
                                                <div className={"card mx-4 my-1 shadow-sm " + classforEvent} key={event.GUID} id={event.GUID}>
                                                    <div className="card-body p-0">
                                                        <Event clickable
                                                            noClickable
                                                            event={event}
                                                            toggle={props.toggle}

                                                            selectOnly
                                                            setCurrentEvent={selectCurrentEvent}
                                                            isSelected={selectedEvent.GUID === event.GUID}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        )}
                                </Scrollbars>
                            </div>
                        </div>
                        <div className='col-6'>
                            {/*Records*/}
                            <div
                                style={{ height: "calc(100vh - 125px)" }}
                            >
                                <small><b>2.<FormattedMessage
                                    id="selectWhereCopy"
                                    defaultMessage="Select where to copy" /></b>
                                </small>
                                <br />
                                <Scrollbars style={{ width: "100%", height: "calc(100%)" }} >
                                    <div className='card shadow-sm my-1'>
                                        <div className='card-body p-0'>
                                            {props.section === "hotel" ?
                                                hotels && hotels.length > 0 && hotels.map(hotel => {
                                                    return (

                                                        <HotelItem hotel={hotel} selectOnly
                                                            pickTargetItem={pickTargetItem}
                                                            selectedItem={selectedTarget}
                                                        />)
                                                })

                                                : null}
                                        </div></div>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <hr />
                        <button className="btn btn-primary"
                            onClick={() => handleSubmit()}
                            disabled={selectedTarget === null}
                        ><FormattedMessage
                                id="done"
                                defaultMessage="Done" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default MoveCopyToAnotherItem;