import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid";
import {
  TeamsAndMembersAvailableOnly,
  getBasicInfo,
  getOrganization,
} from "../../utils";
import {
  IframeGoogleDoc,
  ModalComponent,
  SimpleLoader,
  ToastNotificationSuccess,
} from "../common";
import MailingListModal from "../common/mailingListModal";

import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountBox,
  Airplane,
  Attachment,
  Bus,
  ClipboardList,
  Domain,
  FolderImage,
  MessageProcessingOutline,
  PlusCircleOutline,
  SmartCardOutline,
} from "mdi-material-ui";
import { useContextMenu } from "react-contexify";
import Draggable from "react-draggable";
import Swal from "sweetalert2";
import api, { apiService } from "../../common/api";
import db from "../../common/db";
import shareApi from "../../common/shareApi";
import { fileIcon, formatFileSize } from "../../page/files/helper";
import { handleCloseEmail } from "../../utils/closeMailModal";
import { ContextMenu } from "../common/ContextMenu";
import DragAndDrop from "../common/DragAndDrop";
import EmailExportComponent from "../common/EmailExport/EmailExportComponent";
import LazyLoadImage from "../common/LazyLoadImage";
import MailDeleted from "../common/MailDeleted";
import MailDrafts from "../common/MailDraft";
import MailHistory from "../common/MailHistory";
import MailSideBar from "../common/MailSideBar";
import RichTextComponent from "../common/RichTextEditorComponent";
import { IMAGES } from "../../constant";

const EmailModal = (props) => {
  const sendingEmail = useSelector((state) => state.common.sendingEmail);
  const event = useSelector((state) => state.event.event);
  const teams = useSelector((state) => state.organizations.teams);
  const [localPDFFiles, setLocalPDFFiles] = useState(null);
  const [localPDFurl, setLocalPDFurl] = useState(null);

  const currentOrganization = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHTMLLoading, setHTMLIsLoading] = useState(false);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [state, setState] = useState({
    loadingContent: false,
    mailingList: false,
    emails:
      props.emails !== undefined && props?.emails?.length !== 0
        ? props.emails
        : [],
    subject:
      props.subject !== undefined && props.subject !== null
        ? props.subject
        : "",
    attachments: [],
    bodyOfEmail:
      props.content !== undefined && props.content !== null
        ? props.content
        : "",
    options: [],
    showMailHistory: false,
    showMailDraft: false,
    showMailDeleted: false,
    showExportEmail: true,
    ExportType: "email",
  });

  const setContent = () => {
    setState((prev) => ({ ...prev, bodyOfEmail: props.content }));
  };

  const setTodoContent = async () => {
    console.log({ todoContent: props.content });
    let link = "";
    if (props?.content?.link) {
      link = `${window.location.protocol}//${window.location.host}/c/${props?.content?.link}`;
    } else {
      const { response } = (
        await shareApi.post("collab/GenerateLink", props.content)
      ).data;
      link = `${window.location.protocol}//${window.location.host}/c/${response.GUID}`;
    }

    let tableRows = "";

    const priorityNum = [null, "Low", "Medium", "High"];

    //     <td
    //     style="border: 1px solid #dddddd;
    // text-align: left;
    // padding: 8px;"
    //   >
    //   ${
    //     priorityNum[todo?.Priority] === null
    //       ? ""
    //       : priorityNum[todo?.Priority]
    //   }
    //   </td>
    props?.content?.data?.forEach(
      (todo) =>
        (tableRows += `
        <tr>
          <td
            style="  border: 1px solid #dddddd;
      text-align: left;
      width: 20%;
      padding: 8px;"
          >
            ${todo.Name}
          </td>
          <td
            style="border: 1px solid #dddddd;
      text-align: left;
      width: 10%;
      padding: 8px;"
          >
          ${todo?.Note}
          </td>
          <td
            style="border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;"
          >
          ${todo?.Status === 0 ? "Pending" : "Completed"}
          </td>
        </tr>`)
    );

    const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
    const logo = `https://billing.plnify.com/icons/100x100-icon.png`;

    // <th style="border: 1px solid #dddddd;
    // text-align: left;
    // padding: 8px;">Priority</th>
    const bodyTable = `<table style="margin:15px auto; width:85%">
    <tr>
      <th style="border: 1px solid #dddddd;
      text-align: left;
      width: 20%;
      padding: 8px;">Name</th>
      <th style="border: 1px solid #dddddd;
      text-align: left;
      padding: 8px; width: 10%;">Description</th>
    
      <th style="border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;">Status</th>
    </tr>
    ${tableRows}
  </table>`;
    const body = `<div style="display:flex; flex-direction:column; align-items:center"><img src="${logo}" alt="Plnify Logo" width="100px" style="width:100px"/><h3>${
      props?.content?.user?.Name + " " + props?.content?.user?.LastName
    } has invited you to collab on the following To-Dos:</h3><div style="width:100%;">${bodyTable}</div><a href="${link}" style="cursor:pointer; font-weight:bold; text-decoration:none; margin:10px 0">Go to Collab</a><p>You can also scan this QRcode using your phone</p><img src=${qrCode} alt="QRCode" style="width:100px"/></div>`;

    setState((prev) => ({
      ...prev,
      loadingContent: false,
      bodyOfEmail: body,
      subject:
        "You have been invited to collab on " +
        props?.subject +
        " on " +
        moment().format("MMMM DD, YYYY"),
    }));
  };

  const setGuestListContent = async () => {
    let link = "";
    if (props?.content?.link) {
      link = `${window.location.protocol}//${window.location.host}/c/${props?.content?.link}`;
    } else {
      const { response } = (
        await shareApi.post("collab/GenerateLink", props.content)
      ).data;
      link = `https://beta.plnify.com/c/${response.GUID}`;
    }

    // const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
    // <p>You can also scan this QRcode using your phone</p><img src=${qrCode} alt="QRCode" style="width:100px"/></div>
    const logo = `https://billing.plnify.com/icons/100x100-icon.png`;

    const body = `<div style="display:flex; flex-direction:column; align-items:center"><img src="${logo}" alt="Plnify Logo" width="100px" style="width:100px"/><h3>${
      props?.content?.user?.Name + " " + props?.content?.user?.LastName
    } has invited you to create tickets using the link below:</h3><a href="${link}" style="cursor:pointer; font-weight:bold; text-decoration:none; margin: 0">Create Tickets</a>`;

    setState((prev) => ({
      ...prev,
      loadingContent: false,
      bodyOfEmail: body,
      subject:
        `${
          props?.content?.user?.Name + " " + props?.content?.user?.LastName
        } has invited you to request tickets on ` +
        moment().format("MMMM DD, YYYY"),
    }));
  };

  let ExportItems = [
    {
      icon: "fa-file-invoice",
      title: "Header",
    },
    {
      icon: "fa-clipboard-list",
      title: "Itineraries",
    },
    {
      icon: () => <Airplane />,
      title: "Flights",
    },
    {
      icon: () => <Domain />,
      title: "Hotels",
    },
    {
      icon: "fa-bus",
      title: "Transportation",
    },
    {
      icon: "fa-address-book",
      title: "Contacts",
    },
    {
      icon: "fa-paperclip",
      title: "Attachments",
    },
    {
      icon: "fa-calendar-alt",
      title: "UpcomingEvents",
    },
  ];

  const SectionParamsInit = {
    Position: [
      {
        order: 1,
        section: "Header",
        align: null,
      },
      {
        order: 2,
        section: "Itineraries",
        align: null,
      },
      {
        order: 3,
        section: "Flights",
        align: null,
      },
      {
        order: 4,
        section: "Hotels",
        align: null,
      },
      {
        order: 5,
        section: "Transportation",
        align: null,
      },
      {
        order: 6,
        section: "Contacts",
        align: null,
      },
      {
        order: 7,
        section: "Attachments",
        align: null,
      },
      {
        order: 8,
        section: "UpcomingEvents",
        align: null,
      },
    ],
    Itineraries: [],
    ItinerariesShow: true,
    ItinerariesStyles: null,
    Flights: [],
    FlightsShow: true,
    FlightsStyles: null,
    Hotels: [],
    HotelsShow: true,
    HotelsStyles: null,
    Transportation: [],
    TransportationShow: true,
    TransportationStyles: null,
    Contacts: [],
    ContactsShow: true,
    ContactsStyles: null,
    Attachments: [],
    AttachmentsShow: true,
    AttachmentsStyles: null,
    Header: [],
    HeaderShow: true,
    HeaderStyles: null,
    UpcomingEvents: [],
    UpcomingEventsShow: true,
    UpcomingEventsStyles: null,
  };

  const ApiUrlBySection = {
    // Header: "/Event/GetEventHeader",
    Itineraries: "/ScheduleMaster/ScheduleForEvent",
    Flights: "/Flights/FlightsForEvent",
    Hotels: "/Hotels/HotelForEvent",
    Transportation: "/Grounds/GroundForEvent",
    Contacts: "/ProductionVenueContact/GetProductionVenueContact",
    Attachments: "/Attachment/Attachments",
    // Upcoming: "/Event/GetUpcomingEvents",
  };

  const fetchLocalHeaderData = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          section: "Header",
          data: [
            {
              GUID: "logo",
              Name: "Logo",
            },
            {
              GUID: "venue",
              Name: "Venue",
            },
            {
              GUID: "event",
              Name: "Event",
            },
            {
              GUID: "notes",
              Name: "Notes",
            },
          ],
        });
      }, 500);
    });
  };

  const fetchSectionData = async (title) => {
    const dataInResponseTitles = ["Hotels", "Transportation"];
    const fetchFromApi = ["Hotels", "Contacts"];
    const apiToUse = fetchFromApi.includes(title) ? api : apiService;

    const { data } =
      title == "Header"
        ? await fetchLocalHeaderData()
        : ApiUrlBySection[title]
        ? await apiToUse.get(
            `${ApiUrlBySection[title]}?EventGUID=${
              props.event ? props.event.GUID : event.GUID
            }`
          )
        : [];

    let stateData =
      title === "Flights"
        ? data?.Response?.reduce((acc, current) => {
            const x = acc.find(
              (item) => item.Connection === current.Connection
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
        : dataInResponseTitles.includes(title)
        ? data.response
        : data;

    if (title === "Itineraries") {
      stateData = stateData?.map((data) => ({
        ...data,
        Schedules: data?.Schedules?.filter((item) => !item.Deleted).sort(
          (a, b) => new Date(a.Date) - new Date(b.Date)
        ),
      }));
    }

    return stateData?.filter((item) => !item.Deleted);
  };

  const getContentForWeb = () => {
    setState((prev) => ({ ...prev, loadingContent: true }));
    const url = `/Events/ExportCalendar?OrganizationGUID=${currentOrganization.GUID}`;

    setLocalPDFurl({ PDFurl: url, isApi: true });

    api.get(url).then((res) => {
      setState((prev) => ({
        ...prev,
        loadingContent: false,
        bodyOfEmail: res.data,
        subject:
          currentOrganization.Name +
          " - Calendar (" +
          moment().format("MMMM DD, YYYY") +
          " Update)",
      }));
    });
  };

  const getDailySheet = async () => {
    const { itinerary, eventGUID } = props;
    let url = itinerary?.QRCode
      ? `/Events/ExportDailySheet?ScheduleGUID=${itinerary.GUID}?QRCode=true`
      : `/Events/ExportDailySheet?ScheduleGUID=${itinerary.GUID}`;

    const SectionData = await Promise.all(
      ExportItems.map(async (item) => {
        const data = await fetchSectionData(item.title);
        return item.title === "UpcomingEvents"
          ? {
              [item.title]: [],
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            }
          : item.title === "Itineraries"
          ? {
              [item.title]: data
                .map((data) => ({
                  GUID: data.GUID,
                  Name: data.Name,
                  Activities: data?.Schedules?.filter((item) => !item.Deleted)
                    .sort((a, b) => new Date(a.Date) - new Date(b.Date))
                    .map((item) => item.GUID),
                }))
                .filter((item) => item.GUID === itinerary.GUID),
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            }
          : {
              [item.title]: data.map((data) =>
                item.title === "Flights" ? data.Connection : data.GUID
              ),
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            };
      })
    );

    const combinedData = SectionData.reduce((acc, item) => {
      return { ...acc, ...item };
    }, {});

    const dailySheetParams = {
      ...SectionParamsInit,
      ...combinedData,
      Attachments: [],
      AttachmentsShow: false,
      AttachmentsStyles: null,
      Contacts: [],
      ContactsShow: false,
      ContactsStyles: null,
      Flights: [],
      FlightsShow: false,
      FlightsStyles: null,
      Transportation: [],
      TransportationShow: false,
      TransportationStyles: null,
    };

    console.log({ dailySheetParams });

    const res = setState((prev) => ({ ...prev, loadingContent: true }));

    setLocalPDFurl({ PDFurl: url, isApi: false });
    let request = await apiService.post(
      `Export/Details?EventGUID=${
        props.event ? props.event.GUID : event.GUID
      }&AlternativeView=true`,
      {
        ...dailySheetParams,
      },
      {}
    );
    setState((prev) => ({
      ...prev,
      bodyOfEmail: request.data,
      loadingContent: false,
    }));
  };

  const getHotelRoomlist = async () => {
    const { hotel } = props;
    setState((prev) => ({ ...prev, loadingContent: true }));
    const url = `/hotels/ExportRoomList?HotelGUID=${hotel.GUID}`;
    setLocalPDFurl({ PDFurl: url, isApi: true });
    let response = await api.get(url);
    setState((prev) => ({
      ...prev,
      bodyOfEmail: response.data,
      loadingContent: false,
      subject: hotel.Name + " - Roomlist",
    }));
  };

  const getEventDetails = async () => {
    setState((prev) => ({ ...prev, loadingContent: true }));
    const url = `Export/Details?EventGUID=${
      props.event ? props.event.GUID : event.GUID
    }&AlternativeView=true`;

    const SectionData = await Promise.all(
      ExportItems.map(async (item) => {
        const data = await fetchSectionData(item.title);

        return item.title === "UpcomingEvents"
          ? {
              [item.title]: [],
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            }
          : item.title === "Itineraries"
          ? {
              [item.title]: data.map((data) => ({
                GUID: data.GUID,
                Name: data.Name,
                Activities: data?.Schedules?.filter((item) => !item.Deleted)
                  .sort((a, b) => new Date(a.Date) - new Date(b.Date))
                  .map((item) => item.GUID),
              })),
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            }
          : {
              [item.title]: data.map((data) =>
                item.title === "Flights" ? data.Connection : data.GUID
              ),
              [item.title + "Show"]: true,
              [item.title + "Styles"]: null,
            };
      })
    );

    const combinedData = SectionData.reduce((acc, item) => {
      return { ...acc, ...item };
    }, {});

    const res = setLocalPDFurl({ PDFurl: url, isApi: false });
    let response = await apiService.post(
      `Export/Details?EventGUID=${
        props.event ? props.event.GUID : event.GUID
      }&AlternativeView=true`,
      {
        ...SectionParamsInit,
        ...combinedData,
      },
      {}
    );
    setState((prev) => ({
      ...prev,
      bodyOfEmail: response.data,
      loadingContent: false,
    }));
  };

  const getGuestList = async () => {
    //http://localhost:55256/api/ticketspasses/generatemail?eventguid=811125a7-b98f-4cb9-b441-7031134f17ce
    const { selectedEvent } = props;
    setState((prev) => ({ ...prev, loadingContent: true }));
    const url = `/guestlist/generatemail?EventGUID=${
      selectedEvent.GUID
    }&AccountGUID=${getBasicInfo().GUID}&OrganizationGUID=${
      getOrganization().GUID
    }`;

    setLocalPDFurl({ PDFurl: url, isApi: false });
    let response = await apiService.get(url);
    setState((prev) => ({
      ...prev,
      bodyOfEmail: response.data,
      loadingContent: false,
    }));
  };

  useEffect(() => {
    const shareCollabActions = {
      guestList: setGuestListContent,
      todo: setTodoContent,
    };
    (async (option) => {
      let copy = {};
      switch (option) {
        case "MOREDETAILS":
          setContent();
          break;
        case "SHARECOLLAB":
          shareCollabActions[props.content?.SectionType]();
          break;
        case "CALENDAR":
          getContentForWeb();
          break;
        case "ITINERARIES":
          copy.subject =
            props.itinerary.Name +
            " " +
            moment(props.itinerary.Date).format("MMM DD, yyyy");
          await getDailySheet();
          break;
        case "HOTEL_ROOMLIST":
          copy.subject = props.hotel.Name;
          await getHotelRoomlist();
          break;
        case "EVENT_DETAILS":
          copy.subject =
            props.event.Name +
            " " +
            moment(props.event.Date).format("MMM DD, yyyy");
          await getEventDetails();
          break;
        case "GUESTLIST":
          copy.subject =
            props.selectedEvent.Name +
            " " +
            moment(props.selectedEvent.Date).format("MMM DD, yyyy") +
            " Guest list";
          await getGuestList();
          break;
        default:
          setContent();
          break;
      }
      setState((prev) => ({ ...prev, ...copy }));
    })(props.fromSection);
  }, []);

  const toggleMailHistory = () => {
    setState((prev) => ({ ...prev, showMailHistory: !prev.showMailHistory }));
  };

  const toggleMailDraft = () => {
    setState((prev) => ({ ...prev, showMailDraft: !prev.showMailDraft }));
  };

  const toggleMailDeleted = () => {
    setState((prev) => ({ ...prev, showMailDeleted: !prev.showMailDeleted }));
  };

  const toggleMailingList = () => {
    setState((prev) => ({
      ...prev,
      mailingList: !state.mailingList,
      showMailHistory: false,
    }));
  };

  const onChangeSubject = (value) => {
    setState((prev) => ({ ...prev, subject: value }));
  };

  const onAttachmentChange = ({ target }) => {
    const files = state.attachments || [];
    Object.values(target?.files).forEach((file) => {
      files.push(file);
    });
    setState((prev) => ({ ...prev, attachments: files }));
  };

  const setHTMLasPDFattachment = async () => {
    setIsLoading(true);
    const files = state.attachments || [];
    const { PDFurl, isApi } = localPDFurl;
    const exportEventDetailsURL = isApi
      ? `https://app.plnify.com/api${PDFurl}`
      : `https://service.plnify.com/api${PDFurl}`;
    try {
      const res = await apiService.get(
        `/GeneratePDF/FromURL?FromURL=${exportEventDetailsURL}`
      );
      const newFile = {
        lastModified: null,
        lastModifiedDate: new Date(),
        name: res?.data?.Response?.name,
        size: res?.data?.Response?.size,
        type: "application/pdf",
        webkitRelativePath: "",
        isPDF: true,
      };
      files.push(newFile);
      setState((prev) => ({ ...prev, attachments: files }));
      setLocalPDFFiles(res?.data?.Response?.name);
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "There was an error creating the PDF",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "yes" }),
      });
    }
    setIsLoading(false);
  };

  const handlePDFAttachment = (newFile, toggleExportModal) => {
    const files = state.attachments || [];
    files.push(newFile);
    setState((prev) => ({
      ...prev,
      subject: "",
      bodyOfEmail: "",
      attachments: files,
    }));
    setLocalPDFFiles(newFile.name);
    toggleExportModal();
  };

  const setHTMLasPDFattachmentFromEmail = async (onlyBody) => {
    setHTMLIsLoading(true);
    const files = state.attachments || [];
    const exportEmailBody =
      textEditorRef?.current?.element?.children?.[2]?.innerHTML || "";

    const result = await Swal.fire({
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      title: "Add your PDF Name",
      input: "text",
      inputLabel: "File Name",
      inputPlaceholder: "Enter file name",
      inputValue: state.subject,
      showCancelButton: true,
      confirmButtonText: "Create",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please add the file name";
        }
      },
    });

    if (result.isDismissed) return setHTMLIsLoading(false);
    const fileName = result?.value || state.subject;

    const postUrl = onlyBody
      ? `/GeneratePDF/FromHTML?OnlyBody=true&fileName=${fileName + ".pdf"}`
      : `/GeneratePDF/FromHTML?fileName=${fileName + ".pdf"}`;
    try {
      const res = await apiService.post(postUrl, {
        html: exportEmailBody,
      });
      const newFile = {
        lastModified: null,
        lastModifiedDate: new Date(),
        name: res?.data?.Response?.name,
        size: res?.data?.Response?.size,
        type: "application/pdf",
        webkitRelativePath: "",
        isPDF: true,
      };
      files.push(newFile);
      setState((prev) => ({ ...prev, attachments: files }));
      setLocalPDFFiles(res?.data?.Response?.name);
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "There was an error creating the PDF",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "yes" }),
      });
    }
    setHTMLIsLoading(false);
  };

  const onAttachmentDrop = (files) => {
    const currentFiles = state.attachments || [];
    Object.values(files).forEach((file) => {
      currentFiles.push(file);
    });
    setState((prev) => ({ ...prev, attachments: currentFiles }));
  };

  const handleAttachmentDelete = (index) => {
    const files = state.attachments || [];

    files.splice(index, 1);

    setState((prev) => ({ ...prev, attachments: files }));
  };

  const confirmedEmail = () => {
    props.toggle();
    Swal.fire({
      title: intl.formatMessage({ id: "Email sent!" }),
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
    });
  };

  const confirmeEmailCustom = () => {
    setIsSent(true);
  };

  const errorEmail = () => {
    Swal.fire({
      title: "Error",
      text: intl.formatMessage({
        id: "The email can't be delivered. Try again.",
      }),
      icon: "error",
      confirmButtonColor: "#3085d6",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
    });
  };

  const sendEmail = async () => {
    setIsSending(true);
    const basicInfo = getBasicInfo();
    const currentOrg = getOrganization();
    let emailsToSend = "";

    state.emails.forEach((item) => {
      if (item.value !== undefined && item.value.length > 0) {
        emailsToSend += (emailsToSend.length > 0 ? ";" : "") + item.value;
      } else if (item.customOption === true) {
        emailsToSend += (emailsToSend.length > 0 ? ";" : "") + item.label;
      } else {
        const text = item;
        const emailRegex =
          /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        const emailAddresses = text.match(emailRegex);
        emailsToSend += (emailsToSend.length > 0 ? ";" : "") + emailAddresses;
      }
    });
    if (emailsToSend.length === 0) {
      setIsSending(false);
      Swal.fire({
        title: "Error",
        text: intl.formatMessage({
          id: "You need to specify at least one To Address",
        }),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "yes" }),
      });
      return;
    }

    let supportInformation =
      "<hr/><br/>Current User: " + basicInfo.Email + "<br/>";
    supportInformation += "Current User ID: " + basicInfo.GUID + "<br/>";
    supportInformation += "URL: " + window.location + "<br/>";
    supportInformation +=
      "Current Organization: " +
      currentOrg.Name +
      " (" +
      currentOrg.GUID +
      ")" +
      "<br/>";
    supportInformation += moment().format();

    const email = {
      GUID: uuid.v4(),
      EmailAddress: emailsToSend,
      Title:
        state.subject +
        (props.fromSection === "SUPPORT" ? " " + basicInfo.Email : ""),
      SenderId: basicInfo.GUID,
      Content:
        state.bodyOfEmail +
        (props.fromSection === "SUPPORT" ? supportInformation : ""),
      success: () => confirmedEmail(),
      error: () => errorEmail(),
    };

    const url = "/Email/Send";

    try {
      const attachments =
        Array.from(state.attachments).filter(
          (attachment) => !attachment.isPDF
        ) || [];

      attachments &&
        (await Promise.all(
          attachments.map(async (attachment) => {
            attachment.path = await uploadFiles(attachment);
          })
        ));

      const uploadedFiles = attachments.map((attachment) => attachment.path);

      await apiService.post(url, {
        EmailAddress: email.EmailAddress,
        Title: email.Title,
        SenderId: email.SenderId,
        Content: email.Content,
        Attachments: attachments.length > 0 ? uploadedFiles.join(";") : null,
        PDFFile: localPDFFiles,
      });

      setTimeout(() => {
        // setIsSending(false);
        confirmeEmailCustom();
        // confirmedEmail();
      }, 3000);
    } catch (error) {
      setIsSending(false);
      Swal.fire({
        title: "Your Message Could Not Be Sent",
        text: "There was an error sending your message, Please try again.",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "ok" }),
      });
    } finally {
      const allElements = document.querySelectorAll(".e-dropdown-popup");
      const allInputEmails = document.querySelectorAll("input");
      allInputEmails?.forEach((input) => {
        if (input?.id?.includes("emailBodyEditor")) {
          input.remove();
        }
      });

      allElements?.forEach((element) => {
        element.remove();
      });
    }
  };

  async function uploadFiles(file) {
    const fileUrl = "file/upload";
    const newFile = {
      GUID: "",
      Name: file.name,
      Note: null,
      Path: file.name,
      UserGUID: getBasicInfo().GUID,
      ParentGUID: null,
      Type: "file",
    };

    let bodyData = new FormData();
    bodyData.append("path", file);

    const { data } = await apiService.post(fileUrl, bodyData);

    return await data?.Response;
  }
  const selectEmail = (arr, item) => {
    if (arr.includes(item)) {
      let selecteds = arr.filter((i) => i !== item);
      setState((prev) => ({ ...prev, emails: selecteds }));
    } else {
      let selecteds = [...arr, item];
      setState((prev) => ({ ...prev, emails: selecteds }));
    }
  };

  const setOptions = async () => {
    //Search teams locally
    let org = getOrganization();
    let table = await db
      .table("organization")
      .where({ GUID: org.GUID })
      .first();
    let availableTeams = TeamsAndMembersAvailableOnly(table.Roles);
    let emails = [];
    let allValues = "";

    //First the groups
    availableTeams.forEach((x) => {
      if (x.Name.trim().length > 0) {
        let membersAll = "";
        //Now we go each email
        x.Staffs.forEach((y) => {
          if (
            y.Active === true &&
            y.Accepted === true &&
            y.Deleted === null &&
            y.Account.Email.trim().length > 0
          ) {
            emails.push({
              id: y.Account.Email.trim(),
              label:
                y.Account.Name +
                " " +
                y.Account.LastName +
                " (" +
                y.Account.Email.trim() +
                ")",
              value: y.Account.Email.trim(),
            });
            membersAll +=
              (membersAll.length > 0 ? ";" : "") + y.Account.Email.trim();
            allValues +=
              (allValues.length > 0 ? ";" : "") + y.Account.Email.trim();
          }
        });

        emails.push({
          id: "group;" + x.Name,
          label: x.Name,
          value: membersAll,
        });
      }
    });
    emails.push({ id: "all", label: "All", value: allValues });
    setState((prev) => ({ ...prev, options: emails }));
  };

  const selectAllEmail = (arr, items) => {
    const emails = items
      .filter((item) => !item.Deleted)
      .filter((item) => item.Account.Email !== null)
      .filter((item) => item.Account.Email.length > 2)
      .map((item) => (item.Account.Email ? item.Account.Email : ""));

    const notDuplicates = !arr
      ? [...new Set([...emails])]
      : [...new Set([...arr, ...emails])];

    setState((prev) => ({ ...prev, emails: notDuplicates }));
  };

  const selectAll = (arr, items) => {
    const emails = items
      .map((item) =>
        item.Staffs.filter((item) => item.Account.Email !== null)
          .filter((item) => item.Account.Email.length > 2)
          .map((staff) => staff.Account.Email)
      )
      .flat();

    const notDuplicates = !arr
      ? [...new Set([...emails])]
      : [...new Set([...arr, ...emails])];

    setState((prev) => ({ ...prev, emails: notDuplicates }));
  };

  const selectedEmail = (record, draft) => {
    setState((prev) => ({
      ...prev,
      loadingContent: false,
      bodyOfEmail: record?.Message,
      subject: record?.Title,
      emails: record?.Receivers === "" ? [] : record?.Receivers?.split(";"),
      attachments: record?.attachments || [],
      draftGUID: record?.GUID,
    }));

    !draft && toggleMailHistory();
  };

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const onChange = (e) => {
    let files = e?.target?.files || e;
    const arrFiles = Array.from(files);

    try {
      // arrFiles.forEach((file) => {
      //   window.URL.createObjectURL(file);
      //   let attachment = {
      //     name: file.name,
      //     note: "",
      //     event: event.GUID,
      //     file: file,
      //   };
      //   dispatch(
      //     actions.addAttachment(
      //       event.GUID,
      //       attachment,
      //       () => {},
      //       () => dispatch(actions.getEvents(event.OrganizationGUID, user.GUID))
      //     )
      //   );
      // });
      ToastNotificationSuccess(1, "Attachment succesfully created");
    } catch (error) {
      Swal.fire("Oops...", "Something went wrong!", "error");
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const handleDrop = (file) => {
    onAttachmentDrop(file);
  };

  const { emails } = state;

  const [currentView, setCurrentView] = useState("");
  const [currentEmail, setCurrentEmail] = useState({ id: "", section: "" });
  const [showSideBar, setShowSideBar] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [draftEmails, setDraftEmails] = useState({
    Records: {
      drafts: [],
      trash: [],
    },
    UI: {
      Search: "",
      Quantity: 20,
      Page: 0,
    },
  });

  const requestRemote = async () => {
    let localUser = getBasicInfo().GUID;

    const emails = await db
      .table("mailDrafts")
      .where({ AccountGUID: localUser })
      .first();
    const currentEmails = emails?.drafts || [];

    setDraftEmails((prev) => ({
      ...prev,
      Records: {
        drafts:
          currentEmails
            .filter((email) => email.Deleted === null)
            .sort((a, b) => new Date(b.Date) - new Date(a.Date)) || [],
        trash:
          currentEmails
            .filter((email) => email.Deleted !== null)
            .sort((a, b) => new Date(b.Date) - new Date(a.Date)) || [],
      },
    }));
  };

  useEffect(() => {
    requestRemote();
  }, []);

  const textEditorRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [viewerModal, setViewerModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const { show, hideAll } = useContextMenu({
    id: "attachmentMenu",
  });

  const ContextMenuOptions = selectedAttachment?.isPDF
    ? [
        // {
        //   id: "export",
        //   type: "item",
        //   title: "Export File",
        //   actions: () => {},
        // },
        {
          id: "preview",
          type: "item",
          title: "Preview File",
          actions: () => {
            setViewerModal((prev) => !prev);
            hideAll();
          },
        },
        {
          id: "delete",
          type: "item",
          title: "Delete",
          destructive: true,
          actions: () => {
            handleAttachmentDelete(selectedAttachment.index);
            hideAll();
          },
        },
      ]
    : [
        // {
        //   id: "export",
        //   type: "item",
        //   title: "Export File",
        //   actions: () => {},
        // },
        // {
        //   id: "preview",
        //   type: "item",
        //   title: "Preview File",
        //   actions: () => {
        //     setViewerModal((prev) => !prev);
        //     hideAll();
        //   },
        // },
        {
          id: "delete",
          type: "item",
          title: "Delete",
          destructive: true,
          actions: () => {
            handleAttachmentDelete(selectedAttachment.index);
            hideAll();
          },
        },
      ];

  // useEffect(() => {
  //   (async () => {
  //     if (selectedAttachment) {
  //       const fileUrl = selectedAttachment.isPDF
  //         ? `https://service.plnify.com/pdf/${selectedAttachment.name}`
  //         : await getUrlFromFile(selectedAttachment);
  //       setSelectedAttachment((prev) => ({ ...prev, url: fileUrl }));
  //     }
  //   })();
  // }, []);

  const handleShowContextMenu = async (e, file) => {
    const fileState = state.attachments[file.index];

    setSelectedAttachment({ ...fileState });

    // setSelectedAttachment({ ...selectedFile });
    show(e);
  };

  function getFileFormat(filename) {
    let match = /\.([0-9a-z]+)$/i.exec(filename);
    if (match) {
      return match[1].toLowerCase();
    } else {
      return "";
    }
  }

  async function getUrlFromFile(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }

  const iframePreview = (icon, file) => {
    const fileUrl = `https://service.plnify.com/pdf/${file.name}`;

    switch (icon) {
      case "jpg":
      case "png":
      case "jpeg":
        return (
          <div className="image-viewer">
            <LazyLoadImage src={fileUrl} />
          </div>
        );
      case "pdf":
        return <IframeGoogleDoc url={fileUrl} />;

      case "mp3":
        return (
          <div className="w-100 d-flex flex-column align-items-center justify-content-between">
            <audio controls className="w-100">
              <source src={fileUrl} type="audio/ogg" />
              <source src={fileUrl} type="audio/mpeg" />
            </audio>
          </div>
        );

      default:
        return <IframeGoogleDoc url={fileUrl} />;
    }
  };

  const toggleExportEmailComponent = () => {
    setState((prev) => ({ ...prev, showExportEmail: !prev.showExportEmail }));
  };

  // console.log({ propsExportEmail: props, state: state });

  const EmailExportIncluded = ["EVENT_DETAILS", "CALENDAR", "ITINERARIES"];

  const handleSendHtmlAsEmailBody = (html) => {
    setState((prev) => ({
      ...prev,
      loadingContent: false,
      bodyOfEmail: html,
      subject: state?.subject,
    }));
    toggleExportEmailComponent();
  };

  const handleGoToExportModal = () => {
    setState((prev) => ({
      ...prev,
      ExportType: "export",
      showExportEmail: true,
    }));
  };

  return (
    <>
      {EmailExportIncluded.includes(props.fromSection) &&
        state.ExportType === "export" && (
          <ModalComponent
            modal={state.showExportEmail}
            toggle={toggleExportEmailComponent}
            childeren={
              <EmailExportComponent
                handlePDFAttachment={handlePDFAttachment}
                itineraryId={props.itinerary?.GUID}
                calledBy={props.fromSection}
                toggle={toggleExportEmailComponent}
                setExportType={setState}
                toggleEmailModal={props.toggle}
                handleSendHtmlAsEmailBody={handleSendHtmlAsEmailBody}
              />
            }
          />
        )}

      <ModalComponent
        modal={isSending}
        toggle={() => {}}
        childeren={
          <div
            className="bg-white d-flex align-items-center justify-content-center rounded-lg"
            style={{
              width: "250px",
              minHeight: "250px",
            }}
          >
            {isSent ? (
              <div className="text-black-50 d-flex flex-column justify-content-center justify-content-between">
                <img
                  src="/email-sent-animation.gif"
                  alt="email-sent-animation"
                />
                <span className="text-center my-2 font-weight-bold">Email sent!</span>
                <button
                  className={"btn btn-primary p-2"}
                  onClick={() => props.toggle()}
                >
                  Close
                </button>
              </div>
            ) : (
              <img
                src="/email-sent-animation-2.gif"
                alt="email-sent-animation"
              />
            )}
          </div>
        }
      />
      {/* <ModalComponent
        toggle={toggleMailingList}
        modal={state.mailingList}
        childeren={
          <MailingListModal
            selectAll={selectAll}
            selectAllEmail={selectAllEmail}
            selectEmail={selectEmail}
            emails={emails}
            teams={teams}
            toggle={() => toggleMailingList()}
          />
        }
      /> */}

      {/* <ModalComponent
        toggle={toggleMailHistory}
        modal={state.showMailHistory}
        childeren={
          <MailHistory
            selectedEmail={selectedEmail}
            toggle={() => toggleMailHistory()}
          />
        }
      />
      <ModalComponent
        toggle={toggleMailDraft}
        modal={state.showMailDraft}
        childeren={
          <MailDrafts
            selectedEmail={selectedEmail}
            toggle={() => toggleMailDraft()}
          />
        }
      /> */}

      <ContextMenu
        menuId="attachmentMenu"
        ContextMenuOptions={ContextMenuOptions}
      />

      <ModalComponent
        modal={viewerModal}
        toggle={() => setViewerModal(!viewerModal)}
        childeren={
          <>
            <Draggable
              axis="x"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[25, 25]}
              scale={1}
            >
              <div className="viewer-documents d-flex flex-column">
                <div className="viewer-documents-drag"></div>
                {selectedAttachment &&
                  iframePreview(
                    getFileFormat(selectedAttachment.name),
                    selectedAttachment
                  )}
              </div>
            </Draggable>
          </>
        }
      />

      <div className="organization-transparent" id="emailModal">
        <div className="event-page d-flex">
          <div
            className={`h-100 bg-white email-sidebar${
              showSideBar ? "_slim" : "_expanded"
            }`}
          >
            <MailSideBar
              className="h-100 border-right w-100"
              currentView={currentView}
              setCurrentView={setCurrentView}
              selectedEmail={selectedEmail}
              setCurrentEmail={setCurrentEmail}
              currentEmail={currentEmail}
              draftEmails={draftEmails}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              toggleMailHistory={toggleMailHistory}
              toggleMailDeleted={toggleMailDeleted}
              toggleMailDraft={toggleMailDraft}
              toggleMailingList={toggleMailingList}
            />
          </div>
          {isMobile ? (
            <>
              <ModalComponent
                toggle={() => toggleMailHistory()}
                modal={state.showMailHistory}
                className={"vw-100 m-0 min-vw-100 min-vh-100"}
                childeren={
                  <MailHistory
                    className={"h-100 w-100"}
                    selectedEmail={selectedEmail}
                    toggle={() => toggleMailHistory()}
                    setCurrentEmail={setCurrentEmail}
                    currentEmail={currentEmail}
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                    isMobile={isMobile}
                  />
                }
              />
              <ModalComponent
                toggle={() => toggleMailingList()}
                modal={state.mailingList}
                className={"vw-100 m-0 min-vw-100"}
                childeren={
                  <MailingListModal
                    className="h-100"
                    selectAll={selectAll}
                    selectAllEmail={selectAllEmail}
                    selectEmail={selectEmail}
                    emails={emails}
                    teams={teams}
                    toggle={() => toggleMailingList()}
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                    isMobile={isMobile}
                    toggleMailingList={toggleMailingList}
                  />
                }
              />
              <ModalComponent
                toggle={() => toggleMailDeleted()}
                modal={state.showMailDeleted}
                className={"vw-100 m-0 min-vw-100 min-vh-100"}
                childeren={
                  <div className="bg-light">
                    <MailDeleted
                      className="h-100"
                      selectedEmail={selectedEmail}
                      toggle={() => toggleMailDeleted()}
                      setCurrentEmail={setCurrentEmail}
                      currentEmail={currentEmail}
                      setDraftEmails={setDraftEmails}
                      draftEmails={draftEmails}
                      requestRemote={requestRemote}
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      isMobile={isMobile}
                    />
                  </div>
                }
              />
              <ModalComponent
                toggle={() => toggleMailDraft()}
                modal={state.showMailDraft}
                className={"vw-100 m-0 min-vw-100 min-vh-100"}
                childeren={
                  <div className="bg-light">
                    <MailDrafts
                      className="h-100"
                      selectedEmail={selectedEmail}
                      toggle={() => toggleMailDraft()}
                      setCurrentEmail={setCurrentEmail}
                      currentEmail={currentEmail}
                      setDraftEmails={setDraftEmails}
                      draftEmails={draftEmails}
                      requestRemote={requestRemote}
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      isMobile={isMobile}
                    />
                  </div>
                }
              />
            </>
          ) : (
            <div className="bg-light">
              {currentView === "draft" ? (
                <MailDrafts
                  className="h-100"
                  selectedEmail={selectedEmail}
                  toggle={() => toggleMailDraft()}
                  setCurrentEmail={setCurrentEmail}
                  currentEmail={currentEmail}
                  setDraftEmails={setDraftEmails}
                  draftEmails={draftEmails}
                  requestRemote={requestRemote}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  setCurrentView={setCurrentView}
                />
              ) : currentView === "mailList" || currentView === "newEmail" ? (
                <MailingListModal
                  className="h-100"
                  selectAll={selectAll}
                  selectAllEmail={selectAllEmail}
                  selectEmail={selectEmail}
                  emails={emails}
                  teams={teams}
                  toggle={() => toggleMailingList()}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  setCurrentView={setCurrentView}
                />
              ) : currentView === "deletedItems" ? (
                <MailDeleted
                  className="h-100"
                  selectedEmail={selectedEmail}
                  toggle={() => toggleMailDraft()}
                  setCurrentEmail={setCurrentEmail}
                  currentEmail={currentEmail}
                  setDraftEmails={setDraftEmails}
                  draftEmails={draftEmails}
                  requestRemote={requestRemote}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  setCurrentView={setCurrentView}
                />
              ) : currentView === "sentItems" ? (
                <MailHistory
                  className="h-100"
                  selectedEmail={selectedEmail}
                  toggle={() => toggleMailHistory()}
                  setCurrentEmail={setCurrentEmail}
                  currentEmail={currentEmail}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  setCurrentView={setCurrentView}
                />
              ) : null}
            </div>
          )}
          <div
            className={`bg-white border-left email-edit-container${
              !currentView
                ? "_full-expanded"
                : showSideBar
                ? "_slim"
                : "_expanded"
            }`}
          >
            <DragAndDrop
              handleDragOut={handleDragOut}
              handleDropCalendarEvent={handleDrop}
              fontSize={18}
            >
              <div className="text-head">
                <div className="text-left d-flex align-items-center">
                  <h5 className="title">
                    {props.fromSection !== "SUPPORT" ? (
                      <div className="d-flex align-items-center">
                        {showSideBar && isMobile && (
                          <buttom
                            className="btn mr-1"
                            onClick={() => setShowSideBar((prev) => !prev)}
                          >
                            <i className="fas fa-bars"></i>
                          </buttom>
                        )}

                        {!isMobile && !currentView && showSideBar && (
                          <buttom
                            className="btn mr-1"
                            onClick={() => setShowSideBar((prev) => !prev)}
                          >
                            <i className="fas fa-bars"></i>
                          </buttom>
                        )}
                        <FormattedMessage id="export" defaultMessage="Export" />
                        {/* <button
                          className="ml-2 btn btn-secondary btn btn-sm"
                          type="button"
                          onClick={() => toggleMailHistory()}
                        >
                          {" "}
                          <FormattedMessage
                            id="mailHistory"
                            defaultMessage="Mail History"
                          />
                        </button>
                        <button
                          className="ml-2 btn btn-danger btn border-0"
                          type="button"
                          onClick={() => toggleMailDraft()}
                        >
                          <FormattedMessage
                            id="drafts"
                            defaultMessage="Drafts"
                          />
                        </button> */}
                        <div className="create-button ml-2">
                          <span className="btn btn-primary btn-file">
                            <i className="fas fa-paperclip mr-1"></i>
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Attach file"
                            />
                            <input
                              className=""
                              onChange={onAttachmentChange}
                              id="inputFiles"
                              type="file"
                              multiple
                            />
                          </span>
                        </div>
                        {/* <div
                          className="create-button ml-2"
                          onClick={setHTMLasPDFattachment}
                        >
                          <span className="btn btn-secondary btn-file">
                            {isLoading && <SimpleLoader loading={isLoading} />}
                            <i className="fas fa-file-export mx-1"></i>
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Attach as PDF"
                            />
                          </span>
                        </div> */}
                        <div
                          className="create-button ml-2"
                          onClick={() => setHTMLasPDFattachmentFromEmail(true)}
                        >
                          <span
                            className="btn btn-secondary btn-file"
                            style={{
                              border: "none",
                              backgroundColor: "#4573d2",
                              color: "white",
                            }}
                          >
                            {isHTMLLoading && (
                              <SimpleLoader loading={isHTMLLoading} />
                            )}
                            <i className="fas fa-file-export mx-1"></i>
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Generate PDF"
                            />
                          </span>
                        </div>
                        <div
                          className="create-button ml-2"
                          onClick={() => handleGoToExportModal()}
                        >
                          <span
                            className="btn btn-secondary btn-file"
                            style={{
                              backgroundColor: "#2d6999",
                              color: "white",
                              border: "none",
                            }}
                          >
                            {isHTMLLoading && (
                              <SimpleLoader loading={isHTMLLoading} />
                            )}
                            <i className="fa fa-gear mx-1"></i>
                            <FormattedMessage
                              id="uploadNew"
                              defaultMessage="Advanced Export"
                            />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <FormattedMessage id="support" defaultMessage="Support" />
                    )}
                  </h5>
                </div>

                <div>
                  {props.EventDetails ? (
                    <div className="hotelEmail__resources mr-2 my-1 px-2 actionIcons d-none">
                      <Airplane className="mx-1" />
                      <Bus className="mx-1" />
                      <Domain className="mx-1" />
                      <ClipboardList className="mx-1" />
                      <AccountBox className="mx-1" />
                      <Attachment className="mx-1" />
                      <MessageProcessingOutline className="mx-1" />
                      <SmartCardOutline className="mx-1" />
                      <FolderImage className="mx-1" />
                    </div>
                  ) : null}
                  {sendingEmail ? (
                    <SimpleLoader loading={sendingEmail} />
                  ) : (
                    <div className="d-flex">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => sendEmail()}
                        disabled={isSending}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} color="white" />
                      </button>

                      <button
                        className="btn btn-danger btn-sm ml-1 border-0"
                        onClick={() =>
                          handleCloseEmail(
                            props.toggle,
                            currentEmail,
                            state.attachments
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} color="white" />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="event-inside hotel">
                <Formik>
                  {() => (
                    <Form autoComplete="off">
                      <div className="event-content">
                        {props.fromSection !== "SUPPORT" ? (
                          <div className="mx-2 hotelEmail__field d-flex align-items-center">
                            <span>
                              <FormattedMessage id="to" defaultMessage="To" />:
                            </span>
                            <Typeahead
                              id="emailRecipients"
                              allowNew={true}
                              selectHintonEnter={true}
                              selected={state.emails}
                              newSelectionPrefix="Add a new email: "
                              multiple={true}
                              onFocus={() => setOptions()}
                              onChange={(selected) => {
                                setState((prev) => {
                                  const newState = {
                                    ...prev,
                                    emails: selected,
                                  };
                                  // getDraftsEmailAndSave(newState);
                                  return newState;
                                });
                              }}
                              options={state.options}
                            />

                            <div className="hotelEmail__cta">
                              <div
                                onClick={() => {
                                  setCurrentView("mailList");
                                }}
                              >
                                <PlusCircleOutline />
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {props.fromSection !== "SUPPORT" ? (
                          <div className="mx-2 hotelEmail__field d-flex align-items-center">
                            <span>
                              <FormattedMessage
                                id="subject"
                                defaultMessage="Subject"
                              />
                              :{" "}
                            </span>
                            <input
                              className=" ml-2 form-control"
                              onChange={(e) => onChangeSubject(e.target.value)}
                              value={state.subject}
                              type="text"
                            />
                          </div>
                        ) : null}

                        {props.fromSection !== "SUPPORT" &&
                          state?.attachments?.length > 0 && (
                            <div className="mx-2 hotelEmail__field d-flex  flex-column">
                              <span>
                                <FormattedMessage
                                  id="attachments"
                                  defaultMessage="Attachments:"
                                />
                              </span>
                              <div className="d-flex flex-wrap w-100">
                                {state?.attachments?.map((file, index) => {
                                  return (
                                    <div
                                      onContextMenu={(e) =>
                                        handleShowContextMenu(e, {
                                          ...file,
                                          index,
                                        })
                                      }
                                      className="d-flex align-items-center justify-content-between bg-white border p-2 mx-1 rounded-lg"
                                      style={{ width: "270px" }}
                                      key={Math.random() + file.name}
                                    >
                                      <i
                                        className={
                                          fileIcon({
                                            ...file,
                                            Path: file.name,
                                          }) + " fa-2x mx-1"
                                        }
                                        style={{ width: "15%" }}
                                      ></i>
                                      <div
                                        className="d-flex flex-column mx-1 overflow-hidden"
                                        style={{ width: "70%" }}
                                      >
                                        <span
                                          className="text-truncate"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {file.name}
                                        </span>
                                        <span style={{ fontSize: "10px" }}>
                                          {file.size &&
                                            formatFileSize(file.size)}
                                        </span>
                                      </div>
                                      <button
                                        className="btn  border-0 mx-1"
                                        style={{ width: "15%" }}
                                        onClick={() =>
                                          handleAttachmentDelete(index)
                                        }
                                      >
                                        <i className="fas fa-times"></i>
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}

                        <div className="hotelEmail__email-container px-2 pb-4">
                          {state.loadingContent && (
                            <div className="bg-transparent text-center p-4">
                              <SimpleLoader loading={state.loadingContent} />
                            </div>
                          )}
                          {/*<SunEditor setContents={state.bodyOfEmail}
                         hideToolbar={false}
                         setAllPlugins={true}
                         //"<b>Hola</b> ok" 
                         height="500px" onChange={content=>{
                            this.setState({bodyOfEmail: content});
                         }}/>
                        */}
                          {!state.loadingContent && (
                            <RichTextComponent
                              id="emailBodyEditor"
                              toolbarSettings={{
                                items: [
                                  "Bold",
                                  "Italic",
                                  "Underline",
                                  "StrikeThrough",
                                  "FontName",
                                  "FontSize",
                                  "FontColor",
                                  "BackgroundColor",
                                  "LowerCase",
                                  "UpperCase",
                                  "|",
                                  "Formats",
                                  "Alignments",
                                  "OrderedList",
                                  "UnorderedList",
                                  "Outdent",
                                  "Indent",
                                  "|",
                                  "CreateLink",
                                  "Image",
                                  "|",
                                  // "CreateTable",
                                  // "|",
                                  "FormatPainter",
                                  "ClearFormat",
                                  "Print",
                                  "SourceCode",
                                  "FullScreen",
                                  "|",
                                  "Undo",
                                  "Redo",
                                ],
                              }}
                              textEditorRef={textEditorRef}
                              value={state.bodyOfEmail}
                              handleChange={({ value }) => {
                                setState((prev) => ({
                                  ...prev,
                                  bodyOfEmail: value,
                                }));
                              }}
                            />

                            //                   <Editor
                            //                     ref={textEditorRef}
                            //                     apiKey={TINY_MCE_KEY}
                            //                     style={{ zIndex: 0 }}
                            //                     value={state.bodyOfEmail}
                            //                     onEditorChange={(content) => {
                            //                       setState((prev) => ({
                            //                         ...prev,
                            //                         bodyOfEmail: content,
                            //                       }));
                            //                     }}
                            //                     init={{
                            //                       placeholder: "",
                            //                       height: "calc(100vh - 190px)",
                            //                       menubar: [], //["file", "insert"],
                            //                       images_upload_url:
                            //                         "https://app.plnify.com/api/Events/UploadImageTINYMCE",
                            //                       templates: [],
                            //                       plugins: [
                            //                         "advlist autolink lists link image fullscreen",
                            //                         "charmap print preview anchor help",
                            //                         "searchreplace visualblocks code",
                            //                         "insertdatetime media table  wordcount",
                            //                         "template textcolor powerpaste code print",
                            //                       ],
                            //                       fontsize_formats:
                            //                         "8pt 10pt 12pt 14pt 15pt 16pt 16pt 18pt 20pt 22pt 24pt 36pt",
                            //                       toolbar:
                            //                         "print undo redo | fontsizeselect forecolor backcolor | template | bold italic | \
                            // alignleft aligncenter alignright | \
                            // bullist numlist outdent indent | help | image table link media code",

                            //                       content_style: "p{font-size:14px !important;}",
                            //                       branding: false,
                            //                       visual: false,
                            //                     }}
                            //                   />
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </DragAndDrop>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailModal;
