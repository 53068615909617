import uuid from "uuid";
import api, { apiService } from "../../../common/api";
import * as constant from "./constants";

export function addAttachmentDropbox(event, attachment, success, refresh) {
  const url = "/Attachment/AddEditForEventWithoutImage";
  return async (dispatch) => {
    dispatch({
      type: constant.ADDING_ATTACHMENT,
    });
    try {
      const attachmentRequest = await addingAttachment(url, event, attachment);
      dispatch({
        type: constant.ADDED_ATTACHMENT_SUCCESS,
      });
      success();
      refresh();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.ADDING_ATTACHMENT_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.ADDING_ATTACHMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.ADDED_ATTACHMENT_SUCCESS:
    case constant.ADDING_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function addingAttachment(url, event, attachment) {
  return apiService.post(url, {
    guid: uuid.v4(),
    name: attachment.name,
    note: "",
    eventGUID: event,
    path: attachment.link || attachment.url || attachment.webUrl,
  });
}
