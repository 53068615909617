import React, { useEffect, useRef, useState } from "react";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";
import { IMAGES } from "../../constant";
import {
  Delete as DeleteButton,
  Star as FavoriteButton,
  NoteTextOutline,
  CloseCircle,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Bell as NotifyButton,
} from "mdi-material-ui";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { getBasicInfo } from "../../utils";
import uuid from "uuid";
import moment from "moment";
import {
  PassTypesModelAsArray,
  PassTypesModel,
  getStatusRequestGuestList,
} from "../../common/helpers";
import ShowMoreText from "react-show-more-text";

const Details = (
  requests,
  updateRequest,
  deleteElement,
  rejectAll,
  confirmAll,
  itemOptions,
  guestListScroll,
  ticketSelected,
  ticket,
  toggleResponseModal,
  toggleAdminNote
) => {
  const intl = useIntl();

  console.log("the request itself", requests);
  let result = requests.map((item, index) => (
    <tr
      key={item.GUID + "_" + index}
      className={`guestRequest child row  ${
        ticket && ticketSelected && ticket.GUID === requests[0].GUID
          ? "active"
          : ""
      }`}
    >
      <td className=" py-0 px-0 col-2">
        <div
          className={`${getStatusRequestGuestList(
            item
          )} px-2 d-inline-block w-100`}
          style={{ height: "100%" }}
        >
          <small className="text-muted">
            <FormattedMessage id="Guest Name" defaultMessage="Guest Name" />{" "}
            <span className="" title={item.RequestDate}>
              ({moment(item.RequestDate).format("MMM DD, YYYY")})
            </span>
          </small>
          <br />
          <div className="d-flex justify-content-between">
            <span>
              {item.GuestLastName !== null ? item.GuestLastName + ", " : ""}
              {item.GuestNames}
            </span>
            {item.Note && item.Note.length > 0 && (
              <div onClick={() => toggleAdminNote(item, true)}>
                <NoteTextOutline htmlColor="#fb9f00" />
              </div>
            )}
          </div>
        </div>
      </td>

      <td className="py-2 px-1 col-1 text-center">{item.Passes}</td>
      <td className="py-2 px-1 col-1">
        <select
          value={item.PassType}
          onChange={(e) => updateRequest({ ...item, PassType: e.target.value })}
          className="form-control inline"
        >
          {PassTypesModelAsArray().map((item, index) => {
            return (
              <option value={item} key={index}>
                {PassTypesModel[item]}
              </option>
            );
          })}
        </select>
      </td>
      <td className="py-2 px-1 col-1">
        <select
          onChange={(e) => updateRequest({ ...item, Pickup: e.target.value })}
          className="form-control inline"
          value={item.PickupLocation ? item.PickupLocation : ""}
        >
          <option value="-">-</option>
          <option value="WillCall">Will Call</option>
          <option value="In-Hand">In Hand</option>
        </select>
      </td>
      <td className="d-flex py-0 px-1 col-2 align-items-center justify-content-center">
        {/* {item.Note && item.Note.length > 0 && (
            <>
              <span
                className="badge badge-secondary d-inlne m-0 px-2 py-1"
                onClick={() => toggleAdminNote(item, true)}
              >
                Request Notes
              </span>

              <ShowMoreText
                lines={1}
                title={item.Note}
                more="Show more"
                less="Show less"
                className={"ml-1 d-inline cursor-default"}
                anchorClass="d-inline text-primary anchor-class"
                expanded={false}
                width={0}
                children={<p>{item.Note}</p>}
              />

              <hr className="m-0 p-0 mb-2 mt-2" />
            </>
          )} */}

        {item.ConfirmedNote && item.ConfirmedNote.length > 0 ? (
          <div
            title={item.ConfirmedNote}
            onDoubleClick={() => toggleAdminNote(item)}
            className="w-100"
          >
            <ShowMoreText
              className={"d-inline cursor-default"}
              anchorClass="d-inline text-primary anchor-class"
              lines={2}
              more="See"
              less="Less"
              expanded={false}
              width={0}
              children={
                <p style={{ cursor: "default" }}>{item.ConfirmedNote}</p>
              }
            />
          </div>
        ) : (
          <button
            className="btn align-self-center"
            onClick={() => toggleAdminNote(item)}
          >
            <i className="fas fa-edit"></i>
            {/* <u>
              <FormattedMessage
                id="Add Note"
                defaultMessage="Add Admin  Note"
              />
            </u> */}
          </button>
        )}
      </td>
      <td className="py-2 px-1 col-1 text-center">
        {item.ConfirmAccountGUID ? (
          <img
            //title={item.ConfirmedAccountName + " ("+ moment(item.ConfirmedDate).utc().fromNow() +")"}
            title={item.ConfirmedAccountName}
            src={item.ConfirmedAccounImage}
            alt={item.ConfirmedAccountName}
            className="mx-2 avatar"
            width="20"
            height="20"
          />
        ) : null}
      </td>
      {/* <td className="py-2 px-1 col-1 text-center">
        {item.ConfirmedNote && item.ConfirmedNote.length > 0 ? (
          <span
            onClick={() => toggleAdminNote(item)}
            title={item.ConfirmedNote}
          >
            <NoteTextOutline htmlColor="#01aeff" />
          </span>
        ) : (
          <small
            className="text-muted underline"
            onClick={() => toggleAdminNote(item)}
          >
            <u>
              <FormattedMessage id="Add Note" defaultMessage="Add Note" />
            </u>
          </small>
        )}
      </td> */}

      <td
        className="py-2 col-1 text-center"
        title={`${item.Section ? "Section: " + item.Section : ""} ${
          item.Row ? "Row: " + item.Row : ""
        } ${item.Seat ? "Seat: " + item.Seat : ""}`}
      >
        <ShowMoreText
          className={"d-inline cursor-default"}
          anchorClass="d-inline text-primary anchor-class"
          lines={1}
          more="See"
          less="Less"
          expanded={false}
          width={0}
          children={
            <small>
              {item.Section && item.Section !== null
                ? "Section: " + item.Section
                : null}
              {item.Row && item.Row !== null ? " Row: " + item.Row : ""}
              {item.Seat && item.Seat !== null ? " Seat: " + item.Seat : ""}
            </small>
          }
        />
      </td>
      <td className="py-2 px-1 text-center col-1">
        <FavoriteButton
          onClick={() => updateRequest({ ...item, Priority: !item.Priority })}
          width="10"
          height="10"
          htmlColor={item.Priority ? "#fb9f00" : "#95979d"}
        />
      </td>
      <td className="py-2 px-1 text-center col-1">
        <NotifyButton
          onClick={() => updateRequest({ ...item, Notify: !item.Notify })}
          width="10"
          height="10"
          htmlColor={item.Notify ? "#01aeff" : "#95979d"}
        />
      </td>
      <td className="py-2 px-1text-truncate py-1 text-right col-1">
        <div className="btn-group align-items-center actionButtons">
          <CheckCircle
            onClick={() => toggleResponseModal(item, true)}
            className="mx-1 d-none d-lg-block d-xl-block"
            htmlColor="#1ba863"
            width="10"
            height="10"
          />
          <CloseCircle
            onClick={() => toggleResponseModal(item, false)}
            className="mx-1 d-none d-lg-block d-xl-block "
            htmlColor="#fe3d33"
            width="10"
            height="10"
          />
          <Popup
            modal={false}
            closeOnDocumentClick={true}
            closeOnEscape={true}
            position={guestListScroll ? "top right" : "bottom right"}
            on="click"
            trigger={
              <button className="btn btn-sm">
                <i className="fas fa-ellipsis-h"></i>
              </button>
            }
          >
            {(open) => {
              return (
                <div className="text-left ">
                  {/* Work Here */}
                  {itemOptions.map((o, index2) => (
                    <div
                      key={index2}
                      className={`${o.border ? "border-bottom " : ""}  ${
                        o.delete ? "delete " : ""
                      } dropDownList`}
                      onClick={() => {
                        o.trigger(item);
                        open();
                      }}
                    >
                      {o.name}
                    </div>
                  ))}
                </div>
              );
            }}
          </Popup>
        </div>
      </td>
    </tr>
  ));

  return result;
};

const GuestItem = ({
  requests,
  clickToSeeDetails,
  deleteElement,
  rejectAll,
  confirmAll,
  updateRequest,
  toggleEditModal,
  ticketSelected,
  ticket,
  setRequestGroup,
  toggleResponseModal,
  toggleAdminNote,
  toggleModalTicket,
  guestListScroll,
  setMode,
}) => {
  const intl = useIntl();
  const itemOptions = [
    {
      name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
      border: true,
      trigger: (item) => {
        toggleEditModal(item);
        setMode(true);
      },
    },
    {
      name: (
        <FormattedMessage
          id="Add request for the same guest"
          defaultMessage="Add request for the same guest"
        />
      ),
      trigger: (item) => {
        let newItem = item;
        newItem.Notes = null;
        newItem.ConfirmedNote = "";
        newItem.ConfirmAccountGUID = null;
        newItem.GUID = uuid.v4();
        newItem.PassType = newItem.PassType !== "Ticket" ? "Ticket" : "Pass";
        newItem.Priority = null;
        toggleEditModal(newItem);
      },
      border: true,
    },

    {
      name: <FormattedMessage id="Approve" defaultMessage="Approve" />,
      border: true,
      trigger: (item) => toggleResponseModal(item, true),
    },
    {
      name: <FormattedMessage id="Decline" defaultMessage="Decline" />,
      border: true,
      trigger: (item) => toggleResponseModal(item, false),
    },
    {
      delete: true,
      name: <FormattedMessage id="delete" defaultMessage="Delete " />,
      border: true,
      trigger: (item) => deleteElement({ ...item, Deleted: new Date() }),
    },
  ];

  const requestOptions = [
    {
      name: intl.formatMessage({ id: "Add another request" }),
      trigger: (item) => {
        console.log({ item });
        let newItem = { ...item };
        newItem.PassType = "";
        newItem.Row = null;
        newItem.Seat = null;
        newItem.Section = null;
        newItem.PickupLocation = null;
        newItem.Notes = null;
        newItem.ConfirmedNote = "";
        newItem.GUID = uuid.v4();
        newItem.ConfirmAccountGUID = null;
        newItem.ConfirmDate = null;
        newItem.Confirmed = null;
        newItem.ConfirmedAccounImage = null;
        newItem.ConfirmedAccountName = null;
        newItem.ConfirmedNote = null;
        newItem.ConfirmedPasses = null;
        newItem.GuestLastName = "";
        newItem.GuestNames = "";
        newItem.Passes = 1;
        newItem.Notify = false;
        toggleEditModal(newItem);
      },
      border: true,
    },
    {
      name: intl.formatMessage({ id: "Approve All" }),
      border: true,
      trigger: (item) =>
        confirmAll({
          ...item,
          ConfirmedPasses: item.Passes,
          ConfirmDate: new Date(),
          ConfirmAccountGUID: getBasicInfo().GUID,
          Confirmed: true,
        }),
    },
    {
      name: intl.formatMessage({ id: "Decline All" }),
      trigger: (item) => rejectAll({ ...item, Confirmed: false }),
    },
  ];
  const [expandedView, setExpandedView] = useState(true);
  return (
    <>
      <tr className={`row guestRequest`}>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-2 text-truncate py-1 px-1"
        >
          {expandedView ? (
            <ChevronUp htmlColor="#000" />
          ) : (
            <ChevronDown htmlColor="#000" />
          )}
          {requests[0].RequestByImage !== null ? (
            <img
              src={requests[0].RequestByImage}
              alt={requests[0].RequestByName}
              className="mx-2 avatar"
              width="20"
              height="20"
            />
          ) : (
            <img
              src={IMAGES.DUMMY_ICON}
              alt={requests.RequestByName}
              className="mr-2 avatar"
              width="20"
              height="20"
            />
          )}
          <span className="requester"> {requests[0].RequestByName}</span>
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Amount" })} (
          {requests.map((r) => r.Passes).reduce((a, b) => a + b, 0)})
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Type" })}
        </th>

        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Pick Up" })}
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-2 text-center text-truncate py-1 px-1"
        >
          {intl.formatMessage({ id: "Notes" })}
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-center text-truncate py-1 px-1"
        >
          {intl.formatMessage({ id: "Confirmed by" })}
        </th>
        {/* <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-center text-truncate py-1 px-1"
        >
          {intl.formatMessage({ id: "Admin Notes" })}
        </th> */}
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Seats" })}
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Priority" })}
        </th>
        <th
          onClick={() => setExpandedView(!expandedView)}
          className="col-1 text-truncate py-1 px-1 text-center"
        >
          {intl.formatMessage({ id: "Notify" })}
        </th>
        <th
          style={{ overflow: "visible" }}
          className="col-1 text-truncate py-1 px-1 text-right actionButtons"
        >
          <button
            className="btn"
            onClick={() => {
              clickToSeeDetails(requests);
              setRequestGroup(requests);
            }}
          >
            <FontAwesomeIcon icon={faExpandAlt} />
          </button>
          <Popup
            modal={false}
            closeOnDocumentClick
            position={"bottom right"}
            on="click"
            trigger={
              <button className="btn btn-sm  mr-2">
                <i className="fas fa-ellipsis-v"></i>
              </button>
            }
          >
            {(close, open) => {
              return (
                <div className="text-left">
                  {requestOptions.map((o) => (
                    <div
                      key={o.name}
                      className={`${o.border ? "border-bottom " : ""}  ${
                        o.delete ? "delete " : ""
                      } dropDownList`}
                      onClick={() => {
                        o.trigger(requests[0]);
                        close();
                      }}
                    >
                      {o.name}
                    </div>
                  ))}
                </div>
              );
            }}
          </Popup>
        </th>
      </tr>
      {expandedView
        ? Details(
            requests,
            updateRequest,
            deleteElement,
            rejectAll,
            confirmAll,
            itemOptions,
            guestListScroll,
            ticketSelected,
            ticket,
            toggleResponseModal,
            toggleAdminNote
          )
        : null}
    </>
  );
};

GuestItem.propTypes = {};

export default GuestItem;
