import React from "react";
import PropTypes from "prop-types";
import { SimpleLoader } from "../../../components";
import { IMAGES } from "../../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../../components/form/formField";
import { forgotPasswordSchema } from "../../../utils/validationsSchemas";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";

const ForgotPassword = (props) => {
  const { handleSubmit, toggle, forgotPassword, handleSuccess, loading } =
    props;
  return (
    <div className="before-login">
      <div className="inner-page">
        <div className="forgot-page">
          <div className="forgot-page-content position-relative">
            <div
              className="close-icon position-absolute btn"
              style={{ top: "-20px", right: "-25px" }}
              onClick={() => toggle()}
            >
              <i className="fas fa-times"></i>
            </div>
            <div className="forgot-page-head">
              <div className="forgot-text">
                <h1>
                  <FormattedMessage
                    id="forgot.password"
                    defaultMessage="Forgot Password"
                  />
                </h1>
              </div>
            </div>
            <div className="body-section">
              <div className="forgot-pg">
                <p>
                  <FormattedMessage
                    id="forgotPasswordReset"
                    defaultMessage="Please provide your email and we’ll send over a link to reset
                    your password."
                  />
                </p>
              </div>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                  email: "",
                }}
                onSubmit={(values) => {
                  forgotPassword(values.email, () => handleSuccess());
                }}
                validationSchema={forgotPasswordSchema}
              >
                <Form>
                  <FormField fullWidth={true} label="Email Address">
                    <Field className="form-control" type="text" name="email" />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="email"
                    />
                  </FormField>

                  <div className="submit-button">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-block btn-sm btn-primary"
                        id="reset"
                      >
                        <FormattedMessage id="submit" defaultMessage="Submit" />
                      </button>
                    )}
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.string,
  handleChange: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default ForgotPassword;
