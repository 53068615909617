export const ROUTES = {
  LOGIN: "/",
  SIGNUP: "/signup",
  FILES: "/files",
  SHEETS: "/files/sheets",
  DOCS: "/files/docs",
  PDF: "/files/pdf",
  FORGOT_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
  DASHBOARD: "/dashboard/:id",
  ADD_EVENT: "/addevent",
  ONBOARDING: "/onboarding",
  CREATE_ORGANIZATION: "/createorganization",
  CALENDAR: "/calendar",
  USERS_ORGANIZATION: "/users",
  TICKETS_AND_PASSES: "/guestList",
  TODO: "/todo",
  TASKVIEW: "/c/:id",
  TODOVIEW: "/todo/:id/:id",
  EVENT: "/calendar/:guid",
  COLLAB: "/collab",
  COLLAB_TYPE: "/collab/:guid",
  COLLAB_INVITE: "/invitation_to_collab/:id",
  TERMS_AND_CONDITION: "/termsandconditions/:id",
  SECURITY_AND_PRIVACY: "/securityandprivacy/:id",
  SELECT_ORGANIZATION: "/selectorganization",
  SETTINGS: "/profile",
  CONFIRMED_EMAIL: "/email_confirmation/:token",
  CHANGE_PASSWORD: "/account/PasswordChange",
  MEET: "/meet/:id",
  WELCOME_SUCCESS: "/ConfirmInvitation",
  CLEAN_UP: "/cleanup",
  JOIN: "/join/:id",
  DIRECTION:"/direction/:id"
};
