import React, { useState } from "react";
import { Delete as DeleteIcon } from "mdi-material-ui";
import Select from "react-select";
import { DropDownComponent } from "../common/dropDown";

import { useDebouncedCallback } from "use-debounce";
import api from "../../common/api";
import { useIntl, FormattedMessage } from "react-intl";
const options = [
  { value: "Guest", access: 0 },
  { value: "Admin", access: 1 },
  { value: "Non-App user", access: 2 },
];

const optionsAppUser = [{ value: "Guest" }, { value: "Admin" }];

const InviteItem = ({
  user,
  teams,
  deleteUser,
  onUpdateUser,
  setDataFromEmail,
  settingRole,
  getValueOfAccess,
  setTeam,
}) => {
  const intl = useIntl();
  const [emails, setEmails] = useState([]);
  const [SelectTeams, setSelectTeams] = useState();
  const [notify, setNotify] = useState(true);
  const searcher = useDebouncedCallback(async (value) => {
    searchEmail(value);
  }, 500);
 
  function searchEmail(value) {
    if (value.length > 3 && value.includes(".") && value.includes("@")) {
      checkAccount(value);
    }
  }

  async function checkAccount(email) {
    try {
      const request = await api.get("/User/GetAccounts", {
        params: { Email: email },
      });
      if (request.data.status === "ok") {
        const emails = request.data.response;
        setEmails(emails);
        if (emails.length > 0) {
          onUpdateUser({
            ...user,
            Email: emails[0].Email,
            Name: emails[0].Name,
            LastName: emails[0].LastName,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="card shadow-sm my-1">
      <div className="card-body p-1 row mx-0">
        <div className="col">
          <input
            autoFocus
            onChange={(event) => {
              onUpdateUser({ ...user, Email: event.target.value });
              searchEmail(event.target.value);
            }}
            className={`form-control my-1 inline`}
            filled={"true"}
            placeholder={"your@email.com"}
            type="email"
            value={user.Email}
            //onBlur={(event) =>     onUpdateUser({ ...user, Email: event.target.value })}
          />
          {user.emailIsValid !== null &&
            user.emailIsValid !== undefined &&
            !user.emailIsValid && (
              <span className="text-danger" style={{ fontSize: 12 }}>
                <FormattedMessage
                  id="emailRequired"
                  defaultMessage="Email is required"
                />
              </span>
            )}
        </div>
        <div className="col">
          <input
            className={`form-control my-1 inline`}
            filled={"true"}
            placeholder={intl.formatMessage({ id: "firstName.signUp" })}
            type="text"
            value={user.Name}
            //onBlur={(event) => onUpdateUser({ ...user, Name: event.target.value })}
            onChange={(event) =>
              onUpdateUser({ ...user, Name: event.target.value })
            }
          />
          {user.nameIsValid !== null &&
            user.nameIsValid !== undefined &&
            !user.nameIsValid && (
              <span className="text-danger" style={{ fontSize: 12 }}>
                <FormattedMessage
                  id="nameRequired"
                  defaultMessage="Name is required"
                />
              </span>
            )}
        </div>
        <div className="col">
          <input
            className={`form-control my-1 inline`}
            filled={"true"}
            placeholder={intl.formatMessage({ id: "lastName.signUp" })}
            type="text"
            value={user.LastName}
            onBlur={(event) =>
              onUpdateUser({ ...user, LastName: event.target.value })
            }
            onChange={(event) =>
              onUpdateUser({ ...user, LastName: event.target.value })
            }
          />
          {user.lastNameIsValid !== null &&
            user.lastNameIsValid !== undefined &&
            !user.lastNameIsValid && (
              <span className="text-danger" style={{ fontSize: 12 }}>
                <FormattedMessage
                  id="lastNameRequired"
                  defaultMessage="Last Name is required"
                />
              </span>
            )}
        </div>

        <div className="col">
          <select
            className="form-control inline"
            value={user.TeamName}
            onChange={(e) => {
              //debugger;
              settingRole(user.GUID, e.target.value);
              onUpdateUser({ ...user, TeamName: e.target.value });
            }}
          >
            <option value="">{intl.formatMessage({ id: "team" })}</option>
            {teams.map((item) => {
              return <option value={item.Name}>{item.Name}</option>;
            })}
          </select>
          {/* <Select
            className="form-control inline"
            getOptionLabel={(option) => option.Name}
            options={teams}
            onChange={(value) => {
              // settingRole(user.GUID, value.Name);
              onUpdateUser({ ...user, TeamName: value.Name});
              //  setSelectTeams(value.Name)
               setTeam(user.GUID, value.Name)
              
            }}
            placeholder="Team"
          /> */}
          {user.teamIsValid !== null &&
            user.teamIsValid !== undefined &&
            !user.teamIsValid && (
              <span className="text-danger" style={{ fontSize: 12 }}>
                <FormattedMessage
                  id="teamRequired"
                  defaultMessage="Team is required"
                />
              </span>
            )}
        </div>
        <div className="col">
          <select
            className="form-control inline"
            value={user.TypeOfAccess}
            onChange={(value) => {
              onUpdateUser({ ...user, TypeOfAccess: value.access });
            }}
          >
            <option value="">{intl.formatMessage({ id: "accessType" })}</option>
            {options.map((item) => {
              return <option value={item.access}>{item.value}</option>;
            })}
          </select>
          {/* <Select
            className="form-control"
            getOptionLabel={(option) => option.value}
            options={options}
             value={getValueOfAccess(user.GUID)}
            onChange={(value) => {
              onUpdateUser({ ...user, TypeOfAccess: value.access });
            }}
            placeholder="Access type"
          /> */}
        </div>

        <div className="col-1 d-flex align-items-center ">
          <label htmlFor="notify" className="font-weight-bold">Notify Member</label>
          <input
            className={`m-2 `}
            style={{ height: "15px", width: "15px" }}
            filled={"true"}
            placeholder={intl.formatMessage({ id: "lastName.signUp" })}
            type="checkbox"
            checked={notify}
            onChange={() =>
              setNotify((prev) => {
                onUpdateUser({ ...user, notify: !prev });
                return !prev;
              })
            }
          />
        </div>
        <div className="pr-2 text-center d-flex align-items-center justify-content-center">
          <DropDownComponent
            data={[
              {
                delete: true,
                name: <FormattedMessage id="delete" defaultMessage="Delete" />,
                trigger: () => deleteUser(user.GUID),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default InviteItem;
