import React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { SimpleLoader } from "../common";
import { compsTicketSchema } from "../../utils/validationsSchemas";
import { useIntl, FormattedMessage } from "react-intl";

const Comps = (props) => {
  const intl = useIntl();
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title">
                <FormattedMessage
                  id="ticketsComps"
                  defaultMessage="Comp Amount"
                />
              </h5>
            </div>
            <div
              className="close-icon d-none d-none"
              onClick={() => props.toggle()}
            ></div>
          </div>
          <Formik
            initialValues={{
              Comps: props.event.CompsCount || null,
              ReturnedComps: props?.event?.ReturnedCompsCount || null,
            }}
            onSubmit={(values) =>
              props.updateComps({
                Comps: values.Comps || null,
                ReturnedCompsCount: values.ReturnedComps || null,
              })
            }
            validateOnBlur={false}
            validationSchema={compsTicketSchema}
          >
            <Form className="mt-3">
              <div className="event-inside">
                <div className="event-content">
                  <div className="row">
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0 pr-2"
                      label={intl.formatMessage({
                        id: "Enter comp amount",
                        defaultMessage: "Enter Comp Amount",
                      })}
                    >
                      <Field
                        className="form-control"
                        type="number"
                        min="0"
                        name="Comps"
                      />
                      <ErrorMessage
                        name="Comps"
                        component="div"
                        className="text-danger"
                      />
                    </FormField>
                    <FormField
                      fullWidth={true}
                      additionalClasses="px-0 pr-2"
                      label={intl.formatMessage({
                        id: "Enter return comp amount",
                        defaultMessage: "Enter Returned Comp Amount",
                      })}
                    >
                      <Field
                        className="form-control"
                        type="number"
                        min="0"
                        name="ReturnedComps"
                      />
                      <ErrorMessage
                        name="ReturnedComps"
                        component="div"
                        className="text-danger"
                      />
                    </FormField>
                  </div>
                </div>
                <div className="py-3">
                  {props.loading ? (
                    <SimpleLoader loading={props.loading} />
                  ) : (
                    <div className="row mx-0">
                      <div className="col-12 px-0">
                        <button
                          type="submit"
                          className="btn btn-primary font-weight-bold  btn-block"
                        >
                          <FormattedMessage id="done" defaultMessage="Done" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

Comps.propTypes = {};

export default Comps;
