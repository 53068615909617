import React, { useState, useEffect } from "react";
import {
  DropDownList,
  ComponentLoader,
  ModalComponent,
} from "../../../components";
import Select, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { OPTION, EVENT_COLOR_ROUND, EVENT_TYPES } from "../../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../../components/form/formField";
import VenueModal from "../../../components/event/VenueModal";
import EventTypesModal from "../../../components/event/EventTypesModal";
import moment from "moment";
import { MenuDown } from "mdi-material-ui";
import api from "../../../common/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useIntl, FormattedMessage } from "react-intl";
import * as actions from "../redux/actions";
import * as commonActions from "../../common/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import RichTextComponent from "../../../components/common/RichTextEditorComponent";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <MenuDown style={{ width: 0 }} />
    </components.DropdownIndicator>
  );
};
//Select Style
const colourStyles = {
  valueContainer: (styles) => ({
    ...styles,
    padding: "0",
  }),
};

const EventDetail = (props) => {
  const intl = useIntl();
  const { countries, cities, venues } = useSelector((state) => state.common);
  const { event } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const {
    handleSidebarItem,
    setVenue,
    deleteVenue,
    toggleVenueModal,
    venueModal,
    editVenueModal,
  } = props;
  const [eventsTypeModal, setEventsTypeModal] = useState(false);

  function setStateOrNotStateVenue(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("Venue.City", place.split(",")[0].trim());
      props.setFieldValue("Venue.State", place.split(",")[1].trim());
      props.setFieldValue("Venue.Country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("Venue.City", place.split(",")[0].trim());
      props.setFieldValue("Venue.State", "");
      props.setFieldValue("Venue.Country", place.split(",")[1].trim());
    }
  }
  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", place.split(",")[1].trim());
      props.setFieldValue("Country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", "");
      props.setFieldValue("Country", place.split(",")[1].trim());
    }
  }

  const updateNote = async (notes) => {
    let item = {
      GUID: event.GUID,
      Notes: notes,
    };
    //Send to the server

    await api.post("/Events/SaveNote", item);
  };

  const [showSaveButton, setShowSaveButton] = useState(false);

  return (
    <div
      onClick={() => handleSidebarItem("EVENT_DETAILS", {})}
      className="resource shadow-sm"
    >
      <div className="resource__header mx-3">
        <h4>General</h4>
      </div>
      {false ? (
        <ComponentLoader loading={true} />
      ) : (
        <Formik
          onSubmit={(values) => dispatch(actions.editEvent(values))}
          enableReinitialize={true}
          initialValues={{
            ...event,
            EventCapacity: event.EventCapacity || "",
          }}
        >
          {(props) => {
            console.log({ InFormikProps: props });
            return (
              <Form autoComplete="off">
                <ModalComponent
                  modal={venueModal || editVenueModal}
                  toggle={() => {
                    toggleVenueModal();
                    dispatch(commonActions.emptyVenues());
                  }}
                  childeren={
                    <VenueModal
                      {...props}
                      // getCities={commonActions.searchCity}
                      // getCountrys={commonActions.searchCountry}
                      countries={countries}
                      cities={cities}
                      // emptyCountries={commonActions.emptyCountries}
                      // emptyCities={commonActions.emptyCities}
                      // emptyVenues={commonActions.emptyVenues}
                      venues={venues}
                      handleSidebarItem={handleSidebarItem}
                      // getVenues={actions.getVenues}
                      setVenue={setVenue}
                      event={event}
                      venue={event.Venue ? event.Venue.Name : ""}
                      // addCustomVenue={actions.addCustomVenue}
                      // editEvent={() => dispatch(actions.editEvent(props.values))}
                      toggle={() => {
                        toggleVenueModal();
                        // dispatch(commonActions.emptyVenues());
                      }}
                      // editVenueAction={actions.editVenue}
                    />
                  }
                />
                <ModalComponent
                  modal={eventsTypeModal}
                  toggle={() => setEventsTypeModal(!eventsTypeModal)}
                  childeren={
                    <EventTypesModal
                      {...props}
                      eventTypeList={EVENT_TYPES}
                      editEvent={() =>
                        dispatch(actions.editEvent(props.values))
                      }
                      toggle={() => {
                        setEventsTypeModal(!eventsTypeModal);
                        props.handleSubmit();
                      }}
                    />
                  }
                />
                <div className="resource__body">
                  <div className="row">
                    <div className="col-md-4">
                      <FormField
                        fullWidth={true}
                        label={intl.formatMessage({
                          id: "eventName",
                          defaultMessage: "Event Name",
                        })}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          value={props.values.Name ? props.values.Name : ""}
                          onChange={(e) => {
                            props.setFieldValue("Name", e.target.value);
                          }}
                          onBlur={() => props.handleSubmit()}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Name"
                          component="div"
                        />
                      </FormField>
                    </div>
                    <div className="col-md-4 eventType__input">
                      <div>
                        <div className="form-group">
                          <label style={{ position: "relative", top: 4 }}>
                            <FormattedMessage
                              id="eventType"
                              defaultMessage="Event Type"
                            />
                          </label>
                          {/* <Field
                          className="form-control"
                          type="text"
                          value={
                            props.values.EventType ? props.values.EventType : ""
                          }
                          onChange={(e) => {
                            props.setFieldValue("EventType", e.target.value);
                          }}
                          onClick={() => setEventsTypeModal(!eventsTypeModal)}
                          onBlur={() => props.handleSubmit()}
                          autoComplete="off"
                        /> */}

                          <Select
                            styles={colourStyles}
                            components={{ DropdownIndicator }}
                            placeholder={intl.formatMessage({
                              id: props.values.EventType
                                ? props.values.EventType
                                : "Select an event type",
                            })}
                            defaultValue={props.values.EventType}
                            value={props.values.EventType}
                            options={EVENT_TYPES?.map((e) => {
                              return {
                                label: intl.formatMessage({
                                  id: e,
                                }),
                                value: e,
                              };
                            })}
                            onChange={(e) => {
                              props.setFieldValue("EventType", e.value);
                              props.handleSubmit();
                            }}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="EventType"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          style={{ position: "relative", top: 4 }}
                          htmlFor={"text"}
                        >
                          <FormattedMessage
                            id="status"
                            defaultMessage="Status"
                          />
                        </label>
                        <div
                          className={EVENT_COLOR_ROUND[props.values.Status]}
                        ></div>
                        <Select
                          components={{ DropdownIndicator }}
                          placeholder={props.values.Status}
                          defaultValue={props.values.Status}
                          value={props.values.Status}
                          onChange={(value) => {
                            props.setFieldValue("Status", value.value);
                            props.handleSubmit();
                          }}
                          options={OPTION}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4" style={{ bottom: 4 }}>
                      <div className="form-group">
                        {true && (
                          <>
                            <label
                              style={{ position: "relative", top: 1 }}
                              htmlFor={"text"}
                            >
                              <FormattedMessage
                                id="date"
                                defaultMessage="Date"
                              />
                            </label>
                            <div>
                              <input
                                type="date"
                                className="form-control"
                                id="date"
                                value={moment(props.values.Date).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "Date",
                                    moment(e.target.value).format("YYYY-MM-DD")
                                  );
                                  props.handleSubmit();
                                }}
                              />

                              <div
                                className="drop-icon d-none"
                                onClick={() =>
                                  document.getElementById("date").focus()
                                }
                              >
                                <MenuDown />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 d-none">
                      <div>
                        <div className="form-group">
                          <label
                            title={intl.formatMessage({
                              id: "generalCapacityEvent",
                            })}
                          >
                            <FormattedMessage
                              id="eventCapacity"
                              defaultMessage="Event Capacity"
                            />
                            <i
                              className="fas fa-info-circle ml-2"
                              title={intl.formatMessage({
                                id: "generalCapacityEvent",
                              })}
                              style={{ color: "rgb(96, 96, 96)" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            value={props.values.EventCapacity}
                            onChange={(e) => {
                              let value = e.target.value.replace(",", "");
                              value = value.replace(" ", "");
                              props.setFieldValue("EventCapacity", value);
                            }}
                            onBlur={() => props.handleSubmit()}
                            autoComplete="off"
                            placeholder="-"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="EventCapacity"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-4 venue-general"
                      style={{ bottom: 2 }}
                    >
                      {props.values.Venue && props.values.Venue.Name ? (
                        <FormField
                          additionalClasses="mx-0 px-0"
                          fullWidth={true}
                          label={intl.formatMessage({
                            id: "venue",
                            defaultMessage: "Venue",
                          })}
                          subLabel="Remove"
                          subAction={() => deleteVenue(props.values)}
                        >
                          <Field
                            className="form-control"
                            type="text"
                            name="Venue.Name"
                            onClick={() => toggleVenueModal()}
                            onBlur={() => props.handleSubmit()}
                            autoComplete="off"
                          />
                          {/* {props?.values?.Venue?.Address && (
                            <span className="fa-sm mt-0">
                              {props?.values?.Venue?.Address?.split(",")?.[0]}
                            </span>
                          )} */}

                          <ErrorMessage
                            className="error-message"
                            name="Venue.Name"
                            component="div"
                          />
                        </FormField>
                      ) : (
                        <FormField
                          fullWidth={true}
                          label={intl.formatMessage({
                            id: "venue",
                            defaultMessage: "Venue",
                          })}
                        >
                          <Field
                            className="form-control"
                            type="text"
                            value="N/A"
                            onClick={() => toggleVenueModal()}
                            onBlur={() => props.handleSubmit()}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Venue.Name"
                            component="div"
                          />
                        </FormField>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <FormField
                        fullWidth={true}
                        label={intl.formatMessage({ id: "city" })}
                      >
                        {props.values.Venue && props.values.Venue.Name ? (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="Venue.City"
                              value={props.values.Venue.City}
                              onChange={(e) => {
                                props.setFieldValue(
                                  "Venue.City",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  dispatch(
                                    commonActions.searchCity(
                                      e.currentTarget.value
                                    )
                                  );
                                } else if (e.currentTarget.value.length < 3) {
                                  dispatch(commonActions.emptyCities());
                                }
                              }}
                              onBlur={() => props.handleSubmit()}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Venue.City"
                              component="div"
                            />
                          </>
                        ) : (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="City"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "City",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  dispatch(
                                    commonActions.searchCity(
                                      e.currentTarget.value
                                    )
                                  );
                                } else if (e.currentTarget.value.length < 3) {
                                  dispatch(commonActions.emptyCities());
                                }
                              }}
                              value={props.values.City}
                              onBlur={() => {
                                props.handleSubmit();
                                dispatch(commonActions.emptyCities());
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="City"
                              component="div"
                            />
                          </>
                        )}
                      </FormField>
                      <DropDownList
                        searchList={cities}
                        setData={(id) => {
                          props.values.Venue && props.values.Venue.Name
                            ? setStateOrNotStateVenue(cities[id].Address, props)
                            : setStateOrNotState(cities[id].Address, props);
                          dispatch(commonActions.emptyCities());
                        }}
                        displayValue={"Address"}
                      />
                    </div>
                    <div className="col-md-4">
                      <FormField
                        fullWidth={true}
                        label={intl.formatMessage({ id: "stateVenue" })}
                      >
                        {props.values.Venue && props.values.Venue.Name ? (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="Venue.State"
                              onBlur={() => props.handleSubmit()}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Venue.State"
                              component="div"
                            />
                          </>
                        ) : (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="State"
                              onChange={(e) => {
                                console.log("state change", e);
                                props.setFieldValue("State", e.target.value);
                              }}
                              onBlur={() => props.handleSubmit()}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="State"
                              component="div"
                            />
                          </>
                        )}
                      </FormField>
                    </div>
                    <div className="col-md-4">
                      <FormField
                        fullWidth={true}
                        label={intl.formatMessage({ id: "country" })}
                      >
                        {props.values.Venue && props.values.Venue.Name ? (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="Venue.Country"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "Venue.Country",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  dispatch(
                                    commonActions.searchCountry(
                                      e.currentTarget.value
                                    )
                                  );
                                }
                              }}
                              onBlur={() => props.handleSubmit()}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Country"
                              component="div"
                            />
                          </>
                        ) : (
                          <>
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="Country"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "Country",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  dispatch(
                                    commonActions.searchCountry(
                                      e.currentTarget.value
                                    )
                                  );
                                } else if (e.currentTarget.value.length < 3) {
                                  dispatch(commonActions.emptyCountries());
                                }
                              }}
                              onBlur={() => {
                                props.handleSubmit();
                                dispatch(commonActions.emptyCountries());
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Venue.Country"
                              component="div"
                            />{" "}
                          </>
                        )}
                      </FormField>
                      <DropDownList
                        searchList={countries}
                        setData={(id) => {
                          props.values.Venue && props.values.Venue.Name
                            ? props.setFieldValue(
                                "Venue.Country",
                                countries[id].Name
                              )
                            : props.setFieldValue(
                                "Country",
                                countries[id].Name
                              );
                          dispatch(commonActions.emptyCountries());
                        }}
                        displayValue={"name"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12"
                      // onFocus={() => setShowSaveButton(true)}
                      // onBlur={() => setShowSaveButton(false)}
                    >
                      <div className="d-flex align-items-center justify-content-between w-100 py-2">
                        <label className="d-flex align-items-center labelTitle my-2">
                          <i className="fas fa-align-left mr-2"></i>

                          <FormattedMessage
                            id="generalNotes"
                            defaultMessage="General Notes"
                          />
                        </label>
                        <button
                          className="btn btn-primary px-2"
                          onClick={() => setShowSaveButton((prev) => !prev)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      </div>

                      {/* <ReactQuill
                        theme="snow"
                        value={props.values.Notes}
                        onChange={(val) => {
                          console.log("Value on the notes", val);
                          props.setFieldValue("Notes", val);
                          //setNotes(val);
                        }}
                      /> */}

                      <RichTextComponent
                        toolbarSettings={{
                          items: [
                            "Bold",
                            "Italic",
                            "Underline",
                            "StrikeThrough",
                            "|",
                            "Formats",
                            "Alignments",
                            "OrderedList",
                            "UnorderedList",
                            "|",
                            "CreateLink",
                            "Image",
                            "|",
                            "SourceCode",
                            "Undo",
                            "Redo",
                          ],
                        }}
                        readOnly={showSaveButton ? false : true}
                        value={props.values.Notes}
                        handleChange={({ value }) => {
                          props.setFieldValue("Notes", value);
                        }}
                      />

                      <div
                        className={`d-flex w-100 justify-content-end mt-2 mb-1 general-save-btn ${
                          showSaveButton ? "active" : "inactive"
                        }`}
                        style={{
                          display: showSaveButton ? "flex" : "none",
                        }}
                      >
                        <button
                          className={"btn btn-primary mr-2"}
                          type="button"
                          onClick={() => {
                            setShowSaveButton(false);
                            updateNote(props.values.Notes);
                          }}
                        >
                          Save
                        </button>

                        <button
                          type="button"
                          className={"btn btn-danger"}
                          onClick={() => setShowSaveButton(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EventDetail;
