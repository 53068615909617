import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import { sortArray } from "../../../common/helpers";
import { groupBy } from "../../../utils";

const sortData = (data) => {
  let filterDeleted = data.filter((x) => x.Deleted === null);
  filterDeleted = sortArray(filterDeleted, "DepartureDate");
  let filterDeletedGrouped = Object.values(
    groupBy(filterDeleted, "Connection")
  ); //Groupby connection
  // let filterDeletedGrouped =groupBy(filterDeleted, "Connection");//Groupby connection
  //Now just take one per connection
  let finalFlight = [];

  filterDeletedGrouped.forEach((item) => {
    let exist = finalFlight.filter(
      (x) => x.Connection === item[0].Connection
    )[0];
    if (!exist) {
      item[0].Count = item.length;
      if (item.length > 1) {
        //Add arrival from last flight
        //     item[0].To =
      }
      finalFlight.push(item[0]);
    }
  });

  return finalFlight;
};

export function getFlights(event) {
  return async (dispatch) => {
    const localFlights = await db
      .table("flights")
      .where({
        EventGUID: event,
      })
      .toArray();

    dispatch({
      type: constant.GET_FLIGHTS,
      data: localFlights,
    });
    try {
      const request = await apiService.get(
        "/Flights/FlightsForEvent?EventGUID=" + event
      );

      if (request.data.Status === "ok") {
        await db.table("flights").clear(); //Cleaning Local DB
        await db.table("flights").bulkPut(request.data.Response);
      }

      console.log("get flight action triggered", request, event);
      //   debugger;
      dispatch({
        type: constant.GET_FLIGHTS_SUCCESS,
        data: request.data.Response,
      });
    } catch (error) {
      debugger;
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_FLIGHTS: {
      return {
        ...state,
        loadingFlights: true,
        flights: sortData(action.data),
      };
    }
    case constant.GET_FLIGHTS_SUCCESS: {
      return {
        ...state,
        flights: sortData(action.data),
        loadingFlights: false,
      };
    }
    default:
      return state;
  }
}
