import React from "react";
import { Formik, Form, Field } from "formik";
import { SimpleLoader } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const LabelForm = ({ toggle, resource, submitFunction, loading }) => {
  return (
    <div className="">
      <div className="event-page" style={{ overflow: "visible" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left d-flex w-100 justify-content-between">
              <h1>
                <FormattedMessage
                  id="addLabel"
                  defaultMessage={resource.Label ? "Edit Label" : "Add a Label"}
                />
              </h1>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </button>
            </div>
          </div>
          <div className="event-inside pl-0">
            <div className="event-content " style={{ paddingBottom: 24 }}>
              <Formik
                initialValues={resource}
                onSubmit={(values) => submitFunction(values, toggle)}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {(props) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 my-3">
                        <label>
                          <FormattedMessage id="tags" defaultMessage="Label" />
                        </label>
                        <Field name="Label" className="form-control" />
                      </div>
                      {loading ? (
                        <div className="w-100 d-flex align-items-center justify-content-center text-center">
                          <SimpleLoader loading={loading} />
                        </div>
                      ) : (
                        <div className="col-12">
                          <button className="btn btn-primary" type="submit">
                            <FormattedMessage id="done" defaultMessage="Done" />
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelForm;
