import React, { Component } from "react";
import TermsAndCondition from "./termsAndCondition";
import { connect } from "react-redux";
import history from "../../../components/common/history";

class TermsAndConditionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 2,
      isElectron: false
    };
  }

  componentDidMount() {
    this.props.getAuthTerms(1);
    this.props.match.params.id &&
      this.setState({
        id: this.props.match.params.id
      });
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      this.setState({ isElectron: true });
    } else {
      this.setState({ isElectron: false });
    }
  }

  render() {
    let { isElectron } = this.state;
    let { termsAndCondition } = this.props;
    return (
      <TermsAndCondition
        data={termsAndCondition}
        id={this.state.id}
        back={() => history.goBack()}
        isElectron={isElectron}
      />
    );
  }
}

const mapStateToProps = state => ({
  termsAndCondition: {}
});

const mapDispatchToProps = {
  getAuthTerms: () => console.log("getAuthTerms")
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionContainer);
