 import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

import * as constant from "./constants";
const sub_url = "/Events/Details";

async function eventDetailsLocal(guid) {
  let request = await db.table("event").where({ GUID: guid }).first();

  return request;
}

export function eventDetail(guid, errorCallback, redirectCallback, onlyLocal) {
  
  return async (dispatch) => {
    dispatch({
      type: constant.GET_EVENT,
    });
    try {
      let local = await db.table("event").where({ GUID: guid }).first();

      if (local !== undefined) {
        dispatch({
          type: constant.GET_EVENT_SUCCESSFULL,
          event: local,
          venue: local.Venue ? local.Venue : null,
        });
      }


      let request = await apiService.get(sub_url, { params: { GUID: guid } });
      let weatherRequest;
      if(request.data.response !== undefined ){
        //Validate path
        let position = request.data.response.Latitude !== null? request.data.response.Latitude+","+ request.data.response.Longitude: request.data.response.City+", "+request.data.response.Country;
       weatherRequest = await api.get(`/weathers/request?query=${position}`);
      }else{
        weatherRequest = null;
      }

      if (request.data.status === "ok") {
        await db.table("event").put(request.data.response);
      }

      local = await db.table("event").where({ GUID: guid }).first();
      if (local !== undefined) {
        dispatch({
          type: constant.GET_EVENT_SUCCESSFULL,
          event: local,
          venue: local.Venue ? local.Venue : null,
          weather: weatherRequest !== null? weatherRequest.data: weatherRequest,
        });
      }
      redirectCallback();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.GET_EVENT_ERROR,
      });
      errorCallback();
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.GET_EVENT_SUCCESSFULL: {
      return {
        ...state,
        loading: false,
        event: action.event,
        eventVenue: action.venue,
        weather: action.weather,
      };
    }
    case constant.GET_EVENTS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
