import React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import {
  addTicketsSchema,
  editTicketSchema,
} from "../../utils/validationsSchemas";
import { SimpleLoader } from "../common";
import { IMAGES } from "../../constant";
import Select from "react-select";
import { getBasicInfo } from "../../utils";
import { PassTypesModel } from "../../common/helpers";
import { useIntl, FormattedMessage } from "react-intl";
const TicketFormResponse = ({
  mode,
  approval,
  updateRequest,
  ticket,
  loading,
  toggle,
  teams,
}) => {
  const intl = useIntl();
  const pickupLocations = [
    { value: "null", label: "-" },
    { value: "WillCall", label: "Will Call" },
    { value: "In-Hand", label: "In-Hand" },
  ];

  if (!ticket) {
    return <></>;
  }
  // ticket.ConfirmedNote =
  //   ticket.ConfirmedNote === null ? "" : ticket.ConfirmedNote;
  // console.log({ ticket });
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title">
                {approval ? (
                  <FormattedMessage
                    id="Approve Request"
                    defaultMessage="Approve Request"
                  />
                ) : (
                  <FormattedMessage
                    id="Decline Request"
                    defaultMessage="Decline Request"
                  />
                )}
              </h5>
              {ticket !== null ? <p>{ticket.RequestByName}</p> : null}
            </div>
            <div className="close-icon d-none d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <Formik
            initialValues={ticket}
            onError={(e) => {
              console.error(e);
              debugger;
            }}
            onSubmit={(values) => {
              updateRequest(
                approval
                  ? {
                      ...values,
                      Passes: ticket.Passes,
                      ConfirmedPasses: values.Passes,
                      ConfirmDate: new Date(),
                      Confirmed: true,
                      ConfirmAccountGUID: getBasicInfo().GUID,
                    }
                  : { ...values, ConfirmedPasses: 0, Confirmed: false },
                false,
                true
              );
            }}
            validationSchema={editTicketSchema}
          >
            {(props) => (
              <Form className="mt-3">
                <div className="event-inside px-0">
                  <div className="event-content">
                    <div className="row">
                      <div className="col-12  ">
                        <h6>
                          <FormattedMessage
                            id="Admin Response"
                            defaultMessage="Admin Response"
                          />
                        </h6>
                        <hr />
                      </div>
                    </div>
                    {!approval ? null : (
                      <div className="row shrinker">
                        {props.values.PassType === "Ticket" ? (
                          <>
                            <FormField
                              additionalClasses="col-md-4  pr-2 "
                              fullWidth={false}
                              label={
                                <FormattedMessage
                                  id="Section"
                                  defaultMessage="Section"
                                />
                              }
                            >
                              <Field
                                className="form-control"
                                type="text"
                                name="Section"
                              />
                              <ErrorMessage
                                className="error-message"
                                name="Section"
                                component="div"
                              />
                            </FormField>
                            <FormField
                              additionalClasses="col-md-4 px-2 "
                              fullWidth={false}
                              label={
                                <FormattedMessage
                                  id="Row"
                                  defaultMessage="Row"
                                />
                              }
                            >
                              <Field
                                className="form-control"
                                type="text"
                                name="Row"
                              />
                              <ErrorMessage
                                className="error-message"
                                name="Row"
                                component="div"
                              />
                            </FormField>
                            <FormField
                              additionalClasses="col-md-4 pl-2 "
                              fullWidth={false}
                              label={
                                <FormattedMessage
                                  id="Seat"
                                  defaultMessage="Seat"
                                />
                              }
                            >
                              <Field
                                className="form-control"
                                type="text"
                                name="Seat"
                              />
                              <ErrorMessage
                                className="error-message"
                                name="Seat"
                                component="div"
                              />
                            </FormField>
                          </>
                        ) : null}
                      </div>
                    )}

                    <div className="row px-3">
                      <FormField
                        fullWidth={true}
                        label={intl.formatMessage({ id: "Admin Notes" })}
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name={
                            <FormattedMessage
                              id="NoteConfirmed"
                              defaultMessage="NoteConfirmed"
                            />
                          }
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="NoteConfirmed"
                        />
                      </FormField>
                    </div>
                    <div className="row">
                      <div className="col-12 bgTitleModal py-1 mt-2 text-dark">
                        <h3>
                          <FormattedMessage
                            id="Request Information"
                            defaultMessage="Request Information"
                          />
                        </h3>
                      </div>
                      <div className="col mb-2 ">
                        <label>
                          <FormattedMessage
                            id="Requested by"
                            defaultMessage="Requested by"
                          />
                        </label>
                        <Select
                          placeholder={
                            props.values.RequestAccountGUID
                              ? teams.find(
                                  (m) =>
                                    m.value === props.values.RequestAccountGUID
                                ).label
                              : "Select"
                          }
                          className="border-bottom no-outline"
                          isSearchable={true}
                          options={teams}
                          onChange={(value) => {
                            props.setFieldValue(
                              "RequestAccountGUID",
                              value.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <FormField
                        additionalClasses=" pr-2"
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Guest First Name"
                            defaultMessage="Guest First Name"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="GuestNames"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="GuestNames"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Guest Last Name"
                            defaultMessage="Guest Last Name"
                          />
                        }
                        additionalClasses=" pl-2"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="GuestLastName"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="GuestLastName"
                          component="div"
                        />
                      </FormField>
                    </div>
                    <div className="row">
                      <FormField
                        fullWidth={false}
                        additionalClasses=" pr-2"
                        label={
                          <FormattedMessage
                            id="Type of Request"
                            defaultMessage="Type of Request"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="PassType"
                          value={PassTypesModel[props.values.PassType]}
                          disabled
                        />
                        <ErrorMessage
                          className="error-message"
                          name="PassType"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        additionalClasses="pl-2"
                        label={
                          <FormattedMessage
                            id="Confirmed Amount"
                            defaultMessage="Confirmed Amount"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="number"
                          min="0"
                          name={approval ? "Passes" : "ConfirmedPasses"}
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Passes"
                        />
                      </FormField>
                    </div>
                    <div className="row ">
                      <div className="col-md-6  pr-2">
                        <label>
                          <FormattedMessage
                            id="Pickup Location"
                            defaultMessage="Pickup Location"
                          />
                        </label>
                        <Select
                          className="border-bottom mb-2 no-outline"
                          isSearchable
                          placeholder={
                            props.values.PickupLocation
                              ? pickupLocations.find(
                                  (l) => l.value === props.values.PickupLocation
                                )?.label
                              : "Select"
                          }
                          options={pickupLocations}
                          onChange={(value) =>
                            props.setFieldValue("Pickup", value.value)
                          }
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Pickup"
                          component="div"
                        />
                      </div>

                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Notify To"
                            defaultMessage="Notify To"
                          />
                        }
                        additionalClasses=" pl-2 d-none"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="NotifyTo"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="NotifyTo"
                          component="div"
                        />
                      </FormField>
                    </div>

                    <div className="row px-3">
                      <FormField fullWidth={true} label="Note">
                        <Field
                          className="form-control"
                          type="text"
                          name={
                            <FormattedMessage
                              id="Notes"
                              defaultMessage="Notes"
                            />
                          }
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Notes"
                        />
                      </FormField>
                    </div>
                  </div>
                  <div className="py-3">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <div className="row ">
                        <div className="col-12">
                          {!approval ? (
                            <button
                              type="submit"
                              className="btn btn-danger block font-weight-bold  btn-block"
                            >
                              <FormattedMessage
                                id="Decline"
                                defaultMessage="Decline"
                              />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-success block font-weight-bold  btn-block"
                            >
                              <FormattedMessage
                                id="Approve"
                                defaultMessage="Approve"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TicketFormResponse;
