import React, { Component } from "react";
import Login from "./login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ROUTES } from "../../../constant";
import history from "../../../components/common/history";
import { ToastNotificationError } from "../../../components/common";

import * as eventActions from "../../event/redux/actions";
import * as authActions from "../redux/actions";
class LoginContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      rememberMeChecked: false,
      showSuccessPopup: false,
      loading: false
    };
  }

  componentDidMount() {
    this.props.eventActions.initEvents();
  }


  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  toggleRememberMe = () => {
    this.setState({
      rememberMeChecked: !this.state.rememberMeChecked
    });
  };

  /**
   * @description this. function is used to set state value
   * @param {string} key contain name of state
   * @param {string} value contain value to set
   */
  handleChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  /**
   * @description this function is used to validate user details
   *         rememberMe(this.state.email, this.state.password);
   *    setAccessToken(token);
   *              this.onClickNavigation(`/dashboard/isLogin`);
   */
  handleSubmit = (email, password, accessCode) => {
    // if(accessCode !=="august2021@" && accessCode !== "beta2021"){
    //   password = "";

    // }


    // localStorage.setItem("LoginMode", accessCode === "beta2021"? "beta":  accessCode);

    this.props.authActions.login(email, password, () =>
    
      this.onClickNavigation(ROUTES.SELECT_ORGANIZATION)
    );
  };

  handleSuccess = () => {
    this.setState({
      showSuccessPopup: true
    });
  };

  /**
   * @description this function is used to navigate to another page
   */
  onClickNavigation = routes => {
    
    window.location=routes;
    return;
    history.push({
      pathname: routes
    });
  };

  render() {
    let { auth, authActions } = this.props;
    return (
      <Login
        {...this.state}
        auth={auth}
        handleSubmit={this.handleSubmit}
        onInputFieldChange={this.onInputFieldChange}
        onClickNavigation={this.onClickNavigation}
        handleChange={this.handleChange}
        togglePassword={this.togglePassword}
        toggleRememberMe={this.toggleRememberMe}
        handleSuccess={this.handleSuccess}
        dataStateLenguage={this.props.dataStateLenguage}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return {
    eventActions: bindActionCreators({ ...eventActions }, dispatch),
    authActions: bindActionCreators({ ...authActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
