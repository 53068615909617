import * as constant from "./constants";
import api from "../../../common/api";
import { resourceUrl } from "../../../utils";
import uuid from "uuid";

export function moveResource(resource, type, arrayOfEvents, success, refresh) {
  const url = resourceUrl(type);
  return async (dispatch) => {
    dispatch({
      type: constant.MOVING_RESOURCE,
    });
    delete resource.HotelReservations;
    delete resource.GroundStaffs;
    delete resource.Itineraries;
    try {
      await api.post(url, { ...resource, Deleted: new Date() });
      arrayOfEvents.forEach(async (event) => {
        await api.post(url, {
          ...resource,
          EventGUID: event.GUID,
          Deleted: null,
          GUID: uuid.v4(),
        });
      });
      dispatch({
        type: constant.MOVED_RESOURCE,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.MOVING_RESOURCE_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.MOVING_RESOURCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.MOVED_RESOURCE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.MOVING_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
