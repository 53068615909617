import React from 'react';
import parse from 'html-react-parser';
import { IMAGES } from '../../../constant';

const PrivacyPolicy = (props) => {
    let { data, id, isElectron, back} = props;
    return (
        <div className={`${id === "1" ? "privacy-bg-light" : "privacy-bg"}`}>
             {isElectron &&
                <div className="terms-head">
                    <div className="close-bg" onClick={() => back()}>
                        <img src={IMAGES.CROSS_ICON} width="16" height="16" alt="close" />
                    </div>
                </div>
            }
            { data && parse(data.body) }
        </div >
    );
};

export default PrivacyPolicy;
