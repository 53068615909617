import React from "react";
import { IMAGES } from "../../constant";
import { TextInput } from "../form";
import { TrashCan as DeleteIcon } from "mdi-material-ui";
import {injectIntl, FormattedMessage} from 'react-intl'

const NonAppUser = (props) => {
  let { toggle } = props;
  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section move-modal ">
          <div className="text-head">
            <div className="text-left">
              <h1>Manage Non-App User</h1>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="event-inside"></div>

          <div className="mt-2 ">
            <table className="table">
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {[0].map((item) => (
                  <tr key={item} className="border-bottom-normal">
                    <th width="25%">
                      <TextInput
                        filled={"true"}
                        placeholder={"First name"}
                        type="text"
                      />
                    </th>
                    <th width="25%">
                      <TextInput
                        filled={"true"}
                        placeholder={"Last name"}
                        type="text"
                      />
                    </th>
                    <th width="40%">
                      <TextInput
                        filled={"true"}
                        placeholder={"Email"}
                        type="email"
                      />
                    </th>
                    <th width="10%" style={{ color: "white" }}>
                      <button className="btn-reset">
                        <DeleteIcon />
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="event-inside">
            <div className="event-content d-flex justify-content-end align-items-center">
              <button className="btn primary w-200 ml-3"><FormattedMessage
                    id="done"
                    defaultMessage="Done"/></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonAppUser;
