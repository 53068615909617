import * as constant from "./constants";
import api from "../../../common/api";

export function addFlightStaff(staffs, flight, success) {
  const url = "/FlightStaff/AddEditBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_PASSENGERS,
      });
      const finalStaffs = staffs.map((s) => ({
        ...s,
        FlightGUID: flight.GUID,
        ReservationNumber:
          s.ReservationNumber && s.ReservationNumber.length > 0
            ? s.ReservationNumber.toString()
            : null,
        SeatNumber:
          s.SeatNumber && s.SeatNumber.length > 0
            ? s.SeatNumber.toString()
            : null,
        TicketNumber:
          s.TicketNumber && s.TicketNumber.length > 0
            ? s.TicketNumber.toString()
            : null,
      }));
      await api.post(url, finalStaffs);
      dispatch({
        type: constant.EDITED_PASSENGERS,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_PASSENGERS_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_PASSENGERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_PASSENGERS:
    case constant.EDITING_PASSENGERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
