import * as CONSTANT from "./constants";
import api from "../../../common/api";

const sub_url = "Roles/ToggleNotification";

export function toggleNotifications(team, callback) {
  return async (dispatch) => {
    dispatch({
      type: CONSTANT.TOGGLING_NOTIFICATIONS_TEAM,
    });
    try {
      const request = await api.get(sub_url, {
        params: {
          RoleGUID: team.GUID,
        },
      });
      if (request.data.status === "ok") {
        dispatch({
          type: CONSTANT.TOGGLED_NOTIFICATIONS,
          data: request.data.response,
        });
        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.TOGGLING_NOTIFICATIONS_TEAM: {
      return {
        ...state,
        loading: true,
      };
    }
    case CONSTANT.TOGGLED_NOTIFICATIONS: {
      const role = action.data;
      return {
        ...state,
        loading: false,
        teams: state.teams.map((team, index) => {
          if (team.GUID === role.GUID) {
            if (team.AllowToReceiveNotifications === null) {
              team.AllowToReceiveNotifications = false;
              return team;
            }
            team.AllowToReceiveNotifications = !team.AllowToReceiveNotifications;
            return team;
          }

          return team;
        }),
      };
    }
    default:
      return state;
  }
}
