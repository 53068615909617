import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function editEvent(event) {
  const url = `/Events/Edit`;
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_EVENT,
    });
    try {
      await db.table("events").put(event);
      await db.table("event").put(event);
      const request = await api.post(url, {
        Name: event.Name,
        EventType: event.EventType,
        EventCapacity: event.EventCapacity,
        Date: `${event.Date}`,
        VenueGUID: event.Venue && event.Venue.Name ? event.VenueGUID : null,
        Notes: event.Notes,
        City: event.City,
        Country: event.Country,
        State: event.State,
        Status: event.Status,
        Latitude: event.Latitude,
        Longitude: event.Longitude,
        GUID: event.GUID,
        OrganizationGUID: event.OrganizationGUID,
        LastSync: event.LastSync,
        DateTime: event.DateTime,
        CreationDate: event.CreateDate,
        Deleted: null,
        Image: event.Image,
        AllowUsersToRequestTickets: event.AllowUsersToRequestTickets,
        WillCallCity: event.WillCallCity,
        WillCallHours: event.WillCallHours,
        WillCallState: event.WillCallState,
        WillCallAddress: event.WillCallAddress,
        WillCallCountry: event.WillCallCountry,
        WillZipCode: event.WillZipCode,
        WillCallPhone: event.WillCallPhone,
        WillCallComment: event.WillCallComment,
      });
      let venueRequest = null;
      if (request.data.Status === "ok"  && request.data.Response.VenueGUID) {
         venueRequest = await api.get(
          `/Venues/Details?GUID=${request.data.Response.VenueGUID}`
        );
      }
      dispatch({
        type: constant.EDITED_EVENT,
        data: { ...request.data.Response, Venue: venueRequest &&  venueRequest.data },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.EDITING_EVENT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_EVENT: {
      return {
        ...state,
        loading: false,
        event: { ...action.data },
      };
    }
    case constant.EDITING_EVENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
