import * as constant from "./constants";
import db from "../../../common/db";

export function cleanUpSession(redirect) {
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.CLEANING_SESSION,
      });
      await db.table("organizations").clear();
      localStorage.clear();
      dispatch({
        type: constant.CLEANED_SESSION,
      });
      redirect();
    } catch (e) {}
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CLEANING_SESSION: {
      return {
        ...state,
      };
    }
    case constant.CLEANED_SESSION: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
