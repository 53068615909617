import React from "react";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import api from "../../common/api";
import { FileDetailComponent } from "../../components/common/FileDetailComponent";
import { IMAGES } from "../../constant";

function FileDetails(props) {
  const intl = useIntl();
  const stateItem = props.parentState.SelectedFile.value;

  const item = {
    Type: stateItem.Type,
    GUID: stateItem.GUID,
    Name: stateItem.Name,
    Path: stateItem.Path,
    Update: stateItem.Update,
    UserId: stateItem.UserId,
    EventShared: stateItem.EventShared,
    ParentGUID: stateItem.ParentGUID,
    Note: stateItem.Note,
    Color: stateItem.Color,
    Created: stateItem.Created,
  };

  const deleteFile = (item) => {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDelete" }) + item.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then(async (result) => {
      if (result.value) {
        props.parentState.UI.isLoading.set(
          !props.parentState.UI.isLoading.value
        );
        const request = await api.get("/File/DeleteFile?Id=" + item.GUID);
        props.parentState.UI.isLoading.set(
          !props.parentState.UI.isLoading.value
        );
        let response = request.data;
        if (response.Status === "ok") {
          props.requestFolderRemote();
        }
      }
    });
  };
  const handleSubmitChanges = async (values) => {
    props.parentState.SelectedFile.Name.set(values.Name);
    props.parentState.SelectedFile.Note.set(values.Note);

    if (item.Type === "folder") {
      let newItem = {
        GUID: item.GUID,
        Name: values.Name,
        Note: values.Note,
        Color: item.Color,
        UserGUID: item.UserId,
        ParentGUID: item.ParentGUID !== null ? item.ParentGUID : null,
        EventShared: item.EventShared,
      };

      let copy = JSON.stringify(newItem);
      //We have to sent to api
      const request = await api.post(`/File/AddEditFolder`, copy);
    } else {
      let newItem = {
        GUID: item.GUID,
        Name: values.Name,
        Note: values.Note,
        Path: item.Path,
        UserGUID: item.UserId,
        ParentGUID: item.ParentGUID !== null ? item.ParentGUID : null,
        Type: item.Type,
        EventShared: item.EventShared,
      };
      const requestGeneral = await api.post(
        "/File/AddEditFileWithoutFile",
        newItem
      );
    }
    await props.requestFolderRemote();
  };

  return (
    <>
      {/* <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-sm d-none "
            onClick={() => props.toggleEvents()}
            title={intl.formatMessage({ id: "linkToEvent" })}
          >
            <i className="fas fa-link text-secondary"></i>
          </button>
          {item.Type !== "link" ? (
            <button
              className="btn btn-sm"
              title={intl.formatMessage({ id: "preview" })}
              onClick={() =>
                props.parentState.showViewerModal.set(
                  !props.parentState.showViewerModal.value
                )
              }
            >
              <i className="far fa-file text-secondary"></i>
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm "
            onClick={() => copyToClipboard(item)}
            title={intl.formatMessage({ id: "copyToClipboard" })}
          >
            <i className="fas fa-share-alt text-secondary"></i>
          </button>
          <a
            href={
              item.Type === "link"
                ? item.Path
                : `https://app.plnify.com/files/${item.Path}`
            }
            target="_blank"
            title={intl.formatMessage({ id: "openDownloadFile" })}
            className="btn btn-sm "
          >
            <i className="fas fa-download text-secondary"></i>
          </a>
          <button
            type="button"
            className="btn btn-sm text-danger"
            onClick={() => deleteFile(item)}
            title={intl.formatMessage({ id: "deleteFile" })}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
        <div className="col-12">
          <i
            className={
              fileIcon(props.parentState.SelectedFile.value) + " fa-7x"
            }
          ></i>
          <h4 className="title mt-2">
            {props.parentState.SelectedFile.Name.value}
          </h4>
          <hr />
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={JSON.parse(
            JSON.stringify(props.parentState.SelectedFile.value)
          )}
          onSubmit={(values) => handleSubmitChanges(values)}
        >
          {(context) => {
            return (
              <>
                <div className="col-12">
                  <small className="my-2">
                    <b>
                      <FormattedMessage
                        id="firstName.signUp"
                        defaultMessage="Name"
                      />
                    </b>
                  </small>
                  <Field
                    type="text"
                    name="Name"
                    className="form-control"
                    onBlur={context.handleSubmit}
                  />
                </div>
                <div className="col-12">
                  <small className="my-2">
                    <b>
                      <FormattedMessage id="notes" defaultMessage="Notes" />
                    </b>
                  </small>
                  <ReactQuill
                    theme="snow"
                    value={context.values.Note}
                    onBlur={() => context.handleSubmit()}
                    onChange={(content) => {
                      props.setFieldValue("Note", content);
                    }}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </div> */}
      <FileDetailComponent
        type={"FILE"}
        file={item}
        editAttachment={handleSubmitChanges}
        confirmDeleteAttachment={deleteFile}
        handleSubmit={handleSubmitChanges}
      />
    </>
  );
}

export default FileDetails;
