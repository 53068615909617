import moment from "moment";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import uuid from "uuid";
import db from "../../common/db";
import { ModalComponent, SimpleLoader } from "../common";
import BatchEventsModal from "../event/BatchEventsModal";
import EventFormHorizontal from "../event/eventFormHorizontal";


const OnboardingImport =(props)=>{
  const [state, setState] = useState({
    batchModal: false,
    caretOpen: false,
  });

  const toggleCaret = () => {
    setState(prev=>({...prev,
      caretOpen: !prev.caretOpen,
    }));
  };

  const toggleBatchModal = () => {
    setState(prev=>({...prev,
      batchModal: !prev.batchModal,
    }));
  };

  // const mountingData = async () => {
  //   const { addEventMultiple } = props;
  //   await db
  //     .table("batchEvent")
  //     .toArray()
  //     .then((res) => {
  //       if (res.length === 0) {
  //         toggleBatchModal(true);
  //       } else {
  //         res.forEach((e) => addEventMultiple(e));
  //       }
  //     });
  // };

  // const unmountingData = () => {
  //   props.clearMultipleState();
  // };

  const testValidation = () => {
    const {
      multipleEvents,
      updateMultipleEvent,
      addMultipleEvents,
    } = props;
    const events = multipleEvents;
    events.map((item) => {
      if (!item.Country && !item.State && !item.VenueGUID) {
        db.table("batchEvent")
          .update(item.GUID, { ...item, Error: true })
          .then(() => updateMultipleEvent({ ...item, Error: true }));
      } else {
        db.table("batchEvent")
          .update(item.GUID, { ...item, Error: false })
          .then(() => updateMultipleEvent({ ...item, Error: false }));
      }
    });

    setTimeout(addMultipleEvents, 600);
  };

  const {
    toggle,
    addEventMultiple,
    multipleEvents,
    clearAll,
    updateEvent,
    addBunchEvents,
    removeEvent,
    loading,
    defaultOrg,
  } = props;
  const { batchModal, caretOpen } = state;

  return (
    <div className="onBoarding contact-transparent ">
      <ModalComponent
        modal={batchModal}
        toggle={() => toggleBatchModal()}
        childeren={
          <BatchEventsModal
            toggle={() => toggleBatchModal()}
            addEventMultiple={addEventMultiple}
            clearAll={clearAll}
            addBunchEvents={addBunchEvents}
            multipleEvents={multipleEvents}
          />
        }
      />
      <div className="event-page modalContainer onBoardingImportEvents my-4">
        <div className="event-expennd-section multiple-contact">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title">Add Multiple Dates</h5>
            </div>
            <div className="text-right">
              <button className="btn text-light" onClick={() => toggle()}>
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div className="multipleEvents__toolbar d-flex align-items-center justify-content-between">
            <button
              onClick={() => clearAll()}
              className="btn secondary w-160 capital mr-2"
            >
              Clear All
            </button>

            <ButtonDropdown isOpen={caretOpen} toggle={toggleCaret}>
              <button
                onClick={() =>
                  addEventMultiple({
                    Name:
                      multipleEvents.length > 0 &&
                      multipleEvents[multipleEvents.length - 1].Name &&
                      multipleEvents[multipleEvents.length - 1].Name
                        ? multipleEvents[multipleEvents.length - 1].Name
                        : "",
                    Date:
                      multipleEvents.length > 0 &&
                      multipleEvents[multipleEvents.length - 1].Date &&
                      multipleEvents[multipleEvents.length - 1].Date
                        ? moment(
                            multipleEvents[multipleEvents.length - 1].Date
                          )
                            .add(1, "days")
                            .toDate()
                        : moment().toDate(),
                    GUID: uuid.v4(),
                    EventType:
                      multipleEvents.length > 0 &&
                      multipleEvents[multipleEvents.length - 1].EventType &&
                      multipleEvents[multipleEvents.length - 1].EventType
                        ? multipleEvents[multipleEvents.length - 1].EventType
                        : "",
                    EventTypeName:
                      multipleEvents.length > 0 &&
                      multipleEvents[multipleEvents.length - 1]
                        .EventTypeName &&
                      multipleEvents[multipleEvents.length - 1].EventTypeName
                        ? multipleEvents[multipleEvents.length - 1]
                            .EventTypeName
                        : "",
                    City: "",
                    Country: "",
                    Latitude: "",
                    State: "",
                    Longitude: "",
                    VenueGUID: "",
                    Venue: { Name: "" },
                    OrganizationGUID: defaultOrg.GUID,
                    Status: "Confirmed",
                  })
                }
                className="btn btn-primary block capital btn-action"
                style={{ fontSize: 15 }}
              >
                <i className="fa fa-plus"></i>
              </button>
              <DropdownToggle
                caret
                style={{ backgroundColor: "#2c9a67", borderColor: "#2c9a67" }}
                color="primary"
              />
              <DropdownMenu>
                <DropdownItem onClick={() => toggleBatchModal()}>
                  Add Multiple
                </DropdownItem>
                <DropdownItem onClick={() => toggleBatchModal()}>
                  Import .ics
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>

          <Scrollbars style={{ height: "calc(100vh - 10rem)" }}>
            {multipleEvents
              .sort(
                (a, b) =>
                  new Date(a.Date).getTime() - new Date(b.Date).getTime()
              )
              .map((_event) => (
                <EventFormHorizontal
                  key={_event.GUID}
                  event={_event}
                  updateEvent={updateEvent}
                  removeEvent={removeEvent}
                />
              ))}
          </Scrollbars>
          <div className="multipleEvents__footer d-flex align-items-center justify-content-end pr-2 py-2">
            {loading ? (
              <SimpleLoader loading={loading} />
            ) : (
              <button
                disabled={multipleEvents.length < 1}
                onClick={() => testValidation()}
                className="btn primary w-200"
              >
                Add To Calendar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingImport;
