import React, { useEffect, useState } from "react";
import { ModalComponent } from ".";
import EmailModal from "../hotel/emailModal";
import ShareLinkModal from "../production/ShareLinkModal";
import { getBasicInfo } from "../../utils";
import { ToastNotificationSuccess } from "..";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { handleCloseEmail } from "../../utils/closeMailModal";

//Object to receive
//Item = the object to work with
//ViaEmail = boolean that indicate if that option is available for that element
//ViaLink = boolean that indicate if that options is available for that element
//ViaClipboard = boolean that indicate if that option is available for that element
//Type = the type of object passed, helpful for identify how we can work the items

const SharingHub = (props) => {
  const [state, setState] = useState({
    UI: {
      Current: props.Item,
      ShareViaEmail: false,
      ShareViaLink: false,
    },
    AccountGUID: "",
  });

  useEffect(() => {
    var basicInfo = getBasicInfo();

    setState((prev) => ({
      ...prev,
      AccountGUID: basicInfo.GUID,
    }));
  }, []);

  const toggleShareEmail = () => {
    let processTheRecord = "";
    let title = "";
    switch (props.Type) {
      case "MOREDETAILS":
        break;
      case "NOTE":
        title = "Note";
        processTheRecord = props.Item.Details;

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));

        break;
      case "SHARECOLLAB":
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: props?.shareApi,
              Title: "Create your Ticket Request",
            },
          },
        }));

        break;
      case "CONTACT":
        title = "Contact";
        if (props.Item.Name !== undefined && props.Item.Name !== null) {
          let name =
            props.Item.Name +
            " " +
            (props.Item.LastName ? props.Item.LastName : "");
          title = name + " " + title;
          processTheRecord += name;
        }
        if (props.Item.Email) {
          processTheRecord += "<br/><b>Email: </b>" + props.Item.Email;
        }
        if (props.Item.MobilePhone) {
          processTheRecord += "<br/><b>Mobile: </b>" + props.Item.MobilePhone;
        }
        if (props.Item.OfficePhone) {
          processTheRecord +=
            "<br/><b>Office: </b>" +
            props.Item.OfficePhone +
            (props.Item.Extension ? " ext. " + props.Item.Extension : "");
        }
        if (props.Item.Position) {
          processTheRecord += "<br/><b>Position: </b>" + props.Item.Position;
        }

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;

      case "ATTACHMENT":
        title = "Attachment";
        if (props.Item.Name !== undefined && props.Item.Name !== null) {
          let name = props.Item.Name;
          title = name + " " + title;
          //processTheRecord += name;
        }
        let address = props.Item.Path.includes("https")
          ? props.Item.Path
          : "https://service.plnify.com/files/" + props.Item.Path;
        processTheRecord += "<br/>" + props.Item.Name + "";
        processTheRecord +=
          '<br/><a href="' + address + '" target="_blank">' + address + "</a>";
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      case "FLIGHT":
        title = "Flight";
        let content = props.Item.replace(/\n/g, "<br/>");

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: content,
              Title: title,
            },
          },
        }));
        break;
      case "ITINERARIES":
        title = "Itinerary";
        let contentItinerary = props.Item.replace(/\n/g, "<br/>");

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: contentItinerary,
              Title: title,
            },
          },
        }));
        break;
      default:
        break;
    }
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShareViaEmail: !prev.UI.ShareViaEmail,
      },
    }));
  };
  const toggleShareLink = () => {
    switch (props.Type) {
      case "MOREDETAILS":
        break;
      case "CONTACT":
        break;
      default:
        break;
    }

    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShareViaEmail: !prev.UI.ShareViaEmail,
      },
    }));
  };

  const copyToClipboard = () => {
    let processTheRecord = "";
    let title = "";
    switch (props.Type) {
      case "MOREDETAILS":
        break;
      case "NOTE":
        title = "Note";
        processTheRecord = props.Item.Details;
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      case "CONTACT":
        title = "Contact";
        if (props.Item.Name !== undefined && props.Item.Name !== null) {
          let name =
            props.Item.Name +
            " " +
            (props.Item.LastName ? props.Item.LastName : "");
          title = name + " " + title;
          processTheRecord += name;
        }
        if (props.Item.Email) {
          processTheRecord += "\nEmail: " + props.Item.Email;
        }
        if (props.Item.MobilePhone) {
          processTheRecord += "\nMobile: " + props.Item.MobilePhone;
        }
        if (props.Item.OfficePhone) {
          processTheRecord +=
            "\nOffice: " +
            props.Item.OfficePhone +
            (props.Item.Extension ? " ext. " + props.Item.Extension : "");
        }
        if (props.Item.Position) {
          processTheRecord += "\nPosition: " + props.Item.Position;
        }
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      case "ATTACHMENT":
        title = "Attachment";
        if (props.Item.Name !== undefined && props.Item.Name !== null) {
          let name = props.Item.Name;
          title = name + " " + title;
          processTheRecord += name;
        }
        processTheRecord +=
          "\n " +
          (props.Item.Path.includes("https")
            ? props.Item.Path
            : "https://service.plnify.com/files/" + props.Item.Path);

        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      case "FLIGHT":
        title = "Flight";

        processTheRecord += props.Item;
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      case "ITINERARIES":
        title = "Itinerary";

        processTheRecord += props.Item;
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }));
        break;
      default:
        break;
    }
    navigator.clipboard.writeText(processTheRecord);
    ToastNotificationSuccess(1, "Copied to Clipboard");
    props.toggle();
  };
  return (
    <>
      <ModalComponent
        toggle={() => handleCloseEmail(toggleShareEmail)}
        modal={state.UI.ShareViaEmail}
        childeren={
          <EmailModal
            subject={
              state?.UI?.Current?.Title !== undefined
                ? state?.UI?.Current?.Title
                : ""
            }
            content={
              state?.UI?.Current?.Content !== undefined
                ? state?.UI?.Current?.Content
                : ""
            }
            //  sendEmail={sendEmail}
            fromSection={props.Type}
            //event={event}
            toggle={() => toggleShareEmail()}
          />
        }
      />

      <ModalComponent
        modal={state.UI.ShareViaLink}
        toggle={() => toggleShareLink()}
        childeren={
          <ShareLinkModal
            toggle={toggleShareLink}
            ParentSectionGUID={
              props.ParentSectionGUID !== undefined
                ? props.ParentSectionGUID
                : null
            }
            AccountGUID={state.AccountGUID}
            name={
              state?.UI?.Current?.Title !== undefined
                ? state?.UI?.Current?.Title
                : ""
            }
            sectionType={props.Type}
            sectionGUID={
              state?.UI?.Current?.GUID !== undefined
                ? state?.UI?.Current?.GUID
                : ""
            }
          />
        }
      />
      <div className="event-page" style={{ overflow: "revert", zIndex: "100" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left d-flex w-100 justify-content-between">
              <h1 className="title">
                <FormattedMessage
                  id="shareOptions"
                  defaultMessage="Share Options"
                />
              </h1>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => props.toggle(false)}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </button>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row">
              {props.ViaEmail ? (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={() => {
                      toggleShareEmail();
                    }}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-paper-plane" />
                      <br />
                      E-Mail
                    </div>
                  </div>
                </div>
              ) : null}

              {props.ViaLink ? (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={() => {
                      toggleShareLink();
                    }}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-link" />
                      <br />
                      Link
                    </div>
                  </div>
                </div>
              ) : null}

              {props.ViaClipboard ? (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={
                      props.copyToClipBoard
                        ? () => props.copyToClipBoard()
                        : () => {
                            copyToClipboard();
                          }
                    }
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-copy" />
                      <br />
                      <FormattedMessage id="copy" defaultMessage="Copy" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharingHub;
