import React, { Component } from "react";
import Collab from "./Collab";
import api, { apiService } from "../../../common/api";
import { ToastNotificationSuccess } from "../../../components/common";

export class CollabContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      event: null,
      itemTowork: null,
      eventGUID: null,
      permissionType: 0,
      GUID: null,
      savingChanges: false,
    };
  }

  componentDidMount() {
    this.fetchCollab();
  }

  fetchCollab = async () => {
    const { match } = this.props;
    const request = await api.get(`/Collab/Details?Id=${match.params.guid}`);
    const response = JSON.parse(request.data.response);

    this.setState(
      {
        GUID: response.SectionGUID,
        permissionType: response.PermissionType,
        eventGUID: response.ParentSectionGUID,
        sectionType: response.SectionType,
      },
      () => {
        this.fetchEventDetails();
        this.fetchMoreDetails();
      }
    );
  };

  fetchEventDetails = async () => {
    const request = await apiService.get("/Events/Details", {
      params: { GUID: this.state.eventGUID },
    });
    this.setState({
      event: request.data.response,
    });
  };

  fetchMoreDetails = async () => {
    const request = await api.get("/eventmoredetails/details", {
      params: { GUID: this.state.GUID },
    });
    this.setState({
      itemTowork: request.data.response,
      loading: false,
      content: request.data.response.Content,
    });
  };

  saveTheContent = (content) => {
    const item = this.state.itemTowork;
    item.Content = content;
    this.setState({
      content,
      itemTowork: item,
    });
  };

  onSaveAll = async () => {
    this.setState({
      savingChanges: true,
    });
    await api.post("/EventMoreDetails/AddEdit", [this.state.itemTowork]);
    this.setState(
      {
        savingChanges: false,
      },
      () => ToastNotificationSuccess(Math.random(), "Changes saved")
    );
  };

  render() {
    const { itemTowork, event, content, savingChanges, permissionType } =
      this.state;
    if (this.state.loading) {
      return <div>Loading everything</div>;
    }

    return (
      <>
        <Collab
          event={event}
          saveTheContent={this.saveTheContent}
          content={content}
          item={itemTowork}
          savingChanges={savingChanges}
          onSaveAll={this.onSaveAll}
          permissionType={permissionType}
          sectionType={this.state.sectionType}
        />
      </>
    );
  }
}

export default CollabContainer;
