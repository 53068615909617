import React, { useState } from "react";
import { IMAGES, GROUPS_COLORS } from "../../../constant";
import { Scrollbars } from "react-custom-scrollbars";
import { SimpleLoader } from "../..";
import SelectableEvent from "../selectableEvent";
import { useIntl, FormattedMessage } from "react-intl";
import { GroupImage } from "./GroupImage";
import EventFilter from "../../common/EventFilter";
import useEventFilters from "../../../hooks/useEventFilters";

const EditGroup = (props) => {
  let {
    toggle,
    eventsList,
    editEventsSelected,
    toggleEvent,
    setColorGroup,
    setGroupName,
    setGroupImage,
    creatingGroup,
    groupNameEdit,
    groupColorEdit,
    groupImageEdit,
    groupImage,
    editModal,
    groupColor,
    editGroup,
  } = props;

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  let debounceTimer;
  const debouncedHandleColorChange = ({ target }) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setColorGroup(target.value);
    }, 500);
  };

  const handleColorChange = ({ target }) => {
    debouncedHandleColorChange({ target });
  };

  // const filterEvents = eventsList?.filter(
  //   (event) =>
  //     event.EventGroupGUID === null || event.EventGroup.Name === groupNameEdit
  // );

  // filterEvents?.sort((a, b) => {
  //   return (b.EventGroupGUID || "").localeCompare(a.EventGroupGUID || "");
  // });

  const filterEvents = eventsList?.sort((a, b) => {
    return (b.EventGroupGUID || "").localeCompare(a.EventGroupGUID || "");
  });

  const {
    eventGroups,
    eventToRender,
    eventFilterState,
    handleDateChange,
    handleFilterByGroup,
    handleFilterFrom,
    handleFilterTo,
    handleSearch,
  } = useEventFilters({ events: filterEvents });

  return (
    <div
      className={"organization-transparent"}
      onDragEnterCapture={handleDragIn}
    >
      <div className="event-page">
        <div className="event-expennd-section extendEventScreen">
          <div className="text-head">
            <div className="text-left">
              <h1>Update group</h1>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside h-auto">
            <div className="event-content">
              <div className="row px-0">
                <GroupImage
                  handleDragOut={handleDragOut}
                  onChangeDrop={setGroupImage}
                  groupImageEdit={groupImageEdit}
                  editModal={editModal}
                  groupColor={groupColorEdit}
                  setGroupImage={setGroupImage}
                  showDrop={showDrop}
                />

                <div className="col-md-12 px-0">
                  <div className="form-group">
                    <label htmlFor={"groupName"}>Group Name</label>
                    <input
                      id="groupName"
                      onChange={(e) => setGroupName(e.target.value)}
                      className="form-control fs-1"
                      placeholder="Insert your Group's Name"
                      value={groupNameEdit}
                    />
                  </div>
                </div>
                <div className="col-md-12 px-0">
                  <div className="form-group mb-1">
                    <label>Select a color for the group</label>
                  </div>
                  <div className="d-flex align-items-center">
                    {GROUPS_COLORS.map((color) => (
                      <button
                        onClick={() => setColorGroup(color)}
                        key={color}
                        style={{
                          backgroundColor: `${color}`,
                          border:
                            groupColorEdit === color
                              ? "3px solid black"
                              : "none",
                        }}
                        type="button"
                        className="clean-button btn-reset color-option pink"
                      ></button>
                    ))}
                    <input
                      type="color"
                      id="color-picker"
                      className="clean-button btn-reset color-option"
                      style={{
                        //backgroundColor: `${groupColor}`,
                        background:
                          "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
                        border:
                          groupColorEdit === "Picker"
                            ? "3px solid black"
                            : "none",
                      }}
                      onChange={handleColorChange}
                    />

                    <button
                      onClick={() => setColorGroup(null)}
                      key="clean"
                      style={{
                        backgroundColor: `#ccc`,
                        border: !groupColorEdit ? "3px solid black" : "none",
                      }}
                      title="Remove color"
                      type="button"
                      className="bg-none clean-button btn-reset color-option text-danger"
                    ></button>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label>Select event to include in this group</label>
                  <EventFilter
                    events={eventsList}
                    eventGroups={eventGroups}
                    eventFilterState={eventFilterState}
                    handleDateChange={handleDateChange}
                    handleFilterByGroup={handleFilterByGroup}
                    handleFilterFrom={handleFilterFrom}
                    handleFilterTo={handleFilterTo}
                    handleSearch={handleSearch}
                  />
                </div>
                <Scrollbars
                  style={{
                    height: "calc(100vh - 520px)",
                    color: "#000",
                  }}
                >
                  {eventToRender?.length > 0 ? (
                    eventToRender?.map((event) => (
                      <SelectableEvent
                        key={event.GUID}
                        event={event}
                        guidVersion={true}
                        eventsSelected={editEventsSelected}
                        toggleEvent={toggleEvent}
                      />
                    ))
                  ) : (
                    <div className="alert alert-info w-100 text-center">
                      No Events Found
                    </div>
                  )}
                </Scrollbars>
              </div>
            </div>
          </div>

          <div className="event-inside h-auto">
            <div className="event-content">
              <div className="row px-0">
                <div className="col-md-12 addGround__cta px-0 text-center">
                  {false ? (
                    <SimpleLoader loading={creatingGroup} />
                  ) : (
                    <button
                      onClick={() => editGroup()}
                      type="button"
                      className="btn primary"
                    >
                      <FormattedMessage id="done" defaultMessage="Done" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroup;
