import { CogSyncOutline } from "mdi-material-ui";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PropTypes } from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const CustomDropDrownMenu = ({
  items,
  selectedItem,
  DrownDownProp,
  hasSearch,
  handleSelectedItem,
  emptyStateComponent,
  selectedItemProp,
  handleSearch,
  search,
  hasTooltip,
  TooltipProp,
  TooltipPlacement,
  toogleStyle,
  toogleClass,
  dropDownStyle,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  let toggleStyles = toogleStyle ?? {
    minWidth: "200px",
    minHeight: "40px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} {...props}>
      <DropdownToggle
        style={toggleStyles}
        id="dropdown-autoclose-true"
        data-toggle="dropdown"
        className={`d-flex align-items-center bg-transparent text-black btn btn-outline-${
          toogleClass ?? "secondary"
        }`}
      >
        <>
          {!selectedItem || selectedItem[selectedItemProp] === null ? (
            emptyStateComponent()
          ) : (
            <div className="d-flex align-items-center">
              {selectedItem?.imgUrl && (
                <img
                  src={selectedItem?.imgUrl}
                  className="rounded-circle mr-2"
                  style={{ width: "25px", height: "25px" }}
                />
              )}
              <span>
                {props.renderBy === "todos"
                  ? selectedItem[selectedItemProp][0]
                  : selectedItem[selectedItemProp]}
              </span>
            </div>
          )}
          <i className="fa fa-angle-down ml-1" />
        </>
      </DropdownToggle>
      <DropdownMenu
        left
        className="p-0"
        style={
          dropDownStyle ?? {
            maxWidth: "200px",
            maxHeight: "200px",
            background: "white",
            alignContent: "center",
            overflow: "auto",
            zIndex: 9999,
          }
        }
      >
        {hasSearch && (
          <div>
            <input
              className="rounded form-control p-1"
              name="search"
              type="search"
              placeholder="Search"
              onChange={handleSearch}
              value={search}
              autoFocus
            />
          </div>
        )}
        {items.map((item, index) => {
          return hasTooltip ? (
            <OverlayTrigger
              key={index}
              placement={TooltipPlacement}
              overlay={
                <Tooltip id={`tooltip-${"right"}`}>{item[TooltipProp]}</Tooltip>
              }
            >
              <DropdownItem
                key={`${item?.GUID + Math.random()}`}
                onClick={() => handleSelectedItem(item)}
                className="rounded border-bottom p-2 d-flex align-items-center w-100"
              >
                {item?.imgUrl && (
                  <img
                    src={item.imgUrl}
                    alt="img"
                    className="rounded-circle mr-2"
                    style={{ width: "25px", height: "25px" }}
                  />
                )}
                <span>{item[DrownDownProp]}</span>
              </DropdownItem>
            </OverlayTrigger>
          ) : (
            <DropdownItem
              key={`${item?.GUID + Math.random()}`}
              onClick={() => handleSelectedItem(item)}
              className="rounded border-bottom p-2 d-flex align-items-center w-100"
            >
              {item?.imgUrl && (
                <img
                  src={item.imgUrl}
                  alt="img"
                  className="rounded-circle mr-2"
                  style={{ width: "25px", height: "25px" }}
                />
              )}
              <span>{item[DrownDownProp]}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomDropDrownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
  DrownDownProp: PropTypes.string.isRequired,
  hasSearch: PropTypes.bool,
  handleSelectedItem: PropTypes.func.isRequired,
  emptyStateComponent: PropTypes.func,
  handleSearch: PropTypes.func,
  search: PropTypes.string,
  hasTooltip: PropTypes.bool,
  TooltipProp: PropTypes.string,
  TooltipPlacement: PropTypes.string,
  toogleStyle: PropTypes.object,
  selectedItemProp: PropTypes.string,
};

export default CustomDropDrownMenu;
