import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Popup from "reactjs-popup";
import { GROUPS_COLORS } from "../../constant";

const MoreDetailsNavigation = (props) => {
  const [state, setState] = useState({
    IsEditing: false,
    Color: "red",
  });
  console.log("PRODUCTION iTEMS", props.parentState.ProductionItems);
  const productionItems = props.parentState.ProductionItems;

  const handleProductionItemsTitle = (index, value) => {
    props.setParentState((prev) => {
      let itemsToEdit = prev.ProductionItems;
      itemsToEdit[index] = { ...itemsToEdit[index], Title: value };
      return {
        ...prev,
        ProductionItems: itemsToEdit,
      };
    });
  };

  const handleProductionItemsColor = (index, value) => {
    props.setParentState((prev) => {
      let itemsToEdit = prev.ProductionItems;
      itemsToEdit[index] = { ...itemsToEdit[index], Color: value };
      return {
        ...prev,
        ProductionItems: itemsToEdit,
      };
    });
  };

  const handleSaveName = (e, from) => {
    if (e.key === "Enter" || from === "blur") {
      props.setParentState((prev) => {
        const title = e?.target?.value;
        return {
          ...prev,
          UI: {
            ...prev.UI,
            Current: { ...prev.UI.Current, Title: title },
          },
        };
      });

      props.saveRemote();
      setState((prev) => ({
        ...prev,
        IsEditing: !prev.IsEditing,
      }));
    }
  };

  return (
    <div
      className="navigationContainer  mx-0 bg-white "
      style={{
        transition: "all 0.3s",
        minWidth: props.showSideBar ? "350px" : "0px",
        width: "0px",
        overflow: props.showSideBar ? "auto" : "hidden",
        maxHeight: "100%",
        // display: props.showSideBar ? "block" : "none",
      }}
    >
      <div
        className="d-flex align-items-center mb-2 justify-content-between p-2"
        style={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <h6>
          <FormattedMessage id="productionItems" defaultMessage="Documents" />
        </h6>
        <div
          className="d-flex px-0 mx-0 align-items-center"
          style={{
            gap: "4px",
          }}
        >
          <button
            className={"btn px-2"}
            style={{
              backgroundColor: "#ff5100",
              color: "#000",
              padding: "7px 0.5rem",
            }}
            title="Add new item"
            onClick={() => props.createFile()}
          >
            <i className="fa fa-plus" />
          </button>
          <button
            className={"btn btn-primary"}
            style={{
              padding: "8px 11px",
            }}
            onClick={props.toggleSideBar}
          >
            <i className="fas fa-angle-left" />
          </button>
        </div>
      </div>
      <div
        className="d-flex flex-column px-0 mx-0"
        style={{
          overflowY: "auto",
          position: "relative",
          zIndex: "1",
          overflowX: "hidden",
          minHeight: "calc(100% - 60px)",
          maxHeight: "calc(100% - 60px)",
        }}
      >
        {productionItems.map((item, index) => {
          const extension =
            item?.Path?.split(".")[item?.Path?.split(".").length - 1];

          let icon =
            item.Path.split(".")[item.Path.split(".").length - 1] === "pdf"
              ? "fa-file-pdf"
              : item.Path.split(".")[item.Path.split(".").length - 1] === "xlsx"
              ? "fa-file-excel"
              : "fa-file-alt";

          let color =
            item.Path.split(".")[item.Path.split(".").length - 1] === "pdf"
              ? "darkred"
              : item.Path.split(".")[item.Path.split(".").length - 1] === "xlsx"
              ? "green"
              : "darkBlue";

          return (
            <div className="w-100 px-0 mx-0 my-1" key={index}>
              <div
                className={
                  "card clickable shadow-sm  mx-1 p-2 " +
                  (props.parentState.UI.Current !== null &&
                  props.parentState.UI.Current.GUID === item.GUID
                    ? "active"
                    : "")
                }
                style={{
                  border: "1px solid #ccc",
                }}
                onClick={() => {
                  let obj = item;
                  props.SelectItem(obj);
                  props.setParentState((prev) => {
                    return {
                      ...prev,
                      UI: {
                        ...prev.UI,
                        fileExtension:
                          obj?.Path?.split(".")[
                            obj?.Path?.split(".").length - 1
                          ],
                      },
                    };
                  });
                }}
              >
                <div className="card-body text-center p-1 px-2 d-flex justify-content-between align-items-sm-center">
                  {state.IsEditing &&
                  props.parentState.UI.Current.GUID === item.GUID ? (
                    <input
                      type="text"
                      className="form-control"
                      value={item.Title}
                      onChange={(e) => {
                        // item.Title.set(e.target.value);
                        handleProductionItemsTitle(index, e.target.value);
                      }}
                      onBlur={(e) => handleSaveName(e, "blur")}
                      onKeyDown={(e) => handleSaveName(e)}
                    ></input>
                  ) : (
                    <>
                      {item.Title ? (
                        <div className="d-flex align-items-center">
                          <i
                            class={` fas fa-solid fa-2x ${icon} mr-2`}
                            style={{
                              color: color,
                              transition: "all 0.3s",
                            }}
                          ></i>
                          <b
                            onDoubleClick={() => {
                              setState((prev) => ({
                                ...prev,
                                IsEditing: !prev.IsEditing,
                              }));
                            }}
                          >
                            {item.Title}
                          </b>
                        </div>
                      ) : (
                        <b
                          onDoubleClick={() => {
                            setState((prev) => ({
                              ...prev,
                              IsEditing: !prev.IsEditing,
                            }));
                          }}
                          className="px-2"
                        >
                          -
                        </b>
                      )}
                      <Popup
                        trigger={
                          <button type="button" className="btn btn-sm">
                            <i className="fas fa-ellipsis-v" />
                          </button>
                        }
                        on="click"
                        position="bottom right"
                        closeOnEscape
                        arrow={false}
                      >
                        {(open) => (
                          <>
                            <div className="text-center d-flex">
                              {GROUPS_COLORS.map((color) => (
                                <button
                                  onClick={() => {
                                    setState((prev) => ({
                                      ...prev,
                                      Color: color,
                                    }));
                                    handleProductionItemsColor(index, color);
                                    props.setParentState((prev) => {
                                      return {
                                        ...prev,
                                        UI: {
                                          ...prev.UI,
                                          Current: {
                                            ...prev.UI.Current,
                                            Color: color,
                                          },
                                        },
                                      };
                                    });
                                    props.saveRemote();
                                    console.log(
                                      "Color selected",
                                      item,
                                      color,
                                      props.parentState.UI.Current
                                    );

                                    open();
                                    //   selectColor(color)
                                  }}
                                  key={color}
                                  style={{
                                    backgroundColor: `${color}`,
                                    border:
                                      item.Color === color
                                        ? "3px solid black"
                                        : "3px solid transparent",
                                  }}
                                  type="button"
                                  className="clean-button btn-reset color-option pink"
                                ></button>
                              ))}
                              <button
                                onClick={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    Color: null,
                                  }));
                                  handleProductionItemsColor(index, null);
                                  props.setParentState((prev) => {
                                    return {
                                      ...prev,
                                      UI: {
                                        ...prev.UI,
                                        Current: {
                                          ...prev.UI.Current,
                                          Color: null,
                                        },
                                      },
                                    };
                                  });
                                  props.saveRemote();
                                  open();
                                }}
                                key="clean"
                                style={{
                                  backgroundColor: `#ccc`,
                                  border:
                                    state.Color === null
                                      ? "3px solid black"
                                      : "3px solid transparent",
                                }}
                                title="Remove color"
                                type="button"
                                className="clean-button btn-reset color-option pink "
                              ></button>
                            </div>

                            <button
                              onClick={() => {
                                props.setParentState((prev) => {
                                  return {
                                    ...prev,
                                    UI: {
                                      ...prev.UI,
                                      ShareModal: !prev.UI.ShareModal,
                                    },
                                  };
                                });
                                open();
                              }}
                              className="btn btn-block flex-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="share"
                                defaultMessage="Share"
                              />
                            </button>
                            <button
                              onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  IsEditing: !prev.IsEditing,
                                }));
                              }}
                              className="btn btn-block flex-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="rename"
                                defaultMessage="Rename"
                              />
                            </button>
                            <button
                              onClick={() => {
                                props.DeleteItem(item);
                                open();
                              }}
                              className="btn btn-block flex-1"
                              type="button"
                            >
                              <FormattedMessage
                                id="delete"
                                defaultMessage="Delete"
                              />
                            </button>
                          </>
                        )}
                      </Popup>
                    </>
                  )}

                  <div
                    className="colorIndicator"
                    style={
                      item.Color !== null
                        ? { backgroundColor: item.Color }
                        : null
                    }
                  ></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MoreDetailsNavigation;
