import React from 'react'
import { ModalComponent } from '../common'
import AddMeetingModal from '../meet/AddMeetingModal'
import CalendarEventModal from './CalendarEventModal'

export const MeetingEvent = (props) => {
  return (
    <div>
        <ModalComponent
          modal={props.state.showDetailsMeetingModal}
          toggle={() => props.toggleMeetingModal()}
          childeren={
            <AddMeetingModal
              onlyToRead
              event={props.event}
              toggle={() => props.toggleMeetingModal()}
            />
          }
        />
        <ModalComponent
          modal={props.state.showEventModal}
          toggle={props.toggleShowEventModal}
          childeren={
            <CalendarEventModal date={props.event.Date} events={props.sameDayEvents} />
          }
        />
        <div
          onClick={() => props.toggleMeetingModal()}
          className="clean-link inner event-container relative"
        >
          <span
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 4,
              height: "100%",
              backgroundColor: props.event.EventGroup && props.event.EventGroup.Color,
            }}
          ></span>
          <span className={`event-status ${props.event.Status}`}></span>
          <div className="event-title text-truncate">{props.event.Name}</div>
          <div className="event-location text-truncate">
            {props.event.VenueGUID && props.event.Venue
              ? `${props.event.Venue.City ? props.event.Venue.City + ", " : ""}${
                props.event.Venue.State ? props.event.Venue.State + ", " : ""
                }${props.event.Venue.Country ? props.event.Venue.Country : ""}`
              : `${props.event.City ? props.event.City + ", " : ""} ${
                props.event.State ? props.event.State + ", " : ""
                } ${props.event.Country ? props.event.Country : ""}`}
          </div>
        </div>
        {props.sameDayEventsCount > 0 && (
          <button
            className="rbc-show-more-modal clean-link btn btn-block"
            onClick={() => props.toggleShowEventModal()}
          >
            + {props.sameDayEventsCount}
          </button>
        )}
      </div>
  )
}
