import React, { useState } from "react";

import UserTabs from "./UserTabs";
import { DropDownComponent, ModalComponent } from "../common";
import GeneralUserItem from "../common/GeneralUserItem";
import AdminAcces from "./AdminAcces";
import { FormattedMessage } from "react-intl";

import { getBasicInfo } from "../../utils";
import UserTag from "./UserTag";

const UserItem = ({
  user,
  toggleStaff,
  deleteStaff,
  currentOrganization,
  toggleShowMove,
  toggleAdminDelete,
  toggleMakeAdmin,
  setDataAllTeam,
  currentTeam,
  ...props
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [adminAccesModal, setadminAccesModal] = useState(false);
  const [showTag, setShowTag] = useState(false);

  const toggleShowTag = (reload) => {
    setShowTag(!showTag);
  };

  const handleMakeAdmin = () => {
    setDataAllTeam &&
      setDataAllTeam((prev) => {
        return prev.map((team, index) => {
          if (index === currentTeam) {
            return {
              ...team,
              Staffs: team.Staffs.map((staff) => {
                if (staff.GUID !== user.GUID) return staff;
                return {
                  ...staff,
                  Permissions: [
                    { ...staff.Permissions[0], CanAccessMacApp: true },
                  ],
                };
              }),
            };
          }
          return team;
        });
      });
    toggleMakeAdmin(user);
  };

  const handleRemoveAdmin = () => {
    setDataAllTeam &&
      setDataAllTeam((prev) => {
        return prev.map((team, index) => {
          if (index === currentTeam) {
            return {
              ...team,
              Staffs: team.Staffs.map((staff) => {
                if (staff.GUID !== user.GUID) return staff;
                return {
                  ...staff,
                  Permissions: [
                    { ...staff.Permissions[0], CanAccessMacApp: false },
                  ],
                };
              }),
            };
          }
          return team;
        });
      });
    toggleAdminDelete(user);
  };

  const Menu = () => {
    let opts = [];
    let currentUser = getBasicInfo();
    //Get current user staff id
    let staff = null;
    currentOrganization.Roles.forEach((role) => {
      role.Staffs.forEach((staffRecord) => {
        if (staffRecord.AccountGUID === currentUser.GUID) {
          staff = staffRecord;
        }
      });
    });

    let CurrentUserIsAdmin =
      currentUser.GUID === currentOrganization.CreatorGUID
        ? true
        : staff !== null
        ? staff.Permissions.length > 0
          ? staff.Permissions[0].CanAccessMacApp
          : false
        : false;
    //Check if the user applies for been disabled
    if (user.Active) {
      opts.push({
        name: <FormattedMessage id="deactivate" defaultMessage="Deactivate" />,
        trigger: () => toggleStaff(user),
        border: true,
      });
    } else {
      opts.push({
        name: <FormattedMessage id="activate" defaultMessage="Activate" />,
        trigger: () => toggleStaff(user),
        border: true,
      });
    }

    //Only the owner can set someone as admin and other admins

    // currentOrganization.CreatorGUID === currentUser.GUID &&
    !user?.Permissions?.[0]?.CanAccessMacApp
      ? opts.push({
          name: (
            <FormattedMessage
              id="grantAdminAccess"
              defaultMessage="Grant Admin Access"
            />
          ),
          trigger: () => handleMakeAdmin(),
          // () =>
          //   window.open("https://billing.plnify.com", "_blank"),
          border: true,
        })
      : opts.push({
          name: (
            <FormattedMessage
              id="removeAdminAccess"
              defaultMessage="Remove Admin Access"
            />
          ),
          trigger: () => handleRemoveAdmin(),
          border: true,
        });
    // opts.push({
    //   name: <FormattedMessage id="adminAccess" defaultMessage="Admin Access" />,
    //   trigger: () => setadminAccesModal(!adminAccesModal),
    //   border: true,
    // });

    opts.push({
      name: (
        <FormattedMessage
          id="moveTeamMember"
          defaultMessage="Move Team Member"
        />
      ),
      trigger: () => toggleShowMove(user),
      border: true,
    });

    if (
      currentUser.GUID == currentOrganization.CreatorGUID ||
      CurrentUserIsAdmin
    ) {
      opts.push({
        name: <FormattedMessage id="delete" defaultMessage="Delete" />,
        trigger: () => deleteStaff(user),
        border: false,
      });
    }

    opts.push({
      name: <FormattedMessage id="tag" defaultMessage="Tag" />,
      trigger: () => toggleShowTag(),
      border: true,
    });
    return opts;
  };

  return (
    <div
      className={"py-2 userTeam border-0 " + (user.Active ? "" : "inactive")}
    >
      <ModalComponent
        modal={showProfile}
        toggle={() => setShowProfile(!showProfile)}
        childeren={
          <UserTabs
            user={user.Account}
            toggle={() => setShowProfile(!showProfile)}
          />
        }
      />
      <ModalComponent
        modal={adminAccesModal}
        toggle={() => setadminAccesModal(!adminAccesModal)}
        childeren={
          <AdminAcces toggle={() => setadminAccesModal(!adminAccesModal)} />
        }
      />

      <ModalComponent
        modal={showTag}
        toggle={toggleShowTag}
        childeren={<UserTag user={user} toggle={toggleShowTag} />}
      />
      <div className="row ">
        <div className="col-auto">
          <GeneralUserItem
            account={user.Account}
            user={user}
            currentOrganization={currentOrganization}
            showDetails
          />

          {/* <div className="col-auto">
          {!user.Accepted ? <span className="badge badge-sm badge-secondary mx-1">Pending</span> : null}
          {!user.Active ? <span className="badge badge-sm badge-secondary mx-1">Inactive</span> : null}
          {user.AccountGUID === currentOrganization.CreatorGUID ? <span className="badge badge-sm badge-primary mx-1">Owner</span> : null}
        </div> */}
        </div>

        <div className="col-auto ml-auto">
          {user.Permissions.length > 0 &&
          user.Permissions[0].CanAccessMacApp === true ? (
            <span className="badge badge-sm badge-warning mx-1">Admin</span>
          ) : null}
        </div>
        {!props.noDropDown && (
          <div className="col-auto ">
            {currentOrganization.CreatorGUID === user.Account.GUID ? null : (
              <button className="teamItem__more btn-reset mx-1">
                <DropDownComponent data={Menu()} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserItem;
