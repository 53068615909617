import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage, useIntl } from "react-intl";
import { sortArray } from "../../common/helpers";
import { IMAGES } from "../../constant";
import { ModalComponent, SimpleLoader } from "../common";
import EmailModal from "./emailModal";
import RoomListItem from "./roomListItem";
import { handleCloseEmail } from "../../utils/closeMailModal";

const RoomListModal = (props) => {
  const [state, setState] = useState({
    currentReservations: [],
    showExport: false,
  });

  let {
    toggle,
    hotelDetails,
    peoples,
    loading,
    updateReservations,
    updateReservation,
  } = props;

  const intl = useIntl();

  const toggleShowExport = () => {
    setState((prev) => ({ ...prev, showExport: !state.showExport }));
  };

  useEffect(() => {
    let rsvps = props?.currentReservations.map((r) => ({
      ...r,
    }));
    props?.addReservations(rsvps);
    return () => props?.cleanReservations();
  }, []);

  return (
    <>
      <ModalComponent
        toggle={() => handleCloseEmail(toggleShowExport)}
        modal={state.showExport}
        childeren={
          <EmailModal
            subject={hotelDetails ? hotelDetails.Name + " - Roomlist" : ""}
            hotel={hotelDetails}
            fromSection="HOTEL_ROOMLIST"
            //teams={props.teams}
            toggle={toggleShowExport}
          />
        }
      />
      <div className="organization-transparent">
        <div className="event-page">
          <div className="event-expennd-section hotel-members">
            <div className="text-head">
              <div className="text-left">
                <h1>
                  <FormattedMessage id="roomList" defaultMessage="Room List" />
                </h1>
                <p>
                  {hotelDetails.Name} |{" "}
                  <FormattedMessage
                    id="checkInDate"
                    defaultMessage="Check In Date"
                  />
                  :{" "}
                  {moment(
                    hotelDetails?.Checkin ? hotelDetails?.Checkin : "-"
                  ).format("MMM, DD YYYY")}{" "}
                  | Check Out Date:{" "}
                  {moment(
                    hotelDetails?.Checkout ? hotelDetails?.Checkout : "-"
                  ).format("MMM, DD YYYY")}
                </p>{" "}
              </div>
              <div className="close-icon d-none" onClick={() => toggle()}>
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div>
            </div>
            <div className="roomList__actions d-flex align-items-center">
              <div className="row justify-content-between">
                <div className="col-md-5 pl-0">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "searchName",
                      })}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="roomList__actions-buttons d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      className="btn w-160 primary text-capitalize"
                      onClick={() => toggleShowExport()}
                    >
                      <FormattedMessage
                        id="exportRoomList"
                        defaultMessage="Export Room List"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Scrollbars style={{ height: "calc(100vh - 170px)" }}>
              <div className="roomList__body">
                {peoples &&
                  sortArray(peoples, "HOTELMEMBERS").map((p) => (
                    <RoomListItem
                      room={p}
                      key={p.GUID}
                      updateReservation={updateReservation}
                    />
                  ))}
              </div>
            </Scrollbars>

            <div className="d-flex align-items-center justify-content-end py-2 px-4">
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <button
                  type="button"
                  onClick={() => updateReservations()}
                  className="btn primary w-160"
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomListModal;
