import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getAttachmentsEvent } from "../../page/event/redux/getAttachmentsEvent";
import RichTextComponent from "../common/RichTextEditorComponent";

export const EditAttachmentModal = ({
  show,
  toggle,
  attachment,
  handleSubmit,
  type,
}) => {
  const dispatch = useDispatch();

  const handleEditFile = (values) => {
    handleSubmit(values);
    toggle();
    const event = localStorage.getItem("CURRENT_EVENT_GUID");
    dispatch(getAttachmentsEvent(event));
  };

  return (
    <Modal show={show} onHide={toggle} backdrop="static">
      <div className="bg-white">
        <div className="d-flex text-head justify-content-between align-items-center text-dark font-weight-bold">
          <span>{`Edit ${
            type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()
          }`}</span>
          <button className="btn " onClick={toggle}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="px-4 border-0">
          <Formik
            enableReinitialize={true}
            initialValues={attachment}
            onSubmit={(values) => handleEditFile(values)}
          >
            {(props) => (
              <Form>
                <div className="attachment__section mt-4">
                  <div className=" attachment__name">
                    <h6>
                      <FormattedMessage
                        id="fileName"
                        defaultMessage="File Name"
                      />
                    </h6>
                  </div>
                  <div className="attachment__input">
                    <Field
                      // onBlur={() => props.handleSubmit()}
                      id="name"
                      className="form-control"
                      type="text"
                      name="Name"
                      // disabled={disabledInputs}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Name"
                    />
                  </div>
                  <div className="attachment__name">
                    <h6>
                      <FormattedMessage id="notes" defaultMessage="Notes" />
                    </h6>
                  </div>
                  <div className="h-100 text-dark">
                    {/* <ReactQuill
                      theme="snow"
                      modules={{
                        toolbar: toolbarOptions,
                      }}
                      value={props.values.Note}
                      onChange={(content) => {
                        console.log({ content });
                        props.setFieldValue("Note", content);
                      }}
                    /> */}

                    <RichTextComponent
                      toolbarSettings={{
                        items: [
                          "Bold",
                          "Italic",
                          "Underline",
                          "StrikeThrough",
                          "|",
                          "Formats",
                          "Alignments",
                          "OrderedList",
                          "UnorderedList",
                          "|",
                          "CreateLink",
                          "Image",
                          "|",
                          "SourceCode",
                          "Undo",
                          "Redo",
                        ],
                      }}
                      value={props.values.Note}
                      handleChange={({ value }) => {
                        props.setFieldValue("Note", value);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-3">
                  <button
                    className="btn btn-primary ml-2 border-0"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-danger ml-2 border-0"
                    onClick={toggle}
                    type="reset"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
