import * as constant from "./constants";
import db from "../../../common/db";

export function setNotes(note) {
  return async (dispatch) => {
    await db.table("activeNotes").put(note);
    dispatch({
      type: constant.SET_NEW_NOTE,
      data: note,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.SET_NEW_NOTE: {
      const notes = [...state.notes, action.data].filter(
        (v, i, a) => a.findIndex((t) => t.GUID === v.GUID) === i
      );
      return {
        ...state,
        notes,
      };
    }
    default:
      return state;
  }
}
