import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import api from "../../common/api";
import { ToastNotificationSuccess } from "../common";
import FormField from "../form/formField";

export default function SocialMedia({ onlyRead, ...props }) {
  const { consultSocialMedia, user, setDataProps } = props;

  const updateSocialMedia = async (values) => {
    const request = await api.post(`/User/Edit`, {
      ...consultSocialMedia,
      ...values,
    });
    setDataProps && setDataProps(request?.data);
    if (request.statusText == "OK") {
      ToastNotificationSuccess(1, "Social Media Succesfully Updated");
    }
  };

  return (
    <div className="card shadow-sm my-2">
      <div className="card-body p-1">
        <Formik
          onSubmit={(values) => updateSocialMedia(values)}
          initialValues={
            consultSocialMedia
              ? {
                  Instagram: consultSocialMedia.Instagram,
                  Facebook: consultSocialMedia.Facebook,
                  Twitter: consultSocialMedia.Twitter,
                  Youtube: consultSocialMedia.Youtube,
                  GUID: consultSocialMedia.GUID,
                }
              : user
              ? {
                  Instagram: user.Instagram,
                  Facebook: user.Facebook,
                  Twitter: user.Twitter,
                  Youtube: user.Youtube,
                  GUID: user.GUID,
                }
              : {
                  Instagram: "",
                  Facebook: "",
                  Twitter: "",
                  Youtube: "",
                  GUID: "",
                }
          }
          enableReinitialize
          // validationSchema={socialMediaSchema}
        >
          {(props) => (
            <Form autoComplete="off" className="pt-1 row">
              <FormField fullWidth={false} label="Facebook">
                <Field
                  className="form-control"
                  type="text"
                  name="Facebook"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Facebook"
                  component="div"
                />
              </FormField>
              <FormField fullWidth={false} label="Twitter">
                <Field
                  className="form-control"
                  type="text"
                  name="Twitter"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Twitter"
                  component="div"
                />
              </FormField>
              <FormField fullWidth={false} label="Instagram">
                <Field
                  className="form-control"
                  type="text"
                  name="Instagram"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Instagram"
                  component="div"
                />
              </FormField>
              <FormField fullWidth={false} label="YouTube">
                <Field
                  className="form-control"
                  type="text"
                  name="Youtube"
                  autoComplete="off"
                  readOnly={onlyRead}
                />
                <ErrorMessage
                  className="error-message"
                  name="Youtube"
                  component="div"
                />
              </FormField>
              {!onlyRead && (
                <div className="col-12 text-right">
                  <button type="submit" className="btn btn-primary">
                    {/* onClick={() => handleSubmit()} */}
                    Save
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
