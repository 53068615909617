import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGES } from "../../constant";
import * as actions from "../../page/event/redux/actions";
import { Scrollbars } from "react-custom-scrollbars";
import { SimpleLoader, ToastNotificationSuccess } from "../common";
import {useIntl, FormattedMessage} from 'react-intl'

import api from "../../common/api";

import GeneralUserItem from "../common/GeneralUserItem";


const onErrorImage = (sender) => {
  sender.target.src = IMAGES.PROFILE_ICON;
};

const getStaff = (staffs, toggleVisible, staffSelected) => {

  if (staffs.length > 0 && staffs.filter((s) => s.Deleted === null).length > 0) {

    let elements = staffs.sort((a, b) => a.Account.Name.localeCompare(b.Account.Name)).filter((s) => s.Deleted === null).map((staff) => {
      return (<div
        key={staff.GUID}
        className="card shadow-sm p-0 my-1 ml-2"
      >
        <div
          className="card-body row p-1 d-flex align-items-center"
          onClick={() => toggleVisible(staff)}>
          <div className="px-2">
            {staffSelected.includes(staff.GUID) ? (
              <i className="fa fa-check-square text-dark"></i>
            ) : (
              <i className="far fa-square text-dark"></i>
            )}
          </div>
          <GeneralUserItem account={staff.Account} />
        </div>
      </div>);

    });
    return elements;
  }

  return null;
};

const getTeams = (teams, toggleVisible, staffSelected, toggleSelectAll) => {
  return (
    teams &&
    teams
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((t) => t.Name !== "Non-App User")
      // .filter((t) => t.Name !== "Invited Users")
      .filter((t) => t.Staffs.length > 0 && t.Staffs.filter(x => x.Deleted === null).length > 0)
      .map((teamData) => {
        return (
          <div key={teamData.GUID} className="admin-container">
            <div className="row px-0 text-dark">
              <div className="col-auto px-2 text-right  bgTitleModal py-1 mt-4">
                <button className="btn btn-sm text-secondary" onClick={() => toggleSelectAll(teamData)} title="Select all">
                    <i className="far fa-square text-dark"></i>
                </button>

              </div>
              <div className="col bgTitleModal py-2 mt-4 px-0 ">
                <h3>{teamData.Name}</h3>
              </div>
            </div>

            {getStaff(teamData.Staffs, toggleVisible, staffSelected)}
          </div>
        );
      })
  );
};

export class EventVisibility extends Component {
  state = {
    staffSelected: [],
    requestData:[],
  };

  componentDidMount() {
    const { visibles } = this.props;
    console.log(this.props)
console.log(this.state)
    const staffVisibles = visibles
      .filter((s) => s.Deleted === null)
      .map((s) => s.StaffGUID);
      if(this.state.requestData !== []){
        this.setState({
          staffSelected: this.state.requestData,
          staffObjects: visibles,
        });
      }else{
         this.setState({
      staffSelected: staffVisibles,
      staffObjects: visibles,
    });
      }
    
  }

  updateLocal =async (obj) => {
    const { event } = this.props;
    let copy = this.state;
    if (obj.Deleted === null) {
      //It supposed to appear
      if (!copy.staffSelected.includes(obj.StaffGUID)) {
        copy.staffSelected.push(obj.StaffGUID);
      }
    } else {
      //Means we have to remove it
      copy.staffSelected = copy.staffSelected.filter((value, index, arr) => {
        return value !== obj.StaffGUID
      });
    }
    this.setState(copy);
        const request = await api.get("/Events/VisibleBy", {
      params: {
        EventGUID: event.GUID,
      },
    })
    console.log("request",request)
    this.setState({
      requestData:request.data.responde
    });
    
  }

  toggleVisible = async (obj) => {

    //UPDATE LOCALLY
    this.updateLocal(obj);
    //UPDATE REMOTELY
    const request = await api.get(
      `/EventVisibleBy/AddEdit?StaffGUID=${obj.GUID}&EventGUID=${this.props.event.GUID}`
    );
    if (request.data.status === "ok") {
      obj = request.data.response;
    }

    this.updateLocal(obj);
  };


  SelectAllInTeamLocally = (team) => {
    let copy = this.state;
    let staffInGroup = team.Staffs;
    staffInGroup.forEach(item => {
      if (!copy.staffSelected.includes(item.GUID)) {
        copy.staffSelected.push(item.GUID);
      }
      else{
        copy.staffSelected.push(item.delete());
        // this.setState(copy.staffSelected)
      }
    });
    this.setState(copy);
  }
  toggleSelectAll = async (team) => {
    // debugger;
    //UPDATE LOCALLY
    this.SelectAllInTeamLocally(team);
    //UPDATE REMOTELY
    const request = await api.get(
      `/EventVisibleBy/SelectAllTeam?TeamGUID=${team.GUID}&EventGUID=${this.props.event.GUID}`
    );
    
    if (request.data.status === "ok") {
      let objs = request.data.response;
      objs.forEach(item => {

      });
    }

    // this.updateLocal(obj);
    //console.log("Staff Objects", this.state.staffObjects, obj);


  };

  render() {
    const { teams, loading } = this.props;
    const { staffSelected } = this.state;
    return (
      <div className="hotel-transparent">
        <div className="event-page ">
          <div className="event-expennd-section event-sidebar">
            <div className="text-head">
              <div className="text-left">
                <h4 className="title"><FormattedMessage
                    id="visibleBy"
                    defaultMessage="Visible by"/></h4>
                <small className="text-muted"><FormattedMessage
                    id="peopleViewThisEvent"
                    defaultMessage="People who can view this event"/></small>
              </div>
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : null}
            </div>
            <Scrollbars style={{ height: "calc(100vh - 105px)" }}>
              {getTeams(
                teams,
                this.toggleVisible,
                staffSelected,
                this.toggleSelectAll
              )}
            </Scrollbars>
            <button
                                            type="button"
                                            // onClick={() => handleSubmit()}
                                            className="btn primary"
                                        >
                              <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                                        </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  event: state.event.event,
  teams: state.organizations.teams,
  common: state.common,
  visibles: state.event.visibles,
  loading: state.event.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventVisibility);
