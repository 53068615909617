import React, { useEffect, useState } from "react";
import api from "../../common/api";
import { IMAGES } from "../../constant";
import Accordion from "react-bootstrap/Accordion";
import { AccordionComponent } from "../../components/common/AccordionComponent";

const ErrorPageComponent = ({ error, resetErrorBoundary }) => {
  const sendErrorToApi = async (error) => {
    debugger;
    const request = await api.post(
      `/General/SendEmail/?EmailAddress=support@plnify.com&title=Error customerbackend`,
      error.toString()
    );
    console.log("sent error message", request.data);

    window.location.reload();
  };
  useEffect(() => {
    console.log("THE ERROR ====", error);
  }, []);

  const [showError, setShowError] = useState(false);
  const handleShowError = () => {
    setShowError((prev) => !prev);
  };

  return (
    <div
      role="alert"
      className="vh-100 d-flex justify-content-center align-items-center p-2"
    >
      <div className="card shadow-sm py-5 col-12" style={{ maxWidth: "600px" }}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={IMAGES.LOGO_GRADIENT} />
          <h1 className="font-weight-bold m-2">Something went wrong</h1>

          <div className="row">
            <div className="col-12 text-center mb-4">
              <span className="">
                Please either refresh the page or return to home screen.
              </span>
              <div className="d-flex justify-content-center item mt-2">
                <button
                  onClick={() => {
                    window.location.assign("/");
                  }}
                  className="btn btn-primary mx-1"
                >
                  Home
                </button>
                <button
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="btn btn-secondary mx-1"
                >
                  Reload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <AccordionComponent
            data={[
              {
                title: () => (
                  <p className="font-weight-bold">
                    {showError ? "Hide details" : "See details"}
                  </p>
                ),
                body: error.message,
                action: handleShowError,
              },
            ]}
          />
        </div>
        <div className="col-12 text-center d-flex flex-column align-items-center">
          <span>If the problem persist, try to</span>
          <button
            onClick={() => {
              window.location = "/cleanup";
            }}
            className="btn btn-danger btn-sm"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPageComponent;
