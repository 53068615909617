import React, { Component } from "react";
import PrivacyPolicy from "./privacyPolicy";
import { connect } from "react-redux";
import history from "../../../components/common/history";

class PricacyPolicyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 2,
      isElectron: false
    };
  }

  componentDidMount() {
    this.props.getAuthTerms(2);
    this.props.match.params.id &&
      this.setState({
        id: this.props.match.params.id
      });
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      this.setState({ isElectron: true });
    } else {
      this.setState({ isElectron: false });
    }
  }

  render() {
    let { isElectron } = this.state;
    let { termsAndCondition } = this.props;
    return (
      <PrivacyPolicy
        data={termsAndCondition}
        id={this.state.id}
        back={() => history.goBack()}
        isElectron={isElectron}
      />
    );
  }
}

const mapStateToProps = state => ({
  termsAndCondition: "state.authTermsReducer.authTerms.authTerms"
});

const mapDispatchToProps = {
  getAuthTerms: () => console.log("authTermsAction")
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricacyPolicyContainer);
