import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import ChatSidebarItem from "./ChatSidebarItem";
import { Scrollbars } from "react-custom-scrollbars";
import { isEqual, getBasicInfo } from "../../utils";
import * as actions from "../../page/meet/redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ModalComponent } from "../common";
import CreateNewChat from "./modal/CreateNewChat";
import Skeleton from "react-loading-skeleton";
import uuid from "uuid";
import { IMAGES } from "../../constant";
import { useIntl, FormattedMessage } from "react-intl";

const ChatSidebar = (props) => {
  const intl = useIntl();
  const isFirstRender = useRef(true);
  const [state, setState] = useState({
    chats: [],
    conversations: [],
    createChatModal: false,
    options: [],
    emails: [],
    accounts: [],
  });

  const globalChats = useSelector((state) => state.meet.chats);
  const globalConnection = useSelector((state) => state.meet.connection);
  const globalEnableChat = useSelector((state) => state.meet.enableChat);
  const globalTeams = useSelector((state) => state.organizations.teams);
  const globalLoading = useSelector((state) => state.meet.loading);

  useEffect(() => {
    fetchConversations(globalConnection);
    setOptionsEmails();
  }, []);
  // Hacer el llamado de Conversations.

  useEffect(() => {
    if (globalConnection.connectionStarted) {
      if (!isEqual(isFirstRender.current, globalConnection)) {
        fetchConversations(globalConnection);
      }
    }
  }, []);

  const fetchConversations = async (connection) => {
    const user = getBasicInfo();
    await connection.invoke("Conversations", user.GUID, 0, 20);
  };

  const toggleCreateChatModal = () => {
    setState((prev) => ({ ...prev, createChatModal: !state.createChatModal }));
  };

  const setOptionsEmails = () => {
    const emails = globalTeams
      .map((item) =>
        item?.Staffs?.filter((item) => item.Account.Email !== null)
          .filter((item) => item.Account.Email.length > 2)
          .map((staff) => staff.Account.Email)
      )
      .flat();
    const accounts = globalTeams
      .map((item) =>
        item?.Staffs?.filter((item) => item.Account.Email !== null)
          .filter((item) => item.Account.Email.length > 2)
          .map((staff) => staff.Account)
      )
      .flat();

    const uniqueEmails = emails?.filter((c, i) => emails.indexOf(c) === i);

    setState((prev) => ({
      ...prev,
      options: uniqueEmails,
      accounts,
    }));
  };

  const selectEmail = (selected) => {
    setState((prev) => ({
      ...prev,
      emails: selected,
    }));
  };

  const createNewChat = async () => {
    const { emails } = state;

    const conversationGUID = uuid.v4();
    const members = emails.map((e) => {
      const account = state.accounts.find((acc) => acc.Email === e);
      return {
        GUID: uuid.v4(),
        conversationGUID,
        AccountGuid: account.GUID,
        isAdmin: false,
      };
    });

    const chattingWith = members.map((member) => {
      const user = state.accounts.find(
        (account) => account.GUID === member.AccountGuid
      );
      return { name: `${user.Name} ${user.LastName}` };
    });

    const conversation = {
      GUID: conversationGUID,
      Name:
        chattingWith.length > 1
          ? chattingWith.reduce((prev, current) => prev + ` | ${current.name}`)
          : chattingWith[0].name,
      CreationDate: new Date(),
      UnreadMessages: 0,
      EventGUID: null,
      ConversationMember: [
        {
          GUID: uuid.v4(),
          conversationGUID,
          AccountGuid: getBasicInfo().GUID,
          isAdmin: true,
        },
        ...members,
      ],
    };
    await globalConnection.invoke(
      "StartConversation",
      conversation,
      getBasicInfo().GUID
    );
    props.toggleMessages();
  };

  const { toggleMessages, showMessages, chatList } = props;

  return (
    <div
      className={`chat__sidebar shadow-lg ${showMessages ? "show" : "hide"}`}
    >
      <ModalComponent
        modal={state.createChatModal}
        toggle={toggleCreateChatModal}
        childeren={
          <CreateNewChat
            toggle={toggleCreateChatModal}
            options={state.options}
            emails={state.emails}
            createNewChat={createNewChat}
            selectEmail={selectEmail}
          />
        }
      />
      <div className="chat__sidebar-header d-flex align-items-center justify-content-between">
        <div>
          <h6>
            <FormattedMessage id="messages" defaultMessage="Messages" />
          </h6>
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => toggleCreateChatModal()}
          >
            <FontAwesomeIcon icon={faPlus} color="white" />
          </button>
          <button
            type="button"
            className="btn btn-sm btn-link ml-2"
            onClick={() => toggleMessages()}
          >
            <FontAwesomeIcon icon={faTimes} color="white" />
          </button>
        </div>
      </div>
      <div>
        {globalChats?.length === 0 ? (
          <Scrollbars style={{ width: "100%", height: "calc(100vh - 65px)" }}>
            <div className="mx-auto text-center h-100 d-flex align-items-center justify-content-center flex-column">
              <div className="px-4 my-3 w-100">
                <img
                  src={IMAGES.RECENT_MESSAGES_PLACEHOLDER}
                  className="img-fluid mx-auto"
                  alt={intl.formatMessage({ id: "noMessagesYet" })}
                />
              </div>
              <div className="text-center">
                <h4>
                  <FormattedMessage
                    id="noMessagesYet"
                    defaultMessage="No messages yet"
                  />
                </h4>
              </div>
            </div>
          </Scrollbars>
        ) : (
          <Scrollbars style={{ width: "100%", height: "calc(100vh - 65px)" }}>
            {globalChats &&
              globalChats.map((chat) => (
                <ChatSidebarItem
                  key={chat.conversationGUID}
                  chat={chat}
                  name={chat.name}
                  unreadMessages={chat.unreadMessages}
                  lastMessage={chat.lastMessage.message}
                  lastMessageDate={chat.lastMessage.date}
                  enableChatWindow={actions.enableChatWindow}
                  enableChat={globalEnableChat}
                  toggleMessages={toggleMessages}
                />
              ))}
          </Scrollbars>
        )}
      </div>
    </div>
  );
};

export default ChatSidebar;
