import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";

import { IMAGES } from "../../constant";

const GuestListEvents = ({ toggle, events, selectEvent }) => {
  return (
    <div className="guestList__events">
      <div className="text-head">
        <div className="text-left">
          <h1>Select an Event</h1>
          <p>Select an event to retrieve your tickets and passes requests.</p>
        </div>
        <div className="close-icon d-none" onClick={() => toggle()}>
          <span className="close-round">
            <img src={IMAGES.CROSS_ICON} alt="close" width="16" height="16" />
          </span>
        </div>
      </div>
      <div className="guestList__events-list">
        <Scrollbars style={{ width: "100%", height: 600 }}>
          {events &&
            events.map((event) => (
              <div
                key={event.GUID}
                onClick={(e) => {
                  selectEvent(e, event);
                  toggle();
                }}
                className="d-flex align-items-center guestList__events-item"
              >
                <div className="icon">
                  <img
                    src={IMAGES.CALENDER}
                    alt="calendar"
                    width="45"
                    height="49"
                  />
                  <div className="calendar-inside">
                    <h3>{moment(event.Date).format("MMM")}</h3>
                    <p>{moment(event.Date).format("DD")}</p>
                  </div>
                </div>
                <div className="ml-2">{event.Name}</div>
              </div>
            ))}
        </Scrollbars>
      </div>
    </div>
  );
};

export default GuestListEvents;
