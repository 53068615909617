import api from "../../../common/api";
import uuid from "uuid";

import * as constant from "./constants";
const subUrl = "/Roles/AddEdit";

export function deleteTeam(team, callBack) {
  return async (dispatch) => {
    try {
      dispatch({ type: constant.DELETING_TEAM });
      const request = await deletingTeamRequest(subUrl, team);
      callBack();
      dispatch({
        type: constant.DELETED_TEAM,
        data: request.data.response,
      });
    } catch (error) {
      dispatch({
        type: constant.DELETING_TEAM_ERROR,
      });
      // errorCallback();
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_TEAM:
      return {
        ...state,
        loading: true,
      };
    case constant.DELETED_TEAM:
      const deletedTeam = action.data;
      return {
        ...state,
        loading: false,
        teams: state.teams.filter((team) => team.GUID !== deletedTeam.GUID),
      };
    case constant.DELETING_TEAM_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function deletingTeamRequest(url, team) {
  return api.post(url, {
    guid: team.GUID,
    name: team.Name,
    deleted: new Date(),
  });
}
