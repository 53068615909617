import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteActivity(activity, success) {
  const url = "/Schedule/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_ACTIVITY,
    });
    try {
      const baseSchedule = await db
        .table("schedules")
        .where({ GUID: activity.ScheduleMasterGUID })
        .first();
      const deletedActivity = { ...activity, Deleted: new Date() };
      const localBase = {
        ...baseSchedule,
        Schedules: baseSchedule.Schedules.map((s) => {
          if (s.GUID === activity.GUID) {
            s = deletedActivity;
          }
          return s;
        }),
      };
      await db.table("schedules").put(localBase);
      dispatch({
        type: constant.DELETED_ACTIVITY_SUCCESS,
        data: deletedActivity,
      });
      success();
      await api.post(url, {
        ...activity,
        Deleted: new Date(),
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.DELETING_ACTIVITY_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETED_ACTIVITY_SUCCESS: {
      return {
        ...state,
        schedulesMasters: state.schedulesMasters.map((schedule) => {
          if (schedule.GUID === action.data.ScheduleMasterGUID) {
            const cleanedSchedules = schedule.Schedules.filter(
              (a) => a.GUID !== action.data.GUID
            );
            schedule.Schedules = cleanedSchedules;
          }

          return schedule;
        }),
      };
    }
    default:
      return state;
  }
}
