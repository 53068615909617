import { ErrorMessage, Field, Form } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormField from "../../form/formField";

const Preferences = (props) => {
  const intl = useIntl();
  return (
    <div className="flex-1">
      <Form className="row">
        <div className="col-6">
          <p className="mb-2">
            <strong>
              <FormattedMessage id="Airlines" defaultMessage="Airlines" />
            </strong>
          </p>
          <div>
            <label className="mb-3">
              <FormattedMessage
                id="Preferred Airline"
                defaultMessage="Preferred Airline"
              />
            </label>
            <Field
              className="form-control"
              type="text"
              name="PreferredAirline"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="PreferredAirline"
              component="div"
            />
          </div>
          <div>
            <label className="mb-3">
              <FormattedMessage
                id="Hometown Airport"
                defaultMessage="Hometown Airport"
              />
            </label>
            <Field
              className="form-control"
              type="text"
              name="HomeTownAirport"
              autoComplete="off"
              readOnly={props.readOnly}
            />
            <ErrorMessage
              className="error-message"
              name="HomeTownAirport"
              component="div"
            />
          </div>
          <div>
            <label className="mb-3">
              <FormattedMessage id="Seat" defaultMessage="Seat" />
            </label>
            <div>
              <Field
                as="select"
                name="Seat"
                className="form-control"
                disabled={props.readOnly}
              >
                <option value="0">{intl.formatMessage({ id: "Aisle" })}</option>
                <option value="1">
                  {intl.formatMessage({ id: "Middle" })}
                </option>
                <option value="2">
                  {intl.formatMessage({ id: "Window" })}
                </option>
              </Field>
            </div>
          </div>
          <div>
            <label className="mb-3">
              <FormattedMessage
                id="Ticket Class"
                defaultMessage="Ticket Class"
              />
            </label>
            <div>
              <Field
                as="select"
                name="TicketClass"
                className="form-control"
                disabled={props.readOnly}
              >
                <option value="0">
                  {intl.formatMessage({ id: "Basic Economy" })}
                </option>
                <option value="1">
                  {intl.formatMessage({ id: "Economy" })}
                </option>
                <option value="2">
                  {intl.formatMessage({ id: "Premium Economy" })}
                </option>
                <option value="3">
                  {intl.formatMessage({ id: "Business" })}
                </option>
                <option value="4">{intl.formatMessage({ id: "First" })}</option>
              </Field>
            </div>
          </div>
        </div>
        <div className="col-6">
          <p className="mb-2">
            <strong>
              <FormattedMessage id="Hospitality" defaultMessage="Hospitality" />
            </strong>
          </p>
          <div>
            <label className="mb-3">
              <FormattedMessage id="Room Type" defaultMessage="Room Type" />
            </label>
            <div>
              <Field
                as="select"
                name="HotelRoomType"
                className="form-control"
                disabled={props.readOnly}
              >
                <option value="0">
                  {intl.formatMessage({ id: "Single" })}
                </option>
                <option value="1">
                  {intl.formatMessage({ id: "Double" })}
                </option>
                <option value="2">{intl.formatMessage({ id: "Queen" })}</option>
                <option value="3">{intl.formatMessage({ id: "King" })}x</option>
                <option value="4">
                  {intl.formatMessage({ id: "Master Suite" })}
                </option>
                <option value="5">
                  {intl.formatMessage({ id: "Junior Suite" })}
                </option>
                <option value="6">
                  {intl.formatMessage({ id: "Standard Suite" })}
                </option>
                <option value="7">
                  {intl.formatMessage({ id: "Executive Suite" })}
                </option>
                <option value="8">
                  {intl.formatMessage({ id: "Presidential Suite" })}
                </option>
              </Field>
            </div>
          </div>
          <div>
            <label className="mb-3">
              <FormattedMessage
                id="Smoking/Non Smoking"
                defaultMessage="Smoking/Non Smoking"
              />
            </label>
            <div>
              <Field
                as="select"
                name="Smoking"
                className="form-control"
                disabled={props.readOnly}
              >
                <option value="0">
                  {intl.formatMessage({ id: "Smoking" })}
                </option>
                <option value="1">
                  {intl.formatMessage({ id: "Non Smoking" })}
                </option>
              </Field>
            </div>
          </div>
          <div>
            <label className="mb-3">
              <FormattedMessage id="Dietary" defaultMessage="Dietary" />
            </label>
            <div>
              <Field
                className="form-control"
                placeholder="Optional"
                type="text"
                name="DietaryOption"
                autoComplete="off"
                readOnly={props.readOnly}
              />
              <ErrorMessage
                className="error-message"
                name="DietaryOption"
                component="div"
              />
            </div>
          </div>
          <div className="addTask__editor">
            <label>
              <FormattedMessage id="Allergies" defaultMessage="Allergies" />
            </label>
            <FormField fullWidth={true} additionalClasses="px-0 pr-2">
              <Field
                className="form-control"
                rows={1}
                as="textarea"
                name="Allergies"
                disabled={props.readOnly}
              ></Field>
            </FormField>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Preferences;
