import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import { Field, Formik, Form } from "formik";
import { PencilOutline, Check } from "mdi-material-ui";
import DatePicker from "react-datepicker";
import { ChevronDown } from "mdi-material-ui";
import uuid from "uuid";
import { useIntl, FormattedMessage } from "react-intl";
import AddItinerary from "./addItinerary";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getScheduleMasters } from "../../page/event/redux/getScheduleMasters";
import LabelForm from "../form/LabelForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { apiService } from "../../common/api";
import { Events } from "react-scroll";

const id = uuid.v4();

const ItinerariesItem = (props) => {
  let {
    itinerary,
    handleSidebarItem,
    toggleExportModal,
    toggleMailingList,
    toggleShare,
    deleteItinerary,
    sideBarItemData,
    toggleActivityForm,
    editItinerary,
    toggleVisibleBy,
    createTemplate,
    toggleLabelForm,
    actionResource,
    event,
  } = props;

  const [canEdit, setCanEdit] = useState(false);
  const [editInitialValue, setEditInitialValues] = useState("");
  const dispatch = useDispatch();

  const [addItineraryModal, setAddItineraryModal] = useState(false);
  const [showLabelForm, setShowLabelForm] = useState(false);

  const handleEditSubmit = (values, toggle) => {
    editItinerary(values);
    toggle();
  };

  const handleDuplicate = async () => {
    let query = {
      Events: event.GUID,
      Items: sideBarItemData.GUID,
    };

    try {
      await apiService.post("/ScheduleMaster/copyToAnotherEvent", query);
      dispatch(getScheduleMasters(event.GUID));
      ToastNotificationSuccess(
        Math.random(),
        "Itinerary duplicated successfuly."
      );
    } catch (err) {
      console.error(err);
      ToastNotificationError(Math.random(), "Itinerary duplicate failed.");
    }
  };

  const toggle = () => {
    setAddItineraryModal(!addItineraryModal);
  };

  const handleEditModal = (itinerary) => {
    setAddItineraryModal(!addItineraryModal);
    setEditInitialValues({ ...itinerary });
  };

  const handleAddEditLabel = () => {
    setShowLabelForm((prev) => !prev);
  };

  const [showTemplateLabel, setShowTemplateLabel] = useState(false);

  const handleAddEditTemplateLabel = () => {
    setShowTemplateLabel((prev) => !prev);
  };

  const handleCreateTemplate = (itinerary, label) => {
    createTemplate(itinerary, label, handleAddEditTemplateLabel);
  };

  return (
    <>
      <ModalComponent
        modal={addItineraryModal}
        toggle={() => setAddItineraryModal(!addItineraryModal)}
        childeren={
          <AddItinerary
            header={"Edit Itinerary"}
            initialValue={editInitialValue}
            loading={false}
            addItinerary={handleEditSubmit}
            toggle={toggle}
          />
        }
      />

      <ModalComponent
        modal={showLabelForm}
        toggle={handleAddEditLabel}
        childeren={
          <LabelForm
            submitFunction={handleEditSubmit}
            resource={sideBarItemData}
            toggle={handleAddEditLabel}
          />
        }
      />

      <Modal
        show={showTemplateLabel}
        onHide={handleAddEditTemplateLabel}
        backdrop="static"
      >
        <div className="w-100 h-100">
          <div className="event-page" style={{ overflow: "visible" }}>
            <div className="event-expennd-section">
              <div className="text-head">
                <div className="text-left d-flex w-100 justify-content-between">
                  <h1>
                    <FormattedMessage
                      id="addLabel"
                      defaultMessage={"Add Template Label"}
                    />
                  </h1>
                  <button
                    type="button"
                    className="btn btn-sm btn-link ml-2"
                    onClick={() => handleAddEditTemplateLabel()}
                  >
                    <FontAwesomeIcon icon={faTimes} color="white" />
                  </button>
                </div>
              </div>
              <div className="event-inside pl-0">
                <div className="event-content " style={{ paddingBottom: 24 }}>
                  <Formik
                    initialValues={{ Label: "" }}
                    onSubmit={(values) =>
                      handleCreateTemplate(sideBarItemData, values.Label)
                    }
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {(props) => (
                      <Form>
                        <div className="row">
                          <div className="col-12 my-3">
                            <div className="d-flex col-12 justify-content-between align-items-center">
                              <label>
                                <FormattedMessage
                                  id="tags"
                                  defaultMessage="Label"
                                />
                              </label>
                              <span
                                className="text-black-50"
                                style={{ fontSize: "10px" }}
                              >
                                *Optional
                              </span>
                            </div>
                            <Field name="Label" className="form-control" />
                          </div>

                          <div className="col-12">
                            <button className="btn btn-primary" type="submit">
                              <FormattedMessage
                                id="done"
                                defaultMessage="Done"
                              />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div
        onClick={() => handleSidebarItem("ACTIVITIES", itinerary)}
        onDoubleClick={() => handleEditModal(itinerary)}
        className={`itinerariesItem__root clickableItem ${
          sideBarItemData && sideBarItemData.GUID === itinerary.GUID
            ? "active"
            : ""
        }`}
      >
        <div className="col-auto px-0">
          <button
            className="btn btn-sm text-secondary"
            onClick={() => props.toggleSelect(itinerary)}
          >
            {props.checkSelectionStatus(
              props.schedulesMasters,
              itinerary,
              props.selectedItems
            ) === 0 ? (
              <i className="far fa-square uncheck"></i>
            ) : props.checkSelectionStatus(
                props.schedulesMasters,
                itinerary,
                props.selectedItems
              ) === 1 ? (
              <i className="fas fa-check-square"></i>
            ) : (
              <i className="fas fa-minus-square"></i>
            )}
          </button>
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={(values) => editItinerary(values)}
          initialValues={{
            ...itinerary,
            Date: moment(itinerary.Date).format("YYYY-MM-DD"),
          }}
        >
          {(props) => (
            <Form
              autoComplete="off"
              className="d-flex flex-1 align-items-center justify-content-between"
            >
              <div className={`itinerariesItem__details truncate-text`}>
                <div className="d-flex flex-column">
                  {/* {canEdit ? (
                  <Field
                    type="text"
                    name="Name"
                    className="form-control text-truncate"
                    onKeyDown={handleEditSubmit}
                  />
                ) : (
                  <h6 className="name">{itinerary.Name}</h6>
                )} */}
                  {itinerary.Label && (
                    <div>
                      <span
                        className="c-pointer labelContainer my-1"
                        onClick={handleAddEditLabel}
                      >
                        {itinerary.Label}
                      </span>
                    </div>
                  )}
                  <h6 className="name">{itinerary.Name}</h6>
                  {/* {canEdit ? (
                  <Check
                    className="check"
                    onClick={() => {
                      setCanEdit((canEdit) => !canEdit);
                      props.handleSubmit();
                    }}
                  />
                ) : (
                  <PencilOutline
                    onClick={() => setCanEdit((canEdit) => !canEdit)}
                  />
                )} */}
                </div>

                {/* {canEdit ? (
                <div className="">
                  <div className="d-flex align-items-center"> */}
                {/* <input
                      type="date"
                      className="form-control underline"
                      value={moment(props.values.Date).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }
                          let finalValue = e.target.value;
                          props.setFieldValue(
                            "Date",
                            moment(finalValue).format("YYYY-MM-DD")
                          );

                          props.handleSubmit();
                        } catch (ex) {
                          console.error(ex);
                          debugger;
                        }
                      }}
                    /> */}
                {/* <DatePicker
                      id={id}
                      withPortal
                      dateFormat="MMMM dd, yyyy"
                      showPopperArrow={false}
                      selected={moment(props.values.Date).toDate()}
                      onChange={(e) => {
                        props.setFieldValue(
                          "Date",
                          moment(e).format("YYYY-MM-DD")
                        );
                        props.handleSubmit();
                      }}
                    />
                    <div
                      className="drop-icon"
                      onClick={() => document.getElementById(id).focus()}
                    >
                      <ChevronDown />
                    </div> */}
                {/* </div>
                </div> */}
                {/* ) : (
                <p className="">{moment(itinerary.Date).format("ll")}</p>
                )} */}
                <p className="">{moment(itinerary.Date).format("ll")}</p>
              </div>
              <div className="itinerariesItem__actions">
                <div className="itinerariesItem__menu">
                  <DropDownComponent
                    data={[
                      {
                        name: (
                          <FormattedMessage
                            id="addActivity"
                            defaultMessage="Add Activity"
                          />
                        ),
                        border: true,
                        trigger: () => toggleActivityForm(),
                      },
                      {
                        name: (
                          <FormattedMessage
                            id="editLabel"
                            defaultMessage={
                              itinerary.Label ? "Edit Label" : "Add Label"
                            }
                          />
                        ),
                        border: true,
                        trigger: () => handleAddEditLabel(),
                      },
                      // {
                      //   name: "Edit Itinerary",
                      //   border: true,
                      //   trigger: () => setCanEdit((canEdit) => !canEdit),
                      // },
                      {
                        name: (
                          <FormattedMessage
                            id="saveAsTemplate"
                            defaultMessage="Save as template"
                          />
                        ),
                        trigger: () => handleAddEditTemplateLabel(),
                      },
                      // {
                      //   name: (
                      //     <FormattedMessage id="share" defaultMessage="Share" />
                      //   ),
                      //   trigger: () => toggleShare(itinerary),
                      // },
                      {
                        name: (
                          <FormattedMessage
                            id="generateDailySheet"
                            defaultMessage="Export daysheet"
                          />
                        ),
                        trigger: () => toggleMailingList(),
                      },
                      {
                        name: (
                          <FormattedMessage
                            id="duplicate"
                            defaultMessage="Duplicate"
                          />
                        ),
                        trigger: () => handleDuplicate(),
                        border: true,
                      },
                      // {
                      //   name: (
                      //     <FormattedMessage
                      //       id="copyToAnotherEvent"
                      //       defaultMessage="Copy to another event"
                      //     />
                      //   ),
                      //   trigger: () => toggleExportModal(),
                      //   border: true,
                      // },
                      {
                        name: (
                          <FormattedMessage
                            id="visibleBy"
                            defaultMessage="Visible by"
                          />
                        ),
                        trigger: () => toggleVisibleBy(),
                        border: true,
                      },
                      {
                        name: (
                          <FormattedMessage
                            id="delete"
                            defaultMessage="Delete"
                          />
                        ),
                        border: true,
                        delete: true,
                        trigger: () => deleteItinerary(itinerary),
                      },
                    ]}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ItinerariesItem;
