import React, { useState } from "react";
import AvatarsRow from "../common/avatarsRow";
import { IMAGES } from "../../constant";
import moment from "moment";
import { DropDownComponent } from "../common";
import { useIntl, FormattedMessage } from "react-intl";
import { deleteGround } from "../../page/event/redux/deleteGround";
import { useDispatch } from "react-redux";

const GroundItem = (props) => {
  const {
    ground,
    handleSidebarItem,
    togglePassengers,
    sideBarItemData,
    toggleLabelForm,
    //  deleteGround = { deleteGround },
  } = props;
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();

  console.log({ props });

  return (
    <div
      onClick={() => {
        handleSidebarItem("GROUND", ground);
      }}
      className={`hotelItem__root clickableItem ground px-0 row ${
        sideBarItemData && sideBarItemData.GUID === ground.GUID ? "active" : ""
      }`}
    >
      <div className="col-auto px-0">
        <button
          className="btn btn-sm text-secondary"
          onClick={() => props.toggleSelect(ground)}
        >
          {props.checkSelectionStatus(
            props.grounds,
            ground,
            props.selectedItems
          ) === 0 ? (
            <i className="far fa-square uncheck"></i>
          ) : props.checkSelectionStatus(
              props.grounds,
              ground,
              props.selectedItems
            ) === 1 ? (
            <i className="fas fa-check-square"></i>
          ) : (
            <i className="fas fa-minus-square"></i>
          )}
        </button>
      </div>
      <div className="hotelItem__details ">
        <div className="flex-1 col text-wrap">
          {ground.Label && (
            <div
              onClick={() => toggleLabelForm()}
              className="c-pointer labelContainer my-1"
            >
              {ground.Label}
            </div>
          )}

          <h6 className="title my-0">
            {ground.Pickup}{" "}
            <img className="mx-2" height="22" src={IMAGES.UNION} alt="" />{" "}
            {ground.Dropoff}
          </h6>
          <p className="subTitle">
            {ground.Company} {ground.CompanyContact} | Pickup Date:{" "}
            {moment(ground.PickupDate).format("ll")}
          </p>
        </div>
      </div>
      <div className="hotelItem__right mr-3">
        <div className="hotelItem__staffs">
          {ground.GroundStaff &&
            ground.GroundStaff.length > 0 &&
            ground.GroundStaff.filter((g) => g.Deleted === null).length > 0 && (
              <AvatarsRow
                key={ground.GUID}
                grounds={true}
                staffs={ground.GroundStaff.filter((g) => g.Deleted === null)}
              />
            )}
        </div>
        <div className="hotelItem__actions">
          <div className={`hotelItem__menu ${dropdown ? "active" : ""}`}>
            <DropDownComponent
              data={[
                {
                  name: (
                    <FormattedMessage
                      id="addLabel"
                      defaultMessage={
                        sideBarItemData.Label ? "Edit Label" : "Add Label"
                      }
                    />
                  ),
                  border: true,
                  trigger: () => toggleLabelForm(),
                },
                {
                  name: (
                    <FormattedMessage
                      id="addTeamMembers"
                      defaultMessage="Add team members"
                    />
                  ),
                  trigger: () => togglePassengers(),
                  border: true,
                },

                {
                  name: (
                    <FormattedMessage id="delete" defaultMessage="Delete" />
                  ),
                  delete: true,
                  trigger: () => {
                    props.toggleSelect(ground);
                    dispatch(deleteGround(ground, () => {}));
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroundItem;
