import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { EVENT_TYPES } from "../../constant";
import { SimpleLoader, DropDownVenue } from "../common";
import { CheckBox } from "..";
import DatePicker from "react-datepicker";
import moment from "moment";
import uuid from "uuid";
import { getOrganization } from "../../utils";
import { CheckCircle } from "mdi-material-ui";
import { useDebouncedCallback } from "use-debounce";
import api from "../../common/api";
import { useIntl, FormattedMessage } from "react-intl";

const BatchEventsModal = (props) => {
  const intl = useIntl();
  let { loading, clearAll, toggle, addBunchEvents, multipleEvents } = props;
  const [until, setUntil] = useState(false);
  const [searchingVenues, setSearchingVenues] = useState(false);
  const [venues, setVenues] = useState([]);

  const searchVenues = useDebouncedCallback(async (query) => {
    setSearchingVenues(true);
    const request = await api.get(`/Venues/Venues?Search=${query}`);
    setVenues(request.data.Response);
    setSearchingVenues(false);
  }, 300);

  function setVenue(place, props) {
    props.setFieldValue("City", place.City);
    props.setFieldValue("Country", place.Country);
    props.setFieldValue("State", place.State);
    props.setFieldValue("VenueGUID", place.GUID);
    props.setFieldValue("Venue", place.Name);
  }

  useEffect(() => {
    document.getElementById("numberOfEvents").focus();
  }, []);

  function addABunch(config) {
    console.log({ config });
    let events;
    if (until) {
      const startDate = moment(config.Date);
      const finalDate = moment(config.finalDate);
      const differenceOfDays = finalDate.diff(startDate, "days") + 1;
      events = [...Array(differenceOfDays).keys()].map((event, i) => ({
        Name: config.Name,
        Date:
          i === 0
            ? moment(config.Date).toDate()
            : moment(config.Date).add(i, "days").toDate(),
        GUID: uuid.v4(),
        EventTypeName: config.EventTypeName,
        EventType: config.EventType,
        City: config.City,
        Country: config.Country,
        Latitude: "",
        State: config.State,
        Longitude: "",
        VenueGUID: config.VenueGUID,
        Venue: { Name: config.Venue },
        Status: "Confirmed",
        OrganizationGUID: getOrganization().GUID,
      }));
    } else {
      events = [...Array(config.Number).keys()].map((event, i) => ({
        Name: config.Name || "",
        Date:
          i === 0
            ? moment(config.Date).toDate()
            : moment(config.Date).add(i, "days").toDate(),
        GUID: uuid.v4(),
        EventTypeName: config.EventTypeName || "",
        EventType: config.EventType || "",
        City: config.City,
        Country: config.Country,
        Latitude: "",
        State: config.State,
        Longitude: "",
        VenueGUID: config.VenueGUID,
        Venue: { Name: config.Venue },
        Status: "Confirmed",
        OrganizationGUID: getOrganization().GUID,
      }));
    }
    addBunchEvents(events);
    toggle();
  }

  function showDates(start, end) {
    const startDate = moment(start);
    const finalDate = moment(end);

    const differenceOfDays = finalDate.diff(startDate, "days") + 1;
    return `${differenceOfDays} dates`;
  }
  return (
    <div className="vehicleModal__root px-4 py-2">
      <h4 className="title">
        {
          <FormattedMessage
            id="Add Multiple events"
            defaultMessage="Add Multiple events"
          />
        }
      </h4>
      <Formik
        onSubmit={(values) => {
          addABunch(values);
        }}
        initialValues={{
          Name: "",
          Date: new Date(),
          finalDate: new Date(),
          EventType: "",
          Venue: "",
          VenueGUID: "",
          Number: 1,
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off">
              <div className="row">
                <div className="col-md-5">
                  <CheckBox
                    header={
                      <FormattedMessage
                        id="Amount of Dates"
                        defaultMessage="Amount of Dates"
                      />
                    }
                    value={until === false}
                    onChange={() => setUntil(false)}
                  />
                </div>
                <div className="col-md-5">
                  <CheckBox
                    header={
                      <FormattedMessage
                        id="From and To Dates"
                        defaultMessage="From and To Dates"
                      />
                    }
                    value={until === true}
                    onChange={() => {
                      props.setFieldValue("finalDate", props.values.Date);
                      setUntil(true);
                    }}
                  />
                </div>
              </div>

              {until ? (
                <div className="row">
                  <div className="col-md-6 pl-0">
                    <label>
                      <FormattedMessage
                        id="Starting From"
                        defaultMessage="Starting From"
                      />
                    </label>
                    <input
                      type="date"
                      className="form-control mt-2"
                      value={
                        props.values.Date
                          ? moment(props.values.Date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }

                          props.setFieldValue("Date", e.target.value);
                        } catch (ex) {
                          console.error(ex);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 pr-0">
                    <label>
                      <FormattedMessage id="Until" defaultMessage="Until" />
                    </label>
                    {until ? (
                      <input
                        type="date"
                        className="form-control mt-2"
                        value={
                          props.values.finalDate
                            ? moment(props.values.finalDate).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(e) => {
                          try {
                            if (!e.target.value) {
                              return;
                            }

                            props.setFieldValue("finalDate", e.target.value);
                          } catch (ex) {
                            console.error(ex);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="row px-0">
                  <div className="col-md-6 pl-0">
                    <label>
                      <FormattedMessage
                        id="Starting From"
                        defaultMessage="Starting From"
                      />
                    </label>
                    <input
                      type="date"
                      className="form-control mt-2"
                      value={
                        props.values.Date
                          ? moment(props.values.Date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }

                          props.setFieldValue("Date", e.target.value);
                        } catch (ex) {
                          console.error(ex);
                        }
                      }}
                    />
                    {/* <DatePicker
                    placeholderText="Select a starting date date"
                    selected={props.values.Date}
                    onChange={(e) => {
                      props.setFieldValue("Date", e);
                    }}
                  /> */}
                  </div>
                  <div className="col-md-6 pr-0">
                    <label>
                      <FormattedMessage
                        id="How many events"
                        defaultMessage="How many events"
                      />
                    </label>
                    <Field
                      id="numberOfEvents"
                      type="number"
                      className="form-control my-1"
                      name="Number"
                      placeholder={intl.formatMessage({
                        id: "How many events",
                      })}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          props.submitForm(props.values);
                          toggle();
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-12 px-0">
                <label>
                  {
                    <FormattedMessage
                      id="eventName"
                      defaultMessage="Event Name"
                    />
                  }
                </label>
                <Field
                  autoComplete="off"
                  type="text"
                  className="form-control my-1"
                  name="Name"
                  placeholder="Name of the event"
                />
              </div>

              <div className="col-md-12 px-0 relative">
                {searchingVenues && (
                  <div className="tinyLoader__container" style={{ right: 0 }}>
                    <SimpleLoader loading={searchingVenues} />
                  </div>
                )}

                <label>
                  <FormattedMessage
                    id="Venue (Optional)"
                    defaultMessage="Venue (Optional)"
                  />
                </label>
                <Field
                  autoComplete="off"
                  type="text"
                  className="form-control my-1"
                  name="Venue"
                  placeholder={intl.formatMessage({ id: "Venue" })}
                  onChange={(e) => {
                    props.setFieldValue("Venue", e.currentTarget.value);
                    if (e.currentTarget.value.length > 3) {
                      searchVenues.callback(e.currentTarget.value);
                    } else {
                      setVenues([]);
                    }
                  }}
                />

                <DropDownVenue
                  searchList={venues}
                  setData={(id) => {
                    setVenue(venues[id], props);
                    setVenues([]);
                  }}
                  displayValue={"Name"}
                />
              </div>

              <div className="col-md-12 px-0">
                <label>
                  <FormattedMessage
                    id="Event Type"
                    defaultMessage="Event Type  "
                  />
                </label>
                <Select
                  placeholder={intl.formatMessage({ id: "Type of Event" })}
                  options={EVENT_TYPES.map((e) => ({
                    value: e,
                    label: e,
                  }))}
                  onChange={(option) => {
                    props.setFieldValue("EventType", option.value);
                    props.setFieldValue("EventTypeName", option);
                  }}
                />
                <small className="mr-auto text-dark form-text d-flex align-items-center">
                  <CheckCircle
                    htmlColor="#1ba863"
                    style={{ fontSize: "1em", marginRight: 5 }}
                  />
                  {until
                    ? showDates(props.values.Date, props.values.finalDate)
                    : `${props.values.Number}` +
                      intl.formatMessage({ id: "events" })}
                </small>
              </div>

              <div className="pb-2 pt-3 text-right">
                {loading ? (
                  <SimpleLoader loading={loading} />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      onClick={() => toggle()}
                      type="button"
                      className="btn secondary text-capitaleze w-120 mr-2"
                    >
                      <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                    </button>
                    <button
                      type="submit"
                      className="btn primary text-capitalize w-160"
                    >
                      <FormattedMessage
                        id="Add events"
                        defaultMessage="Add events"
                      />
                    </button>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BatchEventsModal;
