import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function addMutipleEvents(data, success) {
  const url = "/Events/Add";
  return async (dispatch) => {
    dispatch({ type: constant.CREATING_MULTI_EVENTS });
    try {
      const request = await api.post(url, data);
      dispatch({
        type: constant.CREATED_MULTI_EVENTS_SUCCESS,
        data: request.data,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_MULTI_EVENTS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_MULTI_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_MULTI_EVENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CREATING_MULTI_EVENTS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
