import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";
import uuid from "uuid";

export function exportAttachments(attachment, events, moving, success) {
  const url = "/Attachment/AddEditForEventWithoutImage";
  return async (dispatch) => {
    delete attachment.ID;
    try {
      if (moving) {
        await apiService.post(url, {
          ...attachment,
          Deleted: new Date(),
        });
      }
      const attachments = events.map((event) => ({
        ...attachment,
        EventGUID: event,
        GUID: uuid.v4(),
      }));
      await db.table("attachments").bulkPut(attachments);
      success();
      await Promise.all(
        attachments.map(async (attachment) => {
          await apiService.post(url, attachment);
        })
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_CONTACT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_RESOURCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EXPORTED_RESOURCE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EXPORTING_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
