import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function addItinerary(itinerary, event, success, toggleItem) {
  const url = `/ScheduleMaster/AddEdit`;
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_ITINERARY,
    });
    try {
      const schedule = {
        Label: itinerary.label,
        Name: itinerary.Name,
        EventGUID: event,
        Date: itinerary.Date,
        GUID: itinerary.GUID,
        Schedules: [],
      };

      // Trying to simulate a background behaviour
      const response = await creatingScheduleMaster(
        url,
        itinerary.Name,
        itinerary.Date,
        event,
        itinerary.GUID
      );

      if (response.data.Status === "ok") {
        schedule.GUID = response.data.Response.GUID;
      }

      await db.table("schedules").put(schedule);

      dispatch({
        type: constant.CREATED_ITINERARY_SUCCESS,
        data: schedule,
      });
      success(schedule);
      toggleItem();
    } catch (error) {
      dispatch({
        type: constant.CREATING_ITINERARY_ERROR,
        data: error,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_ITINERARY: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_ITINERARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        schedulesMasters: [...state.schedulesMasters, action.data],
      };
    }
    case constant.CREATING_ITINERARY_ERROR: {
      return {
        ...state,
        loading: false,
        schedulesMasters: state.scheduleMasters,
      };
    }
    default:
      return state;
  }
}

function creatingScheduleMaster(url, name, date, event_id, guid) {
  return apiService.post(url, {
    GUID: guid,
    Name: name,
    EventGUID: event_id,
    Date: date,
    Public: true,
  });
}
