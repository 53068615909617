import React from "react";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IMAGES, ROOM_TYPES } from "../../constant";
import { ChevronDown } from "mdi-material-ui";
import uuid from "uuid";
import { useIntl, FormattedMessage } from "react-intl";
import GeneralUserItem from "../common/GeneralUserItem";

const RoomListItem = (props) => {
  let { room, updateReservation, loading } = props;
  const idCheckin = uuid.v4();
  const idCheckout = uuid.v4();
  const intl = useIntl();

  return (
    <div className="roomList__item ">
      <Formik initialValues={room}>
        {(props) => (
          <Form className="d-flex align-items-center">
            <div className="roomList__item-details-container d-flex align-items-center">
              <GeneralUserItem account={room.Account} showShortName />
            </div>
            <div className="roomList__item-field">
              <div className="relative">
                <div>
                  <input
                    type="date"
                    className="form-control py-0"
                    // disabled={!props.doesExist}
                    value={
                      room.CheckInDate
                        ? moment(room.CheckInDate).format("YYYY-MM-DD")
                        : moment(props?.hotel?.Checkin).format("YYYY-MM-DD")
                    }
                    //value={moment(state.CheckOutDate.value).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      try {
                        if (!e.target.value) {
                          return;
                        }
                        updateReservation({
                          ...props.values,
                          CheckInDate: moment(e.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        });
                        // setState({
                        //     ...state,
                        //     CheckOutDate: moment(e.target.value).format("YYYY-MM-DD")
                        // });

                        // props.updateSelected(state);
                      } catch (ex) {
                        console.error(ex);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="roomList__item-field">
              <div className="relative">
                <div>
                  <input
                    type="date"
                    className="form-control py-0"
                    //disabled={!props.doesExist}
                    value={
                      room.CheckOutDate
                        ? moment(room.CheckOutDate).format("YYYY-MM-DD")
                        : moment(props?.hotel?.Checkout).format("YYYY-MM-DD")
                    }
                    //value={moment(state.CheckOutDate.value).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      try {
                        if (!e.target.value) {
                          return;
                        }
                        updateReservation({
                          ...props.values,
                          CheckOutDate: moment(e.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      } catch (ex) {
                        console.error(ex);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="roomList__item-field">
              <div>
                <Field
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "reservation" })}
                  name="Number"
                  onChange={(e) => {
                    props.setFieldValue("Number", e.target.value);
                  }}
                  onBlur={() => updateReservation(props.values)}
                />
              </div>
            </div>
            <div className="roomList__item-field">
              <div>
                <Field
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "roomNumber" })}
                  name="Room"
                  onChange={(e) => {
                    props.setFieldValue("Room", e.target.value);
                  }}
                  onBlur={() => updateReservation(props.values)}
                />{" "}
              </div>
            </div>
            <div className="roomList__item-field">
              <Select
                placeholder={
                  room.RoomType
                    ? room.RoomType
                    : intl.formatMessage({ id: "roomType" })
                }
                options={ROOM_TYPES}
                onChange={(option) => {
                  props.setFieldValue("RoomType", option.value);
                }}
                onBlur={() => updateReservation(props.values)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RoomListItem;
