import * as constant from "./constants";
import api from "../../../common/api";

const sub_url = "/Venues/Venues?Search=";

export function getVenues(query) {
  return async (dispatch) => {
    try {
      const request = await api.get(`${sub_url}${query}`);
      dispatch({
        type: constant.POPULATE_VENUES,
        data: request.data.Response,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.POPULATE_VENUES: {
      return {
        ...state,
        venues: action.data,
      };
    }
    default:
      return state;
  }
}
