import api from "../../../common/api";
import * as constant from "./constants";

export function addAccount(account, users, field, value) {
  if (field && value) {
    const updatedUsers = users.map((user) => {
      if (account.guid === user.guid) {
        return Object.assign({}, user, {
          [field]: value,
        });
      } else {
        return user;
      }
    });

    return (dispatch) => {
      dispatch({
        type: constant.UPDATE_ACCOUNT,
        data: updatedUsers,
      });
    };
  }

  return (dispatch) => {
    dispatch({
      type: constant.ADD_ACCOUNT,
      data: account,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.ADD_ACCOUNT: {
      return {
        ...state,
        emailToInvite: state.emailToInvite
          .filter((e) => e.guid !== action.data.guid)
          .concat(action.data),
      };
    }
    case constant.UPDATE_ACCOUNT: {
      return {
        ...state,
        emailToInvite: action.data,
      };
    }
    default:
      return state;
  }
}
