import React, { Component } from "react";
import PropTypes from "prop-types";
import GuestList from "./GuestList";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { default as Navbar } from "../../../components/common/navbar";
import { ToastNotificationSuccess } from "../../../components/common";
import * as actions from "../redux/actions";
import * as calendarActions from "../../calendar/redux/actions";
import * as organizationActions from "../../organization/redux/actions";
import {
  getOrganization,
  getBasicInfo,
  groupBy,
  isEqual,
} from "../../../utils";
import { LOCAL_STORAGE, IMAGES } from "../../../constant";
import uuid from "uuid";
import Swal from "sweetalert2";
import api, { apiService } from "../../../common/api";
import { injectIntl, FormattedMessage } from "react-intl";
class GuestListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      showEventsSidebar: false,
      modalTicket: false,
      ticket: null,
      editModal: false,
      ticketSelected: false,
      requestSelected: [],
      responseModal: false,
      adminNote: false,
      boxOfficeModal: false,
      compsModal: false,
      mode: false,
      newRequested: false,
      showEmailModal: false,
      userNote: false,
    };
  }

  componentDidMount() {
    const user = getBasicInfo();
    const organization = getOrganization();
    this.props.calendarActions.getEvents(organization.GUID, user.GUID);
    this.props.organizationActions.getOrganizationList(organization.GUID);
    this.props.organizationActions.getCurrentOrganization(
      organization.GUID,
      user.GUID
    );
    console.log("Current EVENT ");
    console.log(LOCAL_STORAGE.CURRENT_EVENT_GUID);

    if (
      LOCAL_STORAGE !== null &&
      LOCAL_STORAGE.CURRENT_EVENT_GUID !== null &&
      LOCAL_STORAGE.CURRENT_EVENT_GUID !== "CURRENT_EVENT_GUID" &&
      LOCAL_STORAGE.CURRENT_EVENT_GUID.length > 0
    ) {
      this.props.actions.selectEvent(
        localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
      );
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.selectedEvent === null) {
      if (this.props.events.length > 0) {
        let defaultEvent;
        const localEvent = localStorage.getItem("CURRENT_EVENT_GUID");
        if (localEvent) {
          defaultEvent = localEvent;
        } else {
          defaultEvent = this.props.events[0].GUID;
        }
        this.setCurrentEvent(defaultEvent);
      }
    }

    if (this.state.selectedEvent !== null) {
      if (!isEqual(prevState.selectedEvent, this.state.selectedEvent)) {
        this.props.actions.selectEvent(
          localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
        );
      }
    }
  }

  setRequestGroup = (requestSelected) => {
    this.setState({
      requestSelected,
    });
  };

  toggleModalTicket = () => {
    this.setState({
      modalTicket: !this.state.modalTicket,
    });
  };
  toggleMailModal = () => {
    this.setState({
      showEmailModal: !this.state.showEmailModal,
    });
  };
  selectEvent = (event) => {
    this.setState(
      {
        selectedEvent: event,
      },
      () => this.props.actions.selectEvent(event.GUID)
    );
  };

  toggleSidebarEvents = () => {
    this.setState({
      showEventsSidebar: !this.state.showEventsSidebar,
    });
  };

  setCurrentEvent = (defaultEvent) => {
    const selectedEvent = defaultEvent
      ? this.props.events.find((e) => e.GUID === defaultEvent)
      : this.props.events[0];
    localStorage.setItem(LOCAL_STORAGE.CURRENT_EVENT_GUID, defaultEvent);
    this.setState({
      selectedEvent,
      showEventsSidebar: false,
    });
  };

  changeCurrentEvent = (selectedEvent) => {
    const newEvent = this.props.events.find((e) => e.GUID === selectedEvent);
    localStorage.setItem(LOCAL_STORAGE.CURRENT_EVENT_GUID, newEvent.GUID);
    this.setState({
      selectedEvent: newEvent,
      showEventsSidebar: false,
    });
  };

  deleteElement = (item) => {
    Swal.fire({
      text: this.props.intl.formatMessage({
        id: "Are you sure you want to delete this item?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.props.intl.formatMessage({ id: "yes" }),
      cancelButtonText: this.props.intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        this.updateRequest(item);
      }
    });
  };

  confirmAll = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to approve all the request",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Approve",
    }).then(async (result) => {
      if (result.value) {
        debugger;
        let query =
          "EventGUID=" +
          item.EventGUID +
          "&RequestAccountGUID=" +
          item.RequestAccountGUID +
          "&ConfirmedAccountGUID=" +
          item.ConfirmAccountGUID;
        let request = await apiService.get("/GuestList/ApproveAll?" + query);
        if (request.data.Status === "ok") {
          this.props.actions.selectEvent(
            localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
          );
        }
        //this.updateRequest(item);
      }
    });
  };

  rejectAll = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to decline all the request",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        debugger;
        let query =
          "EventGUID=" +
          item.EventGUID +
          "&RequestAccountGUID=" +
          item.RequestAccountGUID +
          "&ConfirmedAccountGUID=" +
          item.ConfirmAccountGUID;
        let request = await apiService.get("/GuestList/RejectAll?" + query);
        if (request.data.Status === "ok") {
          this.props.actions.selectEvent(
            localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
          );
        }
        //this.updateRequest(item);
      }
    });
  };

  createTicket = (pass, newOne) => {
    const user = getBasicInfo();
    const newPass = {
      ...pass,
      GUID: uuid.v4(),
      RequestedFor: pass.RequestedFor,
      RequestedForLastName: pass.RequestedForLastName,
      RequestAccountGUID: pass.RequestAccountGUID
        ? pass.RequestAccountGUID
        : user.GUID,
      ConfirmDate: null,
      RequestDate: new Date(),
      Confirmed: false,
      ConfirmedPasses: null,
      ConfirmAccountGUID: null,
      // NoteConfirmed: null,
      Notify: false,
    };
    this.props.actions.createRequest(newPass, () => {
      if (!newOne) {
        this.toggleModalTicket();
      } else {
        this.toggleEditModal({});
      }
      ToastNotificationSuccess(Math.random(), "Created successfully");
      this.props.actions.selectEvent(
        localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
      );
      this.setState({
        newRequested: false,
      });
    });
  };

  updateRequest = (pass, withToggle, withResponse) => {
    this.props.actions.updateRequest(pass, () => {
      if (withToggle) {
        ToastNotificationSuccess(Math.random(), "Updated successfully");
        this.toggleEditModal({});
      }
      if (withResponse) {
        ToastNotificationSuccess(Math.random(), "Updated successfully");
        this.toggleResponseModal({});
      }
      this.props.actions.selectEvent(
        localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
      );
    });
  };

  toggleEditModal = (ticket, mode) => {
    if (mode) {
      return this.setState({
        editModal: !this.state.editModal,
        ticket,
        ticketSelected: !this.state.ticketSelected,
        mode,
      });
    }
    return this.setState({
      editModal: !this.state.editModal,
      ticket,
      ticketSelected: !this.state.ticketSelected,
    });
  };

  toggleAdminNote = (ticket, userNote) => {
    this.setState({
      adminNote: !this.state.adminNote,
      ticket,
      userNote,
    });
  };

  toggleResponseModal = (ticket, approval) => {
    this.setState({
      responseModal: !this.state.responseModal,
      approval,
      ticket,
      ticketSelected: !this.state.ticketSelected,
    });
  };

  updateComps = ({ Comps, ReturnedCompsCount }) => {
    const { selectedEvent } = this.state;
    this.props.actions.editEvent(
      {
        ...selectedEvent,
        CompsCount: Comps,
        ReturnedCompsCount,
        Venue: null,
        EventGroup: null,
      },
      () => {
        this.toggleComps();
        this.setState({
          selectedEvent: {
            ...this.state.selectedEvent,
            CompsCount: Comps,
            ReturnedCompsCount,
          },
        });
        ToastNotificationSuccess(Math.random(), "Comps updated");
      }
    );
  };

  reloadSelectedEvent = (newRecord) => {
    let copy = this.state;
    copy.selectedEvent = newRecord;
    this.setState(copy);
  };

  editBoxOffice = (boxOfficeDetails) => {
    const { selectedEvent } = this.state;

    this.props.actions.editEvent(
      { ...selectedEvent, ...boxOfficeDetails, Venue: null, EventGroup: null },
      () => {
        ToastNotificationSuccess(Math.random(), "Box Office Updated");
        this.toggleBoxOffice();
        this.setState({
          selectedEvent: { ...this.state.selectedEvent, ...boxOfficeDetails },
        });
      }
    );
  };

  toggleBoxOffice = () => {
    this.setState({
      boxOfficeModal: !this.state.boxOfficeModal,
    });
  };

  toggleComps = () => {
    this.setState({
      compsModal: !this.state.compsModal,
    });
  };

  setMode = (mode) => {
    this.setState({
      mode,
    });
  };

  continueAndAddNewRequest = (pass, setNewRequest) => {
    const user = getBasicInfo();
    const newPass = {
      ...pass,
      GUID: uuid.v4(),
      RequestedFor: pass.RequestedFor,
      RequestedForLastName: pass.RequestedForLastName,
      RequestAccountGUID: pass.RequestAccountGUID
        ? pass.RequestAccountGUID
        : user.GUID,
      ConfirmDate: null,
      RequestDate: new Date(),
      Confirmed: false,
      ConfirmedPasses: null,
      ConfirmAccountGUID: null,
      // NoteConfirmed: null,
    };
    this.props.actions.createRequest(newPass, () => {
      setNewRequest();
      ToastNotificationSuccess(Math.random(), "Created successfully");
      this.setState({
        newRequested: true,
      });
      this.props.actions.selectEvent(
        localStorage.getItem(LOCAL_STORAGE.CURRENT_EVENT_GUID)
      );
    });
  };

  render() {
    const { tickets, events, passesAndTickets, uniqueEmailsAndMembers } =
      this.props;
    return (
      <GuestList
        {...this.state}
        ticketsAndPasses={passesAndTickets}
        cleanedTickets={this.props.cleanedTickets}
        loadingTickets={tickets.loadingTickets}
        tickets={tickets.ticketsAndPasses}
        eventList={events}
        selectEvent={this.selectEvent}
        toggleLayout={this.toggleLayout}
        toggleSidebarEvents={this.toggleSidebarEvents}
        setCurrentEvent={this.changeCurrentEvent}
        deleteElement={this.deleteElement}
        rejectAll={this.rejectAll}
        confirmAll={this.confirmAll}
        updateRequest={this.updateRequest}
        createTicket={this.createTicket}
        toggleModalTicket={this.toggleModalTicket}
        teams={uniqueEmailsAndMembers}
        toggleEditModal={this.toggleEditModal}
        setRequestGroup={this.setRequestGroup}
        toggleResponseModal={this.toggleResponseModal}
        toggleAdminNote={this.toggleAdminNote}
        updateComps={this.updateComps}
        toggleBoxOffice={this.toggleBoxOffice}
        editBoxOffice={this.editBoxOffice}
        toggleComps={this.toggleComps}
        setMode={this.setMode}
        continueAndAddNewRequest={this.continueAndAddNewRequest}
        toggleMailModal={this.toggleMailModal}
        showEmailModal={this.state.showEmailModal}
        eventDetail={actions.eventDetail}
        reloadSelectedEvent={this.reloadSelectedEvent}
        showExpanded={this.props.showExpanded}
        expanded={this.props.expanded}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const tickets = state.tickets;
  const events = state.calendar.calendarEvents;
  const teams = state.organizations.teams
    .map((item) =>
      item.Staffs.filter(
        (item) =>
          item.Account.Email !== null &&
          item.Account.Email.length > 2 &&
          item.Deleted === null
      ).map((staff) => ({
        value: staff.AccountGUID,
        label: `${staff.Account.Name} ${staff.Account.LastName} (${staff.Account.Email})`,
        email: staff.Account.Email,
      }))
    )
    .flat();

  const uniqueEmailsAndMembers = [
    ...new Map(teams.map((item) => [item["email"], item])).values(),
  ];

  const cleanedTickets = tickets.ticketsAndPasses.filter(
    (t) => t.Deleted === null
  );

  const passesAndTickets = Object.values(
    groupBy(cleanedTickets, "RequestAccountGUID")
  );
  return {
    uniqueEmailsAndMembers,
    teams,
    tickets,
    events,
    passesAndTickets,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    calendarActions: bindActionCreators({ ...calendarActions }, dispatch),
    organizationActions: bindActionCreators(
      { ...organizationActions },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar(injectIntl(GuestListContainer)));
