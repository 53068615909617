import api from "../../../common/api";
import uuid from "uuid";

import * as constant from "./constants";
const subUrl = "/Roles/AddEdit";

export function createTeam(teams, name, organization, callBack) {
  return async (dispatch) => {
    try {
      dispatch({ type: constant.ADDING_TEAM });
      const request = await creatingTeamRequest(
        subUrl,
        name,
        organization,
        teams
      );
      dispatch({
        type: constant.ADD_TEAM,
        data: request.data.response,
      });
      callBack();
    } catch (error) {
      dispatch({
        type: constant.ADDING_TEAM_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.ADDING_TEAM:
      return {
        ...state,
        loading: true,
      };
    case constant.ADD_TEAM:
      return {
        ...state,
        loading: false,
        teams: [...state.teams, { ...action.data, Staffs: [] }],
      };
    case constant.ADDING_TEAM_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function creatingTeamRequest(url, name, organization, teams) {
  const _guid = uuid.v4();
  const roles = teams.map((team) => ({
    roleGUID: _guid,
    canViewRoleGUID: team.GUID,
    guid: uuid.v4(),
  }));
  return api.post(url, {
    guid: _guid,
    name: name,
    organizationGUID: organization.GUID,
    organization: null,
    AllowToReceiveNotifications: true,
    role_CanViewRole1: roles,
    scheduleMasterVisibleBies: null,
    staffs: null,
    role_CanViewRole: null,
  });
}
