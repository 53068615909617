export const ACTIVATE_MEET = "ACTIVATE_MEET";
export const ACCEPT_MEET = "ACCEPT_MEET";
export const DECLINE_MEET = "DECLINE_MEET";
export const CLOSE_MEET = "CLOSE_MEET";

export const ENABLE_CHAT = "ENABLE_CHAT";
export const CHANGE_CONVERSATION = "CHANGE_CONVERSATION";
export const SET_RECENT_CONVERSATIONS = "SET_RECENT_CONVERSATIONS";
export const SET_CONNECTED_USERS = "SET_CONNECTED_USERS";
export const CLOSE_CHAT = "CLOSE_CHAT";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const SET_CONNECTION = "SET_CONNECTION";
export const SET_CHATS = "SET_CHATS";
export const SET_CONVERSATION_DETAILS = "SET_CONVERSATION_DETAILS";
export const SET_CHAT_REFRESHED = 'SET_CHAT_REFRESHED'