import initialState from "./initialState";
import { reducer as makeMeetReducer } from "./makeMeet";
import { reducer as chatHandlersReducer } from "./chatHandlers";

const reducers = [makeMeetReducer, chatHandlersReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
