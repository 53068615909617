import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import FormField from "../../form/formField";
import { getBasicInfo, getOrganization } from "../../../utils";
import { addListSchema } from "../../../utils/validationsSchemas";
import { ToastNotificationSuccess } from "../..";
import uuid from "uuid";
import { InformationOutline } from "mdi-material-ui";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

import * as actions from "../../../page/todoPage/redux/actions";
import * as calendarActions from "../../../page/calendar/redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AddList = (props) => {
  const intl = useIntl();

  useEffect(() => {
    const user = getBasicInfo();
    const org = getOrganization();
    document.getElementsByClassName("Name")[0].focus();
    if (props.events.length === 0) {
      props.calendarActions.getEvents(org.GUID, user.GUID);
    }
  }, []);

  const addList = (list) => {
    const allStaffs = [];
    const user = getBasicInfo();
    const newList = {
      ...list,
      CreatedByGUID: user.GUID,
    };
    props.teams
      .filter((t) => t.Deleted === null)
      .map((team) => {
        team.Staffs.filter((s) => s.Deleted === null).map((s) =>
          allStaffs.push({
            GUID: uuid.v4(),
            AccountGUID: s.AccountGUID,
            Deleted: new Date(),
            TodoGUID: null,
          })
        );
      });
    props.actions.addList(newList, allStaffs, () => {
      ToastNotificationSuccess(Math.random(), "List created successfully");
      props.toggle();
    });
  };

  const { events, loading, loadingCalendarEvents } = props;
  const eventsToSelect = events.map((e) => ({
    label: moment(e.Date).format("MMM DD, YYYY") + " - " + e.Name,
    value: e.GUID,
  }));
  return (
    <div className="event-page" style={{ overflowY: "visible" }}>
      <div className="event-expennd-section">
        <div className="event-scroll">
          <div className="text-head">
            <div className="text-left d-flex justify-content-between w-100">
              <h5 className="title">
                <FormattedMessage id="addTo-Do" defaultMessage="Add To-Do" />
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => props.toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </div>
          <Formik
            onSubmit={(values) => addList(values)}
            validationSchema={addListSchema}
            enableReinitialize
            initialValues={{
              GUID: uuid.v4(),
              Name: "",
              EventGUID: "",
              Note: "",
              Date: new Date(),
            }}
          >
            {(props) => (
              <Form autoComplete="off" className="addEvent__form">
                <div className="event-inside">
                  <div className="event-content">
                    <FormField
                      fullWidth={true}
                      label={
                        <FormattedMessage id="name" defaultMessage="Name" />
                      }
                    >
                      <Field
                        className="form-control Name"
                        type="text"
                        name="Name"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="Name"
                        component="div"
                      />
                    </FormField>
                    <div className="row">
                      <div className="col-md-12 px-0 pr-2 event-picker">
                        <label
                          className="d-flex align-items-center"
                          htmlFor={"EventType"}
                          style={{ marginBottom: 10 }}
                        >
                          <FormattedMessage
                            id="linkWithEvent"
                            defaultMessage="Link with an Event (Optional)"
                          />
                          <>
                            <ReactTooltip type="info" effect="solid" />
                            <InformationOutline
                              className="ml-1 label-info"
                              data-tip={intl.formatMessage({
                                id: "byLinkingTo-Do",
                              })}
                            />
                          </>
                        </label>
                        <div
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            bottom: "10px",
                          }}
                        >
                          <Select
                            disabled={loadingCalendarEvents}
                            options={eventsToSelect}
                            placeholder={
                              loadingCalendarEvents
                                ? "Fetching events..."
                                : "None"
                            }
                            isSearchable
                            name="EventType"
                            onChange={(selection) =>
                              props.setFieldValue("EventGUID", selection.value)
                            }
                          />
                          <ErrorMessage
                            className="error-message"
                            name="EventType"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb-2">
                      <button
                        disabled={!props.isValid || loading}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {loading ? (
                          "Adding..."
                        ) : (
                          <FormattedMessage id="done" defaultMessage="Done" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.calendar.calendarEvents,
  loadingCalendarEvents: state.calendar.loading,
  teams: state.organizations.teams,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    calendarActions: bindActionCreators({ ...calendarActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddList);
