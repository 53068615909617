import React, { useEffect, useState } from "react";
import { IMAGES } from "../../constant";
import { MapComponent } from "../common";
import { getBasicInfo, getDistanceFromLatLonInKm } from "../../utils";
import db from "../../common/db";
import { useIntl, FormattedMessage } from "react-intl";

import {
  sortArray,
  VenueFullAddress,
  HotelFullAddress,
  KMToMiles,
} from "../../common/helpers";
const MarkerModal = (props) => {
  const basicInfoGUID = getBasicInfo().GUID;
  const [state, setState] = useState({
    Places: [],
    PointA: null,
    PointB: null,
    CenterMap: null,
    AppSettings: null,
  });

  useEffect(() => {
    loadLocalData();
    loadSettings();
  }, []);

  const loadSettings = async () => {
    let copy = JSON.parse(JSON.stringify(state));
    const appSettings = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    if (appSettings) {
      if (appSettings.metric === undefined) {
        appSettings.metric = "m";
        await db.table("appSettings").put(appSettings);
      }

      copy.AppSettings = appSettings;
      if (state) {
        setState(copy);
      }
      //}, 500)
    } else {
      let newAppSettings = {
        AccountGUID: basicInfoGUID,
        metric: "m",
      };
      await db.table("appSettings").put(newAppSettings);

      // state.AppSettings.set(newAppSettings);

      copy.AppSettings = newAppSettings;
    }
  };

  const loadLocalData = async () => {
    let places = [];
    try {
      if (props.event.Venue) {
        let full = VenueFullAddress(props.event.Venue);
        //Venue
        places.push({
          type: "venue",
          id: props.event.VenueGUID,
          order: 1,
          place: {
            Name: props.event.Venue.Name,
            SubTitle: full,
            Latitude: props.event.Venue.Latitude,
            Longitude: props.event.Venue.Longitude,
          },
        });
        //   state.CenterMap.set({lat: props.event.Venue.Latitude, lng: props.event.Venue.Longitude});
      }

      //Hotels
      let hotels = await db
        .table("hotels")
        .where({ EventGUID: props.event.GUID })
        .toArray();

      hotels = hotels.filter((x) => x.Deleted === null);
      hotels.forEach((item) => {
        places.push({
          type: "hotel",
          id: item.GUID,
          order: 3,
          place: {
            Name: item.Name,
            SubTitle: HotelFullAddress(item),
            Latitude: item.Latitude,
            Longitude: item.Longitude,
          },
        });
      });
      //Airports
      let flights = await db
        .table("flights")
        .where({ EventGUID: props.event.GUID })
        .toArray();
      flights = flights.filter((x) => x.Deleted === null);
      let flightsToUse = [];
      flights.forEach((item) => {
        flightsToUse.push({
          type: "airport",
          id: item.GUID,
          order: 2,
          place: {
            Name: item.From,
            SubTitle: item.FromLongName + ", " + item.FromAddress,
            Latitude: item.FromLatitude,
            Longitude: item.FromLongitude,
          },
        });

        flightsToUse.push({
          type: "airport",
          id: item.GUID,
          order: 2,
          place: {
            Name: item.To,
            SubTitle: item.ToLongName + ", " + item.ToAddress,
            Latitude: item.ToLatitude,
            Longitude: item.ToLongitude,
          },
        });
      });

      let finalFlightsToUse = [];
      flightsToUse.forEach((singleFlight) => {
        //check if exist
        let exist = finalFlightsToUse.filter(
          (x) => x.place.Name === singleFlight.place.Name
        );
        if (exist.length === 0) {
          finalFlightsToUse.push(singleFlight);
        }
      });

      places.push(...finalFlightsToUse);

      setTimeout(() => {
        let copy = JSON.parse(JSON.stringify(state));
        copy.Places = sortArray(places, "order", true);
        setState(copy);
      }, 1000);
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };
  // Good Morning America Central Park Summer Stage, 72nd street & 5th Ave, New York, NY, United States, 40.7724534,-73.9697477
  const loadDistance = (pointA, pointB, Places, metric) => {
    try {
      let placeAFinal = null;
      let placeBFinal = null;

      if (Places?.length > 0) {
        const placeA = Places.find((x) => x.id === pointA);
        const placeB = Places.find((x) => x.id === pointB);

        if (placeA && placeB) {
          let placeAFullName = placeA.place.Name + ", " + placeA.place.SubTitle;
          var countA = (placeAFullName.match(/\,/g) || []).length;

          let placeBFullName = placeB.place.Name + ", " + placeB.place.SubTitle;
          var countB = (placeBFullName.match(/\,/g) || []).length;

          placeAFinal = placeA.place.Latitude
            ? placeAFullName +
              (countA < 2
                ? ", " + placeA.place.Latitude + "," + placeA.place.Longitude
                : "")
            : placeA.place.Name + ", " + placeA.place.SubTitle;
          placeAFinal = placeAFinal.replace("&", "and"); //To bypass an issue with Google

          placeBFinal = placeB.place.Latitude
            ? placeBFullName +
              (countB < 2
                ? ", " + placeB.place.Latitude + "," + placeB.place.Longitude
                : "")
            : placeB.place.Name + ", " + placeB.place.SubTitle;
          placeBFinal = placeBFinal.replace("&", "and"); //To bypass an issue with Google

          // Check if both have latitude and longitude
          if (placeA.place.Latitude && placeB.place.Latitude) {
            let distanceInKm = getDistanceFromLatLonInKm(
              placeA.place.Latitude,
              placeA.place.Longitude,
              placeB.place.Latitude,
              placeB.place.Longitude
            );
            let strDistance =
              metric === "m"
                ? KMToMiles(distanceInKm) + " miles"
                : distanceInKm + " km";
                
            return (
              <>
                {strDistance}
                <button
                  className="btn btn-success btn-sm  ml-2"
                  onClick={() =>
                    parseAndShowDirection(placeAFinal, placeBFinal)
                  }
                >
                  <i className="fas fa-map-marked-alt"></i>
                </button>
              </>
            );
          } else {
            // If both don't have latitude and longitude
            return (
              <button
                className="btn btn-success btn-sm "
                onClick={() => parseAndShowDirection(placeAFinal, placeBFinal)}
              >
                <i className="fas fa-map-marked-alt"></i>
              </button>
            );
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    return null;
  };

  const placesRecord = () => {
    try {
      let places = [];

      if (state.Places && state.Places.length > 0) {
        state.Places.forEach((subItem, index) => {
          let parsed = JSON.parse(JSON.stringify(subItem));
          try {
            places.push(
              <option value={parsed.id} key={index}>
                {parsed.place.Name +
                  (parsed.place.SubTitle
                    ? " (" + parsed.place.SubTitle + ")"
                    : "")}
              </option>
            );
          } catch (ex) {
            console.error(ex);
            debugger;
          }
        });
      }
      return places;
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };

  function parseAndShowDirection(placeA, placeB) {
    window.open(
      `https://www.google.com/maps/dir/${placeA}/${placeB}`,
      "MapWindow",
      "width=1280,height=840"
    );
  }

  const openPlace = (place) => {
    let query = place.place.Latitude
      ? place.place.Name +
        " " +
        place.place.SubTitle +
        " " +
        place.place.Latitude +
        "," +
        place.place.Longitude
      : place.place.Name + " " + place.place.SubTitle;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${query}`,
      "MapWindow",
      "width=1280,height=840"
    );
  };

  return (
    <div className={props.customClass ?? "organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section move-modal">
          <div className="text-head">
            <div className="text-left">
              <h1>
                <FormattedMessage id="Directions" defaultMessage="Directions" />
              </h1>
            </div>
          </div>
          <div className="eventHeader__distances p-0 m-0">
            <div className="row text-dark">
              <MapComponent
                event={props.event}
                CenterMap={state.CenterMap}
                Points={state.Places}
              ></MapComponent>
              <div className="col-12 bgTitleModal py-1  text-dark">
                <small>
                  <FormattedMessage
                    id="calculateDistance"
                    defaultMessage="Calculate Distance"
                  />
                </small>
              </div>
              <div className="col">
                <small>
                  <FormattedMessage id="from" defaultMessage="From" />
                </small>
                <select
                  className="form-control"
                  value={state.PointA ? state.PointA : ""}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, PointA: e.target.value }));
                  }}
                >
                  <option value="">-</option>
                  {placesRecord()}
                </select>
              </div>
              <div className="col-auto mx-4 text-center pt-3">-</div>
              <div className="col">
                <small>
                  <FormattedMessage id="to" defaultMessage="To" />
                </small>
                <select
                  className="form-control"
                  value={state.PointB ? state.PointB : ""}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, PointB: e.target.value }));
                  }}
                >
                  <option value="">-</option>
                  {placesRecord()}
                </select>
              </div>
              <div className="col-auto ml-auto mr-3">
                <small>
                  <FormattedMessage id="distance" defaultMessage="Distance" />
                </small>
                <br />
                {state.PointA && state.PointB
                  ? loadDistance(state.PointA, state.PointB, state.Places)
                  : "-"}
              </div>
            </div>
            <div className="col-12 bgTitleModal py-1 mt-2">
              <small className="text-dark">
                <FormattedMessage id="addresses" defaultMessage="Addresses" />
              </small>
            </div>
            <div className="col-12 my-2">
              {state.Places.map((item, index) => {
                return (
                  <div
                    className="card shadow-sm my-1 clickable text-dark"
                    key={index}
                    onClick={() => {
                      let obj = {
                        lat: item.place.Latitude,
                        lng: item.place.Longitude,
                      };
                      setState((prev) => ({ ...prev, CenterMap: obj }));
                    }}
                  >
                    <div className="card-body p-1">
                      <div className="row">
                        <div className="col-auto">
                          {item.place.Name}
                          {item.place.SubTitle ? (
                            <>
                              <br />
                              <small className="text-muted">
                                {item.place.SubTitle}
                              </small>
                            </>
                          ) : null}
                        </div>
                        <div className="col text-right">
                          <button
                            className="btn btn-sm btn-success mt-2"
                            onClick={() => {
                              openPlace(item);
                            }}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkerModal;
