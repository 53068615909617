import React, { useState } from "react";
import BasicInfo from "../account/basicInfo";
import SocialMedia from "../account/socialMedia";
import TravelProfile from "../account/travelProfile";

const UserTabs = (props) => {
  const [state, setState] = useState({
    ActiveArea: "basicInfo",
  });

  const selectOption = (opt) => {
    setState((prev) => ({ ...prev, ActiveArea: opt }));
  };
  return (
    <div
      className="AccountContainer card shadow-sm"
      style={{
        minWidth: "800px",
        minHeight: "500px",
        maxHeight: "500px",
      }}
    >
      <div className="card-body row p-0">
        <div className="col-lg-2 col-4 px-1 py-2">
          <div className="row">
            <button
              className={
                state.ActiveArea === "basicInfo"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("basicInfo")}
            >
              Basic Info
            </button>
            <button
              className={
                state.ActiveArea === "socialMedia"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("socialMedia")}
            >
              Social Media
            </button>
            <button
              className={
                state.ActiveArea === "travelProfile"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("travelProfile")}
            >
              Travel Profile
            </button>
          </div>
        </div>
        <div className="col-lg-10 col-8 content px-2">
          {state.ActiveArea === "basicInfo" ? (
            <BasicInfo onlyRead user={props.user} />
          ) : null}
          {state.ActiveArea === "socialMedia" ? (
            <SocialMedia onlyRead user={props.user} />
          ) : null}
          {state.ActiveArea === "travelProfile" ? (
            <TravelProfile onlyRead user={props.user} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default UserTabs;
