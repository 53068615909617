import React from "react";
import { IMAGES } from "../../constant";

const DeleteHotel = ({ hotel }) => {
  return (
    <div className="deleteModal__root">
      <h3 className="deleteModal__title">Delete Hotel?</h3>
      <div className="deleteModal__item">
        <img
          className="img-responsive"
          src={IMAGES.ORGANIZATION_PLACEHOLDER}
          alt={hotel.hotel.name}
        />
        <h6>{hotel.hotel.name}</h6>
      </div>
      <p className="deleteModal__text">
        You are about to delete the hotel. This action can’t be undone.
      </p>
      <div className="deleteModal__buttons">
        <button className="btn secondary">Cancel</button>
        <button className="btn secondary">Delete Hotel</button>
      </div>
    </div>
  );
};

export default DeleteHotel;
