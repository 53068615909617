import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function getHotel(event, currentHotel, sucess) {
  return async (dispatch) => {
    dispatch({
      type: constant.GET_HOTEL,
    });
    try {
      const localEvents = await db
        .table("hotels")
        .where({ EventGUID: event })
        .toArray();
      dispatch({
        type: constant.GET_HOTEL_SUCCESS,
        data: localEvents,
      });

      const request = await api.get("/Hotels/HotelForEvent", {
        params: { EventGUID: event },
      });
      let cleanedHotel = request.data.response;

      // const cleanedHotel = request.data.response
      //   ? request.data.response.filter((d) => d.Deleted === null)
      //   : [];

      await db.table("hotels").bulkPut(cleanedHotel);
      cleanedHotel =
        cleanedHotel && cleanedHotel.filter((x) => x.Deleted === null);
      const [sideBarItem] = currentHotel
        ? cleanedHotel.filter((hotel) => hotel.GUID === currentHotel)
        : [""];
      sucess && sucess(sideBarItem);
      dispatch({
        type: constant.GET_HOTEL_SUCCESS,
        data: cleanedHotel,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_HOTEL: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.GET_HOTEL_SUCCESS: {
      return {
        ...state,
        hotels: action.data,
        loading: false,
      };
    }
    default:
      return state;
  }
}
