import initialState from "./initialState";
import { reducer as getListsOfTodosReducer } from "./getListsOfTodo";
import { reducer as addListReducer } from "./addList";
import { reducer as deleteListReducer } from "./deleteList";
import { reducer as toggleTaskReducer } from "./toggleTask";
import { reducer as addTaskReducer } from "./addTask";
import { reducer as addAssigneesReducer } from "./addAssignees";
import { reducer as editListReducer } from "./editList";
import { reducer as editTaskReducer } from "./editTask";

const reducers = [
  getListsOfTodosReducer,
  addListReducer,
  deleteListReducer,
  toggleTaskReducer,
  addTaskReducer,
  addAssigneesReducer,
  editListReducer,
  editTaskReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
