const initialState = {
  isLoading: false,
  dataState: [],
  sectionsParams: {
    Position: [
      {
        order: 1,
        section: "Header",
        align: null,
      },
      {
        order: 2,
        section: "Itineraries",
        align: null,
      },
      {
        order: 3,
        section: "Flights",
        align: null,
      },
      {
        order: 4,
        section: "Hotels",
        align: null,
      },
      {
        order: 5,
        section: "Transportation",
        align: null,
      },
      {
        order: 6,
        section: "Contacts",
        align: null,
      },
      {
        order: 7,
        section: "Attachments",
        align: null,
      },
      {
        order: 8,
        section: "UpcomingEvents",
        align: null,
      },
      {
        order: 9,
        section: "Notes",
        align: null,
      },
    ],
    Itineraries: [],
    ItinerariesShow: true,
    ItinerariesStyles: null,
    Flights: [],
    FlightsShow: true,
    FlightsStyles: null,
    Hotels: [],
    HotelsShow: true,
    HotelsStyles: null,
    Transportation: [],
    TransportationShow: true,
    TransportationStyles: null,
    Contacts: [],
    ContactsShow: true,
    ContactsStyles: null,
    Attachments: [],
    AttachmentsShow: true,
    AttachmentsStyles: null,
    Header: [],
    HeaderShow: true,
    HeaderStyles: null,
    UpcomingEvents: [],
    UpcomingEventsShow: true,
    UpcomingEventsStyles: null,
    Notes: [],
    NotesShow: true,
    NotesStyles: null,
  },
};

export default initialState;
