export const GETTING_LISTS = "GETTING_LISTS";
export const GET_LISTS = "GET_LISTS";
export const GETTING_LISTS_ERROR = "GETTING_LISTS_ERROR";

export const GETTING_DETAILS = "GETTING_DETAILS";
export const GET_DETAILS = "GET_DETAILS";
export const GETTING_DETAILS_ERROR = "GETTING_DETAILS_ERROR";

export const ADDING_LIST = "ADDING_LIST";
export const ADDED_LIST = "ADDED_LIST";
export const ADDING_LIST_ERROR = "ADDING_LIST_ERROR";
export const DELETING_LIST = "DELETING_LIST";
export const DELETED_LIST = "DELETED_LIST";
export const DELETING_LIST_ERROR = "DELETING_LIST_ERROR";
export const EDITING_LIST = "EDITING_LIST";
export const EDITED_LIST = "EDITED_LIST";
export const EDITING_LIST_ERROR = "EDITING_LIST_ERROR";

export const ADDING_TASK = "ADDING_TASK";
export const ADDED_TASK = "ADDED_TASK";
export const ADDING_TASK_ERROR = "ADDING_TASK_ERROR";
export const EDITING_TASK = "EDITING_TASK";
export const EDITED_TASK = "EDITED_TASK";
export const EDITING_TASK_ERROR = "EDITING_TASK_ERROR";

export const ADDING_ASSIGNEES = "ADDING_ASSIGNEES";
export const ADDED_ASSIGNEES = "ADDED_ASSIGNEES";
export const ADDING_ASSIGNEES_ERROR = "ADDING_ASSIGNEES_ERROR";
