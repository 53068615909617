import React from "react";
import TicketForm from "./TicketForm";

import { IMAGES } from "../../constant";

const RequestPasses = (props) => {
  let {
    toggle,
    event,
    createTicket,
    mode,
    loading,
    teams,
    continueAndAddNewRequest,
    newRequested,
  } = props;
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1>{mode ? "Tickets Request" : "Pass Request"}</h1>
              <p>{event?.Name}</p>
            </div>
            <div className="close-icon d-none d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="event-inside ">
            <div className="event-content text-center"></div>
          </div>
          <TicketForm
            teams={teams}
            createTicket={createTicket}
            mode={mode}
            event={event}
            loading={loading}
            continueAndAddNewRequest={continueAndAddNewRequest}
            newRequested={newRequested}
          />
        </div>
      </div>
    </div>
  );
};

RequestPasses.propTypes = {};

export default RequestPasses;
