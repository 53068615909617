export const LOGIN_USER = "LOGIN_USER";
export const LOGGED_USER = "LOGGED_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const REQUEST_PASSWORD = "REQUEST_PASSWORD";
export const REQUEST_PASSWORD_ERROR = "REQUEST_PASSWORD_ERROR";
export const REQUESTED_PASSWORD = "REQUEST_PASSWORD_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGED_PASSWORD = "CHANGED_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const SIGNING_USER = "SIGNING_USER";
export const SIGNED_USER = "SIGNED_USER";
export const SIGNING_USER_ERROR = "SIGNING_USER_ERROR";

export const CONFIRMED_EMAIL = "CONFIRMED_EMAIL";
export const CONFIRMED_EMAIL_ERROR = "CONFIRMED_EMAIL_ERROR";
