import initialState from "./initialState";
import { reducer as getOrganizationListReducer } from "./getOrganizationList";
import { reducer as selectOrganizationReducer } from "./selectOrganization";
import { reducer as getCurrentOrganizationReducer } from "./getCurrentOrganization";
import { reducer as createTeamReducer } from "./createTeam";
import { reducer as deleteTeamReducer } from "./deleteTeam";
import { reducer as addAccountReducer } from "./addAccount";
import { reducer as cleanInvitesReducer } from "./cleanInvites";
import { reducer as inviteUsersReducer } from "./inviteUsers";
import { reducer as toggleNotificationsReducer } from "./toggleNotifications";
import { reducer as toggleStaffReducer } from "./toggleStaff";
import { reducer as deleteStaffReducer } from "./deleteStaff";
import { reducer as editTeamReducer } from "./editTeam";
import { reducer as editOrganizationReducer } from "./editOrganization";
import { reducer as editLogoReducer } from "./editLogo";
import { reducer as createOrganizationReducer } from "./createOrganization";
import { reducer as deleteOrganizationReducer } from "./deleteOrganization";

const reducers = [
  getOrganizationListReducer,
  selectOrganizationReducer,
  getCurrentOrganizationReducer,
  createTeamReducer,
  deleteTeamReducer,
  addAccountReducer,
  cleanInvitesReducer,
  inviteUsersReducer,
  toggleNotificationsReducer,
  toggleStaffReducer,
  deleteStaffReducer,
  editTeamReducer,
  editOrganizationReducer,
  editLogoReducer,
  createOrganizationReducer,
  deleteOrganizationReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
