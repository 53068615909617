export const FETCHING_EVENTS = "FETCHING_EVENTS";
export const FETCHING_EVENTS_ERROR = "FETCHING_EVENTS_ERROR";
export const FETCHED_EVENTS = "FETCHED_EVENTS";

export const CREATED_EVENT_SUCCESS = "CREATED_EVENT_SUCCESS";

export const CREATING_GROUPS = "CREATING_GROUPS";
export const CREATED_GROUPS = "CREATED_GROUPS";
export const CREATING_GROUPS_ERROR = "CREATING_GROUPS_ERROR";

export const GETTING_GROUPS = "GETTING_GROUPS";
export const GET_GROUPS = "GET_GROUPS";
export const GETTING_GROUPS_ERROR = "GETTING_GROUPS_ERROR";

export const DELETING_GROUP = "DELETING_GROUP";
export const DELETED_GROUP = "DELETED_GROUP";
export const DELETING_GROUP_ERROR = "DELETING_GROUP_ERROR";

export const UPDATING_GROUPS_EVENTS = "UPDATING_GROUPS_EVENTS";
export const UPDATE_DELETE_GROUPS = "UPDATE_DELETE_GROUPS";

export const DELETING_EVENT = "DELETING_EVENT";
export const DELETED_EVENT = "DELETED_EVENT";
export const DELETING_EVENT_ERROR = "DELETING_EVENT_ERROR";

export const EDITING_CALENDAR_EVENT = "EDITING_CALENDAR_EVENT";
export const EDITED_CALENDAR_EVENT = "EDITED_CALENDAR_EVENT";
export const EDITING_CALENDAR_EVENT_ERROR = "EDITING_CALENDAR_EVENT_ERROR";
