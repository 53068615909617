import React, { useEffect, useState } from "react";
import { Delete, Pencil } from "mdi-material-ui";
import { DropDownComponent } from "../../common";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";

const GroupItem = (props) => {
  let { group, deleteGroup, editGroup } = props;

  const imgUrl = group?.Image
    ? `https://service.plnify.com/files/${group.Image}?n=${Math.random()}`
    : "";

  return (
    <div className="groupItem__root d-flex align-items-center my-1">
      <button
        style={{
          backgroundColor: group.Color === null ? "grey" : `${group.Color}`,
        }}
        disabled
        type="button"
        className="btn-reset color-option mr-2"
      >
        {imgUrl && (
          <img
            src={imgUrl}
            alt="image"
            className="p-0 m-0 border-0 rounded-lg"
            style={{
              width: "85%",
              height: "85%",
              objectFit: "cover",
              objectPosition: "50% 35%",
            }}
          />
        )}
      </button>
      <div className="mr-auto">
        <p className="text-dark">{group.Name && group.Name}</p>
        <p className="text-dark text-underline" style={{ fontSize: 10 }}>
          <strong>
            <FormattedMessage id="events" defaultMessage="Events" />:
          </strong>{" "}
          {group?.Events?.length}
        </p>
      </div>
      <DropDownComponent
        data={[
          {
            name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
            trigger: () => editGroup(group),
            border: true,
          },
          {
            delete: true,
            name: <FormattedMessage id="delete" defaultMessage="Delete" />,
            trigger: () => deleteGroup(group),
          },
        ]}
      />
    </div>
  );
};

export default GroupItem;
