import * as CONSTANT from "./constants";

export function toggleSidebar() {
  return async (dispatch) => {
    dispatch({
      type: CONSTANT.TOGGLE_SIDEBAR,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: !state.sidebarExpanded,
      };
    }
    case CONSTANT.EXPANDED_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: true,
      };
    }
    case CONSTANT.SLIM_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: false,
      };
    }
    default:
      return state;
  }
}
