import * as CONSTANT from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

const sub_url = "Organizations/GetOrganizations?AccountGUID=";

async function getOrganizationsLocal() {
  const localOrgs = await db.table("organizations").toArray();
  return localOrgs;
}

export function getOrganizationList(accountGuid, onlyLocal) {
  return async (dispatch) => {
    dispatch({
      type: CONSTANT.GETTING_ORGANIZATIONS,
    });
    try {
      let localOrgs = await getOrganizationsLocal();

      dispatch({
        type: CONSTANT.GET_ORGANIZATIONS,
        data: localOrgs,
      });
      if (onlyLocal === true) {
        return;
      }
      const request = await apiService.get(
        `${sub_url}${accountGuid}&source=web`
      );
      const remote_orgs = request.data.response
        .filter((org) => org.Accepted)
        .filter((org) => org.Organization.Deleted === null)
        .map((org) => org.Organization);

      if (remote_orgs !== null) {
        await db.table("organizations").bulkPut(remote_orgs);
        localOrgs = await getOrganizationsLocal();
      }

      dispatch({
        type: CONSTANT.GET_ORGANIZATIONS,
        data: localOrgs,
      });
    } catch (error) {
      if (!error.status) {
        const localOrgs = await getOrganizationsLocal();
        dispatch({
          type: CONSTANT.GET_ORGANIZATIONS,
          data: localOrgs,
        });
      }
      dispatch({
        type: CONSTANT.GETTING_ORGANIZATIONS_ERROR,
        error: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.GETTING_ORGANIZATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case CONSTANT.GET_ORGANIZATIONS: {
      const organizationsList = action.data;
      return {
        ...state,
        loading: false,
        organizationsList: organizationsList,
      };
    }
    case CONSTANT.GETTING_CURRENT_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
        organizationsList: [],
      };
    }
    default:
      return state;
  }
}
