import { IMAGES } from "./images";
import { useIntl, FormattedMessage } from "react-intl";
export const navRoutes = [
  {
    name: <FormattedMessage id="calendar" defaultMessage="Calendar" />,
    destination: "/calendar",
    key: "27c393af-cc31-4f65-9c42-9302c62a4ef9",
    id: "calendar",
    icon: IMAGES.CALENDER,
    fontAwesomeIcon: "far fa-calendar-alt",
  },
  {
    name: <FormattedMessage id="team" defaultMessage="Team" />,
    destination: "/users",
    key: "5f236e99-0431-49f2-b540-cefd5f8f54f1",
    id: "teams",
    icon: IMAGES.ORGANIZATION,
    fontAwesomeIcon: "fas fa-users",
  },
  {
    name: <FormattedMessage id="guestlist" defaultMessage="Guest list" />,
    destination: "/guestList",
    key: "3ddab645-7aad-4d85-a733-2cbcb732ba54",
    id: "guestList",
    icon: IMAGES.TICKET_PASSES,

    fontAwesomeIcon: "fa fa-ticket",
  },

  {
    name: (
      <FormattedMessage id="notifications" defaultMessage="Notifications" />
    ),
    destination: "NOTIFICATIONS",
    icon: IMAGES.NOTIFICATION,
    id: "notifications",
    key: "notifications",
    type: "ACTION",
    //mode : "beta",
    fontAwesomeIcon: "far fa-bell",
  },

  {
    name: <FormattedMessage id="to-Do" defaultMessage="To-Do" />,
    destination: "/todo",
    key: "18c71cb5-5d83-4579-bf0c-83a65597f319",
    id: "toDo",
    icon: IMAGES.TODO_LIST,
    //mode : "beta",
    fontAwesomeIcon: "far fa-check-square",
  },
  {
    name: <FormattedMessage id="files" defaultMessage="Files" />,
    destination: "/files",
    key: "files_id",
    id: "files",
    icon: IMAGES.TODO_LIST,
    mode: "Beta",
    fontAwesomeIcon: "far fa-folder",
  },
];
