import React, { useEffect, useRef, useState } from "react";
import { IMAGES, GROUPS_COLORS } from "../../constant";
import { Scrollbars } from "react-custom-scrollbars";
import { EventList, SimpleLoader } from "..";
import SelectableEvent from "./selectableEvent";
import { sortArray } from "../../common/helpers";
import { useIntl, FormattedMessage } from "react-intl";
import DragAndDrop from "../common/DragAndDrop";
import { GroupImage } from "./groups/GroupImage";
import useEventFilters from "../../hooks/useEventFilters";
import EventFilter from "../common/EventFilter";

const CreateGroup = (props) => {
  let {
    toggle,
    eventsList,
    eventsSelected,
    toggleEvent,
    setColorGroup,
    setGroupName,
    setGroupImage,
    groupImage,
    groupColor,
    createGroup,
    creatingGroup,
  } = props;

  let containerScroller = useRef();
  // const scrollNearTo = () => {
  //   let element = containerScroller.current;

  //   if (element === null || element === undefined) {
  //     return;
  //   }
  //   let elementEstimatedHeight = 58;
  //   //find first index of dates in the array of events
  //   let theIndex = findWithAttr(eventsList);
  //   if (theIndex > 0) {
  //     //check if we have more than 10 events ahead
  //     if (eventsList.length - theIndex > 10) {
  //       element.scrollTop(elementEstimatedHeight * (theIndex + 10));
  //     } else if (theIndex - 5 > 0) {
  //       //check if we have 10 events below so we can try to center
  //       element.scrollTop(elementEstimatedHeight * (theIndex + 5));
  //     } else {
  //       element.scrollTop(elementEstimatedHeight * theIndex);
  //     }
  //   }
  // };
  // let findWithAttr = (array) => {
  //   let today = new Date();

  //   for (var i = 0; i < array.length; i += 1) {
  //     var record = array[i];
  //     var parsedDate = new Date(record.Date);
  //     if (
  //       parsedDate.getFullYear() === today.getFullYear() &&
  //       parsedDate.getMonth() == today.getMonth()
  //     ) {
  //       return i;
  //     }
  //   }
  //   return -1;
  // };

  // useEffect(() => {
  //   scrollNearTo();
  // }, []);

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  let debounceTimer;
  const debouncedHandleColorChange = ({ target }) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setColorGroup(target.value);
    }, 500);
  };

  const handleColorChange = ({ target }) => {
    debouncedHandleColorChange({ target });
  };

  const filterEvents = eventsList?.filter(
    (event) => event.EventGroupGUID === null
  );

  const {
    eventGroups,
    eventToRender,
    eventFilterState,
    handleDateChange,
    handleFilterByGroup,
    handleFilterFrom,
    handleFilterTo,
    handleSearch,
  } = useEventFilters({ events: filterEvents });

  return (
    <div
      className={"organization-transparent"}
      onDragEnterCapture={handleDragIn}
    >
      <div className="event-page ">
        <div className="event-expennd-section extendEventScreen">
          <div className="text-head">
            <div className="text-left">
              <h1>
                <FormattedMessage
                  id="createNewGroup"
                  defaultMessage="Create a new group"
                />
              </h1>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside h-auto">
            <div className="event-content">
              <div className="row px-0">
                <GroupImage
                  handleDragOut={handleDragOut}
                  onChangeDrop={setGroupImage}
                  groupImage={groupImage}
                  groupColor={groupColor}
                  setGroupImage={setGroupImage}
                  showDrop={showDrop}
                />
                <div className="col-md-12 px-0">
                  <div className="form-group">
                    <label htmlFor={"groupName"}>
                      <FormattedMessage
                        id="groupName"
                        defaultMessage="Group Name"
                      />
                    </label>
                    <input
                      id="groupName"
                      onChange={(e) => setGroupName(e.target.value)}
                      className="form-control"
                      placeholder="Insert your Group's Name"
                    />
                  </div>
                </div>
                <div className="col-md-12 px-0">
                  <div className="form-group mb-1">
                    <label>
                      <FormattedMessage
                        id="selectColor"
                        defaultMessage="Select a color"
                      />
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    {GROUPS_COLORS.map((color) => (
                      <button
                        onClick={() => setColorGroup(color)}
                        key={color}
                        style={{
                          backgroundColor: `${color}`,
                          border:
                            groupColor === color ? "3px solid black" : "none",
                        }}
                        type="button"
                        className="clean-button btn-reset color-option pink"
                      ></button>
                    ))}
                    <input
                      type="color"
                      id="color-picker"
                      className="clean-button btn-reset color-option"
                      defaultValue={"#ffffff"}
                      style={{
                        //backgroundColor: `${groupColor}`,
                        background:
                          "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
                        border:
                          groupColor === "Picker" ? "3px solid black" : "none",
                      }}
                      onChange={handleColorChange}
                    />
                    <button
                      onClick={() => setColorGroup(null)}
                      key="clean"
                      style={{
                        backgroundColor: `#ccc`,
                        border: !groupColor ? "3px solid black" : "none",
                      }}
                      title="Remove color"
                      type="button"
                      className="bg-none clean-button btn-reset color-option text-danger"
                    ></button>
                  </div>
                </div>

                <div className="form-group mt-3 w-100">
                  <label>Select event to include in this group</label>
                  <EventFilter
                    events={filterEvents}
                    eventGroups={eventGroups}
                    eventFilterState={eventFilterState}
                    handleDateChange={handleDateChange}
                    handleFilterByGroup={handleFilterByGroup}
                    handleFilterFrom={handleFilterFrom}
                    handleFilterTo={handleFilterTo}
                    handleSearch={handleSearch}
                  />
                </div>
                <Scrollbars
                  // ref={containerScroller}
                  style={{
                    height: "calc(100vh - 550px)",
                    marginTop: 18,
                    color: "#000",
                  }}
                >
                  <div className="row">
                    {eventToRender?.length > 0 ? (
                      eventToRender?.map((event) => (
                        <div className="col-12 px-0">
                          <SelectableEvent
                            key={event.GUID}
                            event={event}
                            eventsSelected={eventsSelected}
                            toggleEvent={toggleEvent}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="alert alert-info w-100 text-center">No Events Found</div>
                    )}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>

          <div className="event-inside h-auto">
            <div className="event-content">
              <div className="row px-0">
                <div className="col-md-12 addGround__cta px-0 text-center">
                  {creatingGroup ? (
                    <SimpleLoader loading={creatingGroup} />
                  ) : (
                    <button
                      onClick={() => createGroup()}
                      type="button"
                      className="btn primary"
                    >
                      <FormattedMessage id="done" defaultMessage="Done" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGroup;
