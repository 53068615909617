import * as constant from "./constants";
import db from "../../../common/db";
import moment from "moment";

export function removeNotes(note) {
  return async (dispatch) => {
    let record = await db.activeNotes.where("GUID").equals(note.GUID).first();
    console.log("record to remove", record, note);
    record.Deleted =moment().format();
    await db.table("activeNotes").put(record);


      let notes = (await db.activeNotes.toArray()).filter(x=>x.Deleted === null);
      dispatch({
        type: constant.REMOVE_NOTE,
        data: notes,
      });
    
  
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.REMOVE_NOTE: {
      const notes = action.data;
      return {
        ...state,
        notes,
      };
    }
    default:
      return state;
  }
}
