import * as constant from "./constants";
import api from "../../../common/api";
import uuid from "uuid";

export function editNote(note, success) {
  const url = `/Note/AddEditBatch`;
  return async (dispatch) => {};
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
