import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function editList(list, success) {
  const urlForTodos = "ToDo/AddEdit";

  return async (dispatch) => {
    try {
      dispatch({ type: constants.EDITING_LIST });
      const editedList = {
        ...list,
        TodoTasks: null,
        TodoAssignees: null,
        Event: null,
      };
      await db.table("todos").put(list);
      const request = await apiService.post(urlForTodos, editedList);
      const listCreated = request.data.Response;
      await db.table("todos").put(list);
      success();
      dispatch({ type: constants.EDITED_LIST, data: list });
    } catch (error) {
      dispatch({
        type: constants.EDITING_LIST_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.EDITING_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.EDITED_LIST: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.EDITING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
