import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import { useIntl, FormattedMessage } from "react-intl";

const LogoCrop = ({
  src,
  onComplete,
  onImageLoaded,
  croppedImageUrl,
  sendImage,
  loading,
  onCreation,
  close,
}) => {
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  return (
    <div>
      <ReactCrop
        src={src}
        crop={crop}
        onImageLoaded={onImageLoaded}
        onChange={(newCrop) => {
          setCrop(newCrop);
          onComplete(crop);
        }}
      />
      <div className="d-flex flex-column align-items-center ">
        <p className="mt-2">Preview Icon: </p>
        {croppedImageUrl && (
          <img src={croppedImageUrl} width="50" style={{ borderRadius: 8 }} />
        )}

        <button
          disabled={loading}
          onClick={onCreation ? () => close() : () => sendImage()}
          type="button"
          className="mt-2 btn btn-primary"
        >
          {loading ? (
            "Updating image..."
          ) : (
            <FormattedMessage id="done" defaultMessage="Done" />
          )}
        </button>
      </div>
    </div>
  );
};

export default LogoCrop;
