import {
  CHANGED_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
} from "./constants";
import api from "../../../common/api";
const sub_url = "user/PasswordChangeWithToken";

export function changePassword(data, token, callback) {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD,
    });

    try {
      await api.get(`${sub_url}?Token=${token}&NewPassword=${data}`);
      dispatch({
        type: CHANGED_PASSWORD,
      });
      callback();
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_ERROR,
        error: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CHANGE_PASSWORD: {
      return {
        ...state,
        logging: true,
        loading: true,
        requested: false,
      };
    }
    case CHANGED_PASSWORD: {
      return {
        ...state,
        logging: false,
        loading: false,
        requested: true,
      };
    }
    default:
      return state;
  }
}
