import React from "react";
import { IMAGES } from "../../constant";
const OFF = "off";

export const TextInput = (props)=>{
  const {
    header,
    type,
    id,
    value,
    maxLength,
    errorMessage,
    togglePassword,
    placeholder,
    showPassword,
    field,
    form,
    filled,
    onChange,
  } = props;
  const showEyeIcon = id === "password" ? true : id === "confirmPassword" && true;

  return (
    <div className>
      <label hrmlfor={type}>{header}</label>
      {id === "phoneNumber" ? (
        <span className="contact-number">
          <input
            type="text"
            className="form-control contact"
            value="+1"
            disabled
            autoComplete="off"
          />
          <span className="line"></span>
          <span className="phone-no">
            <input
              onChange={onChange}
              type={type}
              id={id}
              autoComplete={OFF}
              value={value}
              maxLength={maxLength}
              className="form-control"
              placeholder={placeholder}
              {...field}
              {...form}
            />
          </span>
        </span>
      ) : (
        <input
          onChange={onChange}
          type={type}
          id={id}
          autoComplete={OFF}
          maxLength={maxLength}
          className={(filled ? "filled" : "") + " form-control"}
          placeholder={placeholder}
          autoCapitalize="on"
          data-toggle="tooltip"
          data-placement="right"
          {...field}
          {...form}
        />
      )}
      <div className="invalid-feedback">{errorMessage}</div>
      {showEyeIcon && (
        <div
          className="forgot-password d-none"
          onClick={() => togglePassword(id)}
        >
          <img
            src={showPassword ? IMAGES.EYE_OPEN : IMAGES.EYE_CLOSE}
            width="25"
            height="25"
            alt="eye"
          />
        </div>
      )}
    </div>
  );
}