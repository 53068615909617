import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function deleteGround(ground, success) {
  const url = "/Ground/AddEdit";

  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_GROUND,
    });
    try {
      await db.table("grounds").delete(ground.GUID);
      dispatch({
        type: constant.DELETED_GROUND_SUCCESS,
        data: ground,
      });
      await api.post(url, {
        ...ground,
        Deleted: new Date(),
        GroundStaffs: null,
        GroundStaff: null,
        GroundVehicles: null,
      });
      success();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.DELETING_GROUND_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_GROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_GROUND_SUCCESS: {
      return {
        ...state,
        grounds: state.grounds.filter((a) => a.GUID !== action.data.GUID),
        loading: false,
      };
    }
    case constant.DELETING_GROUND_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
