import React, { useState } from "react";

const NewFileComponent = ({
  color,
  icon,
  title,
  description,
  iconBg,
  cardClass,
  isSelected,
  action,
  handleEdit,
  handleDelete,
  hasBtns,
  type,
  accept,
  hasBash,
}) => {
  const ref = React.useRef();
  const handleFileUpload = () => {
    ref.current.click();
  };

  return (
    <div
      onClick={type === "file" ? handleFileUpload : action}
      className={`position-relative new-document-card border rounded-lg p-3 ${
        cardClass ?? ""
      } ${isSelected ? "template-card-selected" : ""}`}
    >
      {type === "file" && (
        <input
          ref={ref}
          type="file"
          accept={accept ?? "*"}
          className="d-none"
          onChange={(e) => {
            action(e);
          }}
        />
      )}
      {hasBash && (
        <div
          className="position-absolute bg-primary text-white p-1 rounded-lg"
          style={{
            top: "5px",
            left: "5px",
            zIndex: "150",
            fontSize: "0.7rem",
          }}
        >
          Plnify
        </div>
      )}
      {hasBtns && (
        <div className="template-card-btn-container position-absolute">
          <button
            className="btn btn-secondary btn-sm border-0"
            title="Edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit();
            }}
          >
            <i class="fa fa-solid fa-pen"></i>{" "}
          </button>
          <button
            title="Delete"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            className="btn btn-danger btn-sm border-0"
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
      <div
        className={`text-black-50 card w-100 ${
          iconBg ?? "bg-light"
        }  border-0 p-0 m-0 d-flex justify-content-center align-items-center`}
        style={{
          width: "100%",
          height: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <i
            class={` fas fa-solid fa-3x ${icon}`}
            style={{
              color: color,
              transition: "all 0.3s",
            }}
          ></i>
        </div>
      </div>
      <div className="text-black-50">
        <span
          className="font-weight-bold line-clamp-1 my-1"
          style={{
            color: color,
            fontSize: "0.9rem",
            lineHeight: "1",
          }}
          title={title}
        >
          {title}
        </span>
        <p
          style={{
            fontSize: "0.8rem",
            lineHeight: "1",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default NewFileComponent;
