import React, { useState } from "react";
import { IMAGES } from "../../constant";
import { cleanDelete } from "../../utils";
import {
  DropDownComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import { BellOff } from "mdi-material-ui";
import { useIntl, FormattedMessage } from "react-intl";
const TeamItem = ({
  team,
  index,
  currentTeam,
  setCurrentTeam,
  deleteTeam,
  toggleNotifications,
  editRole,
  toggleModalTeam,
  toggleExportModal,
  handleDragDrop,
  handleInviteUserBy,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleDragLeave(e);
    handleDragDrop(team);
  };

  return (
    <div
      onClick={() => setCurrentTeam(index)}
      className={`teamItem py-2 ${index === currentTeam ? "active" : ""} ${
        isDragOver ? "border border-info p-3 text-center" : ""
      }`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {isDragOver ? (
        `Drop user here to move to ${team.Name}`
      ) : (
        <>
          <div className="teamItem__details">
            <h6>{team?.Name}</h6>
            <div className="teamItem__users subTitle text-truncate">
              {team?.Staffs !== undefined &&
              cleanDelete(team?.Staffs).length > 0 ? (
                <span className="">
                  <FormattedMessage
                    id="totalUsers"
                    defaultMessage="Total members"
                  />
                  :&nbsp;
                  {team?.Staffs && cleanDelete(team?.Staffs).length}
                </span>
              ) : (
                <span className="">
                  <FormattedMessage
                    id="totalUsers"
                    defaultMessage="Total members"
                  />
                  :&nbsp; 0
                </span>
              )}
              {team?.Pending !== undefined &&
              cleanDelete(team?.Pending).length > 0 ? (
                <span>
                  | <FormattedMessage id="pending" defaultMessage="Pending" />:{" "}
                  {team?.Pending?.filter((s) => !s.Deleted).length}
                </span>
              ) : null}
            </div>
          </div>
          <div className="teamItem__actions">
            {team?.Name !== "Non-App User" ? (
              <button
                onClick={() =>
                  toggleNotifications(team, () =>
                    team?.AllowToReceiveNotifications
                      ? ToastNotificationSuccess(
                          1,
                          `Successfully ${"enabled"} notifications`
                        )
                      : ToastNotificationError(
                          1,
                          `Successfully ${"disabled"} notifications`
                        )
                  )
                }
                className={`btn-reset teamItem__notifications ${
                  team?.AllowToReceiveNotifications ? "active" : ""
                }`}
              >
                {/* <BellOff className="img-responsive" /> */}
                <i
                  className={`fa fa-bell ${
                    team?.AllowToReceiveNotifications
                      ? "text-primary"
                      : "text-secondary"
                  }`}
                  style={{ fontSize: "0.9rem" }}
                ></i>
              </button>
            ) : null}

            <button className="teamItem__more btn-reset">
              {team?.Name === "Non-App User" || team?.Name === "Admin" ? (
                <DropDownComponent
                  data={[
                    {
                      name: (
                        <FormattedMessage
                          id="editTeam"
                          defaultMessage="Edit Team"
                        />
                      ),
                      border: true,

                      trigger: () => {
                        editRole(team);
                        toggleModalTeam();
                      },
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="inviteMember"
                          defaultMessage="Invite Members"
                        />
                      ),
                      border: true,
                      trigger: () => handleInviteUserBy(),
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="visibleEvents"
                          defaultMessage="Visible Events"
                        />
                      ),
                      trigger: () => toggleExportModal(),
                    },
                  ]}
                />
              ) : (
                <DropDownComponent
                  data={[
                    {
                      name: (
                        <FormattedMessage
                          id="editTeam"
                          defaultMessage="Edit Team"
                        />
                      ),
                      border: true,

                      trigger: () => {
                        editRole(team);
                        toggleModalTeam();
                      },
                    },
                    {
                      name: `${
                        team?.AllowToReceiveNotifications
                          ? "Disabled"
                          : "Enabled"
                      } Notifications`,
                      border: true,
                      trigger: () =>
                        toggleNotifications(team, () =>
                          ToastNotificationSuccess(
                            1,
                            `Successfully ${
                              team?.AllowToReceiveNotifications
                                ? "enabled"
                                : "disabled"
                            } notifications`
                          )
                        ),
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="inviteMember"
                          defaultMessage="Invite Members"
                        />
                      ),
                      border: true,
                      trigger: () => handleInviteUserBy(),
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="visibleEvents"
                          defaultMessage="Visible Events"
                        />
                      ),
                      border: true,

                      trigger: () => toggleExportModal(),
                    },
                    {
                      name: "Delete Team",
                      delete: true,
                      trigger: () => deleteTeam(team),
                    },
                  ]}
                />
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamItem;
