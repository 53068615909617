import initialState from "./initialState";
import { reducer as getStripeCustomerIDReducer } from "./getStripeCustomerID";
import { reducer as changePasswordReducer } from "./changePassword";
import { reducer as editBasicInfoReducer } from "./editBasicInfo";
import { reducer as updateSocialMediaReducer } from "./updateSocialMedia";

const reducers = [
  getStripeCustomerIDReducer,
  changePasswordReducer,
  editBasicInfoReducer,
  updateSocialMediaReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
