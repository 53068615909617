import React, { useEffect, useState } from "react";

import { IMAGES } from "../../constant";
import { ToastNotificationSuccess, SimpleLoader } from "../common";
import { Scrollbars } from "react-custom-scrollbars";

import api from "../../common/api";
import uuid from 'uuid';
import moment from "moment";
import db from "../../common/db";
import {useIntl, FormattedMessage} from 'react-intl'

import { organizationUsers, sortArray } from '../../common/helpers';
import { getOrganization } from '../../utils';

const GuestlistSettings = props => {

  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOtherTeams = async () => {
    let theOrganization = getOrganization();
    let users = await organizationUsers(theOrganization.GUID);
    let obj = [];
    users.forEach(team => {


      obj.push({
        //RoleGUID: roleToEdit.GUID,
        GUID: team.GUID,

        Deleted: null,
        Name: team.Name
      })
    });
    obj = obj.sort((a, b) => a.Name.localeCompare(b.Name))
    setTeams(obj)
  }


  const handleSubmit = async (selected) => {
    try {
      let query = "UserGUID=" + props.selectedUser.GUID + "&RoleGUID=" + selected.GUID

      //Add the can view
      setIsLoading(!isLoading);
      const request = await api.get("/User/MoveToGroup?" + query);
      setIsLoading(!isLoading);
      console.log("Response from server", request);
      if (request.data.Status === "ok") {
        //Saving locally 

        props.toggle(props.selectedUser, true);
      }
    } catch (ex) {
      console.error(ex)
    }
  }


  useEffect(() => {
    //  setTeams(teams);
    loadOtherTeams();


  }, []);
  return (
    <div className={""}>
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <div className="d-flex align-items-center">
                <h1>Settings</h1>


              </div>

            </div>
            <div className="d-none close-icon d-none" onClick={() => props.toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>

          <div className="event-inside px-1">
            <div className="event-content text-dark">
              <Scrollbars style={{ width: "100%", height: "calc(34vh)" }}>
                <div className="row py-2">
                  <div className="col-10">
                    Enable tickets request
                  </div>
                  <div className="col text-center">
                    {props.AllowUsersToRequestTickets ? (
                      <i className="fas fa-check-square"></i>
                    ) : (
                      <i className="far fa-square uncheck"></i>
                    )}
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div className="button-section py-3 d-flex align-items-center justify-content-center">
              {isLoading ? (
                <SimpleLoader loading={isLoading} />
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mx-2" onClick={() => handleSubmit()}
                >
    <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                </button>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestlistSettings;
