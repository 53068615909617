import api from "../../../common/api";
import * as constant from "./constants";

export function deleteStaff(staff) {
  const url = "Staff/Remove?GUID";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_STAFF,
      });
      const request = await api.get(`${url}=${staff.GUID}`);
      console.log(request.data);
      dispatch({
        type: constant.DELETED_STAFF,
        data: staff,
      });
    } catch (error) {
      dispatch({
        type: constant.DELETING_STAFF_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_STAFF: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_STAFF: {
      return {
        ...state,
        loading: false,
        teams: state.teams.map((team) => {
          if (team.GUID === action.data.RoleGUID) {
            const newStaffs = team.Staffs.filter(
              (s) => s.GUID !== action.data.GUID
            );
            team.Staffs = newStaffs;
            return team;
          }

          return team;
        }),
      };
    }
    case constant.DELETING_STAFF_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
