import React, { useState } from "react";
import MarkerModal from "../../../components/event/MarkerModal";
import { useDispatch, useSelector } from "react-redux";
import { eventDetail, getEvents } from "../redux/actions";
import { getBasicInfo, getOrganization } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { CogSyncOutline } from "mdi-material-ui";
import { IMAGES } from "../../../constant";
import { Loading } from "../../../components/common/loading";
import { Navigate } from "react-big-calendar";

export const DirectionPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentEvent = location.pathname.replace("/direction/", "");

  const { event, loading } = useSelector((state) => state.event);

  useState(() => {
    dispatch(eventDetail(currentEvent));
  }, []);

  let hotelPlaces =
    [] ||
    event?.hotels?.map((h) => ({
      Name: h.Name,
      Address: h.Address,
      Latitude: h.Latitude,
      Longitude: h.Longitude,
    }));
  let venuePlace = {
    Name: event?.Venue && event?.Venue?.Name,
    Address: event?.Venue && event?.Venue?.Address,
    Latitude: event?.Venue && event?.Venue?.Latitude,
    Longitude: event?.Venue && event?.Venue?.Longitude,
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ height: "100vh", width: "100vw" }}
    >
      {loading ? (
        <div className="w-100 h-100">
          <Loading />
        </div>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <MarkerModal
            customClass={""}
            event={event}
            hotelPlaces={hotelPlaces}
            venuePlace={venuePlace}
          />
        </div>
      )}
    </div>
  );
};
