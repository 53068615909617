import React, { useEffect } from "react";
import { ToastNotificationSuccess } from "../../components";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../../components/form/formField";
import { SimpleLoader } from "../../components/common";
import { addContactSchema } from "../../utils/validationsSchemas";
import { useIntl, FormattedMessage } from "react-intl";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

const AddContact = (props) => {
  let {
    addContact,
    toggle,
    event,
    loading,
    sideBarItemData,
    handleSidebarItem,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    document.getElementById("firstName").focus();
  }, []);
  const handleSumit = (values, event) => {
    if (sideBarItemData) {
      dispatch(
        addContact(values, event.GUID, () => {
          if (sideBarItemData) {
            ToastNotificationSuccess(1, "Contact successfully updated");
          }
          toggle();
          handleSidebarItem("CONTACTS", values);
        })
      );
    } else {
      dispatch(
        addContact(values, event, () => {
          ToastNotificationSuccess(1, "Contact successfull created");
          toggle();
        })
      );
      handleSidebarItem("CONTACTS", values);
    }
  };
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100 d-flex justify-content-between">
              {sideBarItemData ? (
                <h1>
                  <FormattedMessage id="Edit" defaultMessage="Edit" />
                </h1>
              ) : (
                <h1>
                  {" "}
                  <FormattedMessage
                    id="addContact"
                    defaultMessage="Add Contact"
                  />
                </h1>
              )}
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </button>
            </div>
          </div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            validationSchema={addContactSchema}
            initialValues={
              sideBarItemData
                ? sideBarItemData
                : {
                    Name: "",
                    LastName: "",
                    OfficePhone: "",
                    MobilePhone: "",
                    Email: "",
                    Position: "",
                  }
            }
            onSubmit={(values) => handleSumit(values, event)}
          >
            {(props) => (
              <div className="event-inside">
                <Form autoComplete="off">
                  <div className="event-content">
                    <div className="row ">
                      <FormField
                        additionalClasses="px-0 pr-2 my-2"
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="firstName.signUp"
                            defaultMessage="First Name"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="Name"
                          id="firstName"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Name"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="lastName.signUp"
                            defaultMessage="Last Name"
                          />
                        }
                        additionalClasses="px-0 pl-2 my-2"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="LastName"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="LastName"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={true}
                        label={
                          <FormattedMessage
                            id="position"
                            defaultMessage="Position"
                          />
                        }
                        additionalClasses="px-0 pr-2"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="Position"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Position"
                        />
                      </FormField>
                    </div>
                    <div className="row">
                      <FormField
                        additionalClasses="px-0 pr-2"
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="officePhone"
                            defaultMessage="Office Phone"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="OfficePhone"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="OfficePhone"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        additionalClasses="px-0 pl-2"
                        fullWidth={false}
                        label="Ext."
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="OfficePhoneExtension"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="OfficePhoneExtension"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="mobile"
                            defaultMessage="Mobile Phone"
                          />
                        }
                        additionalClasses="px-0 pr-2"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="MobilePhone"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="MobilePhone"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="emailAddress"
                            defaultMessage="Email Address"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="Email"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Email"
                        />
                      </FormField>
                    </div>

                    <div className="text-center my-3 d-flex align-items-center">
                      {loading ? (
                        <SimpleLoader loading={loading} />
                      ) : (
                        <>
                          {!sideBarItemData && (
                            <button
                              disabled={loading || !props.isValid}
                              onClick={() => {
                                dispatch(
                                  addContact(props.values, event, () => {
                                    ToastNotificationSuccess(
                                      1,
                                      "Contact successfully created"
                                    );
                                  })
                                );
                                props.resetForm();
                                //props.setFieldValue("Name", "", true);
                                //  props.setFieldValue("LastName", "", true);
                              }}
                              type="button"
                              className="mr-2 btn secondary"
                            >
                              <FormattedMessage
                                id="doneContinue"
                                defaultMessage="Done & Continue"
                              />
                            </button>
                          )}
                          <button
                            disabled={loading || !props.isValid}
                            type="submit"
                            className="btn primary "
                          >
                            <FormattedMessage id="done" defaultMessage="Done" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
