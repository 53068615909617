import React, { Component } from "react";
import Calendar from "./calendar";
import { connect } from "react-redux";
import history from "../../../components/common/history";
import {
  ModalComponent,
  ToastNotificationSuccess,
} from "../../../components/common";
import { bindActionCreators } from "redux";
import {
  getOrganization,
  getBasicInfo,
  logoutUser,
  dynamicFilter,
} from "../../../utils";
import db from "../../../common/db";
import uuid from "uuid";
import * as actions from "../redux/actions";
import * as organizationActions from "../../organization/redux/actions";
import * as eventActions from "../../event/redux/actions";
import * as commonActions from "../../common/redux/actions";
import * as todoActions from "../../todoPage/redux/actions";
import IdleTimer from "react-idle-timer";
import moment from "moment";

import EmailModal from "../../../components/hotel/emailModal";
import AddEventComponent from "../../event/addEvent/addEventComponent";
import StartingWeekModal from "../../../components/calendar/StartingWeekModal";
import FilterModal from "../../../components/calendar/FilterModal";
import Navbar from "../../../components/common/navbar";
import EventGroupModal from "../../../components/event/EventGroupModal";
import Swal from "sweetalert2";
import EventVisibility from "../../../components/event/EventVisibility";
import { IMAGES } from "../../../constant/images";
import ImportModal from "../../../components/onboarding/ImportModal";

import ical from "cal-parser";
import EventHistory from "../../../components/event/EventHistory";
import { addMultipleEventsHelper } from "../../../utils/addMultipleEventsHelper";
import AddMultipleEvent from "../../event/addEvent/addMultipleEvent";
import { addNotification } from "../../../components/notifications/addNotification";
import { PushNotifications } from "../../../components/notifications/PushNotifications";
import { MoveEvent } from "../../event/moveEvent/moveEvent";
import { SideModal } from "../../event/moveEvent/SideModal";
import { CustomNotification } from "../../../components/common/CustomNotification";
import { useCustomNotification } from "../../../hooks/useCustomNotification";
import { handleCloseEmail } from "../../../utils/closeMailModal";
import { addOneYearToDate } from "../../../utils/addOneYearToDate";
import { getStartAndEndOfMonth } from "../../../utils/getStartAndEndOfMonth";
import { apiService } from "../../../common/api";

class CalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      filtersOnEvent: null,
      filtersOnEventStatus: null,
      filtersOnEventGroups: null,
      filtersOnEventFrom: null,
      filtersOnEventTo: null,
      selectDate: new Date(),
      selectedDate: new Date(),
      eventsSelected: [],
      groupName: null,
      groupColor: null,
      groupImage: null,
      groupImageTarget: null,
      groupImageBlob: null,
      pageNumber: 1,
      showList: false,
      pageSize: 10,
      editEvent: false,
      currentEditIndex: null,
      currentOrgId: null,
      importEventModal: false,
      emailModal: false,
      groupModal: false,
      addEventModal: false,
      startingDayModal: false,
      filterModal: false,
      createGroupModal: false,
      currentDate: new Date(),
      selectedEvent: null,
      selectedEventVisibleBy: [],
      visibleByModal: false,
      exportMailModal: false,
      eventToExport: null,
      showEventHistory: false,
      displayMode: "calendar",
      loading: false,
      addMultiple: false,
      multipleEvents: [],
      showMoveEvent: false,
      exportType: "email",
    };
  }

  componentDidMount() {
    this.checkIsAdmin().then((res) => {
      if (!res) {
        history.push("/selectOrganization");
        return;
      }
    });
    this.settingDefaultView();
    let theOrg = getOrganization();
    if (theOrg === null) {
      history.push("/selectOrganization");
      return;
    }
    const {
      commonActions: { setActiveNotes },
      actions: { getEvents, getGroups, addMutipleEvents },
      organizationActions: { getOrganizationList, getCurrentOrganization },
      todoActions: { getListsOfTodo },
    } = this.props;

    this.getCurrentCalendarDate().then((date) => {
      const { endOfMonth, startOfMonth } = getStartAndEndOfMonth(date);

      const currentOrganizationGUID = getOrganization().GUID;
      const basicInfoGUID = getBasicInfo().GUID;
      getGroups(currentOrganizationGUID);

      console.log({ startOfMonth, endOfMonth });

      getEvents(
        currentOrganizationGUID,
        basicInfoGUID,
        startOfMonth.toDateString(),
        endOfMonth.toDateString()
      );
      getOrganizationList(basicInfoGUID);
      getCurrentOrganization(currentOrganizationGUID, basicInfoGUID);
      getListsOfTodo(null, basicInfoGUID, false);
      setActiveNotes();
      this.getCurrentDayOnCalendar();
      localStorage.removeItem("scrollpos");
    });
  }

  checkIsAdmin = async () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const currentOrganizationGUID = getOrganization().GUID;
    const response = await apiService.get(
      `Organizations/IsAdmin?AccountGUID=${basicInfoGUID}&OrganizationGUID=${currentOrganizationGUID}`
    );
    return response.data.Response;
  };

  settingDefaultView = async () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const calendarMode = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();
    this.setState({
      showList: calendarMode ? calendarMode.calendarView : false,
      displayMode: "calendar",
    });
  };

  getCurrentCalendarDate = async () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const calendarMode = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();

    return calendarMode
      ? moment(calendarMode.currentDayOnCalendar).toDate()
      : new Date();
  };

  getCurrentDayOnCalendar = async () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const calendarMode = await db
      .table("appSettings")
      .where({ AccountGUID: basicInfoGUID })
      .first();

    this.setState({
      currentDate: calendarMode
        ? moment(calendarMode.currentDayOnCalendar).toDate()
        : new Date(),
    });
  };

  toggleAddEvent = (e) => {
    const selectedDate =
      e && e.start ? moment(e.start).toDate() : moment().toDate();
    this.setState({
      selectedDate,
      addEventModal: !this.state.addEventModal,
    });
  };

  toggleMultiple = () => {
    this.setState({
      addMultiple: !this.state.addMultiple,
    });
  };

  logout = () => {
    history.push("/");
    logoutUser();
  };

  toggleStartingDay = () => {
    this.setState({ startingDayModal: !this.state.startingDayModal });
  };

  toggleFilterModal = () => {
    this.setState({ filterModal: !this.state.filterModal });
  };

  toggleListView = async (mode) => {
    const basicInfoGUID = getBasicInfo().GUID;
    await db
      .table("appSettings")
      .put({
        AccountGUID: basicInfoGUID,
        calendarView: !this.state.showList,
        calendarViewMode: mode,
      })
      .then(() => {
        const minDate = new Date(
          this.state.currentDate.getFullYear(),
          this.state.currentDate.getMonth(),
          1
        );
        const maxDate = addOneYearToDate(minDate);

        let from = this.state.filtersOnEventFrom ?? minDate;
        let to = this.state.filtersOnEventTo ?? maxDate;

        if (mode === "calendar") {
          from = new Date(2020, 1, 1);
          to = new Date(2050, 31, 12);
        }

        this.setState({
          showList: !this.state.showList,
          displayMode: mode,
          filtersOnEventFrom: from,
          filtersOnEventTo: to,
        });
      });
  };

  toggleImportEvents = () => {
    this.setState({
      importEventModal: !this.state.importEventModal,
    });
  };

  toggleEmailModal = () => {
    if (this.state.emailModal)
      return this.setState({
        emailModal: !this.state.emailModal,
      });
    Swal.fire({
      text: "How would you like to send this email?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "secondary",
      confirmButtonText: "Generate PDF",
      cancelButtonText: "As Body of Email",
    }).then((result) => {
      if (result.value) {
        this.setState({
          exportType: "export",
          emailModal: !this.state.emailModal,
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState({
          exportType: "email",
          emailModal: !this.state.emailModal,
        });
      }
    });
  };

  toggleGroupModal = () => {
    this.setState({
      groupModal: !this.state.groupModal,
      groupName: null,
      groupColor: null,
      eventsSelected: [],
    });
  };

  toggleCreateGroupModal = () => {
    this.setState({
      createGroupModal: !this.state.createGroupModal,
    });
  };

  updateFiltersStatus = (e) => {
    this.setState({
      filtersOnEventStatus: e,
    });
  };
  updateFiltersFrom = (e) => {
    this.setState({
      filtersOnEventFrom: e,
    });
  };
  updateFiltersTo = (e) => {
    this.setState({
      filtersOnEventTo: e,
    });
  };

  updateFiltersGroups = (e) => {
    this.setState({
      filtersOnEventGroups: e,
    });
  };

  updateFilters = (filters) => {
    this.setState({
      filtersOnEvent: filters,
    });
  };

  setColorGroup = (value, from) => {
    this.setState({
      groupColor: from ?? value,
    });
  };

  setGroupImage = (event, type) => {
    const fileUploaded = type === "drop" ? event : event.target.files[0];
    this.setState({ groupImageTarget: fileUploaded });
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ groupImage: reader.result })
      );
      reader.readAsDataURL(fileUploaded);
    }
  };

  setGroupName = (value) => {
    this.setState({
      groupName: value,
    });
  };

  toggleEvent = (event) => {
    let events = this.state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return this.setState({
        eventsSelected: filtered,
      });
    } else {
      let newOnes = events.concat(event);
      return this.setState({
        eventsSelected: newOnes,
      });
    }
  };

  toggleExportMail = (event) => {
    this.setState({
      eventToExport: event,
      exportMailModal: !this.state.exportMailModal,
    });
  };

  resizeImage = (image, groupObject, eventsSelected) => {
    const file = image;
    const maxImageWidth = 1000; // Set your desired maximum width

    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxImageWidth) {
          newWidth = maxImageWidth;
          newHeight = (maxImageWidth / img.width) * img.height;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            const { createGroup } = this.props.actions;
            createGroup(groupObject, eventsSelected, blob, () => {
              this.props.actions.getGroups(getOrganization().GUID);
              this.toggleCreateGroupModal();
              this.setState({
                groupName: "",
                groupColor: "",
                groupImage: "",
                eventsSelected: [],
              });
            });
          },
          "image/png",
          0.5
        ); // You can adjust the format and quality here
      };
    }
  };

  createGroup = async () => {
    const {
      groupName,
      groupColor,
      eventsSelected,
      groupImage,
      groupImageTarget,
    } = this.state;
    const groupObject = {
      Name: groupName,
      Color: groupColor,
      GUID: uuid.v4(),
      OrganizationGUID: getOrganization().GUID,
      Created: new Date(),
    };

    if (groupImage) {
      this.resizeImage(groupImageTarget, groupObject, eventsSelected);
    } else {
      const { createGroup } = this.props.actions;
      createGroup(groupObject, eventsSelected, "", () => {
        this.props.actions.getGroups(getOrganization().GUID);
        this.toggleCreateGroupModal();
        this.setState({
          groupName: "",
          groupColor: "",
          groupImage: "",
          eventsSelected: [],
        });
      });
    }
  };

  editGroup = (group, events, blob, close, sucess) => {
    const { editGroup, getEvents, getGroups } = this.props.actions;
    const currentOrganizationGUID = getOrganization().GUID;
    const basicInfoGUID = getBasicInfo().GUID;

    editGroup(group, events, blob, close, () => {
      getGroups(currentOrganizationGUID);
      getEvents(currentOrganizationGUID, basicInfoGUID);
    });
  };

  deleteGroup = (group) => {
    this.props.actions.deleteGroup(group, () =>
      this.props.actions.getGroups(getOrganization().GUID)
    );
  };

  deleteEvent = (event) => {
    const { deleteEvent } = this.props.actions;
    Swal.fire({
      text: "Are you sure you want to delete this event '" + event.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteEvent(event);
      }
    });
  };

  fetchAndSetVisibleBy = (event) => {
    const { getVisibles } = this.props.eventActions;
    if (this.state.visibleByModal) {
      this.setState({
        selectedEvent: null,
        visibleByModal: false,
      });
    } else {
      this.setState({
        selectedEvent: event,
        visibleByModal: true,
      });
      getVisibles(event.GUID);
    }
  };

  editEvent = (event, status) => {
    const { editEvent } = this.props.actions;
    let eventToEdit = { ...event, Status: status };
    editEvent(eventToEdit);
  };

  // DO NOT DELETE
  onIdle = (e) => {
    console.log("user is idle", e);
    console.log("last active", this.idleTimer.getLastActiveTime());
    Object.entries(db._allTables).forEach(([key, value]) => {
      if (key.includes("Transactions")) {
        db.table(key)
          .count()
          .then((result) => console.log(result));
      }
    });
  };

  parseAndPrepareIcsFile = async (file) => {
    const result = await file.text();
    console.log("the ical file to import", result);
    const parsedResult = ical.parseString(result);
    const unreadyEvents = parsedResult.events.map((event) => {
      let location = event.location?.value.split(",");
      let [venue, street, city, state, zipcode, country] = location;

      return {
        Name: event.summary.value,
        Date: moment(event.dtstart.value).format("YYYY-MM-DDTHH:mm:ss"),
        GUID: event.uid.value,
        City: city || "",
        Country: country || "",
        State: state || "",
        Venue: venue || "",
      };
    });
    //debugger;

    const events = addMultipleEventsHelper(unreadyEvents);

    this.addBunchEvents(events);

    this.toggleImportEvents();

    this.toggleMultiple();
    // this.toggleMiniImport();
    //this.toggleImportEvents();
  };

  addBunchEvents = async (events) => {
    const actualEvents = this.state.multipleEvents;
    const updatedEvents = events;

    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(updatedEvents)
      .then(() =>
        this.setState({
          multipleEvents: updatedEvents,
        })
      );
  };

  clearEllEvents = async () => {
    await db
      .table("batchEvent")
      .clear()
      .then(() =>
        this.setState({
          multipleEvents: [],
        })
      );
  };

  updateEvent = async (eventUpdated) => {
    let events = this.state.multipleEvents.map((event) =>
      event.GUID === eventUpdated.GUID ? (event = eventUpdated) : event
    );
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(events)
      .then(() =>
        this.setState({
          multipleEvents: events,
        })
      );
  };

  addMultipleEvents = (props) => {
    const { multipleEvents } = this.state;
    const { getEvents } = this.props.actions;
    const { addMutipleEvents } = this.props.eventActions;
    const { toggleAddEvent, teams } = this.props;
    let finalStaffs = [];
    teams.map((team) => {
      team.Staffs.forEach((staff) => finalStaffs.push(staff.GUID));
    });
    finalStaffs = finalStaffs.map((s) => ({
      StaffGUID: s,
      Deleted: null,
      GUID: uuid.v4(),
    }));
    let finalEvents = multipleEvents
      .filter((event) => event.Name !== "")
      .map((event) => {
        const utcDate = `${moment(event.Date)
          .utc()
          .format("YYYY-MM-DD")}T23:00:00`;
        return {
          ...event,
          Date: utcDate,
          VisibleBy: finalStaffs.map((s) => ({
            ...s,
            GUID: uuid.v4(),
            EventGUID: event.GUID,
          })),
        };
      });
    const isNotValidYet = multipleEvents.some((item) => item.Error === true);
    if (isNotValidYet) {
      /* Swal.fire({
        title: "Verify",
        text: "There is some events must be completed before submit",
        icon: "info",
      });*/
    } else {
      let events = finalEvents.map((event) => {
        delete event.Venue;
        delete event.EventTypeName;
        delete event.Error;
        return {
          ...event,
        };
      });
      addMutipleEvents(events, () => {
        ToastNotificationSuccess(1, "Event added successfully.");
        toggleAddEvent();
        getEvents(getOrganization().GUID, getBasicInfo().GUID);
        this.clearEllEvents();
      });
    }
    this.componentDidMount();
  };

  removeEvent = async (event) => {
    let events = this.state.multipleEvents;
    let filtered = events.filter((s) => s.GUID !== event.GUID);
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(filtered)
      .then(() =>
        this.setState({
          multipleEvents: filtered,
        })
      );
  };

  updateMultipleEvent = (event) => {
    let updatedEvents = this.state.multipleEvents;
    const newEvents = updatedEvents.map((e) =>
      e.GUID === event.GUID ? event : e
    );
    this.setState({ multipleEvents: newEvents });
  };

  clearMultipleState = () => {
    this.setState({
      multipleEvents: [],
    });
  };

  addEventMultiple = async (event) => {
    const { teams } = this.props;
    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut([...this.state.multipleEvents, event])
      .then(() =>
        this.setState({
          multipleEvents: [...this.state.multipleEvents, event],
        })
      );
  };

  toggleHistory = () => {
    this.setState({
      showEventHistory: !this.state.showEventHistory,
    });
  };

  toggleMoveEvent = () => {
    this.setState({ showMoveEvent: !this.state.showMoveEvent });
  };

  render() {
    let {
      importEventModal,
      emailModal,
      groupModal,
      addEventModal,
      selectedDate,
      startingDayModal,
      filterModal,
      filtersOnEvent,
      eventsSelected,
      groupName,
      groupColor,
      groupImage,
      createGroupModal,
      filtersOnEventFrom,
      filtersOnEventTo,
      filtersOnEventGroups,
      filtersOnEventStatus,
      currentDate,
      visibleByModal,
      exportMailModal,
      selectedEvent,
    } = this.state;
    let {
      modal,
      toggleModal,
      loadingCalendar,
      events,
      creatingGroup,
      eventGroups,
      deletingGroup,
      actions: { addEvent },
    } = this.props;
    let event_list = events;
    let filteredEvent = [];
    let eventsFiltered =
      filtersOnEvent !== null ? filtersOnEvent : [{ value: [] }];
    console.log("the filters applied to the calendar", filtersOnEvent);
    eventsFiltered.forEach((item) => {
      filteredEvent = [
        ...filteredEvent,
        ...dynamicFilter([item.value], event_list),
      ];
    });
    //Check if we have to filter per days too
    if (filtersOnEventFrom !== null) {
      filteredEvent = filteredEvent.filter((x) => {
        let compare = new Date(
          filtersOnEventFrom.getFullYear(),
          filtersOnEventFrom.getMonth(),
          filtersOnEventFrom.getDate()
        );
        return new Date(x.Date) >= compare;
      });
    }

    if (filtersOnEventTo !== null) {
      filteredEvent = filteredEvent.filter((x) => {
        let compare = new Date(
          filtersOnEventTo.getFullYear(),
          filtersOnEventTo.getMonth(),
          filtersOnEventTo.getDate()
        );
        return new Date(x.Date) <= compare;
      });
    }

    return (
      <div>
        <PushNotifications />
        <ModalComponent
          modal={this.state.showEventHistory}
          toggle={() => this.toggleHistory()}
          childeren={<EventHistory toggle={() => this.toggleHistory()} />}
        />

        <ModalComponent
          modal={exportMailModal}
          toggle={() => handleCloseEmail(this.toggleExportMail)}
          childeren={
            <EmailModal
              fromCalendar
              eventToExport={this.state.eventToExport}
              sendEmail={this.props.commonActions.sendEmail}
              toggle={() => this.toggleExportMail()}
            />
          }
        />
        <ModalComponent
          modal={visibleByModal}
          toggle={() => this.fetchAndSetVisibleBy()}
          childeren={
            <EventVisibility
              selectedEvent={selectedEvent}
              toggle={() => this.fetchAndSetVisibleBy()}
            />
          }
        />
        <ModalComponent
          modal={filterModal}
          toggle={() => this.toggleFilterModal()}
          childeren={
            <FilterModal
              groups={eventGroups}
              updateFiltersGroups={this.updateFiltersGroups}
              updateFiltersStatus={this.updateFiltersStatus}
              updateFiltersFrom={this.updateFiltersFrom}
              updateFiltersTo={this.updateFiltersTo}
              filtersOnEventGroups={filtersOnEventGroups}
              filtersOnEventStatus={filtersOnEventStatus}
              filtersOnEventFrom={filtersOnEventFrom}
              filtersOnEventTo={filtersOnEventTo}
              filtersOnEvent={filtersOnEvent}
              updateFilters={this.updateFilters}
              toggle={() => this.toggleFilterModal()}
            />
          }
        />

        <ModalComponent
          modal={startingDayModal}
          toggle={() => this.toggleStartingDay()}
          childeren={
            <StartingWeekModal toggle={() => this.toggleStartingDay()} />
          }
        />

        <ModalComponent
          modal={importEventModal}
          toggle={() => this.toggleImportEvents()}
          childeren={
            <ImportModal
              toggle={() => this.toggleImportEvents()}
              parseAndPrepareIcsFile={this.parseAndPrepareIcsFile}
            />
          }
        />
        <ModalComponent
          modal={emailModal}
          toggle={() => handleCloseEmail(this.toggleEmailModal)}
          childeren={
            <EmailModal
              toggle={() => this.toggleEmailModal()}
              fromSection="CALENDAR"
              ExportType={this.state.exportType}
            />
          }
        />

        <ModalComponent
          modal={groupModal}
          toggle={() => this.toggleGroupModal()}
          childeren={
            <EventGroupModal
              groupName={groupName}
              groupColor={groupColor}
              groupImage={groupImage}
              setColorGroup={this.setColorGroup}
              setGroupName={this.setGroupName}
              setGroupImage={this.setGroupImage}
              eventsSelected={eventsSelected}
              toggleEvent={this.toggleEvent}
              eventsList={event_list}
              createGroup={this.createGroup}
              creatingGroup={creatingGroup}
              eventGroups={eventGroups}
              deletingGroup={deletingGroup}
              deleteGroup={this.deleteGroup}
              toggle={() => this.toggleGroupModal()}
              toggleCreateGroupModal={() => this.toggleCreateGroupModal()}
              createGroupModal={createGroupModal}
              editGroup={this.editGroup}
            />
          }
        />

        <ModalComponent
          modal={addEventModal}
          toggle={() => this.toggleAddEvent()}
          childeren={
            <AddEventComponent
              addMultipleView={this.state.batchModal}
              fromCalendar={true}
              selectedDate={selectedDate}
              toggleAddEvent={this.toggleAddEvent}
            />
          }
        />

        <ModalComponent
          modal={this.state.addMultiple}
          toggle={() => this.toggleMultiple()}
          childeren={
            <AddMultipleEvent
              openBatchModal={false}
              addEvent={addEvent}
              addEventMultiple={this.addEventMultiple}
              clearAll={this.clearEllEvents}
              multipleEvents={this.state.multipleEvents}
              toggle={this.toggleMultiple}
              updateEvent={this.updateEvent}
              addMultipleEvents={this.addMultipleEvents}
              addBunchEvents={this.addBunchEvents}
              removeEvent={this.removeEvent}
              loading={this.state.loading}
              updateMultipleEvent={this.updateMultipleEvent}
              clearMultipleState={this.clearMultipleState}
            />
          }
        />

        <SideModal
          show={this.state.showMoveEvent}
          toggle={() => this.toggleMoveEvent()}
          children={<MoveEvent events={events} toggle={this.toggleMoveEvent} />}
        />
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60}
        />
        <Calendar
          {...this.state}
          toggleHistory={this.toggleHistory}
          events={filteredEvent}
          setStateValue={this.setStateValue}
          paginationInfo={event_list && event_list.pageInfo}
          logoutUser={this.logout}
          modalValue={modal}
          toggleModal={toggleModal}
          fetchEventList={this.fetchEventList}
          toggleListView={this.toggleListView}
          toggleEditEvent={this.toggleEditEvent}
          toggleImportEvents={this.toggleImportEvents}
          toggleEmailModal={this.toggleEmailModal}
          toggleGroupModal={this.toggleGroupModal}
          toggleAddEvent={this.toggleAddEvent}
          toggleStartingDay={this.toggleStartingDay}
          toggleFilterModal={this.toggleFilterModal}
          loadingCalendar={loadingCalendar}
          deleteEvent={this.deleteEvent}
          currentDate={currentDate}
          fetchAndSetVisibleBy={this.fetchAndSetVisibleBy}
          toggleExportMail={this.toggleExportMail}
          editEvent={this.editEvent}
          toggleMoveEvent={this.toggleMoveEvent}
          expanded={this.props.expanded}
          showExpanded={this.props.showExpanded}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.calendar.calendarEvents,
  loadingCalendar: state.calendar.loadingCalendar,
  eventGroups: state.calendar.eventGroups,
  deletingGroup: state.calendar.deletingGroup,
  creatingGroup: state.calendar.creatingGroup,
  visibleBies: state.event.visibles,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    organizationActions: bindActionCreators(
      { ...organizationActions },
      dispatch
    ),
    eventActions: bindActionCreators({ ...eventActions }, dispatch),
    commonActions: bindActionCreators({ ...commonActions }, dispatch),
    todoActions: bindActionCreators({ ...todoActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar(CalendarComponent));
