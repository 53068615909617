import React from "react";
import PropTypes from "prop-types";
import { TextInput, Loader } from "../../../components";
import { IMAGES } from "../../../constant";

const ResetPassword = (props) => {
  const {
    email,
    password,
    passwordError,
    confirmPassword,
    confirmPasswordError,
    handleChange,
    onInputFieldChange,
    handleSubmit,
    showPassword,
    showConfirmPassword,
    showHideTextValue,
    loading
  } = props;

  return (
    <div className="main-container">
      <div className="before-reset-login">
      <Loader loading={loading} />
        <div className="inner-page">
          <div className="logo">
            <img src={IMAGES.LOGO} width="128" alt="logo" />
          </div>
          <div className="reset-page">
            <div className="reset-page-content">
              <h1>Reset your Password</h1>
              <p>Please enter a new password for your account.</p>
              <div className="email-section">
                Email: {email}
              </div>
              <form>
                <TextInput
                  header={"New Password"}
                  type={showPassword ? 'text' : 'password'}
                  id={"password"}
                  value={password}
                  maxLength={200}
                  onChange={(e) => onInputFieldChange(e)}
                  onFocus={() => handleChange("passwordError", "")}
                  errorMessage={passwordError}
                  togglePassword={showHideTextValue}
                  showPassword={showPassword}
                />
                <TextInput
                  header={"Confirm Password"}
                  type={showConfirmPassword ? 'text' : 'password'}
                  id={"confirmPassword"}
                  value={confirmPassword}
                  maxLength={200}
                  onChange={(e) => onInputFieldChange(e)}
                  onFocus={() => handleChange("confirmPasswordError", "")}
                  errorMessage={confirmPasswordError}
                  togglePassword={showHideTextValue}
                  showPassword={showConfirmPassword}
                />
              </form>
              <div className="submit-button">
                <button type="submit" className="btn primary" id="reset" onClick={() => handleSubmit()}>
                  RESET MY PASSWORD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  showHideTextValue: PropTypes.func,
  showPassword: PropTypes.bool,
  showConfirmPassword: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  passwordError: PropTypes.string,
  confirmPassword: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  handleChange: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

export default ResetPassword;
