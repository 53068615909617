const initialState = {
  authenticated: false,
  user: {},
  logging: false,
  loginError: false,
  requested: false,
  loading: false,
};

export default initialState;
