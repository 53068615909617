import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { IMAGES } from "../../constant";
import { Event } from "./event";
import { getOrganization } from "../../utils";
import db from "../../common/db";
import { sortArray } from "../../common/helpers";
import { injectIntl, FormattedMessage } from "react-intl";

const EventHistory = (props) => {
  let { selectedEvent, toggle, fromGuestList, setCurrentEvent } = props;
  //let today = new Date();
  let containerScroller = useRef();
  const [localEvents, setLocalEvents] = useState([]);
  const [search, setSearch] = useState("");

  const bindData = async () => {
    //Load events locally
    let currentOrganization = getOrganization();

    let localEvents = await db
      .table("eventHistory")
      .where({ OrganizationGUID: currentOrganization.GUID })
      .toArray();

    if (localEvents?.length > 0) {
      localEvents = localEvents.filter(
        (x) => x.Deleted === null && x.Date !== null
      );
      localEvents = sortArray(localEvents, "lastDate");
      localEvents = localEvents.reverse();

      //See if we need to apply some filters here
      // if (search.length > 0) {
      //   let simplifiedSearch = search.toLowerCase().trim();
      //   console.log({ simplifiedSearch });
      //   localEvents = localEvents.filter(
      //     (x) =>
      //       x.Name.toLowerCase().includes(simplifiedSearch) ||
      //       x.City.toLowerCase().includes(simplifiedSearch) ||
      //       x.EventType.toLowerCase().includes(simplifiedSearch)
      //   );
      // }

      setLocalEvents(localEvents);
      //  console.log("local events found", localEvents);
      //navigating
    }
  };

  useEffect(() => {
    bindData();
    setTimeout(() => {
      let se = document.getElementsByClassName("search")[0];
      if (se) {
        se.focus();
      }
    }, 1000);
  }, []);

  const filter = (e) => {
    setSearch(e);
    bindData();
  };

  let searchValue = search.toLowerCase().trim();
  let filteEvents = localEvents;
  if (searchValue) {
    filteEvents = searchValue
      ? localEvents.filter(
          (x) =>
            x.Name.toLowerCase().includes(searchValue) ||
            x.City.toLowerCase().includes(searchValue) ||
            x.EventType.toLowerCase().includes(searchValue)
        )
      : localEvents;
  }

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section event-sidebar extendEventScreen">
          <div className="text-head">
            <div className="text-left">
              <h1>
                <FormattedMessage
                  id="eventHistory"
                  defaultMessage="Event History"
                />
              </h1>
              <small className="text-muted">
                <FormattedMessage
                  id="eventHistoryNote"
                  defaultMessage="Go back to the previous event you worked on"
                />
              </small>
            </div>
            <div className="text-right formAlternativeStyle">
              <input
                type="text"
                className="form-control search"
                placeholder="search"
                value={search}
                onChange={(e) => filter(e.target.value)}
              ></input>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className=" px-0 pb-0">
            <div className="event-content">
              <div style={{ height: "calc(100vh - 55px)" }}>
                <Scrollbars
                  style={{ width: "100%", height: "calc(100%)" }}
                  ref={containerScroller}
                >
                  {filteEvents &&
                    filteEvents.map((event) => {
                      // let theDate = new Date(event.Date);
                      let classforEvent =
                        selectedEvent && event.GUID === selectedEvent.GUID
                          ? "active"
                          : "";

                      return (
                        <div
                          className={
                            "card mx-4 my-1 shadow-sm " + classforEvent
                          }
                          key={event.GUID}
                          id={event.GUID}
                        >
                          <div className="card-body p-0">
                            <Event
                              clickable
                              event={event}
                              toggle={toggle}
                              fromSidebar
                              fromGuestList={fromGuestList}
                              setCurrentEvent={setCurrentEvent}
                            />
                          </div>
                        </div>
                      );
                    })}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventHistory;
