import React, { useState } from "react";
import Select from "react-select";
import { status, IMAGES } from "../../constant";
import DatePicker from "react-datepicker";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
const FilterModal = (props) => {
  let {
    updateFilters,
    filtersOnEventGroups,
    filtersOnEventStatus,
    filtersOnEventFrom,
    filtersOnEventTo,
    updateFiltersStatus,
    updateFiltersGroups,
    updateFiltersFrom,
    updateFiltersTo,
    toggle,
    groups,
  } = props;
  groups = groups.map((item) => ({
    id: item.GUID,
    label: item.Name,
    value: ["EventGroupGUID", item.GUID],
  }));
  const intl = useIntl();

  console.log({ filtersOnEventFrom });
  return (
    <div className="vehicleModal__root filter" style={{ minWidth: "unset" }}>
      <div
        className="d-flex align-items-center"
        style={{ padding: "8px 14px" }}
      >
        <div className=" mr-auto d-flex w-100 justify-content-between">
          <h4 className="title">
            <FormattedMessage
              id="filterEvents"
              defaultMessage="Filter Events"
            />
          </h4>
          <button
            type="button"
            className="btn btn-sm btn-link ml-2"
            onClick={() => toggle()}
          >
            <FontAwesomeIcon icon={faTimes} color="black" />
          </button>
        </div>
      </div>
      <div className="row px-0">
        <div className="col-md-6 mb-2">
          <label>
            <FormattedMessage id="from" defaultMessage="From" />
          </label>

          <input
            className="form-control p-2 rounded-lg"
            name="dateFrom"
            type="date"
            value={moment(filtersOnEventFrom).format("YYYY-MM-DD")}
            onChange={({ target }) => {
              updateFiltersFrom(target.valueAsDate);
            }}
          />

          {/* <DatePicker
            dateFormat="MMM, dd yyyy"
            selected={filtersOnEventFrom}
            onChange={(date) => {
              updateFiltersFrom(date);
            }}
          /> */}
        </div>
        <div className="col-md-6">
          <label>
            <FormattedMessage id="until" defaultMessage="Until" />
          </label>

          <input
            name="dateTo"
            type="date"
            className="form-control p-2 rounded-lg"
            value={moment(filtersOnEventTo).format("YYYY-MM-DD")}
            onChange={({ target }) => updateFiltersTo(target.valueAsDate)}
          />
          {/* <DatePicker
            dateFormat="MMM, dd yyyy"
            selected={filtersOnEventTo}
            onChange={(date) => {
              updateFiltersTo(date);
            }}
          /> */}
        </div>
        {!props?.renderBy === "guestList" && (
          <div className="col-md-6 mb-2">
            <label>
              <FormattedMessage
                id="selectStatus"
                defaultMessage="Select by Status"
              />
            </label>
            <Select
              value={filtersOnEventStatus}
              placeholder={intl.formatMessage({ id: "select" })}
              isMulti
              options={status}
              classNamePrefix="customStatus"
              onChange={(e, trigger) => {
                let filters = e === null ? null : e.map((item) => item);
                updateFiltersStatus(filters);
                props.renderBy !== "guestList" && updateFilters(filters);
              }}
            />
          </div>
        )}
        <div className="col-md-6">
          <label>
            <FormattedMessage
              id="selectGroup"
              defaultMessage="Select by Group"
            />
          </label>

          <Select
            value={filtersOnEventGroups}
            placeholder={intl.formatMessage({ id: "select" })}
            isMulti
            options={groups}
            classNamePrefix="groups"
            onChange={(e, trigger) => {
              let filters = e === null ? null : e.map((item) => item);
              updateFiltersGroups(filters);
              props.renderBy !== "guestList" && updateFilters(filters);
            }}
          />
        </div>
        <div className="pb-2 pt-3 pr-2 text-right" style={{ width: "100%" }}>
          <button
            type="button"
            className="btn-secondary btn"
            onClick={() => {
              let filters = null;
              updateFiltersStatus(filters);
              updateFiltersGroups(filters);
              updateFiltersFrom(new Date(2020, 1, 1));
              updateFiltersTo(new Date(2050, 11, 31));
              updateFilters(filters, true);
            }}
          >
            <FormattedMessage id="resetFilter" defaultMessage="Reset Filter" />
          </button>
          <button
            type="button"
            className="ml-2 btn-primary btn"
            onClick={() => {
              toggle();
              props?.renderBy === "guestList" && updateFilters([], false);
            }}
          >
            <FormattedMessage id="done" defaultMessage="Done" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
