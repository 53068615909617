export function filterEvents(localEvents, filterState) {
  const { from, to, group, search } = filterState;
  const minDate = new Date(2020, 1, 1);
  const maxDate = new Date(2050, 12, 31);

  const fromDate = from === "" ? minDate : new Date(from);
  const toDate = to === "" ? maxDate : new Date(to);

  let filteredEvents = localEvents?.filter((event) => {
    if (from === "" && to === "" && !group) {
      return true;
    }

    const eventDate = new Date(event.Date);
    const isDateInRange = eventDate >= fromDate && eventDate <= toDate;
    const isGroupIncluded = !group
      ? true
      : group?.GUID === event?.EventGroupGUID;
    return isDateInRange && isGroupIncluded;
  });

  if (search) {
    filteredEvents = search
      ? filteredEvents?.filter(
          (x) =>
            x?.Name?.toLowerCase()?.includes(search) ||
            x?.City?.toLowerCase()?.includes(search) ||
            x?.EventType?.toLowerCase()?.includes(search)
        )
      : filteredEvents;
  }

  return filteredEvents;
}
