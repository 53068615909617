import initialState from "./initialState";
import { reducer as searchCityReducer } from "./searchCity";
import { reducer as searchCountryReducer } from "./searchCountry";
import { reducer as emptyCitiesReducer } from "./emptyCities";
import { reducer as emptyCountriesReducer } from "./emptyCountries";
import { reducer as toggleSidebarReducer } from "./toggleSidebar";
import { reducer as searchVenuesReducer } from "./searchVenues";
import { reducer as emptyVenuesReducer } from "./emptyVenues";
import { reducer as searchHotelsReducer } from "./searchHotels";
import { reducer as exportResourceReducer } from "./exportResource";
import { reducer as moveResourceReducer } from "./moveResource";
import { reducer as emptyHotelsReducer } from "./emptyHotels";
import { reducer as sendEmailReducer } from "./sendEmail";
import { reducer as createShareLinkReducer } from "./createShareLink";
import { reducer as deleteShareLinkReducer } from "./deleteShareLink";
import { reducer as setNotesReducer } from "./setNotes";
import { reducer as removeNotesReducer } from "./removeNotes";
import { reducer as clearNotesReducer } from "./clearNotes";
import { reducer as setActiveNotesReducer } from "./setActiveNotes";
import { reducer as deleteNoteReducer } from "./deleteNote";
import { reducer as getNotificationsReducer } from "./getNotifications";

const reducers = [
  searchCityReducer,
  searchCountryReducer,
  emptyCitiesReducer,
  emptyCountriesReducer,
  toggleSidebarReducer,
  searchVenuesReducer,
  emptyVenuesReducer,
  searchHotelsReducer,
  exportResourceReducer,
  moveResourceReducer,
  emptyHotelsReducer,
  sendEmailReducer,
  createShareLinkReducer,
  deleteShareLinkReducer,
  setNotesReducer,
  removeNotesReducer,
  clearNotesReducer,
  setActiveNotesReducer,
  deleteNoteReducer,
  getNotificationsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
