import React, { useState } from "react";
import {
  DropDownList,
  DropDownVenue,
  ToastNotificationSuccess,
  SimpleLoader,
} from "../../../components";

import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { IMAGES, EVENT_TYPES } from "../../../constant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormField from "../../../components/form/formField";
import { getBasicInfo, getOrganization } from "../../../utils";
import moment from "moment";
import { addEventSchema } from "../../../utils/validationsSchemas";
import { useIntl, FormattedMessage } from "react-intl";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

const AddEvent = (props) => {
  const intl = useIntl();
  let {
    getVisibilityCount,
    toggle,
    loading,
    typeIsEdit,
    toggleVisibilty,
    getCountrys,
    getCities,
    cities,
    emptyCities,
    countries,
    emptyCountries,
    visibleStaffs,
    addEvent,
    searchVenues,
    venues,
    emptyVenues,
    toggleMultiple,
    teams,
    expanded,
    fromCalendar,
    selectedDate,
  } = props;

  const [eventType, setEventType] = useState("");

  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", place.split(",")[1].trim());
      props.setFieldValue("Country", place.split(",")[2].trim());
    } else {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", "");
      props.setFieldValue("Country", place.split(",")[1].trim());
    }
  }

  function setVenue(place, props) {
    if (place !== undefined && place !== null && place.Name !== undefined) {
      props.setFieldValue("VenueGUID", place.GUID);
      props.setFieldValue("City", place.City);
      props.setFieldValue("Country", place.Country);
      props.setFieldValue("State", place.State);
      props.setFieldValue("Latitude", place.Latitude);
      props.setFieldValue("Longitude", place.Longitude);
      props.setFieldValue("Venue.Name", place.Name);
      props.setFieldValue("VenueObj", place);
    }
  }

  const handleSubmit = (values, visibleStaffs, teams) => {
    addEvent(
      values,
      visibleStaffs,
      teams,
      () => {
        toggle();
        ToastNotificationSuccess(1, "Event Created succesfully.");
      },
      eventType
    );
  };

  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="event-scroll">
            <div className="text-head">
              <div className="text-left d-flex flex-row justify-content-between w-100">
                <div>
                  <h1>
                    <FormattedMessage
                      id="addEvent"
                      defaultMessage="Add Event"
                    />
                  </h1>
                  <p>
                    <FormattedMessage
                      id="fillBelowDetailsEvent"
                      defaultMessage="Fill out the below details to add new event"
                    />
                  </p>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-link ml-2"
                  onClick={() => props.toggle()}
                >
                  <FontAwesomeIcon icon={faTimes} color="black" />
                </button>
              </div>
              <div className="close-icon d-none" onClick={() => toggle()}>
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values) => handleSubmit(values, visibleStaffs, teams)}
              validationSchema={addEventSchema}
              initialValues={{
                Name: "",
                Country: "US",
                VenueObj: {},
                VenueGUID: "",
                EventType: "",
                City: "",
                State: "",
                CreationDate: new Date(),
                Date: fromCalendar
                  ? moment(selectedDate).toDate()
                  : moment().format("YYYY-MM-DD"),
                Notes: "",
                Status: "Confirmed",
                Longitude: "",
                Latitude: "",
                OrganizationGUID: getOrganization().GUID,
                CreatedUserGUID: getBasicInfo().GUID,
                LastSync: new Date(),
                EventCapacity: "",
              }}
            >
              {(propsFormik) => {
                console.log({ propsFormik });

                return (
                  <Form autoComplete="off" className="addEvent__form">
                    <div className="event-inside">
                      <div className="event-content">
                        <FormField
                          fullWidth={true}
                          label={
                            <FormattedMessage
                              id="eventName"
                              defaultMessage="Event Name"
                            />
                          }
                          additionalClasses="mt-2 px-0"
                        >
                          <Field
                            className="form-control"
                            type="text"
                            name="Name"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Name"
                            component="div"
                          />
                        </FormField>
                        <div className="row justify-content-between">
                          <FormField
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="eventType"
                                defaultMessage="Event Type"
                              />
                            }
                          >
                            <Select
                              className="form-control"
                              placeholder={intl.formatMessage({
                                id: "Select an event type",
                              })}
                              options={EVENT_TYPES?.map((e) => {
                                return {
                                  label: intl.formatMessage({
                                    id: e,
                                  }),
                                  value: e,
                                };
                              })}
                              onChange={(e) => {
                                propsFormik.setFieldValue("EventType", e.value);
                                setEventType(e.value);
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="EventType"
                              component="div"
                            />
                          </FormField>

                          <FormField
                            fullWidth={false}
                            className="pl-3 w-50"
                            label={
                              <FormattedMessage
                                id="date"
                                defaultMessage="Date"
                              />
                            }
                          >
                            <Field
                              type="date"
                              className="form-control"
                              style={{ marginTop: "9px" }}
                              name="Date"
                              value={moment(propsFormik.values.Date).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                propsFormik.setFieldValue(
                                  "Date",
                                  e.target.value
                                );
                              }}
                            />
                          </FormField>
                        </div>
                        <FormField
                          fullWidth={true}
                          label={
                            <FormattedMessage
                              id="venue"
                              defaultMessage="Venue"
                            />
                          }
                        >
                          <Field
                            autoComplete="off"
                            className="form-control"
                            type="text"
                            name="Venue.Name"
                            onFocus={(e) => {
                              e.target.setAttribute("autocomplete", "off");
                            }}
                            onChange={(e) => {
                              propsFormik.setFieldValue(
                                "Venue.Name",
                                e.currentTarget.value
                              );
                              if (e.currentTarget.value.length > 3) {
                                searchVenues(e.currentTarget.value);
                              } else if (e.currentTarget.value.length < 3) {
                                emptyVenues();
                              }
                            }}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Venue.Name"
                            component="div"
                          />
                          <DropDownVenue
                            searchList={venues}
                            setData={(id) => {
                              setVenue(venues[id], propsFormik);
                              emptyVenues();
                            }}
                            displayValue={"Name"}
                          />
                        </FormField>
                        <div className="row">
                          <FormField
                            additionalClasses="px-0 p-right"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="city"
                                defaultMessage="City"
                              />
                            }
                          >
                            <Field
                              autoComplete="off"
                              className="form-control"
                              name="City"
                              id="City"
                              onChange={(e) => {
                                propsFormik.setFieldValue(
                                  "City",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  getCities(e.currentTarget.value);
                                } else if (e.currentTarget.value.length < 3) {
                                  emptyCities();
                                }
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="City"
                              component="div"
                            />
                            <DropDownList
                              searchList={cities}
                              setData={(id) => {
                                setStateOrNotState(
                                  cities[id].Address,
                                  propsFormik
                                );
                                emptyCities();
                              }}
                              displayValue={"Address"}
                            />
                          </FormField>
                          <FormField
                            additionalClasses="px-0 pl-2"
                            fullWidth={false}
                            label={
                              <FormattedMessage
                                id="state.signUp"
                                defaultMessage="State"
                              />
                            }
                          >
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="State"
                            />
                            <ErrorMessage
                              className="error-message"
                              name="State"
                              component="div"
                            />
                          </FormField>
                        </div>
                        <div className="row">
                          <FormField
                            fullWidth={false}
                            additionalClasses="px-0 pr-2"
                            label={
                              <FormattedMessage
                                id="country"
                                defaultMessage="Country"
                              />
                            }
                          >
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="Country"
                              onChange={(e) => {
                                propsFormik.setFieldValue(
                                  "Country",
                                  e.currentTarget.value
                                );
                                if (e.currentTarget.value.length > 3) {
                                  getCountrys(e.currentTarget.value);
                                } else if (e.currentTarget.value.length < 3) {
                                  emptyCountries();
                                }
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="Country"
                              component="div"
                            />
                            <DropDownList
                              searchList={countries}
                              setData={(id) => {
                                propsFormik.setFieldValue(
                                  "country",
                                  countries[id].Name
                                );
                                emptyCountries();
                              }}
                              displayValue={"name"}
                            />
                          </FormField>
                          <FormField
                            fullWidth={false}
                            additionalClasses="px-0 pl-2"
                            label={
                              <FormattedMessage
                                id="eventCapacity"
                                defaultMessage="Event Capacity"
                              />
                            }
                          >
                            <Field
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              value={propsFormik.values.EventCapacity}
                              onChange={(e) => {
                                let value = e.target.value.replace(",", "");
                                value = value.replace(" ", "");
                                propsFormik.setFieldValue(
                                  "EventCapacity",
                                  value
                                );
                              }}
                            />
                            <ErrorMessage
                              className="error-message"
                              name="EventCapacity"
                              component="div"
                            />
                          </FormField>
                        </div>

                        <div className="row">
                          <div className="col-md-6 px-0 pr-2 d-none">
                            <div
                              className="form-group visibility"
                              onClick={() => toggleVisibilty()}
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <label hrmlfor={"text"}>
                                <FormattedMessage
                                  id="Visible By"
                                  defaultMessage="Visible By"
                                />
                              </label>
                              <Select
                                menuIsOpen={true}
                                closeMenuOnSelect={false}
                                placeholder={getVisibilityCount()}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 px-0 pr-2">
                            <div
                              className="form-group"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <label hrmlfor={"text"}>
                                {
                                  <FormattedMessage
                                    id="published"
                                    defaultMessage="Published"
                                  />
                                }
                              </label>
                              <Select
                                placeholder={intl.formatMessage({
                                  id: "Published",
                                })}
                                options={[
                                  {
                                    label: intl.formatMessage({
                                      id: "Published",
                                    }),
                                    value: new Date(),
                                  },
                                  {
                                    label: intl.formatMessage({
                                      id: "Private",
                                    }),
                                    value: null,
                                  },
                                ]}
                                onChange={(e) => {
                                  propsFormik.setFieldValue(
                                    "LastSync",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <FormField
                          fullWidth={true}
                          label={
                            <FormattedMessage
                              id="generalNotes"
                              defaultMessage="General Notes"
                            />
                          }
                        >
                          <ReactQuill
                            theme="snow"
                            value={propsFormik.values.Notes}
                            onChange={(val) => {
                              console.log("Value on the notes", val);
                              propsFormik.setFieldValue("Notes", val);
                              //setNotes(val);
                            }}
                            // onBlur={() => updateNote(props.values.Notes)}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Note"
                            component="div"
                          />
                        </FormField>
                        <div className="button-section">
                          {loading ? (
                            <div className="text-center">
                              <SimpleLoader loading={loading} />
                            </div>
                          ) : (
                            <>
                              <div className="button-left">
                                <button
                                  type="button"
                                  onClick={() => toggleMultiple()}
                                  className="btn secondary"
                                >
                                  {
                                    <FormattedMessage
                                      id="addMultiple"
                                      defaultMessage="Add Multiple"
                                    />
                                  }
                                </button>
                              </div>
                              <div className="submit-button">
                                <button type="submit" className="btn primary">
                                  {
                                    <FormattedMessage
                                      id="done"
                                      defaultMessage="Done"
                                    />
                                  }
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
