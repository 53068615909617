import React from "react";
import NewWindow from "react-new-window";
// import { Editor } from "@tinymce/tinymce-react";
import { TINY_MCE_KEY } from "../../constant";
import RichTextComponent from "../common/RichTextEditorComponent";

const OutsideNote = (props) => {
  let { updateTitle, content, updateContent, saveNote, title, note } = props;
  return (
    <NewWindow>
      <div
        className="note"
        style={{ backgroundColor: note.Color || "#fad155" }}
      >
        <div className="d-flex align-items-center">
          <h6 className="note__title draggable">
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => updateTitle(e.target.value)}
              onBlur={saveNote}
              placeholder="Add title here..."
            />
          </h6>
        </div>
        <div
          className="note__editor"
          style={{ height: window.innerHeight - 100 }}
        >
          <RichTextComponent
            value={content}
            handleChange={({ value }) => {
              updateContent(value);
            }}
            onBlur={saveNote}
          />

          {/* <Editor
            images_upload_url="https://app.plnify.com/api/Events/UploadImageTINYMCE"
            apiKey={TINY_MCE_KEY}
            //   initialValue={content}
            value={content}
            onEditorChange={(content) => updateContent(content)}
            inline
            init={{
              images_upload_url:
                "https://app.plnify.com/api/Events/UploadImageTINYMCE",
              menubar: false,
              plugins: ["advlist autolink lists link image fullscreen"],
              toolbar: [
                "undo redo | bold italic alignleft aligncenter alignright | image",
              ],
              branding: false,
              content_style: "p{font-size:14px !important;}",
              visual: false,
            }}
            onBlur={saveNote}
          /> */}
        </div>
      </div>
    </NewWindow>
  );
};

export default OutsideNote;
