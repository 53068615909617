import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./scss/style.scss";
import configStore from "./common/configStore";
import "react-customize-token-input/dist/react-customize-token-input.css";
import registerServiceWorker from "./utils/serviceWorker";
import "react-image-crop/dist/ReactCrop.css";
import { Layout } from "./components/layout/layout";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adENiXnpXc3JXRWVf"
);

const store = configStore();
const element = document.getElementById("root");

const ConnectedApp = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(ConnectedApp, element);
//registerServiceWorker();
