import React, { useState, useRef, useEffect } from "react";
import { ModalComponent, SimpleLoader } from ".";
import { IMAGES } from "../../constant";
import SelectableEvent from "../event/selectableEvent";
import { Scrollbars } from "react-custom-scrollbars";
import { scrollNearTo } from "../../common/helpers";
import { injectIntl, FormattedMessage } from "react-intl";
import { getOrganization } from "../../utils";
import db from "../../common/db";
import { useDispatch } from "react-redux";
import { ConsoleNetwork } from "mdi-material-ui";

const LinkModal = (props) => {
  let {
    nextStep,
    toggle,
    arrayEvents,
    title,
    toggleEvent,
    eventsSelected,
    footer,
    actionResource,
    sideBarItemData,
    success,
    type,
    reservationStep,
    reservationStepText,
    skipStep,
    loading,
  } = props;

  let today = new Date();
  let containerScroller = useRef();

  const organization = getOrganization().GUID;
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    //navigating

    (async () => {
      setEvents(
        (
          await db
            .table("events")
            .where({ OrganizationGUID: organization })
            .toArray()
        ).filter((x) => x.Deleted === null)
      );
    })();

    scrollNearTo(containerScroller.current, events, props.event.GUID);
  }, []);

  return (
    <>
      <div className="organization-transparent">
        <div className="event-page">
          <div className="event-expennd-section event-sidebar move-modal">
            <div className="text-head hotel headerBg">
              <div className="text-left flex">
                <h1>{title ? title : "Link Event"}</h1>
              </div>
            </div>
            <div className="p-2" style={{ height: "calc(100vh - 80px)" }}>
              <Scrollbars ref={containerScroller}>
                {events
                  .filter((event) => event.Status === "Confirmed").sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime())
                  .map((item) => {
                    //   console.log("event equals", item, props.event,  item.GUID === props.event.GUID);
                    let classforEvent =
                      item.GUID === props.event.GUID ? "activeEvent" : "";
                    return (
                      <div
                        className={"card my-1 shadow-sm " + classforEvent}
                        key={item.GUID}
                      >
                        <div className="card-body p-0">
                          <SelectableEvent
                            event={item}
                            toggleEvent={toggleEvent}
                            eventsSelected={eventsSelected}
                            currentEvent
                          />
                        </div>
                      </div>
                    );
                  })}
              </Scrollbars>
            </div>
            <div className="event-inside hotel">
              <div className="event-content">
                <div
                  className="button-section py-0"
                  style={{ display: "block" }}
                >
                  {skipStep ? (
                    <button
                      onClick={() => nextStep()}
                      className="btn secondary block"
                    >
                      <FormattedMessage id="Skip" defaultMessage="Skip" />
                    </button>
                  ) : null}
                  {reservationStep ? (
                    <button
                      onClick={() => nextStep()}
                      type="button"
                      className="btn primary block"
                    >
                      {reservationStepText}
                    </button>
                  ) : loading ? (
                    <SimpleLoader loading={loading} />
                  ) : (
                    <button
                      onClick={() =>
                        dispatch(
                          actionResource(
                            sideBarItemData,
                            type,
                            eventsSelected,
                            success
                          )
                        )
                      }
                      type="button"
                      className="btn primary block"
                    >
                      {footer}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkModal;
