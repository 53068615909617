import React, { Component } from "react";
import Profile from "./Profile";

class ProfileContainer extends Component {
  state = {};
  render() {
    return <Profile />;
  }
}

export default ProfileContainer;
