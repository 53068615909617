import React, { useState, useEffect } from "react";

const messagesArray = [
  "Checking the speakers...",
  "Going to the Hotel...",
  "Loading the Mics...",
  "Drinking Coffee...",
  "Check the lights please!",
  "Calling to my Agent",
];

export const Loader = (props) => {
  const [textIdx, updateTextIdx] = useState(0);
  let timeout;

  useEffect(() => {
    timeout = setInterval(() => {
      let currentIdx = textIdx;
      updateTextIdx({ textIdx: currentIdx + 1 });
    }, 500);
    return () => {
      clearInterval(timeout);
    };
  });

  let textChanges = "";//messagesArray[textIdx]; //Comented due to unnecesary usage
  let { loading } = props;

  return (
    loading && (
      <div className="loader-main d-flex flex-column">
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="text-center">
          <p className="text-dark">{textChanges}</p>
        </div>
      </div>
    )
  );
};
