import React, { Component } from "react";
import ChangePassword from "./ChangePassword";

class ChangePasswordContainer extends Component {
  render() {
    return <ChangePassword />;
  }
}

export default ChangePasswordContainer;
