import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import db from "../../common/db";
import { usePrevious } from "../../hooks/usePrevious";
import { DropDownComponent, ToastNotificationSuccess } from "../common";
import { useHistory, useLocation } from "react-router-dom";
import { getBasicInfo, getOrganization } from "../../utils";
import uuid from "uuid";
import shareApi from "../../common/shareApi";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";

const TodoItem = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    event: null,
  });

  const [selectedLink, setSelectedLink] = useState(null);

  useEffect(() => {
    getEvent(props?.todo.EventGUID);
  }, []);

  const prevProps = usePrevious(props);

  useEffect(() => {
    if (prevProps?.todo.EventGUID !== props?.todo.EventGUID) {
      getEvent(props?.todo.EventGUID);
    }
  }, [prevProps]);

  const getEvent = async (eventGUID) => {
    if (eventGUID === null) {
      setState((prev) => ({ ...prev, event: null }));
      return;
    }
    const event = await db.table("events").where({ GUID: eventGUID }).first();
    setState((prev) => ({ ...prev, event }));
  };

  // const getPercentageDone = (tasks) => {
  //   const tasksTotal = cleanDelete(tasks);
  //   const tasksDone = tasksTotal?.filter((t) => t.Status).length;
  //   const total = (tasksDone / tasksTotal.length) * 100;
  //   return isNaN(total) ? 0 : Math.round(total);
  // };

  const {
    currentList,
    todo,
    setCurrentList,
    deleteList,
    setEditList,
    setLinkList,
    createTemplate,
    handleShareModal,
  } = props;
  const { event } = state;

  const handleTodoView = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let permission = 1;
    const { response } = (
      await shareApi.post("collab/GenerateLink", {
        GUID: uuid.v4(),
        SectionType: "todo",
        SectionGUID: todo.GUID, //example id of the todo
        SharedByAccountGUID: currentUser, // id of the user who shared this
        OrganizationGUID: currentOrganization, //id of the organization
        ParentSectionGUID: null, //sent it null
        Deleted: null, //sent it null
        Updated: null, //sent it null
        Created: null, //sent it null
        PermissionType: permission, // 0 = readonly ; 1= read and write
      })
    ).data;
    history.push(`/c/${response.GUID}`);
  };

  const handleShare = async () => {
    const currentUser = getBasicInfo().GUID;
    const currentOrganization = getOrganization().GUID;
    let innerSelectedLink = null;
    let permission = 0;

    const linksArray = props.linkIds || [];

    // const response = await shareApi.get(
    //   `collab/links?SectionId=${currentList.GUID}`
    // );

    // const links = await response.data;

    // const parsedLinks = typeof links;
    // console.log({ parsedLinks, links, response });

    const shareLink = async () => {
      if (!navigator.canShare) return handleShareModal();

      const { response } = !innerSelectedLink
        ? (
            await shareApi.post("collab/GenerateLink", {
              GUID: uuid.v4(),
              SectionType: "todo",
              SectionGUID: currentList.GUID, //example id of the todo
              SharedByAccountGUID: currentUser, // id of the user who shared this
              OrganizationGUID: currentOrganization, //id of the organization
              ParentSectionGUID: null, //sent it null
              Deleted: null, //sent it null
              Updated: null, //sent it null
              Created: null, //sent it null
              PermissionType: 1, // 0 = readonly ; 1= read and write
            })
          ).data
        : { response: { GUID: innerSelectedLink } };

      navigator.canShare();

      const shareData = {
        title: "Plnify",
        text: "Check your pending task.",
        url: `${window.location.protocol}//${window.location.host}/c/${response.GUID}`,
      };

      try {
        await navigator.share(shareData);
        // ToastNotificationSuccess(1,"Link shared successfully")
      } catch (err) {
        ToastNotificationSuccess(2, `Error: ${err}`);
      }
    };

    Swal.fire({
      title: "Select a Link to Share",
      input: "select",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      inputOptions: {
        NewLink: "Create New Link",
        ...props.linkIds,
      },
      inputPlaceholder: "Select a link",
      confirmButtonText: "Create",
      showCancelButton: true,
      showDenyButton: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve(
              "You need to select a link or select the create new one option."
            );
          }
        });
      },
    }).then((result) => {
      if (result.isDismissed) return;
      if (result.value !== "NewLink") {
        setSelectedLink(() => {
          innerSelectedLink = linksArray[result.value];
          return innerSelectedLink;
        });
      }

      shareLink();
    });
  };

  return (
    <div
      onClick={() => setCurrentList(todo)}
      // onDoubleClick={() => handleTodoView(todo)}
      className={`teamItem  ${
        currentList && currentList.GUID === todo.GUID ? "active" : ""
      }`}
    >
      <div className="row align-items-center">
        <div className="col-9 px-0">
          <div className="teamItem__details">
            <h5 className="title">{todo.Name}</h5>
            {event && (
              <div className="subTitle">
                <span>
                  {moment(event.Date).format("MMM DD, YYYY")} | {event.Name},{" "}
                  {event.City}, {event.Country}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="taskItem__progress col-auto ml-auto px-0">
          {/* <span className="ml-1">{`${this.getPercentageDone(
              todo.TodoTasks
            )} %`}</span> */}
          <button className="teamItem__more btn-reset">
            <DropDownComponent
              data={[
                {
                  name: "Save as Template",
                  trigger: () => createTemplate(currentList),
                },
                {
                  name: "Share",
                  trigger: () => handleShare(),
                },
                {
                  name: "Link to Event",
                  trigger: () => setLinkList(),
                },
                {
                  name: <FormattedMessage id="Edit" defaultMessage="Edit" />,
                  trigger: () => {
                    setEditList();
                    props?.setIsAddingNewList && props.setIsAddingNewList(true);
                  },
                },
                {
                  name: (
                    <FormattedMessage id="delete" defaultMessage="Delete" />
                  ),
                  trigger: () => deleteList(todo),
                  delete: true,
                },
              ]}
            />
          </button>
        </div>
        {/* <div className="col-1"> */}

        {/* </div> */}
      </div>
    </div>
  );
};

export default TodoItem;
