import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InfoTip, SimpleLoader } from "../common";
import uuid from "uuid";
import InviteItem from "./InviteItem";
import { validateEmail, getBasicInfo, getOrganization } from "../../utils";
import Swal from "sweetalert2";
import * as organizationActions from "../../page/organization/redux/actions";
import { useIntl, FormattedMessage } from "react-intl";

const InviteUserForm = (props) => {
  const intl = useIntl();
  const [state, setState] = useState({
    users: [],
    emails: [],
    readyToSend: false,
  });

  useEffect(() => {
    const user = {
      GUID: uuid.v4(),
      Name: "",
      LastName: "",
      Email: "",
      Phone: "",
      TeamName: props?.SelectedTeam || "",
      TypeOfAccess: "",
      notify: true,
    };

    setState({
      users: [...state.users, user],
      defaultPassword: "",
    });
  }, []);

  const addNewUser = () => {
    const user = {
      GUID: uuid.v4(),
      Name: "",
      // nameIsValid: false,
      LastName: "",
      // lastNameIsValid: false,
      Email: "",
      // emailIsValid: false,
      Phone: "",
      TeamName: "",
      // teamIsValid: false,
      TypeOfAccess: "",
      notify: true,
    };
    const newUsers = state?.users?.concat(user);
    // console.log(newUsers);
    setState(
      {
        users: newUsers,
      },
      checkButtonStatus
    );
  };

  const clearAllUsers = () => {
    setState(
      {
        users: [],
      },
      checkButtonStatus()
    );
  };

  const deleteUser = (GUID) => {
    const filteredUsers = state?.users.filter((user) => user.GUID !== GUID);
    setState(
      {
        users: filteredUsers,
      },
      checkButtonStatus()
    );
  };

  const onUpdateUser = (user) => {
    let result = state?.users.map((u) => {
      if (u.GUID === user.GUID) {
        u = {
          ...user,
          teamIsValid: false,
          emailIsValid: false,
          lastNameIsValid: false,
          nameIsValid: false,
        };
        return u;
      }
      return u;
    });
    // console.log(result)
    //  setState(
    //   {
    //     result,
    //   },
    //    checkButtonStatus
    // );
    checkAllArray(user, false);
  };

  const settingRole = (GUID, value) => {
    debugger;
    if (value === "Non-App User") {
      const user = state?.users.find((u) => u.GUID === GUID);
      user.TypeOfAccess = 2;
      const users = state?.users.map((u) => {
        if (u.GUID === user.GUID) {
          u = user;
          return u;
        }
        return false;
      });

      setState({
        users,
      });
    } else if (value === "Admin") {
      const user = state?.users.find((u) => u.GUID === GUID);
      user.TypeOfAccess = 1;
      const users = state?.users.map((u) => {
        if (u.GUID === user.GUID) {
          u = user;
          return u;
        }
        return;
      });

      setState({
        users,
      });
    } else {
      const user = state?.users.find((u) => u.GUID === GUID);
      user.TypeOfAccess = 0;
      user.TeamName = value;
      const users = state?.users.map((u) => {
        if (u.GUID === user.GUID) {
          u = user;
          return u;
        }
        return;
      });

      setState({
        users,
      });
    }
  };

  const setTeam = (GUID, value) => {
    const user = state?.users.find((u) => u.GUID === GUID);
    user.TeamName = value;
    const users = state?.users.map((u) => {
      if (u.GUID === user.GUID) {
        u = user;
        return u;
      }
      return;
    });
    setState({
      users,
    });
  };

  const settingAccess = (value) => {
    let nonAppRole = props.teams.find((team) => team.Name === "Non-App User");
  };

  const setDataFromEmail = (GUID, email) => {
    const user = {
      GUID,
      Name: email.Name,
      LastName: email.LastName,
      Phone: email.Phone,
      TeamName: "",
      TypeOfAccess: 0,
    };
    const users = state?.users.map((u) => {
      if (u.GUID === user.GUID) {
        u = user;
        return u;
      }
      return u;
    });
    checkAllArray(user, false);

    setState({
      users,
    });
  };

  const getValueOfAccess = (GUID) => {
    const userAccess = state?.users.find((u) => u.GUID === GUID).TypeOfAccess;
    //debugger
    if (userAccess === 0) return { value: "Guest" };
    if (userAccess === 1) return { value: "Admin" };
    if (userAccess === 2) return { value: "Non-App User" };
  };

  const checkAllArray = (userUpdated, final) => {
    if (final) {
      if (userUpdated.Name.length > 2) {
        userUpdated.nameIsValid = true;
      } else {
        userUpdated.nameIsValid = false;
      }
      if (userUpdated.LastName.length > 2) {
        userUpdated.lastNameIsValid = true;
      } else {
        userUpdated.lastNameIsValid = false;
      }
      if (validateEmail(userUpdated.Email)) {
        userUpdated.emailIsValid = true;
      } else {
        userUpdated.emailIsValid = false;
      }
      if (userUpdated.TeamName.length > 2) {
        userUpdated.teamIsValid = true;
      } else {
        userUpdated.teamIsValid = false;
      }
    }
    const users = state?.users.map((u) => {
      if (u.GUID === userUpdated.GUID) {
        u = userUpdated;
        return u;
      }
      return u;
    });
    setState(
      {
        users,
      },
      checkButtonStatus()
    );
  };

  const checkButtonStatus = () => {
    const ifValidOrNot = state?.users.map(
      ({ nameIsValid, lastNameIsValid, emailIsValid, teamIsValid }) => {
        const validations = {
          nameIsValid,
          lastNameIsValid,
          emailIsValid,
          teamIsValid,
        };
        const validationsArray = Object.entries(validations);
        const finalValids = validationsArray.map((v) => v[1]).flat();
        // console.log(finalValids)
        return {
          finalValids,
        };
      }
    );
    const readyToSend = ifValidOrNot
      .map((f) => f.finalValids)
      .flat()
      .includes(false);

    setState({
      readyToSend,
    });
  };

  const refreshTeam = () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const currentOrganizationGUID = getOrganization().GUID;
    const {
      organizationActions: { getOrganizationList, getCurrentOrganization },
    } = props;
    getCurrentOrganization(currentOrganizationGUID, basicInfoGUID);
    getOrganizationList(basicInfoGUID, true);
  };

  const sendInvitations = () => {
    const users = state.users;
    const defaultPassword = state.defaultPassword;
    users?.forEach((user) => {
      checkAllArray(user, true);
    });
    //confirm we dont have wrong data
    const ifValidOrNot = state?.users.map(
      ({ nameIsValid, lastNameIsValid, emailIsValid, teamIsValid }) => {
        const validations = {
          nameIsValid,
          lastNameIsValid,
          emailIsValid,
          teamIsValid,
        };
        const validationsArray = Object.entries(validations);
        const finalValids = validationsArray.map((v) => v[1]).flat();
        return {
          finalValids,
        };
      }
    );
    const hasInvalidValues = ifValidOrNot
      .map((f) => f.finalValids)
      .flat()
      .includes(false);
    if (hasInvalidValues) {
      return;
    }

    if (props.fromNavbar) {
      const usersToNotify = users.filter((user) => user.notify === true);
      if (usersToNotify < 1) {
        refreshTeam();
        props.toggle();
        clearAllUsers();
        return;
      }

      props.organizationActions.inviteUsers(users, defaultPassword, () => {
        Swal.fire({
          title: "Invitation sent!",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        refreshTeam();
        props.toggle();
        clearAllUsers();
      });
    } else {
      const usersToNotify = users.filter((user) => user.notify === true);
      if (usersToNotify < 1) {
        refreshTeam();
        props.toggle();
        clearAllUsers();
        return;
      }

      props.inviteUsers(users, defaultPassword, () => {
        Swal.fire({
          title: "Invitation sent!",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        refreshTeam();
        props.toggle();
        clearAllUsers();
      });
    }
  };

  const setDefaultPassword = (value) => {
    setState((prev) => ({ ...prev, defaultPassword: value }));
  };
  const { toggle, teams, emailToInvite, addAccount, loading } = props;
  const { users, readyToSend } = state;

  console.log({ users });
  return (
    <div className={"organization-transparent"} style={{ width: "80vw" }}>
      <div className="event-page">
        <div className="event-expennd-section invite-team">
          <div className="text-head">
            <div className="text-left d-flex align-items-center">
              <h1>
                <FormattedMessage
                  id="inviteTeamMembers"
                  defaultMessage="Invite Team Members"
                />
              </h1>
              <i
                className="fas fa-info-circle ml-2"
                title={intl.formatMessage({ id: "thisFeature" })}
                style={{ color: "#606060" }}
              ></i>
            </div>
            <div className="col-auto text-right">
              <div className="input-group">
                <div className=" formAlternativeStyle mx-2">
                  {users?.length > 0 ? (
                    <input
                      type="text"
                      autoComplete="off"
                      className=" form-control "
                      placeholder={intl.formatMessage({
                        id: "defaultPassword",
                      })}
                      onChange={(e) => setDefaultPassword(e.target.value)}
                    />
                  ) : null}
                </div>
                <button
                  type="button"
                  onClick={() => clearAllUsers()}
                  className="btn btn-secondary mr-2"
                >
                  <FormattedMessage id="ClearAll" defaultMessage="Clear All" />
                </button>
                <button
                  type="button"
                  onClick={() => addNewUser()}
                  className="btn btn-success border-0"
                >
                  {/* <FormattedMessage
                    id="addNewMember"
                    defaultMessage="Add New Member"
                  /> */}
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-2 inviteContainer mx-2">
            {users?.map((user, index) => (
              <InviteItem
                key={index}
                deleteUser={deleteUser}
                onUpdateUser={onUpdateUser}
                setDataFromEmail={setDataFromEmail}
                settingRole={settingRole}
                getValueOfAccess={getValueOfAccess}
                setTeam={setTeam}
                addAccount={addAccount}
                user={user}
                teams={teams}
                emailToInvite={emailToInvite}
              />
            ))}
          </div>

          <div className="event-inside">
            <div className="event-content d-flex justify-content-end align-items-center pb-2">
              {loading ? null : (
                <div className="no-label">
                  <div></div>
                </div>
              )}
              {state.defaultPassword && state.defaultPassword.length > 0 ? (
                <small className="text-secondary">
                  Assigning a default password is going to create the same
                  password for all the invited members,{" "}
                  <b className="text-danger">
                    if the user already exist in PLNIFY it won't apply.
                  </b>
                </small>
              ) : null}
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <button
                  disabled={!users || !users.length}
                  type="button"
                  onClick={() => sendInvitations()}
                  className="btn btn-primary w-260 ml-3 "
                >
                  <FormattedMessage
                    id="sendInvite"
                    defaultMessage="Send Invite"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teams: state.organizations.teams,
  loading: state.organizations.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(
      { ...organizationActions },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserForm);
