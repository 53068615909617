import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { IMAGES } from "../../../constant";
import { useIntl, injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CreateNewChat = ({
  toggle,
  options,
  createNewChat,
  emails,
  selectEmail,
}) => {
  const intl = useIntl();

  return (
    <div className="">
      <div className="event-page" style={{ overflow: "visible" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left w-100 d-flex justify-content-between">
              <div className="text-left">
                <h1>
                  <FormattedMessage
                    id="createNewChat"
                    defaultMessage="Create new chat"
                  />
                </h1>
                <p>
                  <FormattedMessage
                    id="selectMembers"
                    defaultMessage="Select the members to create a new chat"
                  />
                </p>
              </div>
              <button
                type="button"
                className="btn btn-sm btn-link ml-2"
                onClick={() => toggle(false)}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="event-inside">
            <div className="event-content">
              <div
                className="typeahead__modifier"
                style={{ padding: "12px 0" }}
              >
                <label htmlFor="emailRecipients">
                  <FormattedMessage id="members" defaultMessage="Members" />
                </label>
                <Typeahead
                  id="emailRecipients"
                  allowNew
                  placeholder={intl.formatMessage({ id: "selectNewMember" })}
                  selected={emails}
                  newSelectionPrefix="Add a new member: "
                  multiple={true}
                  onChange={(selected) => {
                    selectEmail(selected);
                  }}
                  options={options}
                  emptyLabel="You dont have members on your workspace"
                />
              </div>
              <div className="button-section">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => createNewChat()}
                >
                  <FormattedMessage
                    id="createNewChatRoom"
                    defaultMessage="Create new chat room"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CreateNewChat);
