import * as constant from "./constants";
import api from "../../../common/api";

export function removeAllReservations(staffs, hotel, success) {
  const url = "/HotelReservation/AddEditMultiple";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_ALL_RESERVATIONS,
      });
      let finalStaffs = staffs.map((staff) => ({
        ...staff,
        Deleted: new Date(),
      }));
      const request = await api.post(url, finalStaffs);
      dispatch({
        type: constant.DELETED_ALL_RESERVATIONS,
        data: hotel,
        staffs: request.data,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.DELETING_ALL_RESERVATIONS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_ALL_RESERVATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_ALL_RESERVATIONS: {
      return {
        ...state,
        loading: false,
        hotels: state.hotels.map((h) => {
          if (h.GUID === action.data.GUID) {
            return { ...h, HotelReservations: action.staffs };
          }

          return h;
        }),
      };
    }
    default:
      return state;
  }
}
