import React, { useEffect, useState } from "react";

export const useWorkspaceInvite = (callBack) => {
  const [needPermission, setNeedPermision] = useState(false);
  const [show, setShow] = useState(false);

  const checkPermission = () => {
    const isInvited = localStorage.getItem("inviteModel");
    if (isInvited) setShow(true);
    return isInvited;
  };

  const declineInvite = (close) => {
    localStorage.removeItem("inviteModel");
    setShow((prev) => !prev);
  };

  const toggle = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    const notificationTimer = setTimeout(() => {
      checkPermission();
    }, 500);
    return () => clearTimeout(notificationTimer);
  }, []);

  return {
    show,
    needPermission,
    handleSubmit: callBack,
    toggle,
    declineInvite,
    checkPermission,
  };
};
