import * as constant from "./constants";
import api from "../../../common/api";

export function createShareLink(link, success) {
  const url = "/Collab/GenerateLink";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.SHARING_LINK,
      });
      const request = await api.post(url, link);
      dispatch({
        type: constant.SHARED_LINK,
        data: JSON.parse(request.data.response).GUID,
      });
      success();
    } catch (e) {
      dispatch({
        type: constant.SHARING_LINK_ERROR,
      });
      console.log(e);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.SHARING_LINK: {
      return {
        ...state,
        sharingLink: true,
      };
    }
    case constant.SHARED_LINK: {
      return {
        ...state,
        sharingLink: false,
        guidLink: action.data,
      };
    }
    case constant.SHARING_LINK_ERROR: {
      return {
        ...state,
        sharingLink: false,
      };
    }
    default:
      return state;
  }
}
