import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { ToastNotificationSuccess } from "..";
import * as commonActions from "../../page/common/redux/actions";
import * as eventActions from "../../page/event/redux/actions";
import { DropDownComponent, ModalComponent } from "../common";

import { IMAGES } from "../../constant";
// import { Editor } from "@tinymce/tinymce-react";
import { FormattedMessage, useIntl } from "react-intl";
import db from "../../common/db";
import NotesPalette from "./NotesPalette";
import OutsideNote from "./OutsideNote";

import RichTextComponent from "../common/RichTextEditorComponent";
import SharingHub from "../common/sharingHub";

const DraggableNote = (props) => {
  const intl = useIntl();
  const urlRef = useRef(null);
  const [showShare, setShowShare] = useState(false);
  const [state, setState] = useState({
    coordinates: { top: 0, left: 0 },
    maximized: false,
    pinned: false,
    title: "",
    content: "",
    lastY: null,
    lastX: null,
    onNewWindow: false,
    style: {},
  });

  useEffect(() => {
    const left = Math.floor(Math.random() * 51);
    const top = Math.floor(Math.random() * 51);
    const color = props.note.Color ? props.note.Color : "#fad155";
    setState((prev) => ({
      ...prev,
      coordinates: { top, left },
      content: props.note.Details,
      title: props.note.Name,
      lastY: props.note.lastY,
      lastX: props.note.lastX,
      color,
    }));
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      content: props.note.Details,
      title: props.note.Name,
      color: props.note.Color,
    }));
  }, [props.note]);

  const toggleMaximized = () => {
    setState((prev) => ({
      ...prev,
      maximized: !state.maximized,
    }));
  };

  const deleteNote = (note) => {
    Swal.fire({
      text: intl.formatMessage({ id: "areYouDeleteNote" }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        props.commonActions.deleteNote(note, () => {
          ToastNotificationSuccess(Math.random(), "Note successfully deleted");
        });
      }
    });
  };

  const pinPosition = () => {
    setState((prev) => ({
      ...prev,
      pinned: !state.pinned,
    }));
  };

  const updateContent = (content) => {
    setState((prev) => {
      return {
        ...prev,
        content,
      };
    });
  };

  const updateTitle = (title) => {
    setState((prev) => ({
      ...prev,
      title,
    }));
  };

  const saveNote = async () => {
    const noteToSave = {
      ...props.note,
      Name: state.title,
      Details: state.content,
      Color: state.color,
    };
    await db.table("notes").put(noteToSave);
    await db.table("activeNotes").put(noteToSave);
    props.eventActions.updateNote(noteToSave);
  };

  const onStopDragging = async (e, data) => {
    const basicNote = props.note;
    const noteToSave = {
      ...basicNote,
      Name: state.title,
      Details: state.content,
      lastY: data.y,
      lastX: data.x,
    };

    setState((prev) => ({
      ...prev,
      lastY: data.y,
      lastX: data.x,
    }));
    await db.table("activeNotes").put(noteToSave);
  };

  const openOutsideNote = () => {
    setState((prev) => ({
      ...prev,
      onNewWindow: true,
    }));
  };

  const setNewColorNote = async (color) => {
    const noteToSave = {
      ...props.note,
      Color: color,
    };

    setState((prev) => ({
      ...prev,
      color,
    }));
    await db.table("notes").put(noteToSave);
    await db.table("activeNotes").put(noteToSave);
    props.eventActions.updateNote(noteToSave);
  };

  const { note } = props;
  const { maximized } = state;
  let secondStyle = {
    backgroundImage: "url(" + IMAGES.LOGO_BLACK_P + ")",
  };

  const toggleShare = () => {
    setShowShare(!showShare);
  };

  return (
    <>
      <ModalComponent
        modal={showShare}
        toggle={() => toggleShare()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={props.note}
            toggle={toggleShare}
            //ParentSectionGUID={attachment.EventGUID}

            Type="NOTE"
            sectionGUID={props.note.GUID}
          />
        }
      />
      {state.onNewWindow && (
        <OutsideNote
          updateTitle={updateTitle}
          content={state.content}
          updateContent={updateContent}
          saveNote={saveNote}
          title={state.title}
          note={note}
        />
      )}
      <Draggable
        disabled={state.maximized || state.pinned}
        bounds="body"
        handle=".note__drag"
        // onStop={urlRef} debe buscarse como sustituir esto(this.eventLogger)
      >
        <div
          className={`noteDraggable note d-flex flex-column  shadow-sm ${
            state.maximized ? "maximized" : ""
          }`}
          style={{
            backgroundColor: state.color ? state.color : "#fad155",

            position: "fixed",
            zIndex: 555,
            width: 300,
            top: state.lastY
              ? state.lastY
              : state.coordinates
              ? state.coordinates.top
              : 0,
            left: state.lastX
              ? state.lastX
              : state.coordinates
              ? state.coordinates.left
              : 0,
            padding: 9,
          }}
        >
          <div className="row topBarNote">
            <div className="col-9 text-left px-0">
              {state.pinned || state.maximized ? null : (
                <div className="note__drag text-left">
                  <button type="button" className="btn btn-link mt-1">
                    <i className="fa fa-grip-vertical"></i>
                  </button>
                </div>
              )}
            </div>
            {/* <OpenInNew onClick={() => openOutsideNote()} /> */}
            <div className="col-3 text-right px-0">
              <div className="btn-group">
                <DropDownComponent
                  data={[
                    {
                      name: (
                        <FormattedMessage id="delete" defaultMessage="Delete" />
                      ),
                      delete: true,
                      border: true,
                      trigger: () => deleteNote(note),
                    },
                    {
                      name: (
                        <FormattedMessage
                          id="pinNote"
                          defaultMessage="Pin Note"
                        />
                      ),
                      border: true,
                      trigger: () => pinPosition(),
                    },
                    {
                      name: (
                        <FormattedMessage id="share" defaultMessage="Share" />
                      ),
                      border: true,
                      trigger: () => toggleShare(),
                    },
                    {
                      name: state.maximized ? (
                        <FormattedMessage
                          id="minimizeNote"
                          defaultMessage="Minimize Note"
                        />
                      ) : (
                        <FormattedMessage
                          id="expandNote"
                          defaultMessage="Expand Note"
                        />
                      ),
                      border: true,
                      trigger: () => toggleMaximized(),
                    },
                  ]}
                >
                  <NotesPalette
                    setNewColorNote={setNewColorNote}
                    noteColor={state.color}
                  />
                </DropDownComponent>
                <button
                  type="button"
                  className="btn btn-sm text-dark d-inline"
                  onClick={() => {
                    //    saveNote();
                    toggleMaximized();
                  }}
                >
                  <i
                    className={
                      state.maximized
                        ? "fa fa-minus"
                        : "fa fa-regular fa-square"
                    }
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-sm text-dark d-inline"
                  onClick={() => {
                    //    saveNote();
                    props.commonActions.removeNotes(note);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
          </div>

          <div
            className="note__editor"
            style={{
              height: maximized ? window.innerHeight - 100 : 202,
              overflowY: "scroll",
            }}
          >
            <div className="noteWatermark" style={secondStyle}></div>

            <RichTextComponent
              readOnly={false}
              value={state?.content ?? ""}
              handleChange={({ value }) => {
                updateContent(value);
              }}
              noToolBar={true}
              containerStyle={{ border: "none" }}
              handleBlur={async () => await saveNote()}
            />
            {/* <Editor
              apiKey={TINY_MCE_KEY}
              //   initialValue={state.content}
              value={state.content}
              onEditorChange={(content) => updateContent(content)}
              inline={true}
              init={{
                height: 200,
                images_upload_url:
                  "https://app.plnify.com/api/Events/UploadImageTINYMCE",
                menubar: state.maximized ? true : false,
                plugins: [
                  "advlist autolink lists link image fullscreen  textcolor",
                ],
                toolbar: !state.maximized
                  ? null
                  : [
                      "undo redo |  forecolor backcolor bold italic alignleft aligncenter alignright",
                    ],
                contextmenu:
                  "undo redo |  forecolor backcolor bold italic alignleft aligncenter alignright | copy paste | link image",
                branding: false,
                content_style: "p{font-size:14px !important;}",
                visual: false,
              }}
              onBlur={saveNote}
            /> */}
          </div>
        </div>
      </Draggable>
    </>
  );
};

const mapStateToProps = (state) => ({
  event: state.event.event,
});

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators({ ...commonActions }, dispatch),
    eventActions: bindActionCreators({ ...eventActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableNote);
