import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { default as Navbar } from "../../../components/common/navbar";
import { getOrganization, getBasicInfo } from "../../../utils";
import { ToastNotificationSuccess } from "../../../components/common/toastNotification";
import Swal from "sweetalert2";
import { ROUTES, IMAGES } from "../../../constant";
import Users from "./Users";
import api, { apiService } from "../../../common/api";
import * as actions from "../redux/actions";

class UsersContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LabelAdmin: false,
      currentTeam: 0,
      rolesToSee: [],
      roleToEdit: null,
      editOrganizationModal: false,
    };
  }

  componentDidMount() {
    const basicInfoGUID = getBasicInfo().GUID;
    const currentOrganizationGUID = getOrganization().GUID;
    const {
      actions: { getOrganizationList, getCurrentOrganization },
      slimModal,
    } = this.props;
    getCurrentOrganization(currentOrganizationGUID, basicInfoGUID);
    getOrganizationList(basicInfoGUID, true);
    slimModal();
  }

  refreshTeam = () => {
    const basicInfoGUID = getBasicInfo().GUID;
    const currentOrganizationGUID = getOrganization().GUID;
    const {
      actions: { getOrganizationList, getCurrentOrganization },
    } = this.props;
    getCurrentOrganization(currentOrganizationGUID, basicInfoGUID);
    getOrganizationList(basicInfoGUID, true);
  };

  setCurrentTeam = (index) => {
    this.setState({
      currentTeam: index,
    });
  };

  toggleTeam = (team) => {
    const { rolesToSee } = this.state;
    if (rolesToSee.includes(team.GUID)) {
      const roles = rolesToSee.filter((t) => t !== team.GUID);
      this.setState((prevState, props) => ({
        rolesToSee: roles,
      }));
    } else if (!rolesToSee.includes(team.GUID)) {
      this.setState((prevState, props) => ({
        rolesToSee: [...prevState.rolesToSee, team.GUID],
      }));
    }
  };

  editRole = (team) => {
    this.setState({
      roleToEdit: team,
    });
  };

  deleteTeam = (team) => {
    Swal.fire({
      text: "Are you sure you want to delete this team ?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.actions.deleteTeam(team, () => {
          ToastNotificationSuccess(1, "Team successfully deleted");
          this.setState({
            currentTeam: 0,
          });
          this.refreshTeam();
        });
      }
    });
  };

  checkTeam = (teamGUID) => {
    const currentTeams = this.state.rolesToSee;
    return currentTeams.includes(teamGUID);
  };

  setTeams = (teams) => {
    const teamsGUID = teams.map((team) => team.GUID);
    this.setState({
      rolesToSee: teamsGUID,
    });
  };

  clearTeams = () => {
    this.setState({
      rolesToSee: [],
    });
  };

  toggleStaff = (staff) => {
    this.props.actions.toggleStaff(staff);
  };
  toggleMakeAdmin = async (staff) => {
    let AccountGUID = staff.AccountGUID;
    let OrganizationGUID = staff.OrganizationGUID;
    let RoleGUID = staff.RoleGUID;
    const request = await apiService.get(
      `/Staff/AddAdmin?AccountGUID=` +
      AccountGUID +
      `&OrganizationGUID=` +
      OrganizationGUID +
      `&RoleGUID=` +
      RoleGUID +
      `&BillingPeriod=`
    );
    console.log(request);
  };
  toggleAdminDelete = async (staff) => {
    const request = await apiService.get(
      `/Staff/RemoveAdmin?StaffGUID=` + staff.GUID
    );
    console.log(request);
  };
  deleteStaff = (staff) => {
    Swal.fire({
      text: "Are you sure you want to delete this staff?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.actions.deleteStaff(staff, () => {
          ToastNotificationSuccess(Math.random(), "Staff deleted successfully");
        });
      }
    });
  };

  editTeam = (team, callback) => {
    this.props.actions.editTeam(team, () => {
      ToastNotificationSuccess(Math.random(), "Team updated successfully");
      callback();
    });
  };

  toggleEditOrganization = () => {
    this.setState({
      editOrganizationModal: !this.state.editOrganizationModal,
    });
  };

  deleteOrganization = (organization) => {
    const user = getBasicInfo();
    Swal.fire({
      text: "Are you sure you want to delete " + organization.Name + "?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.actions.deleteOrganization(
          organization.GUID,
          user.GUID,
          () => {
            this.props.history.push(ROUTES.SELECT_ORGANIZATION);
            ToastNotificationSuccess(
              Math.random(),
              "Organization successfully deleted"
            );
          }
        );
      }
    });
  };

  render() {
    const {
      loading,
      emailToInvite,
      teams,
      currentOrganization,
      history,
      actions: {
        createTeam,
        addAccount,
        cleanInvites,
        inviteUsers,
        toggleNotifications,
      },
    } = this.props;
    const { roleToEdit } = this.state;
    const currentUser = getBasicInfo();
    return (
      <Users
        {...this.state}
        toggleMakeAdmin={this.toggleMakeAdmin}
        toggleAdminDelete={this.toggleAdminDelete}
        setCurrentTeam={this.setCurrentTeam}
        createTeam={createTeam}
        deleteTeam={this.deleteTeam}
        currentOrganization={currentOrganization}
        teams={teams}
        emailToInvite={emailToInvite}
        loading={loading}
        toggleTeam={this.toggleTeam}
        addAccount={addAccount}
        cleanInvites={cleanInvites}
        inviteUsers={inviteUsers}
        toggleNotifications={toggleNotifications}
        editRole={this.editRole}
        roleToEdit={roleToEdit}
        checkTeam={this.checkTeam}
        setTeams={this.setTeams}
        clearTeams={this.clearTeams}
        toggleStaff={this.toggleStaff}
        deleteStaff={this.deleteStaff}
        refreshTeam={this.refreshTeam}
        editTeam={this.editTeam}
        toggleEditOrganization={this.toggleEditOrganization}
        history={history}
        deleteOrganization={this.deleteOrganization}
        currentUser={currentUser}
        expanded={this.props.expanded}
        showExpanded={this.props.showExpanded}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizations.organizationsList,
  loading: state.organizations.loading,
  currentOrganization: state.organizations.currentOrganization,
  teams: state.organizations.teams,
  emailToInvite: state.organizations.emailToInvite,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar(UsersContainers));
