import React from "react";
import { ModalComponent } from "../../../components/common";
import FlightList from "../../../components/flight/flightList";
import { useSelector } from "react-redux";

const Flights = (props) => {
  let {
    toggleAddFlightModal,
    addFlightModal,
    handleSidebarItem,
    sideBarItemData,
    toggleNewMembersModal,
  } = props;

  if (props?.event?.GUID === undefined) return <></>;
  return (
    <div className="resource shadow-sm">
      <ModalComponent toggle={toggleAddFlightModal} modal={addFlightModal} />
      <FlightList
        event={props.event}
        handleSidebarItem={handleSidebarItem}
        toggleAddFlightModal={toggleAddFlightModal}
        sideBarItemData={sideBarItemData}
        toggleNewMembersModal={toggleNewMembersModal}
        {...props}
      />
    </div>
  );
};

export default Flights;
