import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`;

const Marker = (props) => {
  const history = useHistory();

  const evaluateStatus = () => {
    if (props?.event?.Status === "Confirmed") {
      return "#1ba863";
    } else if (props?.event?.Status === "Postponed") {
      return "#bd10e0";
    } else if (props?.event?.Status === "Tentative/Hold") {
      return "#fb9f00";
    } else if (props?.event?.Status === "Cancelled") {
      return "#dd3b44";
    } else {
      return "#ff0000";
    }
  };
  const calcOpacity = () => {
    let now = moment().format("YYYY-MM-DD");
    let isUpcoming = props?.event?.Date >= now ? 1 : 0.5;
    return isUpcoming;
  };
  return (
    <div
      className="marker"
      style={{
        opacity: calcOpacity(),
      }}
      title={
        moment(props?.event?.Date).format("MMM DD, YYYY") +
        " - " +
        props?.event?.Name
      }
      onClick={() => {
        history.push(`/calendar/${props.event.GUID}`);
      }}
    >
      <svg
        baseProfile="basic"
        xmlns="http://www.w3.org/2000/svg"
        fill={evaluateStatus()}
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path d="M24 0c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z"></path>
      </svg>
      <i className="text-white">{props?.index + 1}</i>
    </div>
  );
};

export default Marker;
