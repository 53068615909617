import React, { Component } from "react";
import PropTypes from "prop-types";
import ConfirmEmail from "./ConfirmEmail";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "../redux/actions";

class ConfirmEmailContainer extends Component {
  static propTypes = {};

  componentDidMount() {
    this.props.authActions.confirmEmail(this.props.match.params.token);
  }

  render() {
    return <ConfirmEmail />;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators({ ...authActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmailContainer);
