import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { logoutUser } from "../../../utils";
import history from "../../../common/history";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";
import { getBasicInfo } from "../../../utils";

import SelectOrganization from "./SelectOrganization";
import { useHistory } from "react-router-dom";


const SelectOrganizationContainer = (props)=>{

  const organizations = useSelector(state=>state.organizations)
  const dispatch= useDispatch();
  const [state, setState] = useState({
    modalCreate: false,
  });

  useEffect(()=>{
    dispatch(actions.getOrganizationList(getBasicInfo().GUID));
  },[])
  
  const history = useHistory();
  const logout = () => {
    history.go("/");
    logoutUser();
  };
  return(
    <>
    <SelectOrganization
      selectOrganization={actions.selectOrganization}
      history={history}
      organizationsList={organizations.organizationsList}
      loading={organizations.loading}
      logout={logout}
    />
    </>
  )
}
export default SelectOrganizationContainer;
