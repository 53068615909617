import React, { useEffect, useState } from "react";
import { TodoComment } from "../todo/TodoComment";
import Scrollbars from "react-custom-scrollbars";
import { apiService } from "../../common/api";
import { ToastNotificationSuccess } from "./toastNotification";
import { getBasicInfo } from "../../utils";
import uuid from "uuid";

const TodosComments = (props) => {
  const currentUser = getBasicInfo();
  const [commentValue, setCommentValue] = useState();
  const [localComments, setLocalComments] = useState(
    props?.comments?.sort((a, b) => new Date(b.Date) - new Date(a.Date))
  );

  const handleAddComment = async () => {
    if (!commentValue && localComments?.length <= 0) return;

    const { Name, LastName, GUID } = currentUser;

    const thePost = {
      GUID: uuid.v4(),
      CollabGUID: props.sectionId,
      Date: new Date(),
      Deleted: null,
      Comment: commentValue,
      AccountGUID: GUID,
      GuestGUID: null,
    };

    try {
      const addedComment = await apiService.post(
        "Collab/AddEditComment",
        thePost
      );

      setLocalComments((prev) => {
        const currentState = [...prev];
        const dateTime = new Date();
        currentState.unshift({
          CollabGUID: props.sectionId,
          FirstName: Name,
          LastName: LastName,
          Comment: commentValue,
          Date: dateTime,
        });

        ToastNotificationSuccess(1, "Comment added succesfully");
        setCommentValue("");

        return currentState;
      });
    } catch (error) {
      console.log({ error });
    }
  };

  let filterComments = localComments.filter(
    (comment) => comment.CollabGUID === props.sectionId
  );

  return (
    <div className="event-scroll bg-light">
      <div className="text-left d-flex justify-content-between w-100 p-2 align-items-center text-head">
        <h5 className="title">{`Comments: ${
          props.sectionId ?? "Choose a link to view comments"
        }`}</h5>
        <div>
          {props.sectionId && (
            <button
              className="btn"
              onClick={() => {
                props.setSectionId(null);
              }}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
          )}
          <button
            className="btn"
            onClick={() => {
              props.toggle();
            }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div className="overflow-auto">
        {filterComments?.length > 0 ? (
          <Scrollbars style={{ width: "100%", height: "20rem" }}>
            {filterComments.map((comment) => (
              <div className="form-control px-4">
                <TodoComment {...comment} />
              </div>
            ))}
          </Scrollbars>
        ) : props.linkIds.length > 0 ? (
          <ul
            className="d-flex flex-column bg-white clean-ul text-black"
            style={{
              minHeight: "379px",
              maxHeight: "379px",
              overflow: "auto",
              color: "black",
            }}
          >
            {props.linkIds.map((link) => (
              <li
                className="btn"
                onClick={() => props.setSectionId(link)}
                key={link}
              >
                {link}
              </li>
            ))}
          </ul>
        ) : (
          <div
            className="spinner-border mr-2"
            style={{
              width: "20px",
              height: "20px",
              margin: "11.1rem 15rem",
            }}
          ></div>
        )}
      </div>

      {filterComments?.length > 0 && (
        <div className="d-flex p-2 shadow-lg">
          <div className="bg-secondary d-flex align-items-center justify-content-center rounded p-2">
            <span
              className="text-uppercase text-white"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {currentUser?.Name?.[0]}
              {currentUser?.LastName?.[0]}
            </span>
          </div>
          <textarea
            onChange={(e) => setCommentValue(e.target.value)}
            className="form-control mx-3"
            style={{ resize: "none" }}
            placeholder="Add a comment"
            value={commentValue}
          ></textarea>
          {/* <div
            className="d-flex justify-content-around"
            style={{ width: "100px" }}
          >
            <i className="fa fa-paperclip"></i>
          </div> */}
          <button
            className="btn btn-primary p-2"
            style={{ width: "15%" }}
            onClick={() => handleAddComment()}
          >
            <i
              className="fa fa-send font-weight-bold"
              style={{ fontSize: "16px" }}
            ></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default TodosComments;
