import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Element } from "react-scroll";
import { ModalComponent } from "../../../components";
import AttachmentsContainers from "../../../components/attachments/attachmentsContainers";
import DragAndDrop from "../../../components/common/DragAndDrop";
import ContactsContainers from "../../../components/contacts/contactsContainers";
import SideEvents from "../../../components/event/SidebarEvents";
import GroundContainers from "../../../components/ground/groundContainers";
import HotelContainers from "../../../components/hotel/hotelContainers";
import ItinerariesContainers from "../../../components/itineraries/itinerariesContainers";
import FlightContainer from "../../flights/flight/flightContainer";
import EventGeneral from "../eventDetailsGeneral/eventDetailsContainer";
import { renderSidebarItem } from "./renderSidebarItem";
import MoreDetails from "../../../components/event/MoreDetails";
import { useHistory } from "react-router-dom";

export const EventDetail = (props) => {
  const {
    currentSideBarItem,
    handleSidebarItem,
    showEventsSidebar,
    eventsList,
    toggleSidebarEvents,
    addContact,
    addAttachment,
    getAttachmentsEvent,
    addItinerary,
    setVenue,
    toggleVenueModal,
    venueModal,
    attachments,
    contacts,
    hotels,
    grounds,
    venues,
    event,
    advancedDetails,
  } = props;

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });
  // const style = { display: showDrop ? "none" : "inline" };
  const history = useHistory();

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: true,
        style: { display: "inline" },
      };
    });
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  return (
    <>
      {advancedDetails ? (
        <div style={{ marginTop: "112px", height: "calc(100dvh - 112px)" }}>
          <MoreDetails
            event={event}
            containerClass="w-100 h-100 z-index-0"
            noteClass="card-body containerEditor w-100 z-index-0"
          />
        </div>
      ) : (
        <div
          className={`dashboard-screen event-detail-page`}
          style={{ marginTop: "112px" }}
          onDragEnterCapture={handleDragIn}
          // onDragOverCapture={handleDragOut}
        >
          <div className={`listSection`} id="listSection">
            <Element style={showDrop.style}>
              <DragAndDrop
                height="100vh"
                width="55%"
                top="-112px"
                handleDragOut={handleDragOut}
              >
                <div
                  style={{
                    height: "100vh",
                    minWidth: "55%",
                    position: "absolute",
                    top: 0,
                    zIndex: 999,
                  }}
                ></div>
              </DragAndDrop>
            </Element>
            <Element
              style={{ color: "white" }}
              name="general"
              className="element"
              id="general"
              // onClick={()=>handleNavigationSelect("general")}
            >
              <EventGeneral
                setVenue={setVenue}
                venuesList={venues}
                handleSidebarItem={handleSidebarItem}
                toggleVenueModal={toggleVenueModal}
                venueModal={venueModal}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              name="itineraries"
              className="element"
              id="itineraries"
              // onClick={()=>handleNavigationSelect("itineraries")}
            >
              <ItinerariesContainers
                addItinerary={addItinerary}
                handleSidebarItem={handleSidebarItem}
                scheduleMaster={props.sideBarItemData}
                sideBarItemData={props.sideBarItemData}
                deleteItinerary={props.deleteItinerary}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              name="flights"
              className="element"
              id="flights"
              // onClick={()=>handleNavigationSelect("flights")}
            >
              <FlightContainer {...props} />
            </Element>
            <Element
              style={{ color: "white" }}
              name="hotels"
              className="element"
              id="hotels"
              // onClick={()=>handleNavigationSelect("hotels")}
            >
              <HotelContainers
                deleteHotel={props.deleteHotel}
                handleSidebarItem={handleSidebarItem}
                hotels={hotels}
                addHotel={props.addHotel}
                {...props}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              name="transportation"
              className="element"
              id="transportation"
              // onClick={()=>handleNavigationSelect("ground")}
            >
              <GroundContainers
                deleteGround={props.deleteGround}
                addGround={props.addGround}
                sideBarItemData={props.sideBarItemData}
                handleSidebarItem={handleSidebarItem}
                grounds={grounds}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              name="contacts"
              className="element"
              id="contacts"
              // onClick={()=>handleNavigationSelect("contacts")}
            >
              <ContactsContainers
                event={event?.GUID}
                addContact={addContact}
                contacts={contacts}
                handleSidebarItem={handleSidebarItem}
                sideBarItemData={props.sideBarItemData}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              name="attachments"
              className="element"
              id="attachments"
              // onClick={()=>handleNavigationSelect("attachments")}
            >
              <AttachmentsContainers
                addAttachmentDropbox={props.addAttachmentDropbox}
                getAttachmentsEvent={getAttachmentsEvent}
                addAttachment={addAttachment}
                event={event?.GUID}
                attachments={attachments}
                handleSidebarItem={handleSidebarItem}
                sideBarItemData={props.sideBarItemData}
              />
            </Element>
            <Element
              style={{ color: "white" }}
              // name="attachments"
              // className="element"
              // id="attachments"
              // onClick={()=>handleNavigationSelect("attachments")}
            >
              <div
                className="w-100"
                style={{ height: "calc(100vh - 440px)" }}
              ></div>
            </Element>
          </div>
          <div className="modalSection shadow-sm">
            <Scrollbars>
              <div className="sidebar__item  p-0 px-2">
                {renderSidebarItem(currentSideBarItem, props)}
              </div>
            </Scrollbars>
            <ModalComponent
              toggle={toggleSidebarEvents}
              modal={showEventsSidebar}
              childeren={
                <SideEvents
                  fromGuestList={false}
                  toggle={toggleSidebarEvents}
                  events={eventsList}
                  selectedEvent={event}
                  renderBy={"navigation"}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
