import { ErrorMessage, Field, Form } from "formik";
import React from "react";
import { FormattedMessage } from 'react-intl';
import FormField from "../../form/formField";
// class RewardsFields extends Component {
const RewardsFields = (props) => {
    return (
      <div className="flex-1">
            <Form className="row">
              <div className="col-6">
                <p className="mb-2">
                  <strong><FormattedMessage
                    id="Airlines"
                    defaultMessage="Airlines"/></strong>
                </p>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="American Advantage"
                    defaultMessage="American Advantage"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="AmericanAdvantage"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="AmericanAdvantage"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Sky Miles"
                    defaultMessage="Sky Miles"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="SkyMilles"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="SkyMilles"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Mileage Plus"
                    defaultMessage="Mileage Plus"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="Mileageplus"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="Mileageplus"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="True Blue"
                    defaultMessage="True Blue"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="TrueBlue"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="TrueBlue"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="SW Rapid Rewards"
                    defaultMessage="SW Rapid Rewards"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="SWRapidRewards"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="SWRapidRewards"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Others"
                    defaultMessage="Others"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="AirlinesOther"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="AirlinesOther"
                    component="div"
                  />
                </FormField>
              </div>
              <div className="col-6">
                <p className="mb-2">
                  <strong><FormattedMessage
                    id="Hotels"
                    defaultMessage="Hotels"/></strong>
                </p>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Hilton Honors"
                    defaultMessage="Hilton Honors"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="HiltonHonors"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="HiltonHonors"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Stash Hotel Rewards"
                    defaultMessage="Stash Hotel Rewards"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="StashHotelRewards"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="StashHotelRewards"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="IHG Rewards Club"
                    defaultMessage="IHG Rewards Club"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="IHGRewardClub"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="IHGRewardClub"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Marriott Bonvoy"
                    defaultMessage="Marriott Bonvoy"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="MarriottBonvoy"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="MarriottBonvoy"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="World of Hyatt"
                    defaultMessage="World of Hyatt"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="WorldOfHyatt"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="WorldOfHyatt"
                    component="div"
                  />
                </FormField>
                <FormField fullWidth={true} label={<FormattedMessage
                    id="Others"
                    defaultMessage="Others"/>}>
                  <Field
                    className="form-control"
                    type="text"
                    name="HotelOther"
                    autoComplete="off"
                    readOnly={props.readOnly}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="HotelOther"
                    component="div"
                  />
                </FormField>
              </div>
            </Form>
         
      </div>
    );
  
}

export default RewardsFields;
