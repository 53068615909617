import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

export const AccordionComponent = ({ data }) => {
  const [isActive, setIsActive] = useState({ active: false, item: "" });
  return (
    <Container>
      <Row>
        <Col>
          <Accordion>
            {data.map((item, index) => {
              return (
                <Accordion.Item key={index} eventKey={index}>
                  <div
                    className="d-flex align-items-center justify-content-between border-bottom-1 my-2 px-3 py-1 rounded"
                    style={{ backgroundColor: "#E5E8EB" }}
                    onClick={(e) => {}}
                  >
                    {typeof item.title === "function" ? (
                      item.title()
                    ) : (
                      <span>{item.title}</span>
                    )}
                    <Accordion.Header
                      onClick={() => {
                        setIsActive((prev) => {
                          return {
                            active:
                              prev.item === item.GUID ? !prev.active : true,
                            item: item.GUID,
                          };
                        });
                        item.action && item.action();
                      }}
                    >
                      <div
                        className={
                          isActive.active && isActive.item === item.GUID
                            ? ""
                            : "isActiveMenu"
                        }
                      >
                        <FontAwesomeIcon
                          icon={faAngleUp}
                          color="black"
                          width={"50px"}
                          height={"50px"}
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body>
                    {typeof item.body === "function" ? (
                      item.body()
                    ) : (
                      <div className="alert alert-danger w-100 p-2 mt-0">
                        {item.body}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
