import * as constant from "./constants";
import api from "../../../common/api";

export function editVisibles(visiblesToEdit, success) {
  return async (dispatch) => {
    dispatch({
      type: constant.EDITING_VISIBLES,
    });
    try {
      await api.post("/Events/AddEditVisibleBy", visiblesToEdit);
      dispatch({
        type: constant.EDITED_VISIBLES,
        data: visiblesToEdit[0],
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_VISIBLES_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_VISIBLES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_VISIBLES: {
      return {
        ...state,
        loading: false,
        visibles: state.visibles.map((v) => {
          if (v.GUID === action.data.GUID) {
            v = action.data;
            return v;
          }
          return v;
        }),
      };
    }
    case constant.EDITING_VISIBLES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
