import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";
import uuid from "uuid";

export function exportContact(contact, events, success) {
  const url = "/ProductionVenueContact/AddEdit";
  return async (dispatch) => {
    try {
      const contacts = events.map((event) => ({
        ...contact,
        EventGUID: event,
        GUID: uuid.v4(),
      }));
      await db.table("contacts").bulkPut(contacts);
      success();
      await Promise.all(
        contacts.map(async (contact) => {
          await api.post(url, contact);
        })
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_CONTACT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EXPORTING_RESOURCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EXPORTED_RESOURCE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.EXPORTING_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
