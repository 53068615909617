import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Swal from "sweetalert2";
import api from "../../common/api";
import { getBasicInfo } from "../../utils";
import { changePasswordSchema } from "../../utils/validationsSchemas";
import FormField from "../form/formField";


const ChangePassword = (props)=>{
  const intl = useIntl();
  const [state, setState] = useState({
    showPassword: false,
    showPasswordNew: false,
    showPasswordConfirmNew: false,
    userInfo: null,
    isLoading: false,
  });

  useState(() => {
    setState(prev=>({
      ...prev,
      userInfo: getBasicInfo(),
    }));
  },[]);


  const togglePassword = () => {
    setState(prev=>({...prev, 
      showPassword: !state.showPassword,
    }));
  };

  const togglePasswordNew = () => {
    setState(prev=>({...prev, 
      showPasswordNew: !state.showPasswordNew,
    }));
  };

  const togglePasswordConfirmNew = () => {
    setState(prev=>({...prev, 
      showPasswordConfirmNew: !state.showPasswordConfirmNew,
    }));
  };

  const changePassword = async (oldPassword, newPassword, reset) => {
    try {
      let copy = state;
      copy.isLoading = !copy.isLoading;
      setState(prev=>({...prev, copy}));
      const RequestData = await api.post(`/user/CanLogin`, {
        Email: props.dataPassword.Email,
        Password: oldPassword,
      });

      if (RequestData.data.status === "ok") {
        const request = await api.get(
          `/user/PasswordChange?AccountID=` +
            props.dataPassword.GUID +
            `&NewPassword=` +
            newPassword
        );
        Swal.fire("Success", "Password changed successfully", "success");
      }
      copy.isLoading = !copy.isLoading;
      setState(copy);
    } catch (ex) {
      console.error(ex);
      Swal.fire("Error", "Current password is not correct", "error");
    }
  };

  return (
     <div className="card shadow-sm my-2">
        <div className="card-body p-1">
          <Formik
            initialValues={{
              Password: "",
              NewPassword: "",
              ConfirmNewPassword: "",
            }}
            validationSchema={changePasswordSchema}
          >
            {(props) => (
              <Form autoComplete="off" className="pt-1 row">
                <FormField
                  fullWidth={true}
                  label={intl.formatMessage({
                    id: "currentPassword", defaultMessage:"Current Password"
                  })}
                >
                  <Field
                    className="form-control"
                    type={state.showPassword ? "text" : "password"}
                    name="Password"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="Password"
                    component="div"
                  />
                  <p
                    style={{
                      position: "absolute",
                      bottom: 6,
                      right: 0,
                      // color: "#fff",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    onClick={() => togglePassword()}
                  >
                    <FormattedMessage id="show" defaultMessage="Show" />
                  </p>
                </FormField>

                <FormField
                  fullWidth={true}
                  label={intl.formatMessage({ id: "newPassword", defaultMessage:"New Password" })}
                >
                  <Field
                    className="form-control"
                    type={state.showPasswordNew ? "text" : "password"}
                    name="NewPassword"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="NewPassword"
                    component="div"
                  />
                  <p
                    style={{
                      position: "absolute",
                      bottom: 6,
                      right: 0,
                      // color: "#fff",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    onClick={() => togglePasswordNew()}
                  >
                    <FormattedMessage id="show" defaultMessage="Show" />
                  </p>
                </FormField>
                <FormField
                  fullWidth={true}
                  label={intl.formatMessage({
                    id: "confirmNewPassword", defaultMessage:"Confirm New Password"
                  })}
                >
                  <Field
                    className="form-control"
                    type={
                      state.showPasswordConfirmNew ? "text" : "password"
                    }
                    name="ConfirmNewPassword"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="ConfirmNewPassword"
                    component="div"
                  />
                  <p
                    style={{
                      position: "absolute",
                      bottom: 6,
                      right: 0,
                      // color: "#fff",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    onClick={() => togglePasswordConfirmNew()}
                  >
                    <FormattedMessage id="show" defaultMessage="Show" />
                  </p>
                </FormField>

                <div className="col-12 text-right">
                  <button
                    onClick={() =>
                      changePassword(
                        props.values.Password,
                        props.values.NewPassword,
                        props.resetForm
                      )
                    }
                    disabled={!props.isValid || props.changingPassword}
                    type="button"
                    className="btn btn-primary"
                  >
                    {props.changingPassword ? (
                      "Updating Password"
                    ) : (
                      <FormattedMessage id="save" defaultMessage="Save" />
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
  )
}

export default ChangePassword;
