import initialState from "./initialState";
import { reducer as selectEventReducer } from "./selectEvent";
import { reducer as acceptTicketsReducer } from "./acceptTickets";
import { reducer as rejectTicketsReducer } from "./rejectTickets";
import { reducer as updateRequestReducer } from "./updateRequest";
import { reducer as deleteRequestReducer } from "./deleteRequest";
import { reducer as guestListByUserReducer } from "./guestListByUser";
import { reducer as createRequestReducer } from "./createRequest";
import { reducer as editEventReducer } from "./editEvent";

const reducers = [
  selectEventReducer,
  acceptTicketsReducer,
  rejectTicketsReducer,
  updateRequestReducer,
  deleteRequestReducer,
  guestListByUserReducer,
  createRequestReducer,
  editEventReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
