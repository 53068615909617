import React from "react";
import "react-image-crop/dist/ReactCrop.css";
import { SimpleLoader } from "../../../components";
import PropTypes from "prop-types";
import { IMAGES, ROUTES } from "../../../constant";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createOrganizationSchema } from "../../../utils/validationsSchemas";
import FormField from "../../../components/form/formField";
import { ArrowLeft } from "mdi-material-ui";
import { useIntl, FormattedMessage } from "react-intl";

const CreateOrganization = (props) => {
  const {
    loading,
    handleChange,
    croppedImageUrl,
    createOrganization,
    isBackButton,
    toggleBack,
    toggleSelect,
  } = props;
  return (
    <div className="createOrganization">
      {isBackButton ? (
        <button
          className="btn btn-link"
          onClick={toggleBack}
          style={{
            position: "fixed",
            top: 25,
            left: 25,
            color: "#fff",
            zIndex: 5,
          }}
        >
          <ArrowLeft /> Back
        </button>
      ) : null}
      <div className="createOrganization__form bg-white shadow-lg">
        <div className="createOrganization__logo">
          <img src={IMAGES.LOGO_COLOR} alt="Plnify" />
        </div>
        <div className="row my-3">
          <div className="col-md-12 text-center">
            <h6>
              <FormattedMessage
                id="createWorkspace"
                defaultMessage="Create a Workspace"
              />
            </h6>
          </div>
        </div>
        <Formik
          validationSchema={createOrganizationSchema}
          initialValues={{ organizationName: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => createOrganization(values.organizationName)}
        >
          <Form autoComplete="off">
            <div className="row">
              <div className="col-md-12 justify-content-center">
                <div
                  style={{
                    marginBottom: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="createOrganization__thumbnail">
                    {croppedImageUrl ? (
                      <img
                        alt="Crop"
                        style={{ maxWidth: "100%" }}
                        className="createOrganization__selected avatarLogo"
                        src={croppedImageUrl}
                      />
                    ) : (
                      <img
                        src={IMAGES.ORGANIZATION_CREATE_PLACEHOLDER}
                        alt="Create Organization"
                        style={{height:"120px", width:"150px", objectFit: "none", objectPosition:"50% 35%"}}
                      />
                    )}
                    <input
                      style={{}}
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <p className="createOrganization__little font-weight-bold
                    " style={{bottom:"50px", margin:"0 auto"}}>
                      <FormattedMessage
                        id="uploadphoto"
                        defaultMessage="Upload your workspace photo"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <FormField fullWidth={true} label="Workspace Name">
                  <Field
                    autoComplete="off"
                    className="border px-3 py-2 w-100 rounded-lg mt-2 text-black-50"
                    type="text"
                    name="organizationName"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="organizationName"
                    component="div"
                  />
                </FormField>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 organization-submit">
                <div className="submit-button text-center">
                  {loading ? (
                    <SimpleLoader loading={loading} />
                  ) : (
                    <button
                      type="submit"
                      className="btn primary w-50"
                      id="reset"
                    >
                      <FormattedMessage id="create" defaultMessage="Create" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      {isBackButton ? null : (
        <div className="createOrganization__link ">
          <Link
            to={ROUTES.SELECT_ORGANIZATION}
            className="text-decoration-none font-weight-bold"
          >
            <i className="fa fa-arrow-left mr-1"></i>Go Back
          </Link>
        </div>
      )}
      {isBackButton ? (
        <div className="createOrganization__link d-none">
          <button
            onClick={() => toggleSelect()}
            type="button"
            className="btn btn-link"
          >
            See your workspaces
          </button>
        </div>
      ) : null}
    </div>
  );
};

CreateOrganization.propTypes = {
  organizationName: PropTypes.string,
  organizationNameError: PropTypes.string,
  onSelectFile: PropTypes.func,
  onInputFieldChange: PropTypes.func,
  loaded: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default CreateOrganization;
