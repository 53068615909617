import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useBetaFeature } from "../../hooks/useBetaFeature";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { addMultipleEventsHelper } from "../../utils/addMultipleEventsHelper";
import { getBasicInfo, getOrganization } from "../../utils";
import ical from "cal-parser";
import moment from "moment";
import db from "../../common/db";
import ImportModal from "../onboarding/ImportModal";
import { ModalComponent } from "../common";
import EventGroupModal from "../event/EventGroupModal";
import uuid from "uuid";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../page/calendar/redux/actions";

export default function CustomNavItem(props) {
  const { destination, icon, name, onClick, type, fontAwesomeIcon, mode } =
    props;

  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.calendarEvents);
  const eventGroups = useSelector((state) => state.calendar.eventGroups);
  const deletingGroup = useSelector((state) => state.calendar.deletingGroup);
  const creatingGroup = useSelector((state) => state.calendar.creatingGroup);

  const [isActive, setIsActive] = useState(false);

  const [showImportModal, setShowImportModal] = useState(false);
  const [multipleEvents, setMultipleEvents] = useState([]);
  const [state, setState] = useState({
    events: events || [],
    filtersOnEvent: null,
    filtersOnEventStatus: null,
    filtersOnEventGroups: null,
    filtersOnEventFrom: null,
    filtersOnEventTo: null,
    selectDate: new Date(),
    selectedDate: new Date(),
    eventsSelected: [],
    groupName: null,
    groupColor: null,
    groupImage: null,
    groupImageTarget: null,
    groupImageBlob: null,
    pageNumber: 1,
    showList: false,
    pageSize: 10,
    editEvent: false,
    currentEditIndex: null,
    currentOrgId: null,
    importEventModal: false,
    emailModal: false,
    groupModal: false,
    addEventModal: false,
    startingDayModal: false,
    filterModal: false,
    createGroupModal: false,
    currentDate: new Date(),
    selectedEvent: null,
    selectedEventVisibleBy: [],
    visibleByModal: false,
    exportMailModal: false,
    eventToExport: null,
    showEventHistory: false,
    displayMode: "calendar",
    loading: false,
    addMultiple: false,
    multipleEvents: [],
    showMoveEvent: false,
  });

  const toggleImportEvents = () => {
    setShowImportModal((prev) => !prev);
  };

  const toggleGroupModal = () => {
    setState((prev) => {
      return { ...prev, groupModal: !prev.groupModal };
    });
  };

  const setColorGroup = (value, from) => {
    setState((prev) => {
      return {
        ...prev,
        groupColor: from ?? value,
      };
    });
  };

  const setGroupImage = (event, type) => {
    const fileUploaded = type === "drop" ? event : event.target.files[0];
    setState((prev) => {
      return { ...prev, groupImageTarget: fileUploaded };
    });
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setState((prev) => {
          return {
            ...prev,
            groupImage: reader.result,
          };
        })
      );
      reader.readAsDataURL(fileUploaded);
    }
  };

  const setGroupName = (value) => {
    setState((prev) => {
      return {
        ...prev,
        groupName: value,
      };
    });
  };

  const toggleEvent = (event) => {
    let events = state.eventsSelected;

    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      setState((prev) => {
        return {
          ...prev,
          eventsSelected: filtered,
        };
      });
    } else {
      let newOnes = events.concat(event);
      setState((prev) => {
        return {
          ...prev,
          eventsSelected: newOnes,
        };
      });
    }
  };

  const toggleCreateGroupModal = () => {
    setState((prev) => {
      return {
        ...prev,
        createGroupModal: !prev.createGroupModal,
      };
    });
  };

  const resizeImage = (image, groupObject, eventsSelected) => {
    const file = image;
    const maxImageWidth = 1000; // Set your desired maximum width

    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxImageWidth) {
          newWidth = maxImageWidth;
          newHeight = (maxImageWidth / img.width) * img.height;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            dispatch(
              actions.createGroup(groupObject, eventsSelected, blob, () => {
                dispatch(actions.getGroups(getOrganization().GUID));
                toggleCreateGroupModal();
                setState((prev) => {
                  return {
                    ...prev,
                    groupName: "",
                    groupColor: "",
                    groupImage: "",
                    eventsSelected: [],
                  };
                });
              })
            );
          },
          "image/png",
          0.5
        ); // You can adjust the format and quality here
      };
    }
  };

  const createGroup = async () => {
    const currentOrganizationGUID = getOrganization().GUID;

    const {
      groupName,
      groupColor,
      eventsSelected,
      groupImage,
      groupImageTarget,
    } = state;

    const groupObject = {
      Name: groupName,
      Color: groupColor,
      GUID: uuid.v4(),
      OrganizationGUID: getOrganization().GUID,
      Created: new Date(),
    };

    if (groupImage) {
      resizeImage(groupImageTarget, groupObject, eventsSelected);
    } else {
      dispatch(
        actions.createGroup(groupObject, eventsSelected, "", () => {
          dispatch(actions.getGroups(currentOrganizationGUID));
          toggleCreateGroupModal();
          setState((prev) => {
            return {
              ...prev,
              groupName: "",
              groupColor: "",
              groupImage: "",
              eventsSelected: [],
            };
          });
        })
      );
    }
  };

  const deleteGroup = (group) => {
    const currentOrganizationGUID = getOrganization().GUID;
    dispatch(
      actions.deleteGroup(group, () =>
        dispatch(actions.getGroups(currentOrganizationGUID))
      )
    );
  };

  const editGroup = (group, events, blob, close, sucess) => {
    const currentOrganizationGUID = getOrganization().GUID;
    const basicInfoGUID = getBasicInfo().GUID;

    dispatch(
      actions.editGroup(group, events, blob, close, () => {
        dispatch(actions.getGroups(currentOrganizationGUID));
        dispatch(actions.getEvents(currentOrganizationGUID, basicInfoGUID));
      })
    );
  };

  const exportAsICS = () => {
    let user = getBasicInfo();
    let currentOrg = getOrganization();
    window.open(
      "https://app.plnify.com/api/organizations/OrganizationCalendar?OrganizationGUID=" +
        currentOrg.GUID +
        "&AccountGUID=" +
        user.GUID,
      "_blank"
    );
  };

  const addBunchEvents = async (events) => {
    const actualEvents = multipleEvents;
    const updatedEvents = events;

    await db.table("batchEvent").clear();
    await db
      .table("batchEvent")
      .bulkPut(updatedEvents)
      .then(() => setMultipleEvents(updatedEvents));
  };

  const parseAndPrepareIcsFile = async (file) => {
    const result = await file.text();
    const parsedResult = ical.parseString(result);
    const unreadyEvents = parsedResult.events.map((event) => {
      let location = event.location?.value.split(",");
      let [venue, street, city, state, zipcode, country] = location;

      return {
        Name: event.summary.value,
        Date: moment(event.dtstart.value).format("YYYY-MM-DDTHH:mm:ss"),
        GUID: event.uid.value,
        City: city || "",
        Country: country || "",
        State: state || "",
        Venue: venue || "",
      };
    });
    //debugger;

    const events = addMultipleEventsHelper(unreadyEvents);

    addBunchEvents(events);

    toggleImportEvents();

    // this.toggleMultiple();
    // this.toggleMiniImport();
    //this.toggleImportEvents();
  };

  const calendarOptions = [
    {
      name: (
        <div className="d-flex justify-content-between align-items-center">
          {/* <i className="fas fa-pen-to-square mr-1"></i> */}
          <FormattedMessage
            id="manageEventGroup"
            defaultMessage="Manage Event Groups"
          />
        </div>
      ),
      border: true,
      trigger: () => toggleGroupModal(),
    },
    {
      border: true,
      trigger: () => toggleImportEvents(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage
            id="importEvent"
            defaultMessage="Import Events (.ics)"
          />
        </div>
      ),
    },
    {
      border: true,
      trigger: () => exportAsICS(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage
            id="exportCalendar"
            defaultMessage="Export Calendar (.ics)"
          />
        </div>
      ),
    },
    {
      border: true,
      trigger: () => props.toggleExportCalendar(),
      name: (
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage
            id="exportCalendar"
            defaultMessage="Export Calendar"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const calendarAccordionBtn = document.querySelector(".accordion-button");

    if (calendarAccordionBtn) {
      calendarAccordionBtn.style.border = "none";
      calendarAccordionBtn.style.borderRadius = 0;
      calendarAccordionBtn.style.margin = 0;
      calendarAccordionBtn.style.marginLeft = "0px";
      calendarAccordionBtn.style.backgroundColor = "#2f4d6e";
    }
  }, []);

  return (
    <>
      <ModalComponent
        modal={state.groupModal}
        toggle={() => toggleGroupModal()}
        childeren={
          <EventGroupModal
            groupName={state.groupName}
            groupColor={state.groupColor}
            groupImage={state.groupImage}
            setColorGroup={setColorGroup}
            setGroupName={setGroupName}
            setGroupImage={setGroupImage}
            eventsSelected={state.eventsSelected}
            toggleEvent={toggleEvent}
            eventsList={events}
            createGroup={createGroup}
            creatingGroup={creatingGroup}
            eventGroups={eventGroups}
            deletingGroup={deletingGroup}
            deleteGroup={deleteGroup}
            toggle={() => toggleGroupModal()}
            toggleCreateGroupModal={() => toggleCreateGroupModal()}
            createGroupModal={state.createGroupModal}
            editGroup={editGroup}
          />
        }
      />

      <ModalComponent
        modal={showImportModal}
        toggle={() => toggleImportEvents()}
        childeren={
          <ImportModal
            toggle={() => toggleImportEvents()}
            parseAndPrepareIcsFile={parseAndPrepareIcsFile}
          />
        }
      />
      <Accordion defaultActiveKey={0}>
        <Accordion.Item>
          <Accordion.Header
            className="bg-none m-0 p-0"
            onClick={() => setIsActive((prev) => !prev)}
          >
            <div className="w-100 d-flex ">
              <NavLink
                className={`navItem px-2`}
                to={destination}
                activeClassName="active"
              >
                <i
                  className={`${fontAwesomeIcon}`}
                  style={{ fontSize: "15px" }}
                ></i>
                <p className="">{name}</p>
                <div
                  className={
                    !isActive
                      ? "isActiveMenu d-flex w-100"
                      : "d-flex w-100 justify-content-end"
                  }
                >
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    color="black"
                    width={"50px"}
                    height={"50px"}
                    style={{
                      fontSize: "20px",
                      fontWeight: 900,
                      color: "white",
                    }}
                  />
                </div>
              </NavLink>
            </div>
          </Accordion.Header>

          <Accordion.Body>
            <div className="text-left calendar-drop-down">
              {calendarOptions.map((o, index2) => (
                <div
                  key={index2}
                  style={{ fontSize: "11px", marginLeft: "2px" }}
                  className={`px-2 py-1 calendar-drop-down__options`}
                  onClick={() => {
                    setIsActive(index2);
                    o.trigger && o.trigger();
                    //   open();
                  }}
                >
                  {o.name}
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

CustomNavItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.object,
  destination: PropTypes.string,
  show: PropTypes.bool,
};
