import * as constant from "./constants";
import api from "../../../common/api";

export function getTemplates(organization) {
  return async (dispatch) => {
    dispatch({
      type: constant.GETTING_TEMPLATES,
    });
    try {
      const request = await api.get("/ScheduleTemplate/List", {
        params: {
          OrganizationGUID: organization,
        },
      });
      dispatch({
        type: constant.GET_TEMPLATES,
        data: request.data,
      });
    } catch (error) {
      dispatch({
        type: constant.GETTING_TEMPLATES_ERROR,
        data: error,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GETTING_TEMPLATES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.GET_TEMPLATES: {
      return {
        ...state,
        templates: action.data,
        loading: false,
      };
    }
    case constant.GETTING_TEMPLATES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
