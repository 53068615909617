import React, { useRef, useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { ToastNotificationSuccess } from "../common";
import { DeleteOutline, ShareVariant, PencilOutline } from "mdi-material-ui";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import SharingHub from "../common/sharingHub";
import { ModalComponent } from "..";
import { randomImage } from "../common/randomImage";
import AddContactComponent from "../../page/addContact/addContactComponent";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

const ContactDetail = (props) => {
  const intl = useIntl();
  let {
    loading,
    event,
    contact,
    addContact,
    deleteContact,
    contacts,
    handleSidebarItem,
    sideBarItemData,
  } = props;
  const [user, setUser] = useState(null);
  const emailRef = useRef(null);
  const [disabledInputs, setDisabledInputs] = useState(true);
  const [ShareModal, setShareModal] = useState(false);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let initials =
      (contact.Name && contact.Name.charAt(0).toUpperCase()) +
      (contact.LastName && contact.LastName.charAt(0));
    let img = randomImage(initials);
    setUser(img);
  }, []);

  function confirmDeleteContact(contact) {
    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to delete this contact?",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          deleteContact(contact, () => {
            ToastNotificationSuccess(1, "Contact deleted successfully");
            handleSidebarItem("EVENT_DETAILS", {});
          })
        );
      }
    });
  }
  const toggleShare = () => {
    setShareModal(!ShareModal);
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <div className="contactDetail__root">
      <ModalComponent
        toggle={toggleModal}
        modal={modal}
        childeren={
          <AddContactComponent
            event={event}
            addContact={addContact}
            toggle={toggleModal}
            loading={loading}
            sideBarItemData={sideBarItemData}
            handleSidebarItem={handleSidebarItem}
          />
        }
      />
      <ModalComponent
        modal={ShareModal}
        toggle={() => toggleShare()}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={contact}
            toggle={toggleShare}
            ParentSectionGUID={contact.EventGUID}
            Type="CONTACT"
            sectionGUID={contact.GUID}
          />
        }
      />

      <Formik
        initialValues={contact}
        onSubmit={(values) =>
          addContact(values, values.EventGUID, () =>
            ToastNotificationSuccess(1, "Contact Succesfully Updated")
          )
        }
        enableReinitialize={true}
      >
        {(props) => (
          <Form autoComplete="off">
            <div className="contactDetail__main">
              <div className="contactDetail__actions d-flex align-items-center justify-content-end">
                <h4 className="title">
                  <FormattedMessage id="contact" defaultMessage="Contact" />
                </h4>
                <button
                  type="button"
                  className="btn-primary btn btn-sm mr-2"
                  onClick={() =>
                    // setDisabledInputs((disabledInputs) => !disabledInputs)
                    toggleModal()
                  }
                >
                  <FormattedMessage id="Edit" defaultMessage="Edit" />
                </button>
                <button
                  type="button"
                  onClick={() => toggleShare()}
                  className="btn-sm btn btn-secondary shareBg mr-2"
                >
                  <i className="fa fa-share-alt"></i>
                </button>

                <button
                  type="button"
                  onClick={() => confirmDeleteContact(contact)}
                  className=" btn-sm btn-danger btn mr-4"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
              <div className="contactDetail__main-profile">
                <div className="contactDetail__profile">
                  <img src={user} className="img-fluid" alt="Contact Name" />
                </div>
                <div className="contactDetail__info">
                  <h3 className="mb-1">{`${contact.Name} ${contact.LastName}`}</h3>
                </div>
              </div>
            </div>

            <div className="contactDetail__info-container ">
              <div className="row justify-content-between">
                <div className="col">
                  <div className="contactDetail__input">
                    <label htmlFor="name">
                      <FormattedMessage
                        id="firstName.signUp"
                        defaultMessage="First Name"
                      />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="name"
                      className={`form-control ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="Name"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Name"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="contactDetail__input text-right">
                    <label htmlFor="lastName">
                      <FormattedMessage
                        id="lastName.signUp"
                        defaultMessage="Last Name"
                      />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="lastName"
                      className={`form-control text-right ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="LastName"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="LastName"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="contactDetail__input">
                    <label htmlFor="position">
                      <FormattedMessage
                        id="position"
                        defaultMessage="Position"
                      />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="position"
                      className={`form-control text-truncate ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="Position"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Position"
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="contactDetail__input text-right">
                    <label htmlFor="email">
                      <FormattedMessage id="email" defaultMessage="Email" />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="email"
                      className={`form-control text-right ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="Email"
                      autoComplete="off"
                    />
                    <input
                      ref={emailRef}
                      defaultValue={props.values.Email}
                      className="d-none"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Email"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-5 pl-0">
                  <div className="contactDetail__input">
                    <label htmlFor="mobilePhone">
                      <FormattedMessage id="mobile" defaultMessage="Mobile" />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="mobilePhone"
                      className={`form-control ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="MobilePhone"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="MobilePhone"
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div className="contactDetail__input text-right">
                    <label htmlFor="officePhone">
                      <FormattedMessage
                        id="officePhone"
                        defaultMessage="Office Phone"
                      />
                    </label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="officePhone"
                      className={`form-control text-right ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="OfficePhone"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="OfficePhone"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="contactDetail__input text-right">
                    <label htmlFor="OfficePhoneExtension">Ext.</label>
                    <Field
                      onBlur={() => props.handleSubmit()}
                      id="OfficePhoneExtension"
                      className={`form-control text-right ${
                        disabledInputs ? "disabled-input" : ""
                      }`}
                      disabled={disabledInputs}
                      type="text"
                      name="OfficePhoneExtension"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="OfficePhoneExtension"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactDetail;
