import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { IMAGES } from "../../constant";
import { getBasicInfo, getOrganization } from "../../utils";
import LogItem from "./LogItem";
import UpdatesItem from "./UpdatesItem";

import api from "../../common/api";

const Notifications = (props) => {
  const [state, setState] = useState({
    UI: {
      IsLoading: false,
      toggleActivityLog: false,
    },
    Notifications: [],
    ActivityLog: [],
  });
  const requestNotifications = async () => {
    try {
      const user = getBasicInfo();

      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: true },
      }));
      //mainState.NotificationsUI.set(ui);
      const request = await api.get(
        `/Notification/GetNotifications?AccountGUID=${user.GUID}`
      );
      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: false },
      }));

      if (request.data) {
        setState((prev) => ({
          ...prev,
          Notifications: request.data,
        }));
      }
    } catch (ex) {
      console.log("An error had happend", ex);
    }
  };

  const requestActivityLog = async () => {
    try {
      const currentOrganization = getOrganization();

      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: true },
      }));
      const request = await api.get(
        `/ActivityLog/GetLog?OrganizationGUID=${currentOrganization.GUID}`
      );
      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: false },
      }));

      if (request.data) {
        setState((prev) => ({
          ...prev,
          ActivityLog: request.data,
        }));
      }
    } catch (ex) {
      console.log("An error had happend", ex);
    }
  };

  const markAllAsReaded = async () => {
    try {
      const user = getBasicInfo();
      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: true },
      }));
      const request = await api.get(
        `/Notification/MarkAllAsReaded?AccountGUID=${user.GUID}`
      );
      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: false },
      }));
      if (request.data.status === "ok") {
        setState((prev) => ({
          ...prev,
          Notifications: request.data.response,
        }));
      }
    } catch (ex) {
      console.error("An error had happend", ex);
    }
  };

  const toggleUpdates = async (state, updates) => {
    setState((prev) => ({
      ...prev,
      UI: { ...prev.UI, toggleActivityLog: updates },
    }));
    if (!updates) {
      requestNotifications();
    } else {
      requestActivityLog();
    }
  };

  useEffect(() => {
    requestNotifications();
  }, []);

  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section move-modal">
          <div className="text-head">
            <div className="text-left">
              <h1 className="d-inline">
                <FormattedMessage
                  id="notificationCenter.not"
                  defaultMessage="Notification Center"
                />
              </h1>
              {state.UI.IsLoading ? (
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center">
              {!state.UI.toggleActivityLog ? (
                <button
                  className="notification-btn btn mr-2 btn-sm"
                  style={{ textTransform: "none" }}
                  type="button"
                  onClick={() => markAllAsReaded()}
                >
                  <FormattedMessage
                    id="markAllRead.not"
                    defaultMessage="Mark all as Read"
                  />
                </button>
              ) : null}

              <div className="mr-3 btn-group">
                <button
                  className={
                    !state.UI.toggleActivityLog
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-secondary"
                  }
                  onClick={() => toggleUpdates(state, false)}
                >
                  <FormattedMessage id="Updates.not" defaultMessage="Updates" />
                </button>
                <button
                  className={
                    state.UI.toggleActivityLog
                      ? " btn btn-sm btn-primary"
                      : " btn btn-sm btn-secondary"
                  }
                  onClick={() => toggleUpdates(state, true)}
                >
                  <FormattedMessage
                    id="activityLog.not"
                    defaultMessage="Activity Log"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="notifications__list p-0 px-0">
            {state.UI.IsLoading ? (
              <div>
                <div className="chat__item">
                  <Skeleton count={2} />
                </div>
                <div className="chat__item">
                  <Skeleton count={2} />
                </div>
                <div className="chat__item">
                  <Skeleton count={2} />
                </div>
                <div className="chat__item">
                  <Skeleton count={2} />
                </div>
              </div>
            ) : (
              <Scrollbars
                style={{ width: "100%", height: `calc(100vh - 57px)` }}
              >
                {state.UI.toggleActivityLog ? (
                  state.ActivityLog.length ? (
                    state.ActivityLog.map((activity, index) => {
                      let activityParsed = JSON.parse(JSON.stringify(activity));
                      return <LogItem key={index} item={activityParsed} />;
                    })
                  ) : (
                    <div className="text-center">
                      <h3 className="text-dark">No recent actions</h3>
                    </div>
                  )
                ) : state.Notifications.length ? (
                  state.Notifications.map((notification) => (
                    <UpdatesItem key={notification.GUID} item={notification} />
                  ))
                ) : (
                  <div className="text-center my-4">
                    <img
                      src={IMAGES.EMPTY_NOTIFICATIONS}
                      alt=""
                      style={{ maxWidth: "70%" }}
                    />
                    {/* <img src="/static/media/Notification.svg" className="emptyStateStyle mt-0" alt=""></img> */}
                    <p className="text-dark">No Notifications</p>
                  </div>
                )}
              </Scrollbars>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
