export const GROUPS_COLORS = [
  "#fbb6bc",
  "#f7cb91",
  "#ade1b0",
  "#99bcfb",
  "#dabceb",
];

export const NOTES_COLORS = [
  "#fbb6bc",
  "#f7cb91",
  "#ade1b0",
  "#99bcfb",
  "#dabceb",
  "#fad155",
];
