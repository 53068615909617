import initialState from "./initialState";

import { reducer as initEventReducer } from "./initEvents";
import { reducer as eventDetailReducer } from "./eventDetail";
import { reducer as getScheduleMastersReducer } from "./getScheduleMasters";
import { reducer as getAttachmentsEventReducer } from "./getAttachmentsEvent";
import { reducer as getContactsReducer } from "./getContacts";
import { reducer as getGroundsReducer } from "./getGrounds";
import { reducer as getHotelReducer } from "./getHotel";
import { reducer as getFlightsReducer } from "./getFlights";
import { reducer as getVenuesReducer } from "./getVenues";
import { reducer as emptyVenuesReducer } from "./emptyVenues";
import { reducer as editEventReducer } from "./editEvent";
import { reducer as venueDetailsReducer } from "./venueDetails";
import { reducer as addContactReducer } from "./addContact";
import { reducer as addAttachmentReducer } from "./addAttachment";
import { reducer as addItineraryReducer } from "./addItinerary";
import { reducer as setVenueReducer } from "./setVenue";
import { reducer as addCustomVenueReducer } from "./addCustomVenue";
import { reducer as editAttachmentReducer } from "./editAttachment";
import { reducer as AddActivityReducer } from "./addActivity";
import { reducer as deleteAttachmentReducer } from "./deleteAttachment";
import { reducer as deleteActivityReducer } from "./deleteActivity";
import { reducer as deleteContactReducer } from "./deleteContact";
import { reducer as deleteItineraryReducer } from "./deleteItinerary";
import { reducer as addGroundReducer } from "./addGround";
import { reducer as addHotelReducer } from "./addHotel";
import { reducer as addEventReducer } from "./addEvent";
import { reducer as deleteHotelReducer } from "./deleteHotel";
import { reducer as deleteGroundReducer } from "./deleteGround";
import { reducer as getVisiblesReducer } from "./getVisibles";
import { reducer as editVisiblesReducer } from "./editVisibles";
import { reducer as exportItineraryReducer } from "./exportItinerary";
import { reducer as addMutipleEventsReducer } from "./addMultipleEvents";
import { reducer as addAttachmentDropboxReducer } from "./addAttachmentDropbox";
import { reducer as editActivityReducer } from "./editActivity";
import { reducer as editVenueReducer } from "./editVenue";
import { reducer as cleanEventReducer } from "./cleanEvent";
import { reducer as addNoteReducer } from "./addNote";
import { reducer as getNotesReducer } from "./getNotes";
import { reducer as toggleLastSyncReducer } from "./toggleLastSync";
import { reducer as editItineraryReducer } from "./editItinerary";
import { reducer as deleteVenueReducer } from "./deleteVenue";
import { reducer as editVisiblesItinerariesReducer } from "./editVisiblesItineraries";
import { reducer as createTemplateReducer } from "./createTemplate";
import { reducer as createFromTemplateReducer } from "./createFromTemplate";
import { reducer as getTemplatesReducer } from "./getTemplates";
import { reducer as clearSchedulesReducer } from "./clearSchedules";
import { reducer as linkableEventsReducer } from "./linkableEvents";
import { reducer as editReservationsReducer } from "./editReservations";
import { reducer as editHotelReducer } from "./editHotel";
import { reducer as removeAllReservationsReducer } from "./removeAllReservations";
import { reducer as exportReservationsReducer } from "./exportReservations";
import { reducer as editGroundReducer } from "./editGround";
import { reducer as editPassengersReducer } from "./editPassengers";
import { reducer as addVehicleReducer } from "./addVehicle";
import { reducer as editVehicleReducer } from "./editVehicle";
import { reducer as deleteVehicleReducer } from "./deleteVehicle";
import { reducer as removeReservationReducer } from "./removeReservation";
import { reducer as addFlightReducer } from "./addFlight";
import { reducer as editFlightReducer } from "./editFlight";
import { reducer as deleteFlightReducer } from "./deleteFlight";
import { reducer as addFlightStaffReducer } from "./addFlightStaff";
import { reducer as deleteFlightStaffReducer } from "./deleteFlightStaff";
import { reducer as removeAllFlightStaffReducer } from "./removeAllFlightStaff";
import { reducer as udpateNoteReducer } from "./updateNote";
import { reducer as exportContactReducer } from "./exportContact";
import { reducer as addMeetingReducer } from "./addMeeting";
import { reducer as exportAttachmentsReucer } from "./exportAttachments";
import { reducer as deleteEventReducer } from "./deleteEvent";

const reducers = [
  initEventReducer,
  eventDetailReducer,
  getScheduleMastersReducer,
  getAttachmentsEventReducer,
  getContactsReducer,
  getGroundsReducer,
  getHotelReducer,
  getFlightsReducer,
  getVenuesReducer,
  emptyVenuesReducer,
  editEventReducer,
  venueDetailsReducer,
  addContactReducer,
  addAttachmentReducer,
  addItineraryReducer,
  setVenueReducer,
  addCustomVenueReducer,
  editAttachmentReducer,
  AddActivityReducer,
  deleteAttachmentReducer,
  deleteActivityReducer,
  deleteContactReducer,
  deleteItineraryReducer,
  addGroundReducer,
  addHotelReducer,
  addEventReducer,
  deleteHotelReducer,
  deleteGroundReducer,
  getVisiblesReducer,
  editVisiblesReducer,
  exportItineraryReducer,
  addMutipleEventsReducer,
  addAttachmentDropboxReducer,
  editActivityReducer,
  editVenueReducer,
  cleanEventReducer,
  addNoteReducer,
  getNotesReducer,
  toggleLastSyncReducer,
  editItineraryReducer,
  deleteVenueReducer,
  editVisiblesItinerariesReducer,
  createTemplateReducer,
  createFromTemplateReducer,
  getTemplatesReducer,
  clearSchedulesReducer,
  linkableEventsReducer,
  editReservationsReducer,
  editHotelReducer,
  removeAllReservationsReducer,
  exportReservationsReducer,
  editGroundReducer,
  editPassengersReducer,
  addVehicleReducer,
  editVehicleReducer,
  deleteVehicleReducer,
  removeReservationReducer,
  addFlightReducer,
  editFlightReducer,
  deleteFlightReducer,
  addFlightStaffReducer,
  deleteFlightStaffReducer,
  removeAllFlightStaffReducer,
  udpateNoteReducer,
  exportContactReducer,
  addMeetingReducer,
  exportAttachmentsReucer,
  deleteEventReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
