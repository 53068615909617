import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CheckBox, ToastNotificationSuccess } from "..";
import api from "../../common/api";

const ShareLinkModal = (props) => {
  const [state, setState] = useState({
    UI: {
      IsLoading: false,
      permission: 0,
    },
    SharingLink: null,
    SharingGUID: null,
    Model: null,
  });
  const urlRef = useRef(null);
  useEffect(() => {
    const fetchPreferences = async () => {
      const result = await api.get(
        `/Collab/DetailsForUser?SectionId=${props.sectionGUID}&AccountId=${props.AccountGUID}`
      );
      const response = result.data.response; // JSON.parse(result.data.response);

      if (response !== null) {
        setState((prev) => ({
          ...prev,
          Model: response,
        }));
        //  setDeleteable(true);
        //setLinkShared(response);
      }
    };
    fetchPreferences();
  }, []);

  const Save = async () => {
    let query = JSON.parse(JSON.stringify(state.Model));
    query.SectionType = props.sectionType;
    query.ParentSectionGUID = props.ParentSectionGUID;

    setState((prev) => ({
      ...prev,
      UI: { ...prev.UI, IsLoading: false },
    }));
    const result = await api.post(`/Collab/GenerateLink`, query);

    setState((prev) => ({
      ...prev,
      UI: { ...prev.UI, IsLoading: true },
    }));
    copyToClipboard();
    props.toggle();
  };
  function copyToClipboard() {
    navigator.clipboard.writeText(urlRef.current.value);
    ToastNotificationSuccess(1, "Copied to Clipboard");
  }
  return (
    <div className="vehicleModal__root" style={{ padding: 16 }}>
      <div className="text-head bg-transparent pl-0">
        <div className="text-left ">
          <h5 style={{ color: "black" }}>
            Invite others to collaborate with you
          </h5>
        </div>
      </div>
      <Formik>
        {(props) => (
          <Form>
            {state.Model !== null ? (
              <>
                <p className="text-dark mt-2">Anyone with the link can: </p>
                <div style={{ paddingLeft: 6 }} className="row">
                  <div className="col-md-3">
                    <CheckBox
                      header={"View"}
                      id={"view"}
                      value={
                        state.Model.PermissionType !== undefined &&
                        state.Model.PermissionType === 0
                      }
                      onChange={() => {
                        setState((prev) => ({
                          ...prev,
                          Model: { ...prev.Model, PermissionType: 0 },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <CheckBox
                      header={"Edit"}
                      id={"edit"}
                      value={
                        state.Model.PermissionType !== undefined &&
                        state.Model.PermissionType === 1
                      }
                      onChange={() => {
                        setState((prev) => ({
                          ...prev,
                          Model: { ...prev.Model, PermissionType: 1 },
                        }));
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {state.Model.GUID !== undefined && state.Model.GUID ? (
              <div className="my-3 form-group">
                <label className="text-dark" htmlFor="link">
                  Copy link to share
                </label>
                <input
                  onClick={() => copyToClipboard()}
                  onFocus={() => copyToClipboard()}
                  ref={urlRef}
                  id="link"
                  autoComplete="off"
                  type="text"
                  className="form-control mt-1"
                  value={
                    "https://web.plnify.com/#collab/" + state.Model.GUID
                  }
                />
              </div>
            ) : null}

            <div className="col-12 text-right">
              {state.UI.IsLoading ? (
                <div
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
              <button
                className="btn btn-primary btn-sm"
                disabled={state.UI.IsLoading === true}
                type="button"
                onClick={() => Save()}
              >
                <FormattedMessage id="done" defaultMessage="Done" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShareLinkModal;
