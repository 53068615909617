import React, { Component } from "react";
import { IMAGES } from "../../../constant";
import { DropDownComponent, ModalComponent } from "../../common";
import { DebounceInput } from "react-debounce-input";
import { Scrollbars } from "react-custom-scrollbars";
import { isEqual } from "../../../utils";
import UserItem from "../utils/UserItem";

function convertToUsable(user) {
  return {
    guid: user.guid,
    accountGuid: user.accountGuid,
    isAdmin: user.isAdmin,
    Account: {
      Name: user.accountGu.name,
      LastName: user.accountGu.lastName,
      Picture: user.accountGu.Picture,
    },
  };
}

class ConversationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversation: null,
      membersModal: false,
      accounts: [],
    };
  }

  componentDidMount() {
    this.setState({
      conversation: this.props.conversation,
    });
    this.setAccounts(this.props.conversation);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.conversation, this.props.conversation)) {
      this.setAccounts(this.props.conversation);
    }
  }

  toggleMembersModal = () => {
    this.setState({
      membersModal: !this.state.membersModal,
    });
  };

  setAccounts = (conversation) => {
    const accounts = conversation.conversationMember.map(
      (acc) => acc.accountGuid
    );
    this.setState({
      accounts,
    });
  };

  deleteUser = (account) => {
    const { conversation } = this.state;
    const { connection } = this.props;
    const member = conversation.conversationMember.find(
      (acc) => acc.accountGuid === account
    );
    member.deleted = new Date();

    const members = conversation.conversationMember.map((acc) => {
      if (acc.accountGuid === account) {
        acc = member;
        return acc;
      }

      return acc;
    });
    conversation.conversationMember = members;
    console.log(conversation.conversationMember);
    this.setState(
      {
        conversation,
      },
      () => connection.invoke("UpdateConversation", conversation)
    );
  };

  toggleAdmin = (account) => {
    const { conversation } = this.state;
    const { connection } = this.props;
    const member = conversation.conversationMember.find(
      (acc) => acc.accountGuid === account
    );
    member.isAdmin = !member.isAdmin;
    const members = conversation.conversationMember.map((acc) => {
      if (acc.accountGuid === account) {
        acc = member;
        return acc;
      }

      return acc;
    });
    members.concat(member);
    conversation.conversationMember = members;
    this.setState(
      {
        conversation,
      },
      () => connection.invoke("UpdateConversation", conversation)
    );
  };

  render() {
    const { connection, conversation, toggle } = this.props;
    //Validate the name
    let name = "";
    if (conversation.Name !== null) {
      name = conversation.Name;
    } else if (conversation.conversationMember.length === 2) {
      conversation.conversationMember.forEach((item) => {
        name += (name.length > 0 ? ", " : "") + item.accountGuid.name;
      });
    } else {
      name = "-";
    }

    return (
      <div className="">
        <ModalComponent
          modal={this.state.membersModal}
          toggle={this.toggleMembersModal}
          childeren={<div onClick={() => this.toggleMembersModal()}>klk</div>}
        />
        <div className="event-page" style={{ overflow: "visible" }}>
          <div className="event-expennd-section">
            <div className="text-head">
              <div className="text-left">
                <h1>{name}</h1>
              </div>
              <div>
                {/* <button
                  type="button"
                  title="Modify Members"
                  className="btn btn-sm btn-secondary"
                  onClick={() => this.toggleMembersModal()}
                >
                  <i className="fa fa-users" />
                </button> */}
                <button
                  type="button"
                  title="Modify Members"
                  className="btn btn-sm btn-secondary border-0"
                  onClick={() => toggle()}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
              {/* <div
                className="close-icon d-none d-none"
                onClick={() => toggle()}
              >
                <span className="close-round">
                  <img
                    src={IMAGES.CROSS_ICON}
                    alt="close"
                    width="16"
                    height="16"
                  />
                </span>
              </div> */}
            </div>
            <div className="event-inside">
              <div className="event-content">
                <div>
                  <label>Room</label>

                  <DebounceInput
                    debounceTimeout={400}
                    type="text"
                    placeholder="Room name"
                    className="form-control"
                    value={
                      this.state.conversation && this.state.conversation.name
                    }
                    onChange={(e) => {
                      const conversation = {
                        ...this.state.conversation,
                        name: e.target.value,
                      };
                      this.setState({ conversation });
                      this.props.connection.invoke("UpdateConversation", conversation);
                    }}
                  />
                </div>
                <div className="mt-2">
                  <label>Members</label>
                  <Scrollbars style={{ width: "100%", height: 250 }}>
                    <div className="">
                      {conversation.conversationMember
                        .sort((member) => (member.isAdmin ? -1 : 1))
                        .map((member) => (
                          <UserItem
                            key={member.guid}
                            user={convertToUsable(member)}
                            toggleAdmin={this.toggleAdmin}
                            deleteUser={this.deleteUser}
                          />
                        ))}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConversationDetail;
