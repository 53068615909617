import React, { Component } from "react";
import { connect } from "react-redux";
import ForgotPassword from "./forgotPassword";
import * as authActions from "../redux/actions";
import { bindActionCreators } from "redux";

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      showPassword: false,
    };
  }

  /**
   * @description this function is used to navigate to another page
   */
  onClickNavigation = (routes) => {
    this.props.history.push({ pathname: routes });
  };

  render() {
    let { toggle, authActions, auth, handleSuccess, loading } = this.props;
    return (
      <ForgotPassword
        {...this.state}
        toggle={toggle}
        loading={auth.logging}
        forgotPassword={authActions.forgotPassword}
        onClickNavigation={this.onClickNavigation}
        handleSuccess={handleSuccess}
        // loading={loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.auth.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators({ ...authActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
