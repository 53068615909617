import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

async function getLocalSchedules(event) {
  return await db.table("schedules").where({ EventGUID: event }).toArray();
}

export function getScheduleMasters(event) {
  return async (dispatch) => {
    dispatch({
      type: constant.GET_SCHEDULES_MASTER,
    });
    try {
      const localSchedules = await getLocalSchedules(event);
      const cleanedLocalSchedules = localSchedules.filter(
        (s) => s.Deleted === null
      );
      dispatch({
        type: constant.GET_SCHEDULES_MASTER_SUCCESS,
        data: cleanedLocalSchedules,
      });
      const request = await apiService.get("/ScheduleMaster/ScheduleForEvent", {
        params: { EventGUID: event },
      });
      
      let remoteSchedules = request.data.map((schedule) => ({
        ...schedule,
        Schedules: schedule.Schedules.filter((s) => s.Deleted === null).sort(
          (a, b) => {
            if (a.Date > b.Date) return 1;
            if (a.Date < b.Date) return -1;
          }
        ),
      }));

      const cleanedRemotesSchedules = remoteSchedules.filter(
        (s) => s.Deleted === null
      );

      await db.table("schedules").bulkPut(cleanedRemotesSchedules);
      dispatch({
        type: constant.GET_SCHEDULES_MASTER_SUCCESS,
        data: cleanedRemotesSchedules,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_SCHEDULES_MASTER_SUCCESS: {
      return {
        ...state,
        schedulesMasters: action.data,
      };
    }
    default:
      return state;
  }
}
