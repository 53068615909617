import { combineReducers } from "redux";
import authReducer from "../page/authentication/redux/reducer";
import eventReducer from "../page/event/redux/reducer";
import commonReducer from "../page/common/redux/reducer";
import organizationsReducer from "../page/organization/redux/reducer";
import ticketsReducer from "../page/ticketsAndPasses/redux/reducer";
import calendarReducer from "../page/calendar/redux/reducer";
import accountReducer from "../page/account/redux/reducer";
import todoReducer from "../page/todoPage/redux/reducer";
import meetReducer from "../page/meet/redux/reducer";
import emailExportReducer from "../components/common/EmailExport/redux/reducer";

const reducerMap = {
  auth: authReducer,
  event: eventReducer,
  common: commonReducer,
  organizations: organizationsReducer,
  tickets: ticketsReducer,
  calendar: calendarReducer,
  account: accountReducer,
  todo: todoReducer,
  meet: meetReducer,
  emailExport: emailExportReducer,
};

export default combineReducers(reducerMap);
