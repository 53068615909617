import * as CONSTANT from "./constants";
import api, { apiService } from "../../../common/api";

import db from "../../../common/db";
const sub_url = "organizations/details";

async function getCurrentOrganizationLocal(organizationGUID) {
  const localOrgs = await db
    .table("organization")
    .where({ GUID: organizationGUID })
    .first();
  //Filter deleted items 
  if (localOrgs !== undefined && localOrgs.Roles !== undefined && localOrgs.Roles.length > 0) {
    localOrgs.Roles = localOrgs.Roles.filter(x => x.Deleted === null);
  }
  return localOrgs;
}

export function getCurrentOrganization(organizationGuid, accountGuid) {
  return async (dispatch) => {
    dispatch({
      type: CONSTANT.GETTING_CURRENT_ORGANIZATION,
    });
    try {
      let local = await getCurrentOrganizationLocal(organizationGuid);
      if (local !== null && local !== undefined) {
        dispatch({
          type: CONSTANT.SET_CURRENT_ORGANIZATION,
          data: local,
        });
      }
      const request = await apiService.get(
        `${sub_url}?Guid=${organizationGuid}&AccountGuid=${accountGuid}`
      );
      const organization = request.data.response;
      // console.log("getting current organization ", organization);
      if (organization === "no_access") {
        return;
      }
      if (organization === "no_active") {
        return;
      }
      
      await db.table("organization").put(organization);
      local = await getCurrentOrganizationLocal(organizationGuid);
      dispatch({
        type: CONSTANT.SET_CURRENT_ORGANIZATION,
        data: local,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.GETTING_CURRENT_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case CONSTANT.SET_CURRENT_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: action.data,
        teams: action.data.Roles,
        loading: false,
      };
    }
    default:
      return state;
  }
}
