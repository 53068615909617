import * as constant from "./constants";

export function cleanEvent() {
  return async (dispatch) => {
    dispatch({
      type: constant.CLEAN_EVENT,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CLEAN_EVENT: {
      return {
        ...state,
        event: {},
        eventVenue: {},
        flights: [],
        grounds: [],
        hotels: [],
        attachments: [],
        schedulesMasters: [],
        visibles: [],
        venues: [],
      };
    }
    default:
      return state;
  }
}
