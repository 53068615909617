import * as constant from "./constants";
import api, { apiService } from "../../../common/api";

export function deleteAttachment(attachment, success) {
  const url = "/Attachment/AddEditForEventWithoutImage";
  return async (dispatch) => {
    dispatch({
      type: constant.DELETING_ATTACHMENT,
    });
    try {
      const request = await apiService.post(url, {
        ...attachment,
        Deleted: new Date(),
      });
      dispatch({
        type: constant.DELETED_ATTACHMENT_SUCCESS,
        data: request.data,
      });
      success();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.DELETING_ATTACHMENT_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_ATTACHMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        attachments: state.attachments.filter(
          (a) => a.GUID !== action.data.GUID
        ),
      };
    }
    case constant.DELETING_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
