import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { EVENT_TYPES } from "../../constant";
import api from "../../common/api";
import { DropDownList, DropDownVenue } from "..";
import moment from "moment";
import { Delete, AlertCircleOutline } from "mdi-material-ui";
import ReactTooltip from "react-tooltip";
import { useDebouncedCallback } from "use-debounce";
import { eventHorizontalSchema } from "../../utils/validationsSchemas";
import { useIntl, FormattedMessage } from "react-intl";
import apiWithPower from "../../common/apiWithPower";
const EventFormHorizontal = (props) => {
  const intl = useIntl();
  let { updateEvent, event, removeEvent } = props;
  const [venues, setVenues] = useState([]);
  const [cities, setCities] = useState([]);
  const [eventType, setEventType] = useState(props?.values?.EventType || []);
  const eventTypeRef = useRef(<Select></Select>);

  const searchVenues = useDebouncedCallback(async (query) => {
    const request = await api.get(
      `/Venues/Venues?Search=${query}`,
      "",
      null,
      true,
      "venues"
    );

    setVenues(request.data.Response);
  }, 300);

  const searchCities = useDebouncedCallback(async (query) => {
    let API = apiWithPower;
    const request = await api.get(
      `/venue/CityAutoComplete?Query=${query}`,
      "",
      null,
      true,
      "cities"
    );
    setCities(request.data);
  }, 300);

  const searchEventTypes = useDebouncedCallback((query) => {
    query = query.toLowerCase();
    let record = EVENT_TYPES.filter((x) =>
      x.toLowerCase(0).includes(query)
    ).map((item) => ({
      value: item,
      label: item,
    }));
    setEventType(record);
  }, 300);

  function setStateOrNotState(place, props) {
    if (place.split(",").length === 3) {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", place.split(",")[1].trim());
      props.setFieldValue("Country", place.split(",")[2].trim());
      updateEvent(props.values);
    } else {
      props.setFieldValue("City", place.split(",")[0].trim());
      props.setFieldValue("State", "");
      props.setFieldValue("Country", place.split(",")[1].trim());
      updateEvent(props.values);
    }
  }

  function resetVenue(props) {
    props.setFieldValue("VenueGUID", "");

    //props.setFieldValue("City", place.City);
    //props.setFieldValue("Country", place.Country);
    //props.setFieldValue("State", place.State);
    //props.setFieldValue("Latitude", place.Latitude);
    //props.setFieldValue("Longitude", place.Longitude);
    props.setFieldValue("Venue.Name", "");
    props.setFieldValue("VenueObj", null);
    updateEvent(props.values);
  }

  function setVenue(place, props) {
    if (place !== undefined && place !== null && place.Name !== undefined) {
      props.setFieldValue("VenueGUID", place.GUID);

      props.setFieldValue("City", place.City);
      props.setFieldValue("Country", place.Country);
      props.setFieldValue("State", place.State);
      props.setFieldValue("Latitude", place.Latitude);
      props.setFieldValue("Longitude", place.Longitude);
      props.setFieldValue("Venue.Name", place.Name);
      props.setFieldValue("VenueObj", place);
      updateEvent(props.values);
    }
  }

  return (
    <div className="eventForm__horizontal card my-1 mx-2 shadow-sm">
      <div className="card-body p-1 px-2">
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          initialValues={event}
        >
          {(props) => {
            const handleSelect = (e) => {
              if (e.target.value.includes("Travel Home")) {
                props.setFieldValue("Venue.Name", "From");
                props.setFieldValue("City", "");
                props.setFieldValue("State", "");
                props.setFieldValue("Country", "");
              } else if (e.target.value.includes("Day Off")) {
                props.setFieldValue("Venue.Name", "Where");
                props.setFieldValue("City", "");
                props.setFieldValue("State", "");
                props.setFieldValue("Country", "");
              } else if (
                e.target.value.includes("Travel") ||
                e.target.value.includes("Day Off")
              ) {
                props.setFieldValue("City", "");
                props.setFieldValue("State", "");
                props.setFieldValue("Country", "");
                props.setFieldValue("Venue.Name", "To");
              } else {
                props.setFieldValue("EventType", e.target.value);
                props.setFieldValue("City", props.initialValues.City);
                props.setFieldValue("State", props.initialValues.Country);
                props.setFieldValue("Country", props.initialValues.State);
                props.setFieldValue(
                  "Venue.Name",
                  props.initialValues.Venue.Name
                );
              }
            };

            console.log({ evenTypeProps: props, eventType });
            return (
              <Form autoComplete="off" className="eventHorizontal">
                <div className="d-flex align-items-center relative">
                  {props.values.Error && (
                    <>
                      <AlertCircleOutline
                        data-tip={props.values.ErrorMsg}
                        className="delete mr-1"
                      />
                      <ReactTooltip place={"top"} effect="solid" type="info" />
                    </>
                  )}

                  <div style={{ flex: 1, paddingRight: 8 }}>
                    <input
                      type="date"
                      className="form-control mt-2"
                      value={
                        props.values.Date
                          ? moment(props.values.Date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        try {
                          if (!e.target.value) {
                            return;
                          }

                          let parsed = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );

                          props.setFieldValue("Date", parsed);

                          updateEvent(props.values);
                        } catch (ex) {
                          console.error(ex);
                        }
                      }}
                    />
                    {/* <DatePicker
                      placeholderText="Select a date"
                      selected={moment(props.values.Date).toDate()}
                      dateFormat="MMM dd"
                      value={moment(props.values.Date).toDate()}
                      title={moment(props.values.Date).format("MMM DD, yyyy")}
                      onChange={(e) => {
                        props.setFieldValue("Date", e);
                        updateEvent({ ...props.values, Date: e });
                      }}
                    /> */}
                  </div>
                  <div style={{ flex: 3, paddingRight: 8 }}>
                    <Field
                      name="Name"
                      type="text"
                      className="form-control inline"
                      placeholder={intl.formatMessage({ id: "Event Name*" })}
                      onBlur={() => updateEvent(props.values)}
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Name"
                    />
                  </div>
                  <div
                    className="form-group horizontal mb-0 relative"
                    style={{ flex: 2, paddingRight: 8 }}
                  >
                    <select
                      className="form-control inline"
                      name="EventType"
                      onChange={handleSelect}
                      value={props.values.EventType}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "Event Type*" })}
                      </option>
                      {EVENT_TYPES.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>

                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="EventType"
                    />
                    <DropDownList
                      searchList={eventType}
                      setData={(id) => {
                        props.setFieldValue("EventType", eventType[id].value);
                        updateEvent(props.values);
                        setEventType([]);
                      }}
                      displayValue={"label"}
                    />
                  </div>
                  <div
                    style={{ flex: 2, paddingRight: 8 }}
                    className="relative"
                  >
                    {
                      <div className="input-group">
                        <Field
                          autoComplete="off"
                          name="Venue.Name"
                          type="text"
                          className="form-control inline text-truncate"
                          placeholder={intl.formatMessage({ id: "Venue" })}
                          onBlur={() => updateEvent(props.values)}
                          onChange={(e) => {
                            props.setFieldValue(
                              "Venue.Name",
                              e.currentTarget.value
                            );
                            if (e.currentTarget.value.length >= 3) {
                              searchVenues.callback(e.currentTarget.value);
                            } else {
                              setVenue({}, props);
                            }
                          }}
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Venue.Name"
                        />
                        <DropDownVenue
                          searchList={venues}
                          setData={(id) => {
                            setVenue(venues[id], props);
                            setVenues([]);
                          }}
                          displayValue={"Name"}
                        />
                        {props.initialValues.Venue.Name ? (
                          <div
                            className="input-group-append"
                            title="Remove venue"
                          >
                            <button
                              className="btn btn-sm"
                              type="button"
                              onClick={() => {
                                resetVenue(props);
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    }
                  </div>
                  <div
                    style={{ flex: 1, paddingRight: 8 }}
                    className="relative"
                  >
                    <Field
                      autoComplete="off"
                      name="City"
                      type="text"
                      className={"form-control inline city_" + event.GUID}
                      placeholder={intl.formatMessage({ id: "City*" })}
                      onBlur={() => updateEvent(props.values)}
                      onChange={(e) => {
                        props.setFieldValue("City", e.currentTarget.value);

                        if (e.currentTarget.value.length > 3) {
                          searchCities.callback(e.currentTarget.value);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="City"
                    />
                    <DropDownList
                      searchList={cities}
                      setData={(id) => {
                        setStateOrNotState(cities[id].Address, props);

                        setCities([]);
                      }}
                      displayValue={"Address"}
                    />
                  </div>
                  <div style={{ flex: 1, paddingRight: 8 }}>
                    <Field
                      autoComplete="off"
                      name="State"
                      type="text"
                      className="form-control inline"
                      placeholder={intl.formatMessage({ id: "state" })}
                      onBlur={() => updateEvent(props.values)}
                    />
                  </div>
                  <div style={{ flex: 1, paddingRight: 0 }}>
                    <Field
                      autoComplete="off"
                      name="Country"
                      type="text"
                      className="form-control inline"
                      placeholder={intl.formatMessage({ id: "Country*" })}
                      onBlur={() => updateEvent(props.values)}
                    />
                    <ErrorMessage
                      className="error-message"
                      component="div"
                      name="Country"
                    />
                  </div>
                  <div>
                    <Delete
                      className="delete"
                      onClick={() => removeEvent(event)}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EventFormHorizontal;
