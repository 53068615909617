import React, { useEffect, useState } from "react";

import UserTabs from "../organization/UserTabs";
import { ModalComponent } from ".";
import { IMAGES } from "../../constant";
import { randomImage } from "./randomImage";
import { FormattedMessage } from "react-intl";
const onErrorImage = (sender) => {
  sender.target.src = IMAGES.PROFILE_ICON;
};
const GeneralUserItem = (props) => {
  const [account, setAccount] = useState(props.account);
  const [showProfile, setShowProfile] = useState(false);
  useEffect(() => {
    if (props.fromSection === "ASSIGNMEMBERSFLIGHT") {
    }
    if (props.user) {
      setAccount(account);
    }
  }, []);

  if (!account) {
    return <></>;
  }

  let fullName = account.Name + " " + account.LastName;
  let shortName =
    account.Name +
    " " +
    (account.LastName != null ? account.LastName.charAt(0) + "." : "");
  let initials =
    (account.Name && account.Name.charAt(0).toUpperCase()) +
    (account.LastName && account.LastName.charAt(0));
  let img = randomImage(initials);

  return (
    <div className={`${account.Active ? "" : "inactive"} `}>
      <ModalComponent
        modal={showProfile}
        toggle={() => setShowProfile(!showProfile)}
        childeren={
          <UserTabs
            user={account}
            toggle={() => setShowProfile(!showProfile)}
          />
        }
      />
      <div className="row p-0 m-0 ">
        <div className="col-auto p-1">
          {account.Picture && account.Picture.includes("http") ? (
            <img
              src={account.Picture}
              alt={fullName}
              className="img-fluid avatar"
              onError={onErrorImage}
            />
          ) : account.Picture ? (
            <img
              src={`https://app.plnify.com/photos/${account.Picture}?w=75&h=75&mode=crop&autorotate=true`}
              alt={fullName}
              className="img-fluid avatar"
              onError={onErrorImage}
            />
          ) : (
            <img
              src={img}
              alt={fullName}
              onError={onErrorImage}
              className="img-fluid avatar"
            />
          )}
        </div>
        <div
          className="col p-0 pl-1 clickable text-truncate"
          onClick={
            props.onClick
              ? () => props.onClick()
              : () => setShowProfile((showProfile) => !showProfile)
          }
        >
          <h5
            className={
              (!props.showDetails ? "mt-1 " : "") + "title  text-truncate"
            }
            title={fullName + " - " + account.Email}
          >
            <div className="row ">
              {props.showShortName ? shortName : fullName}

              {props.user && !props.user.Accepted ? (
                <span className="badge badge-sm badge-warning mx-1">
                  <FormattedMessage id="pending" defaultMessage="Pending" />
                </span>
              ) : null}
              {props.user && !props.user.Active ? (
                <span className="badge badge-sm badge-secondary mx-1">
                  <FormattedMessage id="inactive" defaultMessage="Inactive" />
                </span>
              ) : null}
              {props.user &&
              props.user.AccountGUID ===
                props?.currentOrganization?.CreatorGUID ? (
                <span className="badge badge-sm badge-primary mx-1">
                  <FormattedMessage id="owner" defaultMessage="Owner" />
                </span>
              ) : null}
              {props.user && props.user.Tag ? (
                <span className="badge badge-sm badge-dark mx-1">
                  {props.user.Tag}
                </span>
              ) : null}
            </div>
          </h5>
          {props.showDetails ? (
            <small className="text-muted" style={{ lineHeight: "1" }}>
              {account.Email}{" "}
              {account.Ocupation ? " | " + account.Ocupation : ""}
            </small>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GeneralUserItem;
