import {
  ModalComponent,
  ToastNotificationSuccess,
  IframeGoogleDoc,
} from "../../components/common";

export const typeIconEquivalent = (item) => {
  let fontReference = "";
  switch (item.Type) {
    case "file":

    case "link":
      fontReference = fileIcon(item);
      break;

    case "folder":
      fontReference = "fa fa-folder";
      break;
  }
  return fontReference;
};

export const openInNewTab = (item) => {
  let extension = item.Path.split(".").pop();
  // https://docs.google.com/viewerng/viewer?url=https://app.plnify.com/files/Baezrosario+-Inicio.pptx

  console.log({ itemInTab: item });
  let path =
    item.Type === "link"
      ? item.Path
      : item.Type === "file" &&
        (extension.includes("pdf") ||
          extension.includes("doc") ||
          extension.includes("docx") ||
          extension.includes("xls") ||
          extension.includes("xlsx"))
      ? `${window.location.origin}/files/docs/`
      : item.Type === "file" &&
        (extension.includes("pptx") || extension.includes("ppt"))
      ? "https://docs.google.com/viewerng/viewer?url=" +
        "https://service.plnify.com/files/" +
        item.Path
      : "https://service.plnify.com/files/" + item.Path;
  const newWindow = window.open(path, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const copyToClipboard = (item) => {
  navigator.clipboard.writeText(
    item.Type === "link"
      ? item.Path
      : `https://service.plnify.com/files/${item.Path}`
  );
  ToastNotificationSuccess(1, "Copied to Clipboard");
};

export function formatFileSize(fileSizeInBytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  const terabyte = gigabyte * 1024;

  if (fileSizeInBytes < kilobyte) {
    return fileSizeInBytes + " B";
  } else if (fileSizeInBytes < megabyte) {
    return (fileSizeInBytes / kilobyte).toFixed(2) + " KB";
  } else if (fileSizeInBytes < gigabyte) {
    return (fileSizeInBytes / megabyte).toFixed(2) + " MB";
  } else if (fileSizeInBytes < terabyte) {
    return (fileSizeInBytes / gigabyte).toFixed(2) + " GB";
  } else {
    return (fileSizeInBytes / terabyte).toFixed(2) + " TB";
  }
}

export const fileIcon = (item) => {
  if (item.Path === undefined) {
    return "";
  }

  if (item.Type === "folder") {
    return "fa fa-folder";
  }
  //  console.log("Icon type" + item.Type,"item ", item)
  if (item.Type === "link") {
    if (item?.Path?.includes("dropbox")) {
      return "fab fa-dropbox dropboxColor";
    } else if (item?.Path?.includes("google")) {
      return "fab fa-google googleDriveColor";
    } else if (item?.Path?.includes("1drv")) {
      return "fas fa-cloud googleDriveColor";
    } else {
      return "fas fa-globe  text-secondary";
    }
  } else {
    if (item?.Path?.includes("dropbox")) {
      return "fab fa-dropbox dropboxColor";
    } else if (item?.Path?.includes("google")) {
      return "fab fa-google googleDriveColor";
    } else if (item?.Path?.includes("1drv")) {
      return "fas fa-cloud googleDriveColor";
    } else if (item?.Path?.includes("http")) {
      return "fas fa-globe text-secondary";
    }
    switch (item?.Path?.split(".")?.pop()?.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "far fa-file-image text-secondary ";
        break;
      case "pdf":
        return "far fa-file-pdf text-danger";
        break;
      case "doc":
      case "docx":
        return "far fa-file-word text-primary";
        break;
      case "xls":
      case "xlsx":
        return "far fa-file-excel text-success";
      case "ppt":
      case "pptx":
        return "far fa-file-powerpoint text-warning";
      default:
        return "far fa-file text-secondary";
    }
  }
};
