import React, { useEffect, useRef, useState, useMemo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { IMAGES, status } from "../../constant";
import { Event } from "./event";
import { getBasicInfo, getOrganization } from "../../utils";
import db from "../../common/db";
import { sortArray } from "../../common/helpers";
import { FormattedMessage } from "react-intl";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FilterModal from "../calendar/FilterModal";
import { ModalComponent } from "../common";
import ConversationDetail from "../meet/modal/ConversationDetail";
import { useLocation } from "react-router-dom";
import { apiService } from "../../common/api";

const SidebarEvents = (props) => {
  let {
    selectedEvent,
    toggle,
    fromGuestList,
    setCurrentEvent,
    toggleSideEvents,
    noClickable,
    isMobile,
    renderBy,
    iconsToRemove,
  } = props;
  //let today = new Date();
  let containerScroller = useRef();
  const [localEvents, setLocalEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCleanedEvents, setFilterCleanedEvents] = useState();
  const location = useLocation();
  const [isFirstRender, setIsFirstRender] = useState(true);

  let scrollNearTo = (records) => {
    let element = containerScroller.current;
    let elementEstimatedHeight = 58;
    //find first index of dates in the array of events
    if (records?.length === 0) {
      // console.log("we don't have the local events yet")
      return;
    }
    let theIndex = records?.findIndex((e) => e?.GUID === selectedEvent?.GUID);
    //console.log("we found the event", selectedEvent, "Position", theIndex, "on the collection", records)

    if (theIndex > 0) {
      //check if we have more than 10 events ahead
      if (records?.length - theIndex > 10) {
        element?.scrollTop(elementEstimatedHeight * (theIndex + 10));
      } else if (theIndex - 5 > 0) {
        //check if we have 10 events below so we can try to center
        element?.scrollTop(elementEstimatedHeight * (theIndex + 5));
      } else {
        element?.scrollTop(elementEstimatedHeight * theIndex);
      }
    }
  };

  const getEvents = async () => {
    const basicInfo = getBasicInfo();
    const currentOrganization = getBasicInfo().CurrentOrganizationGUID;
    const currentAccount = getBasicInfo().GUID;

    const res = await apiService.get(
      `/Events/listClean?OrganizationGUID=${currentOrganization}&AccountGUID=${currentAccount}`
    );

    console.log({ selectedEvent });
    return res.data.response.filter(
      (event) => event.GUID === selectedEvent?.GUID
    );
  };

  const bindData = async () => {
    //Load events locally
    let localEvents = props.events;
    if (localEvents?.length > 0) {
      localEvents = localEvents.filter(
        (x) => x.Deleted === null && x.Date !== null
      );
      localEvents = sortArray(localEvents, "Date");

      //See if we need to apply some filters here
      if (search.length > 0) {
        let simplifiedSearch = search.toLowerCase().trim();
        localEvents = localEvents.filter(
          (x) =>
            x.Name.toLowerCase().includes(simplifiedSearch) ||
            (x.City && x.City.toLowerCase().includes(simplifiedSearch)) ||
            (x.EventType &&
              x.EventType.toLowerCase().includes(simplifiedSearch))
        );
      }
      setLocalEvents(localEvents);
    }
  };

  useEffect(() => {
    bindData();
    setTimeout(() => {
      let se = document.getElementsByClassName("search")[0];
      if (se) {
        se.focus();
      }
    }, 1000);
  }, [props.events]);

  const filter = (e) => {
    setSearch(e);
    bindData();
  };

  function addOneYearToDate(inputDate) {
    if (!(inputDate instanceof Date)) {
      return "Invalid Date";
    }
    const newDate = new Date(inputDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    return newDate;
  }

  const [isFilter, setIsFilter] = useState(false);
  const [eventFilterState, setEventFilterState] = useState({
    from: new Date(),
    to: addOneYearToDate(new Date()),
    groups: [],
    statusData: [],
    groups: [],
    groupsData: [],
  });

  const toggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  const handleFilterByGroup = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        groups: data,
        groupsData: data?.map((group) => group.id),
      };
    });
  };

  function handleUpdateFilters(data, reset) {
    const filterData = {
      from: eventFilterState.from || new Date(),
      to: eventFilterState.to || addOneYearToDate(new Date()),
      statusData: eventFilterState.statusData,
      groupsData: eventFilterState.groupsData || [],
    };

    if (
      reset ||
      (filterData?.from === "" &&
        filterData?.to === "" &&
        (filterData?.groupsData?.length === 0 || !filterData.groupsData) &&
        (filterData?.statusData?.length === 0 || !filterData.statusData))
    ) {
      const currentDate = new Date();
      const twoYears = 2 * 365 * 24 * 60 * 60 * 1000; // Two years in milliseconds
      const minDate = new Date(currentDate - twoYears);
      const maxDate = addOneYearToDate(new Date());
      setEventFilterState({
        from: minDate,
        to: maxDate,
        groups: [],
        groupsData: [],
        status: [],
        statusData: [],
      });
      setIsFilter(false);
      // toggleFilterModal();
    } else {
      setIsFilter(true);
      const filteredEvents = filterEvents(localEvents, filterData);
      setFilterCleanedEvents(filteredEvents);
    }
  }

  const handleFilterFrom = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };

  const handleFilterTo = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        to: data,
      };
    });
  };

  const handleFilterByStatus = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        status: data,
        statusData: data,
      };
    });
  };

  const HandleEventFilters = () => {
    toggleFilterModal();
  };

  const eventGroupsAll = [];

  localEvents.forEach((event) => {
    if (
      !eventGroupsAll.includes(event?.EventGroup?.GUID) &&
      event?.EventGroup?.GUID
    ) {
      eventGroupsAll.push(event.EventGroup);
    }
  });

  const eventGroups = Array.from(
    eventGroupsAll.reduce((guidSet, obj) => {
      if (!guidSet.has(obj.GUID)) {
        guidSet.add(obj.GUID);
      }
      return guidSet;
    }, new Set()),
    (guid) => eventGroupsAll.find((obj) => obj.GUID === guid)
  );

  function filterEvents(localEvents, filterState) {
    const { from, to, statusData, groupsData } = filterState;

    const statusDataCleaned =
      statusData === null
        ? []
        : statusData?.map((data) => {
            const [name, value] = data.value;
            return value;
          });

    const minDate = new Date(0); // Minimum possible date (January 1, 1970)
    const maxDate = new Date(8640000000000000); // Maximum possible date (December 31, 275760)

    // Use the default values for from and to if not provided
    const fromDate = from === "" ? minDate : from;
    const toDate = to === "" ? maxDate : to;

    const filteredEvents = localEvents.filter((event) => {
      if (
        from === "" &&
        to === "" &&
        statusDataCleaned.length === 0 &&
        groupsData.length === 0
      ) {
        return true;
      }

      const eventDate = new Date(event.Date);
      // Check if the event's date is within the specified range

      const isDateInRange = eventDate >= fromDate && eventDate <= toDate;

      // Filter by status if statusData is provided
      const isStatusIncluded =
        statusDataCleaned?.length === 0 ||
        statusDataCleaned.includes(event.Status);

      // Filter by groups if groupsData is not an empty array
      const isGroupIncluded =
        groupsData?.length === 0 || groupsData?.includes(event?.EventGroupGUID);

      // Combine conditions (e.g., both group and status conditions must match)

      return isDateInRange && isStatusIncluded && isGroupIncluded;
    });

    return filteredEvents;
  }

  useEffect(() => {
    //This is done to filter by current day + 365days.
    handleUpdateFilters([], false);
    console.log({ localEvents });
    if (
      localEvents.length > 0 &&
      location.pathname.includes("calendar/") &&
      isFirstRender
    ) {
      const event = location.pathname.split("/")[2];
      setIsFirstRender(false);
      event && props?.setCurrentEvent && props?.setCurrentEvent(event);
    }
  }, [localEvents]);

  const eventToRender = isFilter ? filterCleanedEvents : localEvents;
  const eventsTypeToExclude = location.pathname.includes("/guestList")
    ? ["Travel Day", "Day Off", "Travel Home", "Travel/Day Off"]
    : [];

  return (
    <div className={props?.eventConatinerClass ?? "organization-transparent"}>
      <div className={props?.eventSecondContainerClass ?? "event-page"}>
        <ModalComponent
          modal={showFilterModal}
          toggle={toggleFilterModal}
          childeren={
            <FilterModal
              groups={eventGroups}
              updateFiltersGroups={handleFilterByGroup}
              updateFiltersStatus={handleFilterByStatus}
              updateFiltersFrom={handleFilterFrom}
              updateFiltersTo={handleFilterTo}
              filtersOnEventGroups={eventFilterState.groups}
              filtersOnEventStatus={eventFilterState.status}
              filtersOnEventFrom={eventFilterState.from}
              filtersOnEventTo={eventFilterState.to}
              // filtersOnEvent={filtersOnEvent}
              updateFilters={handleUpdateFilters}
              toggle={() => toggleFilterModal()}
              renderBy={renderBy}
            />
          }
        />
        <div
          className={
            props?.eventThirdContainerClass ??
            "event-expennd-section event-sidebar extendEventScreen"
          }
        >
          <div className={props?.eventHeadContainerClass ?? "text-head"}>
            <div className="text-left">
              <h1>
                <FormattedMessage id="events" defaultMessage="Events" />
              </h1>
            </div>
            {!props.hideSearch && (
              <div className="text-right formAlternativeStyle d-flex">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="search"
                  value={search}
                  onChange={(e) => filter(e.target.value)}
                ></input>
                {/* <button
                  className="btn btn-primary mr-1 border-0"
                  title="Filter Events"
                  onClick={HandleEventFilters}
                >
                  <i className={`fa ${isFilter ? "fa-sort" : "fa-filter"}`}></i>
                </button> */}
              </div>
            )}
            {props.hideSearch && (
              <div className="d-flex">
                <button
                  className="btn btn-primary mr-1 border-0"
                  title="Filter Events"
                  onClick={HandleEventFilters}
                >
                  <i className={`fa ${isFilter ? "fa-sort" : "fa-filter"}`}></i>
                </button>

                {!isMobile && (
                  <button
                    className="btn btn-secondary border-0"
                    onClick={() => toggleSideEvents()}
                    title="Hide Events"
                  >
                    <i className="fas fa-angle-left"></i>
                  </button>
                )}
              </div>
            )}

            <div className="close-icon d-none" onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <div className="px-0 pb-0">
            <div
              className={
                props.eventContent
                  ? "event-content " + props.eventContent
                  : "event-content"
              }
            >
              <div
                style={
                  props.scrollbarsStyles
                    ? props.scrollbarsStyles
                    : { height: "calc(100vh - 55px)" }
                }
              >
                <Scrollbars
                  style={{ width: "100%", height: "calc(100%)" }}
                  ref={containerScroller}
                >
                  {eventToRender && eventToRender.length === 0 ? (
                    <span className="badge badge-info d-flex w-100 p-2 justify-content-center text-white font-weight-bold">
                      No Events Found
                    </span>
                  ) : (
                    eventToRender
                      .filter(
                        (event) =>
                          !eventsTypeToExclude.includes(event.EventType)
                      )
                      .map((event) => {
                        // let theDate = new Date(event.Date);
                        let classforEvent =
                          selectedEvent && event.GUID === selectedEvent.GUID
                            ? "active"
                            : "";

                        return (
                          <div
                            className={
                              "card mx-2 my-1 shadow-sm " + classforEvent
                            }
                            key={event.GUID}
                            id={event.GUID}
                          >
                            <div
                              className={
                                props?.clickable
                                  ? "card-body p-0 clickable"
                                  : "card-body p-0 "
                              }
                            >
                              <Event
                                clickable
                                event={event}
                                toggle={toggle}
                                noClickable={noClickable}
                                iconsToRemove={
                                  iconsToRemove
                                    ? iconsToRemove
                                    : [
                                        "published",
                                        "private",
                                        "flights",
                                        "hotels",
                                        "itinearies",
                                        "tickets",
                                      ]
                                }
                                fromSidebar
                                fromGuestList={fromGuestList}
                                setCurrentEvent={setCurrentEvent}
                                renderBy={renderBy}
                              />
                            </div>
                          </div>
                        );
                      })
                  )}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarEvents;
