import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import { db, deleteDatabase } from "../../../common/db";

export function getListsOfTodo(event, user, forEvent) {
  const urlForTodos = forEvent
    ? `ToDo/ToDosForEvent?EventGUID=${event}`
    : `ToDo/ToDosForUser?AccountGUID=${user}`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GETTING_LISTS,
      });
      const localTodos = await db.table("todos").toArray();
      dispatch({
        type: constants.GET_LISTS,
        data: localTodos,
      });
      const request = await apiService.get(urlForTodos);
      const cleanedLists = request.data.Response.filter(
        (l) => l.Deleted === null
      );
      await db.table("todos").bulkPut(cleanedLists);
      const freshTodos = await db.table("todos").toArray();
      dispatch({
        type: constants.GET_LISTS,
        data: freshTodos,
      });
    } catch (error) {
      deleteDatabase();
      getListsOfTodo(event, user, forEvent);
      dispatch({
        type: constants.GETTING_LISTS_ERROR,
      });
      console.error(error);
    }
  };
}

export function getTodoDetails(ID, success) {
  const urlForTodos = `ToDo/ToDoDetails?TodoGUID=${ID}`;
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GETTING_DETAILS,
      });
      const localTodos = await db.table("todos").toArray();
      dispatch({
        type: constants.GET_DETAILS,
        data: localTodos,
      });
      const request = await apiService.get(urlForTodos);

      const cleanedLists = request.data.Response.filter(
        (l) => l.Deleted === null
      );

      await db.table("todos").bulkPut(cleanedLists);
      const freshTodos = await db.table("todos").toArray();
      dispatch({
        type: constants.GET_DETAILS,
        data: freshTodos,
      });

      success && success(ID, localTodos.EventGUID);
    } catch (error) {
      deleteDatabase();
      getTodoDetails(ID);
      dispatch({
        type: constants.GETTING_DETAILS_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.GETTING_LISTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.GET_LISTS: {
      const lists = action.data;
      return {
        ...state,
        loading: false,
        todos: lists,
      };
    }
    case constants.GETTING_LISTS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
