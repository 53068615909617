
import React, { useEffect } from 'react';
import {useIntl, FormattedMessage} from 'react-intl'

export default function AddEditLabel(props) {

  return (
    <div className="">

      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1 className="title"><FormattedMessage
                    id="label"
                    defaultMessage="Label"/></h1>
            </div>
          </div>
          <div className="body text-dark p-2">

            <div className="row">
              <div className="col-12">

                <input id="Name" type="text" className="form-control" value={props.label}

                  onChange={(e) => props.onChangeLabel(e.target.value)}
                />
              </div>

              <div className="col-12 text-right">

                <button className="btn btn-primary btn-sm mt-2"
                  type="button" onClick={() => props.toggle()}>
                 <FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  );
}
