import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { IMAGES } from "../../../constant";

import { useDispatch, useSelector } from "react-redux";

import api, { apiService } from "../../../common/api";
import {
  DropDownComponent,
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../../common";
import FlightPassenger from "../FlightPassenger";

import Swal from "sweetalert2";

import * as actions from "../../../page/event/redux/actions";
import AddEditFlights from "../../flights/AddEditFlights";
import AddEditPrivateFlights from "../../flights/AddEditPrivateFlights";

import { FormattedMessage, useIntl } from "react-intl";
import "react-quill/dist/quill.snow.css";
import db from "../../../common/db";
import { extractContentText, sortArray } from "../../../common/helpers";
import AddEditSingleAssigneeFlight from "../../flights/AddEditSingleAssigneeFlight";
import AssignMembersFlight from "../../flights/AssignMembersFlight";
import { PrivateFlight } from "./flightPrivate";
import DragAndDrop from "../../common/DragAndDrop";
import { fileIcon } from "../../../page/files/helper";
import uuid from "uuid";

const FlightDetails = (props) => {
  const event = useSelector((state) => state.event.event);
  const loading = useSelector((state) => state.event.loading);
  const teams = useSelector((state) => state.organizations.teams);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [state, setState] = useState({
    notes: false,
    editModal: false,
    singleEditModal: false,
    addNewsModal: false,
    single: null,
    searchedFlights: [],
    staffSelected: [],
    proposals: [],
    airports: [],
    airlines: [],
    status: "new",
    hotelImage: false,
    imgSrc: null,
    ArrivalNote: props.sideBarItemData && props.sideBarItemData.ArrivalNote,
    Record: props.sideBarItemData,
    SingleStaffSelected: null,
    showSingleStaff: false,
  });

  const loadLocalData = useCallback(async () => {
    try {
      if (
        props.sideBarItemData === undefined ||
        !props.sideBarItemData.Connection
      ) {
        return;
      }

      let local = await db
        .table("flights")
        .where({ Connection: props.sideBarItemData.Connection })
        .toArray();
      local = sortArray(local, "DepartureDate");

      setState((prev) => ({ ...prev, Record: local }));
    } catch (ex) {
      console.error("error loading local flight", ex);
    }
  }, [props]);

  useEffect(() => {
    (async () => {
      await loadLocalData();
    })();
  }, [loadLocalData]);

  const bindDataRemote = async () => {
    const request = await apiService.get(
      "/Flights/FlightsForEvent?EventGUID=" + event.GUID
    );
    if (request.data.Status === "ok") {
      await db.table("flights").bulkPut(request.data.Response);
    }
    loadLocalData();
  };

  const toggleNewMembersModal = async () => {
    setState((prev) => ({ ...prev, addNewsModal: !prev.addNewsModal }));

    if (!state.addNewsModal) {
      await bindDataRemote();
    }
  };

  const toggleEditModal = async (reload) => {
    setState((prev) => ({ ...prev, editModal: !state.editModal }));
    if (reload) {
      await bindDataRemote();
      props.handleSidebarItem("FLIGHT_LIST", props.sideBarItemData);
    }
  };

  // const toggleNotes = () => {
  //   setState((prev) => ({ ...prev, notes: !state.notes }));
  // };

  // const singleEdit = (flight) => {
  //   setState((prev) => ({ ...prev, single: flight }));
  // };

  // const searchFlights = async (query, util) => {
  //   setState((prev) => ({ ...prev, loadingProposals: true }));
  //   util && util();
  //   const flights = await api.get("/Flight/FlightDetailsExternal", {
  //     params: { Query: query },
  //   });

  //   const groups = flights.data.reduce((groups, flight) => {
  //     const date = flight.DepartureDate.split("T")[1];
  //     if (!groups[date]) {
  //       groups[date] = [];
  //     }
  //     groups[date].push(flight);
  //     return groups;
  //   }, {});

  //   const flightsArray = Object.keys(groups).map((date) => {
  //     return {
  //       date,
  //       flights: groups[date],
  //     };
  //   });

  //   setState((prev) => ({
  //     ...prev,
  //     loadingProposals: false,
  //     proposals: flightsArray,
  //   }));
  // };

  // const searchAirports = async (query) => {
  //   const airports = await api.get(`/Airports/GetAirports?Search=${query}`);
  //   setState((prev) => ({ ...prev, airports: airports.data }));
  // };

  // const searchAirlines = async (query) => {
  //   const airlines = await api.get(`/Airlines/GetAirlines?Search=${query}`);
  //   setState((prev) => ({ ...prev, airlines: airlines.data }));
  // };

  // const cleanAirlines = () => {
  //   setState((prev) => ({ ...prev, airlines: [] }));
  // };

  // const updateFlight = (flight) => {
  //   if (flight.GUID) {
  //     let stops = state.stops.map((stop) =>
  //       stop.GUID === flight.GUID ? (stop = flight) : stop
  //     );
  //     setState((prev) => ({ ...prev, stops: stops }));
  //   }
  // };

  // const editFlight = (flight) => {
  //   dispatch(
  //     actions.editFlight(flight, () => {
  //       ToastNotificationSuccess(1, "Flight updated");
  //       dispatch(actions.getFlights(props.event.GUID));
  //     })
  //   );
  // };

  const toggleHotelImage = (image) => {
    setState((prev) => ({
      ...prev,
      hotelImage: !state.hotelImage,
      imgSrc: image,
    }));
  };

  const addReservations = (staffs) => {
    setState((prev) => ({ ...prev, staffSelected: staffs }));
  };

  const cleanReservations = () => {
    setState((prev) => ({ ...prev, staffSelected: [] }));
  };

  const updatePassengers = () => {
    const { sideBarItemData, event } = props;
    dispatch(
      actions.addFlightStaff(state.staffSelected, sideBarItemData, () => {
        ToastNotificationSuccess(1, "Updated staffs");
        dispatch(actions.getFlights(event.GUID));
        toggleNewMembersModal();
      })
    );
  };

  const updatePassenger = (reservation) => {
    //debugger;
    let staffs = state.staffSelected.map((staff) => {
      if (staff.StaffGUID === reservation.StaffGUID) {
        staff = { ...staff, ...reservation };
        return staff;
      }
      return staff;
    });
    setState((prev) => ({ ...prev, staffSelected: staffs }));
  };

  const deleteAllStaffs = () => {
    const { event, sideBarItemData } = props;

    Swal.fire({
      text: intl.formatMessage({
        id: "Are you sure you want to remove all the members",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.removeAllFlightStaff(sideBarItemData.FlightStaffs, () => {
            dispatch(actions.getFlights(event.GUID));
            // bindDataRemote();
          })
        );
        ToastNotificationSuccess(1, "Deleted all staffs");
      }
    });
  };

  const editStaff = (staff, reload) => {
    setState((prev) => ({
      ...prev,
      SingleStaffSelected: staff,
      showSingleStaff: !prev.showSingleStaff,
    }));

    if (reload) {
      loadLocalData();
      bindDataRemote();
    }
  };

  const deleteStaff = (staff) => {
    Swal.fire({
      title: intl.formatMessage({ id: "Are you sure" }),
      text: intl.formatMessage({
        id: "You won't be able to revert this",
      }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteFlightStaff(staff, () => {
            loadLocalData();
            dispatch(actions.getFlights(event.GUID));
            ToastNotificationSuccess(1, "Deleted");
          })
        );
      }
    });
  };

  const returnConnectionTime = (arrival, departure) => {
    try {
      const arrivalDate = moment(arrival);
      const departureDate = moment(departure);
      const difference = departureDate.diff(arrivalDate);
      const duration = moment.duration(difference);
      let result = `${
        duration.hours() > 0 ? duration.hours() : 0
      }hours ${duration.minutes()}minutes`;
      console.log(
        "connection between",
        "arrival",
        arrival,
        "departure",
        departure,
        result
      );
      result = result.replace("-", "");
      return result;
    } catch (ex) {
      console.error(ex);
      return "-";
    }
  };

  const { sideBarItemData, handleSidebarItem } = props;

  const { editModal, hotelImage, imgSrc } = state;

  const stops =
    state.Record.length === 0 && sideBarItemData
      ? [sideBarItemData]
      : state.Record;
  let staffs = [];
  staffs =
    Array.isArray(stops) && stops.length > 0
      ? stops[0].FlightStaffs &&
        stops[0].FlightStaffs.filter((f) => f.Deleted === null)
      : stops.FlightStaffs;

  let firstStop;
  firstStop = stops[0];

  let departure = moment().add(-2, "hours");
  console.log("the stops for the flight", stops, state.Record);

  const [showDrop, setShowDrop] = useState({
    show: false,
    style: { display: "none" },
  });

  const [flightFiles, setFlightFiles] = useState([]);

  const loadFlightFiles = async () => {
    const response = await apiService.get(
      `/Attachment/Attachments?EventGUID=${sideBarItemData.EventGUID}&FlightGUID=${sideBarItemData.GUID}`
    );
    setFlightFiles(
      response.data.filter((x) => x.Deleted === null && !x.HotelGUID)
    );
  };

  useEffect(() => {
    loadFlightFiles();
  }, [props.sideBarItemData]);

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDrop((prev) => {
      return {
        show: false,
        style: { display: "none" },
      };
    });
  };

  const onChangeDrop = async (files) => {
    try {
      let theFiles = Array.from(files);
      const response = await Promise.all(
        theFiles?.map(async (item) => {
          let newItem = {
            guid: uuid.v4(),
            name: item.name,
            note: "",
            path: item.name,
            eventGUID: state.Record.EventGUID,
            FlightGUID: state.Record.GUID,
          };

          const bodyData = new FormData();
          bodyData.append("path", item);
          const responseFile = await apiService.post(`/File/upload/`, bodyData);

          const requestGeneral = await apiService.post(
            "/attachment/AddEditForEventWithoutImage",
            {
              ...newItem,
              Path: responseFile.data.Response,
            }
          );
        })
      );

      if (response.length > 0) {
        loadFlightFiles();
        ToastNotificationSuccess(1, "Files uploaded successfully");
      }
    } catch (ex) {
      console.log("Error", ex);
      ToastNotificationError(1, "Error uploading files");
    }
  };



  function confirmDeleteAttachment(attachment) {
    Swal.fire({
      text:
        intl.formatMessage({ id: "Are you sure you want to delete" }) +
        attachment.Name +
        "?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.deleteAttachment(attachment, () => {
            ToastNotificationSuccess(1, "Attachment deleted");
            loadFlightFiles();
          })
        );
      }
    });
  }

  if (!state.Record) {
    return <></>;
  }

  if (!stops || stops.length === 0) {
    return <div className="row mx-0"></div>;
  }

  return (
    <div className="row mx-0">
      <ModalComponent
        modal={hotelImage}
        toggle={() => toggleHotelImage()}
        childeren={
          <div className="image-main">
            <div className="d-flex align-items-center mb-2">
              <div
                className="close-icon d-none"
                onClick={() => toggleHotelImage()}
              >
                <div className="close-bg">
                  <img
                    src={IMAGES.CROSS_ICON}
                    width="16"
                    height="16"
                    alt="close"
                  />
                </div>
              </div>
            </div>

            <img src={imgSrc} alt="Venue" className="fluid-img img-thumbnail" />
          </div>
        }
      />
      <ModalComponent
        modal={editModal}
        toggle={toggleEditModal}
        childeren={
          firstStop !== undefined && firstStop.PrivateFlight ? (
            <AddEditPrivateFlights
              //connection={sideBarItemData.Connection}
              event={event}
              IsEditing={true}
              toggle={toggleEditModal}
              selectedFlights={stops}
              handleSidebarItem={handleSidebarItem}
            />
          ) : (
            <AddEditFlights
              //connection={sideBarItemData.Connection}
              event={event}
              IsEditing={true}
              toggle={toggleEditModal}
              selectedFlights={stops}
              handleSidebarItem={handleSidebarItem}
            />
          )
        }
      />

      <ModalComponent
        toggle={toggleNewMembersModal}
        modal={state.addNewsModal}
        childeren={
          <AssignMembersFlight
            flight={sideBarItemData}
            toggle={toggleNewMembersModal}
            teams={teams}
            eventStaffs={teams}
            arrayToBeSelected={state.staffSelected}
            edit={true}
            cleanReservations={cleanReservations}
            addReservations={addReservations}
            updatePassenger={updatePassenger}
            updatePassengers={updatePassengers}
            loading={loading}
            stops={stops}
            handleSidebarItem={handleSidebarItem}
            setEventDetailsState={props.setEventDetailsState}
            // currentPassengers={sideBarItemData.FlightStaffs}
            // addStaff={addStaff}
            // toggleMember={this.toggleMember}
          />
        }
      />

      <ModalComponent
        toggle={editStaff}
        modal={state.showSingleStaff}
        childeren={
          <AddEditSingleAssigneeFlight
            model={state.SingleStaffSelected}
            toggle={editStaff}
          />
        }
      />
      <div className="col-10">
        <h5 className="contentTitle">
          <FormattedMessage id="flightInfo" defaultMessage="Flight Info" />
        </h5>
      </div>
      <div className="col-2 text-right">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => toggleEditModal()}
        >
          <FormattedMessage id="Edit" defaultMessage="Edit" />
        </button>
      </div>
      <div className="col-8 mb-2">
        <div className="hotelDetail__picture ">
          <img
            //  onClick={() => this.toggleHotelImage(stops[0].Image)}
            src={IMAGES.FLIGHT_PLACEHOLDER}
            alt="Flight Route"
            className="img-fluid fluid-img"
          />
        </div>
      </div>
      <div className="col-4 mb-2 text-right pt-4">
        {stops.length > 1 ? (
          <DropDownComponent
            data={stops.map((item) => {
              return {
                delete: false,
                name: item.AirlineFlight,
                trigger: () => {
                  window.open(
                    `https://flightaware.com/live/flight/${item.AirlineFlight}`,
                    "FlightAwareWindow",
                    "width=1280,height=840"
                  );
                },
              };
            })}
            Custom={
              <button
                // onClick={
                //   (e) => {
                //     e.preventDefault();
                //     window.open(
                //       `https://flightaware.com/live/flight/${stops[0].AirlineFlight}`,
                //       "FlightAwareWindow",
                //       "width=1280,height=840"
                //     );
                //   }

                // }
                disabled={
                  stops[0] && moment(stops[0].DepartureDate).isBefore(departure)
                }
                className="btn btn-sm btn-success mt-4"
                title="Track in Flight Aware"
              >
                Track Flight Status
              </button>
            }
          />
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `https://flightaware.com/live/flight/${stops[0].AirlineFlight}`,
                "FlightAwareWindow",
                "width=1280,height=840"
              );
            }}
            disabled={
              stops[0] && moment(stops[0].DepartureDate).isBefore(departure)
            }
            className="btn btn-sm btn-success mt-4"
            title="Track in Flight Aware"
          >
            <FormattedMessage
              id="trackFlightStatus"
              defaultMessage="Track Flight Status"
            />
          </button>
        )}
      </div>
      {stops[0] && stops[0].PrivateFlight ? (
        <PrivateFlight stops={stops} />
      ) : (
        <>
          <div className="col-6 text-dark mt-2 ">
            <p className="font-weight-bold">
              <FormattedMessage
                id="airlineAndFlight"
                defaultMessage="Airline/Flight #"
              />
            </p>

            <p>
              {stops[0] && stops[0].Airline}{" "}
              {stops[0] && stops[0].AirlineFlight}
            </p>
          </div>

          <div className="col-6 text-right text-dark mt-2 ">
            {stops[0] &&
              !stops[0].PrivateFlight &&
              stops[0].OperatedBy &&
              stops[0].OperatedBy !== stops[0].Airline && (
                <>
                  <small>
                    <FormattedMessage
                      id="Operated By"
                      defaultMessage="Operated By"
                    />
                  </small>
                  <p className="text-muted">{stops[0].OperatedBy}</p>
                </>
              )}
          </div>
          <div className="col-12">
            <hr className="my-1 py-0" />
          </div>
          <div className="col-5 text-dark">
            <p
              className="font-weight-bold text-truncate"
              title={stops[0] && stops[0].FromLongName}
            >
              {stops[0] && stops[0].PrivateFlight && stops[0].FromLongName}
              {stops[0] && !stops[0].PrivateFlight && stops[0].From}
              <br />
              <span className="text-truncate">
                {stops[0] && stops[0].FromAddress}
              </span>
            </p>

            <p>
              {moment(stops[0] && stops[0].DepartureDate).format(
                "ddd, MMM DD, h:mm A"
              )}
            </p>
          </div>
          <div className="col-2 text-center ">
            <img src={IMAGES.GROUP} alt="dot" height="19" />
            {stops.length > 1 && (
              <p className="stop">{stops.length - 1 + " stops"}</p>
            )}
          </div>
          <div className="col-5 text-right text-dark">
            <p
              className="font-weight-bold text-truncate"
              title={
                stops[stops.length - 1] && stops[stops.length - 1].ToLongName
              }
            >
              {stops[stops.length - 1] &&
                stops[stops.length - 1].PrivateFlight &&
                stops[stops.length - 1].ToLongName}
              {stops[stops.length - 1] &&
                !stops[stops.length - 1].PrivateFlight &&
                stops[stops.length - 1].To}
              <br />
              <span className="text-truncate">
                {stops[stops.length - 1] && stops[stops.length - 1].ToAddress}
              </span>
            </p>
            <p>
              {moment(
                stops[stops.length - 1] && stops[stops.length - 1].ArrivalDate
              ).format("ddd, MMM DD, h:mm A")}
            </p>
          </div>
        </>
      )}
      <div className="col-12">
        <hr className="my-1 py-0" />
      </div>
      {stops.length === 1 && stops[0].Duration ? (
        <div className="col-12 text-dark ">
          <p className="font-weight-bold">
            <FormattedMessage
              id="estimatedDuration"
              defaultMessage="Estimated Duration"
            />
          </p>
          <p>{stops[0] && stops[0].Duration}</p>
        </div>
      ) : null}
      <div className="col-12">
        {stops[0] && !stops[0].PrivateFlight && stops.length > 1 && (
          <div className="flightDetail__journey">
            <h6 className="title mt-4 mb-2">
              <FormattedMessage
                id="flightDetails"
                defaultMessage="Flight Details"
              />
            </h6>

            {stops.map((f, i) => (
              <div key={i}>
                <div key={f.GUID} className="card shadow-sm my-1 text-dark">
                  <div className="card-body px-0 py-1 row mx-0">
                    <div className="col-8 text-truncate">
                      <p>
                        {f.AirlineFlight} {f.From} - {f.To} |{" "}
                        {moment(f.DepartureDate).format("h:mm a")} -{" "}
                        {moment(f.ArrivalDate).format("h:mm a")}
                      </p>
                    </div>
                    <div className="col-4 text-truncate pl-0 ml-0">
                      <p>
                        <FormattedMessage
                          id="duration"
                          defaultMessage="Duration"
                        />
                        : {f.Duration}
                      </p>
                    </div>
                  </div>
                </div>
                {i === stops.length - 1 ? null : (
                  <div className="col-12 text-center">
                    <small className="my-1 stop ">
                      <FormattedMessage
                        id="estimatedConnectionTime"
                        defaultMessage="Estimated connection time"
                      />
                      :{" "}
                      {returnConnectionTime(
                        stops[i].ArrivalDate,
                        stops[i + 1].DepartureDate
                      )}
                    </small>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className=" col-auto mt-2 ">
        <h6 className="f-13">
          {staffs && staffs.length}{" "}
          <FormattedMessage
            id="memberAssigned"
            defaultMessage="Member(s) Assigned"
          />
        </h6>
      </div>
      <div className="col-4  text-truncated d-none">
        <small className="text-muted smallest">Record Locator</small>
      </div>
      <div className="col-auto ml-auto text-right">
        <button
          disabled={staffs && !staffs.length > 0}
          onClick={() => deleteAllStaffs()}
          type="button"
          className="btn btn-danger btn-sm mr-2"
        >
          <FormattedMessage id="removeAll" defaultMessage="Remove All" />
        </button>
        <button
          onClick={() => toggleNewMembersModal()}
          type="button"
          title={staffs && staffs.length > 0 ? "Update Members" : "Add Members"}
          className="btn btn-sm btn-primary text-light"
        >
          <i className="fa fa-plus"></i>
          <FormattedMessage
            id="assignMembers"
            defaultMessage="Assign Members"
          />
        </button>
      </div>
      {staffs && staffs.length > 0
        ? sortArray(staffs, "FLIGHTMEMBER").map((f, index) => (
            <div className="col-12" key={index}>
              <FlightPassenger
                key={f.GUID}
                user={f}
                deleteStaff={deleteStaff}
                editStaff={editStaff}
                onClick={toggleNewMembersModal}
              />
            </div>
          ))
        : null}

      <div className="col-12">
        <hr />
        <h6 className="f-13">Files</h6>
        <div className="row">
          <DragAndDrop
            handleDragOut={handleDragOut}
            handleDropCalendarEvent={onChangeDrop}
            fontSize={18}
            className="col-12 px-0  mx-0"
          >
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                margin: "20px 0",
                height: "200px",
                width: "100%",
                border: "2px dashed #ccc",
                color: "black",
              }}
            >
              <i className="text-primary fa fa-cloud-upload-alt text-white fa-5x"></i>
              Drop your flight related files here
            </div>
          </DragAndDrop>
        </div>
        <div className="d-flex flex-column">
          {flightFiles?.map((file) => (
            <div className="row bg-white shadow-sm rounded p-2 my-1">
              <div className="col-9 my-1  d-flex align-items-center px-0">
                <div className="d-inline-block mr-2 ">
                  <i className={fileIcon(file) + " fa-2x"}></i>
                  {/* {file.Path && file.Path.includes("dropbox")
            ? iconPreview(
                file.Path && file.Path.split("?").pop(),
                file
              )
            : iconPreview(
                file.Path && file.Path.split(".").pop(),
                file
              )} */}
                </div>
                <div className="text-truncate">
                  <h6 className="text-truncate">
                    <a
                      target="_blank"
                      className="clean-link"
                      href={
                        (file.Path && file.Path?.includes("dropbox")) ||
                        file.Path?.includes("google") ||
                        file.Path?.includes("http") ||
                        file.Path?.includes("1drv")
                          ? `${file.Path}`
                          : `https://service.plnify.com/files/${file.Path}`
                      }
                    >
                      {file.Name ? file.Name : file.Path}
                    </a>
                  </h6>

                  {file.Note && (
                    <div className="text-truncate subTitle">
                      {extractContentText(file.Note)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2 ml-auto ">
                <DropDownComponent
                  className="float-right"
                  data={[
                    // {
                    //   name: "Copy to Other Events",
                    //   trigger: () => toggleExport(),
                    // },
                    // {
                    //   name: "Move to another event",
                    //   trigger: () => toggleMove(),
                    //   border: true,
                    // },
                    {
                      delete: true,
                      name: (
                        <FormattedMessage id="delete" defaultMessage="Delete" />
                      ),
                      trigger: () => confirmDeleteAttachment(file),
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default FlightDetails;
