import React, {  useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { IMAGES } from "../../../constant";
import { Scrollbars } from "react-custom-scrollbars";
import { RadioboxBlank, RadioboxMarked, ArrowLeft } from "mdi-material-ui";
import {
  checkIfStaffExistsC,
} from "../../../utils";
import { SimpleLoader } from "../../../components/common";
import { FormattedMessage} from 'react-intl'

const AddVisibiltity = (props) => {
  let {
    toggle,
    eventStaffs,
    arrayToBeSelected,
    donable,
    currentReservations,
    addPassengers,
    edit,
    toggleMember,
    loading,
    toggleSelectAll,
  } = props;
  useEffect(() => {
    if (edit) {
      let reservations = currentReservations.map((s) => ({
        ...s,
        Staff: null,
      }));
      addPassengers(reservations);
    }
  }, []);

  const getEventList = () => {
    return (
      eventStaffs &&
      eventStaffs
        .filter((t) => t.Name !== "Non-App User")
        .filter((t) => t.Name !== "Invited Users")
        .filter((t) => t.Staffs.length > 0)
        .map((teamData) => {
          return (
            <div className="admin-container">
              <div className="visible-detail">
                <div className="head-section">
                  <p>{teamData.Name}</p>
                </div>
                <div className="head-section">
                  <p onClick={() => toggleSelectAll(teamData.Staffs)}>
                  <FormattedMessage
                  id="SELECT ALL"
                  defaultMessage="SELECT ALL"/>
                  </p>
                </div>
              </div>
              {getStaff(teamData.Staffs)}
            </div>
          );
        })
    );
  };

  const getStaff = (staffs) => {
    return (
      staffs.length > 0 &&
      staffs
        .filter((s) => s.Deleted === null)
        .map((staff) => {
          return (
            <div
              key={staff.GUID}
              className={`${
                checkIfStaffExistsC(staff.GUID, arrayToBeSelected)
                  ? "selected"
                  : ""
              } visible-info-main`}
            >
              <div className="admin-detail" onClick={() => toggleMember(staff)}>
                {checkIfStaffExistsC(staff.GUID, arrayToBeSelected) ? (
                  <RadioboxMarked />
                ) : (
                  <RadioboxBlank />
                )}

                <div className="admin-img">
                  {staff.Account.Picture ? (
                    <img
                      src={`https://app.plnify.com/photos/${staff.Account.Picture}?w=400&h=400&mode=pad&autorotate=true`}
                      width="30"
                      height="30"
                      alt="adminimg"
                    />
                  ) : (
                    <img
                      src={`https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${
                        staff.Account.Name && staff.Account.Name.charAt(0)
                      }${
                        staff.Account.LastName &&
                        staff.Account.LastName.charAt(0)
                      }`}
                      width="30"
                      height="30"
                      alt="adminimg"
                    />
                  )}
                </div>
                <div className="admin-info">
                  <p>
                    {staff.Account.Name && staff.Account.Name}{" "}
                    {staff.Account.LastName && staff.Account.LastName}
                  </p>
                  <p className="email">
                    {staff.Account.Email}
                    {staff.Account.Ocupation ? (
                      <>
                        <span className="line"></span> {staff.Account.Ocupation}
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          );
        })
    );
  };

  return (
    <div className="visible-page">
      <div className="visible-expand-section">
        <div className="visible-head">
          {donable ? null : (
            <div className="back-icon" onClick={() => toggle()}>
              <ArrowLeft />
            </div>
          )}

          <div className="text-left">
            <h1 className="text-capitalize">{props.title}</h1>
            <div className="flight-text">
              <p><FormattedMessage
                  id="Who can view the event"
                  defaultMessage="Who can view the eventf"/></p>
            </div>
          </div>
          {donable ? (
            <div className="close-icon d-none" onClick={() => toggle(true)}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          ) : null}
        </div>
        <div className="visible-main"></div>
        <Scrollbars style={{ height: "calc(100vh - 155px)" }}>
          {getEventList()}
        </Scrollbars>

        <div className="submit-button">
          {loading ? (
            <SimpleLoader loading={loading} />
          ) : donable ? (
            <button
              type="button"
              onClick={() => props.submit()}
              className="btn primary"
            >
              {arrayToBeSelected.length > 0 ? <FormattedMessage
                    id="done"
                    defaultMessage="Done"/> : <FormattedMessage
                    id="Skip"
                    defaultMessage="Skip"/>}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => props.submit()}
              className="btn primary"
            >
<FormattedMessage
                    id="done"
                    defaultMessage="Done"/>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddVisibiltity;
