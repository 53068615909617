import React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { editTicketSchema } from "../../utils/validationsSchemas";
import { SimpleLoader } from "../common";
import { IMAGES } from "../../constant";
import Select from "react-select";
import { useIntl, FormattedMessage } from "react-intl";

const EditTicketForm = ({
  updateTicket,
  toggle,
  mode,
  ticket,
  loading,
  teams,
  createTicket,
}) => {
  const intl = useIntl();
  const pickupLocations = [
    { value: "null", label: "-" },
    { value: "WillCall", label: "Will Call" },
    { value: "In-Hand", label: "In-Hand" },
  ];
  if (!ticket) {
    return <></>;
  }

  ticket.NoteConfirmed = ticket.ConfirmedNote || ticket.NoteConfirmed;

  console.log({ ticket });
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1>
                {ticket.PassType === "Ticket"
                  ? "Tickets Request"
                  : "Pass Request"}
              </h1>
              <p>
                {ticket.RequestByName} {ticket.GuestLastName}
              </p>
            </div>
            <div className="close-icon d-none " onClick={() => toggle()}>
              <span className="close-round">
                <img
                  src={IMAGES.CROSS_ICON}
                  alt="close"
                  width="16"
                  height="16"
                />
              </span>
            </div>
          </div>
          <Formik
            initialValues={{ ...ticket, guestLastName: ticket.GuestLastName }}
            onSubmit={(values) => {
              const updatedTicket = {
                ...values,
                NoteConfirmed: values.NoteConfirmed,
                ConfirmedNote: values.NoteConfirmed,
              };
              !mode
                ? createTicket(values, true)
                : updateTicket(updatedTicket, true);
            }}
            validationSchema={editTicketSchema}
          >
            {(props) => (
              <Form className="mt-3">
                <div className="event-inside">
                  <div className="event-content">
                    <div className="row">
                      <div className="col-md-12 px-0 mb-2">
                        <label>
                          <FormattedMessage
                            id="Requested by"
                            defaultMessage="Requested by"
                          />
                        </label>
                        <Select
                          placeholder={
                            props.values.RequestAccountGUID
                              ? teams.find(
                                  (m) =>
                                    m.value === props.values.RequestAccountGUID
                                )?.label
                              : "Select"
                          }
                          className="border-bottom no-outline"
                          isSearchable
                          options={teams}
                          onChange={(value) => {
                            props.setFieldValue(
                              "RequestAccountGUID",
                              value.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <FormField
                        additionalClasses="px-0 pr-2"
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Guest First Name"
                            defaultMessage="Guest First Name"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="GuestNames"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="GuestNames"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Guest Last Name"
                            defaultMessage="Guest Last Name"
                          />
                        }
                        additionalClasses="px-0 pl-2"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="GuestLastName"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="GuestLastName"
                          component="div"
                        />
                      </FormField>
                    </div>
                    <div className="row">
                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pr-2"
                        label={
                          <FormattedMessage
                            id="Type of Request"
                            defaultMessage="Type of Request"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="PassType"
                          disabled
                        />
                        <ErrorMessage
                          className="error-message"
                          name="PassType"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        additionalClasses="px-0 pl-2"
                        label={
                          <FormattedMessage
                            id="Amount"
                            defaultMessage="Amount"
                          />
                        }
                      >
                        <Field
                          className="form-control"
                          type="number"
                          min="0"
                          name="Passes"
                        />
                        <ErrorMessage
                          className="error-message"
                          component="div"
                          name="Passes"
                        />
                      </FormField>
                    </div>
                    <div className="row ">
                      <FormField
                        additionalClasses="px-0 pr-2"
                        fullWidth={true}
                        label={
                          <FormattedMessage
                            id="Pickup Location"
                            defaultMessage="Pickup Location"
                          />
                        }
                      >
                        <Select
                          className="border-bottom mb-2 no-outline"
                          placeholder={
                            props.values.PickupLocation
                              ? pickupLocations.find(
                                  (l) => l.value === props.values.PickupLocation
                                )
                                ? pickupLocations.find(
                                    (l) =>
                                      l.value === props.values.PickupLocation
                                  ).label
                                : "-"
                              : "-"
                          }
                          isSearchable
                          options={pickupLocations}
                          onChange={(value) => {
                            props.setFieldValue("Pickup", value.value);
                          }}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="Pickup"
                          component="div"
                        />
                      </FormField>
                      <FormField
                        fullWidth={false}
                        label={
                          <FormattedMessage
                            id="Notify To"
                            defaultMessage="Notify To"
                          />
                        }
                        additionalClasses="px-0 pl-2 d-none"
                      >
                        <Field
                          className="form-control"
                          type="text"
                          name="NotifyTo"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="NotifyTo"
                          component="div"
                        />
                      </FormField>
                    </div>
                    {ticket.PassType !== "Ticket" ? null : (
                      <div className="row shrinker">
                        <FormField
                          additionalClasses="col-md-4 px-0 pl-2"
                          fullWidth={false}
                          label={
                            <FormattedMessage
                              id="Section"
                              defaultMessage="Section"
                            />
                          }
                        >
                          <Field
                            className="form-control"
                            type="text"
                            name="Section"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Section"
                            component="div"
                          />
                        </FormField>
                        <FormField
                          additionalClasses="col-md-4 px-0 pl-2"
                          fullWidth={false}
                          label={
                            <FormattedMessage id="row" defaultMessage="Row" />
                          }
                        >
                          <Field
                            className="form-control"
                            type="text"
                            name="Row"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Row"
                            component="div"
                          />
                        </FormField>
                        <FormField
                          additionalClasses="col-md-4 px-0 pl-2"
                          fullWidth={false}
                          label={
                            <FormattedMessage id="Seat" defaultMessage="Seat" />
                          }
                        >
                          <Field
                            className="form-control"
                            type="text"
                            name="Seat"
                          />
                          <ErrorMessage
                            className="error-message"
                            name="Seat"
                            component="div"
                          />
                        </FormField>
                      </div>
                    )}

                    <FormField fullWidth={true} label="Note">
                      <Field
                        className="form-control"
                        as="textarea"
                        name={
                          <FormattedMessage id="Note" defaultMessage="Note" />
                        }
                      />
                      <ErrorMessage
                        className="error-message"
                        component="div"
                        name="Note"
                      />
                    </FormField>
                    <FormField fullWidth={true} label="Admin Note">
                      <Field
                        className="form-control"
                        as="textarea"
                        name="NoteConfirmed"
                      />
                      <ErrorMessage
                        className="error-message"
                        component="div"
                        name="NoteConfirmed"
                      />
                    </FormField>
                  </div>
                  <div className="py-3">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <div className="row px-0">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn primary block font-weight-bold  btn-block"
                          >
                            <FormattedMessage id="done" defaultMessage="Done" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditTicketForm;
