import * as CONSTANT from "./constants";
import api from "../../../common/api";

const sub_url = `/venue/countryautocomplete?query=`;

export function searchCountry(query) {
  return async dispatch => {
    dispatch({
      type: CONSTANT.GETTING_ALL_COUNTRIES
    });
    try {
      const request = await api.get(`${sub_url}${query}`);
      dispatch({
        type: CONSTANT.GET_ALL_COUNTRIES,
        data: request.data
      });
    } catch (error) {
      dispatch({
        type: CONSTANT.GETTING_ALL_CITIES_ERROR
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONSTANT.GETTING_ALL_COUNTRIES: {
      return {
        ...state,
        countries: []
      };
    }
    case CONSTANT.GET_ALL_COUNTRIES: {
      return {
        ...state,
        countries: action.data
      };
    }
    case CONSTANT.GETTING_ALL_CITIES_ERROR: {
      return {
        ...state,
        countries: [{ name: action.error }]
      };
    }
    default:
      return state;
  }
}
