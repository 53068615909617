export const GETTING_STRIPE_CUSTOMERID = "GETTING_STRIPE_CUSTOMERID";
export const GET_STRIPE_CUSTOMERID = "GET_STRIPE_CUSTOMERID";
export const GET_STRIPE_CUSTOMERID_ERROR = "GET_STRIPE_CUSTOMERID_ERROR";

export const GET_BILLING_ORDERS = "GET_BILLING_ORDERS";
export const DELETE_PAYMENTMETHOD = "DELETE_PAYMENTMETHOD";
export const ADD_PAYMENTMETHOD = "ADD_PAYMENTMETHOD";

export const CHANGING_NEW_PASSWORD = "CHANGING_NEW_PASSWORD";
export const CHANGED_NEW_PASSWORD = "CHANGED_NEW_PASSWORD";
export const CHANGING_NEW_PASSWORD_ERROR = "CHANGING_NEW_PASSWORD_ERROR";

export const UPDATING_BASIC_INFO = "UPDATING_BASIC_INFO";
export const UPDATED_BASIC_INFO = "UPDATED_BASIC_INFO";
export const UPDATING_BASIC_INFO_ERROR = "UPDATING_BASIC_INFO_ERROR";
