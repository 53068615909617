import * as constant from "./constants";
import api from "../../../common/api";

export function searchFlights(query) {
  const url = `/Flight/FlightDetailsExternal?Query`;
  return async (dispatch) => {
    dispatch({
      type: constant.FETCHED_EXTERNAL_FLIGHTS,
    });
    try {
      const request = await api.get(`${url}=${query}`);
      if (request.data.length === 0) {
        dispatch({
          type: constant.FETCHED_EXTERNAL_FLIGHTS_EMPTY,
          data: request.data,
        });
      } else {
        dispatch({
          type: constant.FETCHED_EXTERNAL_FLIGHTS,
          data: request.data,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.FETCHING_EXTERNAL_FLIGHTS_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.FETCHED_EXTERNAL_FLIGHTS: {
      return {
        ...state,
        flightStatus: "loading",
      };
    }
    case constant.FETCHED_EXTERNAL_FLIGHTS_EMPTY: {
      return {
        ...state,
        flightStatus: "empty",
      };
    }
    case constant.FETCHING_EXTERNAL_FLIGHTS: {
      return {
        ...state,
        flightStatus: "ok",
        searchedFlights: action.data,
      };
    }
    default:
      return state;
  }
}
