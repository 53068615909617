import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";

export function getContacts(event) {
  return async (dispatch) => {
    dispatch({
      type: constant.GET_CONTACTS,
    });
    try {
      const localContacts = await db
        .table("contacts")
        .where({ EventGUID: event })
        .toArray();
      dispatch({
        type: constant.GET_CONTACTS_SUCCESS,
        data: localContacts,
      });
      const request = await api.get(
        "/ProductionVenueContact/GetProductionVenueContact",
        {
          params: { EventGUID: event },
        }
      );
      const remoteContacts = request.data.filter((c) => c.Deleted === null);
      await db.table("contacts").bulkPut(remoteContacts);
      dispatch({
        type: constant.GET_CONTACTS_SUCCESS,
        data: remoteContacts,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_CONTACTS_SUCCESS: {
      return {
        ...state,
        contacts: action.data,
      };
    }
    default:
      return state;
  }
}
