import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getBasicInfo, getOrganization } from "../../utils";
import ChatItem from "./ChatItem";
import Chat from "./Chat";
import * as actions from "../../page/meet/redux/actions";
import ChatNotificationSound from "../../sound/ChatNotification.mp3";
import { useBetaFeature } from "../../hooks/useBetaFeature";

export const RecentConversationsFunctional = (props) => {
  let recentConversationsGobal = useSelector(
    (state) => state.meet.recentConversations
  );
  const enableChat = useSelector((state) => state.meet.enableChat);
  const conversation = useSelector((state) => state.meet.conversation);
  const chatMessages = useSelector((state) => state.meet.chatMessages);
  const connection = useSelector((state) => state.meet.connection);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    recentConversations: [],
    currentUser: null,
  });

  const audio = new Audio(ChatNotificationSound);

  useEffect(() => {
    const currentUser = getBasicInfo();
    setState((prev) => ({ ...prev, currentUser }));

    if (!connection && connection?.connectionState !== "Connected") {
      const newConnection = new HubConnectionBuilder()
        .withUrl("https://signal.plnify.com/connectionHub")
        //.withUrl("https://localhost:44301/connectionHub")//
        .withAutomaticReconnect()
        .build();

      newConnection.on("send", (data) => {
        dispatch(actions.setConnectedUsers(data));
      });

      newConnection.on("OnSendMessageResponse", (data) => {
        audio.play();
        //we should query the messages
        newConnection.invoke(
          "Messages",
          data.conversationGUID,
          getBasicInfo().GUID,
          0,
          20
        );
      });

      newConnection.on("ConversationListExtendedResponse", (data) => {
        dispatch(actions.setRecentConversations(data));
      });
      newConnection.on("OnMessagesResponse", (data) => {
        dispatch(actions.setChatMessages(data.response));
      });
      newConnection.on("ConversationsResponse", (data) => {
        //TDOO: review this is not been called.
        dispatch(actions.setChats(data.response));
      });
      newConnection.on("OnStartConversationResponse", (data) => {
        dispatch(
          actions.enableChatWindow(false, {
            ...data,
            conversationGUID: data.guid,
          })
        );
      });
      newConnection.on("OnConversationDetailsResponse", (data) => {
        dispatch(actions.setConversationDetails(data));
        dispatch(
          actions.enableChatWindow(false, {
            ...data,
            conversationGUID: data.guid,
          })
        );
        dispatch(actions.refreshChats(data));
      });
      dispatch(actions.setConnection(newConnection));
    }
  }, []);

  useEffect(() => {
    const theOrganization = getOrganization();
    const theUser = getBasicInfo();
    const user = {
      AccountGUID: theUser.GUID,
      OrganizationGUID: theOrganization && theOrganization.GUID,
      SectionType: "Event",
      ParentSectionGuid: "",
    };

    // window.addEventListener("unload", async (e) => {
    //   e.preventDefault();
    //   if (connection?.connectionState === "Connected") {
    //     return await connection.invoke(
    //       "Exit",
    //       user.AccountGUID,
    //       user.OrganizationGUID,
    //       user.SectionType,
    //       user.ParentSectionGuid
    //     );
    //   }
    // });

    if (connection?.connectionState === "Disconnected") {
      connection.start().then(async () => {
        console.log("websocket connected");
        await connection.invoke(
          "Enter",
          user.AccountGUID,
          user.OrganizationGUID,
          user.SectionType,
          user.ParentSectionGuid
        );
        await connection.invoke("ConversationListExtended", user.AccountGUID);
      });
    }

    return async () => {
      if (connection?.connectionState === "Connected") {
        console.log("websocket disconnected");
        await connection.invoke(
          "Exit",
          user.AccountGUID,
          user.OrganizationGUID,
          user.SectionType,
          user.ParentSectionGuid
        );
      }
    };
  }, [connection]);

  useEffect(() => {
    const currentUser = getBasicInfo();
    setState((prev) => ({ ...prev, currentUser }));
  }, []);

  const { isChecked } = useBetaFeature();

  return (
    <div className={`beta ${!isChecked && "active"}`}>
      {enableChat ? (
        <Chat
          currentUser={state.currentUser}
          conversation={conversation}
          connection={connection}
          chatMessages={chatMessages}
        />
      ) : null}
      {recentConversationsGobal !== null &&
      recentConversationsGobal !== undefined &&
      recentConversationsGobal.length > 0 ? (
        <>
          <div className="separator d-flex justify-content-between align-items-center">
            <p>Recent Messages </p>
            {isChecked && <span className="badge bg-info p-1">Beta</span>}
          </div>
          <div className="mx-2  my-2">
            {recentConversationsGobal.map((conversation, index) => (
              <ChatItem
                key={index}
                conversation={conversation}
                enableChat={enableChat}
                connection={connection}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
