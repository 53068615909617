import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function addList(list, allStaffs, success) {
  list = { ...list, EventGUID: list.EventGUID || null }; //avoiding empty string.
  const urlForTodos = "ToDo/AddEdit";
  // const urlForAssignees = "ToDo/AddEditAssignees";

  return async (dispatch) => {
    try {
      dispatch({ type: constants.ADDING_LIST });
      await db.table("todos").put(list);
      const request = await apiService.post(urlForTodos, list);

      const listCreated = request.data.Response;
      const assignees = allStaffs.map((staff) => ({
        ...staff,
        TodoGUID: listCreated.GUID,
      }));
      // await api.post(urlForAssignees, assignees);
      await db.table("todos").put(listCreated);
      success();
      dispatch({ type: constants.ADDED_LIST, data: listCreated });
    } catch (error) {
      dispatch({
        type: constants.ADDING_LIST_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.ADDING_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.ADDED_LIST: {
      return {
        ...state,
        loading: false,
        todos: [...state.todos, action.data],
      };
    }
    case constants.ADDING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
