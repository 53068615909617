import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ModalComponent, SimpleLoader, ToastNotificationSuccess } from "..";
import CreateGroup from "./CreateGroup";
import EditGroup from "./groups/EditGroup";
import GroupItem from "./groups/GroupItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import uuid from "uuid";
import * as Actions from "../../page/calendar/redux/editGroup";
import { getGroups } from "../../page/calendar/redux/getGroups";

const EventGroupModal = (props) => {
  const [state, setState] = useState({
    editModal: false,
    editEventsSelected: [],
    groupNameEdit: "",
    groupEditImageTarget: null,
    editgroupColor: props.groupColor || "",
    groupColorEdit: "",
    groupImageEdit: "",
    groupGUID: "",
    groupOrg: "",
  });

  const dispatch = useDispatch();

  const toggleEventEdit = (event) => {
    let events = state.editEventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let editedEvent;
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      let findedEvents = events.find((s) => s.GUID === event.GUID);
      if (findedEvents.Deleted === true) {
        editedEvent = { GUID: event.GUID, Deleted: null };
      } else if (findedEvents.Deleted === null) {
        editedEvent = { GUID: event.GUID, Deleted: true };
      }
      return setState((prev) => ({
        ...prev,
        editEventsSelected: [...filtered, editedEvent],
      }));
    } else {
      let newOnes = events.concat({ GUID: event.GUID, Deleted: null });
      return setState((prev) => ({ ...prev, editEventsSelected: newOnes }));
    }
  };

  const openEditModal = (group) => {
    setState((prev) => ({
      ...prev,
      editModal: true,
      editEventsSelected: group.Events,
      groupNameEdit: group.Name,
      groupImageEdit: group.Image,
      groupColorEdit: group.Color,
      groupGUID: group.GUID,
      groupOrg: group.OrganizationGUID,
    }));
  };

  const closeEditModal = () => {
    setState((prev) => ({ ...prev, editModal: false }));
  };

  const editColorGroup = (value, from) => {
    setState((prev) => ({ ...prev, groupColorEdit: value }));
  };

  const setGroupNameEdit = (value) => {
    setState((prev) => ({ ...prev, groupNameEdit: value }));
  };

  const setGroupImageEdit = (event, type) => {
    const fileUploaded = type === "drop" ? event : event?.target?.files[0];
    setState((prev) => {
      return { ...prev, groupEditImageTarget: fileUploaded };
    });
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setState((prev) => {
          return { ...prev, groupImageEdit: reader.result };
        })
      );
      reader.readAsDataURL(fileUploaded);
    }
  };

  function resizeImage(image, groupObject) {
    const file = image;
    const maxImageWidth = 1000; // Set your desired maximum width

    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxImageWidth) {
          newWidth = maxImageWidth;
          newHeight = (maxImageWidth / img.width) * img.height;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            dispatch(
              Actions.editGroup(
                {
                  Name: groupObject.groupNameEdit,
                  GUID: groupObject.groupGUID,
                  Color: groupObject.groupColorEdit,
                  OrganizationGUID: groupObject.groupOrg,
                },
                state?.editEventsSelected,
                blob,
                closeEditModal,
                () => {
                  ToastNotificationSuccess(
                    Math.random(),
                    "Group updated successfuly."
                  );
                  dispatch(getGroups(state.groupOrg));
                }
              )
            );
          },
          "image/png",
          0.5
        ); // You can adjust the format and quality here
      };
    }
  }
  const editGroup = () => {
    const {
      groupNameEdit,
      groupColorEdit,
      groupImageEdit,
      groupGUID,
      editEventsSelected,
      groupOrg,
      groupEditImageTarget,
    } = state;

    const groupObject = {
      groupNameEdit,
      groupColorEdit,
      groupImageEdit,
      groupGUID,
      editEventsSelected,
      groupOrg,
    };

    if (groupImageEdit && groupEditImageTarget) {
      resizeImage(groupEditImageTarget, groupObject);
    } else {
      dispatch(
        Actions.editGroup(
          {
            Name: groupNameEdit,
            GUID: groupGUID,
            Color: groupColorEdit,
            OrganizationGUID: groupOrg,
            Image: groupImageEdit,
          },
          editEventsSelected,
          "",
          closeEditModal,
          () => {
            ToastNotificationSuccess(
              Math.random(),
              "Group updated successfuly."
            );
            dispatch(getGroups(state.groupOrg));
          }
        )
      );
    }
  };

  const {
    groupName,
    groupImage,
    eventsSelected,
    toggleEvent,
    eventsList,
    groupColor,
    createGroup,
    setColorGroup,
    setGroupName,
    setGroupImage,
    creatingGroup,
    eventGroups,
    deleteGroup,
    deletingGroup,
    toggleCreateGroupModal,
    createGroupModal,
    toggle,
  } = props;

  const {
    editEventsSelected,
    editModal,
    groupNameEdit,
    groupColorEdit,
    groupImageEdit,
    editgroupColor,
  } = state;

  return (
    <div className="rounded">
      <ModalComponent
        toggle={() => toggleCreateGroupModal()}
        modal={createGroupModal}
        childeren={
          <CreateGroup
            groupName={groupName}
            groupColor={groupColor}
            groupImage={groupImage}
            setColorGroup={setColorGroup}
            setGroupName={setGroupName}
            setGroupImage={setGroupImage}
            eventsSelected={eventsSelected}
            toggleEvent={toggleEvent}
            eventsList={eventsList}
            createGroup={createGroup}
            creatingGroup={creatingGroup}
            toggle={() => toggleCreateGroupModal()}
          />
        }
      />
      <ModalComponent
        modal={editModal}
        toggle={() => closeEditModal()}
        childeren={
          <EditGroup
            groupNameEdit={groupNameEdit}
            groupColorEdit={groupColorEdit}
            groupImageEdit={groupImageEdit}
            setColorGroup={editColorGroup}
            setGroupName={setGroupNameEdit}
            setGroupImage={setGroupImageEdit}
            editEventsSelected={editEventsSelected}
            toggleEvent={toggleEventEdit}
            eventsList={eventsList}
            editGroup={editGroup}
            editModal={editModal}
            toggle={() => closeEditModal()}
          />
        }
      />

      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h2
                style={{ fontSize: 18 }}
                className="text-dark font-weight-bold"
              >
                <FormattedMessage
                  id="eventsGroup"
                  defaultMessage="Event Groups"
                />
              </h2>
            </div>
            <div className="ml-auto mr-2">
              {deletingGroup ? (
                <SimpleLoader loading={deletingGroup} />
              ) : (
                <div className="w-50 d-flex justify-content-between">
                  <button
                    onClick={() => toggleCreateGroupModal()}
                    type="button"
                    className="btn btn-sm primary"
                  >
                    <i className="fa fa-plus" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-link ml-2"
                    onClick={() => toggle()}
                  >
                    <FontAwesomeIcon icon={faTimes} color="white" />
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="event-inside h-auto" style={{ padding: "10px 15px" }}>
            {eventGroups.map((group) => (
              <GroupItem
                key={group.GUID}
                group={group}
                editGroup={openEditModal}
                deleteGroup={deleteGroup}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventGroupModal;
