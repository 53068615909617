import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function deleteFlight(data, success) {
  const url = "/Flights/AddEditBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.DELETING_FLIGHT,
      });
      const request = await apiService.post(url, [{ ...data, Deleted: new Date() }]);
      dispatch({
        type: constant.DELETED_FLIGHT,
        data: request.data.Response.filter((d) => d.Deleted === null),
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.DELETING_FLIGHT_ERROR,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.DELETING_FLIGHT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.DELETED_FLIGHT: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.DELETING_FLIGHT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
