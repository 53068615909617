import React, { useEffect, useState } from "react";
import uuid from "uuid";
import { useIntl, FormattedMessage } from "react-intl";

export default function AddEditVehicle(props) {
  const [state, setState] = useState(props.Item);

  const save = (type) => {
    props.setParentState((prev) => ({
      ...prev,
      UI: { ...prev.UI, SelectedVehicle: state },
    }));

    let parsedArray = props.parentState.UI.Vehicles;

    let tempVehicule = props.parentState.UI.tedVehicle;
    parsedArray.forEach((element, index) => {
      console.log({ ElementsInQuestion: element, parentState: tempVehicule });
      if (tempVehicule.isNew && element?.GUID === tempVehicule?.GUID) {
        console.log({ IfParsedItem: parsedArray[index] });
        parsedArray[index] = {
          ...parsedArray[index],
          DriverName: state.DriverName,
          DriverPhone: state.DriverPhone,
          VehicleType: state.VehicleType,
        };
      } else if (element?.GUID === state?.GUID) {
        parsedArray[index] = state;
      }
    });

    console.log({ parsedArray });
    props.setParentState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        Vehicles: parsedArray,
      },
    }));
    props.toggle();
  };

  console.log({ parentState: props.parentState });

  return (
    <div className="">
      <div className="event-page" style={{ overflow: "revert" }}>
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1 className="title">
                <FormattedMessage id="vehicle" defaultMessage="Vehicle" />
              </h1>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row">
              <div className="col-12">
                <label>
                  <FormattedMessage
                    id="vehicleType"
                    defaultMessage="Vehicle Type"
                  />
                </label>
                <select
                  className="form-control"
                  value={state?.VehicleType}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      VehicleType: e?.target?.value,
                    }))
                  }
                >
                  <option value="">-</option>
                  <option value="Sedan">Sedan</option>
                  <option value="Van">Van</option>
                  <option value="SUV">SUV</option>
                  <option value="Sprinter Van">Sprinter Van</option>
                  <option value="Shuttle">Shuttle</option>
                  <option value="Bus">Bus</option>
                  <option value="Truck">Truck</option>
                  <option value="Mini Van">Mini Van</option>
                  <option value="Cargo Van">Cargo Van</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-6">
                <label>
                  <FormattedMessage
                    id="driverName"
                    defaultMessage="Driver's Name"
                  />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={state?.DriverName}
                  onChange={(e) => {
                    let name = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      DriverName: name,
                    }));
                  }}
                />
              </div>

              <div className="col-6">
                <label>
                  <FormattedMessage
                    id="driverContact"
                    defaultMessage="Driver's Contact"
                  />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={state?.DriverPhone}
                  onChange={(e) => {
                    let phone = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      DriverPhone: phone,
                    }));
                  }}
                />
              </div>

              <div className="col-12 text-right">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={() => {
                    save();
                  }}
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
