import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import api from "../../common/api";

const UpdatesItem = ({ item }) => {
  console.log("obj====", JSON.parse(JSON.stringify(item)));

  async function MarkAsReaded() {
    try {
      const request = await api.get(
        `/Notification/MarkAsReaded?NotificationGUID=${item.GUID}`
      );
      // mainState.Notifications.set(request.data);
      debugger;

      console.log("The item", item, item.Checked);
      //state.set(request);
    } catch (ex) {
      console.log("An error had happend", ex);
    }
  }
  if (item) {
    return (
      <div
        onClick={async () => MarkAsReaded()}
        className="update__item card shadow-sm my-1"
      >
        <div className="card-body row p-1">
          <div className="col-1 d-flex align-items-center justify-content-center">
            {item.Checked ? (
              <i className="fa fa-bell text-dark" />
            ) : (
              <i className="fa fa-bell text-primary" />
            )}
          </div>
          <div className="col-11 text-truncate ">
            <h6 className="title d-inline ">{item.Description}</h6>
            <span className="subTitle d-block">
              {moment(item.CreationDate).fromNow()}
              <small>
                |{" "}
                <FormattedMessage
                  id="clickNotification"
                  defaultMessage="Click on Notification to mark as read"
                />
              </small>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default UpdatesItem;
