import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../page/meet/redux/actions";

const ChatItem = ({ connection, conversation, enableChat }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="d-flex align-items-center px-2 py-1"
      style={{
        cursor: "pointer",
        pointerEvents: connection.connectionStarted ? "all" : "none",
        opacity: connection.connectionStarted ? 1 : 0.3,
      }}
      onClick={() =>
        dispatch(actions.enableChatWindow(enableChat, conversation))
      }
    >
      {/* <div
        className={`bg-${
          connection.connection.connectionState === "Connected"
            ? "success"
            : "danger"
        } rounded-circle mr-2`}
        style={{ width: "8px", height: "8px" }}
      ></div> */}
      <p className="text-truncate  my-1" title={conversation.name}>
        {conversation.unreadMessages > 0 ? (
          <b className="text-white">{conversation.name}</b>
        ) : (
          conversation.name
        )}
      </p>
    </div>
  );
};

export default ChatItem;
