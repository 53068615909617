import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from ".";
import { IMAGES, navActions, navPaths, navRoutes } from "../../constant";
import AccountComponent from "../../page/account/accountComponent";
import AddEventComponent from "../../page/event/addEvent/addEventComponent";
import NavAction from "../navbar/navAction";
import NavItem from "../navbar/navitem";
import OrganizationItem from "../navbar/organizationitem";
import UserItem from "../navbar/useritem";
import DraggableNote from "../notes/DraggableNote";
import Notifications from "../notifications/Notifications";
import Profile from "../profile/Profile";
import AddList from "../todo/quickactions/AddList";
import OrganizationSidebar from "./OrganizationSidebar";
import ChatNotificationSound from "../../sound/ChatNotification.mp3";
import { getBasicInfo } from "../../utils";
import EmailModal from "../hotel/emailModal";
import AddMeetingModal from "../meet/AddMeetingModal";
import ChatSidebar from "../meet/ChatSidebar";
import MeetWindow from "../meet/MeetWindow";
import InviteUserFrom from "../organization/InviteUserForm";
import { FormattedMessage } from "react-intl";
import { RecentConversationsFunctional } from "../meet/RecentConversationsFunctional";
import ShareInviteLink from "../organization/shareInviteLink";
import { BroadcastMessageModal } from "./BroadcastMessageModal";
import { apiService } from "../../common/api";
import Swal from "sweetalert2";
import { handleCloseEmail } from "../../utils/closeMailModal";
import CustomNavItem from "../navbar/CustomNavItem";

function Navbar(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        expanded: window.innerWidth >= 1200 ? true : false, //Default to always open
        dropdownOpen: false,
        showAddEvent: false,
        showNotifications: false,
        showProfile: false,
        showAccount: false,
        todoModal: false,
        showAddMeeting: false,
        ActiveAccountArea: "",
        showMessages: false,
        showOrganizationList: true, //defaults to true to always show it false,
        theme: "light",
        showInviteUsers: { manual: false, link: false },
        showExportCalendar: false,
        currentUser: null,
        showSupport: false,
        showBroadcastMessage: false,
        isBroadCastLoading: false,
        exportType: "email",
      };
      this.audio = new Audio(ChatNotificationSound);
    }

    componentDidMount() {
      const currentUser = getBasicInfo();
      this.setState({
        expanded: window.innerWidth >= 1200 ? true : false,
        currentUser,
      });
    }

    componentDidUpdate() {}

    toggleOrganizations = () => {
      this.setState({
        showOrganizationList: !this.state.showOrganizationList,
      });
    };
    toggleSupport = () => {
      this.setState({
        showSupport: !this.state.showSupport,
      });
    };
    showExpanded = () => {
      this.setState({
        expanded: !this.state.expanded,
      });
    };

    showSlim = () => {
      this.setState({
        expanded: false,
      });
    };
    showAccount = () => {
      this.setState({
        expanded: true,
      });
    };

    openDropdown = () => {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen,
      });
    };

    toggleProfile = () => {
      this.setState({
        showProfile: !this.state.showProfile,
      });
    };

    handleInviteUserBy = () => {
      Swal.fire({
        title: "How would you like to invite users?",
        input: "select",
        inputOptions: {
          link: "Generate Invite Link",
          manual: "Advanced Invite",
        },
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.toggleInviteUsers(result.value);
        }
      });
    };
    toggleInviteUsers = (toggleBy) => {
      this.setState({
        showInviteUsers:
          toggleBy === "link"
            ? { manual: false, link: !this.state.showInviteUsers.link }
            : { manual: !this.state.showInviteUsers.manual, link: false },
      });
    };
    toggleAccount = (area) => {
      this.setState({
        showProfile: false,
        ActiveAccountArea: area,
        showAccount: !this.state.showAccount,
      });
    };

    toggle = () => {
      if (this.state.dropdownOpen === true) {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen,
        });
      }
    };
    toggleNotifications = () => {
      this.setState({ showNotifications: !this.state.showNotifications });
    };

    toggleAddEvent = () => {
      this.setState({ showAddEvent: !this.state.showAddEvent });
    };

    toggleTodo = () => {
      this.setState({ todoModal: !this.state.todoModal });
    };

    toggleExportCalendar = () => {
      if (this.state.showExportCalendar)
        return this.setState({
          showExportCalendar: !this.state.showExportCalendar,
        });

      this.setState({
        exportType: "email",
        showExportCalendar: !this.state.showExportCalendar,
      });

      //   Swal.fire({
      //     text: "How would you like to send this email?",
      //     imageUrl: IMAGES.LOGO_BLACK_P,
      //     imageWidth: 80,
      //     imageHeight: 80,
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "secondary",
      //     confirmButtonText: "Regular Export",
      //     cancelButtonText: "Advanced Export",
      //   }).then((result) => {
      //     if (result.value) {
      //       this.setState({
      //         exportType: "export",
      //         showExportCalendar: !this.state.showExportCalendar,
      //       });
      //     } else if (result.dismiss === Swal.DismissReason.cancel) {
      //       this.setState({
      //         exportType: "email",
      //         showExportCalendar: !this.state.showExportCalendar,
      //       });
      //     }
      //   });
    };

    toggleAddMeeting = () => {
      this.setState({ showAddMeeting: !this.state.showAddMeeting });
    };

    toggleMessages = () => {
      this.setState({ showMessages: !this.state.showMessages });
    };

    toggleBroadcastMessage = () => {
      this.setState({
        showBroadcastMessage: !this.state.showBroadcastMessage,
      });
    };

    handleBroadcastMessage = async (values, receivers, setState) => {
      let channels =
        values.channels.slice(-1) === "|"
          ? values.channels.slice(0, -1)
          : values.channels;
      const newMessage = {
        channels,
        sender: this.state.currentUser.GUID,
        to: JSON.stringify([
          ...new Set(receivers.map((receiver) => receiver.GUID)),
        ]),
        message: values.message,
      };

      try {
        await apiService.post("Broadcast/Send", newMessage);
        ToastNotificationSuccess(Math.random(), "Message sent successfully");
      } catch (error) {
        ToastNotificationError(
          Math.random(),
          "Error while sending, please try again." + error
        );
      }
      setState((prev) => ({ ...prev, isLoading: false }));
      this.toggleBroadcastMessage();
    };

    //Depending of what the user select, let's execute the desired action
    chooseAction = (obj) => {
      switch (obj.destination) {
        case navPaths.ADD_EVENT:
          this.setState({ showAddEvent: !this.state.showAddEvent });
          break;
        case navPaths.NOTIFICATIONS:
          this.setState({ showNotifications: !this.state.showNotifications });
          break;
        case navPaths.ADD_TODO:
          this.setState({ todoModal: !this.state.todoModal });
          break;
        case navPaths.INVITE_USERS:
          this.handleInviteUserBy();
          break;
        case navPaths.EXPORT_CALENDAR:
          if (this.state.showExportCalendar) {
            return this.setState({
              showExportCalendar: !this.state.showExportCalendar,
            });
          }
          // Swal.fire({
          //   text: "How would you like to send this email?",
          //   imageUrl: IMAGES.LOGO_BLACK_P,
          //   imageWidth: 80,
          //   imageHeight: 80,
          //   showCancelButton: true,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "secondary",
          //   confirmButtonText: "Generate PDF",
          //   cancelButtonText: "As Body of Email",
          // }).then((result) => {
          //   if (result.value) {
          //     this.setState({
          //       exportType: "export",
          //       showExportCalendar: !this.state.showExportCalendar,
          //     });
          //   } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({
            exportType: "email",
            showExportCalendar: !this.state.showExportCalendar,
          });
          //   }
          // });

          break;
        case navPaths.ADD_MEETING:
          this.setState({ showAddMeeting: !this.state.showAddMeeting });
          break;
        case navPaths.MESSAGES: {
          this.setState({ showMessages: !this.state.showMessages });
          break;
        }
        case navPaths.BROADCAST: {
          this.toggleBroadcastMessage();
          break;
        }
        default:
          break;
      }
    };

    render() {
      const {
        expanded,
        dropdownOpen,
        sticky,
        showAddEvent,
        showNotifications,
        showProfile,
        showAccount,
        ActiveAccountArea,
        showOrganizationList,
        showInviteUsers,
        todoModal,
        showAddMeeting,
        showExportCalendar,
        showSupport,
        showBroadcastMessage,
      } = this.state;
      const stateWithoutTimer = { ...this.state, time: null };
      const { organizationList, notes } = this.props;
      const currentUserMode = localStorage.getItem("LoginMode");

      return (
        <div>
          {showAddEvent && (
            <AddEventComponent
              expanded={this.state.expanded}
              toggleAddEvent={this.toggleAddEvent}
              typeEdit={false}
            />
          )}
          <ModalComponent
            toggle={this.toggleNotifications}
            modal={showNotifications}
            childeren={
              <Notifications
                loading={false}
                expanded={this.state.expanded}
                toggle={() => this.toggleNotifications()}
              />
            }
          />
          <ModalComponent
            toggle={() => handleCloseEmail(this.toggleSupport)}
            modal={showSupport}
            childeren={
              <EmailModal fromSection="SUPPORT" toggle={this.toggleSupport} />
            }
          />
          <ModalComponent
            toggle={() => this.toggleInviteUsers("manual")}
            modal={showInviteUsers.manual}
            childeren={
              <InviteUserFrom
                fromNavbar
                toggle={() => this.toggleInviteUsers("manual")}
              />
            }
          />

          <ModalComponent
            toggle={() => this.toggleInviteUsers("link")}
            modal={showInviteUsers.link}
            childeren={
              <ShareInviteLink
                // selectedUser={selectedUser}
                toggle={() => this.toggleInviteUsers("link")}
              />
            }
          />

          <ModalComponent
            toggle={this.toggleProfile}
            modal={showProfile}
            childeren={
              <Profile
                toggle={() => this.toggleProfile()}
                launchAccount={(ActiveAccountArea) =>
                  this.toggleAccount(ActiveAccountArea)
                }
              />
            }
          />
          <ModalComponent
            toggle={this.toggleAccount}
            modal={showAccount}
            className="modal-md"
            childeren={
              <AccountComponent
                ActiveArea={ActiveAccountArea}
                toggle={() => this.toggleAccount()}
              />
            }
          />
          <ModalComponent
            toggle={this.toggleTodo}
            modal={todoModal}
            childeren={<AddList toggle={() => this.toggleTodo()} />}
          />

          <ModalComponent
            toggle={this.toggleAddMeeting}
            modal={showAddMeeting}
            childeren={
              <AddMeetingModal toggle={() => this.toggleAddMeeting()} />
            }
          />

          <ModalComponent
            toggle={() => handleCloseEmail(this.toggleExportCalendar)}
            modal={showExportCalendar}
            childeren={
              <EmailModal
                fromCalendar
                fromSection="CALENDAR"
                ExportType={this.state.exportType}
                toggle={() => this.toggleExportCalendar()}
              />
            }
          />

          <BroadcastMessageModal
            toggle={this.toggleBroadcastMessage}
            show={showBroadcastMessage}
            handleSubmit={this.handleBroadcastMessage}
          />
          <MeetWindow />
          {this.state.showMessages && (
            <ChatSidebar
              showMessages={this.state.showMessages}
              toggleMessages={this.toggleMessages}
            />
          )}

          <div className="dashboard__layout flex">
            <nav
              id="navbar"
              className={`sidebar ${expanded ? "expanded" : "slim"}`}
            >
              <button
                onClick={() => this.showExpanded()}
                /*d-xl-none removed to show Menu Toggle on all screens*/
                className={`nav_menu_btn position-absolute btn  ${
                  expanded ? "btn-secondary" : "btn-primary"
                }`}
                style={{
                  right: "5px",
                  top: "15px",
                  zIndex: "50",
                }}
              >
                <span className="white-text">
                  <i className={`${"fas fa-angle-left"}`}></i>
                </span>
              </button>
              <div className="m-0 pb-1 pt-1">
                <OrganizationItem
                  toggle={this.toggle}
                  show={expanded}
                  dropdownOpen={dropdownOpen}
                  openDropdown={this.openDropdown}
                  toggleOrganizations={this.toggleOrganizations}
                />
              </div>
              <OrganizationSidebar
                {...stateWithoutTimer}
                showOrganizationList={showOrganizationList}
                organizations={organizationList}
                toggleOrganizations={this.toggleOrganizations}
                expanded={expanded}
              />
              <div className="mainSidebarContainer pt-2">
                <div className="sidebar__content mt-0">
                  <Scrollbars
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          backgroundColor: "rgba(255,255,255,0)",
                          width: "2px",
                          borderRadius: "1.5px",
                          opacity: 0,
                        }}
                      />
                    )}
                  >
                    {navRoutes
                      .filter(
                        (x) =>
                          x.mode !== "beta" ||
                          (currentUserMode === "beta" &&
                            x.mode === currentUserMode)
                      )
                      .map((item, index) => {
                        if (item.id === "calendar") {
                          return (
                            <CustomNavItem
                              type={item.type}
                              onClick={() => this.chooseAction(item)}
                              toggleExportCalendar={this.toggleExportCalendar}
                              id={item.id}
                              key={index}
                              icon={item.icon}
                              destination={item.destination}
                              name={item.name}
                              show={expanded}
                              fontAwesomeIcon={item.fontAwesomeIcon}
                              mode={item.mode}
                            />
                          );
                        }
                        return (
                          <NavItem
                            type={item.type}
                            onClick={() => this.chooseAction(item)}
                            id={item.id}
                            key={index}
                            icon={item.icon}
                            destination={item.destination}
                            name={item.name}
                            show={expanded}
                            fontAwesomeIcon={item.fontAwesomeIcon}
                            mode={item.mode}
                          />
                        );
                      })}
                    <NavAction
                      onClick={() => this.toggleMessages()}
                      id={"messages"}
                      icon={IMAGES.MESSAGES}
                      name={
                        <FormattedMessage
                          id="messages"
                          defaultMessage="Messages"
                        />
                      }
                      destination={navPaths.MESSAGES}
                      fontAwesomeIcon={"far fa-comments"}
                      betaClass="beta"
                    />
                    <div className="separator">
                      <p>
                        <FormattedMessage
                          id="quickActions"
                          defaultMessage="Quick Actions"
                        />
                      </p>
                    </div>

                    {navActions

                      //.filter(x => (x.mode !== "beta" || currentUserMode === "beta") && x.mode === currentUserMode)
                      .map((item, index) => {
                        // console.log("Item in the navbar", item)
                        return (
                          <NavAction
                            index={index}
                            //icon={item.icon}
                            //  icon={item.fontAwesomeIcon}
                            key={index}
                            onClick={() => this.chooseAction(item)}
                            name={item?.name}
                            fontAwesomeIcon={item.fontAwesomeIcon}
                            show={expanded}
                          />
                        );
                      })}
                    {
                      <RecentConversationsFunctional
                        currentUser={this.state.currentUser}
                      />
                    }
                  </Scrollbars>
                </div>

                <UserItem
                  toggleProfile={this.toggleProfile}
                  {...this.props}
                  show={expanded}
                  user={getBasicInfo()}
                />
              </div>
            </nav>
            <div
              className={`content ${
                expanded
                  ? `expanded ${sticky ? "fixed" : ""}`
                  : `slim ${sticky ? "fixed" : ""}`
              } `}
              style={{ transition: "all .5s ease-in-out" }}
            >
              {/*<NotificationTicker />*/}
              {this.props.notes.map((note) => (
                <DraggableNote key={note.GUID} note={note} />
              ))}
              <WrappedComponent
                {...this.props}
                {...stateWithoutTimer}
                slimModal={() => {
                  this.showSlim();
                  this.toggle();
                }}
                showExpanded={this.showExpanded}
                expanded={expanded}
              />
            </div>
          </div>
        </div>
      );
    }
  };
}

OrganizationItem.propTypes = {};

const mapStateToProps = (state) => {
  return {
    organizationList: state.organizations.organizationsList,
    notes: state.common.notes,
    events: state.calendar.calendarEvents,
    enableChat: state.meet.enableChat,
    teams: state.organizations.teams,
  };
};

const ConnectedNavbar = compose(connect(mapStateToProps), Navbar);

export default ConnectedNavbar;
