import { useState as useHookState } from "@hookstate/core";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import uuid from "uuid";
import * as yup from "yup";
import api, { apiService } from "../../common/api";
import { ModalComponent } from "../common";

import DatePicker from "react-datepicker";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import db from "../../common/db";
import AddEditVehicle from "./AddEditVehicle";

import { Form } from "reactstrap";
import AddEditLabel from "./AddEditLabel";

import mainState from "../../common/mainState";
//REDUX THINGS

import { useDispatch } from "react-redux";
import { getDistanceFromLatLonInKm, getOrganization } from "../../utils";

import Scrollbars from "react-custom-scrollbars";
import Swal from "sweetalert2";
import { organizationUsers, sortArray } from "../../common/helpers";
import { IMAGES } from "../../constant";
import AssignMembersGround from "./AssignMembersGround";
import { getGrounds } from "../../page/event/redux/getGrounds";
import { red } from "@material-ui/core/colors";

//END REDUX THINGS
const companyTypeMaster = [
  "Car Service",
  "Runner",
  "Personal",
  "Tour Bus",
  "Others",
];

//Validation
const validationSchema = yup.object({
  Pickup: yup.string().required(),
  Dropoff: yup.string().required(),
});

const AddEditGround = (props) => {
  const globalState = useHookState(mainState);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    UI: {
      isLoading: false,
      Current:
        props.ground !== undefined && props.editMode
          ? props.ground
          : {
              GUID: uuid.v4(),
              EventGUID: props.event.GUID,
              Company: "",
              Pickup: "",
              Dropoff: "",
              CompanyContact: "",
              Note: "",
              Distance: "",
              Website: "",
              Deleted: null,
              PickupDate: moment().format(),
              Reservation: "",
              Label:
                props.editMode && props?.ground?.Label
                  ? props?.ground?.Label
                  : "",
              PickupGUID: "",
              DropoffGUID: "",
              GroundStaff: [],
              GroundVehicles: [],
            },
      IsOnOther: false,
      Vehicles: [],
      SelectedVehicle: {},
      Label: "",
      ShowAddEditLabel: false,
      ShowMembersModal: false,
      Teams: [],
      staffSelected: [],
      DropoffPlaces: [],
      PickupPlaces: [],
      SelectedPickup: null,
      SelectedDropoff: null,
      ShowAddEditVehicle: false,
    },
  });

  useEffect(() => {
    if (globalState.EventsUI.ReloadGround.value) {
      //toggleAddEditMember();
      props.toggle();
    }
  }, []);

  useEffect(() => {
    try {
      let theOrganization = getOrganization();
      organizationUsers(theOrganization.GUID);
      //Check for passed item

      if (props?.ground?.value !== undefined) {
        let obj = JSON.parse(JSON.stringify(props?.ground?.value));
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: obj,
            Vehicles: obj.GroundVehicles,
          },
        }));
        searchPlace("pickup", obj.Pickup);
        searchPlace("dropoff", obj.Dropoff);
      } else if (props.ground !== undefined && props.editMode) {
        let obj = props.ground;
        let copy = JSON.parse(JSON.stringify(state));

        copy.UI.Current = obj;
        copy.UI.Vehicles = obj.GroundVehicles;
        setState(copy);

        searchPlace("pickup", props.ground.Pickup);
        searchPlace("dropoff", props.ground.Dropoff);
      } else {
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              PickupDate: moment(props.event.Date).toDate(),
            },
          },
        }));
      }

      let companyValue = state.UI.Current.Company;
      if (
        companyValue.length === 0 ||
        (companyTypeMaster.includes(companyValue) && companyValue !== "Others")
      ) {
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            IsOnOther: false,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            IsOnOther: true,
          },
        }));
      }

      console.log(
        "Current obj",
        state.UI.Current,
        moment(state.UI.Current.PickupDate).toDate(),
        props.event
      );
    } catch (ex) {
      console.error("Loading ground add edit ", ex);
    }
  }, []);

  const handleSubmit = async (obj) => {
    try {
      if (formik.isValid) {
        //state.UI.Current.set(obj);
        //state.UI.Current.Label.set(state.UI.Label.value);
        let record = obj;
        record.Label = state.UI.Label || props.ground.Label;
        record.PickupDate = moment(record.PickupDate).format(
          "YYYY-MM-DDTHH:mm"
        );

        //Send to the server
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        }));
        let query = [];
        query.push(record);

        let request = await apiService.post("/grounds/AddEditBatch", query);
        if (request.data.status === "ok") {
          if (state.UI.Vehicles.length > 0) {
            //Then add the vehicles
            let queryVehicles = JSON.parse(JSON.stringify(state.UI.Vehicles));
            ///Update the object so we sent the actual ground id
            let vehicles = [];
            queryVehicles.forEach((vehicle) => {
              vehicle.GroundGUID = record.GUID;
              vehicles.push(vehicle);
            });

            let requestVehicles = await api.post(
              "/grounds/AddEditVehicleBatch",
              vehicles
            );
            if (requestVehicles.data.status === "ok") {
            }
          }

          //Teams
          let results = await teams();
          // toggleMemberModal();
          !props.editMode && toggleAddEditMember();
          if (props.editMode) {
            props.toggle();

            setState((prev) => ({
              ...prev,
              UI: {
                ...prev.UI,
                isLoading: !prev.UI.isLoading,
              },
            }));

            if (props.handleSidebarItem) props.handleSidebarItem("GROUND", obj);
            dispatch(getGrounds(props.event.GUID));
          }

          //Pass to the next screen
        }
        setState((prev) => ({
          ...prev,
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        }));

        if (props.handleSidebarItem) props.handleSidebarItem("GROUND", obj);
      }
    } catch (ex) {
      console.error(ex);
      debugger;
    }
  };
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues:
      props.ground && props.editMode
        ? props.ground
        : {
            GUID: uuid.v4(),
            EventGUID: props.event.GUID,
            Company: "",
            Pickup: "",
            Dropoff: "",
            CompanyContact: "",
            Note: "",
            Distance: "",
            Website: "",
            Deleted: null,
            PickupDate: moment(props.event.Date).format(),
            Reservation: "",
            Label:
              props.editMode && props?.ground?.Label
                ? props?.ground?.Label
                : "",
            PickupGUID: "",
            DropoffGUID: "",
            GroundStaff: [],
            GroundVehicles: [],

            DriverName: "",
            TypeVehicle: "",
            Contact: "",
          },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const ToggleAddEditLabel = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowAddEditLabel: !prev.UI.ShowAddEditLabel,
      },
    }));
  };

  const searchPlace = async (section, formikValues) => {
    try {
      if (typeof state === undefined || !state) {
        return;
      }

      // let copy = state;
      switch (section) {
        case "pickup":
          if (formikValues === "Other") {
            setState((prev) => ({
              ...prev,
              UI: {
                ...prev.UI,
                Current: { ...prev.UI.Current, PickupGUID: "" },
              },
              PickupPlaces: [],
            }));
          } else if (formikValues === "Venue") {
            setState((prev) => ({
              ...prev,
              UI: {
                ...prev.UI,
                Current: {
                  ...prev.UI.Current,
                  PickupGUID: props.event.VenueGUID,
                },
              },
              SelectedPickup: props.event.Venue.Name,
            }));
          } else if (formikValues === "Hotel") {
            //Search for the hotels
            let records = await db
              .table("hotels")
              .where({ EventGUID: props.event.GUID })
              .toArray();
            records = records
              .filter((x) => x.Deleted === null)
              .sort((a, b) => {
                if (a.Name > b.Name) {
                  return 1;
                } else {
                  return -1;
                }
              });
            setState((prev) => ({
              ...prev,
              PickupPlaces: records,
            }));

            console.log("hotels ", records, { records });
          } else if (formikValues === "Airport") {
            //We have to search for the airports (arrival)
            //Select all the flights in the event, take only the arrival
            let records = await db
              .table("flights")
              .where({ EventGUID: props.event.GUID })
              .toArray();
            records = records.filter((x) => x.Deleted === null);

            // let grouped = groupBy(records, "Connection");
            //  console.log("grouped flights", grouped)
            let airports = [];
            records.forEach((flight, index) => {
              console.log("flight", flight, "index", index);
              //Departure
              let placeDeparture = flight.FromLongName
                ? flight.FromLongName
                : "";
              if (!flight.FromLongName) {
                if (placeDeparture.length > 0) {
                  placeDeparture += " (" + flight.From + ")";
                } else {
                  placeDeparture += flight.From;
                }
              }
              airports.push({
                GUID: flight.GUID + ";" + flight.From,
                Name: placeDeparture,
              });

              //Arrival
              let place = flight.ToLongName ? flight.ToLongName : "";
              if (!flight.ToLongName) {
                if (place.length > 0) {
                  place += " (" + flight.To + ")";
                } else {
                  place += flight.To;
                }
              }

              airports.push({
                GUID: flight.GUID + ";" + flight.To,
                Name: place,
              });
            });
            //Sort
            airports = sortArray(airports, "Name");

            let airportsFilter = [];
            airports.forEach((item) => {
              if (
                airportsFilter.filter((x) => x.Name === item.Name).length === 0
              ) {
                airportsFilter.push(item);
              }
            });
            setState((prev) => ({
              ...prev,
              PickupPlaces: airportsFilter,
            }));
          }
          break;
        case "dropoff":
          if (formikValues === "Other") {
            setState((prev) => ({
              ...prev,
              UI: {
                ...prev.UI,
                Current: { ...prev.UI.Current, DropoffGUID: "" },
              },
              DropoffPlaces: [],
            }));
          } else if (formikValues === "Venue") {
            setState((prev) => ({
              ...prev,
              UI: {
                ...prev.UI,
                Current: {
                  ...prev.UI.Current,
                  DropoffGUID: props.event.VenueGUID,
                },
                SelectedDropoff: props.event.Venue.Name,
              },
            }));
          } else if (formikValues === "Hotel") {
            //Search for the hotels
            let records = await db
              .table("hotels")
              .where({ EventGUID: props.event.GUID })
              .toArray();
            records = records
              .filter((x) => x.Deleted === null)
              .sort((a, b) => {
                if (a.Name > b.Name) {
                  return 1;
                } else {
                  return -1;
                }
              });
            setState((prev) => ({
              ...prev,
              DropoffPlaces: records,
            }));

            console.log("hotels ", records);
          } else if (formikValues === "Airport") {
            //We have to search for the airports (arrival)
            //Select all the flights in the event, take only the arrival
            let records = await db
              .table("flights")
              .where({ EventGUID: props.event.GUID })
              .toArray();
            let airports = [];
            records.forEach((flight, index) => {
              console.log("flight", flight, "index", index);
              //Departure
              let placeDeparture = flight.FromLongName
                ? flight.FromLongName
                : "";
              if (!flight.FromLongName) {
                if (placeDeparture.length > 0) {
                  placeDeparture += " (" + flight.From + ")";
                } else {
                  placeDeparture += flight.From;
                }
              }
              airports.push({
                ...flight,
                GUID: flight.GUID + ";" + flight.From,
                Name: placeDeparture,
              });
              //Arrival
              let place = flight.ToLongName ? flight.ToLongName : "";
              if (!flight.ToLongName) {
                if (place.length > 0) {
                  place += " (" + flight.To + ")";
                } else {
                  place += flight.To;
                }
              }
              airports.push({
                ...flight,
                GUID: flight.GUID + ";" + flight.To,
                Name: place,
              });
            });
            //Sort
            airports = airports.sort((a, b) => {
              if (a.Name > b.Name) {
                return 1;
              } else {
                return -1;
              }
            });

            let airportsFilter = [];
            airports.forEach((item) => {
              if (
                airportsFilter.filter((x) => x.Name === item.Name).length === 0
              ) {
                airportsFilter.push(item);
              }
            });

            setState((prev) => ({
              ...prev,
              DropoffPlaces: airportsFilter,
            }));
            // state.DropoffPlaces.set(airports);
          }
          break;

        default:
          break;
      }
      //   state.set(copy);
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleVehicleModal = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowAddEditVehicle: !prev.UI.ShowAddEditVehicle,
      },
    }));
  };

  const toggleAddEditVehicle = (newVehicle) => {
    if (newVehicle === true) {
      //Means is a new one
      let newItem = {
        GUID: uuid.v4(),
        GroundGUID: state.UI.Current.GUID,
        VehicleType: "",
        DriverName: "",
        DriverPhone: "",
        Deleted: null,
        isNew: true,
      };

      let objs = state.UI.Vehicles;
      objs.push(newItem);

      setState((prev) => ({
        ...prev,
        UI: {
          ...prev.UI,
          Vehicles: objs,
          tedVehicle: newItem,
          SelectedVehicle: [],
        },
      }));

      toggleVehicleModal();
    }
  };
  const DeleteVehicle = (item) => {
    Swal.fire({
      text: "Are you sure you want to delete the vehicle?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        state.UI.Vehicles.forEach((vehicle, index) => {
          if (vehicle.GUID === item.GUID) {
            item.Deleted = moment().format("YYYY-MMM-DD");

            setState((prev) => {
              let deletedVehicle = state.UI.Vehicles;
              deletedVehicle[index] = item;
              return {
                ...prev,
                UI: {
                  ...prev.UI,
                  Vehicles: deletedVehicle,
                },
              };
            });
          }
        });
      }
    });
  };
  const VehiclesUI = () => {
    let items = JSON.parse(JSON.stringify(state.UI.Vehicles));
    var records = items.filter((x) => x.Deleted === null);
    if (records.length === 0) {
      return (
        <div className="card shadow-sm my-2">
          <div className="card-body px-2 py-1">
            <div className="row">
              <div className="col-auto">
                <img src={IMAGES.GROUND_ICON_EMPTY} className="img-fluid" />
              </div>
              <div className="col">
                <small>Start adding vehicles by tapping on new vehicles</small>
              </div>
            </div>
          </div>
        </div>
      );
    }
    var results = records.map((item, index) => {
      return (
        <div className="col-auto px-0 mx-0 my-2" key={index}>
          <div
            className={
              "card clickable  shadow-sm mx-1 " +
              (state.UI.SelectedVehicle !== null &&
              state.UI.SelectedVehicle.GUID === item.GUID
                ? "active"
                : "")
            }
          >
            <div className="card-body text-center p-1 px-2 row">
              <>
                <div
                  className=" d-inline col-auto text-left"
                  onClick={() => {
                    let obj = item;

                    setState((prev) => ({
                      ...prev,
                      UI: {
                        ...prev.UI,
                        SelectedVehicle: obj,
                      },
                    }));
                    toggleVehicleModal();
                  }}
                >
                  {item.VehicleType !== null ? (
                    <small>
                      <b className="px-2">#{index + 1}</b>
                      {item?.VehicleType}
                    </small>
                  ) : null}
                  {item?.DriverName !== null && item?.DriverName?.length > 0 ? (
                    <small className="text-muted">
                      <br />
                      {item?.DriverName}
                    </small>
                  ) : (
                    <small>
                      <br />-
                    </small>
                  )}
                </div>
                <div className="col-auto text-right px-0">
                  <button
                    type="button"
                    className="btn btn-sm "
                    onClick={() => {
                      DeleteVehicle(item);
                    }}
                  >
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    });

    return results;
  };

  /* MEMBERS */
  const toggleMemberModal = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowMembersModal: !prev.UI.ShowMembersModal,
      },
    }));
  };
  const teams = async () => {
    let organization = getOrganization();
    let teamRecords = (
      await db.table("organization").where({ GUID: organization.GUID }).first()
    ).Roles;
    console.log("TEAMS", teamRecords);
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        Teams: teamRecords.filter((x) => x.Deleted === null),
      },
    }));

    return teamRecords;
  };

  //Select all the members of team to the ground

  const toggleAddEditMember = () => {
    setState((prev) => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShowAddEditMembers: !prev.UI.ShowAddEditMembers,
      },
    }));
  };

  /* END MEMBERS */

  const [calculatedDistance, setCalculatedDistance] = useState(null);

  function handleLocationChange(e, section) {
    const value = e.target.value;
    const name = e.target.name;
    formik.setFieldValue(section, value);

    const pickUp = formik?.values?.Pickup;
    const dropOff = formik?.values?.Dropoff;

    //TODO: CONTINUE HERE, CHECK THE if the pickUp or DropOff is Venue or Airport and continue from there.

    console.log({ Props: props });

    const currentPickUp =
      section === "PickupGUID"
        ? value.split(";")[0]
        : formik?.values?.PickupGUID;
    const currentDropOff =
      section === "DropoffGUID"
        ? value.split(";")[0]
        : formik?.values?.DropoffGUID;

    console.log({ value, name, section, currentPickUp, currentDropOff });

    const [PickupPlace] =
      currentPickUp && pickUp
        ? state?.PickupPlaces?.filter((x) => x.GUID === currentPickUp)
        : [null];
    const [DropoffPlace] =
      currentDropOff && dropOff
        ? state?.DropoffPlaces?.filter((x) => x.GUID === currentDropOff)
        : [null];

    const pickUpLatitud =
      pickUp === "Airport"
        ? PickupPlace?.ToLatitude
        : pickUp === "Venue"
        ? props.event.Latitude
        : PickupPlace?.Latitude;
    const pickUpLongitud =
      pickUp === "Airport"
        ? PickupPlace?.ToLongitude
        : pickUp === "Venue"
        ? props.event.Longitude
        : PickupPlace?.Longitude;
    const dropOffLatitud =
      dropOff === "Airport"
        ? DropoffPlace?.ToLatitude
        : pickUp === "Venue"
        ? props.event.Latitude
        : DropoffPlace?.Latitude;
    const dropOffLongitud =
      dropOff === "Airport"
        ? DropoffPlace?.ToLongitude
        : pickUp === "Venue"
        ? props.event.Longitude
        : DropoffPlace?.Longitude;

    if (pickUpLatitud && pickUpLongitud && dropOffLatitud && dropOffLongitud) {
      const distance =
        getDistanceFromLatLonInKm(
          pickUpLatitud,
          pickUpLongitud,
          dropOffLatitud,
          dropOffLongitud
        ) + " km";

      setCalculatedDistance(distance);
    }
  }

  return (
    <div className="organization-transparent">
      <ModalComponent
        modal={state.UI.ShowAddEditMembers}
        toggle={() => toggleAddEditMember()}
        childeren={
          <AssignMembersGround
            toggle={toggleAddEditMember}
            parentState={state}
            donable
            usersSelected={state.UI.staffSelected}
            ground={formik.values}
            parentToggle={props.toggle}
            event={props.event}
          />
        }
      />

      <ModalComponent
        modal={state.UI.ShowAddEditLabel}
        toggle={() => ToggleAddEditLabel()}
        childeren={
          <AddEditLabel
            toggle={ToggleAddEditLabel}
            parentState={{
              ...state,
              UI: {
                ...state.UI,
                Label: props?.editMode ? props?.ground?.Label : state.UI.Label,
              },
            }}
            setParentState={setState}
          />
        }
      />

      <ModalComponent
        modal={state.UI.ShowAddEditVehicle}
        toggle={() => toggleVehicleModal()}
        childeren={
          <AddEditVehicle
            toggle={toggleVehicleModal}
            parentState={state}
            setParentState={setState}
            Item={state.UI.SelectedVehicle}
          />
        }
      />

      <Form onSubmit={formik.handleSubmit}>
        <div className="event-page text-dark">
          <div className="event-expennd-section">
            <div className="text-head">
              <div className="text-left">
                <h5 className="title">
                  <FormattedMessage
                    id="ground"
                    defaultMessage="Transportation"
                  />
                </h5>
                {state?.UI?.Label || props?.ground?.Label ? (
                  <small className="text-muted">
                    {props?.editMode && props?.ground?.Label
                      ? props?.ground?.Label
                      : state?.UI?.Label}
                  </small>
                ) : null}
              </div>
              <div className="text-right">
                <button
                  className="btn btn-block btn-primary btn-sm"
                  type="button"
                  onClick={() => {
                    //We have to add the current flight to the list (first checking that the id does not exist) and then adding a new record

                    ToggleAddEditLabel();
                  }}
                >
                  {props?.editMode && props?.ground?.Label ? (
                    <FormattedMessage
                      id="editLabel"
                      defaultMessage="Edit Label"
                    />
                  ) : (
                    <FormattedMessage
                      id="addLabel"
                      defaultMessage="Add Label"
                    />
                  )}
                </button>
              </div>
            </div>
            <div className="modal-body p-1 pb-0 px-0 ">
              <Scrollbars
                style={{
                  height: `${
                    props?.editMode && props?.ground?.Label || state?.UI?.Label
                      ? "calc(100vh - 130px)"
                      : "calc(100vh - 105px)"
                  }`,
                }}
              >
                <div className="row">
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="serviceBy"
                        defaultMessage="Transportation Type"
                      />
                      {state.UI.IsOnOther ||
                      formik.values.Company === "Others" ? (
                        <button
                          className="btn btn-sm ml-2"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              UI: {
                                ...prev.UI,
                                IsOnOther: false,
                              },
                            }));
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      ) : null}
                    </label>
                    {!state.UI.IsOnOther ? (
                      <select
                        className="form-control"
                        name="Company"
                        id="Company"
                        value={formik.values.Company}
                        onChange={(e) => {
                          if (e.target.value === "Others") {
                            setState((prev) => ({
                              ...prev,
                              UI: {
                                ...prev.UI,
                                IsOnOther: true,
                              },
                            }));
                            formik.setFieldValue("Company", "");
                          } else {
                            setState((prev) => ({
                              ...prev,
                              UI: {
                                ...prev.UI,
                                IsOnOther: false,
                              },
                            }));
                            formik.handleChange(e);
                          }
                        }}
                      >
                        <option value="">-</option>
                        {companyTypeMaster.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        name="Company"
                        id="Company"
                        value={formik.values.Company}
                        onChange={formik.handleChange}
                      />
                    )}

                    {formik.errors.Company ? (
                      <div className="invalid text-sm">
                        {formik.errors.Company}
                      </div>
                    ) : null}
                  </div>
                  {/* {formik.values.Company === "Car Service" ? (
                    <div className="col-6 mt-2">
                      <label>
                        <FormattedMessage id="name" defaultMessage="Name" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="CompanyContact"
                        autoComplete="true"
                        value={formik.values.CompanyContact}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.CompanyContact ? (
                        <div className="invalid text-sm">
                          {formik.errors.CompanyContact}
                        </div>
                      ) : null}
                    </div>
                  ) : formik.values.Company === "Runner" ||
                    formik.values.Company === "Personal" ? (
                    <div className="col-6 mt-2">
                      <label>
                        <FormattedMessage id="name" defaultMessage="Name" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="CompanyContact"
                        autoComplete="true"
                        value={formik.values.CompanyContact}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.CompanyContact ? (
                        <div className="invalid text-sm">
                          {formik.errors.CompanyContact}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {formik.values.Company === "Car Service" ? (
                    <div className="col-6 mt-2">
                      <label>
                        <FormattedMessage
                          id="contact"
                          defaultMessage="Contact"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Reservation"
                        value={formik.values.Reservation}
                        onChange={formik.handleChange}
                      />
                    </div>
                  ) : formik.values.Company === "Runner" ||
                    formik.values.Company === "Personal" ? (
                    <div className="col-6 mt-2">
                      <label>
                        <FormattedMessage
                          id="contact"
                          defaultMessage="Contact"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Reservation"
                        value={formik.values.Reservation}
                        onChange={formik.handleChange}
                      />
                    </div>
                  ) : null} */}
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="driverName"
                        defaultMessage="driverName"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="DriverName"
                      autoComplete="true"
                      value={formik.values.DriverName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.DriverName ? (
                      <div className="invalid text-sm">
                        {formik.errors.DriverName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="driverContact"
                        defaultMessage="driverContact"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Contact"
                      autoComplete="true"
                      value={formik.values.Contact}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.Contact ? (
                      <div className="invalid text-sm">
                        {formik.errors.Contact}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 bgTitleModal py-1 mt-4">
                    <h3>
                      <FormattedMessage
                        id="pickUpDropOff"
                        defaultMessage="Pick up/Drop off Information"
                      />
                    </h3>
                  </div>
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage id="pickUp" defaultMessage="Pick Up" />
                    </label>
                    <select
                      className="form-control"
                      name="Pickup"
                      value={formik.values.Pickup}
                      onChange={(e) => {
                        formik.setFieldValue("Pickup", e.target.value);

                        searchPlace("pickup", e.target.value);
                      }}
                    >
                      <option value="">-</option>
                      <option value="Venue">Venue</option>
                      <option value="Airport">Airport</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="Location"
                        defaultMessage="Location"
                      />
                    </label>
                    {formik.values.Pickup !== undefined ? (
                      formik.values.Pickup === "Other" ? (
                        <input
                          type="text"
                          className="form-control"
                          name="PickupGUID"
                          value={formik.values.PickupGUID}
                          onChange={formik.handleChange}
                        />
                      ) : formik.values.Pickup === "Venue" ? (
                        <input
                          type="text"
                          className="form-control"
                          value={
                            state.UI.SelectedPickup
                              ? state.UI.SelectedPickup
                              : ""
                          }
                          readOnly={true}
                        />
                      ) : (
                        <select
                          className="form-control"
                          name="PickupGUID"
                          value={formik.values.PickupGUID}
                          onChange={(e) =>
                            handleLocationChange(e, "PickupGUID")
                          }
                        >
                          <option value="">-</option>
                          {state?.PickupPlaces?.length > 0 &&
                            state?.PickupPlaces?.map((item, index) => {
                              return (
                                <option key={index} value={item.GUID}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      )
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        value="-"
                        readOnly={true}
                      />
                    )}
                  </div>

                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="pickupDate"
                        defaultMessage="Pick up Date"
                      />
                    </label>
                    <input
                      type="date"
                      className="form-control underline"
                      name="PickupDate"
                      value={moment(formik.values.PickupDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        if (!e.target.value) {
                          return;
                        }
                        console.log(
                          "pickup value",
                          e.target.value,
                          formik.values.PickupDate
                        );
                        formik.setFieldValue(
                          "PickupDate",
                          e.target.value +
                            "T" +
                            moment(formik.values.PickupDate).format("HH:mm:ss")
                        );
                      }}
                    />
                  </div>
                  <div className="col-6 mt-2">
                    <label htmlFor="DepartureTime">
                      <FormattedMessage
                        id="pickupTime"
                        defaultMessage="Pick up Time"
                      />
                    </label>

                    <DatePicker
                      className="form-control underline"
                      popperPlacement="top-start"
                      id={"checkinTime"}
                      name="PickupDate"
                      selected={moment(formik.values.PickupDate).toDate()}
                      onChange={(e) => {
                        let pickupDateBeforeUpdate = formik.values.PickupDate;
                        let datePart = moment(pickupDateBeforeUpdate).format(
                          "YYYY-MM-DD"
                        );
                        let timePart = moment(e).format("HH:mm:ss");
                        let compiled = datePart + "T" + timePart;
                        console.log(
                          "pickup date",
                          pickupDateBeforeUpdate,
                          "after",
                          e,
                          "compiled",
                          compiled
                        );
                        formik.setFieldValue("PickupDate", compiled);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>

                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="dropOff"
                        defaultMessage="Drop Off"
                      />
                    </label>
                    <select
                      className="form-control"
                      name="Dropoff"
                      value={formik.values.Dropoff}
                      onChange={(e) => {
                        formik.setFieldValue("Dropoff", e.target.value);

                        searchPlace("dropoff", e.target.value);
                      }}
                    >
                      <option value="">-</option>
                      <option value="Venue">Venue</option>
                      <option value="Airport">Airport</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="Location"
                        defaultMessage="Location"
                      />
                    </label>
                    {formik.values.Dropoff !== undefined ? (
                      formik.values.Dropoff === "Other" ? (
                        <input
                          type="text"
                          className="form-control"
                          name="DropoffGUID"
                          value={formik.values.DropoffGUID}
                          onChange={formik.handleChange}
                        />
                      ) : formik.values.Dropoff === "Venue" ? (
                        <input
                          type="text"
                          className="form-control"
                          value={state.UI.SelectedDropoff}
                          readOnly={true}
                        />
                      ) : (
                        <select
                          className="form-control"
                          name="DropoffGUID"
                          value={formik.values.DropoffGUID}
                          onChange={(e) =>
                            handleLocationChange(e, "DropoffGUID")
                          }
                        >
                          <option value="">-</option>
                          {state?.DropoffPlaces?.length > 0 &&
                            state.DropoffPlaces.map((item, index) => {
                              return (
                                <option key={index} value={item.GUID}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      )
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        value="-"
                        readOnly={true}
                      />
                    )}
                  </div>

                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="distance"
                        defaultMessage="Distance"
                      />
                    </label>

                    {calculatedDistance ? (
                      <span>{calculatedDistance}</span>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        name="Distance"
                        value={formik.values.Distance}
                        onChange={formik.handleChange}
                      />
                    )}
                  </div>

                  <div className="col-6 mt-2">
                    <label>
                      <FormattedMessage
                        id="TypeVehicle"
                        defaultMessage="Vehicle Type"
                      />
                    </label>
                    <select
                      className="form-control "
                      style={{ padding: "1.2px" }}
                      name="TypeVehicle"
                      value={formik.values.TypeVehicle}
                      onChange={(e) => {
                        formik.setFieldValue("TypeVehicle", e.target.value);

                        searchPlace("TypeVehicle", e.target.value);
                      }}
                    >
                      <option value="">-</option>
                      <option value="Tour Bus">Tour Bus</option>
                      <option value="Sprinter Van">Sprinter Van</option>
                      <option value="SUV">SUV</option>
                      <option value="Van">Van</option>
                      <option value="Sedan">Sedan</option>
                      <option value="Bus">Bus</option>
                    </select>
                  </div>

                  {/* <div className="col-12 bgTitleModal py-1 mt-4">
                    <div className="row">
                      <div className="col-6 py-2 px-0">
                        <h3>
                          <FormattedMessage
                            id="VehicleDriverInformation"
                            defaultMessage=""
                          />
                        </h3>
                      </div>
                      <div className="col-6 text-right pr-0">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => toggleAddEditVehicle(true)}
                          type="button"
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-12 mt-2">
                    <div className="row ">{VehiclesUI()}</div>
                  </div> */}

                  <div className="col-12 my-2">
                    <label>
                      <FormattedMessage id="notes" defaultMessage="Notes" />
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={
                        state.UI.Current.Note === undefined
                          ? ""
                          : state.UI.Current.Note
                      }
                      onChange={(content) => {
                        console.log("content of the editor", content);
                        setState((prev) => ({
                          ...prev,
                          UI: {
                            ...prev.UI,
                            Current: { ...prev.UI.Current, Note: content },
                          },
                        }));
                        formik.setFieldValue("Note", content);
                      }}
                    />
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div className="modal-footer text-right pos">
              <div className="col-12 px-1">
                <button
                  className="btn btn-block btn-primary"
                  readOnly={state.UI.isLoading}
                  type="submit"
                >
                  <FormattedMessage id="done" defaultMessage="Done" />
                  {props.editMode ? "" : ` & Assign`}
                  {state.UI.isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

///The redux thing
export default AddEditGround;

//END redux thing
//export default AddEditFlights;
