import * as constant from "./constants";
import api from "../../../common/api";

export function changePassword(user, oldPassword, newPassword, success, error) {
  const urlCanLogin = "User/CanLogin";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.CHANGING_NEW_PASSWORD,
      });
      const loginRequest = await api.post(urlCanLogin, {
        Email: user.Email,
        Password: oldPassword,
      });
      if (loginRequest.data.response !== "no_account") {
        await api.get(
          `User/PasswordChange?AccountID=${user.GUID}&NewPassword=${newPassword}`
        );
        dispatch({
          type: constant.CHANGED_NEW_PASSWORD,
        });
        return success();
      }
      error();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constant.CHANGING_NEW_PASSWORD_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CHANGING_NEW_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CHANGED_NEW_PASSWORD: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CHANGING_NEW_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
