import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function getGrounds(event) {
  return async (dispatch) => {
    dispatch({
      type: constant.GET_GROUNDS,
    });
    try {
      const localGrounds = await db
        .table("grounds")
        .where({ EventGUID: event })
        .toArray();
      dispatch({
        type: constant.GET_GROUNDS_SUCCESS,
        data: localGrounds,
      });
      const request = await apiService.get("/Grounds/GroundForEvent", {
        params: { EventGUID: event },
      });
      const cleanedGrounds = request.data.response.filter(
        (d) => d.Deleted === null
      );
      await db.table("grounds").bulkPut(cleanedGrounds);
      dispatch({
        type: constant.GET_GROUNDS_SUCCESS,
        data: cleanedGrounds,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.GET_GROUNDS_SUCCESS: {
      return {
        ...state,
        grounds: action.data,
      };
    }
    default:
      return state;
  }
}
