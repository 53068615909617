import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { StaffDropDown } from "./StaffDropDown";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import ShowMoreText from "react-show-more-text";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";

export const TaskViewTable = ({ todo, assignee, toggleTask, mobile }) => {
  const [descShow, setDescShow] = useState({ GUID: "", show: false });
  const priorityStyle = {
    High: "danger",
    Medium: "warning",
    Low: "success",
  };

  const priorityNum = [null, "Low", "Medium", "High"];

  const isOver = false;
  // const handleMouseOver = () => {
  //   setIsOver((prev) => !prev);
  // };

  const handleShowDesc = (GUID) => {
    setDescShow((prev) => ({
      GUID,
      show: !prev.show,
    }));
  };

  // let assignees = assignee ? JSON.parse(assignee) : "";

  const toggleDescription = (todo) => {
    Swal.fire({
      title: todo.Name,
      html: `<p style="font-size:14px;">${todo.Note}</p>`,
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Done",
    });
  };

  return (
    <tr>
      {/* <div
        key={todo.GUID}
        className="d-flex border align-items-center p-2 justify-content-between"
        onClick={() => handleShowDesc(todo.GUID)}
      > */}
      <td
        onClick={() => toggleTask(todo)}
        style={{
          width: "1.5rem",
          verticalAlign: "middle",
        }}
      >
        <div
          style={{
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {todo.Status ? (
            <i
              className="fas fa-check-circle pt-1 text-primary"
              style={{ fontSize: "18px" }}
            ></i>
          ) : (
            <i
              className={`pt-1 far ${
                isOver ? "fa-check" : "fa-circle"
              } uncheck`}
              // onMouseEnter={handleMouseOver}
              // onMouseLeave={handleMouseOver}
              style={{ fontSize: "16px" }}
            ></i>
          )}
        </div>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          width: "5rem",
        }}
      >
        {todo?.Date && todo?.Status === 0 && (
          <div
            className="d-flex align-items-center"
            style={{ fontSize: "11px" }}
          >
            {/*      
     {`Due ${moment(todo?.Date).format(
              "MMM DD"
            )} at */}
            {/* <i className="fa fa-calendar-o mx-1" aria-hidden="true"></i> */}
            <span className="text-muted w-100 text-center">
              {`${
                moment(todo?.Date).format("hh:mm A") === "12:00 AM"
                  ? ""
                  : moment(todo?.Date).format("hh:mm A")
              }`}
            </span>
          </div>
        )}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          width: "10rem",
        }}
      >
        <div className="d-flex flex-column align-items-baseline">
          <span
            className={`ml-2 text-center w-100`}
            style={{
              textDecoration: `${todo.Status > 0 ? "line-through" : ""}`,
            }}
          >
            {todo.Name}
          </span>
        </div>
      </td>
      {/* <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {assignees[0] && (
          <StaffDropDown
            staffSelected={assignees[0]}
            handleSelectAssignee={() => {}}
            isEditable={false}
            mobile={mobile}
          />
        )}
      </td> */}
      {/* <td
        className="text-center"
        style={{
          verticalAlign: "middle",
        }}
      >
        {todo?.Priority ? (
          mobile ? (
            <span
              className={`btn btn-${
                priorityStyle[priorityNum[todo.Priority]]
              } rounded-pill`}
              style={{ cursor: "auto", pointerEvents: "none" }}
            >
              {priorityNum[todo.Priority]?.[0] ?? "Add Priority"}
            </span>
          ) : (
            <span
              className={`btn btn-${
                priorityStyle[priorityNum[todo.Priority]]
              } rounded-pill`}
              style={{ cursor: "auto", pointerEvents: "none" }}
            >
              {priorityNum[todo.Priority] ?? "Add Priority"}
            </span>
          )
        ) : (
          ""
        )}
      </td> */}
      <td
        style={
          mobile
            ? {
                verticalAlign: "middle",
                textAlign: "center",

                fontSize: "12px",
                overflow: "hidden",
              }
            : {
                verticalAlign: "middle",
                width: "20rem",
                maxWidth: "20rem",
                minWidth: "20rem",
                fontSize: "12px",
                overflow: "hidden",
              }
        }
      >
        {mobile ? (
          todo.Note && (
            <button
              title="Description"
              className="btn btn-primary border-0"
              onClick={() => toggleDescription(todo)}
            >
              <i className="fa fa-comment"></i>
            </button>
          )
        ) : (
          <ShowMoreText
            className={"cursor-default"}
            anchorClass="d-inline text-primary anchor-class"
            lines={2}
            more="See"
            less="Less"
            expanded={false}
            width={0}
            children={
              <p className="" style={{ maxWidth: "20rem" }}>
                {todo.Note}
              </p>
            }
          />
        )}
      </td>
      {/* 
      {descShow.GUID === todo.GUID && descShow.show && (
        <div className="btn btn-secondary d-flex pe-none border-0">
          <span>Description: </span>
          <span className="ml-2">{todo.Note}</span>
        </div>
      )} */}
    </tr>
  );
};
