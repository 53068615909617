import React from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import Slide from "../../components/onboarding/slides/Slide";
import { ChevronRight, LocationExit } from "mdi-material-ui";
import { ROUTES } from "../../constant";

const WalkThrough = ({
  activeIndex,
  next,
  previous,
  goToIndex,
  items,
  setAnimating,
  onClickNavigation,
  logOut,
  history,
}) => {
  const slides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.text}
    >
      <Slide
        index={index}
        title={item.title}
        text={item.text}
        logo={item.logo}
        cta={item.cta}
        trigger={item.trigger}
      />
    </CarouselItem>
  ));
  return (
    <div className="onboarding__container">
      {activeIndex === 0 ? null : (
        <button
          style={{ position: "fixed", top: 25, right: 25, zIndex: 55555 }}
          type="button"
          className="btn btn-link"
          onClick={() => onClickNavigation(ROUTES.SELECT_ORGANIZATION)}
        >
          Skip <ChevronRight htmlColor={"#fff"} />
        </button>
      )}

      <div className="onboarding__carousel-container">
        <Carousel
          interval={false}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          {slides}
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {activeIndex ===0 ? null : (
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
          )}
          {activeIndex === items.length -1  ? null : (
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          )}
        </Carousel>
        <div
          style={{
            cursor: "pointer ",
            color: "white",
            width: "100%",
            position: "absolute",
            left: 0,
            bottom: 15,
          }}
          className="text-center"
        >
          <div
            onClick={() => {
              logOut();
              history.go("/");
            }}
          >
            <LocationExit htmlColor={"white"} /> Log out
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalkThrough;
