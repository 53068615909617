import * as constant from "./constants";
import api from "../../../common/api";
import db from "../../../common/db";
import moment from "moment";

export function deleteFlightStaff(staff, success) {
  const url = "/FlightStaff/AddEditBatch";
  return async (dispatch) => {
    try {
      dispatch({
        type: constant.EDITING_PASSENGERS,
      });
      const deletedStaff = [{ ...staff, Deleted: new Date() }];

      await api.post(url, deletedStaff);
      //Delete locally 
      //debugger;
      let localRecord = await db.table("flights").where({GUID: staff.FlightGUID}).first();
      localRecord.FlightStaffs.forEach( (item, index)=>{
        if(item.GUID === staff.GUID){
          localRecord.FlightStaffs[index].Deleted = moment().format();
        }
      })
      await db.table("flights").put(localRecord);
      dispatch({
        type: constant.EDITED_PASSENGERS,
      });
      success();
    } catch (error) {
      dispatch({
        type: constant.EDITING_PASSENGERS_ERROR,
      });
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EDITING_PASSENGERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.EDITED_PASSENGERS:
    case constant.EDITING_PASSENGERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
