import React, { useEffect, useRef, useState } from "react";

import SelectableEvent from "../../components/event/selectableEvent";
import { Event } from "../../components/event/event";
import { Scrollbars } from "react-custom-scrollbars";
import api, { apiService } from "../../common/api";
import { useIntl, FormattedMessage } from "react-intl";
import { ModalComponent } from "../../components";
import FilterModal from "../../components/calendar/FilterModal";
import { useFilterModal } from "../../hooks/useFilterModal";
import { getBasicInfo, getOrganization } from "../../utils";
import { getCurrentOrganization } from "../organization/redux/getCurrentOrganization";
const AssignToEvents = (props) => {
  const [search, setSearch] = useState("");
  const [localState, setLocalState] = useState({
    EventShared: "",
    isLoading: false,
  });

  const [events, setEvents] = useState();

  const getEvents = async (from, to) => {
    const basicInfo = getBasicInfo();
    const currentOrganization = basicInfo.CurrentOrganizationGUID;
    const currentAccount = basicInfo.GUID;
    const res = await apiService.get(
      `/Events/listClean?OrganizationGUID=${currentOrganization}&AccountGUID=${currentAccount}`
    );
    setEvents(res.data.response);
  };

  useEffect(() => {
    if (props.parentState.SelectedFiles.value.length === 1) {
      let fileGuid = props.parentState.SelectedFiles.value[0];
      let filter = props.parentState.Files.value.filter(
        (x) => x.GUID === fileGuid
      )[0].EventShared;
      setLocalState({
        ...localState,
        EventShared: filter,
      });

      bindData();
    }
    getEvents();

    // setTimeout(() => {
    //   scrollNearTo();
    // }, 1000);
  }, [props]);

  const convertToArray = (value) => {
    if (typeof value !== "string") return value;
    do {
      value = JSON.parse(value);
    } while (typeof value === "string");

    return value;
  };

  const SelectEvent = (event) => {
    let EventsToBeSharedWith =
      typeof localState?.EventShared === `string`
        ? convertToArray(localState?.EventShared)
        : localState?.EventShared || [];

    if (EventsToBeSharedWith.includes("")) {
      let nullIndex = EventsToBeSharedWith.indexOf("");
      EventsToBeSharedWith?.splice(nullIndex, 1);
    }

    if (
      localState.EventShared !== null &&
      localState.EventShared?.includes(event.GUID)
    ) {
      //We should remove it
      let index = EventsToBeSharedWith.indexOf(event.GUID);
      EventsToBeSharedWith?.splice(index, 1);
      setLocalState({
        ...localState,
        EventShared: EventsToBeSharedWith,
      });
    } else {
      // let EventsToBeSharedWith =
      //   typeof localState?.EventShared === "string"
      //     ? JSON.parse(localState?.EventShared)
      //     : localState?.EventShared || [];

      //We should add it
      EventsToBeSharedWith?.push(event.GUID);
      // localState?.EventShared?.replace(event.GUID, "")?.replace(
      //   ",,",
      //   ","
      // ),
      setLocalState({
        ...localState,
        EventShared: EventsToBeSharedWith,
      });
    }
  };
  const LinkToEventHandler = async () => {
    let query = [];
    //Each selected item

    // localState?.EventShared?.length === 1
    //         ? null
    //         : localState?.EventShared,
    props.parentState.SelectedFiles.value.forEach((item) => {
      let newItem = {
        ItemGUID: item,
        SelectedEvents: JSON.stringify(localState.EventShared),
      };
      query.push(newItem);
    });
    setLocalState({
      ...localState,
      isLoading: !localState.isLoading,
    });

    const request = await api.post("/File/LinkToEvent?", query);
    setLocalState({
      ...localState,
      isLoading: !localState.isLoading,
    });
    props.toggleEvents();
  };

  const bindData = () => {
    let localEvents = events;
    if (search.length > 0) {
      let simplifiedSearch = search.toLowerCase().trim();
      localEvents = localEvents.filter(
        (x) =>
          x.Name.toLowerCase()?.includes(simplifiedSearch) ||
          (x.City && x.City.toLowerCase()?.includes(simplifiedSearch)) ||
          (x.EventType && x.EventType.toLowerCase()?.includes(simplifiedSearch))
      );
    }

    setEvents(localEvents);
  };

  const filter = (e) => {
    setSearch(e);
    bindData();
  };

  let containerScroller = useRef();
  const scrollNearTo = () => {
    let element = containerScroller.current;

    if (element === null || element === undefined) {
      return;
    }
    let elementEstimatedHeight = 50.8;
    //find first index of dates in the array of events
    let theIndex = findWithAttr(events);

    if (theIndex > 0) {
      //check if we have more than 10 events ahead
      if (events?.length - theIndex > 10) {
        element.scrollTop(elementEstimatedHeight * (theIndex + 10));
      } else if (theIndex - 5 > 0) {
        //check if we have 10 events below so we can try to center
        element.scrollTop(elementEstimatedHeight * (theIndex + 5));
      } else {
        element.scrollTop(elementEstimatedHeight * theIndex);
      }
    }
  };

  const findWithAttr = (array) => {
    let today = new Date();
    //console.log("EVENTS ===", array, array.length);

    for (var i = 0; i < array?.length; i += 1) {
      var record = array[i];
      var parsedDate = new Date(record?.Date);
      if (
        parsedDate?.getFullYear() === today?.getFullYear() &&
        parsedDate?.getMonth() === today?.getMonth()
      ) {
        return i;
      }
    }
    return -1;
  };

  function sortEventsBySelection(events, localState) {
    if (!events || !localState?.EventShared) {
      return events;
    }

    const selectedEvents = [];
    const unselectedEvents = [];

    events.forEach((record) => {
      if (localState.EventShared.includes(record.GUID)) {
        selectedEvents.push(record);
      } else {
        unselectedEvents.push(record);
      }
    });

    const sortedEvents = [...selectedEvents, ...unselectedEvents];
    return sortedEvents;
  }

  const handleSearch = ({ target }) => {
    setSearch(target.value);
  };

  const cleanEvents = events?.filter((event) =>
    event?.Name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const sortedEvents = sortEventsBySelection(cleanEvents, localState);

  const {
    eventToRender,
    eventGroups,
    eventFilterState,
    showFilterModal,
    toggleFilterModal,
    handleFilterByGroup,
    handleFilterByStatus,
    handleFilterFrom,
    handleFilterTo,
    handleUpdateFilters,
  } = useFilterModal(sortedEvents);
  

  const allSelectedEvents = eventToRender?.filter((record) =>
    localState.EventShared?.includes(record.GUID)
  );

  console.log({ sortedEvents, eventToRender });

  return (
    <div className="organization-transparent">
      <ModalComponent
        modal={showFilterModal}
        toggle={toggleFilterModal}
        childeren={
          <FilterModal
            groups={eventGroups}
            updateFiltersGroups={handleFilterByGroup}
            updateFiltersStatus={handleFilterByStatus}
            updateFiltersFrom={handleFilterFrom}
            updateFiltersTo={handleFilterTo}
            filtersOnEventGroups={eventFilterState.groups}
            filtersOnEventStatus={eventFilterState.status}
            filtersOnEventFrom={eventFilterState.from}
            filtersOnEventTo={eventFilterState.to}
            // filtersOnEvent={filtersOnEvent}
            updateFilters={handleUpdateFilters}
            toggle={() => toggleFilterModal()}
            renderBy={"guestList"}
          />
        }
      />
      <div
        className="card shadow-sm eventPickerContainer h-100"
        style={{
          width: "950px",
          maxWidth: "100vw",
        }}
      >
        <div className="card-header px-3">
          <div className="row align-items-center">
            <div className="col p-0 mr-5">
              <h1 className="title">
                <FormattedMessage
                  id="linkWithTheseEvents"
                  defaultMessage="Link with these events"
                />
              </h1>
            </div>
            <div
              className="d-flex text-right justify-content-end"
              style={{ width: "250px" }}
            >
              {/* <div className="text-right formAlternativeStyle">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="search"
                  value={search}
                  onChange={handleSearch}
                ></input>
              </div> */}
              <div className="d-flex ml-2">
                <button
                  className="btn btn-primary border-0"
                  title="Filter Events"
                  onClick={toggleFilterModal}
                >
                  <i className={`fa fa-filter`}></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" d-flex">
          <div className="card-body text-dark p-0">
            <div className="border p-2 bg-light">
              <span>1. Events</span>
            </div>
            <div className="px-2">
              <Scrollbars
                ref={containerScroller}
                style={{ width: "100%", height: `calc(100vh - 140px)` }}
              >
                {eventToRender?.length > 0 &&
                  eventToRender
                    .filter(
                      (record) => !localState.EventShared?.includes(record.GUID)
                    )
                    .map((record) => (
                      <div
                        className={
                          "card my-1 shadow-sm eventRow clickable" +
                          (localState.EventShared &&
                          localState.EventShared?.includes(record.GUID)
                            ? " active "
                            : "")
                        }
                        key={record.GUID}
                        onClick={() => SelectEvent(record)}
                      >
                        <div className="card-body p-0">
                          <Event
                            key={record.GUID}
                            event={record}
                            noIcons
                            noClickable
                          />
                        </div>
                      </div>
                    ))}
              </Scrollbars>
            </div>
          </div>
          <div className="card-body text-dark p-0">
            <div className="border p-2 bg-light">
              <span>2. Linked Events</span>
            </div>

            <div className="px-2">
              <Scrollbars
                ref={containerScroller}
                className="p-2"
                style={{ width: "100%", height: `calc(100vh - 140px)` }}
              >
                {allSelectedEvents?.length > 0 ? (
                  allSelectedEvents.map((record) => (
                    <div
                      className={
                        "card my-1 shadow-sm eventRow clickable" +
                        (localState.EventShared &&
                        localState.EventShared?.includes(record.GUID)
                          ? " active "
                          : "")
                      }
                      key={record.GUID}
                      onClick={() => SelectEvent(record)}
                    >
                      <div className="card-body p-0">
                        <Event
                          key={record.GUID}
                          event={record}
                          noIcons
                          noClickable
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="alert alert-info text-center mt-1 w-100">
                    No Linked Events
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
        <div
          className="card-footer text-right position-fixed w-100"
          style={{ zIndex: 100, right: 0, bottom: 0 }}
        >
          {localState.isLoading ? (
            <div
              className="spinner-border spinner-border-sm mr-2 "
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : null}
          {events?.length > 0 ? (
            <button
              className="btn btn-sm btn-primary ml-auto"
              onClick={() => {
                LinkToEventHandler();
              }}
            >
              <FormattedMessage id="done" defaultMessage="Done" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AssignToEvents;
