import * as constant from "./constants";

export function emptyVenues() {
  return (dispatch) => {
    dispatch({
      type: constant.EMPTY_VENUES,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.EMPTY_VENUES: {
      return {
        ...state,
        venues: [],
      };
    }
    default:
      return state;
  }
}
