import moment from "moment";
import uuid from "uuid";
import { getOrganization } from ".";

export const addMultipleEventsHelper = (unreadyEvents) => {
  let events = unreadyEvents.map((event, i) => ({
    Name: event.Name || "",
    Date:
      i === 0
        ? moment(event.Date).toDate()
        : moment(event.Date).add(i, "days").toDate(),
    GUID: uuid.v4(),
    EventTypeName: event.EventTypeName || "",
    EventType: event.EventType || "",
    City: event.City,
    Country: event.Country,
    Latitude: "",
    State: event.State,
    Longitude: "",
    VenueGUID: event.VenueGUID,
    Venue: { Name: event.Venue },
    Status: "Confirmed",
    OrganizationGUID: getOrganization().GUID,
  }));

  return events;
};
