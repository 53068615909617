import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";

const StopNavigation = (props) => {
  const handleDeleteFlight = (item) => {
    let obj = item;
    props.deleteFlight(obj);
  };

  return (
    <div className="navigationContainer row">
      {props.parentState.Flights.filter(
        (x) => x.Deleted === undefined || x.Deleted === null
      ).map((item, index) => {
        return (
          <div className="col-auto px-0 mx-0 mt-2" key={index}>
            <div
              className={
                "card clickable shadow-sm mx-1 " +
                (props.parentState.UI.CurrentFlight.GUID === item.GUID
                  ? "active"
                  : "")
              }
              onClick={() => {
                let obj = item;
                props.SelectFlight(obj);
              }}
            >
              <div className="card-body text-center p-2 px-4">
                <small>
                  <FormattedMessage id="flight" defaultMessage="Flight" /> #{" "}
                  {index + 1}
                  {props.parentState.Flights.filter(
                    (x) => x.Deleted === undefined || x.Deleted === null
                  ).length > 1 ? (
                    <span
                      className="badge bg-warning text-dark floatTopRight"
                      onClick={() => handleDeleteFlight(item)}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                  ) : null}
                </small>
                <br />
                <b>
                  {item.From} - {item.To}
                </b>
              </div>
            </div>
          </div>
        );
      })}
      {props.parentState.UI.Page === "flight" ? (
        <div className="col-auto px-0 mx-0 mt-2">
          <div
            className={
              "card clickable shadow-sm mx-1 px-2 brandingOrangeBackground"
            }
            onClick={() => props.AddStop()}
          >
            <div className="card-body text-center p-2">
              <FormattedMessage id="Add" defaultMessage="Add" /> <br />
              <FormattedMessage id="Stop" defaultMessage="Stop" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StopNavigation;
