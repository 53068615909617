import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import useSound from "use-sound";
import ringSound from "../../../sound/ring.mp3";
import { getBasicInfo } from "../../../utils";

const avatarUrl =
  "https://app.plnify.com/photos/553ada58-bd81-4178-92ed-6c81f5c17d01.jpg?w=75&h=75&mode=crop&autorotate=true";
//TypeOfScreen = called | calling | calledVideo | callingVideo

const InvitationCard = ({
  acceptCall,
  rejectCall,
  whosCalling,
  whoIsBeingCalled,
  typeOfScreen,
}) => {
  const [play, { stop }] = useSound(ringSound);

  useEffect(() => {
    play();

    return () => {
      stop();
    };
  });
  const currentUser = getBasicInfo();
  if (whosCalling !== currentUser.GUID) {
    return (
      <div className="meet__invitation card shadow-sm">
        <div className="card-body text-center">
          <img
            src={avatarUrl}
            className="rounded-circle img-responsive mt-2"
            style={{ height: "100px" }}
            alt="User is calling you"
          />
          <h5 className="text-center title my-2">Starlin</h5>
          <p className="mt-auto text-center subTitle">calling...</p>
        </div>
        <div className="card-footer btn-group justify-content-between align-items-center ">
          <button
            onClick={() => rejectCall()}
            type="button"
            className="btn btn-danger"
          >
            <FontAwesomeIcon icon={faPhoneSlash} color="white" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="meet__invitation card shadow-sm">
      <div className="card-body text-center">
        <img
          src={avatarUrl}
          className="rounded-circle img-responsive mt-2"
          style={{ height: "100px" }}
          alt="User is calling you"
        />
        <h5 className="text-center title my-2">Starlin</h5>
        <p className="mt-auto text-center subTitle">is calling you</p>
      </div>
      <div className="card-footer btn-group justify-content-between align-items-center ">
        <button
          onClick={() => rejectCall()}
          type="button"
          className="btn btn-danger"
        >
          <FontAwesomeIcon icon={faPhoneSlash} color="white" />
        </button>
        <button
          onClick={() => acceptCall()}
          type="button"
          className="btn  btn-success"
        >
          <FontAwesomeIcon icon={faPhone} color="white" />
        </button>
      </div>
    </div>
  );
};

export default InvitationCard;
