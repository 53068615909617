import initialState from "./initialState";
import { reducer as getEventsReducer } from "./getEvents";
import { reducer as createGroupReducer } from "./createGroup";
import { reducer as getGroupsReducer } from "./getGroups";
import { reducer as deleteGroupReducer } from "./deleteGroup";
import { reducer as editGroupReducer } from "./editGroup";
import { reducer as deleteEventReducer } from "./deleteEvent";
import { reducer as editEventReducer } from "./editEvent";

const reducers = [
  getEventsReducer,
  createGroupReducer,
  getGroupsReducer,
  deleteGroupReducer,
  editGroupReducer,
  deleteEventReducer,
  editEventReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
