import React, { useState, useEffect } from "react";
import { IMAGES } from "../../constant";
import SelectItem from "../organization/SelectItem";
import { Scrollbars } from "react-custom-scrollbars";
import { ArrowLeft } from "mdi-material-ui";
import Skeleton from "react-loading-skeleton";
import api, { apiService } from "../../common/api";
import { getBasicInfo } from "../../utils";

const OnboardingWorkspace = ({ selectWorkspace, history, toggleBack }) => {
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  useEffect(() => {
    const fetchWorkspaces = async () => {
      setLoading(true);
      const result = await apiService.get(
        `Organizations/GetOrganizations?AccountGUID=${getBasicInfo().GUID}&source=web`
      );
      setWorkspaces(result.data.response.filter((w) => w.Deleted === null));
      setLoading(false);
    };
    fetchWorkspaces();
  }, []);

  return (
    <div className="createOrganization">
      <div className="selectOrganization__container mx-auto col-md-6 p-0 col-6 col-lg-4 ">
        <div
          onClick={() => toggleBack()}
          className="selectOrganization__back d-flex align-items-center pb-3"
        >
          <ArrowLeft />
          <span>Back</span>
        </div>
        <div className="selectOrganization__container-inner shadow-sm">
          <div className="selectOrganization__body p-1">
            <div className="text-center my-4">
              <div className="createOrganization__logo ">
                <img src={IMAGES.LOGO_ALT} alt="Plnify" className="mx-auto" />
              </div>

              <h5 className="title text-white">Select a workspace</h5>
            </div>
            <Scrollbars style={{ height: 380 }}>
              {loading ? (
                <div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-3">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-3">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                  <div className="chat__item d-flex align-items-center">
                    <Skeleton circle={true} height={50} width={50} />
                    <div className="ml-3">
                      <Skeleton count={1} height={10} width={150} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center flex-wrap justify-content-around">
                  {workspaces
                    .sort((a, b) =>
                      a.Organization.Name.localeCompare(b.Organization.Name)
                    )
                    .map((organization) => (
                      <SelectItem
                        setOrganization={selectWorkspace}
                        history={history}
                        key={organization.GUID}
                        organization={organization.Organization}
                      />
                    ))}
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWorkspace;
