import React, { Component } from "react";
import CalendarItem from "./CalendarItem";
import moment from "moment";

export class CalendarEventModal extends Component {
  render() {
    const { events, date } = this.props;
    return (
      <div className="vehicleModal__root groups">
        <h5
          style={{
            color: "black",
            fontSize: "1rem",
            textAlign: "center",
            paddingBottom: 12,
          }}
        >
          Events on {moment(date).format("MMM, DD yyyy")}
        </h5>
        {events.map((event) => (
          <div key={event.GUID} className="rbc-event">
            <CalendarItem event={event} />
          </div>
        ))}
      </div>
    );
  }
}

export default CalendarEventModal;
