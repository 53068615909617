import React from "react";
import { NOTES_COLORS } from "../../constant";

const NotesPalette = (props) => {
  let { noteColor, setNewColorNote } = props;
  return (
    <div
      className="d-flex align-items-center"
      style={{ padding: "0.5rem 1rem" }}
    >
      {NOTES_COLORS.map((color) => (
        <button
          onClick={() => setNewColorNote(color)}
          key={color}
          style={{
            backgroundColor: `${color}`,
            border: noteColor === color ? "3px solid black" : "none",
          }}
          type="button"
          className="clean-button btn-reset color-option pink"
        ></button>
      ))}
      <input
        type="color"
        id="color-picker"
        className="clean-button btn-reset color-option"
        style={{
          // backgroundColor: `${noteColor}`,

          background:
            "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
          border: "none",
        }}
        onBlur={(e) => setNewColorNote(e.target.value)}
      />
      {/* <button
        onClick={() => setNewColorNote(null)}
        key="clean"
        style={{
          backgroundColor: `#ccc`,
          border:
            !noteColor ? "3px solid black" : "none",
        }}
        title="Remove color"
        type="button"
        className="clean-button btn-reset color-option "
      ></button> */}
    </div>
  );
};

export default NotesPalette;
