import React, { Component } from "react";
import ResetPassword from "./resetPassword";
import ResetPasswordSuccess from "./resetPasswordSuccess";
import ResetPasswordLinkExpire from "./resetPasswordLinkExpire";
import { connect } from "react-redux";
import { ROUTES } from "../../../constant";

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passwordError: "",
      confirmPassword: "",
      confirmPasswordError: "",
      showPassword: false,
      token: "",
      showConfirmPassword: false,
      showMessage: 1
    };
  }

  /**
   *
   */
  componentDidMount() {
    const URL = this.props.location.search;
    const URL_ARRAY = URL.split("&");
    const tokenArray = URL_ARRAY[1] ? URL_ARRAY[0].split("=") : [];
    const emailArray = URL_ARRAY[1] ? URL_ARRAY[1].split("=") : [];
    this.setState({
      token: tokenArray[1] ? tokenArray[1] : "",
      email: emailArray[1] ? emailArray[1] : ""
    });
    if (!emailArray[1]) {
      this.setState({ showMessage: 3 });
    }
  }

  componentDidUpdate() {
    let { resetPassword } = this.props;
    if (resetPassword.status === 400 && this.state.showMessage !== 3) {
      this.setState({ showMessage: 3 });
    }
    if (resetPassword.message && this.state.showMessage !== 2) {
      this.setState({ showMessage: 2 });
      this.props.history.replace({
        pathname: ROUTES.RESET_PASSWORD
      });
    }
  }

  showHideTextValue = id => {
    let { showPassword, showConfirmPassword } = this.state;
    if (id === "password") {
      this.setState({ showPassword: !showPassword });
    } else {
      this.setState({ showConfirmPassword: !showConfirmPassword });
    }
  };
  /**
   * @description this. function is used to set state value
   * @param {string} key contain name of state
   * @param {string} value contain value to set
   */
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  /**
   * @description this function is used to validate user details
   */
  handleSubmit = () => {
    const { password, confirmPassword, token, email } = this.state;
    const { resetPasswordAction } = this.props;
    // if (!validatePassword(password).status) {
    //   this.handleChange("passwordError", validatePassword(password).error);
    // }
    if (confirmPassword === "") {
      this.handleChange(
        "confirmPasswordError",
        " MESSAGE.CONFIRM_PASSWORD_BLANK"
      );
    }
    if (password !== confirmPassword) {
      this.handleChange(
        "confirmPasswordError",
        "MESSAGE.CONFIRM_PASSWORD_INVALID"
      );
    }
    if (
      email !== "" &&
      password === confirmPassword
      // validatePassword(password).status
    ) {
      const data = {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
        email: email
      };
      resetPasswordAction(data);
    }
  };

  /**
   * @description this function is used to set state omn fileds change
   * @param {object} e conatins object of input fields change
   */
  onInputFieldChange = e => {
    const target = e.target;
    const value = target.value;
    const name = target.id;
    this.handleChange(name, value);
  };

  /**
   * @description this function is used to navigate to another page
   */
  onClickNavigation = routes => {
    this.props.history.push({ pathname: routes });
  };

  render() {
    const { showMessage, email } = this.state;
    let { loading } = this.props;
    switch (showMessage) {
      case 1:
        return (
          <ResetPassword
            {...this.state}
            loading={loading}
            handleSubmit={this.handleSubmit}
            onInputFieldChange={this.onInputFieldChange}
            onClickNavigation={this.onClickNavigation}
            handleChange={this.handleChange}
            showHideTextValue={this.showHideTextValue}
          />
        );
      case 2:
        return <ResetPasswordSuccess email={email} />;
      case 3:
        return <ResetPasswordLinkExpire />;
      default:
        return <ResetPasswordLinkExpire />;
    }
  }
}

const mapStateToProps = state => ({
  user: {},
  resetPassword: {},
  loading: false
});

const mapDispatchToProps = {
  resetPasswordAction: () => console.log("resetPasswordAction")
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
