import { ConsoleNetworkOutline } from "mdi-material-ui";
import React, { useState, useEffect } from "react";

function LazyLoadImage({ src, style }) {
  const [inView, setInView] = useState({ inView: false, image: null });
  useEffect(() => {
    const image = new Image();
    image.onload = (e) => {
      setInView({ inView: true, image: src });
    };
    image.src = src;
  }, [src]);

  return (
    <>
      {!inView.inView ? (
        <div className="d-flex justify-content-center align-items-center">
          <span className="image-loader shadow-none"></span>
        </div>
      ) : (
        <img
          className="img-fluid"
          loading="lazy"
          src={inView?.image}
          alt="image"
          style={style ?? {}}
        />
      )}
    </>
  );
}

export default LazyLoadImage;
