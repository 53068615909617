import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { IframeGoogleDoc, ModalComponent } from "../../components/common";
import { IMAGES } from "../../constant";
import { getBasicInfo } from "../../utils";

import Draggable from "react-draggable";
import { FormattedMessage, useIntl } from "react-intl";
import Swal from "sweetalert2";
import api, { apiService } from "../../common/api";
import EmptyState from "../../components/common/emptyState";
import { copyToClipboard, openInNewTab, typeIconEquivalent } from "./helper";
import { FormatLetterCase } from "mdi-material-ui";

function FilesModal(props) {
  const intl = useIntl();
  const [state, setState] = useState({
    CurrentFolderPath: [],
    folderNavigationHistory: [],
    Files: [],
    UI: {
      showAddEditFolder: false,
      isLoading: false,
      ShowPickFolder: false,
    },
    CurrentUser: {},
    ChoosenFolder: {},
    SelectedFolder: "/",
    SelectedFile: null,
    showViewerModal: false,
    previewFile: null,
    ChoosenLink: null,
    SelectedFiles: [],
    showVisibleByEvents: false,
    Events: [],
  });

  useEffect(() => {
    // const currentOrganizationGUID = getOrganization().GUID;
    // const basicInfoGUID = getBasicInfo().GUID;
    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath));
    copy.push({ GUID: "/", Name: "/" });
    setState((prev) => {
      return {
        ...prev,
        CurrentUser: getBasicInfo(),
        CurrentFolderPath: copy,
      };
    });
  }, []);

  useEffect(() => {
    requestFolderRemote("/");
  }, [state.CurrentUser]);

  const unLinkFile = (item) => {
    const CurrentFolderPath = state.CurrentFolderPath;

    if (CurrentFolderPath.length > 1) {
      return Swal.fire({
        text: "The successful execution of this task requires the main folder to be unlinked. Navigate to the file root to initiate the action.",
        imageUrl: IMAGES.LOGO_BLACK_P,
        imageWidth: 80,
        imageHeight: 80,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await requestFolderRemote("/");
        }

        // state.UI.isLoading.set(!state.UI.isLoading.value);
      });
    }

    const currentEventsSharedWith =
      typeof item.EventShared === "string"
        ? JSON.parse(item.EventShared)
        : item.EventShared;

    let currentEvents = [];
    currentEventsSharedWith?.forEach((item) => {
      if (item) {
        currentEvents.push(item);
      }
    });

    const indexToBeDeleted = currentEvents.indexOf(props.event.GUID);
    currentEvents = currentEvents.slice(indexToBeDeleted, 0);

    Swal.fire({
      text: "Are you sure you want to unlink '" + item.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        if (item.Type === "folder") {
          let newItem = {
            GUID: item.GUID,
            Name: item.Name,
            Note: item.Note,
            Color: item.Color,
            UserGUID: item.UserId,
            ParentGUID: item.ParentGUID !== null ? item.ParentGUID : null,
            EventShared: JSON.stringify(currentEvents),
          };
          let copy = JSON.stringify(newItem);
          //We have to sent to api
          const request = await api.post(`/File/AddEditFolder`, copy);
        } else {
          let newItem = {
            GUID: item.GUID,
            Name: item.Name,
            Note: item.Note,
            Path: item.Path,
            UserGUID: item.UserId,
            ParentGUID: item.ParentGUID !== null ? item.ParentGUID : null,
            Type: item.Type,
            EventShared: JSON.stringify(currentEvents),
          };

          // state.UI.isLoading.set(!state.UI.isLoading.value);
          const requestGeneral = await api.post(
            "/File/AddEditFileWithoutFile",
            newItem
          );
        }
        // state.UI.isLoading.set(!state.UI.isLoading.value);

        await requestFolderRemote("/");
      }
    });
  };

  const openItem = (item) => {
    console.log({ item });
    //First check the type
    switch (item.Type) {
      case "folder":
        enterFolder(item);
        break;
      case "file":
      case "link":
        setState((prev) => {
          return {
            ...prev,
            SelectedFile: JSON.parse(JSON.stringify(item)),
            showViewerModal: !prev.showViewerModal,
          };
        });
        openPreview();
        break;
    }
  };

  function getIsNextDisable() {
    const index = state.CurrentFolderPath.length;
    const currentFolder = JSON.parse(
      JSON.stringify(state?.CurrentFolderPath[index - 1]) || null
    );

    const folderHistory = JSON.parse(
      JSON.stringify(state?.folderNavigationHistory)
    );

    const lastFolder = folderHistory[folderHistory.length - 1];
    let currenFolderIndex = 0;
    folderHistory.forEach((folder, index) => {
      if (folder.GUID === currentFolder.GUID) currenFolderIndex = index;
    });

    return (
      state.folderNavigationHistory.length < state.CurrentFolderPath.length ||
      !lastFolder ||
      lastFolder?.GUID === currentFolder?.GUID
    );
  }

  const isNextDisable = getIsNextDisable();

  const enterFolder = (item) => {
    setState((prev) => {
      return {
        ...prev,
        SelectedFile: null,
        SelectedFolder: item.GUID,
      };
    });

    let copy = JSON.parse(JSON.stringify(state.CurrentFolderPath));

    var exist = copy.some((x) => x.GUID === item.GUID);
    if (exist) {
      //If exist we must go over the array and create a new one until this record
      let newArray = [];
      for (var i = 0; i < copy.length; i++) {
        newArray.push(copy[i]);
        if (copy[i].GUID === item.GUID) {
          break;
        }
      }
      copy = newArray;
    } else {
      copy.push({ GUID: item.GUID, Name: item.Name });
    }

    setState((prev) => {
      return {
        ...prev,
        CurrentFolderPath: copy,
      };
    });

    let historyFolders = JSON.parse(
      JSON.stringify(state.folderNavigationHistory)
    );
    let firstFolder = copy.length > 1 ? copy[1] : null;

    historyFolders = historyFolders?.[1]?.GUID;
    firstFolder = firstFolder?.GUID;

    if (
      (firstFolder && historyFolders !== firstFolder) ||
      state.folderNavigationHistory.length < copy.length
    ) {
      setState((prev) => {
        return { ...prev, folderNavigationHistory: copy };
      });
    }

    requestFolderRemote(item.GUID);
  };

  const handleFileNavitation = (toFolder) => {
    const index = state.CurrentFolderPath.length;
    const currentFolder = JSON.parse(
      JSON.stringify(state.CurrentFolderPath[index - 1])
    );
    const folder = state.CurrentFolderPath[index - 2];
    const history = JSON.parse(JSON.stringify(state?.folderNavigationHistory));

    if (toFolder === "next") {
      const lastFolder = history[history.length - 1];
      let currenFolderIndex = 0;
      history.forEach((folder, index) => {
        if (folder.GUID === currentFolder.GUID) currenFolderIndex = index;
      });

      if (!lastFolder || lastFolder?.GUID === currentFolder?.GUID) return;

      const nextFolder = state.folderNavigationHistory[currenFolderIndex + 1];
      enterFolder(nextFolder);
    } else {
      enterFolder(folder);
    }
  };

  const openFile = (item) => {
    setState((prev) => {
      return {
        ...prev,
        SelectedFile: JSON.parse(JSON.stringify(item)),
      };
    });
    openInNewTab(item);
  };

  const openPreview = () => {
    let item = state.SelectedFile;

    console.log({ item });

    if (item === null) {
      return;
    }
    let icon = item.Path.split(".").pop();

    let result = null;

    if (item.Type === "link") {
      setState((prev) => {
        return {
          ...prev,
          showViewerModal: false,
        };
      });
      result = null;
      if (
        (item.Path && item.Path.includes("dropbox")) ||
        item.Path.includes("google") ||
        item.Path.includes("1drv") ||
        item.Path.includes("http")
      ) {
        window.open(item.Path, "noopener");
      }
      return;
    }

    switch (icon) {
      case "jpg":
      case "png":
      case "jpeg":
        result = (
          <div className="image-viewer">
            <img
              className="img-fluid"
              src={`https://service.plnify.com/files/${item.Path}`}
            />
          </div>
        );
        break;

      case "ds":
        setState((prev) => {
          return {
            ...prev,
            showViewerModal: false,
          };
        });

        result = null;
        if (
          (item.Path && item.Path.includes("dropbox")) ||
          item.Path.includes("google") ||
          item.Path.includes("1drv") ||
          item.Path.includes("http")
        ) {
          window.open(item.Path, "noopener");
        }
        break;

      default:
        result = (
          <IframeGoogleDoc
            url={
              item.Path.includes("google") || item.Path.includes("1drv")
                ? item.Path
                : `https://service.plnify.com/files/${item.Path}`
            }
          />
        );
        break;
    }
    //   state.showViewerModal.set(!state.showViewerModal.value);
    return result;
  };

  const requestFiles = () => {
    let files = [];
    state.Files.forEach((item) => {
      let newFile = (
        <div className="col-12 px-0 mx-0">
          <div className="row filesContainer">
            <div className="col-auto text-center">
              <i
                style={
                  item.Color
                    ? { color: item.Color }
                    : item.Type === "folder"
                    ? { color: "#E6E8EA" }
                    : null
                }
                className={typeIconEquivalent(item) + " fa-2x"}
                onClick={() => {
                  openItem(item);
                }}
              />
            </div>
            <div className="col-8">
              <h5
                className="title clickable mt-1"
                onClick={() => {
                  openItem(item);
                }}
              >
                {item.Name}
                {item.EventShared !== null ? (
                  <i className="fas fa-link text-secondary ml-2 fa-xs d-none"></i>
                ) : null}
              </h5>
            </div>
            <div className="col-2 text-right ml-auto">
              <Popup
                trigger={
                  <button type="button" className="btn btn-sm">
                    <i className="fa fa-ellipsis-v" />
                  </button>
                }
                on="click"
                position="bottom right"
                arrow={false}
              >
                {(open) => (
                  <>
                    {item.Type !== "folder" ? (
                      <button
                        type="button"
                        className="btn btn-block flex-1"
                        onClick={() => {
                          openItem(item);
                          open();
                        }}
                        title="Copy to clipboard"
                      >
                        Preview
                      </button>
                    ) : null}
                    {item.Type !== "folder" ? (
                      <button
                        type="button"
                        className="btn btn-block flex-1"
                        onClick={() => {
                          openFile(item);
                        }}
                        title="Copy to clipboard"
                      >
                        Download
                      </button>
                    ) : null}

                    {item.Type !== "folder" ? (
                      <button
                        type="button"
                        className="btn btn-block flex-1"
                        onClick={() => {
                          copyToClipboard(item);
                          open();
                        }}
                        title="Copy to clipboard"
                      >
                        Copy Link
                      </button>
                    ) : null}
                    <button
                      className="btn btn-block flex-1"
                      type="button"
                      onClick={() => {
                        unLinkFile(item);
                        open();
                      }}
                    >
                      Unlink
                    </button>
                  </>
                )}
              </Popup>
            </div>

            <div className="col-12">
              <hr className=" my-1 py-1" />
            </div>
          </div>
        </div>
      );
      files.push(newFile);
    });

    return files;
  };

  const requestFolderRemote = async (folder) => {
    setState((prev) => {
      return {
        ...prev,
        SelectedFiles: [],
        UI: {
          ...prev.UI,
          isLoading: !prev.UI.isLoading,
        },
      };
    });
    // if (props.event === undefined || props.event === null) {
    //   return;
    // }

    const url =
      folder === "/" || folder === undefined
        ? `/File/List?UserID=${state.CurrentUser.GUID}&FolderId=${
            folder === "/" ? "" : folder
          }${props.event.GUID ? "&EventID=" + props.event.GUID : ""}`
        : `/File/List?UserID=${state.CurrentUser.GUID}&FolderId=${
            folder === "/" ? "" : folder
          }`;

    const request = await apiService.get(url);

    let response = request.data

    if (response.Status === "ok") {
      // state?.Files?.set(response.Response);
      setState((prev) => {
        return {
          ...prev,
          Files: response?.Response.filter((item) => !item.Deleted),
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          UI: {
            ...prev.UI,
            isLoading: !prev.UI.isLoading,
          },
        };
      });
    }
  };

  return (
    <div className={"organization-transparent"}>
      <ModalComponent
        modal={state.showViewerModal}
        toggle={() =>
          setState((prev) => {
            return { ...prev, showViewerModal: !prev.showViewerModal };
          })
        }
        childeren={
          <Draggable
            axis="x"
            defaultPosition={{ x: 0, y: 0 }}
            position={null}
            grid={[25, 25]}
            scale={1}
          >
            <div className="viewer-documents d-flex flex-column">
              <div className="viewer-documents-drag"></div>
              {state.showViewerModal && openPreview()}
            </div>
          </Draggable>
        }
      />

      {/*END MODALS AREA*/}

      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h1 className="title">
                <FormattedMessage id="files" defaultMessage="Files" />
              </h1>
            </div>
          </div>
          <div className="px-0 py-0">
            <div className="row customToolbar px-0 py-2 mx-0">
              <div className="col-12 px-1">
                {state.CurrentFolderPath.length > 0 ? (
                  <nav aria-label="breadcrumb" className="mb-2">
                    <ol className="breadcrumb m-0 p-0">
                      <button
                        className="btn btn-group"
                        onClick={handleFileNavitation}
                        disabled={
                          state.CurrentFolderPath.length > 1 ? false : true
                        }
                      >
                        <i
                          className="fa fa-arrow-left text-secondary"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-group"
                        onClick={() => handleFileNavitation("next")}
                        disabled={isNextDisable ? true : false}
                      >
                        <i
                          className="fa fa-arrow-right text-secondary"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </button>
                      {state.CurrentFolderPath.map((path) => {
                        return path.GUID === "/" ? (
                          <li
                            key={path.GUID}
                            className="breadcrumb-item "
                            onClick={() => enterFolder(path)}
                          >
                            <i className="fa fa-home-alt text-secondary"></i>
                          </li>
                        ) : (
                          <li
                            key={path.GUID}
                            className="breadcrumb-item text-secondary"
                            onClick={() => enterFolder(path)}
                          >
                            {path.Name}
                          </li>
                        );
                      })}
                    </ol>
                  </nav>
                ) : null}
              </div>
            </div>
            <div className="row  containerFullHeight text-dark">
              <div className="col-12 mx-0 px-0">
                {/*FILES */}
                {
                  state.Files.length > 0 ? (
                    <div className="row">
                      <div className="col-10 mb-2">
                        <small>
                          <FormattedMessage
                            id="file.name"
                            defaultMessage="Name"
                          />
                        </small>
                        {state.UI.isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                      </div>
                      {requestFiles()}
                    </div>
                  ) : (
                    <EmptyState
                      image={IMAGES.ATTACHMENTS_FILES}
                      cta="Add a new attachment"
                      text={intl.formatMessage({ id: "noFilesLinked" })}
                    />
                  )
                  //Supposed to display empty state
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilesModal;
