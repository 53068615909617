import React, { useEffect, useRef, useState } from "react";
import Activity from "./activity";
import ActivityForm from "./activityform";
import {
  ModalComponent,
  ToastNotificationError,
  ToastNotificationSuccess,
} from "../common";
import moment from "moment";
import Swal from "sweetalert2";
import { IMAGES } from "../../constant";
import EmailModal from "../hotel/emailModal";
import Popup from "reactjs-popup";
import { useIntl, FormattedMessage } from "react-intl";
import * as actions from "../../page/event/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ConsoleNetworkOutline } from "mdi-material-ui";
import SharingHub from "../common/sharingHub";
import { handleCloseEmail } from "../../utils/closeMailModal";

const ActivitiesList = (props) => {
  let {
    sideBarItemData,
    event,
    addActivity,
    deleteActivity,
    editActivity,
    refreshSchedules,
    handleSidebarItem,
    loading,
  } = props;
  const [state, setState] = useState({ showShare: false, sharingContent: "" });
  const [exportType, setExportType] = useState("email");
  const [createForm, setCreateForm] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [exportItinerary, setExportItinerary] = useState({
    exportItinerary: false,
    QRcode: false,
  });
  const ScheduleMaster = useSelector((state) => state?.event?.schedulesMasters);
  const [currentSchedule, setCurrentSchedule] = useState(sideBarItemData);
  const intl = useIntl();
  const dispatch = useDispatch();
  function clear(itinerary) {
    Swal.fire({
      text: intl.formatMessage({ id: "Are you sure you want to delete" }),
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
    }).then((result) => {
      if (result.value) {
        dispatch(
          actions.clearSchedules(itinerary, () => {
            ToastNotificationSuccess(1, "Cleared all");
            handleSidebarItem("ACTIVITIES", {
              ...sideBarItemData,
              Schedules: [],
            });
          })
        );
      }
    });
  }

  useEffect(() => {
    const currentSchedule = ScheduleMaster.filter(
      (schedule) => schedule.GUID === sideBarItemData.GUID
    );
    setCurrentSchedule(currentSchedule?.[0]);
    const isPublic =
      sideBarItemData.Public === null ? false : sideBarItemData.Public;
    setIsPublished(isPublic);
  }, [sideBarItemData]);

  const groups = currentSchedule?.Schedules?.reduce((groups, activity) => {
    const date = activity?.Date?.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(activity);
    return groups;
  }, {});

  const activitiesArray = !groups
    ? []
    : Object.keys(groups).map((date) => {
        return {
          date,
          activities: groups[date],
        };
      });

  const toggleExportItinerary = (generateQROnly) => {
    if (exportItinerary.exportItinerary)
      return setExportItinerary((prev) => {
        return {
          exportItinerary: !prev.exportItinerary,
          QRcode: generateQROnly ? true : false,
        };
      });

    // Swal.fire({
    //   text: intl.formatMessage({
    //     id: "How would you like to send this email?",
    //   }),
    //   imageUrl: IMAGES.LOGO_BLACK_P,
    //   imageWidth: 80,
    //   imageHeight: 80,
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "secondary",
    //   confirmButtonText: intl.formatMessage({ id: "Generate PDF" }),
    //   cancelButtonText: intl.formatMessage({ id: "As Body of Email" }),
    // }).then((result) => {
    //   if (result.value) {
    //     setExportType("export");
    //     setExportItinerary((prev) => {
    //       return {
    //         exportItinerary: !prev.exportItinerary,
    //         QRcode: generateQROnly ? true : false,
    //       };
    //     });
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    setExportType("email");
    setExportItinerary((prev) => {
      return {
        exportItinerary: !prev.exportItinerary,
        QRcode: generateQROnly ? true : false,
      };
    });
    //   }
    // });
  };

  const toggleShare = (itinerary) => {
    let data = itinerary;
    let dataContent = "";
    if (data !== null) {
      let msg =
        data.Name + " \n" + moment(data.Date).format("MMM DD, YYYY") + "\n";
      let content = "";
      content += msg + "\n";
      let activities = data?.Schedules?.filter((x) => x.Deleted === null);
      activities?.forEach((item) => {
        let description = item?.Notes ? "(" + item?.Notes + ")" : "";
        let formatedTime =
          moment(item?.Date).format("hh:mm A") +
          (item?.DateUntil && item?.Date !== item?.DateUntil
            ? " - " + moment(item?.DateUntil).format("hh:mm A")
            : "");
        content += formatedTime + ": " + item?.Name + description + "\n";
      });
      dataContent = content;
    }

    setState((prev) => ({
      ...prev,
      showShare: !prev.showShare,
      sharingContent: dataContent,
    }));
  };

  const toggleRef = useRef();

  const handlePublishToggle = () => {
    setIsPublished((prev) => {
      const notificationType = prev
        ? ToastNotificationError
        : ToastNotificationSuccess;

      dispatch(
        actions.editItinerary(
          {
            Date: sideBarItemData.Date,
            Name: sideBarItemData.Name,
            GUID: sideBarItemData.GUID,
            EventGUID: sideBarItemData.EventGUID,
            Public: !prev,
            Label: sideBarItemData.Label,
          },
          () => {
            notificationType(
              Math.random(),
              `Itineary ${
                prev ? "unpublished successfully." : "published successfully."
              }`
            );
          },
          () => {}
        )
      );

      return !prev;
    });
  };

  return (
    <>
      <ModalComponent
        modal={exportItinerary.exportItinerary}
        toggle={() => handleCloseEmail(toggleExportItinerary)}
        childeren={
          <EmailModal
            fromSection="ITINERARIES"
            ExportType={exportType}
            // sendEmail={props.commonActions.sendEmail}
            itinerary={{ ...sideBarItemData, QRCode: exportItinerary.QRcode }}
            toggle={toggleExportItinerary}
          />
        }
      />
      <ModalComponent
        modal={state.showShare}
        toggle={toggleShare}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={state.sharingContent}
            toggle={toggleShare}
            //    ParentSectionGUID={attachment.EventGUID}

            Type="ITINERARIES"
            // sectionGUID={attachment.GUID}
          />
        }
      />

      <ModalComponent
        clear={true}
        modal={createForm}
        toggle={() => setCreateForm((createForm) => !createForm)}
        childeren={
          <ActivityForm
            expanded={document
              .getElementById("navbar")
              .classList.contains("expanded")}
            toggle={() => setCreateForm((createForm) => !createForm)}
            addActivity={addActivity}
            scheduleMaster={sideBarItemData}
            event={event.GUID}
            loading={loading}
          />
        }
      />

      <div className="activitiesList">
        <div className="activitiesList__header">
          <h4 className="contentTitle">{sideBarItemData.Name}</h4>
          <div className="activitiesList__buttons d-flex">
            <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => toggleShare(sideBarItemData)}
            >
              <i className="fa fa-share-alt" />
            </button>
            <button
              onClick={() => toggleExportItinerary()}
              className="btn btn-sm btn-secondary mr-2 flex-1"
            >
              <FormattedMessage
                id="exportDaysheet"
                defaultMessage="Export Daysheet"
              />
            </button>
            <button
              onClick={() => setCreateForm((createForm) => !createForm)}
              className="btn btn-sm btn-primary"
            >
              <FormattedMessage
                id="addActivity"
                defaultMessage="Add Activity"
              />
            </button>
            <buttom
              className={`d-flex justify-content-between align-items-center mx-2`}
              style={{ width: "110px" }}
              title={isPublished ? "Make Private" : "Make Public"}
              onClick={handlePublishToggle}
            >
              <span className="mr-1 text-black-50 text-right w-50">
                {isPublished ? "Published" : "Private"}
              </span>
              <div
                className={`toggle-switch ${isPublished && "active"}`}
                id="myToggle"
              >
                <div
                  ref={toggleRef}
                  className={`toggle-switch-inner ${isPublished && "active"}`}
                ></div>
              </div>
            </buttom>
            <Popup
              trigger={
                <button type="button" className="btn btn-sm  mr-3 ml-2">
                  <i className="fa fa-ellipsis-v" />
                </button>
              }
              on="click"
              position="bottom right"
              arrow={false}
            >
              {(open) => (
                <>
                  <button
                    onClick={() => {
                      open();
                      clear(sideBarItemData);
                    }}
                    className="btn btn-block flex-1"
                    type="button"
                  >
                    <FormattedMessage
                      id="removeAll"
                      defaultMessage="Remove all"
                    />
                  </button>
                  <button
                    onClick={() => {
                      open();
                      toggleExportItinerary(true);
                    }}
                    className="btn btn-block flex-1"
                    type="button"
                  >
                    <FormattedMessage
                      id="generateQR"
                      defaultMessage="Generate QR Code"
                    />
                    <i className="fa fa-qrcode ml-2" />
                  </button>
                </>
              )}
            </Popup>
          </div>
        </div>

        {activitiesArray.length > 0 ? (
          activitiesArray.map((group, index) => (
            <div key={index}>
              <div
                key={Math.random()}
                className="activitiesList__actions d-flex align-items-center"
              >
                <div className="mr-auto">
                  <p>
                    <span>{moment(group.date).format("MMM DD, YYYY")}</span>
                  </p>
                </div>
              </div>
              <div>
                {group.activities
                  .filter((a) => a.Deleted === null)
                  .sort((a, b) => {
                    if (a.Date > b.Date) return 1;
                    if (a.Date < b.Date) return -1;
                  })
                  .map((activity) => (
                    <Activity
                      key={activity.GUID}
                      activity={activity}
                      deleteActivity={deleteActivity}
                      event={event.GUID}
                      addActivity={addActivity}
                      scheduleMaster={sideBarItemData}
                      editActivity={editActivity}
                      refreshSchedules={refreshSchedules}
                    />
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{ height: "calc(100% - 100px)" }}
            className="text-center d-flex flex-column justify-content-center align-items-center"
          >
            <img
              alt=""
              className="mx-auto img-fluid mb-4"
              style={{ maxWidth: "35%" }}
              src={IMAGES.ACTIVITIES_PLACEHOLDER}
            />
            <button
              onClick={() => setCreateForm((createForm) => !createForm)}
              type="button"
              className="btn primary w-260"
            >
              <FormattedMessage
                id="addYourFirstActivity"
                defaultMessage="Add Your first activity"
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivitiesList;
