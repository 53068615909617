import React, { useState } from "react";
import {
  Delete as DeleteButton,
  Star as FavoriteButton,
  NoteTextOutline,
  CloseCircle,
  CheckCircle,
} from "mdi-material-ui";
import { IMAGES } from "../../constant";
import {useIntl, FormattedMessage} from 'react-intl'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GuestItem from "./GuestItem";

const RequestDetails = (props) => {
  const [toggleTicketsAndPasses, settoggleTicketsAndPasses] = useState(false);
  let { toggle, requestSelected } = props;
  return (
    <div className={"organization-transparent"}>
      <div className="event-page">
        <div className="event-expennd-section invite-team ticketRequestModal">
          <div className="text-head">
            <div className="text-left">
              <h1><FormattedMessage
                    id="Request Details"
                    defaultMessage="Request Details"/></h1>
              <p className="text-muted">By Starlin G</p>
            </div>
            <div className="text-right">
              <button type="button" className="btn btn-primary">
                <FontAwesomeIcon icon={faPlus} color="white" />
              </button>
            </div>
          </div>
          <div className="event-inside px-2">
            <div className="event-content">
              <table className="table my-2 shadow-sm guestList">
                <tbody>
                  <GuestItem requests={requestSelected} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestDetails.propTypes = {};

export default RequestDetails;
