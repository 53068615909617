import React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormField from "../form/formField";
import { SimpleLoader } from "../common";
import { confirmedNoteSchema } from "../../utils/validationsSchemas";
import { useIntl, FormattedMessage } from "react-intl";

const AdminNote = ({ ticket, loading, updateRequest, toggle, userNote }) => {
  return (
    <div className="">
      <div className="event-page">
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left">
              <h5 className="title">
                {userNote ? "User note" : "Admin Notes"}
              </h5>
            </div>
            <button className="btn" onClick={() => toggle()}>
              <i className="fas fa-times" />
            </button>
          </div>
          <Formik
            initialValues={ticket}
            onSubmit={(values) => updateRequest(values, toggle())}
            validationSchema={confirmedNoteSchema}
          >
            <Form className="mt-3">
              <div className="event-inside">
                <div className="event-content">
                  <div className="row">
                    <FormField fullWidth={true} additionalClasses="px-0 pr-2">
                      {userNote ? (
                        <Field
                          className="form-control"
                          rows={4}
                          as="textarea"
                          name="Note"
                        ></Field>
                      ) : (
                        <Field
                          className="form-control"
                          rows={4}
                          as="textarea"
                          name="ConfirmedNote"
                        ></Field>
                      )}
                    </FormField>
                  </div>
                </div>
                {!userNote && (
                  <div className="py-3">
                    {loading ? (
                      <SimpleLoader loading={loading} />
                    ) : (
                      <div className="row mx-0">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary font-weight-bold  btn-block"
                          >
                            <FormattedMessage id="done" defaultMessage="Done" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

AdminNote.propTypes = {};

export default AdminNote;
