import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { VEHICLES } from "../../constant";
import { SimpleLoader } from "../common";

const VehicleModal = (props) => {
  let { add, ground, vehicle, addVehicle, editVehicle, loading } = props;
  return (
    <div className="vehicleModal__root" style={{ padding: "12px 18px" }}>
      <h5 style={{ color: "black" }}>{add ? "Add Vehicle" : "Edit Vehicle"}</h5>
      <Formik
        initialValues={
          add
            ? {
                DriverPhone: "",
                VehicleType: "",
                DriverName: "",
                GroundGUID: ground.GUID,
              }
            : vehicle
        }
      >
        {(props) => (
          <Form autoComplete="off">
            <Select
              placeholder="Type of Vehicle"
              value={
                props.values.VehicleType
                  ? VEHICLES.find((v) => v.value === props.values.VehicleType)
                  : ""
              }
              options={VEHICLES}
              onChange={(option) => {
                props.setFieldValue("VehicleType", option.value);
              }}
            />

            <Field
              autoComplete="off"
              type="text"
              className="form-control my-1"
              name="DriverName"
              placeholder="Driver"
            />
            <Field
              autoComplete="off"
              type="text"
              className="form-control my-1"
              name="DriverPhone"
              placeholder="Contact"
            />

            <div className="pb-2 pt-3 text-right">
              {loading ? (
                <SimpleLoader loading={loading} />
              ) : (
                <button
                  onClick={
                    add
                      ? () => addVehicle(props.values)
                      : () => editVehicle(props.values)
                  }
                  type="button"
                  className="btn primary text-capitalize w-160"
                >
                  {add ? "Add Vehicle" : "Update Vehicle"}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VehicleModal;
