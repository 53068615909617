import * as constant from "./constants";
import api from "../../../common/api";
import uuid from "uuid";
import db from "../../../common/db";

export function addContact(contact, event, success) {
  const url = "/ProductionVenueContact/AddEdit";
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_CONTACT,
    });
    try {
      const contactToSave = {
        GUID: contact.GUID ? contact.GUID : uuid.v4(),
        EventGUID: event,
        Name: contact.Name,
        LastName: contact.LastName,
        Email: contact.Email,
        OfficePhone: contact.OfficePhone,
        MobilePhone: contact.MobilePhone,
        Position: contact.Position,
        OfficePhoneExtension: contact.OfficePhoneExtension,
      };
      await db.table("contacts").put(contactToSave);
      if (contact.GUID) {
        dispatch({
          type: constant.EDITED_CONTACT,
          data: contactToSave,
        });
      } else {
        dispatch({
          type: constant.CREATED_CONTACT,
          data: contactToSave,
        });
      }
      success();
      await api.post(url, contactToSave);
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_CONTACT_ERROR,
        data: error,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_CONTACT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_CONTACT: {
      return {
        ...state,
        loading: false,
        contacts: [...state.contacts, action.data],
      };
    }
    case constant.EDITED_CONTACT: {
      return {
        ...state,
        loading: false,
        contacts: state.contacts.map((contact) => {
          if (contact.GUID === action.data.GUID) {
            contact = action.data;
          }

          return contact;
        }),
      };
    }
    case constant.CREATING_CONTACT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
