import { useIntl, FormattedMessage } from "react-intl";

export const eventSections = [
  {
    guid: "2e95b571-3dd4-41c7-8d3f-a37ca60c02d9",
    name: <FormattedMessage id="general" defaultMessage="General" />,
    id: "general",
    icon: "fa fa-home",
  },

  {
    guid: "621b5ac0-65f8-41da-9b33-55510c2a2506",
    name: <FormattedMessage id="itineraries" defaultMessage="Itineraries" />,
    id: "itineraries",
    icon: "fa fa-clipboard-list",
  },
  {
    guid: "43f6a63e-0d31-4464-895d-31d6a94027a7",
    name: <FormattedMessage id="flights" defaultMessage="Flights" />,
    id: "flights",
    icon: "fa fa-plane-departure",
  },
  {
    guid: "5c06d6a5-b1ff-491c-bbca-cd12ac7fbf30",
    name: <FormattedMessage id="hotels" defaultMessage="Hotels" />,
    id: "hotels",
    icon: "fa fa-hotel",
  },
  {
    guid: "cfc1ddb3-3704-45eb-8a09-34a5246b1c99",
    name: (
      <FormattedMessage id="transportation" defaultMessage="Transportation" />
    ),
    id: "transportation",
    icon: "fa fa-bus",
  },
  {
    guid: "4505a64f-6a73-4a78-8e83-6c10289a8231",
    name: <FormattedMessage id="contacts" defaultMessage="Event Contacts" />,
    id: "contacts",
    icon: "fa fa-address-book",
  },
  {
    guid: "20669ed9-e3bf-423b-8617-19d6b6777080",
    name: <FormattedMessage id="attachments" defaultMessage="Attachments" />,
    id: "attachments",
    icon: "fa fa-paperclip",
  },
];
