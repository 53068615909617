// import { Editor } from "@tinymce/tinymce-react";
import React, { useState } from "react";
import api from "../../../common/api";
import EventHeader from "../../../components/event/eventHeader";
import { IMAGES, TINY_MCE_KEY } from "../../../constant";
import RichTextComponent from "../../../components/common/RichTextEditorComponent";

const Collab = ({ item, content, permissionType, event, sectionType }) => {
  const [state, setState] = useState({
    Item: item,
    UI: { IsLoading: false },
  });
  const saveRemote = async () => {
    if (permissionType === 1 && sectionType.toUpperCase() === "MOREDETAILS") {
      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: true },
      }));
      let query = JSON.parse(JSON.stringify(state.Item));
      let queryArray = [];
      queryArray.push(query);
      const remoteData = await api.post(
        `/EventMoreDetails/AddEdit`,
        queryArray
      );

      setState((prev) => ({
        ...prev,
        UI: { ...prev.UI, IsLoading: false },
      }));
    }
  };

  return (
    <div className="dashboard-screen">
      <div className="calendar-page">
        {/**HEADER */}
        <div className=" row mx-0 page-header">
          <div className="text-left col-4 px-0 ">
            {event && <EventHeader event={event} production />}
          </div>
          <div className="col-4 text-center">
            <img
              src={IMAGES.LOGO_BLACK}
              alt="Plnify"
              style={{ width: "75px" }}
            />
          </div>

          <div className="col-4  px-0 text-right">
            <h1 className="mr-2">Collab - {item.Title}</h1>
          </div>
        </div>
        {/**END HEADER */}
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="card shadow-sm my-4">
              {permissionType === 1 ? (
                <div className="card-header text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      saveRemote();
                    }}
                  >
                    Save
                  </button>
                </div>
              ) : null}
              <div
                className="card-body containerEditor"
                style={{ height: "80vh" }}
              >
                {sectionType === "MoreDetails" ? (
                  permissionType === 0 ? (
                    <iframe
                      height="100%"
                      width="100%"
                      id="content"
                      frameBorder="0"
                      srcDoc={content}
                    />
                  ) : (
                    <RichTextComponent
                      value={
                        state.Item.Content === null ? "" : state.Item.Content
                      }
                      handleChange={({ value }) => {
                        setState((prev) => ({
                          ...prev,
                          Item: { ...prev.Item, Content: value },
                        }));
                      }}
                      onBlur={async () => {
                        await saveRemote();
                      }}
                    />
                    //   <Editor
                    //     apiKey={TINY_MCE_KEY}
                    //     value={
                    //       state.Item.Content === null ? "" : state.Item.Content
                    //     }
                    //     onEditorChange={(content) => {
                    //       setState((prev) => ({
                    //         ...prev,
                    //         Item: { ...prev.Item, Content: content },
                    //       }));
                    //       //  state.UI.Current.Content.set(content);
                    //     }}
                    //     onBlur={async () => {
                    //       await saveRemote();
                    //     }}
                    //     init={{
                    //       placeholder: "",

                    //       menubar: true,
                    //       plugins: [
                    //         "forecolorpicker advlist autolink lists link image fullscreen",
                    //         "charmap print preview anchor help",
                    //         "searchreplace visualblocks code",
                    //         "insertdatetime media table advtable  wordcount  textcolor  powerpaste",
                    //       ],
                    //       paste_merge_formats: false,
                    //       powerpaste_allow_local_images: true,
                    //       toolbar:
                    //         "undo redo | fontselect fontsizeselect formatselect | bold italic underline forecolor backcolor | \
                    // alignleft aligncenter alignright | \
                    // table  | \
                    // bullist numlist outdent indent | image link media | removeformat help",

                    //       content_style:
                    //         "p{font-size:14px !important;} height:100%",
                    //       branding: false,
                    //     }}
                    //   />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collab;
