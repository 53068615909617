import * as constant from "./constants";
import api, { apiService } from "../../../common/api";
import { getBasicInfo } from "../../../utils";

export function createTemplate(itinerary, organization, Label, success) {
  return async (dispatch) => {
    try {
      await apiService.get("/ScheduleTemplate/CreateTemplate", {
        params: {
          ScheduleMasterGUID: itinerary,
          OrganizationGUID: organization,
          Label: Label,
          CreateAccountGUID: getBasicInfo().GUID,
        },
      });
      success();
    } catch (error) {
      console.error(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
