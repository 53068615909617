import * as constants from "./constants";
import api, { apiService } from "../../../common/api";
import db from "../../../common/db";

export function addAssignees(assignees, todo, success) {
  const urlForAssignees = "ToDo/AddEditAssignees";
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.ADDING_ASSIGNEES,
      });
      const localTodo = await db.table("todos").where({ GUID: todo }).first();
      localTodo.TodoAssignees = assignees;
      await db.table("todos").put(localTodo);
      const request = await apiService.post(urlForAssignees, assignees);
      const remoteAssigned = request.data.Response;
      localTodo.TodoAssignees = remoteAssigned;
      await db.table("todos").put(localTodo);
      dispatch({
        type: constants.ADDED_ASSIGNEES,
        data: localTodo,
        list: todo,
      });
      success();
    } catch (error) {
      dispatch({
        type: constants.ADDING_ASSIGNEES_ERROR,
        data: todo,
      });
      console.log(error);
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constants.ADDING_ASSIGNEES: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.ADDED_ASSIGNEES: {
      return {
        ...state,
        loading: false,
        todos: state.todos.map((t) => {
          if (t.GUID === action.list) {
            t = action.data;
            return t;
          }
          return t;
        }),
      };
    }
    case constants.ADDING_ASSIGNEES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
