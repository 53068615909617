import React from "react";

export const SimpleLoader = (props) => {
  let { loading } = props;
  return (
    loading && (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  );
};
